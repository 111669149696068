import { Fragment } from "react";
import { LangConstant } from "const";
import { TableContainer, Table, TableHead, TableCell, TableRow, TableBody, IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";

const TableUserAccess = props => {
  const { searchUser, listUser, handleDeleteUser } = props;

  return (
    <TableContainer style={{ maxHeight: 700 }}>
      <Table stickyHeader sx={{ overflow: "scroll" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>STT</TableCell>
            <TableCell>{LangConstant.TXT_NAME}</TableCell>
            <TableCell>{LangConstant.TXT_TIME_SYSTEM} </TableCell>
            <TableCell>{LangConstant.TXT_EMAIL}</TableCell>
            <TableCell align="center">{LangConstant.TXT_ACTION}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listUser
            ?.filter(user => user.name.includes(searchUser))
            ?.map((row, index) => (
              <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.department}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.email}
                </TableCell>
                <TableCell align="center" sx={{ display: "flex", justifyContent: "space-around" }}>
                  <Fragment>
                    <IconButton title={"Xóa"} onClick={() => handleDeleteUser(row.uid)}>
                      <Delete />
                    </IconButton>
                  </Fragment>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default TableUserAccess;
