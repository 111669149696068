import { EventConstant } from "const";

export const useNotification = () => {
  const send = (message, type = "success") => {
    const event = new CustomEvent(EventConstant.GLOBAL_EVENTS.appendNotification, { detail: { message, type } });
    window.dispatchEvent(event);
  };

  return { send };
};
