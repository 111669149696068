import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";

const ShipIcon = ({ className, width, height, fillColor }) => {
  return (
    <SvgIcon className={className} width={width} height={height} viewBox={`0 0 24 45`}>
      <path
        d="M4.58065 4L2 42L11.0323 34.2712L22 37.4915L4.58065 4Z"
        fill={fillColor}
        stroke="#FF0000"
        strokeWidth="1"
      />
    </SvgIcon>
  );
};
ShipIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};
ShipIcon.defaultProps = {
  width: 24,
  height: 24,
};
export default memo(ShipIcon);
