import React, { memo, useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Box, Button, CircularProgress, IconButton, Typography } from "@mui/material";
import { Add, AltRoute, Close, Info, WarningAmber, Cached, RestartAlt, Search } from "@mui/icons-material";
import Cookies from "js-cookie";
import { convertCoordinates, getPositionOfPopup, hasRoleScopes, dragElement } from "utils";
import { convertMillisecondsToDateTime, getCreatedTime } from "utils/date.utils";
import { useMapContext, useFlickerMarker, useNotification, useHoverMarker } from "hooks";
import { AppConstant, EventConstant, LangConstant, SystemConstant } from "const";
import ShipActions from "redux-store/ship.redux";
import LayerAddViolationShipDialog from "./LayerAddViolationShipDialog";
import LayerShipDetailDialog from "./LayerShipDetailDialog";
import LayerAddTrackingShipDialog from "./LayerAddTrackingShipDialog";
import LayerSearchShipsAroundDialog from "./LayerSearchShipsAroundDialog";
import { EventBus } from "EventBus";
import countryList from "react-select-country-list";
import { CopyClipboard } from "theme/images";

const LayerShipInfoDialog = () => {
  const id = DIALOG_ID;
  const { clickedShip, setClickedShip, setSidebarMode, flickerLayerRef, hoverShip, hoverLayerRef, turnOnMeasure } =
    useMapContext();
  const { createFlickerMarker } = useFlickerMarker(flickerLayerRef);
  const { createHoverMarker } = useHoverMarker(hoverLayerRef);
  const roleId = Cookies.get("role");
  const classes = useStyles();
  const dispatch = useDispatch();
  const notification = useNotification();

  const trackingShip = useSelector(state => state.shipRedux.trackingShip);
  const countryOptions = useMemo(() => countryList().getData(), []);

  const [isOpenAddViolationDialog, setIsOpenAddViolationDialog] = useState(false);
  const [isOpenAddShipDetailDialog, setIsOpenAddShipDetailDialog] = useState(false);
  const [isOpenAddShipToGroup, setIsOpenAddShipToGroup] = useState(false);
  const [isOpenSearchShipsAround, setIsOpenSearchShipsAround] = useState(false);

  const dispatchTrackingShipEvent = ship => {
    let buttons = document.querySelectorAll(".navbar-button-uniq");
    buttons.forEach(button => button.classList.remove("active"));
    document.querySelector(".navbar-button-uniq-" + AppConstant.NAVBAR_ITEM_KEY.trackingShip).classList.add("active");
    const event = new CustomEvent(EventConstant.GLOBAL_EVENTS.addTrackingShipData, { detail: ship });
    setSidebarMode("tracking");
    setClickedShip({ event: null, info: null });
    window.dispatchEvent(event);
  };

  const onClose = () => {
    setClickedShip({ event: null, info: null });
    onCloseAddViolationDialog();
    onCloseAddShipDetailDialog();
    dispatch(ShipActions.shipSet({ trackingShip: {} }));
  };

  const onOpenAddViolationDialog = () => {
    setIsOpenAddViolationDialog(true);
  };

  const onCloseAddViolationDialog = () => {
    setIsOpenAddViolationDialog(false);
  };

  const onOpenAddShipDetailDialog = () => {
    setIsOpenAddShipDetailDialog(true);
  };

  const onCloseAddShipDetailDialog = () => {
    setIsOpenAddShipDetailDialog(false);
  };

  const updateLocationShip = () => {
    if (clickedShip.info) {
      const ship = clickedShip.info;
      createFlickerMarker(ship);
      dispatch(
        ShipActions.requestTrackingShip({
          ...ship,
          uid: ship.ship_uid,
          distance: ship.distance,
          resetLocation: true,
          update_f: true,
        }),
      );
      dispatch(
        ShipActions.shipSet({
          trackingShip: {
            distance: ship.distance,
            time: ship.time,
          },
        }),
      );
    }
  };

  const stopPropagation = e => {
    e.stopPropagation();
  };

  const handleSendSearchJourneyToSGW = () => {
    EventBus.emit(EventConstant.GATEWAY_EVENTS.syncShipLocation, trackingShip.ship_uid);
  };

  useEffect(() => {
    if (turnOnMeasure && clickedShip.info) setClickedShip({ event: null, info: null });
  }, [turnOnMeasure, clickedShip]);

  useEffect(() => {
    if (clickedShip.info) {
      const ship = clickedShip.info;
      createFlickerMarker(ship);
      dispatch(ShipActions.requestTrackingShip({ ...ship, uid: ship.ship_uid, distance: ship.distance }));
      dispatch(
        ShipActions.shipSet({
          trackingShip: {
            distance: ship.distance,
            time: ship.time,
          },
        }),
      );
    } else {
      onCloseAddViolationDialog();
      onCloseAddShipDetailDialog();
      dispatch(ShipActions.shipSet({ trackingShip: {} }));
    }
  }, [clickedShip]);

  useEffect(() => {
    createHoverMarker(hoverShip?.info);
  }, [hoverShip]);

  const isFishingMonitored = trackingShip?.source_type === AppConstant.SOURCE_DATA.gstc;

  const { top, left, bottom, right } = clickedShip.event
    ? getPositionOfPopup(clickedShip.event)
    : { top: 0, left: 0, bottom: 0, right: 0 };

  useEffect(() => {
    const { top, left, bottom, right } = clickedShip.event
      ? getPositionOfPopup(clickedShip.event)
      : { top: 0, left: 0, bottom: 0, right: 0 };
    var elmnt = document.getElementById(id);
    elmnt.style.top = top;
    elmnt.style.left = left;
    elmnt.style.bottom = bottom;
    elmnt.style.right = right;
    console.log(clickedShip.event, top, left, bottom, right);
  }, [clickedShip]);

  const handleTdClick = useCallback(async event => {
    try {
      const text = event.target.innerText;
      if (text) {
        if (navigator.clipboard) {
          // Modern async clipboard API
          await navigator.clipboard.writeText(text);
        } else if (window.clipboardData) {
          // IE specific clipboard API
          window.clipboardData.setData("Text", text);
        } else {
          // Fallback for older browsers
          const textarea = document.createElement("textarea");
          textarea.value = text;
          document.body.appendChild(textarea);
          textarea.select();
          document.execCommand("copy");
          document.body.removeChild(textarea);
        }
        notification.send("Copy thành công", "success");
      }
    } catch (err) {}
  }, []);
  useEffect(() => {
    if (id) {
      dragElement(id);
    }
  }, [id]);

  return (
    <>
      <Box
        id={id}
        style={{ top: top, left: left, bottom: bottom, right: right }}
        className={Boolean(clickedShip.info) ? classes.container : "hidden"}
        component="section"
      >
        <Box
          id={DIALOG_ID + "header"}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "8px",
            cursor: "move",
          }}
        >
          <Typography variant="subtitle2" onClick={handleTdClick} className={classes.copyTd}>
            {isFishingMonitored ? trackingShip?.license_plate : trackingShip?.ship_name}
          </Typography>
          <IconButton size="small" onClick={onClose} className={classes.closeButton}>
            <Close />
          </IconButton>
        </Box>
        {/* TODO: Option nguồn dữ liệu làm sau */}
        {trackingShip && Object.keys(trackingShip).length > 0 && trackingShip.ship_name ? (
          <>
            <table className={classes.popupTable}>
              <tbody>
                <tr>
                  <th className={classes.popupDesc}>Vị trí</th>
                  <td onClick={handleTdClick} className={classes.copyTd} colSpan={3}>
                    {convertCoordinates(Number(trackingShip?.ship_lat), Number(trackingShip?.ship_long))}
                  </td>
                </tr>
                <tr>
                  <th className={classes.popupDesc}>Tốc độ</th>
                  <td onClick={handleTdClick} className={classes.copyTd}>
                    {trackingShip?.speed?.toFixed(2)} knot
                  </td>

                  <th className={classes.popupDesc}>Hướng</th>
                  <td onClick={handleTdClick} className={classes.copyTd}>
                    {Boolean(trackingShip?.course) ? `${trackingShip?.course} độ` : "Không có dữ liệu"}
                  </td>
                </tr>

                {!isFishingMonitored && (
                  <tr>
                    <th className={classes.popupDesc}>Quốc tịch</th>
                    <td onClick={handleTdClick} className={classes.copyTd} colSpan={3}>
                      {trackingShip?.country_code !== null &&
                      trackingShip?.country_code !== "Unknown" &&
                      trackingShip?.country_code !== ""
                        ? countryOptions
                            .filter(member => member.value === trackingShip.country_code)
                            .map(item => item.label)
                        : "Chưa xác định"}
                    </td>
                  </tr>
                )}
                {isFishingMonitored && (
                  <tr>
                    <th className={classes.popupDesc}>Nhà cung cấp</th>
                    <td onClick={handleTdClick} className={classes.copyTd} colSpan={3}>
                      {trackingShip?.device_type}
                    </td>
                  </tr>
                )}
                {!isFishingMonitored && (
                  <tr>
                    <th className={classes.popupDesc}>MMSI</th>
                    <td onClick={handleTdClick} className={classes.copyTd}>
                      {trackingShip?.mmsi_code}
                    </td>

                    <th className={classes.popupDesc}>IMO</th>
                    <td onClick={handleTdClick} className={classes.copyTd}>
                      {trackingShip?.IMO}
                    </td>
                  </tr>
                )}
                {!isFishingMonitored && (
                  <tr>
                    <th className={classes.popupDesc}>Loại tàu</th>
                    <td onClick={handleTdClick} className={classes.copyTd} colSpan={3}>
                      {trackingShip?.ship_type?.name}
                    </td>
                  </tr>
                )}
                {!isFishingMonitored && (
                  <tr>
                    <th className={classes.popupDesc}>Chiều dài</th>
                    <td onClick={handleTdClick} className={classes.copyTd}>
                      {trackingShip?.length}m
                    </td>

                    <th className={classes.popupDesc}>Chiều rộng</th>
                    <td onClick={handleTdClick} className={classes.copyTd}>
                      {trackingShip?.width}m
                    </td>
                  </tr>
                )}
                {!isFishingMonitored && (
                  <>
                    <tr>
                      <th className={classes.popupDesc}>Điểm đến</th>
                      <td onClick={handleTdClick} className={classes.copyTd} colSpan={3}>
                        {trackingShip?.destination !== "CLASS B" && trackingShip?.destination}
                      </td>
                    </tr>
                    <tr>
                      <th className={classes.popupDesc}>E.T.A</th>
                      <td onClick={handleTdClick} className={classes.copyTd} colSpan={3}>
                        {trackingShip?.eta && trackingShip?.eta > 0 && convertMillisecondsToDateTime(trackingShip?.eta)}
                      </td>
                    </tr>
                  </>
                )}
                <tr>
                  <th className={classes.popupDesc}>Thời gian cập nhật</th>
                  <td onClick={handleTdClick} className={classes.copyTd} colSpan={2}>
                    {trackingShip?.time && convertMillisecondsToDateTime(trackingShip?.time)}
                    <br />({getCreatedTime(trackingShip?.time)})
                  </td>
                  <td className={classes.shipUpdateTd} colSpan={1}>
                    <Button
                      size="small"
                      variant="contained"
                      className={classes.shipPopupButton}
                      disableElevation
                      title="Cập nhật vị trí tàu (Nếu có thay đổi)"
                      onClick={updateLocationShip}
                    >
                      <RestartAlt fontSize="small" />
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
            <Box className={classes.buttonWrapper}>
              <Button
                size="small"
                variant="contained"
                className={classes.shipPopupButton}
                disableElevation
                title="Xem thông tin chi tiết của tàu"
                onClick={onOpenAddShipDetailDialog}
              >
                <Info fontSize="small" />
              </Button>
              <Button
                size="small"
                variant="contained"
                className={classes.shipPopupButton}
                disableElevation
                title="Báo cáo tàu vi phạm"
                onClick={onOpenAddViolationDialog}
              >
                <WarningAmber fontSize="small" />
              </Button>
              {hasRoleScopes(AppConstant.SCOPES.LOCATION_HISTORY) && (
                <Button
                  size="small"
                  variant="contained"
                  className={classes.shipPopupButton}
                  disableElevation
                  onClick={() => dispatchTrackingShipEvent(trackingShip)}
                  title="Xem hành trình"
                >
                  <AltRoute fontSize="small" />
                </Button>
              )}
              <Button
                size="small"
                variant="contained"
                className={classes.shipPopupButton}
                disableElevation
                disabled={roleId === AppConstant.ROLE.STAFF}
                title={roleId === AppConstant.ROLE.STAFF ? "Cần quyền admin" : "Thêm vào danh sách giám sát"}
              >
                <Add onClick={() => setIsOpenAddShipToGroup(true)} fontSize="small" />
              </Button>
              <Button
                size="small"
                variant="contained"
                className={classes.shipPopupButton}
                disableElevation
                title={"Tìm kiếm tàu xung quanh"}
              >
                <Search onClick={() => setIsOpenSearchShipsAround(true)} fontSize="small" />
              </Button>
              {AppConstant.CURRENT_SYSTEM_MODE === SystemConstant.SYSTEM_MODE.WAN && (
                <Button
                  size="small"
                  variant="contained"
                  className={classes.shipPopupButton}
                  disableElevation
                  onClick={handleSendSearchJourneyToSGW}
                  title={LangConstant.TXT_SEND_REQUEST_TO_SGW}
                >
                  <Cached fontSize="small" />
                </Button>
              )}
            </Box>
          </>
        ) : (
          <Box className={classes.loadingBox}>
            <CircularProgress />
          </Box>
        )}
      </Box>
      <LayerAddViolationShipDialog
        open={isOpenAddViolationDialog}
        onClose={onCloseAddViolationDialog}
        ship={trackingShip}
      />
      <LayerShipDetailDialog
        open={isOpenAddShipDetailDialog}
        onClose={onCloseAddShipDetailDialog}
        ship={trackingShip}
      />
      <LayerAddTrackingShipDialog
        isOpenAddShipToGroup={isOpenAddShipToGroup}
        setIsOpenAddShipToGroup={setIsOpenAddShipToGroup}
      />
      <LayerSearchShipsAroundDialog
        isOpenSearch={isOpenSearchShipsAround}
        setIsOpenSearch={setIsOpenSearchShipsAround}
      />
    </>
  );
};

export default memo(LayerShipInfoDialog);

const DIALOG_ID = "marker-popup";

const useStyles = makeStyles(() => ({
  container: {
    position: "fixed",
    top: 80,
    right: 8,
    width: 320,
    backgroundColor: "#fff",
    padding: 8,
    borderRadius: 2,
    zIndex: 1000,
    cursor: "auto",
    boxShadow:
      "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
  },

  shipPopup: {
    width: "100%",
    minWidth: 300,
  },

  popupDesc: {
    width: 85,
    padding: "2px 4px",
    fontSize: "14px",
    lineHeight: "18px",
    "& ~ td": {
      padding: "2px 4px",
      fontSize: "14px",
      lineHeight: "22px",
    },
  },

  popupTable: {
    border: "1px solid #999999",
    borderCollapse: "collapse",
    width: "100%",
    marginBottom: 8,
    "& th": {
      border: "1px solid #999999",
      borderCollapse: "collapse",
    },
    "& td": {
      border: "1px solid #999999",
      borderCollapse: "collapse",
    },
    "& tr": {
      border: "1px solid #999999",
      borderCollapse: "collapse",
    },
  },

  popupTitle: {
    margin: "8px 0 !important",
    fontWeight: 600,
    cursor: "move",
  },

  buttonWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 4,
  },

  shipPopupButton: {
    flex: 1,
    height: 34,
    minWidth: "unset",
  },

  closeButton: {
    "& > svg": {
      fontSize: 14,
    },
  },

  loadingBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "40px",
  },

  copyTd: {
    cursor: `url(${CopyClipboard}), auto`,
  },

  shipUpdateTd: {
    textAlign: "center", // Horizontal centering
    verticalAlign: "middle", // Vertical centering
  },
}));
