import { useEffect } from "react";
import { EventBus } from "EventBus";
import { EventConstant, SystemConstant } from "const";
import { ShipService } from "services";
import { useNotification } from "./useNotification";
import { toSnake } from "utils";
import { handleResponse } from "services/config/api.config";

export const useHandleGatewayRequest = () => {
  const notification = useNotification();

  const showNotification = (isSuccess = false) => {
    if (isSuccess) {
      notification.send("Gửi truy vấn lên SGW thành công", "success");
    } else {
      notification.send("Gửi truy vấn lên SGW thất bại", "error");
    }
  };

  const handleSyncShipInfo = async payload => {
    try {
      const response = await ShipService.getShips({ filter: payload, sgw_f: SystemConstant.FLAG_SEND_TO_SGW });
      handleResponse(response);

      showNotification(true);
    } catch (error) {
      console.error(error);
      showNotification(false);
    }
  };

  const handleSyncShipLocation = async payload => {
    try {
      const response = await ShipService.trackingShips(
        toSnake({ end: Date.now(), shipUidList: [payload], sgwF: SystemConstant.FLAG_SEND_TO_SGW }),
      );
      handleResponse(response);

      showNotification(true);
    } catch (error) {
      console.error(error);
      showNotification(false);
    }
  };

  useEffect(() => {
    EventBus.on(EventConstant.GATEWAY_EVENTS.syncShipInfo, handleSyncShipInfo);
    EventBus.on(EventConstant.GATEWAY_EVENTS.syncShipLocation, handleSyncShipLocation);

    return () => {
      EventBus.off(EventConstant.GATEWAY_EVENTS.syncShipInfo, handleSyncShipInfo);
      EventBus.off(EventConstant.GATEWAY_EVENTS.syncShipLocation, handleSyncShipLocation);
    };
  });
};
