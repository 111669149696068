import { ApiConstant, LangConstant } from "const";
import { call, put, select } from "redux-saga/effects";
import { FolderService } from "services";
import FolderAction from "../redux-store/folder.redux";
import { handleOpenLoading, handleSendNotiInSaga } from "utils";
import { saveAs } from "file-saver";

const MESSAGE_ERROR = LangConstant.TXT_MESSAGE_ERROR;

export function* getFolderManager(action) {
  try {
    const { data } = action;
    let response = yield call(FolderService.getFolderList, data);

    if (response.status === ApiConstant.STT_OK) {
      yield put(
        FolderAction.folderSet({
          listFolder: response.data.data,
          totalPageFolder: response.data.total_page,
          totalElementFolder: response.data.total,
          isReGetFolders: false,
        }),
      );
    } else {
      yield put(FolderAction.folderReset());
    }
  } catch (error) {
    yield put(FolderAction.folderReset());
  }
}

export function* uploadFileFolder(action) {
  const { data } = action;
  const { callbackError, ...formData } = data;
  try {
    let response = yield call(FolderService.uploadFileFolder, formData);
    const dataResponse = response.data?.data;
    if (response.status === ApiConstant.STT_OK) {
      const fileList = yield select(state => state.folderRedux.fileList);
      yield put(
        FolderAction.folderSet({
          fileList: fileList?.concat(dataResponse?.success || []),
        }),
      );
      handleSendNotiInSaga(LangConstant.MS_UPLOAD_SUCCESS, "success");
    } else {
      callbackError();
    }
  } catch (error) {
    callbackError();
  }
}

export function* getFolderDetail(action) {
  try {
    const { data } = action;
    let response = yield call(FolderService.getFolderDetail, data);
    const dataResponse = response.data?.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        FolderAction.folderSet({
          folderDetail: dataResponse,
        }),
      );
    } else {
      handleSendNotiInSaga(MESSAGE_ERROR, "error");
    }
  } catch (error) {
    handleSendNotiInSaga(MESSAGE_ERROR, "error");
    console.log(error);
  }
}

export function* deleteFolder(action) {
  try {
    const { data } = action;
    let response = yield call(FolderService.deleteFolder, data);

    if (response.status === ApiConstant.STT_OK) {
      yield put(
        FolderAction.folderSet({
          isReGetFolders: true,
        }),
      );
      handleSendNotiInSaga(LangConstant.TXT_SUCCESS, "success");
    } else {
      handleSendNotiInSaga(MESSAGE_ERROR, "error");
    }
  } catch (error) {
    handleSendNotiInSaga(MESSAGE_ERROR, "error");
    console.log(error);
  }
}
export function* deleteFileFolder(action) {
  const { data: id } = action;
  const fileList = yield select(state => state.folderRedux.fileList);
  yield put(
    FolderAction.folderSet({
      fileList: [...fileList].filter(item => item.uid !== id),
    }),
  );
}

export function* createFolder(action) {
  try {
    const { data } = action;
    let response = yield call(FolderService.createFolder, data);

    if (response.status === ApiConstant.STT_OK) {
      yield put(
        FolderAction.folderSet({
          isReGetFolders: true,
          isOpenCreateFolder: false,
        }),
      );
      handleSendNotiInSaga(LangConstant.MS_CREATE_SUCCESS, "success");
    } else {
      handleSendNotiInSaga(LangConstant.MS_CREATE_FAILED, "error");
    }
  } catch (error) {
    handleSendNotiInSaga(LangConstant.MS_CREATE_FAILED, "error");
    console.log(error);
  }
}

export function* updateFolder(action) {
  try {
    const { data } = action;
    let response = yield call(FolderService.updateFolder, data);

    if (response.status === ApiConstant.STT_OK) {
      yield put(
        FolderAction.folderSet({
          isReGetFolders: true,
          isOpenEditFolder: false,
        }),
      );
      handleSendNotiInSaga(LangConstant.MS_UPDATE_SUCCESS, "success");
    } else {
      handleSendNotiInSaga(LangConstant.MS_UPDATE_FAILED, "error");
    }
  } catch (error) {
    handleSendNotiInSaga(LangConstant.MS_UPDATE_FAILED, "error");
    console.log(error);
  }
}

export function* downloadFolder(action) {
  try {
    const { data } = action;
    let response = yield call(FolderService.downloadFolder, data);

    if (response.status === ApiConstant.STT_OK) {
      saveAs(
        new File([response.data], "FolderBaoCao.zip", {
          type: "application/octet-stream",
        }),
      );
    } else {
      handleSendNotiInSaga(MESSAGE_ERROR, "error");
    }
  } catch (error) {
    handleSendNotiInSaga(MESSAGE_ERROR, "error");
    console.log(error);
  }
}

export function* downloadFilesFolder(action) {
  try {
    const { data } = action;
    let response = yield call(FolderService.downloadFilesFolder, data);

    if (response.status === ApiConstant.STT_OK) {
      saveAs(
        new File([response.data], "FolderBaoCao.zip", {
          type: "application/octet-stream",
        }),
      );
    } else {
      handleSendNotiInSaga(MESSAGE_ERROR, "error");
    }
  } catch (error) {
    handleSendNotiInSaga(MESSAGE_ERROR, "error");
    console.log(error);
  }
}

export function* getFolderNotificationManager(action) {
  try {
    const { data } = action;
    let response = yield call(FolderService.getFolderNotification, data);
    if (response.status === ApiConstant.STT_OK) {
      handleOpenLoading(false);

      yield put(
        FolderAction.folderSet({
          listFolderNotification: response.data.data,
          totalPageFolderNotification: response.data.total_page,
          totalElementFolderNotification: response.data.total,
          is_new: response.data.is_new,
        }),
      );
    } else {
      handleOpenLoading(false);

      yield put(FolderAction.folderReset());
    }
  } catch (error) {
    handleOpenLoading(false);

    yield put(FolderAction.folderReset());
    console.log(error);
  }
}
