import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState } from "react";
import { Backdrop, Button, CircularProgress, Radio, TextField } from "@mui/material";
import { ConversationService } from "services";
import { Download } from "@mui/icons-material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import ConversationRedux from "redux-store/conversation.redux";
import { useDispatch } from "react-redux";
import { FormatConstant, LangConstant } from "const";
import { isDate, isValid, isWithinInterval } from "date-fns";
import { addDays } from "date-fns";
import { now } from "moment";
import { handleSendNotiInSaga } from "utils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Tabhistory = () => {
  const dispatch = useDispatch();

  const [value, setValue] = useState(0);
  const [isShowBackDrop, setIsShowBackDrop] = useState(false);
  const classes = useStyles();
  const [timeShip, setTimeShip] = useState(new Date(new Date().getTime() - 2 * 60 * 60 * 1000));
  const [toTimeShip, setToTimeShip] = useState(new Date());
  const [checkedRadio, setCheckedRadio] = useState(0);

  const onClickExpostFile = () => {
    const warnings = async () => {
      setIsShowBackDrop(true);
      let fileResponse = await ConversationService.exportFileSagaAll({
        type: 2,
        from: new Date(timeShip).getTime(),
        to: new Date(toTimeShip).getTime(),
      });

      if (fileResponse.status === 200) {
        setIsShowBackDrop(false);
        handleSendNotiInSaga(LangConstant.MS_WAITING_EXPORT_FILE, "success");
        dispatch(
          ConversationRedux.getHistory({
            from: now(),
            action: 2,
          }),
        );
      } else if (fileResponse.status === 404) {
        setIsShowBackDrop(false);
        handleSendNotiInSaga(LangConstant.MS_NOT_HAVE_NEW_DATA, "error");
      } else {
        setIsShowBackDrop(false);
        handleSendNotiInSaga(LangConstant.TXT_MESSAGE_ERROR, "error");
      }
    };
    warnings();
  };

  const onClickExpostFile1 = () => {
    const warnings = async () => {
      setIsShowBackDrop(true);
      let fileResponse = await ConversationService.exportFileSagaAll({
        type: 3,
        from: new Date(timeShip).getTime(),
        to: new Date(toTimeShip).getTime(),
      });
      if (fileResponse.status === 200) {
        setIsShowBackDrop(false);
        handleSendNotiInSaga(LangConstant.MS_WAITING_EXPORT_FILE, "success");
        // saveAs(new Blob([fileResponse.data]), "Cảng biển.sql");
        dispatch(
          ConversationRedux.getHistory({
            from: now(),
            action: 3,
          }),
        );
      } else if (fileResponse.status === 404) {
        setIsShowBackDrop(false);
        handleSendNotiInSaga(LangConstant.MS_NOT_HAVE_NEW_DATA, "error");
      } else {
        setIsShowBackDrop(false);
        handleSendNotiInSaga(LangConstant.TXT_MESSAGE_ERROR, "error");
      }
    };
    warnings();
  };

  const onClickExpostFile2 = () => {
    const warnings = async () => {
      setIsShowBackDrop(true);
      let fileResponse = await ConversationService.exportFileSagaAll({
        type: 5,
        from: new Date(timeShip).getTime(),
        to: new Date(toTimeShip).getTime(),
      });
      if (fileResponse.status === 200) {
        setIsShowBackDrop(false);
        handleSendNotiInSaga(LangConstant.MS_WAITING_EXPORT_FILE, "success");
        // saveAs(new Blob([fileResponse.data]), "Hành trình.sql");
        dispatch(
          ConversationRedux.getHistory({
            from: now(),
            action: 5,
          }),
        );
      } else if (fileResponse.status === 404) {
        setIsShowBackDrop(false);
        handleSendNotiInSaga(LangConstant.MS_NOT_HAVE_NEW_DATA, "error");
      } else {
        setIsShowBackDrop(false);
        handleSendNotiInSaga(LangConstant.TXT_MESSAGE_ERROR, "error");
      }
    };
    warnings();
  };

  const onClickExpostFile3 = () => {
    const warnings = async () => {
      setIsShowBackDrop(true);
      let fileResponse = await ConversationService.exportFileSagaAll({
        type: 7,
        from: new Date(timeShip).getTime(),
        to: new Date(toTimeShip).getTime(),
      });
      if (fileResponse.status === 200) {
        setIsShowBackDrop(false);
        handleSendNotiInSaga(LangConstant.MS_WAITING_EXPORT_FILE, "success");
        // saveAs(new Blob([fileResponse.data]), "Tàu vi phạm.sql");
        dispatch(
          ConversationRedux.getHistory({
            from: now(),
            action: 7,
          }),
        );
      } else if (fileResponse.status === 404) {
        setIsShowBackDrop(false);
        handleSendNotiInSaga(LangConstant.MS_NOT_HAVE_NEW_DATA, "error");
      } else {
        setIsShowBackDrop(false);
        handleSendNotiInSaga(LangConstant.TXT_MESSAGE_ERROR, "error");
      }
    };
    warnings();
  };

  const onClickExpostFile4 = () => {
    const warnings = async () => {
      setIsShowBackDrop(true);
      let fileResponse = await ConversationService.exportFileSagaAll({
        type: 1,
        from: new Date(timeShip).getTime(),
        to: new Date(toTimeShip).getTime(),
      });
      if (fileResponse.status === 200) {
        setIsShowBackDrop(false);
        handleSendNotiInSaga(LangConstant.MS_WAITING_EXPORT_FILE, "success");
        // saveAs(new Blob([fileResponse.data]), "Toàn bộ.sql");
        dispatch(
          ConversationRedux.getHistory({
            from: now(),
            action: 1,
          }),
        );
      } else if (fileResponse.status === 404) {
        setIsShowBackDrop(false);
        handleSendNotiInSaga(LangConstant.MS_NOT_HAVE_NEW_DATA, "error");
      } else {
        setIsShowBackDrop(false);
        handleSendNotiInSaga(LangConstant.TXT_MESSAGE_ERROR, "error");
      }
    };
    warnings();
  };

  const onChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (timeShip && toTimeShip && timeShip > toTimeShip) {
      setToTimeShip(new Date(timeShip).setHours(new Date(timeShip).getHours() + 1));
    }

    // Data is limited to a maximum of 10days from starting point
    if (
      toTimeShip &&
      timeShip &&
      isDate(new Date(timeShip)) &&
      !isWithinInterval(toTimeShip, {
        start: new Date(timeShip),
        end: new Date(addDays(timeShip, 10)),
      })
    ) {
      setToTimeShip(addDays(timeShip, 10));
    }
  }, [timeShip, toTimeShip]);

  return (
    <Box className={classes.borderTop} sx={{ width: "100%" }}>
      <Typography variant="h6">Xuất dữ liệu</Typography>
      <Box className={classes.flexTabs}>
        <Tabs
          value={value}
          TabIndicatorProps={{
            style: { display: "none" },
          }}
          onChange={onChange}
          aria-label="basic tabs example"
        >
          {listTab.map(item => (
            <Tab
              key={item.id}
              label={
                <Box onClick={() => setCheckedRadio(item.id)} className={classes.flexRadio}>
                  <Radio checked={Boolean(checkedRadio === item.id) ? true : false} />
                  <Typography>{item.name}</Typography>
                </Box>
              }
              {...a11yProps(item.id)}
            />
          ))}
        </Tabs>
      </Box>

      <Box className={classes.tabhistory}>
        <Box style={{ paddingRight: 10 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label="Từ ngày"
              inputFormat={FormatConstant.FM_DD_MM_YYY_HH_MM}
              onChange={newValue => {
                if (newValue) {
                  setTimeShip(newValue.getTime());
                }
              }}
              value={timeShip}
              maxDate={new Date(toTimeShip)}
              renderInput={params => <TextField size="small" {...params} />}
            />
          </LocalizationProvider>
        </Box>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label="Tới ngày"
            inputFormat={FormatConstant.FM_DD_MM_YYY_HH_MM}
            value={toTimeShip}
            minDate={new Date(timeShip)}
            onChange={newValue => {
              if (newValue) {
                setToTimeShip(newValue.getTime());
              }
            }}
            renderInput={params => <TextField size="small" {...params} />}
          />
        </LocalizationProvider>
      </Box>

      {new Date(timeShip).getTime() >= new Date().getTime() && (
        <Typography className={classes.startTimeError}>Vui lòng chọn thời gian bắt đầu trong quá khứ</Typography>
      )}
      <Box className={classes.buttonPadding}>
        <Button
          style={{ paddingTop: 10 }}
          disabled={
            new Date(timeShip).getTime() >= new Date().getTime() ||
            !timeShip ||
            !isValid(timeShip) ||
            !isValid(toTimeShip)
          }
          onClick={() => {
            switch (value) {
              case 0:
                onClickExpostFile();
                break;

              case 1:
                onClickExpostFile1();
                break;

              case 2:
                onClickExpostFile2();
                break;

              case 3:
                onClickExpostFile3();
                break;

              case 4:
                onClickExpostFile4();
                break;

              default:
                break;
            }
          }}
          color="warning"
          variant="contained"
          type="submit"
          endIcon={<Download />}
        >
          Xuất File
        </Button>
      </Box>
      <Backdrop sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }} open={isShowBackDrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default Tabhistory;

const useStyles = makeStyles(theme => ({
  buttonPadding: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 10,
  },

  tabhistory: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 20,
  },

  flexRadio: {
    display: "flex",
    alignItems: "center",
  },

  flexTabs: {
    display: "flex",
    justifyContent: "center",
  },

  borderTop: {
    borderTop: "2px solid #ccc",
    borderBottom: "2px solid #ccc",
    padding: "10px 0",
  },

  startTimeError: {
    color: "red",
    width: "100%",
    textAlign: "center",
    marginBottom: 10,
  },
}));

const listTab = [
  {
    id: 0,
    name: "tàu thuyền",
  },
  {
    id: 1,
    name: "Cảng biển",
  },
  {
    id: 2,
    name: "Hành trình",
  },
  {
    id: 3,
    name: "Tàu vi phạm",
  },
  {
    id: 4,
    name: "Toàn bộ",
  },
];
