import React from "react";
import { Box, Typography } from "@mui/material";
import { PermissionForm } from "./components";
import { useCreatePermission } from "./hooks";

const CreatePermission = () => {
  const { createPermission } = useCreatePermission();

  return (
    <Box sx={classes.container}>
      <Typography sx={classes.pageTitle} variant="h6">
        Thêm mới nhóm quyền
      </Typography>
      <PermissionForm onSubmit={createPermission} />
    </Box>
  );
};

export default CreatePermission;

const classes = {
  container: {
    padding: "40px",
  },

  pageTitle: {
    marginBottom: "24px",
  },
};
