import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { LangConstant } from "const";

const ConfirmCancelDialog = props => {
  const { open, onClose, isAdd, onCancel } = props;

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={onClose}>
      <DialogTitle>{LangConstant.TXT_CONFIRM_SAVE}</DialogTitle>
      <DialogContent style={{ paddingTop: 10 }}>
        {isAdd ? LangConstant.TXT_CONFIRM_ADD_SAVE : LangConstant.TXT_CONFIRM_EDIT_SAVE}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onCancel}>
          {LangConstant.TXT_YES}
        </Button>
        <Button variant="contained" color="error" onClick={onClose}>
          {LangConstant.TXT_NO}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmCancelDialog;
