import { Box } from "@mui/material";
import TableNotifyUserManagement from "./TableNotifyUserManagement";

const NotifyUser = () => {
  return (
    <Box style={{ padding: 30 }}>
      <TableNotifyUserManagement />
    </Box>
  );
};

export default NotifyUser;
