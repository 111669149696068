import FilterByArea from "./FilterByArea";
import FilterByCapacity from "./FilterByCapacity";
import FilterByDataResource from "./FilterByDataResource";
import FilterByNation from "./FilterByNation";
import FilterByPort from "./FilterByPort";
import FilterByShipType from "./FilterByShipType";
import FilterByLength from "./FilterByLength";
import FilterByTime from "./FilterByTime";

const FilterTypes = [
  {
    id: 1,
    title: "Loại tàu",
    Children: FilterByShipType,
    scrollabel: true,
  },
  {
    id: 2,
    title: "Tải trọng",
    Children: FilterByCapacity,
    scrollabel: false,
  },
  {
    id: 3,
    title: "Theo chiều dài tàu",
    Children: FilterByLength,
    scrollabel: false,
  },
  {
    id: 4,
    title: "Cảng biển",
    Children: FilterByPort,
    scrollabel: true,
  },
  {
    id: 5,
    title: "Quốc gia",
    Children: FilterByNation,
    scrollabel: false,
  },
  {
    id: 6,
    title: "Nguồn dữ liệu",
    Children: FilterByDataResource,
    scrollabel: true,
  },
  {
    id: 8,
    title: "Theo khu vực",
    Children: FilterByArea,
    scrollabel: false,
  },
  {
    id: 9,
    title: "Theo Thời Gian",
    Children: FilterByTime,
    scrollabel: false,
  },
];

export default FilterTypes;
