import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  errorSet: ["error"],
  errorReset: [],
});

export const ErrorTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  status: null,
  error: null,
};

/* ------------- Reducers ------------- */

export const set = (state = INITIAL_STATE, action) => {
  let data = action?.error || {};

  return {
    ...state,
    ...data,
    isFetching: true,
  };
};

export const reset = () => INITIAL_STATE;

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.ERROR_SET]: set,
  [Types.ERROR_RESET]: reset,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
