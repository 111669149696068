import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { LangConstant } from "const";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConversationRedux from "redux-store/conversation.redux";
import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const PopupResetPassword = ({ open, onClose, data }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [newPassword, setNewPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [errPassword, setErrPassword] = useState("");
  const [showPassword] = useState(false);

  const successResestPassword = useSelector(state => state.conversationRedux.successResestPassword);

  const onChangePassword = e => {
    setNewPassword(e.target.value);
  };

  const onChangePasswordConfirmation = e => {
    setPasswordConfirmation(e.target.value);
  };

  const onResetPassword = e => {
    e.preventDefault();
    let isValidated = true;
    const regexPassword = /^(?=.*[0-9])(?=.*[A-Za-z])(?=.*\W)(?!.* )[^\s].{8,32}$/;
    if (!regexPassword.test(newPassword)) {
      setErrPassword("Mật khẩu Nhập tối thiểu 8 ký tự với ít nhất 1 số, chữ thường, chữ hoa và chữ đặc biệt");
      isValidated = false;
    } else if (newPassword !== passwordConfirmation) {
      setErrPassword("Mật khẩu không trùng khớp");
      isValidated = false;
    }
    if (newPassword && isValidated) {
      dispatch(
        ConversationRedux.resetPassword({
          id: data.uid,
          password: newPassword,
        }),
      );
    }
  };

  const onClickButton = () => {
    onClose();
    setErrPassword("");
    setPasswordConfirmation("");
  };

  useEffect(() => {
    if (successResestPassword) onClose();
    dispatch(ConversationRedux.conversationReset());
    setNewPassword("");
    setErrPassword("");
  }, [successResestPassword]);

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Box className={classes.flexTitle}>
          <Typography> {LangConstant.TXT_RESET_PASSWORD}</Typography>
          <IconButton onClick={onClickButton}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent style={{ paddingTop: 10 }}>
        <form id="reset_password" onSubmit={onResetPassword}>
          <Typography className={classes.password}>Mật khẩu mới</Typography>
          <FormControl size="small" fullWidth variant="outlined">
            <OutlinedInput
              placeholder="Mật khẩu mới"
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              onChange={onChangePassword}
              name="password"
            />
          </FormControl>
          {errPassword && <Box style={{ fontSize: 12, color: "red" }}>{errPassword}</Box>}
          <Box pt={2} />
          <Typography className={classes.password}>Nhập lại mật khẩu mới</Typography>
          <FormControl size="small" fullWidth variant="outlined">
            <OutlinedInput
              placeholder="Nhập lại mật khẩu mới"
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              onChange={onChangePasswordConfirmation}
              name="password"
            />
          </FormControl>
          {errPassword && <Box style={{ fontSize: 12, color: "red" }}>{errPassword}</Box>}
          <Box pt={2} />
          <Button
            fullWidth
            variant="contained"
            disabled={newPassword.length > 0 ? "" : Boolean("disable")}
            type="submit"
          >
            {LangConstant.TXT_RESET_PASSWORD}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default PopupResetPassword;

const useStyles = makeStyles(theme => ({
  flexTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));
