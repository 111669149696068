import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PortActions from "redux-store/port.redux";
import ShipActions from "redux-store/ship.redux";
import { makeStyles } from "@mui/styles";
import { Checkbox, FormGroup, FormControlLabel } from "@mui/material";

export default function FilterByPort() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const ports = useSelector(state => state.portRedux.ports);
  const shipConditions = useSelector(state => state.shipRedux.shipConditions);

  const [selected, setSelected] = useState([]);

  const onChangeValue = event => {
    const value = event.target.value;
    const list = [...selected];
    const index = list.indexOf(value);
    index === -1 ? list.push(value) : list.splice(index, 1);
    setSelected(list);

    dispatch(
      ShipActions.shipSet({
        isChangingFilter: true,
        shipConditions: Object.assign(shipConditions, { ports: list }),
      }),
    );
  };

  useEffect(() => {
    dispatch(PortActions.requestGetPorts());
  }, []);

  useEffect(() => {
    setSelected(shipConditions.ports || []);
  }, [shipConditions.ports]);

  return (
    <FormGroup
      classes={{
        root: classes.filterByPortBox,
      }}
    >
      {ports.map(item => (
        <FormControlLabel
          key={item.uid}
          control={<Checkbox value={item.uid} onChange={onChangeValue} checked={selected.includes(item.uid)} />}
          label={item.name}
          classes={{
            label: classes.portName,
          }}
        />
      ))}
    </FormGroup>
  );
}

const useStyles = makeStyles(theme => ({
  filterByPortBox: {
    display: "grid",
    background: "#fff",
    gridTemplateColumns: "auto",
    paddingLeft: "16px",
  },

  portName: {
    fontSize: "14px",
    color: "#000",
    lineHeight: "100%",
    display: "flex",
    alignItems: "center",
    userSelect: "none",
  },
}));
