import { CardMedia } from "@mui/material";
import { StatisticIcon, WarningShipIcon } from "theme/images";
import ArticleIcon from "@mui/icons-material/Article";
import TimelineIcon from "@mui/icons-material/Timeline";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import StatisticByArea from "./StatisticByArea";
import ViolateShipReport from "./ViolateShipReport";
import ShipNumberReport from "./ShipNumberReport";
import ShipInTerritorialReport from "./ShipInTerritorialReport";
import ReportListShipDisconnected from "./ReportListShipDisconnected";
import ShipOutTerritorialReport from "./ShipOutTerritorialReport";
import { AppConstant, LangConstant } from "const";
import ReportListShipLostSignal from "./ReportListShipLostSignal";
import { hasRoleScopes } from "utils";
import ReportByDay from "./ReportByDay";

const useHorizontalBarData = () => {
  const horizontalBarData = [
    {
      id: 1,
      isShow: true,
      title: "Quản lý tàu",
      icon: <DirectionsBoatIcon />,
      submenu: "",
    },
    {
      id: 2,
      isShow: hasRoleScopes(AppConstant.SCOPES.LOCATION_HISTORY),
      title: "Hành trình",
      icon: <TimelineIcon />,
      submenu: "",
    },
    {
      id: 3,
      isShow: true,
      title: "Báo cáo thống kê",
      icon: <ArticleIcon />,
      submenu: [
        {
          id: 32,
          isShow: hasRoleScopes(AppConstant.SCOPES.STATISTIC_VIOLATION_SHIP),
          title: "Báo cáo tàu có nguy cơ vi phạm",
          icon: <CardMedia component="img" image={WarningShipIcon} height="20px" width="20px" />,
          submenu: "",
          Children: ViolateShipReport,
        },
        {
          id: 33,
          isShow: hasRoleScopes(AppConstant.SCOPES.STATISTIC_AREA),
          title: "Thống kê theo khu vực",
          icon: <CardMedia component="img" image={StatisticIcon} height="20px" width="20px" />,
          submenu: "",
          Children: StatisticByArea,
        },
        // {
        //   id: 34,
        //   isShow: hasRoleScopes(AppConstant.SCOPES.STATISTIC_SHIP_AMOUNT),
        //   title: "Thống kê số lượng tàu",
        //   icon: <CardMedia component="img" image={StatisticIcon} height="20px" width="20px" />,
        //   submenu: "",
        //   Children: ShipNumberReport,
        // },
        // {
        //   id: 35,
        //   isShow: hasRoleScopes(AppConstant.SCOPES.STATISTIC_SHIP_AMOUNT_IN),
        //   title: "Thống kê tàu cá trong vùng lãnh hải",
        //   icon: <CardMedia component="img" image={StatisticIcon} height="20px" width="20px" />,
        //   submenu: "",
        //   Children: ShipInTerritorialReport,
        // },
        // {
        //   id: 36,
        //   isShow: hasRoleScopes(AppConstant.SCOPES.STATISTIC_SHIP_AMOUNT_OUT),
        //   title: "Thống kê tàu cá ngoài vùng lãnh hải",
        //   icon: <CardMedia component="img" image={StatisticIcon} height="20px" width="20px" />,
        //   submenu: "",
        //   Children: ShipOutTerritorialReport,
        // },
        {
          id: 37,
          isShow: true,
          title: LangConstant.TXT_REPORT_LIST_SHIP_DISCONNECTED,
          icon: <CardMedia component="img" image={WarningShipIcon} height="20px" width="20px" />,
          submenu: "",
          Children: ReportListShipDisconnected,
        },
        {
          id: 38,
          isShow: true,
          title: LangConstant.TXT_REPORT_LIST_SHIP_LOST_SIGNAL,
          icon: <CardMedia component="img" image={WarningShipIcon} height="20px" width="20px" />,
          submenu: "",
          Children: ReportListShipLostSignal,
        },
        {
          id: 39,
          isShow: true,
          title: LangConstant.TXT_REPORT_BY_DAY,
          icon: <CardMedia component="img" image={WarningShipIcon} height="20px" width="20px" />,
          submenu: "",
          Children: ReportByDay,
        },
      ],
    },
  ];

  return horizontalBarData;
};

export default useHorizontalBarData;
