import { ApiConstant } from "const";
import { createApiWithToken, createApiDownloadExcel } from "services/config/api.config";
import StringFormat from "string-format";

export const getFolderList = data => createApiWithToken().get(ApiConstant.GET_FOLDER, data);
export const getFolderDetail = data => createApiWithToken().get(StringFormat(ApiConstant.DELETE_FOLDER, data));
export const deleteFolder = data => createApiWithToken().delete(StringFormat(ApiConstant.DELETE_FOLDER, data));
export const createFolder = data => createApiWithToken().post(ApiConstant.GET_FOLDER, data);
export const updateFolder = data => {
  const { uid, ...payload } = data;
  return createApiWithToken().put(StringFormat(ApiConstant.DELETE_FOLDER, { uid: uid }), payload);
};
export const uploadFileFolder = data => {
  const { headerConfig, ...payload } = data;
  return createApiWithToken().post(ApiConstant.FOLDER_UPLOAD_FILE, payload.formData, headerConfig);
};

export const downloadFolder = data => {
  return createApiWithToken().get(
    StringFormat(ApiConstant.GET_DETAIL_FOLDER, data),
    { download_f: 1 },
    { responseType: "blob" },
  );
};

export const exportFolderFileReport = data => createApiDownloadExcel().get(StringFormat(ApiConstant.EXPORT_FILE, data));
export const getFolderNotification = data => createApiWithToken().get(ApiConstant.GET_FOLDER_NOTIFICATIONS, data);
export const getFolderNotificationDetail = uid =>
  createApiWithToken().get(StringFormat(ApiConstant.GET_FOLDER_NOTIFICATIONS_DETAIL, { uid }));
export const readAllFolderNotification = data =>
  createApiWithToken().get(ApiConstant.READ_ALL_FOLDER_NOTIFICATIONS, data);

export const downloadFilesFolder = data => {
  const { uid, ...payload } = data;
  return createApiWithToken().post(StringFormat(ApiConstant.DELETE_FOLDER, { uid: uid }), payload, {
    responseType: "blob",
  });
};
