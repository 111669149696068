import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Close, Visibility, VisibilityOff } from "@mui/icons-material";
import StringFormat from "string-format";
import ConversationRedux from "redux-store/conversation.redux";
import { LangConstant } from "const";
import { handleSendNotiInSaga } from "utils";

const PopupConfirm = ({ title = "", isShow, onClose, password, dataEdit = "", statics }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const changePasswordSuccess = useSelector(state => state.conversationRedux.changePasswordSuccess);
  const errChangePassword = useSelector(state => state.conversationRedux.errChangePassword);
  const successDeleteUser = useSelector(state => state.conversationRedux.successDeleteUser);
  const deleteStatistic = useSelector(state => state.conversationRedux.deleteStatistic);

  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [errCurrenPassword, setErrCurrenPassword] = useState("");
  const [errPassword, setErrPassword] = useState("");
  const [valuePassword, setValuePassword] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });

  const onClickShowPassword = () => setShowPassword(show => !show);
  const onClickShowNewPassword = () => setNewPassword(show => !show);
  const onClickShowConfirmPassword = () => setConfirmPassword(show => !show);

  const onChange = event => {
    setValuePassword({
      ...valuePassword,
      [event.target.name]: event.target.value,
    });
  };

  const confirmDeleteUser = () => {
    dispatch(
      ConversationRedux.deleteUser({
        uids: dataEdit.uid,
      }),
    );
  };

  const conFirmDeleteStatic = () => {
    dispatch(
      ConversationRedux.deleteStatistic({
        uid: dataEdit.uid,
      }),
    );
  };

  const onClickCancel = () => {
    onClose();
    setErrPassword("");
    setErrCurrenPassword("");
    setValuePassword("");
  };

  const changePassword = () => {
    let errPasswordName = false;
    let errCurrenPassword = false;
    const regexPassword = /^(?=.*[0-9])(?=.*[A-Za-z])(?=.*\W)(?!.* )[^\s].{8,32}$/;
    if (regexPassword.test(valuePassword.newPassword)) {
      setErrPassword("");
    } else {
      setErrPassword("Nhập tối thiểu 8 ký tự với ít nhất 1 số, chữ thường, chữ hoa hoặc kí tự đặc biệt");
      errPasswordName = true;
    }
    if (valuePassword.password.length <= 8) {
      setErrCurrenPassword("Mật khẩu trên 8 kí tự");
      errCurrenPassword = true;
    } else {
      setErrCurrenPassword("");
    }
    if (
      !errCurrenPassword &&
      !errPasswordName &&
      valuePassword.password &&
      valuePassword.password &&
      valuePassword.newPassword &&
      valuePassword.confirmPassword
    )
      dispatch(
        ConversationRedux.changePassword({
          currentPassword: valuePassword.password,
          newPassword: valuePassword.newPassword,
          reNewPassword: valuePassword.confirmPassword,
        }),
      );
  };

  useEffect(() => {
    if (changePasswordSuccess || successDeleteUser || deleteStatistic) {
      onClose();
      dispatch(ConversationRedux.conversationReset());
    }
    setValuePassword("");
  }, [changePasswordSuccess, successDeleteUser, deleteStatistic]);

  useEffect(() => {
    if (errChangePassword) {
      handleSendNotiInSaga(LangConstant.MS_ERROR_CURRENT_PASSWORD_NOT_MATCH, "error");
    }
    dispatch(
      ConversationRedux.conversationSet({
        errChangePassword: false,
      }),
    );
  }, [errChangePassword]);

  return (
    <Dialog open={isShow} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Box className={classes.titleIcon}>
          <Typography variant="h6">{title}</Typography>
          <IconButton onClick={onClickCancel}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {password ? (
          <Fragment>
            <Box>
              <Typography className={classes.password}>{LangConstant.TXT_PASSWORD_PRESENT}</Typography>
              <FormControl size="small" fullWidth variant="outlined">
                <OutlinedInput
                  placeholder={LangConstant.P_CONFIRM_PASSWORD_PRESENT}
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  onChange={onChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={onClickShowPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  name="password"
                />
                {errCurrenPassword && <Box style={{ fontSize: 12, color: "red" }}>{errCurrenPassword}</Box>}
              </FormControl>
            </Box>
            <Box>
              <Typography className={classes.password}>{LangConstant.TXT_NEW_PASSWORD}</Typography>
              <FormControl size="small" fullWidth variant="outlined">
                <OutlinedInput
                  placeholder={LangConstant.P_NEW_PASSWORD}
                  id="outlined-adornment-password"
                  type={newPassword ? "text" : "password"}
                  onChange={onChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={onClickShowNewPassword} edge="end">
                        {newPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  name="newPassword"
                />
              </FormControl>
            </Box>
            {errPassword && <Box style={{ fontSize: 12, color: "red" }}>{errPassword}</Box>}
            <Box>
              <Typography className={classes.password}>{LangConstant.TXT_CONFIRM_PASSWORD}</Typography>
              <FormControl size="small" fullWidth variant="outlined">
                <OutlinedInput
                  placeholder={LangConstant.L_CONFIRM_PASSWORD}
                  id="outlined-adornment-password"
                  type={confirmPassword ? "text" : "password"}
                  onChange={onChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={onClickShowConfirmPassword}
                        edge="end"
                      >
                        {confirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  name="confirmPassword"
                />
              </FormControl>
            </Box>
            {valuePassword.newPassword === valuePassword.confirmPassword ? (
              ""
            ) : (
              <Typography className={classes.errPassword}>{LangConstant.TXT_PASSWORD_INCORRECT}</Typography>
            )}
          </Fragment>
        ) : (
          <Typography>{StringFormat(LangConstant.FM_CONFIRM_DELETE, dataEdit.name || dataEdit.ship_name)}</Typography>
        )}
      </DialogContent>
      <DialogContent sx={{ paddingTop: 0 }}>
        {statics ? (
          <Button fullWidth onClick={conFirmDeleteStatic}>
            Đồng ý
          </Button>
        ) : password ? (
          <Button fullWidth variant="contained" onClick={changePassword}>
            {LangConstant.TXT_BUTTON_CHANGE_PASSWORD}
          </Button>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "8px" }}>
            <Button fullWidth disableElevation variant="contained" onClick={confirmDeleteUser}>
              Có
            </Button>
            <Button fullWidth disableElevation variant="contained" color="error" onClick={onClickCancel}>
              Không
            </Button>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PopupConfirm;

const useStyles = makeStyles(theme => ({
  inputPassword: {
    display: "flex",
    textAlign: "center",
    alignItems: "center",
  },
  password: {
    fontSize: "16px !important",
    padding: "8px 0 2px",
  },
  titleIcon: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  errPassword: {
    fontSize: "12px !important",
    color: "red",
  },
}));
