import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  TableBody,
  Table,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TableCell,
  TableContainer,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Checkbox,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";
import { LangConstant } from "const";
import ConversationRedux from "redux-store/conversation.redux";
import { debounce } from "lodash";

const PopupUser = ({ title = "", isShow, onClose, onApply, listUser = [] }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userList = useSelector(state => state.conversationRedux.userList);
  const [pagination, setPagination] = useState(1);
  const [searchName, setSearchName] = useState("");
  const panigation1 = useSelector(state => state.conversationRedux.pagination);

  const [listUserSelect, setListUserSelect] = useState(listUser);

  useEffect(() => {
    setSearchName("");
    setListUserSelect(listUser);
  }, [listUser, isShow]);

  const onClickCheckbox = row => {
    if (listUserSelect.find(user => row.uid === user.uid)) {
      setListUserSelect(listUserSelect.filter(item => item.uid !== row.uid));
    } else {
      setListUserSelect([...listUserSelect, row]);
    }
  };

  const onClickCancel = () => {
    onClose();
  };

  useEffect(() => {
    dispatch(
      ConversationRedux.getUserList({
        page: pagination,
        name: searchName,
        size: 100000,
      }),
    );
    dispatch(ConversationRedux.conversationReset());
  }, [pagination, searchName]);

  const handleApply = () => {
    onApply(listUserSelect);
    onClose();
  };
  return (
    <Dialog open={isShow} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box className={classes.titleIcon}>
          <Typography variant="h6">{title}</Typography>
          <IconButton onClick={onClickCancel}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ height: 700, overflow: "auto" }}>
        <TextField
          sx={{ marginY: 2 }}
          onChange={debounce(e => {
            setSearchName(e.target.value);
          }, 500)}
          fullWidth
          size="small"
          id="search_box_user_folder"
          label={LangConstant.L_SEARCH}
          variant="outlined"
          defaultValue={searchName}
        />
        <TableContainer sx={{ height: 600, overflow: "auto" }} component={Paper}>
          <Table stickyHeader sx={{ overflow: "scroll" }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>STT</TableCell>
                <TableCell>{LangConstant.TXT_NAME}</TableCell>
                <TableCell align="center">{LangConstant.TXT_TIME_SYSTEM} </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userList?.map((row, index) => (
                <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <Checkbox
                      checked={listUserSelect.find(user => {
                        return row.uid === user.uid;
                      })}
                      onClick={() => {
                        onClickCheckbox(row);
                      }}
                      color="warning"
                    />
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {(pagination - 1) * panigation1 + index + 1}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.department}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogContent sx={{ paddingTop: 0 }}>
        <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center", gap: "8px" }}>
          <Button
            sx={{ width: "fit-content" }}
            fullWidth
            disableElevation
            variant="contained"
            onClick={handleApply}
            disabled={!listUserSelect.length}
          >
            {LangConstant.TXT_BUTTON_ADD}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PopupUser;
const useStyles = makeStyles(theme => ({
  titleIcon: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));
