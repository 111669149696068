import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConversationRedux from "redux-store/conversation.redux";

const PopupDeleteMany = props => {
  const { open, onClose, checked, data, resetData, setCheckedCheckbox } = props;
  const dispatch = useDispatch();
  const deleteMuchGroupShips = useSelector(state => state.conversationRedux.deleteMuchGroupShips);

  useEffect(() => {
    if (deleteMuchGroupShips) {
      onClose();
    }
    dispatch(
      ConversationRedux.conversationSet({
        deleteMuchGroupShips: false,
      }),
    );
  }, [deleteMuchGroupShips]);

  const onClickDeleteMuch = () => {
    dispatch(
      ConversationRedux.deleteGroupShip({
        uids: [checked],
      }),
    );
    resetData();
    setCheckedCheckbox();
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={onClose}>
      <DialogTitle>Xác nhận xóa</DialogTitle>
      <DialogContent style={{ paddingTop: 10 }}>
        {checked?.length > 1
          ? `Bạn có muốn xóa ${checked?.length} danh sách không?`
          : `Bạn có muốn xóa ${data?.ships} không?`}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickDeleteMuch}>Đồng ý</Button>
        <Button onClick={onClose}>Thoát</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupDeleteMany;
