/* eslint-disable no-useless-escape */
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";
import Cookies from "js-cookie";
import { AppConstant, LangConstant } from "const";
import ConversationRedux from "redux-store/conversation.redux";
import { handleSendNotiInSaga } from "utils";

const EditUserPermissionPopup = ({ open, onClose, dataEdit, disable }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const successEditUser = useSelector(state => state.conversationRedux.successEditUser);
  const errEmailEditUser = useSelector(state => state.conversationRedux.errEmailEditUser);
  const errPermission = useSelector(state => state.conversationRedux.errPermission);

  const [valueInputEdit, setValueInputEdit] = useState({
    name: dataEdit.name,
    email: dataEdit.email,
    department: dataEdit.department,
  });

  const [phoneInput, setPhoneInput] = useState(dataEdit.phoneNumber);
  const [errPhoneInput, setErrPhoneInput] = useState("");
  const [selectPermission, setSelectPermission] = useState(dataEdit.power);

  const onChangeSelect = event => {
    setSelectPermission(event.target.value);
  };

  const onChangeValue = e => {
    setValueInputEdit({ ...valueInputEdit, [e.target.name]: e.target.value });
  };

  const onClickAgree = e => {
    let errEmail = false;
    let errPhoneNumber = false;
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regex.test(valueInputEdit.email)) {
      setValueInputEdit({
        email: "email không hợp lệ",
        name: dataEdit.name,
        phoneNumber: valueInputEdit.phoneNumber,
      });
      errEmail = true;
    } else if (phoneInput && phoneInput.length <= 8) {
      setErrPhoneInput("Số điện thoại trên 8 kí tự");
      errPhoneNumber = true;
    } else if (!errEmail && !errPhoneNumber) {
      dispatch(
        ConversationRedux.editUser({
          id: dataEdit.uid,
          name: valueInputEdit.name,
          email: valueInputEdit.email,
          phoneNumber: phoneInput,
          department: valueInputEdit.department,
          role: selectPermission,
        }),
      );
    }
  };

  const onCloseButton = () => {
    onClose();
    setValueInputEdit("");
  };

  useEffect(() => {
    setSelectPermission(dataEdit?.power);
  }, [dataEdit?.power]);

  useEffect(() => {
    setValueInputEdit({
      name: dataEdit.name,
      email: dataEdit.email,
      phoneNumber: dataEdit.phoneNumber,
      department: dataEdit.department,
    });
    setPhoneInput(dataEdit.phoneNumber);
  }, [dataEdit.email, dataEdit.name, dataEdit.phoneNumber, dataEdit.department]);

  useEffect(() => {
    if (successEditUser) {
      handleSendNotiInSaga(LangConstant.MS_EDIT_SUCCESS, "success");
      onClose();
    }
  }, [successEditUser]);

  useEffect(() => {
    if (errEmailEditUser) {
      handleSendNotiInSaga(LangConstant.MS_ERROR_EMAIL_EXSITED, "error");
    }
  }, [errEmailEditUser]);

  useEffect(() => {
    if (errPermission) {
      handleSendNotiInSaga(LangConstant.MS_ERROR_SELECT_PERMISSION_AGAIN, "error");
    }
  }, [errPermission]);

  return (
    <Dialog open={open} maxWidth="sm" onClose={onClose} fullWidth>
      {disable ? (
        <Fragment>
          <DialogTitle>
            <Box className={classes.flexIcon}>
              <Box>Sửa quyền người dùng</Box>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent style={{ paddingTop: 10 }}>
            <Box style={{ paddingBottom: 10 }}>
              <TextField
                defaultValue={dataEdit.name}
                fullWidth
                size="small"
                label={LangConstant.L_NAME}
                variant="outlined"
                name="name"
                onChange={onChangeValue}
              />
            </Box>
            <Box className={classes.paddingTopInput}>
              <PhoneInput
                country="vn"
                value={dataEdit.phoneNumber || phoneInput}
                onChange={phone => setPhoneInput(phone)}
                onlyCountries={AppConstant.COUNTRY}
                defaultValue={dataEdit?.phoneNumber}
                placeholder=""
              />
              {errPhoneInput && <Box style={{ fontSize: 10, color: "red" }}>Số điện thoại 8 kí tự</Box>}
            </Box>
            <Box>
              <TextField
                defaultValue={dataEdit.email}
                fullWidth
                size="small"
                label={LangConstant.L_EMAIL}
                variant="outlined"
                name="email"
                onChange={onChangeValue}
              />
              {valueInputEdit.email === "email không hợp lệ" && (
                <Box style={{ fontSize: 10, color: "red" }}>{valueInputEdit.email}</Box>
              )}
              <Box style={{ paddingTop: 15 }}>
                <TextField
                  defaultValue={dataEdit.department}
                  fullWidth
                  size="small"
                  label="Phòng ban"
                  variant="outlined"
                  name="department"
                  onChange={onChangeValue}
                />
              </Box>
            </Box>

            <Box style={{ paddingTop: 15 }}>
              <FormControl size="small" fullWidth>
                <InputLabel id="demo-simple-select-label">Quyền người dùng</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectPermission}
                  onChange={onChangeSelect}
                  label="Quyền người dùng"
                >
                  {parseInt(dataEdit.power) <= 1 && (
                    <MenuItem
                      value={1}
                      disabled={
                        !(
                          parseInt(Cookies.get(AppConstant.KEY_ROLE_ID)) < parseInt(dataEdit.power) &&
                          parseInt(Cookies.get(AppConstant.KEY_ROLE_ID)) <= 1
                        )
                      }
                    >
                      Quản trị viên hệ thống
                    </MenuItem>
                  )}
                  <MenuItem
                    value={2}
                    disabled={
                      !(
                        parseInt(Cookies.get(AppConstant.KEY_ROLE_ID)) < parseInt(dataEdit.power) &&
                        parseInt(Cookies.get(AppConstant.KEY_ROLE_ID)) <= 2
                      )
                    }
                  >
                    Quản trị viên phòng
                  </MenuItem>

                  <MenuItem
                    value={3}
                    disabled={
                      !(
                        parseInt(Cookies.get(AppConstant.KEY_ROLE_ID)) < parseInt(dataEdit.power) &&
                        parseInt(Cookies.get(AppConstant.KEY_ROLE_ID)) <= 3
                      )
                    }
                  >
                    Quản lý
                  </MenuItem>

                  <MenuItem
                    value={4}
                    disabled={
                      !(
                        parseInt(Cookies.get(AppConstant.KEY_ROLE_ID)) < parseInt(dataEdit.power) &&
                        parseInt(Cookies.get(AppConstant.KEY_ROLE_ID)) <= 3
                      )
                    }
                  >
                    Nhân viên
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
        </Fragment>
      ) : (
        <Fragment>
          <Box onClick={onClose} className={classes.flexIcon}>
            <IconButton>
              <Close />
            </IconButton>
          </Box>
          <DialogTitle>
            <TextField
              defaultValue={dataEdit.name}
              fullWidth
              size="small"
              label={LangConstant.L_NAME}
              variant="outlined"
              disabled
            />
          </DialogTitle>
          <DialogContent>
            <DialogContent>
              <Box className={classes.paddingTopInput}>
                <TextField
                  defaultValue={dataEdit.calories}
                  fullWidth
                  size="small"
                  label={LangConstant.L_NAME}
                  variant="outlined"
                  disabled
                />
              </Box>
            </DialogContent>
          </DialogContent>
        </Fragment>
      )}
      <DialogActions>
        {disable ? (
          <Button variant="contained" onClick={onClickAgree}>
            {LangConstant.TXT_BUTTON_AGREE}
          </Button>
        ) : (
          <></>
        )}
        <Button onClick={onCloseButton}>{LangConstant.TXT_BUTTON_CANCEL}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUserPermissionPopup;

const useStyles = makeStyles(theme => ({
  containerBody: {
    display: "flex",
    flexDirection: "column",
  },

  paddingTopInput: {
    padding: "5px 0 20px 0",
  },
  errMessenger: {
    fontSize: "12px !important",
    color: "red",
  },
  flexIcon: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 3,
  },
}));
