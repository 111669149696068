import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { StyledEngineProvider } from "@mui/material";
import App from "./App";
import store from "./redux-store";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { NotificationQueueProvider } from "./NotificationQueueProvider";
import "react-phone-input-2/lib/style.css";
import "leaflet-draw";
import "leaflet-mouse-position";
import "leaflet-kmz/dist/leaflet-kmz";
import "leaflet-kml";
import "leaflet-markers-canvas/dist/leaflet-markers-canvas";
import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster.freezable/dist/leaflet.markercluster.freezable";
import "leaflet.motion/dist/leaflet.motion";
import "leaflet/dist/leaflet.css";
import "./theme/styles";

ReactDOM.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <HashRouter>
        <React.StrictMode>
          <SnackbarProvider maxSnack={5} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
            <NotificationQueueProvider>
              <App />
            </NotificationQueueProvider>
          </SnackbarProvider>
        </React.StrictMode>
      </HashRouter>
    </StyledEngineProvider>
  </Provider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.info);
