import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FileDownloadOutlined, KeyboardBackspace } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LangConstant, PathConstant, SystemConstant } from "const";
import { convertMillisecondsToDateTime } from "utils/date.utils";
import UserActionRedux from "redux-store/userAction.redux";
import useUserActionTypeList from "./hooks/useUserActionTypeList";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import UserRedux from "redux-store/user.redux";

const UserActionTable = ({ userId }) => {
  const { actionList } = useUserActionTypeList();

  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const path = useLocation();
  const userInfo = useSelector(state => state.userRedux.userInfo);
  const userActionList = useSelector(state => state.userActionRedux.userActionList);

  const [action, setAction] = useState(SystemConstant.USER_ACTION.ALL);
  const [pagination, setPagination] = useState(1);

  useEffect(() => {
    dispatch(UserRedux.getUserInfo(userId));
    return () => dispatch(UserRedux.userSet({ userInfo: null }));
  }, []);

  const getNameAction = action => {
    return actionList.find(item => action === item.value).label;
  };

  const onChangePagination = (event, value) => {
    setPagination(value);
  };

  const onSelectActionType = event => {
    setPagination(1);
    setAction(event.target.value);
  };

  const onClickDownload = () => {
    dispatch(
      UserActionRedux.exportUserActionListByUser({
        exportF: 1,
        action: action,
        uid: userId,
      }),
    );
  };

  const onHandleBack = () => {
    navigate(PathConstant.ADMIN_USER);
  };

  useEffect(() => {
    dispatch(
      UserActionRedux.getUserActionListByUser({
        page: pagination,
        action: action,
        uid: userId,
      }),
    );
  }, [pagination, action]);

  return (
    <Box sx={{ pr: 2, pl: 2 }}>
      {path.pathname ===
        generatePath(PathConstant.ADMIN_USER_ACTION_PERSONAL, {
          userId: userId || "",
        }) && (
        <Box className={classes.containerTitle}>
          <Button className={classes.buttonBack} onClick={onHandleBack}>
            <KeyboardBackspace />
          </Button>
          <Typography variant="h6">{userInfo?.name}</Typography>
        </Box>
      )}
      <Typography variant="h5" className={classes.titleUserActionTable}>
        {LangConstant.TXT_USER_ACTION}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 2 }}>
        <FormControl size="small" sx={{ width: 250 }}>
          <InputLabel id="select-user-action-type">{LangConstant.TXT_ACTION}</InputLabel>
          <Select
            labelId="select-user-action-type"
            label={LangConstant.TXT_ACTION}
            value={action}
            onChange={onSelectActionType}
          >
            {actionList.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button variant="outlined" startIcon={<FileDownloadOutlined />} onClick={onClickDownload}>
          {LangConstant.L_DOWNLOAD_ACTION}
        </Button>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellHeader}>{LangConstant.TXT_STT}</TableCell>
              <TableCell className={classes.cellHeader}>{LangConstant.TXT_ACTION}</TableCell>
              <TableCell className={classes.cellHeader}>{LangConstant.TXT_TIME}</TableCell>
              <TableCell className={classes.cellHeader}>{LangConstant.TXT_IP_ADDRESS}</TableCell>
              <TableCell className={classes.cellHeader}>{LangConstant.TXT_STATUS}</TableCell>
            </TableRow>
          </TableHead>
          {
            <TableBody>
              {userActionList.data &&
                userActionList.data.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell align="center">{(pagination - 1) * userActionList.size + index + 1}</TableCell>
                      <TableCell align="center">{getNameAction(row.action)}</TableCell>
                      <TableCell align="center">{convertMillisecondsToDateTime(row.created)}</TableCell>
                      <TableCell align="center">{row.ipAddress}</TableCell>
                      <TableCell align="center">
                        {row.status === SystemConstant.STATUS_ACTION.SUCCESS
                          ? LangConstant.TXT_SUCCESS
                          : LangConstant.TXT_FAIL}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          }
        </Table>
      </TableContainer>

      <Box className={classes.panigationPadding}>
        <Pagination
          onChange={onChangePagination}
          sx={{ paddingTop: 2 }}
          count={+userActionList.totalPage}
          variant="outlined"
          page={pagination}
        />
      </Box>
    </Box>
  );
};

export default UserActionTable;

const useStyles = makeStyles({
  cellHeader: {
    fontWeight: "bold",
    textAlign: "center",
  },

  containerTitle: {
    display: "flex",
    marginBottom: "20px",
  },

  buttonBack: {
    color: "black",
    padding: "0px",
    minWidth: "fit-content",
    marginRight: "20px",
  },

  titleUserActionTable: {
    paddingBottom: "15px",
  },
});
