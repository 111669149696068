import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ExpandMore, ExpandLess, Logout, Dashboard } from "@mui/icons-material";
import { AppConstant, LangConstant, PathConstant } from "const";
import { onLogout } from "utils";
import { useMapContext } from "hooks";
import SubMenu from "./SubMenu";
import useHorizontalBarData from "./HorizontalBarData";
import ShipActions from "redux-store/ship.redux";
import ConversationActions from "redux-store/conversation.redux";
import { AuthService } from "services";
import { useNotificationQueue } from "hooks";
import WarningNotification from "./WarningNotification";

export default function HorizontalBar() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const horizontalBarData = useHorizontalBarData();
  const { setNotificationQueue } = useNotificationQueue();
  const wrapperRef = useRef(null);

  const { isOpenStatisticalReport, sidebarMode, setSidebarMode, setIsShowSidebar } = useMapContext();

  const shipConditions = useSelector(state => state.shipRedux.shipConditions);

  const [isOpen, setIsOpen] = useState(false);
  const [idChoose, setIdChoose] = useState(AppConstant.NAVBAR_ITEM_KEY.manageShip);

  useEffect(() => {
    dispatch(
      ShipActions.shipSet({
        shipConditions: Object.assign(shipConditions, { handleGroupMembers: [] }),
      }),
    );
  }, [sidebarMode]);

  useEffect(() => {
    setIsOpen(false);
  }, [isOpenStatisticalReport]);

  const onClickChangeSideBar = (e, id) => {
    setIdChoose(id);
    const buttons = document.querySelectorAll(".navbar-button-uniq");
    buttons.forEach(button => button.classList.remove("active"));
    e.currentTarget.classList.add("active");
    if (id === AppConstant.NAVBAR_ITEM_KEY.manageShip) {
      setSidebarMode("management");
    } else if (id === AppConstant.NAVBAR_ITEM_KEY.trackingShip) {
      setSidebarMode("tracking");
      setIsShowSidebar(true);
    }
  };

  const onClickOpenSubMenu = id => {
    setIsOpen(prev => !prev);
    setIdChoose(id);
  };

  const onOpenAdminDashboard = () => {
    navigate(PathConstant.ADMIN_ROLE_MANAGEMENT);
  };

  const onClickLogout = () => {
    AuthService.logout();
    dispatch(ConversationActions.conversationReset());
    dispatch(ConversationActions.conversationSet({ isLoginSuccess: false }));
    setNotificationQueue([]);
    onLogout();
  };

  return (
    <Box className={classes.horizontalBarContainer}>
      <Box className={classes.rightMenuBox}>
        {horizontalBarData.map(item => (
          <Box key={item.id} ref={wrapperRef}>
            {item.submenu
              ? item.isShow && (
                  <Button
                    variant="outlined"
                    startIcon={item.icon}
                    endIcon={isOpen && item.id === idChoose ? <ExpandLess /> : <ExpandMore />}
                    onClick={() => onClickOpenSubMenu(item.id)}
                  >
                    <Typography className={classes.buttonTitle}>{item.title}</Typography>
                  </Button>
                )
              : item.isShow && (
                  <Button
                    variant="outlined"
                    startIcon={item.icon}
                    onClick={e => onClickChangeSideBar(e, item.id)}
                    classes={{
                      root: clsx(classes.button, "navbar-button-uniq", "navbar-button-uniq-" + item.id, {
                        active:
                          (item.id === AppConstant.NAVBAR_ITEM_KEY.manageShip && sidebarMode === "management") ||
                          (item.id === AppConstant.NAVBAR_ITEM_KEY.trackingShip && sidebarMode === "tracking"),
                      }),
                    }}
                  >
                    <Typography className={classes.buttonTitle}>{item.title}</Typography>
                  </Button>
                )}
            {isOpen && item.id === idChoose ? item.submenu && <SubMenu items={item.submenu} /> : <></>}
          </Box>
        ))}

        <Button variant="outlined" startIcon={<Dashboard />} onClick={onOpenAdminDashboard}>
          <Typography className={classes.buttonTitle}>{LangConstant.TXT_MANAGE_SYSTEM}</Typography>
        </Button>
        <WarningNotification />
        <IconButton onClick={onClickLogout} title="Đăng xuất">
          <Logout />
        </IconButton>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  horizontalBarContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "80vw",
    padding: "12px 0",
  },

  leftMenuBox: {
    display: "flex",
    justifyContent: "flex-start",
    width: "50%",
    alignItems: "center",
    position: "relative",
  },

  rightMenuBox: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: "16px",
    gap: "16px",
    "& button.active": {
      background: "#1976d2",
      color: "#fff",
    },
  },

  buttonTitle: {
    fontSize: "15px",
    fontWeight: "500",
  },

  button: {
    justifyContent: "flex-start",
    margin: "4px 0",
  },

  startIconBox: {
    color: "#000",
  },

  endIconBox: {
    color: "#000",
  },

  logoutButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  bell: {
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
}));
