import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import countryList from "react-select-country-list";
import { getContry } from "utils";

const ViewDetailsWatchList = ({ dataView, open, onClose }) => {
  const getTypeShip = useSelector(state => state.conversationRedux.getTypeShip);
  const options = useMemo(() => countryList().getData(), []);
  const classes = useStyles();

  const [name, setName] = useState("");

  useEffect(() => {
    const filterShipName =
      getTypeShip && getTypeShip.filter(itemShip => itemShip.type === dataView.type).map(itemShip => itemShip.name);
    setName(filterShipName[0]);
  }, [dataView.type, getTypeShip]);

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullWidth
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Xem thông tin chi tiết nhóm tàu </DialogTitle>
      <DialogContent>
        <Box className={classes.paddingTop}>
          <TextField disabled fullWidth size="small" label="Tên tàu" defaultValue={dataView?.name} variant="outlined" />
        </Box>
        <Box className={classes.paddingItems}>
          <TextField disabled fullWidth size="small" label="ID Tàu" defaultValue={dataView?.uid} variant="outlined" />
        </Box>
        <TextField disabled fullWidth size="small" defaultValue={dataView?.imo} label="IMO" variant="outlined" />
        <Box className={classes.paddingItems}>
          <TextField
            disabled
            fullWidth
            size="small"
            defaultValue={dataView?.mmsi_code}
            label="MMSI"
            variant="outlined"
          />
        </Box>
        <TextField
          disabled
          fullWidth
          size="small"
          defaultValue={dataView?.call_sign}
          label="Hô hiệu"
          variant="outlined"
        />
        <Box className={classes.paddingItems}>
          <TextField disabled fullWidth size="small" value={name} label="Loại tàu" variant="outlined" />
        </Box>
        <TextField
          disabled
          fullWidth
          size="small"
          defaultValue={dataView?.plate_licence}
          label="Nhà cung cấp"
          variant="outlined"
        />
        <Box className={classes.paddingItems}>
          <TextField
            disabled
            fullWidth
            size="small"
            defaultValue={
              dataView.country_code === "Unknown"
                ? "Không xác định"
                : getContry(options, dataView?.ship_detail?.country_code).map(item => item.label)
            }
            label="Quốc tịch"
            variant="outlined"
          />
        </Box>
        <TextField
          disabled
          fullWidth
          size="small"
          defaultValue={dataView?.created && format(dataView?.created, "dd/MM/yyyy")}
          label="Thời gian tạo"
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Thoát</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewDetailsWatchList;

const useStyles = makeStyles(theme => ({
  paddingItems: {
    padding: "15px 0",
  },
  paddingTop: {
    paddingTop: 10,
  },
}));
