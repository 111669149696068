import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserService } from "services";
import { toSnake } from "utils";
import { PAGE_SIZE } from "../components/AddUserDialog";
import { useNotification } from "hooks";

export const useSearchUser = () => {
  const notification = useNotification();
  const { permissionGroupUid } = useParams();

  const [searchingUsers, setSearchingUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  const searchUser = async (name, page = 1, size = PAGE_SIZE) => {
    try {
      const permissionGroupF = permissionGroupUid ? 0 : 1;
      const response = await UserService.filterUsers(
        toSnake({ name, page, size, permissionGroupF, permissionGroupUid }),
      );
      if (response.ok && response.data) {
        setSearchingUsers(response.data.data);
        setTotalPages(response.data.total_page);
        setTotalItems(response.data.total);
      } else {
        setSearchingUsers([]);
        notification.send("Đã có lỗi xảy ra, vui lòng thử lại sau", "error");
      }
    } catch (error) {
      console.error(error);
      setSearchingUsers([]);
      notification.send("Đã có lỗi xảy ra, vui lòng thử lại sau", "error");
    }
  };

  useEffect(() => {
    searchUser("");
  }, []);

  return { searchingUsers, totalPages, totalItems, searchUser };
};
