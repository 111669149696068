import * as React from "react";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import TableShipManagement from "./TableShipManagement";

const ShipManagement = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <TableShipManagement />
    </Box>
  );
};

export default ShipManagement;

const useStyles = makeStyles(theme => ({
  container: {
    padding: 30,
  },
}));
