/**
 * Saga index: connects action type and saga
 */

import { all, takeLatest } from "redux-saga/effects";
import { ConversationTypes } from "../redux-store/conversation.redux";
import {
  changePassword,
  createGroupShip,
  createHarbors,
  createMorePort,
  createShip,
  createUser,
  deleteGroupDetailShip,
  deleteGroupShip,
  deleteHarbors,
  deleteListShip,
  deleteStatistic,
  deleteUser,
  editUser,
  exportFile,
  getDetailGroup,
  getGroupShip,
  getGroupShipDetail,
  getHarbors,
  getHistory,
  getListDeatails,
  getListShip,
  getListShipWatch,
  getListUsers,
  getPortManager,
  getShipType,
  getStatistic,
  importFile,
  resetPassword,
  updateHarbors,
  updateShip,
  updateShipGroup,
  updateStatistic,
  uploadFile,
} from "./conversation.saga";
import { AuthTypes } from "../redux-store/auth.redux";
import { loginPage, sendOtp, verifyOtp, loginTrios, linkTrios, linkTriosSubmit } from "./auth.saga";
import { ShipTypes } from "redux-store/ship.redux";
import {
  addViolationShip,
  deleteViolationShip,
  editViolationShip,
  requestFilterShips,
  getCrawlDataHistory,
  getFilterShipInDataTable,
  getShipTypes,
  getTrackingShips,
  searchShipsByCondition,
  searchShipsInDialog,
  trackingShip,
  trackingShips,
  getShipInArea,
  getCrawlShipUpdate,
  getListShipLost,
  getListShipByDay,
  getShipsAround,
} from "./ship.saga";
import { PortTypes } from "redux-store/port.redux";
import { deletePorts, getPorts, updatePort } from "./port.saga";
import { AreaTypes } from "redux-store/area.redux";
import { WeatherTypes } from "redux-store/weather.redux";
import { getWeatherData } from "./weather.saga";
import {
  createCustomArea,
  deleteCustomArea,
  getCustomArea,
  getKmlFiles,
  getDefaultArea,
  updateCustomArea,
  uploadKmlFile,
  getTypeArea,
  createPointArea,
  getPointArea,
  updatePointArea,
  deletePointArea,
  getWeatherPoint,
} from "./area.saga";
import { ReportTypes } from "redux-store/report.redux";
import { getReportByType } from "sagas/report.saga";
import { ManagementTypes } from "redux-store/management.redux";

import { UserTypes } from "redux-store/user.redux";
import { getUserInfo, exportUsersInfo, exportUserInfo, changeAvatar, changeTwoFA } from "./user.saga";
import { raiseError } from "./error.saga";
import { getModule, getRolePermission } from "./management.saga";
import { UserActionTypes } from "redux-store/userAction.redux";
import {
  exportSummaryAction,
  exportUserActionListAllUser,
  exportUserActionListByUser,
  getSummaryAction,
  getTotalAction,
  getUserActionListAllUser,
  getUserActionListByUser,
} from "./userAction.saga";

import { EventWarningTypes } from "redux-store/eventwarning.redux";
import { FolderTypes } from "redux-store/folder.redux";
import {
  deleteFileFolder,
  getFolderManager,
  uploadFileFolder,
  createFolder,
  updateFolder,
  deleteFolder,
  getFolderDetail,
  downloadFolder,
  getFolderNotificationManager,
  downloadFilesFolder,
} from "./folder.saga";
import {
  getEventWarningManager,
  changeNotifyOption,
  deleteEventWarning,
  createEventWarning,
  updateEventWarning,
  getEventWarningNotificationManager,
  getTopEventWarningNotificationManager,
  exportEventWarningNotification,
} from "./eventwarning.saga";

/* ------------- Types ------------- */

/* ------------- Sagas ------------- */

/* ------------- Connect Types To Sagas ------------- */
export default function* root() {
  // yield call(watchRequests);
  // eslint-disable-next-line no-sparse-arrays
  yield all([
    // Conversation
    takeLatest(ConversationTypes.CREATE_USER, createUser),
    takeLatest(ConversationTypes.DELETE_USER, deleteUser),
    takeLatest(ConversationTypes.CHANGE_PASSWORD, changePassword),
    takeLatest(ConversationTypes.EDIT_USER, editUser),
    takeLatest(ConversationTypes.GET_HISTORY, getHistory),
    takeLatest(ConversationTypes.GET_USER_LIST, getListUsers),
    takeLatest(ConversationTypes.RESET_PASSWORD, resetPassword),
    takeLatest(ConversationTypes.CREATE_SHIP, createShip),
    takeLatest(ConversationTypes.GET_LIST_SHIP, getListShip),
    takeLatest(ConversationTypes.GET_SHIP_TYPE, getShipType),
    takeLatest(ConversationTypes.DELETE_LIST_SHIP, deleteListShip),
    takeLatest(ConversationTypes.UPDATE_SHIP, updateShip),
    takeLatest(ConversationTypes.CREATE_SHIP_GROUP, createGroupShip),
    takeLatest(ConversationTypes.GET_HARBORS, getHarbors),
    takeLatest(ConversationTypes.CREATE_HARBORS, createHarbors),
    takeLatest(ConversationTypes.CREATE_MORE_PORT, createMorePort),
    takeLatest(ConversationTypes.GET_PORT_MANAGER, getPortManager),
    takeLatest(ConversationTypes.DELETE_HARBORS, deleteHarbors),
    takeLatest(ConversationTypes.UPDATE_HARBOR_SHIP, updateHarbors),
    takeLatest(ConversationTypes.GET_GROUP_SHIP, getGroupShip),
    takeLatest(ConversationTypes.GET_LIST_SHIP_WATCH, getListShipWatch),
    takeLatest(ConversationTypes.GET_LIST_DETAILS, getListDeatails),

    takeLatest(ConversationTypes.GET_DETAILS_GROUP, getDetailGroup),
    takeLatest(ConversationTypes.GETSTATISTIC, getStatistic),
    takeLatest(ConversationTypes.UPDATE_STATISTIC, updateStatistic),
    takeLatest(ConversationTypes.DELETE_STATISTIC, deleteStatistic),

    takeLatest(ConversationTypes.DELETE_GROUP_SHIP, deleteGroupShip),
    takeLatest(ConversationTypes.UPDATE_GROUP_SHIP, updateShipGroup),
    takeLatest(ConversationTypes.DELETE_GROUP_DETAIL_SHIP, deleteGroupDetailShip),
    takeLatest(ConversationTypes.UPLOAD_FILE, uploadFile),
    takeLatest(ConversationTypes.EXPORT_FILE, exportFile),
    takeLatest(ConversationTypes.IMPORT_FILE, importFile),
    takeLatest(ConversationTypes.GET_GROUP_SHIP_DETAIL, getGroupShipDetail),

    //Auth
    takeLatest(AuthTypes.LOGIN_PAGE, loginPage),
    takeLatest(AuthTypes.SEND_OTP, sendOtp),
    takeLatest(AuthTypes.VERIFY_OTP, verifyOtp),
    takeLatest(AuthTypes.LOGIN_TRIOS, loginTrios),
    takeLatest(AuthTypes.LINK_TRIOS, linkTrios),
    takeLatest(AuthTypes.LINK_TRIOS_SUBMIT, linkTriosSubmit),

    // User
    takeLatest(UserTypes.GET_USER_INFO, getUserInfo),
    takeLatest(UserTypes.EXPORT_USERS_INFO, exportUsersInfo),
    takeLatest(UserTypes.EXPORT_USER_INFO, exportUserInfo),
    takeLatest(UserTypes.CHANGE_AVATAR, changeAvatar),
    takeLatest(UserTypes.CHANGE_TWO_FA, changeTwoFA),

    // Ships
    takeLatest(ShipTypes.REQUEST_GET_SHIP_TYPES, getShipTypes),
    takeLatest(ShipTypes.REQUEST_SEARCH_SHIPS, searchShipsByCondition),
    takeLatest(ShipTypes.REQUEST_GET_TRACKING_SHIPS, getTrackingShips),
    takeLatest(ShipTypes.REQUEST_FILTER_SHIPS, requestFilterShips),
    takeLatest(ShipTypes.REQUEST_TRACKING_SHIPS, trackingShips),
    takeLatest(ShipTypes.REQUEST_TRACKING_SHIP, trackingShip),
    takeLatest(ShipTypes.GET_CRAWL_DATA_HISTORY, getCrawlDataHistory),
    takeLatest(ShipTypes.REQUEST_FILTER_SHIPS_IN_DIALOG, searchShipsInDialog),
    takeLatest(ShipTypes.REQUEST_ADD_VIOLATION_SHIP, addViolationShip),
    takeLatest(ShipTypes.REQUEST_DELETE_VIOLATION_SHIP, deleteViolationShip),
    takeLatest(ShipTypes.REQUEST_EDIT_VIOLATION_SHIP, editViolationShip),
    takeLatest(ShipTypes.REQUEST_GET_FILTER_SHIP_IN_DATA_TABLE, getFilterShipInDataTable),
    takeLatest(ShipTypes.GET_SHIP_IN_AREA, getShipInArea),
    takeLatest(ShipTypes.GET_CRAWL_SHIP_UPDATE, getCrawlShipUpdate),
    takeLatest(ShipTypes.GET_LIST_SHIP_LOST, getListShipLost),
    takeLatest(ShipTypes.GET_LIST_SHIP_BY_DAY, getListShipByDay),
    takeLatest(ShipTypes.GET_SHIPS_AROUND, getShipsAround),

    // Ports
    takeLatest(PortTypes.REQUEST_GET_PORTS, getPorts),
    takeLatest(PortTypes.DELETE_PORTS, deletePorts),
    takeLatest(PortTypes.UPDATE_PORT, updatePort),

    // Area
    takeLatest(AreaTypes.REQUEST_GET_DEFAULT_AREA, getDefaultArea),
    takeLatest(AreaTypes.REQUEST_GET_CUSTOM_AREA, getCustomArea),
    takeLatest(AreaTypes.REQUEST_GET_KML_FILE, getKmlFiles),
    takeLatest(AreaTypes.GET_TYPE_AREA, getTypeArea),
    takeLatest(AreaTypes.REQUEST_CREATE_CUSTOM_AREA, createCustomArea),
    takeLatest(AreaTypes.REQUEST_CREATE_POINT_AREA, createPointArea),
    takeLatest(AreaTypes.REQUEST_UPDATE_POINT_AREA, updatePointArea),
    takeLatest(AreaTypes.DELETE_POINT_AREA, deletePointArea),
    takeLatest(AreaTypes.REQUEST_GET_POINT_AREA, getPointArea),
    takeLatest(AreaTypes.REQUEST_UPDATE_CUSTOM_AREA, updateCustomArea),
    takeLatest(AreaTypes.REQUEST_DELETE_CUSTOM_AREA, deleteCustomArea),
    takeLatest(AreaTypes.UPLOAD_KML_FILE, uploadKmlFile),
    takeLatest(AreaTypes.GET_WEATHER_POINT, getWeatherPoint),

    // Weather
    takeLatest(WeatherTypes.GET_WEATHER_DATA, getWeatherData),

    // Report
    takeLatest(ReportTypes.GET_REPORT_BY_TYPE, getReportByType),

    // Management
    takeLatest(ManagementTypes.GET_MODULE, getModule),
    takeLatest(ManagementTypes.GET_PERMISSION, getRolePermission),

    takeLatest([PortTypes.PORT_SET, ShipTypes.SHIP_SET, ManagementTypes.MANAGEMENT_SET], raiseError),

    //UserAction
    takeLatest(UserActionTypes.GET_USER_ACTION_LIST_BY_USER, getUserActionListByUser),
    takeLatest(UserActionTypes.EXPORT_USER_ACTION_LIST_BY_USER, exportUserActionListByUser),
    takeLatest(UserActionTypes.GET_USER_ACTION_LIST_ALL_USER, getUserActionListAllUser),
    takeLatest(UserActionTypes.EXPORT_USER_ACTION_LIST_ALL_USER, exportUserActionListAllUser),
    takeLatest(UserActionTypes.GET_SUMMARY_ACTION, getSummaryAction),
    takeLatest(UserActionTypes.EXPORT_SUMMARY_ACTION, exportSummaryAction),
    takeLatest(UserActionTypes.GET_TOTAL_ACTION, getTotalAction),

    // Event Warning
    takeLatest(EventWarningTypes.GET_EVENT_WARNINGS, getEventWarningManager),
    takeLatest(EventWarningTypes.CHANGE_NOTIFY_OPTION, changeNotifyOption),
    takeLatest(EventWarningTypes.DELETE_EVENT_WARNING, deleteEventWarning),
    takeLatest(EventWarningTypes.CREATE_EVENT_WARNING, createEventWarning),
    takeLatest(EventWarningTypes.UPDATE_EVENT_WARNING, updateEventWarning),
    takeLatest(EventWarningTypes.GET_EVENT_WARNING_NOTIFICATIONS, getEventWarningNotificationManager),
    takeLatest(EventWarningTypes.GET_TOP_EVENT_WARNING_NOTIFICATIONS, getTopEventWarningNotificationManager),
    takeLatest(EventWarningTypes.EXPORT_EVENT_WARNING_NOTIFICATIONS, exportEventWarningNotification),

    // Folder
    takeLatest(FolderTypes.GET_FOLDERS, getFolderManager),
    takeLatest(FolderTypes.UPLOAD_FILE_FOLDER, uploadFileFolder),
    takeLatest(FolderTypes.DELETE_FILE_FOLDER, deleteFileFolder),
    takeLatest(FolderTypes.CREATE_FOLDER, createFolder),
    takeLatest(FolderTypes.UPDATE_FOLDER, updateFolder),
    takeLatest(FolderTypes.DELETE_FOLDER, deleteFolder),
    takeLatest(FolderTypes.GET_FOLDER, getFolderDetail),
    takeLatest(FolderTypes.DOWNLOAD_FOLDER, downloadFolder),
    takeLatest(FolderTypes.GET_FOLDER_NOTIFICATIONS, getFolderNotificationManager),
    takeLatest(FolderTypes.DOWNLOAD_FILES_FOLDER, downloadFilesFolder),
  ]);
}
