import { Box } from "@mui/material";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CreateEventWarning from "./CreateEventWarning";
import EditEventWarning from "./EditEventWarning";
import TableEventWarningManagement from "./TableEventWarningManagement";
import EventWarningAction from "redux-store/eventwarning.redux";

const EventWarningManagement = () => {
  const dispatch = useDispatch();
  const isOpenCreate = useSelector(state => state.eventWarningRedux.isOpenCreateEventWarning);
  const isOpenEdit = useSelector(state => state.eventWarningRedux.isOpenEditEventWarning);

  useEffect(() => {
    return () => {
      dispatch(
        EventWarningAction.eventWarningSet({
          isOpenCreateEventWarning: false,
          isOpenEditEventWarning: false,
        }),
      );
    };
  }, []);

  return (
    <Box style={{ padding: 30 }}>
      {!isOpenCreate && !isOpenEdit && <TableEventWarningManagement />}
      {isOpenCreate && <CreateEventWarning />}
      {isOpenEdit && <EditEventWarning />}
    </Box>
  );
};

export default EventWarningManagement;
