import { ApiConstant, AppConstant } from "const";
import apisauce from "apisauce";
import Cookies from "js-cookie";
import { set, INITIAL_STATE } from "redux-store/error.redux";
import { AuthService } from "services";
import { handlingLogin, handleSendNotiInSaga } from "utils";

export const API_CONFIG_REG = {
  baseURL: ApiConstant.API_BASE_URL,
  headers: ApiConstant.API_HEADER_DEFAULT,
  timeout: ApiConstant.API_TIMEOUT,
};

export const EXPORT_EXCEL_CONFIG = {
  baseURL: ApiConstant.API_BASE_URL,
  headers: ApiConstant.API_HEADER_DEFAULT,
  timeout: ApiConstant.API_TIMEOUT,
};

export const API_CONFIG_DOWNLOAD_EXCEL = {
  baseURL: ApiConstant.API_BASE_URL,
  headers: ApiConstant.API_HEADER_DEFAULT,
  timeout: ApiConstant.API_TIMEOUT,
  responseType: "blob",
};

export const API_CONFIG_UPLOAD_FILE = {
  baseURL: ApiConstant.API_BASE_URL,
  timeout: ApiConstant.API_TIMEOUT,
  headers: {
    ...ApiConstant.API_HEADER_DEFAULT,
    Accept: "application/json",
  },
};

export const createApiWithToken = (initConfig = API_CONFIG_REG, token = null) => {
  let appToken = token || Cookies.get(AppConstant.KEY_ACCESS_TOKEN);
  if (appToken) initConfig.headers.Authorization = `Bearer ${appToken}`;
  let api = apisauce.create(initConfig);

  api.addRequestTransform(request => {
    if (request.url.endsWith(".png") || request.url.endsWith(".jpg") || request.url.endsWith(".jpeg")) {
      // Cache for 1 day
      request.headers["Cache-Control"] = "max-age=86400";
    }
    if (request.url.includes("2.base.maps.ls.hereapi.com")) {
      // Cache for 1 week
      request.headers["Cache-Control"] = "max-age=604800";
    }
    if (request.url.includes("clustered1")) {
      // Cache for 1 day
      request.headers["Cache-Control"] = "public, max-age=3600";
    }
  });

  api.addResponseTransform(response => {
    if (response.config.url !== ApiConstant.LOGIN_PAGE && response.config.url !== ApiConstant.REFRESH_TOKEN) {
      if (response.status === ApiConstant.STT_FORBIDDEN) {
        let appRefreshToken = Cookies.get(AppConstant.KEY_REFRESH_TOKEN);
        handleRefreshToken(appToken, appRefreshToken, response);
      }
      if (response.status === ApiConstant.STT_UNAUTHORIZED) {
        Object.assign(INITIAL_STATE, response.data);
        set(INITIAL_STATE, { error: response.data });
      }
    }
  });

  return api;
};
const handleRefreshToken = async (appToken, appRefreshToken, response) => {
  const respRefresh = await AuthService.refreshTokenSaga({ access_token: appToken, refresh_token: appRefreshToken });
  if (respRefresh.status === ApiConstant.STT_OK) {
    const dataLogin = respRefresh.data?.data;
    handlingLogin(dataLogin);
    handleSendNotiInSaga("Tài khoản đã được cập nhật nhóm quyền, vui lòng tải lại trang để tiếp tục", "info");
  }
};

export const createApiDownloadExcel = () => createApiWithToken(API_CONFIG_DOWNLOAD_EXCEL);

export const createApiUploadFile = () => createApiWithToken(API_CONFIG_UPLOAD_FILE);

export const handleResponse = response => {
  switch (response.status) {
    case ApiConstant.STT_OK:
      return response.data;

    case ApiConstant.STT_FORBIDDEN:
    case ApiConstant.STT_BAD_REQUEST:
    case ApiConstant.STT_NOT_FOUND:
    default:
      throw response;
  }
};
