import { createStore, applyMiddleware, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas";
import * as ConversationRedux from "./conversation.redux";
import * as AuthRedux from "./auth.redux";
import * as MapRedux from "./map.redux";
import * as ShipRedux from "./ship.redux";
import * as PortRedux from "./port.redux";
import * as AreaRedux from "./area.redux";
import * as CommonTypes from "./common.redux";
import * as WeatherTypes from "./weather.redux";
import * as ReportTypes from "./report.redux";
import * as ErrorTypes from "./error.redux";
import * as ManagementTypes from "./management.redux";
import * as UserTypes from "./user.redux";
import * as UserActionRedux from "./userAction.redux";
import * as EventWarningRedux from "./eventwarning.redux";
import * as FolderRedux from "./folder.redux";

/* ------------- Assemble The Reducers ------------- */
export const appReducer = combineReducers({
  conversationRedux: ConversationRedux.reducer,
  authRedux: AuthRedux.reducer,
  mapRedux: MapRedux.reducer,
  shipRedux: ShipRedux.reducer,
  portRedux: PortRedux.reducer,
  areaRedux: AreaRedux.reducer,
  commonRedux: CommonTypes.reducer,
  weatherRedux: WeatherTypes.reducer,
  reportRedux: ReportTypes.reducer,
  errorRedux: ErrorTypes.reducer,
  managementRedux: ManagementTypes.reducer,
  userActionRedux: UserActionRedux.reducer,
  userRedux: UserTypes.reducer,
  eventWarningRedux: EventWarningRedux.reducer,
  folderRedux: FolderRedux.reducer,
});

export const rootReducer = (state, action) => {
  // Action logout
  // if (action.type === KeyConstant.LOGOUT_REQUEST) {
  //   state = undefined;
  // }
  return appReducer(state, action);
};

/* ------------- Redux Configuration ------------- */

/* ------------- Saga Middleware ------------- */
const sagaMiddleware = createSagaMiddleware();

// Create store
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

// kick off root saga
sagaMiddleware.run(rootSaga);

export default store;
