import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pie } from "react-chartjs-2";
import {
  Box,
  Button,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { FileDownloadOutlined } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { debounce } from "lodash";
import { endOfMonth, startOfMonth } from "date-fns";
import { getRandomColor, hasRoleScopes } from "utils";
import { AppConstant, FormatConstant, LangConstant, SystemConstant } from "const";
import UserActionRedux from "redux-store/userAction.redux";
import useUserActionTypeList from "../CurrentUserAction/hooks/useUserActionTypeList";

const UserActionSummary = () => {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const summaryList = useSelector(state => state.userActionRedux.userActionSummaryList);
  const totalList = useSelector(state => state.userActionRedux.userActionTotalList);

  const { actionList } = useUserActionTypeList();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [searchInput, setSearchInput] = useState();
  const [date, setDate] = useState(null);
  const [pagination, setPagination] = useState(1);
  const [pieChartData, setPieChartData] = useState({
    labels: [],
    datasets: [],
  });

  const getNameAction = action => {
    return actionList.find(item => action === item.value).label;
  };

  const onChangeSearchInput = debounce(value => {
    setSearchInput(value);
  }, 500);

  const onChangeDate = value => {
    if (value) {
      setDate(value);
    } else {
      setDate(null);
    }
  };

  const onChangePagination = (event, value) => {
    setPagination(value);
  };

  const onClickDownload = () => {
    dispatch(
      UserActionRedux.exportSummaryAction({
        exportF: SystemConstant.IS_EXPORT.TRUE,
        name: searchInput,
        start: date ? startOfMonth(date).getTime() : null,
        end: date ? endOfMonth(date).getTime() : null,
      }),
    );
  };

  useEffect(() => {
    dispatch(
      UserActionRedux.getSummaryAction({
        page: pagination,
        name: searchInput,
        start: date ? startOfMonth(date).getTime() : null,
        end: date ? endOfMonth(date).getTime() : null,
      }),
    );
  }, [pagination, searchInput, date]);

  useEffect(() => {
    let labelArr = [];
    let dataArray = [];
    let colorArr = [];
    totalList.forEach(item => {
      labelArr.push(getNameAction(Number(item.action)));
      dataArray.push(item.count);
      colorArr.push(getRandomColor());
    });

    setPieChartData({
      labels: labelArr,
      datasets: [
        {
          data: dataArray,
          backgroundColor: colorArr,
          borderColor: "gray",
          borderWidth: 0.5,
        },
      ],
    });
  }, [totalList]);

  useEffect(() => {
    dispatch(
      UserActionRedux.getTotalAction({
        start: date ? startOfMonth(date).getTime() : null,
        end: date ? endOfMonth(date).getTime() : null,
        name: searchInput,
      }),
    );
  }, [date, searchInput]);

  return (
    <Box>
      <Box className={classes.inputBox}>
        <TextField
          onChange={event => onChangeSearchInput(event.target.value)}
          size="small"
          label={LangConstant.L_SEARCH}
          variant="outlined"
          sx={{ width: 350 }}
        />

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={LangConstant.TXT_TIME}
            views={["month", "year"]}
            inputFormat={FormatConstant.FM_MM_YYYY}
            onChange={value => onChangeDate(value)}
            value={date}
            maxDate={new Date()}
            renderInput={params => <TextField size="small" {...params} />}
          />
        </LocalizationProvider>
      </Box>

      <Box>
        <Typography fontWeight="700">{LangConstant.TXT_USER_ACTION_ON_SYSTEM}</Typography>

        <Box className={classes.pieChartBox}>
          <Pie
            style={{
              width: 800,
              height: 500,
            }}
            data={pieChartData}
            options={options}
          />
        </Box>
      </Box>

      <Box>
        <Box className={classes.btnBox}>
          <Typography fontWeight="700">{LangConstant.TXT_USER_ACTION_DETAIL_USER}</Typography>
          {hasRoleScopes(AppConstant.SCOPES.USER_ACTION_SUMMARY_EXPORT) && (
            <Button variant="outlined" startIcon={<FileDownloadOutlined />} onClick={onClickDownload}>
              {LangConstant.L_DOWNLOAD_LIST}
            </Button>
          )}
        </Box>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.cellHeader}>{LangConstant.TXT_STT}</TableCell>
                <TableCell className={classes.cellHeader}>{LangConstant.TXT_NAME}</TableCell>

                {actionList
                  .filter(item => item.value > 0)
                  .map(item => {
                    return <TableCell className={classes.cellHeader}>{item.label}</TableCell>;
                  })}
              </TableRow>
            </TableHead>
            {
              <TableBody>
                {summaryList.data &&
                  summaryList.data.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell align="center">{(pagination - 1) * summaryList.size + index + 1}</TableCell>
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell align="center">{row.countActions.login}</TableCell>
                        <TableCell align="center">{row.countActions.logout}</TableCell>
                        <TableCell align="center">{row.countActions.changePassword}</TableCell>
                        <TableCell align="center">{row.countActions.turnOn_2FactorAuthentication}</TableCell>
                        <TableCell align="center">{row.countActions.turnOff_2FactorAuthentication}</TableCell>
                        <TableCell align="center">{row.countActions.createFolder}</TableCell>
                        <TableCell align="center">{row.countActions.createReport}</TableCell>
                        <TableCell align="center">{row.countActions.shareFile}</TableCell>
                        <TableCell align="center">{row.countActions.shareInformation}</TableCell>
                        <TableCell align="center">{row.countActions.deleteData}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            }
          </Table>
        </TableContainer>

        <Box className={classes.panigationPadding}>
          <Pagination
            onChange={onChangePagination}
            sx={{ paddingTop: 2 }}
            count={+summaryList.totalPage}
            variant="outlined"
            page={pagination}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default UserActionSummary;

const useStyles = makeStyles({
  cellHeader: {
    fontWeight: "bold",
    textAlign: "center",
  },

  btnBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 16,
    marginBottom: 4,
  },

  inputBox: {
    display: "flex",
    marginBottom: 16,
    gap: 16,
  },

  pieChartBox: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 2,
    paddingBottom: 4,
  },
});

const options = {
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      callbacks: {
        label: ttItem => `${ttItem.parsed} lượt`,
      },
    },
    legend: {
      position: "right",
    },
  },
};
