import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { AppConstant, LangConstant } from "const";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";

const ViewPortManagement = ({ open, onClose, dataView }) => {
  const classes = useStyles();
  const getPortManager = useSelector(state => state.conversationRedux.getPortManager);

  const [selectPortManager, setSelectPortManager] = useState("1fa67aa1625740b8b21651aab356581c");
  const [phoneNumber, setPhoneNumber] = useState(dataView?.phone_number);
  const [valueInput, setValueInput] = useState({
    portName: dataView?.name,
    numberOfBridges: dataView?.quay_number,
    DWT: "",
    height: dataView?.quay_length,
    minimumDepth: dataView?.depth_min,
    maximumFlowDepth: dataView?.depth_max,
    longStream: dataView?.length,
    wattage: dataView?.power,
    warehouseArea: dataView?.square,
    companyName: dataView?.company_name,
    address: dataView?.address,
    numberFax: dataView?.fax,
    latitude: dataView?.latitude,
    longitude: dataView?.longitude,
  });

  const onChangeInput = e => {
    setValueInput({ ...valueInput, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setPhoneNumber(dataView?.phone_number);
  }, [dataView?.phone_number]);

  useEffect(() => {
    setSelectPortManager(dataView?.port_name);
  }, [dataView?.port_name]);

  useEffect(() => {
    setValueInput({
      portName: dataView?.name,
      numberOfBridges: dataView?.quay_number,
      DWT: "",
      height: dataView?.quay_length,
      minimumDepth: dataView?.depth_min,
      maximumFlowDepth: dataView?.depth_max,
      longStream: dataView?.length,
      wattage: dataView?.power,
      warehouseArea: dataView?.square,
      companyName: dataView?.company_name,
      address: dataView?.address,
      numberFax: dataView?.fax,
      latitude: dataView?.latitude,
      longitude: dataView?.longitude,
    });
  }, [
    dataView?.address,
    dataView?.company_name,
    dataView?.depth_max,
    dataView?.depth_min,
    dataView?.fax,
    dataView?.latitude,
    dataView?.length,
    dataView?.longitude,
    dataView?.name,
    dataView?.power,
    dataView?.quay_length,
    dataView?.quay_number,
    dataView?.square,
  ]);

  useEffect(() => {
    const filters = getPortManager && getPortManager?.filter(item => item?.name === dataView?.port_name);
    filters && filters?.forEach(item2 => setSelectPortManager(item2.uid));
  }, [dataView?.port_name, getPortManager]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{LangConstant.TXT_VIEW_MORE_PORT}</DialogTitle>
      <DialogContent>
        <DialogContent style={{ paddingBottom: 10 }}>
          <Box className={classes.paddingTopSelect}>
            <FormControl disabled size="small" fullWidth>
              <InputLabel id="demo-simple-select-label">Chọn cảng biển</InputLabel>
              <Select
                onChange={e => setSelectPortManager(e.target.value)}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Chọn cảng biển"
                value={selectPortManager}
              >
                {getPortManager &&
                  getPortManager.map(item => (
                    <MenuItem key={item.uid} value={item.uid}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.paddingInput}>
            <TextField
              disabled
              onChange={onChangeInput}
              name="portName"
              sx={{
                "& label": {
                  "&.Mui-focused": {
                    color: "red",
                  },
                },
              }}
              size="small"
              fullWidth
              label={LangConstant.L_NAME_MORE_PORT}
              variant="outlined"
              defaultValue={dataView?.name}
            />
          </Box>
          <Typography>Thông tin</Typography>
          <TextField
            disabled
            sx={{
              "& label": {
                "&.Mui-focused": {
                  color: "red",
                },
              },
            }}
            onChange={onChangeInput}
            name="numberOfBridges"
            type="number"
            size="small"
            fullWidth
            label={LangConstant.L_NUMBER_OF_DEMAND}
            variant="outlined"
            defaultValue={dataView?.quay_number}
          />
          <Box className={classes.paddingInput}>
            <TextField
              disabled
              onChange={onChangeInput}
              name="DWT"
              type="number"
              size="small"
              fullWidth
              label={LangConstant.L_DWT}
              variant="outlined"
            />
          </Box>
          <TextField
            disabled
            onChange={onChangeInput}
            name="height"
            type="number"
            size="small"
            fullWidth
            label={LangConstant.L_HEIGHT}
            variant="outlined"
            defaultValue={dataView?.quay_length}
          />
          <Box className={classes.paddingInput}>
            <TextField
              disabled
              onChange={onChangeInput}
              type="number"
              size="small"
              fullWidth
              label={LangConstant.L_MINIMUM_DEPTH}
              variant="outlined"
              name="minimumDepth"
              defaultValue={dataView?.depth_min}
            />
          </Box>
          <TextField
            disabled
            onChange={onChangeInput}
            name="maximumFlowDepth"
            type="number"
            size="small"
            fullWidth
            label={LangConstant.L_MAXMUM_FLOW_DEPTH}
            variant="outlined"
            defaultValue={dataView?.depth_max}
          />
          <Box className={classes.paddingInput}>
            <TextField
              disabled
              onChange={onChangeInput}
              name="longStream"
              type="number"
              size="small"
              fullWidth
              label={LangConstant.L_LONG_STREAM}
              variant="outlined"
              defaultValue={dataView?.length}
            />
          </Box>
          <TextField
            disabled
            onChange={onChangeInput}
            name="wattage"
            type="number"
            size="small"
            fullWidth
            label={LangConstant.L_POWER}
            variant="outlined"
            defaultValue={dataView?.power}
          />
          <Box className={classes.paddingInput}>
            <TextField
              disabled
              onChange={onChangeInput}
              type="number"
              size="small"
              fullWidth
              label={LangConstant.L_SQUARE}
              variant="outlined"
              name="warehouseArea"
              defaultValue={dataView?.square}
            />
          </Box>
          <TextField
            disabled
            onChange={onChangeInput}
            name="companyName"
            size="small"
            fullWidth
            label={LangConstant.L_COMPANY_NAME}
            variant="outlined"
            defaultValue={dataView?.company_name}
          />
          <Box className={classes.paddingInput}>
            <TextField
              disabled
              onChange={onChangeInput}
              name="address"
              size="small"
              fullWidth
              label={LangConstant.L_ADDRESS}
              variant="outlined"
              defaultValue={dataView?.address}
            />
          </Box>
          <Box className={classes.paddingInput}>
            <TextField
              disabled
              onChange={onChangeInput}
              name="numberFax"
              type="number"
              size="small"
              fullWidth
              label={LangConstant.L_FAX}
              variant="outlined"
              defaultValue={dataView?.fax}
            />
          </Box>
          <PhoneInput
            value={phoneNumber}
            onChange={phone => setPhoneNumber(phone)}
            country="vn"
            onlyCountries={AppConstant.COUNTRY}
            disabled
            defaultValue={dataView?.phone_number}
          />
          <Box className={classes.paddingInput}>
            <TextField
              disabled
              onChange={onChangeInput}
              name="latitude"
              type="number"
              size="small"
              fullWidth
              label={LangConstant.L_LANGITUDE}
              variant="outlined"
              defaultValue={dataView?.latitude}
            />
          </Box>
          <TextField
            disabled
            onChange={onChangeInput}
            name="longitude"
            type="number"
            size="small"
            fullWidth
            label={LangConstant.L_LONGITUDE}
            variant="outlined"
            defaultValue={dataView?.longitude}
          />
        </DialogContent>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{LangConstant.TXT_BUTTON_CANCEL}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewPortManagement;

const useStyles = makeStyles(theme => ({
  paddingInput: {
    padding: "10px 0",
  },
}));
