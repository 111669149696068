import React from "react";
import { Box } from "@mui/material";
import { Outlet, Navigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { PathConstant } from "const";
import { Sidebar } from "components";
import { hasLogin } from "utils";

export default function Layout() {
  const classes = useStyles();

  return hasLogin() ? (
    <Box className={classes.containerSidebar}>
      <Box className={classes.sidebarBox}>
        <Sidebar />
      </Box>
      <Box className={classes.mainContent}>
        <Outlet />
      </Box>
    </Box>
  ) : (
    <Navigate to={PathConstant.LOGIN_PAGE} />
  );
}

const useStyles = makeStyles(theme => ({
  containerSidebar: {
    display: "flex",
    overflow: "hidden",
    height: "100vh",
    width: "100%",
  },

  sidebarBox: {
    width: "max-content",
  },

  mainContent: {
    padding: 10,
    width: "100%",
    overflow: "auto",
  },
}));
