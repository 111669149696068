import { useContext } from "react";
import { MapContext } from "pages/HomePage";

export const useMapContext = () => {
  const {
    map,
    placeholderCanvasLayerRef,
    canvasLayerRef,
    placeholderVectorLayerRef,
    vectorLayerRef,
    clusteredLayer,
    drawLayer,
    weatherLayer,
    areaLayer,
    trackingLayer,
    drawControl,
    mapState,
    isShowWeather,
    setIsShowWeather,
    isShowShip,
    setIsShowShip,
    isClusteredShip,
    setIsClusteredShip,
    isShowShipName,
    setIsShowShipName,
    isShowShipSpeed,
    setIsShowShipSpeed,
    hoverShip,
    setHoverShip,
    clickedShip,
    setClickedShip,
    isOpenStatisticalReport,
    setIsOpenStatisticalReport,
    dragElement,
    activeStatisticalShip,
    setActiveStatisticalShip,
    isOpenStatisticalDetail,
    setIsOpenStatisticalDetail,
    statisticalReportArea,
    setStatisticalReportArea,
    fromDay,
    setFromDay,
    toDay,
    setToDay,
    isShowSidebar,
    setIsShowSidebar,
    toggleShowShipManage,
    isOpenAreaManagementDialog,
    setIsOpenAreaManagementDialog,
    areaManagementConfigs,
    setAreaManagementConfigs,
    selectedAreaList,
    setSelectedAreaList,
    sidebarMode,
    setSidebarMode,
    videoTrackingOptions,
    setVideoTrackingOptions,
    animateMarker,
    captureLayer,
    flickerLayerRef,
    showMenuLayer,
    setShowMenuLayer,
    hoverLayerRef,
    zoomSize,
    setZoomSize,
    showMenuWatchList,
    setShowMenuWatchList,
    watchListLayerRef,
    showOtherShip,
    setShowOtherShip,
    showBackgroundMapList,
    setShowBackgroundMapList,
    chooseBackgroundMap,
    setChooseBackgroundMap,
    pointLayerRef,
    hoverPoint,
    setHoverPoint,
    turnOnMeasure,
    setTurnOnMeasure,
    metresPerPixel,
    setMetresPerPixel,
    weatherDialog,
    setWeatherDialog,
    trackkingPointLayerRef,
  } = useContext(MapContext);

  return {
    map,
    placeholderCanvasLayerRef,
    canvasLayerRef,
    placeholderVectorLayerRef,
    vectorLayerRef,
    clusteredLayer,
    drawLayer,
    weatherLayer,
    areaLayer,
    trackingLayer,
    drawControl,
    mapState,
    isShowWeather,
    setIsShowWeather,
    isShowShip,
    setIsShowShip,
    isClusteredShip,
    setIsClusteredShip,
    isShowShipName,
    setIsShowShipName,
    isShowShipSpeed,
    setIsShowShipSpeed,
    hoverShip,
    setHoverShip,
    clickedShip,
    setClickedShip,
    isOpenStatisticalReport,
    setIsOpenStatisticalReport,
    dragElement,
    activeStatisticalShip,
    setActiveStatisticalShip,
    isOpenStatisticalDetail,
    setIsOpenStatisticalDetail,
    statisticalReportArea,
    setStatisticalReportArea,
    fromDay,
    setFromDay,
    toDay,
    setToDay,
    isShowSidebar,
    setIsShowSidebar,
    toggleShowShipManage,
    isOpenAreaManagementDialog,
    setIsOpenAreaManagementDialog,
    areaManagementConfigs,
    setAreaManagementConfigs,
    selectedAreaList,
    setSelectedAreaList,
    sidebarMode,
    setSidebarMode,
    videoTrackingOptions,
    setVideoTrackingOptions,
    animateMarker,
    captureLayer,
    flickerLayerRef,
    showMenuLayer,
    setShowMenuLayer,
    hoverLayerRef,
    zoomSize,
    setZoomSize,
    showMenuWatchList,
    setShowMenuWatchList,
    watchListLayerRef,
    showOtherShip,
    setShowOtherShip,
    showBackgroundMapList,
    setShowBackgroundMapList,
    chooseBackgroundMap,
    setChooseBackgroundMap,
    pointLayerRef,
    hoverPoint,
    setHoverPoint,
    turnOnMeasure,
    setTurnOnMeasure,
    metresPerPixel,
    setMetresPerPixel,
    weatherDialog,
    setWeatherDialog,
    trackkingPointLayerRef,
  };
};
