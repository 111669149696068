import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ShipActions from "redux-store/ship.redux";
import { makeStyles } from "@mui/styles";
import { Checkbox, FormGroup, FormControlLabel, Box } from "@mui/material";
import { SystemConstant } from "const";
import { ShipIcon, NavigationAidIcon } from "theme/icons";
import { getShipTypeColor } from "utils";

export default function FilterByShipType() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const shipTypes = useSelector(state => state.shipRedux.shipTypes);
  const shipConditions = useSelector(state => state.shipRedux.shipConditions);

  const [selected, setSelected] = useState(shipConditions.ship_type_list.map(type => type.uid || type));

  const onChangeValue = event => {
    const value = event.target.value;
    const list = [...selected];
    const index = list.indexOf(value);
    index === -1 ? list.push(value) : list.splice(index, 1);
    setSelected(list);
    dispatch(
      ShipActions.shipSet({
        isChangingFilter: true,
        shipConditions: Object.assign(shipConditions, { ship_type_list: list }),
      }),
    );
  };

  useEffect(() => {
    dispatch(ShipActions.requestGetShipTypes());
  }, []);

  useEffect(() => {
    setSelected(shipConditions.ship_type_list.map(type => type.uid || type));
  }, [shipConditions.ship_type_list]);

  return (
    <FormGroup
      classes={{
        root: classes.filterByShipTypeBox,
      }}
    >
      {shipTypes.map(item => (
        <FormControlLabel
          key={item.uid}
          control={
            <>
              <Checkbox value={item.uid} onChange={onChangeValue} checked={selected.includes(item.uid)} />
              {item.type === SystemConstant.SHIP_TYPE.NAVIGATION_AID ? (
                <NavigationAidIcon className={classes.navigationIcon} />
              ) : (
                <ShipIcon fillColor={getShipTypeColor(item.type)} />
              )}
            </>
          }
          label={<Box className={classes.nameAndIcon}>{item.name}</Box>}
          classes={{
            root: classes.controlForm,
            label: classes.shipTypeTitle,
          }}
        />
      ))}
    </FormGroup>
  );
}

const useStyles = makeStyles(theme => ({
  filterByShipTypeBox: {
    display: "grid",
    background: "#fff",
    gridTemplateColumns: "auto",
    paddingLeft: "16px",
    width: "100%",
  },

  selectFilterCheckBox: {
    display: "flex",
  },

  shipTypeTitle: {
    fontSize: "14px",
    color: "#000",
    lineHeight: "100%",
    display: "flex",
    alignItems: "center",
    userSelect: "none",
    width: "100%",
  },

  nameAndIcon: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },

  controlForm: {
    width: "100%",
    display: "flex",
    gap: "4px",
  },

  navigationIcon: {
    fill: "pink",
    padding: "3px",
    userSelect: "none",
    display: "inline-block",
    flexShrink: 0,
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontSize: "24px",
  },
}));
