import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import StringFormat from "string-format";
import { Box, Typography, Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import { LangConstant } from "const";
import { deepCloneJsonObject } from "utils";

function MonitoringShipList({ item, setSelectedGroupShips }) {
  const classes = useStyles();

  const isManageShip = useSelector(state => state.conversationRedux.isManageShip);

  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState([]);

  const onClickOpen = () => {
    setIsOpen(prev => !prev);
  };

  const nameShip = item.ships;

  const isAllSelected = nameShip.length > 0 && selected.length === nameShip.length;

  const onChangeValue = event => {
    let value = event.target.value;
    if (value === "all") {
      setSelected(selected.length === nameShip.length ? [] : nameShip.map(item => ({ uid: item.uid })));
      return;
    }
    let list = deepCloneJsonObject(selected);
    let index = list.findIndex(i => i.uid === value);
    index === -1 ? list.push({ uid: value }) : list.splice(index, 1);
    setSelected(list);
  };

  useEffect(() => {
    setSelectedGroupShips(state => {
      let clone = deepCloneJsonObject(state);
      clone[item.uid] = deepCloneJsonObject(selected);

      return clone;
    });
  }, [selected]);

  useEffect(() => {
    if (isManageShip) {
      setSelected([]);
    }
  }, [isManageShip]);

  return (
    <Box className={classes.monitoringShipBox}>
      <Box className={classes.filterSelectedAllBox}>
        <Box className={classes.selectAllBox}>
          <Checkbox
            value="all"
            onChange={onChangeValue}
            checked={isAllSelected}
            className={classes.selectAllCheckBox}
          />
        </Box>
        <Box onClick={() => onClickOpen()} className={classes.monitoringShipTitleBox}>
          <Box className={classes.monitoringShipTitle}>
            <Typography>
              {StringFormat(LangConstant.FM_MONITORING_LIST, item.name || "Danh sách chưa đặt tên", nameShip.length)}
            </Typography>
          </Box>
          <Box>
            {isOpen ? (
              <KeyboardArrowUp className={classes.colorBlack} />
            ) : (
              <KeyboardArrowDown className={classes.colorBlack} />
            )}
          </Box>
        </Box>
      </Box>
      <Box>
        {isOpen && (
          <FormGroup
            classes={{
              root: classes.shipBox,
            }}
          >
            {nameShip.map(item => (
              <FormControlLabel
                key={item.uid}
                control={
                  <Checkbox
                    value={item.uid}
                    onChange={onChangeValue}
                    checked={selected.map(s => s.uid).includes(item.uid)}
                  />
                }
                label={item.name}
                classes={{
                  root: classes.labelRoot,
                  label: classes.nameShip,
                }}
              />
            ))}
          </FormGroup>
        )}
      </Box>
    </Box>
  );
}

export default MonitoringShipList;

const useStyles = makeStyles(theme => ({
  monitoringShipBox: {
    marginTop: 4,
    background: "#fff",
    padding: "4px 8px",
    boxSizing: "border-box",
    border: "1px solid #c4c4c4",
    borderRadius: 4,
  },

  monitoringShipTitle: {
    display: "flex",
    alignItems: "center",
    userSelect: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },

  selectAllBox: {
    display: "flex",
  },

  filterSelectedAllBox: {
    display: "flex",
    alignItems: "center",
  },

  shipBox: {
    display: "grid",
    gridTemplateColumns: "auto",
    marginBottom: 18,
  },

  monitoringShipTitleBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },

  nameShip: {
    fontSize: "14px",
    color: "#000",
    lineHeight: "100%",
    display: "flex",
    alignItems: "center",
    userSelect: "none",
  },

  colorBlack: {
    color: "#000",
  },

  labelRoot: {
    margin: 0,
    padding: 0,
    marginLeft: "16px",
  },
}));
