import React, { useEffect } from "react";
import { Box, Button, Grid } from "@mui/material";
import { KeyboardBackspace } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import ChangeAvatar from "pages/AdminDashboard/Profile/ChangeAvatar";
import ShareProfile from "pages/AdminDashboard/Profile/ShareProfile";
import ShowDetailsProfile from "pages/AdminDashboard/Profile/ShowDetailsProfile";
import UserRedux from "redux-store/user.redux";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { PathConstant } from "const";

const UserDetailPersonal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId } = useParams();

  const userInfo = useSelector(state => state.userRedux.userInfo);

  const onHandleBack = () => {
    navigate(PathConstant.ADMIN_USER);
  };

  useEffect(() => {
    dispatch(UserRedux.getUserInfo(userId));
    return () => dispatch(UserRedux.userSet({ userInfo: null }));
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Button className={classes.buttonBack} onClick={onHandleBack}>
          <KeyboardBackspace />
        </Button>
        <Box className={classes.containerAvatar}>
          <ChangeAvatar userInfo={userInfo?.uid === userId ? userInfo : undefined} />
          <ShareProfile userInfo={userInfo?.uid === userId ? userInfo : undefined} />
        </Box>
      </Grid>
      <Grid item xs={9}>
        <ShowDetailsProfile userInfo={userInfo?.uid === userId ? userInfo : undefined} />
      </Grid>
    </Grid>
  );
};

export default UserDetailPersonal;

const useStyles = makeStyles(() => ({
  containerAvatar: {
    textAlign: "center",
    margin: "100px auto",
  },

  buttonBack: {
    marginTop: "40px",
    color: "black",
  },
}));
