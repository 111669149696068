import { useNavigate } from "react-router-dom";
import { useLoading, useNotification } from "hooks";
import { PermissionService } from "services";
import { toSnake } from "utils";
import { PathConstant } from "const";

export const useCreatePermission = () => {
  const loading = useLoading();
  const notification = useNotification();
  const navigate = useNavigate();

  const createPermission = async payload => {
    try {
      loading.open();
      const response = await PermissionService.createPermissionGroup(toSnake(payload));
      loading.close();
      if (response.ok) {
        notification.send("Thêm mới dữ liệu thành công", "success");
        navigate(PathConstant.ADMIN_PERMISSION_LIST);
      } else {
        notification.send("Đã có lỗi xảy ra, vui lòng thử lại sau", "error");
      }
    } catch (error) {
      loading.close();
      notification.send("Đã có lỗi xảy ra, vui lòng thử lại sau", "error");
      console.error(error);
    }
  };

  return { createPermission };
};
