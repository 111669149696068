import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import { EventConstant, AppConstant } from "const";
import { useLoading, useMapContext, useNotification } from "hooks";
import AreaActions from "redux-store/area.redux";
import { EventBus } from "EventBus";
import LayerCreateNewAreaDialog from "./LayerCreateNewAreaDialog";
import ConfirmDialog from "components/ConfirmDialog";

const LayerDrawShape = () => {
  const classes = useStyles();
  const notification = useNotification();
  const loading = useLoading();
  const dispatch = useDispatch();
  const { map, mapState, drawLayer, setAreaManagementConfigs } = useMapContext();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const area = useRef(null);

  const customArea = useSelector(state => state.areaRedux.customArea);

  const [createAreaDialogData, setCreateAreaDialogData] = useState({
    type: null,
    open: false,
    name: "Khu vực chưa đặt tên",
    latlngs: [],
  });

  const handleDrawCreatedEvent = event => {
    setAreaManagementConfigs(state => ({ ...state, status: "idle" }));
    drawLayer.current.addLayer(event.layer);
    if (allowShapes.includes(event.layerType)) {
      const latlngs = event.layer._latlngs[0].map(latlng => ({ lat: latlng.lat, lng: latlng.lng }));
      area.current = event.layer;
      setCreateAreaDialogData(state => ({
        ...state,
        open: true,
        name: "Khu vực chưa đặt tên",
        latlngs: latlngs,
        type: event.layerType,
        latlng: event?.layer?._latlng,
        radius: event?.layer?._mRadius,
      }));
    }
    if (event.layerType === "circle") {
      area.current = event.layer;
      setCreateAreaDialogData(state => ({
        ...state,
        open: true,
        name: "Khu vực chưa đặt tên",
        type: event.layerType,
        center_lat: event?.layer?._latlng.lat,
        center_lon: event?.layer?._latlng.lng,
        radius: event?.layer?._mRadius * AppConstant.METER_TO_NAUTICAL_MILE,
      }));
    }
  };

  const handleDrawStartEvent = event => {
    setAreaManagementConfigs(state => ({ ...state, status: "drawing_" + event.layerType }));
    if (allowShapes.includes(event.layerType) && area.current) {
      drawLayer.current.removeLayer(area.current);
      area.current = null;
      setCreateAreaDialogData(state => ({
        ...state,
        open: false,
        name: "Khu vực chưa đặt tên",
        latlngs: [],
        type: null,
      }));
    }
  };

  const handleIdleStatus = () => {
    setAreaManagementConfigs(state => ({ ...state, status: "idle" }));
  };

  const handleDrawStartEdit = () => {
    setAreaManagementConfigs(state => ({ ...state, status: "editing" }));
  };

  const handleDrawStartDelete = () => {
    setAreaManagementConfigs(state => ({ ...state, status: "deleting" }));
  };

  const handleDrawDeletedEvent = event => {
    event.layers.eachLayer(layer => {
      if (area.current && layer._leaflet_id === area.current._leaflet_id) {
        area.current = null;
        setCreateAreaDialogData(state => ({
          ...state,
          open: false,
          name: "Khu vực chưa đặt tên",
          latlngs: [],
          type: null,
        }));
      }
    });
  };

  const handleConfirmEdit = () => {
    setAreaManagementConfigs(state => ({ ...state, status: "idle" }));
    setCreateAreaDialogData(state => ({
      ...state,
      open: false,
      name: "Khu vực chưa đặt tên",
      latlngs: [],
      type: null,
    }));
    drawLayer.current.removeLayer(area.current);
    area.current = null;
    setConfirmOpen(false);
  };

  const handleChangeAreaName = e => {
    setCreateAreaDialogData(state => ({ ...state, name: e.target.value }));
  };
  const handleChangeAreaInput = (e, inputName) => {
    setCreateAreaDialogData(state => ({ ...state, [inputName]: e.target.value }));
  };

  const handleChangeLatInput = (e, index) => {
    // Vĩ độ
    const value = e.target.value;
    if (value !== "") {
      const newLatlngs = createAreaDialogData.latlngs.map(latlng => ({ ...latlng }));
      if (createAreaDialogData.type === "rectangle") {
        if (index === 0 || index === 3) {
          newLatlngs[0]["lat"] = parseFloat(value);
          newLatlngs[3]["lat"] = parseFloat(value);
        } else {
          newLatlngs[1]["lat"] = parseFloat(value);
          newLatlngs[2]["lat"] = parseFloat(value);
        }
      } else {
        newLatlngs[index]["lat"] = parseFloat(value);
      }
      setCreateAreaDialogData(state => ({ ...state, latlngs: newLatlngs }));
    }
  };

  const handleChangeLngInput = (e, index) => {
    // Kinh độ
    const value = e.target.value;
    if (value !== "") {
      const newLatlngs = createAreaDialogData.latlngs.map(latlng => ({ ...latlng }));
      if (createAreaDialogData.type === "rectangle") {
        if (index === 0 || index === 1) {
          newLatlngs[0]["lng"] = parseFloat(value);
          newLatlngs[1]["lng"] = parseFloat(value);
        } else {
          newLatlngs[2]["lng"] = parseFloat(value);
          newLatlngs[3]["lng"] = parseFloat(value);
        }
      } else {
        newLatlngs[index]["lng"] = parseFloat(value);
      }
      setCreateAreaDialogData(state => ({ ...state, latlngs: newLatlngs }));
    }
  };

  const handleCreateArea = () => {
    if (!createAreaDialogData.name) {
      notification.send("Vui lòng nhập tên của khu vực", "error");
    } else {
      loading.open();
      dispatch(AreaActions.requestCreateCustomArea({ ...createAreaDialogData, points: createAreaDialogData.latlngs }));
    }
  };

  useEffect(() => {
    if (map.current && mapState === "loaded") {
      EventBus.on(EventConstant.MAP_DRAW_EVENTS.DRAWSTART, handleDrawStartEvent);
      EventBus.on(EventConstant.MAP_DRAW_EVENTS.DRAWSTOP, handleIdleStatus);
      EventBus.on(EventConstant.MAP_DRAW_EVENTS.CREATED, handleDrawCreatedEvent);
      EventBus.on(EventConstant.MAP_DRAW_EVENTS.EDITSTART, handleDrawStartEdit);
      EventBus.on(EventConstant.MAP_DRAW_EVENTS.EDITSTOP, handleIdleStatus);
      EventBus.on(EventConstant.MAP_DRAW_EVENTS.EDITED, handleIdleStatus);
      EventBus.on(EventConstant.MAP_DRAW_EVENTS.DELETESTART, handleDrawStartDelete);
      EventBus.on(EventConstant.MAP_DRAW_EVENTS.DELETESTOP, handleIdleStatus);
      EventBus.on(EventConstant.MAP_DRAW_EVENTS.DELETED, handleDrawDeletedEvent);
    }

    return () => {
      EventBus.off(EventConstant.MAP_DRAW_EVENTS.DRAWSTART, handleDrawStartEvent);
      EventBus.off(EventConstant.MAP_DRAW_EVENTS.DRAWSTOP, handleIdleStatus);
      EventBus.off(EventConstant.MAP_DRAW_EVENTS.CREATED, handleDrawCreatedEvent);
      EventBus.off(EventConstant.MAP_DRAW_EVENTS.EDITSTART, handleDrawStartEdit);
      EventBus.off(EventConstant.MAP_DRAW_EVENTS.EDITSTOP, handleIdleStatus);
      EventBus.off(EventConstant.MAP_DRAW_EVENTS.EDITED, handleIdleStatus);
      EventBus.off(EventConstant.MAP_DRAW_EVENTS.DELETESTART, handleDrawStartDelete);
      EventBus.off(EventConstant.MAP_DRAW_EVENTS.DELETESTOP, handleIdleStatus);
      EventBus.off(EventConstant.MAP_DRAW_EVENTS.DELETED, handleDrawDeletedEvent);
    };
  }, [map, mapState]);

  useEffect(() => {
    if (customArea) {
      notification.send("Tạo khu vực mới thành công");
      drawLayer.current.removeLayer(area.current);
      area.current = null;
      dispatch(AreaActions.areaSet({ customArea: null }));
      dispatch(AreaActions.requestGetDefaultArea());
      dispatch(AreaActions.requestGetCustomArea());
      setCreateAreaDialogData(state => ({
        ...state,
        open: false,
        name: "Khu vực chưa đặt tên",
        latlngs: [],
        type: null,
      }));
    }
    loading.close();
  }, [customArea]);

  return (
    <>
      <Box className={classes.cont} component="section">
        <LayerCreateNewAreaDialog
          mode="create"
          open={createAreaDialogData.open}
          name={createAreaDialogData.name}
          latlngs={createAreaDialogData.latlngs}
          onClose={() => {
            setConfirmOpen(true);
          }}
          onSubmit={handleCreateArea}
          onChangeAreaName={handleChangeAreaName}
          onChangeLatInput={handleChangeLatInput}
          onChangeLngInput={handleChangeLngInput}
          onChangeAreaInput={handleChangeAreaInput}
          type={createAreaDialogData.type}
          center_lon={createAreaDialogData.center_lon}
          center_lat={createAreaDialogData.center_lat}
          radius={createAreaDialogData.radius}
        />
      </Box>
      <ConfirmDialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={() => handleConfirmEdit()}
        title=""
        message={`Dữ liệu của bạn sẽ bị mất. Bạn có chắc chắn muốn thực hiện hành động này?`}
      />
    </>
  );
};

export default memo(LayerDrawShape);

const allowShapes = ["polygon", "rectangle"];

const useStyles = makeStyles(() => ({
  cont: {},
}));
