export const ROOT = "/";
export const HOME = "/home";
export const LOGIN_PAGE = "/login";
export const FILE = "/file";
export const WEATHER_MANAGEMENT = "/weather-management";
export const DETAIL_GROUP = "/detail-group";

export const ADMIN = "/admin";
export const ADMIN_NOTIFICATION = "/admin/notification";
export const ADMIN_ROLE_MANAGEMENT = "/admin/role";
export const ADMIN_USER = "/admin/user";
export const ADMIN_USER_DETAIL = "/admin/user/:userId";
export const ADMIN_SHIP = "/admin/ship";
export const ADMIN_HISTORY = "/admin/history";
export const ADMIN_DATA_CRAWL_HISTORY = "/admin/crawl-history";
export const ADMIN_SEAPORT = "/admin/seaport";
export const ADMIN_HABOR = "/admin/habor";
export const ADMIN_WATCH_LIST = "/admin/watchList";
export const ADMIN_GROUP_DETAILS = "/admin/group-details";
export const ADMIN_GROUP_DETAILS_ID = "/admin/group-details/:id";
export const ADMIN_PROFILE = "/admin/profile";
export const ADMIN_USER_ACTION_PERSONAL = "/admin/user-action/:userId";
export const ADMIN_USER_ACTION_MANAGEMENT = "/admin/user-action/list";
export const ADMIN_MANAGE_EVENT_WARNING = "/admin/event-warning";
export const ADMIN_NOTIFICATION_EVENT_WARNING_MANAGEMENT = "/admin/event-warning/notification";
export const ADMIN_FOLDER_WARNING = "/admin/folder";
export const ADMIN_PERMISSION_LIST = "/admin/permission/list";
export const ADMIN_CREATE_PERMISSION = "/admin/permission/create";
export const ADMIN_UPDATE_PERMISSION = "/admin/permission/update/:permissionGroupUid";
