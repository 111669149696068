import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import { Button, TextField, Checkbox, FormControlLabel, MenuItem, Typography } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { AppConstant, LangConstant, SystemConstant } from "const";
import FilterByCapacity from "pages/HomePage/Sidebar/Filter/FilterByCapacity";
import FilterGroupShip from "pages/HomePage/Sidebar/Filter/FilterGroupShip";
import FilterByShipType from "pages/HomePage/Sidebar/Filter/FilterByShipType";
import FilterByPort from "pages/HomePage/Sidebar/Filter/FilterByPort";
import SeaArea from "pages/HomePage/Sidebar/Filter/SeaArea";
import MonitoringArea from "pages/HomePage/Sidebar/Filter/MonitoringArea";
import AreaActions from "redux-store/area.redux";
import EventWarningAction from "redux-store/eventwarning.redux";
import ShipActions from "redux-store/ship.redux";
import ConfirmCancelDialog from "../../../components/ConfirmCancelDialog";
import { useClickOutside } from "hooks";
import FilterByManyNation from "pages/HomePage/Sidebar/Filter/FilterByManyNation";
import countryList from "react-select-country-list";

const EditEventWarning = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const data = useSelector(state => state.eventWarningRedux.dataEventWarning);
  const selectedAreaList = useSelector(state => state.areaRedux.selectedAreaList);
  const shipConditions = useSelector(state => state.shipRedux.shipConditions);
  const countryCodes = useMemo(() => countryList().getData(), []);

  const [isReceiveNotify, setIsReceiveNotify] = useState(data.receive_notification_f);
  const [isOpenTypeActionList, setIsOpenTypeActionList] = useState(false);
  const [isOpenFilterCapacity, setIsOpenFilterCapacity] = useState(false);
  const [isOpenMonitoringShips, setIsOpenMonitoringShips] = useState(false);
  const [isOpenShipTypeList, setIsOpenShipTypeList] = useState(false);
  const [isOpenCountryCodeList, setIsOpenCountryCodeList] = useState(false);
  const [isOpenSeaArea, setIsOpenSeaArea] = useState(false);
  const [isOpenMonitoringArea, setIsOpenMonitoringArea] = useState(false);
  const [isOpenPortList, setIsOpenPortList] = useState(false);
  const [selectedTypeAction, setSelectedTypeAction] = useState(Object.values(data.action_list).map(obj => obj.id));
  const [eventWarningName, setEventWarningName] = useState(data.name);
  const [minSpeed, setMinSpeed] = useState(data.min_speed);
  const [maxSpeed, setMaxSpeed] = useState(data.max_speed);
  const [rangeCourse, setRangeCourse] = useState(data.range_course);
  const [rangeDraft, setRangeDraft] = useState(data.range_draft);
  const [isOpenCancelConfirm, setIsOpenCancelConfirm] = useState(false);

  const typeActionRef = useClickOutside(() => setIsOpenTypeActionList(false));
  const monitoringShipRef = useClickOutside(() => setIsOpenMonitoringShips(false));
  const shipTypeRef = useClickOutside(() => setIsOpenShipTypeList(false));
  const countryCodeRef = useClickOutside(() => setIsOpenCountryCodeList(false));
  const capacityRef = useClickOutside(() => setIsOpenFilterCapacity(false));
  const seaAreaRef = useClickOutside(() => setIsOpenSeaArea(false));
  const monitoringAreaRef = useClickOutside(() => setIsOpenMonitoringArea(false));
  const portRef = useClickOutside(() => setIsOpenPortList(false));

  useEffect(() => {
    dispatch(AreaActions.requestGetDefaultArea());
    dispatch(AreaActions.requestGetCustomArea());
  }, []);

  const onClickTypeAction = id => {
    setSelectedTypeAction(pre => {
      const isChecked = selectedTypeAction.includes(id);
      if (isChecked) {
        return selectedTypeAction.filter(item => item !== id);
      } else {
        setSelectedTypeAction(pre => [...pre, id]);
      }
    });
  };

  const onClickUpdate = () => {
    dispatch(
      EventWarningAction.updateEventWarning({
        uid: data.uid,
        name: eventWarningName,
        min_speed: parseFloat(minSpeed),
        max_speed: parseFloat(maxSpeed),
        range_course: parseFloat(rangeCourse),
        range_draft: parseFloat(rangeDraft),
        min_weight: shipConditions.capacity_min,
        max_weight: shipConditions.capacity_max,
        action_ids: selectedTypeAction,
        group_uids: shipConditions.group_ids,
        ship_type_uids: shipConditions.ship_type_list.map(item => item.uid || item),
        country_codes:
          shipConditions.country_code_list.length === 0 ||
          shipConditions.country_code_list.length === countryCodes.length
            ? null
            : shipConditions.country_code_list,
        area_uids: selectedAreaList?.map(area => area.uid),
        receive_notification_f: isReceiveNotify ? 1 : 0,
      }),
    );
  };

  const onClickClose = () => {
    dispatch(
      EventWarningAction.eventWarningSet({
        isOpenEditEventWarning: false,
      }),
    );
  };

  const enableButtonUpdate = () => {
    return (
      eventWarningName &&
      selectedTypeAction.length > 0 &&
      !selectedTypeAction.some(
        obj =>
          (obj === SystemConstant.TYPE_ACTION_WARNING.CHANGE_SPEED.id && !(minSpeed && maxSpeed)) ||
          (obj === SystemConstant.TYPE_ACTION_WARNING.CHANGE_COURSE.id && !rangeCourse) ||
          (obj === SystemConstant.TYPE_ACTION_WARNING.CHANGE_DRAFT.id && !rangeDraft) ||
          (obj === SystemConstant.TYPE_ACTION_WARNING.MOVE_IN_AREA.id && !(selectedAreaList?.length > 0)) ||
          (obj === SystemConstant.TYPE_ACTION_WARNING.MOVE_OUT_AREA.id && !(selectedAreaList?.length > 0)),
      )
    );
  };

  const onDeleteAllSelects = () => {
    dispatch(
      ShipActions.shipSet({
        shipConditions: {
          ...shipConditions,
          group_ids: [],
          ship_type_list: [],
          ports: [],
          capacity_min: AppConstant.SHIP_CAPACITY.min,
          capacity_max: AppConstant.SHIP_CAPACITY.max,
        },
      }),
    );
    dispatch(AreaActions.areaSet({ selectedAreaList: [] }));
  };

  return (
    <>
      <Button sx={{ border: "1px solid" }} onClick={() => onClickClose()}>
        {LangConstant.TXT_SHOW_LIST_EVENT_WARNING}
      </Button>
      <Box sx={{ marginTop: 4 }}>
        <Box sx={{ fontWeight: "bold", fontSize: "20px" }}>1. {LangConstant.TXT_EVENT_WARNING_INFO} </Box>

        <Box sx={{ marginTop: 2, display: "flex", paddingRight: "10%" }}>
          <TextField
            sx={{ width: 300, marginRight: "15%" }}
            fullWidth
            size="small"
            id="event_warning_id"
            label={LangConstant.TXT_EVENT_WARNING_NAME}
            variant="outlined"
            defaultValue={data.name}
            onChange={e => setEventWarningName(e.target.value)}
            required="true"
          />

          <FormControlLabel
            checked={isReceiveNotify}
            color="warning"
            control={<Checkbox />}
            onChange={() => setIsReceiveNotify(!isReceiveNotify)}
            label={LangConstant.L_RECEIVE_NOTIFY_EVENT_WARNING}
          />
        </Box>

        <Box marginTop={2}>
          <Typography className={classes.label}> {LangConstant.L_TYPE_EVENT_WARNING} </Typography>
          <Box className={classes.typeEventWarningLabel} onClick={() => setIsOpenTypeActionList(!isOpenTypeActionList)}>
            {LangConstant.L_CHOOSE_TYPE_CHANGE_VALUE}
            <KeyboardArrowDown />
          </Box>

          {isOpenTypeActionList && (
            <Box className={classes.typeEventWarningList} ref={typeActionRef}>
              {Object.values(SystemConstant.TYPE_ACTION_WARNING).map((type, index) => (
                <MenuItem
                  value={type.id}
                  key={index}
                  onClick={() => {
                    onClickTypeAction(type.id);
                  }}
                >
                  <Checkbox checked={selectedTypeAction.includes(type.id)} {...label} />
                  {type.name}
                </MenuItem>
              ))}
            </Box>
          )}
        </Box>

        <Box marginTop={2}>
          <Typography className={classes.label}>{LangConstant.L_RANGE_SPEED}</Typography>
          <Box sx={{ display: "flex", marginTop: 1 }}>
            <TextField
              sx={{ width: 300, marginRight: "15%" }}
              value={minSpeed}
              type="number"
              fullWidth
              id="min_speed"
              size="small"
              label={LangConstant.L_INPUT_MIN_SPEED}
              variant="outlined"
              inputProps={{ step: "0.1" }}
              onChange={e => {
                setMinSpeed(e.target.value.toString().slice(0, 10));
              }}
              defaultValue={data.min_speed}
              disabled={!selectedTypeAction.includes(SystemConstant.TYPE_ACTION_WARNING.CHANGE_SPEED.id)}
            />

            <TextField
              sx={{ width: 300 }}
              value={maxSpeed}
              type="number"
              fullWidth
              size="small"
              id="max_speed"
              label={LangConstant.L_INPUT_MAX_SPEED}
              variant="outlined"
              inputProps={{ step: "0.1" }}
              onChange={e => setMaxSpeed(e.target.value.toString().slice(0, 10))}
              defaultValue={data.max_speed}
              disabled={!selectedTypeAction.includes(SystemConstant.TYPE_ACTION_WARNING.CHANGE_SPEED.id)}
            />
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 2 }}>
          <Box sx={{ width: 300, marginRight: "15%" }}>
            <Typography className={classes.label}> {LangConstant.L_RANGE_COURSE} </Typography>
            <TextField
              sx={{ marginTop: 1 }}
              value={rangeCourse}
              type="number"
              fullWidth
              id="range_course"
              size="small"
              label={LangConstant.L_INPUT_COURSE}
              variant="outlined"
              inputProps={{ step: "0.1" }}
              onChange={e => setRangeCourse(e.target.value.toString().slice(0, 10))}
              defaultValue={data.range_course}
              disabled={!selectedTypeAction.includes(SystemConstant.TYPE_ACTION_WARNING.CHANGE_COURSE.id)}
            />
          </Box>

          <Box sx={{ width: 300 }}>
            <Typography className={classes.label}> {LangConstant.L_RANGE_DRAFT} </Typography>
            <TextField
              sx={{ marginTop: 1 }}
              value={rangeDraft}
              type="number"
              fullWidth
              size="small"
              id="range_draft"
              label={LangConstant.L_INPUT_DRAFT}
              variant="outlined"
              inputProps={{ step: "0.1" }}
              onChange={e => setRangeDraft(e.target.value.toString().slice(0, 10))}
              defaultValue={data.range_draft}
              disabled={!selectedTypeAction.includes(SystemConstant.TYPE_ACTION_WARNING.CHANGE_DRAFT.id)}
            />
          </Box>
        </Box>
      </Box>

      <Box sx={{ marginTop: 2 }}>
        <Box sx={{ fontWeight: "bold", fontSize: "20px", display: "flex", alignItems: "center" }}>
          <Typography>2. {LangConstant.TXT_EVENT_WARNING_DATA}</Typography>
          <Button className={classes.buttonDeleteSelect} onClick={onDeleteAllSelects}>
            {LangConstant.TXT_DELETE_SELECT}
          </Button>
        </Box>

        {selectedTypeAction.length > 0 ? (
          <Box sx={{ display: "flex", marginTop: 2, maxWidth: "70%" }}>
            <Box sx={{ width: 350, marginRight: "30%" }}>
              <Typography className={classes.label}> {LangConstant.TXT_DATA_SHIP} </Typography>

              <Box className={classes.dataList} onClick={() => setIsOpenMonitoringShips(!isOpenMonitoringShips)}>
                {LangConstant.TXT_MONITORING_LIST} <KeyboardArrowDown />
              </Box>
              <Box
                className={clsx(classes.optionShow, { [classes.optionHidden]: !isOpenMonitoringShips })}
                ref={monitoringShipRef}
              >
                <FilterGroupShip />
              </Box>

              <Box className={classes.dataList} onClick={() => setIsOpenShipTypeList(!isOpenShipTypeList)}>
                {LangConstant.TXT_SHIP_TYPE} <KeyboardArrowDown />
              </Box>
              <Box
                className={clsx(classes.optionShow, {
                  [classes.optionHidden]: !isOpenShipTypeList,
                  [classes.optionScroll]: true,
                })}
                ref={shipTypeRef}
              >
                <FilterByShipType />
              </Box>
              <Box className={classes.dataListBox}>
                <Box className={classes.dataList} onClick={() => setIsOpenCountryCodeList(!isOpenCountryCodeList)}>
                  {LangConstant.TXT_NATIONALITY} <KeyboardArrowDown />
                </Box>
                <Box
                  className={clsx(classes.optionShow, {
                    [classes.optionHidden]: !isOpenCountryCodeList,
                    [classes.optionScrollBox]: true,
                  })}
                  ref={countryCodeRef}
                >
                  <FilterByManyNation />
                </Box>
              </Box>

              <Box className={classes.dataList} onClick={() => setIsOpenFilterCapacity(!isOpenFilterCapacity)}>
                {LangConstant.TXT_WEIGHT} (Tấn) <KeyboardArrowDown />
              </Box>
              <Box
                className={clsx(classes.optionShow, { [classes.optionHidden]: !isOpenFilterCapacity })}
                ref={capacityRef}
              >
                <FilterByCapacity
                  data={{ min: parseInt(shipConditions.capacity_min), max: parseInt(shipConditions.capacity_max) }}
                />
              </Box>
            </Box>
            <Box sx={{ width: 350 }}>
              <Typography className={classes.label}> {LangConstant.TXT_DATA_AREA} </Typography>

              <Box
                className={
                  selectedTypeAction.includes(SystemConstant.TYPE_ACTION_WARNING.MOVE_IN_AREA.id) ||
                  selectedTypeAction.includes(SystemConstant.TYPE_ACTION_WARNING.MOVE_OUT_AREA.id)
                    ? classes.dataList
                    : classes.dataListDisable
                }
                onClick={() =>
                  (selectedTypeAction.includes(SystemConstant.TYPE_ACTION_WARNING.MOVE_IN_AREA.id) ||
                    selectedTypeAction.includes(SystemConstant.TYPE_ACTION_WARNING.MOVE_OUT_AREA.id)) &&
                  setIsOpenSeaArea(!isOpenSeaArea)
                }
              >
                {LangConstant.TXT_SEA_AREA} <KeyboardArrowDown />
              </Box>
              <Box
                className={clsx(classes.optionShow, {
                  [classes.optionHidden]: !isOpenSeaArea,
                  [classes.optionShowOverride]: true,
                })}
                ref={seaAreaRef}
              >
                <SeaArea />
              </Box>

              <Box
                className={
                  selectedTypeAction.includes(SystemConstant.TYPE_ACTION_WARNING.MOVE_IN_AREA.id) ||
                  selectedTypeAction.includes(SystemConstant.TYPE_ACTION_WARNING.MOVE_OUT_AREA.id)
                    ? classes.dataList
                    : classes.dataListDisable
                }
                onClick={() =>
                  (selectedTypeAction.includes(SystemConstant.TYPE_ACTION_WARNING.MOVE_IN_AREA.id) ||
                    selectedTypeAction.includes(SystemConstant.TYPE_ACTION_WARNING.MOVE_OUT_AREA.id)) &&
                  setIsOpenMonitoringArea(!isOpenMonitoringArea)
                }
              >
                {LangConstant.TXT_MONITORING_AREA} <KeyboardArrowDown />
              </Box>
              <Box
                className={clsx(classes.optionShow, {
                  [classes.optionHidden]: !isOpenMonitoringArea,
                  [classes.optionShowOverride]: true,
                })}
                ref={monitoringAreaRef}
              >
                <MonitoringArea />
              </Box>
              <Box className={classes.dataListBox}>
                <Box
                  className={
                    selectedTypeAction.includes(SystemConstant.TYPE_ACTION_WARNING.MOVE_IN_AREA.id) ||
                    selectedTypeAction.includes(SystemConstant.TYPE_ACTION_WARNING.MOVE_OUT_AREA.id)
                      ? classes.dataList
                      : classes.dataListDisable
                  }
                  onClick={() =>
                    (selectedTypeAction.includes(SystemConstant.TYPE_ACTION_WARNING.MOVE_IN_AREA.id) ||
                      selectedTypeAction.includes(SystemConstant.TYPE_ACTION_WARNING.MOVE_OUT_AREA.id)) &&
                    setIsOpenPortList(!isOpenPortList)
                  }
                >
                  {LangConstant.TXT_PORT} <KeyboardArrowDown />
                </Box>
                <Box
                  className={clsx(classes.optionShow, {
                    [classes.optionHidden]: !isOpenPortList,
                    [classes.optionScrollBox]: true,
                  })}
                  ref={portRef}
                >
                  <FilterByPort />
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Typography sx={{ color: "red" }}>*{LangConstant.TXT_REQUIRED_CHOOSED_TYPE_ACTION}</Typography>
        )}
      </Box>

      <Box sx={{ marginTop: 8, paddingRight: "10%", display: "flex", justifyContent: "flex-end" }}>
        <Button
          sx={{ color: "red", border: "1px solid", marginRight: "5%" }}
          onClick={() => {
            if (
              eventWarningName !== data.name ||
              minSpeed !== data.min_speed ||
              maxSpeed !== data.max_speed ||
              rangeCourse !== data.range_course ||
              rangeDraft !== data.range_draft ||
              selectedTypeAction.toString() !==
                Object.values(data.action_list)
                  .map(obj => obj.id)
                  .toString() ||
              selectedAreaList !== data.area_list ||
              isReceiveNotify !== data.receive_notification_f
            ) {
              setIsOpenCancelConfirm(true);
              return;
            }
            onClickClose();
          }}
        >
          {LangConstant.TXT_BUTTON_CANCEL}
        </Button>
        <Button
          sx={{ color: "cornflowerblue", border: "1px solid" }}
          onClick={() => onClickUpdate()}
          disabled={!enableButtonUpdate()}
        >
          {LangConstant.TXT_BUTTON_UPDATE}
        </Button>
      </Box>
      <ConfirmCancelDialog
        open={isOpenCancelConfirm}
        onClose={() => onClickClose()}
        onCancel={() => setIsOpenCancelConfirm(false)}
      />
    </>
  );
};

export default EditEventWarning;

const useStyles = makeStyles(theme => ({
  label: {
    marginLeft: "6px",
    fontWeight: "bold",
  },

  typeEventWarningLabel: {
    paddingLeft: 6,
    paddingRight: 6,
    maxWidth: 400,
    height: "40px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    border: "1px solid gainsboro",
  },

  typeEventWarningList: {
    padding: 6,
    maxWidth: 400,
    minWidth: 400,
    minHeight: 400,
    position: "absolute",
    border: "1px solid cornflowerblue",
    borderRadius: "5px",
    backgroundColor: "ghostwhite",
    zIndex: "2",
  },

  dataList: {
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 12,
    height: "40px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    border: "1px solid gainsboro",
    backgroundColor: "cornflowerblue",
    color: "white",
  },

  dataListDisable: {
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 12,
    height: "40px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    border: "1px solid gainsboro",
    backgroundColor: "#9e9e9e",
    color: "white",
  },

  optionShow: {
    display: "block",
    maxHeight: 350,
    borderRight: "unset",
    position: "absolute",
    backgroundColor: "white",
    width: "370px",
    zIndex: 999,
  },

  optionHidden: {
    display: "none",
  },

  optionShowOverride: {
    backgroundColor: "transparent",
    "& >div": {
      marginLeft: "0px",
      backgroundColor: "white",
    },
  },

  optionScroll: {
    height: "220px",
    overflowY: "scroll",
    border: "1px solid #0033",
  },

  buttonDeleteSelect: {
    marginLeft: "16px",
    textTransform: "inherit",
  },
  dataListBox: {
    position: "relative",
  },
  optionScrollBox: {
    position: "absolute",
    top: "-220px",
    height: "220px",
    overflowY: "scroll",
    border: "1px solid #0033",
  },
}));
