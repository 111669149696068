import { ApiConstant } from "const";
import StringFormat from "string-format";
import { createApiDownloadExcel, createApiUploadFile, createApiWithToken } from "services/config/api.config";

export const getUserInfo = data => createApiWithToken().get(StringFormat(ApiConstant.USER, data));

export const exportUsersInfo = data => createApiDownloadExcel().get(ApiConstant.GET_FILTER_USERS, data);

export const exportUserInfo = data => createApiDownloadExcel().get(ApiConstant.EXPORT_USERS_INFO, data);

export const changeAvatar = data => createApiUploadFile().put(ApiConstant.CHANGE_AVATAR, data);

export const changeTwoFA = () => createApiWithToken().post(ApiConstant.CHANGE_TWO_FA);

export const filterUsers = data => createApiWithToken().get(ApiConstant.GET_FILTER_USERS, data);
