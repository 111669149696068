import React, { createContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import L from "leaflet";
import { convertKmToNm, dragElement } from "utils";
import { AppConstant, EventConstant } from "const";
import AreaAction from "redux-store/area.redux";
import EventWarningAction from "redux-store/eventwarning.redux";
import Navbar from "./NavBar";
import Sidebar from "./Sidebar";
import Map from "./Map";
import leafletDrawI18n from "static/leaflet-draw-i18n.json";
import LayerAreaManagementDialog from "./Map/layers/LayerAreaManagementDialog";
import LayerWatchListSettingDialog from "./Map/layers/LayerWatchListSettingDialog";
import "leaflet-ruler";
import WeatherActions from "redux-store/weather.redux";
import LayerBackgroundMapSettingDialog from "./Map/layers/LayerBackgroundMapSettingDialog";
import LayerCreatePointDialog from "./Map/layers/LayerCreatePointDialog";
import { EventBus } from "EventBus";
import LayerWeatherPointDialog from "./Map/layers/LayerWeatherPointDialog";
import LayerMenuRightClick from "./Map/layers/LayerMenuRightClick";

export const MapContext = createContext({});

export default function HomePage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const resizeRef = useRef(null);
  const mapRef = useRef(null);
  const weatherLayerRef = useRef(L.layerGroup());
  const areaLayerRef = useRef(L.layerGroup());
  const trackingLayerRef = useRef(L.layerGroup());
  const drawLayerRef = useRef(L.featureGroup());
  const placeholderCanvasLayerRef = useRef(L.layerGroup());
  const captureLayerRef = useRef(L.featureGroup());
  const canvasLayerRef = useRef(new L.MarkersCanvas());
  const placeholderVectorLayerRef = useRef(L.layerGroup());
  const pointLayerRef = useRef(new L.MarkersCanvas());
  const animateMarker = useRef();
  const flickerLayerRef = useRef(L.layerGroup());
  const hoverLayerRef = useRef(L.layerGroup());
  const watchListLayerRef = useRef(new L.MarkersCanvas());
  const vectorLayerRef = useRef(new L.MarkersCanvas());
  const trackkingPointLayerRef = useRef(L.layerGroup());

  const clusteredLayerRef = useRef(
    new L.MarkerClusterGroup({
      maxClusterRadius: 100,
      disableClusteringAtZoom: 12,
      removeOutsideVisibleBounds: true,
      chunkedLoading: true,
      chunkDelay: 80,
      chunkInterval: 240,
      animate: false,
      animateAddingMarkers: false,
    }),
  );

  L.Draw.Circle.include({
    _onMouseMove: function (e) {
      var latlng = e.latlng,
        showRadius = this.options.showRadius,
        useMetric = this.options.metric,
        radius;
      if (!this._startLatLng) {
        return;
      }

      // Get the center of the circle
      let center = this._startLatLng;

      // Check if the mouse position is defined
      if (!e.latlng) {
        return;
      }

      // Get the mouse position
      let mousePos = e.latlng;

      // Calculate the angle in radians
      let angleRad = Math.atan2(mousePos.lat - center.lat, mousePos.lng - center.lng);

      // Convert the angle to degrees
      let angleDeg = angleRad * (180 / Math.PI);

      // Adjust the angle so that 0 degrees is up
      angleDeg -= 90;
      if (angleDeg < 0) {
        angleDeg += 360;
      }
      this._tooltip?.updatePosition(latlng);
      if (this._isDrawing) {
        this._drawShape(latlng);

        // Get the new radius (rounded to 1 dp)
        radius = this._shape.getRadius().toFixed(1);
        console.log(this.options);
        var subtext = "";
        if (showRadius) {
          subtext =
            L.drawLocal.draw.handlers.circle.radius +
            ": " +
            L.GeometryUtil.readableDistance(radius, useMetric, this.options.feet, this.options.nautic) +
            "</br>" +
            L.drawLocal.draw.handlers.circle.angle +
            ": " +
            angleDeg.toFixed(1) +
            "°";
        }
        this._tooltip.updateContent({
          text: this._endLabelText,
          subtext: subtext,
        });
      }
    },
  });
  const drawControlRef = useRef(
    new L.Control.Draw({
      edit: {
        featureGroup: drawLayerRef.current,
        edit: true,
        remove: true,
      },
      draw: {
        marker: false,
        circlemarker: false,
        polygon: {
          metric: true,
        },
        polyline: false,
        rectangle: {
          metric: true,
        },
        circle: {
          metric: true,
        },
      },
    }),
  );

  const [isOpenStatisticalReport, setIsOpenStatisticalReport] = useState(false);
  const [isOpenStatisticalDetail, setIsOpenStatisticalDetail] = useState(false);
  const [activeStatisticalShip, setActiveStatisticalShip] = useState([]);
  const [statisticalReportArea, setStatisticalReportArea] = useState("");
  const [fromDay, setFromDay] = useState(Date.now() - 86400000 * 7); // last 7 days
  const [toDay, setToDay] = useState(Date.now());
  const [isShowSidebar, setIsShowSidebar] = useState(true);
  const [mapState, setMapState] = useState("idle");
  const [isShowWeather, setIsShowWeather] = useState(
    localStorage.getItem(AppConstant.LOCAL_STORAGE_KEY.settingShowWeather) === "true",
  );
  const [isShowShip, setIsShowShip] = useState(
    localStorage.getItem(AppConstant.LOCAL_STORAGE_KEY.settingShowShip) !== "false",
  );
  const [isClusteredShip, setIsClusteredShip] = useState(
    localStorage.getItem(AppConstant.LOCAL_STORAGE_KEY.settingShowClusteredShip) !== "false",
  );
  const [isShowShipName, setIsShowShipName] = useState(
    localStorage.getItem(AppConstant.LOCAL_STORAGE_KEY.settingShowShipName) === "true",
  );
  const [isShowShipSpeed, setIsShowShipSpeed] = useState(
    localStorage.getItem(AppConstant.LOCAL_STORAGE_KEY.settingShowSpeed) !== "false",
  );
  const [isOpenAreaManagementDialog, setIsOpenAreaManagementDialog] = useState(false);
  const [hoverShip, setHoverShip] = useState({ info: null, event: null });
  const [clickedShip, setClickedShip] = useState({ info: null, event: null });
  const [areaManagementConfigs, setAreaManagementConfigs] = useState({ status: "idle" });
  const [selectedAreaList, setSelectedAreaList] = useState(undefined);
  const [sidebarMode, setSidebarMode] = useState("management");
  const [videoTrackingOptions, setVideoTrackingOptions] = useState(DEFAULT_VIDEO_TRACKING_CONFIGS);
  const [showMenuLayer, setShowMenuLayer] = useState(false);
  const [zoomSize, setZoomSize] = useState(6);
  const [showMenuWatchList, setShowMenuWatchList] = useState(false);
  const [turnOnMeasure, setTurnOnMeasure] = useState(false);
  const [showOtherShip, setShowOtherShip] = useState(
    localStorage.getItem(AppConstant.LOCAL_STORAGE_KEY.showOtherShip) !== "false",
  );
  const [showBackgroundMapList, setShowBackgroundMapList] = useState(false);
  const [chooseBackgroundMap, setChooseBackgroundMap] = useState(
    localStorage.getItem(AppConstant.LOCAL_STORAGE_KEY.backgroundMap)
      ? localStorage.getItem(AppConstant.LOCAL_STORAGE_KEY.backgroundMap)
      : AppConstant.MAP_URL,
  );
  const [hoverPoint, setHoverPoint] = useState({ info: null, event: null });
  const [metresPerPixel, setMetresPerPixel] = useState(2356);
  const [weatherDialog, setWeatherDialog] = useState({ location: null });

  useEffect(() => {
    localStorage.setItem(AppConstant.LOCAL_STORAGE_KEY.settingShowWeather, isShowWeather);
    if (isShowWeather) dispatch(WeatherActions.getWeatherData());
  }, [isShowWeather]);
  useEffect(() => {
    localStorage.setItem(AppConstant.LOCAL_STORAGE_KEY.settingShowShipName, isShowShipName);
  }, [isShowShipName]);
  useEffect(() => {
    localStorage.setItem(AppConstant.LOCAL_STORAGE_KEY.settingShowSpeed, isShowShipSpeed);
  }, [isShowShipSpeed]);
  useEffect(() => {
    localStorage.setItem(AppConstant.LOCAL_STORAGE_KEY.settingShowShip, isShowShip);
  }, [isShowShip]);
  useEffect(() => {
    localStorage.setItem(AppConstant.LOCAL_STORAGE_KEY.settingShowClusteredShip, isClusteredShip);
  }, [isClusteredShip]);
  useEffect(() => {
    localStorage.setItem(AppConstant.LOCAL_STORAGE_KEY.showOtherShip, showOtherShip);
  }, [showOtherShip]);
  useEffect(() => {
    if (selectedAreaList === undefined) return;
    localStorage.setItem(
      AppConstant.LOCAL_STORAGE_KEY.selectedAreaList,
      selectedAreaList?.map(area => area?.uid),
    );
  }, [selectedAreaList]);

  const toggleShowShipManage = () => {
    setIsShowSidebar(!isShowSidebar);
  };
  const ResetZoomControl = L.Control.extend({
    options: {
      position: "topleft", // Position of the control
    },

    onAdd: function (map) {
      // Create a control button
      const controlButton = L.DomUtil.create("button", "leaflet-bar");
      controlButton.innerHTML = ` <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="24px" height="24px" viewBox="0 0 32 32" id="icon"><defs><style>.cls-1{fill:none;}</style></defs><title>Trở về điểm mặc định</title><polygon points="6 12 4 12 4 4 12 4 12 6 6 6 6 12"/><polygon points="28 12 26 12 26 6 20 6 20 4 28 4 28 12"/><polygon points="12 28 4 28 4 20 6 20 6 26 12 26 12 28"/><polygon points="28 28 20 28 20 26 26 26 26 20 28 20 28 28"/><rect x="15" y="10" width="2" height="4"/><rect x="10" y="15" width="4" height="2"/><rect x="18" y="15" width="4" height="2"/><rect x="15" y="18" width="2" height="4"/><rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32"/></svg>`;
      controlButton.style.backgroundColor = "white";
      controlButton.style.padding = "3px";

      // Attach a click event to the control button
      L.DomEvent.on(controlButton, "click", function () {
        map.setView(AppConstant.DEFAULT_POSITION, 6); // Reset the view to the default position and zoom level
      });

      return controlButton;
    },
  });

  useEffect(() => {
    dispatch(AreaAction.requestGetDefaultArea());
    dispatch(AreaAction.requestGetCustomArea());
    dispatch(AreaAction.requestGetPointArea());
    dispatch(EventWarningAction.getTopEventWarningNotifications());
  }, []);

  useEffect(() => {
    if (mapRef.current === null) {
      L.Control.Scale.include({
        // DO NOT using arrow function in this one
        _updateMetric: function (maxMeters) {
          const maxMilliMeters = maxMeters;
          const milliMeters = this._getRoundNum(maxMilliMeters);
          const label = ((milliMeters / 1000) * 0.53996).toFixed(1) + " nm";
          this._updateScale(this._mScale, label, milliMeters / maxMilliMeters);
        },
      });

      // DO NOT using arrow function in this one
      L.GeometryUtil.readableDistance = function (distance) {
        return `${convertKmToNm(distance / 1000)} nm`;
      };

      L.drawLocal = leafletDrawI18n;

      const map = L.map("map", { preferCanvas: true });
      const tileUrl = chooseBackgroundMap ? chooseBackgroundMap : AppConstant.MAP_URL;
      const tileLayer = L.tileLayer(tileUrl, { maxZoom: 22, minZoom: 1, attribution: "" });
      const scale = L.control.scale();
      const resetZoomControl = new ResetZoomControl();

      map.addControl(resetZoomControl);
      map.setView(AppConstant.DEFAULT_POSITION, 6);
      map.addLayer(tileLayer);
      map.addLayer(watchListLayerRef.current);
      map.addLayer(placeholderCanvasLayerRef.current);
      map.addLayer(trackingLayerRef.current);
      map.addLayer(canvasLayerRef.current);
      map.addLayer(placeholderVectorLayerRef.current);
      map.addLayer(vectorLayerRef.current);
      map.addLayer(drawLayerRef.current);
      map.addLayer(weatherLayerRef.current);
      map.addLayer(areaLayerRef.current);
      map.addLayer(clusteredLayerRef.current);
      map.addLayer(captureLayerRef.current);
      map.addLayer(flickerLayerRef.current);
      map.addLayer(hoverLayerRef.current);
      map.addControl(scale);
      map.addControl(drawControlRef.current);
      map.addLayer(pointLayerRef.current);
      map.addLayer(trackkingPointLayerRef.current);

      // Step 1: Define a custom ruler control class
      L.Control.CustomRuler = L.Control.Ruler.extend({
        _toggleMeasure: function (e) {
          // Your custom logic here
          console.log("_toggleMeasure", this._choice);
          // Call the original _toggleMeasure method
          L.Control.Ruler.prototype._toggleMeasure.call(this, e);
          if (this._choice) {
            this._tempCircle = L.featureGroup().addTo(this._allLayers);
            setTurnOnMeasure(true);
            EventBus.on(EventConstant.POINT_EVENTS.clickMeasure, this._clicked.bind(this));
          } else {
            setTurnOnMeasure(false);
            EventBus.off(EventConstant.POINT_EVENTS.clickMeasure, this._clicked.bind(this));
          }
        },
        _clicked: function (e) {
          L.Control.Ruler.prototype._clicked.call(this, e);
        },
        _moving: function (e) {
          L.Control.Ruler.prototype._moving.call(this, e);
          if (this._clickedLatLong) {
            if (this._tempCircle) {
              this._map.removeLayer(this._tempCircle);
            }
            this._tempCircle = L.featureGroup().addTo(this._map);

            var distanceInMeters = this._clickedLatLong.distanceTo(e.latlng); // Khoảng cách trong mét
            var distanceInNauticalMiles = distanceInMeters / 1852; // Chuyển đổi sang hải lý
            var distanceStep = Math.round(distanceInNauticalMiles / 50) * 10; // Làm tròn lên số chia hết cho 10 gần nhất
            distanceStep = Math.max(distanceStep, 10); // Đảm bảo bước nhảy tối thiểu là 10

            for (var radius = distanceStep; radius <= distanceInNauticalMiles; radius += distanceStep) {
              L.circle(this._clickedLatLong, {
                radius: radius * 1852, // Chuyển đổi hải lý sang mét
                fillOpacity: 0, // Độ trong suốt của vùng bên trong vòng tròn
                weight: 1,
                color: "#d76d27",
              }).addTo(this._tempCircle);
            }

            if (distanceInNauticalMiles % distanceStep !== 0) {
              L.circle(this._clickedLatLong, {
                radius: distanceInNauticalMiles * 1852, // Bán kính chính xác bằng khoảng cách hiện tại
                fillOpacity: 0.1, // Độ trong suốt của vùng bên trong vòng tròn
                color: "#d76d27",
              }).addTo(this._tempCircle);
            }

            // Bán kính Trái Đất trong mét
            var earthRadius = 6378137;
            var deltaLongitude =
              ((distanceInMeters / earthRadius) * (180 / Math.PI)) /
              Math.cos((this._clickedLatLong.lat * Math.PI) / 180);
            var deltaLatitude = (distanceInMeters / earthRadius) * (180 / Math.PI);

            var pointEast = L.latLng(this._clickedLatLong.lat, this._clickedLatLong.lng + deltaLongitude);
            var pointWest = L.latLng(this._clickedLatLong.lat, this._clickedLatLong.lng - deltaLongitude);
            var pointNorth = L.latLng(this._clickedLatLong.lat + deltaLatitude, this._clickedLatLong.lng);
            var pointSouth = L.latLng(this._clickedLatLong.lat - deltaLatitude, this._clickedLatLong.lng);

            if (!this._tempLine) {
              this._tempLine = L.featureGroup().addTo(this._map);
            }

            let polyline = L.polyline([pointWest, pointEast], { weight: 1, color: "#d76d27" }).addTo(this._tempLine);
            L.polyline([pointSouth, pointNorth], { weight: 1, color: "#d76d27" }).addTo(this._tempLine);
            polyline
              .bindTooltip(`${distanceStep} nm`, {
                permanent: true, // This makes the tooltip always visible
                direction: "left", // This positions the tooltip in the center of the polyline
                className: "no-background-tooltip", // Apply custom CSS class
              })
              .openTooltip();
          }
        },
        _closePath: function (e) {
          this._map.removeLayer(this._tempCircle);
          L.Control.Ruler.prototype._closePath.call(this, e);
        },
      });

      map.addControl(
        new L.Control.CustomRuler({
          lengthUnit: {
            display: "nm",
            decimal: 2,
            factor: 0.5399568,
            label: "Khoảng cách:",
          },
          angleUnit: {
            display: "&deg;",
            decimal: 2,
            factor: null,
            label: "Hướng:",
          },
          lineStyle: {
            color: "#d76d27",
            dashArray: "1,6",
          },
          circleMarker: {
            color: "#843521",
            radius: 2,
          },
        }),
      );
      mapRef.current = map;
      setMapState("loaded");
    }
  }, [mapRef.current]);

  // TODO: tạm thêm vào để bắt các trường hợp app bị crash do bỗng nhiên map.current trở thành undefined (chưa điều tra ra), và những lỗi tương tự, khi bị crash thì reload lại trang để load lại tài nguyên cần thiết, sau này cần phải tìm ra nguyên nhân thực sự
  window.onerror = function (message, source, lineno, colno, error) {
    if (message.includes("Cannot read properties of undefined")) {
      window.location.reload();
    }
  };

  useEffect(() => {
    resizeRef.current = setTimeout(() => {
      if (mapRef.current) {
        mapRef.current.invalidateSize();
      }
    }, 100);

    return () => {
      clearTimeout(resizeRef.current);
    };
  }, [isShowSidebar, mapRef.current]);

  useEffect(() => {
    if (chooseBackgroundMap !== null) {
      mapRef.current.eachLayer(layer => {
        if (layer._url && layer._tiles) mapRef.current.removeLayer(layer);
      });

      let tileLayer = L.tileLayer(chooseBackgroundMap, { maxZoom: 22, minZoom: 1, attribution: "" });
      mapRef.current.addLayer(tileLayer.bringToBack());
    }
  }, [chooseBackgroundMap]);

  const mapProviderValues = {
    map: mapRef,
    placeholderCanvasLayerRef: placeholderCanvasLayerRef,
    canvasLayerRef: canvasLayerRef,
    placeholderVectorLayerRef: placeholderVectorLayerRef,
    vectorLayerRef: vectorLayerRef,
    clusteredLayer: clusteredLayerRef,
    drawLayer: drawLayerRef,
    weatherLayer: weatherLayerRef,
    drawControl: drawControlRef,
    areaLayer: areaLayerRef,
    trackingLayer: trackingLayerRef,
    captureLayer: captureLayerRef,
    flickerLayerRef: flickerLayerRef,
    hoverLayerRef: hoverLayerRef,
    watchListLayerRef: watchListLayerRef,
    pointLayerRef: pointLayerRef,
    trackkingPointLayerRef: trackkingPointLayerRef,
    mapState,
    isShowWeather,
    setIsShowWeather,
    isShowShip,
    setIsShowShip,
    isClusteredShip,
    setIsClusteredShip,
    isShowShipName,
    setIsShowShipName,
    isShowShipSpeed,
    setIsShowShipSpeed,
    hoverShip,
    setHoverShip,
    clickedShip,
    setClickedShip,
    isOpenStatisticalReport,
    setIsOpenStatisticalReport,
    dragElement,
    activeStatisticalShip,
    setActiveStatisticalShip,
    isOpenStatisticalDetail,
    setIsOpenStatisticalDetail,
    statisticalReportArea,
    setStatisticalReportArea,
    fromDay,
    setFromDay,
    toDay,
    setToDay,
    isShowSidebar,
    setIsShowSidebar,
    toggleShowShipManage,
    isOpenAreaManagementDialog,
    setIsOpenAreaManagementDialog,
    areaManagementConfigs,
    setAreaManagementConfigs,
    selectedAreaList,
    setSelectedAreaList,
    sidebarMode,
    setSidebarMode,
    videoTrackingOptions,
    setVideoTrackingOptions,
    animateMarker,
    showMenuLayer,
    setShowMenuLayer,
    zoomSize,
    setZoomSize,
    showMenuWatchList,
    setShowMenuWatchList,
    showOtherShip,
    setShowOtherShip,
    showBackgroundMapList,
    setShowBackgroundMapList,
    chooseBackgroundMap,
    setChooseBackgroundMap,
    hoverPoint,
    setHoverPoint,
    turnOnMeasure,
    setTurnOnMeasure,
    metresPerPixel,
    setMetresPerPixel,
    weatherDialog,
    setWeatherDialog,
  };

  return (
    <MapContext.Provider value={mapProviderValues}>
      <Box className={classes.container}>
        <Navbar />
        <Box className={classes.bodyContainer}>
          <Sidebar />
          <Map />
          <LayerAreaManagementDialog />
          <LayerWatchListSettingDialog />
          <LayerBackgroundMapSettingDialog />
          <LayerCreatePointDialog />
          <LayerMenuRightClick />
          <LayerWeatherPointDialog />
        </Box>
      </Box>
      <canvas id={VIDEO_CANVAS_PREVIEW_ID} className={classes.canvasLayer} />
    </MapContext.Provider>
  );
}

export const DEFAULT_VIDEO_TRACKING_CONFIGS = {
  open: false,
  shipUid: "",
  shipName: "",
  shipMMSI: "",
  speeds: [],
  play: false,
  totalDuration: 0,
  durationSteps: [],
  positions: [],
  speed: 1,
  showNamePopup: false,
  showMMSIPopup: false,
  showSpeedPopup: false,
};

export const VIDEO_CANVAS_PREVIEW_ID = "map-video-canvas-preview-id";

const useStyles = makeStyles(theme => ({
  container: {
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
  },

  bodyContainer: {
    width: "100vw",
    height: "calc(100vh - 60px)",
    display: "flex",
    marginTop: "60px",
  },

  errorsContainer: {
    position: "fixed",
    bottom: 8,
    right: 8,
    zIndex: 1000,
  },

  snackbarRoot: {
    position: "relative",
    marginBottom: 8,
  },

  canvasLayer: {
    width: "100vw",
    height: "auto",
  },
}));
