import { Box, Button, Grid, IconButton, InputLabel, OutlinedInput, Typography } from "@mui/material";
import { Download } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import ConversationRedux from "redux-store/conversation.redux";
import UserRedux from "redux-store/user.redux";
import { useNotification } from "hooks";
import { AppConstant, LangConstant, PathConstant } from "const";
import DialogChangePassword from "./DialogChangePassword";
import Cookies from "js-cookie";
import { generatePath, useLocation } from "react-router";
import { Link } from "react-router-dom";

const ShowDetailsProfile = ({ userInfo }) => {
  const dispatch = useDispatch();
  const notification = useNotification();
  const location = useLocation();

  const isChangeTwoFASuccess = useSelector(state => state.userRedux.isChangeTwoFASuccess);

  const classes = useStyles();
  const [address, setAddress] = useState(userInfo?.address || "");
  const [isOpenDialogPass, setIsOpenDialogPass] = useState(false);
  const [isDisableSave, setIsDisableSave] = useState(true);
  const [addressError, setAddressError] = useState("");

  const onExportUserInfo = () => {
    dispatch(
      UserRedux.exportUserInfo({
        user_uids: userInfo.uid,
      }),
    );
  };

  const onChangeAddress = event => {
    setAddress(event.target.value);
    setIsDisableSave(false);
  };

  const onUpdateAddress = () => {
    const regexAddress = /^[\p{L}0-9, _\/]{1,255}$/u;
    if (regexAddress.test(address) || !address) {
      dispatch(
        ConversationRedux.editUser({
          ...userInfo,
          address: address,
          role: userInfo.power,
          id: userInfo.uid,
        }),
      );
      setAddressError("");
    } else {
      setAddressError(LangConstant.MS_ADDRESS_INVALID);
    }
  };

  const onChangeTwoFA = () => {
    dispatch(UserRedux.changeTwoFA());
  };

  const onOpenDialogPass = () => {
    setIsOpenDialogPass(true);
  };

  const onCloseDialogPass = () => {
    setIsOpenDialogPass(false);
  };

  useEffect(() => {
    if (isChangeTwoFASuccess) {
      dispatch(UserRedux.getUserInfo(Cookies.get(AppConstant.KEY_USER_ID)));
      notification.send(
        userInfo?.otpOptionF === AppConstant.OTP_2FA_MODE.on
          ? LangConstant.MS_TURN_OFF_2FA_SUCCESS
          : LangConstant.MS_TURN_ON_2FA_SUCCESS,
        "success",
      );
    }
    dispatch(
      UserRedux.userSet({
        isChangeTwoFASuccess: false,
      }),
    );
  }, [isChangeTwoFASuccess]);

  return (
    <Box className={classes.containerDetailsProfile}>
      <Box className={classes.headerDetailsProfile}>
        <Typography variant="h3">
          {location.pathname === PathConstant.ADMIN_PROFILE
            ? LangConstant.TXT_USER_CURRENT_PROFILE
            : LangConstant.TXT_USER_PROFILE}
        </Typography>
        <Button
          variant="contained"
          color="warning"
          className={classes.buttonDownloadProfile}
          onClick={onExportUserInfo}
        >
          <IconButton>
            <Download className={classes.icon} />
          </IconButton>
          {LangConstant.TXT_EXPORT_PROFILE}
        </Button>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box className={classes.inputProfile}>
            <InputLabel>{LangConstant.TXT_NAME}</InputLabel>
            <OutlinedInput disabled fullWidth value={userInfo?.name || ""} />
          </Box>
          <Box className={classes.inputProfile}>
            <InputLabel>{LangConstant.TXT_EMAIL}</InputLabel>
            <OutlinedInput disabled fullWidth value={userInfo?.email || ""} />
          </Box>
          <Box className={classes.inputProfile}>
            <InputLabel>{LangConstant.TXT_USER_RIGHTS}</InputLabel>
            <OutlinedInput disabled fullWidth value={AppConstant.ROLE_NAME[userInfo?.power] || ""} />
          </Box>
          <Box className={classes.inputProfile}>
            <InputLabel>{LangConstant.TXT_TIME_SYSTEM}</InputLabel>
            <OutlinedInput disabled fullWidth value={userInfo?.department || ""} />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className={classes.inputProfile}>
            <InputLabel>{LangConstant.TXT_ACCOUNT}</InputLabel>
            <OutlinedInput disabled fullWidth value={userInfo?.email || ""} />
          </Box>
          <Box className={classes.inputProfile}>
            <InputLabel>{LangConstant.TXT_NUMBER}</InputLabel>
            <OutlinedInput disabled fullWidth value={userInfo?.phoneNumber || ""} />
          </Box>
          <Box className={classes.inputProfile}>
            <InputLabel>{LangConstant.TXT_ADDRESS}</InputLabel>
            <OutlinedInput
              disabled={location.pathname !== PathConstant.ADMIN_PROFILE}
              fullWidth
              value={location.pathname === PathConstant.ADMIN_PROFILE ? address : userInfo?.address || ""}
              onChange={onChangeAddress}
              inputProps={{ maxLength: 255 }}
            />
            {addressError && <Box className={classes.errorMessage}>{addressError}</Box>}
          </Box>
          <Box className={classes.inputProfile}>
            <InputLabel>{LangConstant.TXT_UNIT}</InputLabel>
            <OutlinedInput disabled fullWidth value={userInfo?.unit || ""} />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className={classes.inputProfile}>
            <Typography variant="h5">{LangConstant.TXT_TRIOS}</Typography>
            <InputLabel>{LangConstant.TXT_NUMBER}</InputLabel>
            <OutlinedInput disabled fullWidth value={userInfo?.triosPhoneNumber ? userInfo?.triosPhoneNumber : ""} />
          </Box>
        </Grid>
      </Grid>
      {location.pathname === PathConstant.ADMIN_PROFILE ? (
        <Box className={classes.buttonSubmit}>
          <Box>
            <Button variant="contained" color="warning" onClick={onOpenDialogPass}>
              {LangConstant.TXT_BUTTON_CHANGE_PASSWORD}
            </Button>
          </Box>
          <Box>
            <Button variant="contained" color="warning" onClick={onUpdateAddress} disabled={isDisableSave}>
              {LangConstant.TXT_SAVE}
            </Button>
          </Box>
        </Box>
      ) : (
        <Button variant="contained" color="warning" className={classes.linkUserHistory}>
          <Link
            to={generatePath(PathConstant.ADMIN_USER_ACTION_PERSONAL, {
              userId: userInfo?.uid || "",
            })}
          >
            {LangConstant.TXT_USER_ACTION_PERSONAL}
          </Link>
        </Button>
      )}
      {isOpenDialogPass && <DialogChangePassword onCloseDialogPass={onCloseDialogPass} />}
    </Box>
  );
};

export default ShowDetailsProfile;

const useStyles = makeStyles(() => ({
  containerDetailsProfile: {
    padding: "30px",
    marginRight: "50px",
  },

  headerDetailsProfile: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  buttonDownloadProfile: {
    height: "fit-content",
    padding: "0px 16px",
  },

  icon: {
    color: "#ffffff",
  },

  buttonSubmit: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 32,
  },

  buttonOn2FA: {
    marginRight: "24px",
  },

  inputProfile: {
    marginTop: "16px",
    "& input": {
      padding: "12px",
    },
  },

  linkUserHistory: {
    marginTop: 32,
    "& a": {
      color: "white",
    },
  },

  errorMessage: {
    fontSize: 12,
    color: "red",
  },
}));
