import React, { useEffect, memo } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  Typography,
  TextField,
  Dialog,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import * as turf from "@turf/turf";

const LayerCreateNewAreaDialog = ({
  open,
  name,
  latlngs,
  mode,
  onChangeAreaName,
  onChangeLatInput,
  onChangeLngInput,
  onChangeAreaInput,
  onClose,
  onSubmit,
  type,
  center_lat,
  center_lon,
  radius,
}) => {
  const classes = useStyles();

  const dragRef = React.useRef(null);
  const [position, setPosition] = React.useState({ x: 0, y: 0 });
  const [areaInKm2, setAreaInKm2] = React.useState(0);

  const handleMouseDown = event => {
    dragRef.current = {
      x: event.clientX - position.x,
      y: event.clientY - position.y,
    };
  };

  const handleMouseUp = () => {
    dragRef.current = null;
  };

  const handleMouseMove = event => {
    if (!dragRef.current) {
      return;
    }

    setPosition({
      x: event.clientX - dragRef.current.x,
      y: event.clientY - dragRef.current.y,
    });
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  useEffect(() => {
    if (type === "circle") {
      var circle = turf.circle([center_lat, center_lon], radius);
      const circleArea = turf.area(circle);
      setAreaInKm2(circleArea / 1000000);
    } else {
      if (!latlngs?.length) {
        setAreaInKm2(0);
        return;
      }
      const polygon = turf.polygon([latlngs.concat([latlngs?.[0]]).map(point => [point.lng, point.lat])]);
      const polygonArea = turf.area(polygon);
      setAreaInKm2(polygonArea / 1000000);
    }
  }, [latlngs, center_lat, center_lon, radius]);

  return (
    <Dialog
      open={open}
      fullWidth
      aria-labelledby="draggable-dialog-title"
      style={{ position: "absolute", top: position.y, left: position.x }}
    >
      <Box className={classes.header} id="draggable-dialog-title" onMouseDown={handleMouseDown}>
        <Typography variant="h6">
          {mode === "create" ? "Tạo khu vực mới" : mode === "edit" ? "Chỉnh sửa khu vực" : ""}
        </Typography>
        <IconButton size="small" onClick={onClose}>
          <Close htmlColor="#fff" fontSize="small" />
        </IconButton>
      </Box>
      <Box className={classes.body}>
        <FormControl className={classes.inputRoot}>
          <Typography marginBottom="4px" variant="subtitle2">
            Tên khu vực
          </Typography>
          <TextField
            value={name}
            onChange={onChangeAreaName}
            autoFocus={!mode === "view"}
            autoComplete="off"
            fullWidth
            InputProps={{ readOnly: mode === "view" }}
            variant="outlined"
            size="small"
          />
        </FormControl>
        {type === "circle" ? (
          <>
            <Box className={classes.boxCordinates}>
              <FormControl className={classes.inputCordinates}>
                <Typography marginBottom="4px" variant="subtitle2">
                  Vĩ độ
                </Typography>
                <TextField
                  value={center_lat}
                  onChange={e => {
                    onChangeAreaInput(e, "center_lat");
                  }}
                  autoFocus={!mode === "view"}
                  autoComplete="off"
                  fullWidth
                  InputProps={{ readOnly: mode === "view" }}
                  variant="outlined"
                  size="small"
                />
              </FormControl>
              <FormControl className={classes.inputCordinates}>
                <Typography marginBottom="4px" variant="subtitle2">
                  Kinh độ
                </Typography>
                <TextField
                  value={center_lon}
                  onChange={e => {
                    onChangeAreaInput(e, "center_lon");
                  }}
                  autoFocus={!mode === "view"}
                  autoComplete="off"
                  fullWidth
                  InputProps={{ readOnly: mode === "view" }}
                  variant="outlined"
                  size="small"
                />
              </FormControl>
            </Box>
            <Box className={classes.boxCordinates}>
              <FormControl className={classes.inputCordinates}>
                <Typography marginBottom="4px" variant="subtitle2">
                  Bán kính (nm)
                </Typography>
                <TextField
                  value={radius}
                  onChange={e => {
                    onChangeAreaInput(e, "radius");
                  }}
                  autoFocus={!mode === "view"}
                  autoComplete="off"
                  fullWidth
                  InputProps={{ readOnly: mode === "view" }}
                  variant="outlined"
                  size="small"
                />
              </FormControl>
              <Box></Box>
            </Box>
          </>
        ) : (
          <>
            <Typography marginBottom="4px" variant="subtitle2">
              Tọa độ các điểm
            </Typography>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" size="small">
                        Số thứ tự
                      </TableCell>
                      <TableCell align="center" size="small">
                        Vĩ độ
                      </TableCell>
                      <TableCell align="center" size="small">
                        Kinh độ
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {latlngs.map((point, index) => (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <TableCell align="center" size="small">
                          Toạ độ điểm {index + 1}
                        </TableCell>
                        <TableCell align="center" size="small">
                          <input
                            className={classes.latLngPointInput}
                            onChange={e => onChangeLatInput(e, index)}
                            value={point.lat}
                            type="number"
                            disabled={mode === "view"}
                            onWheel={e => e.target.blur()}
                          />
                        </TableCell>
                        <TableCell align="center" size="small">
                          <input
                            className={classes.latLngPointInput}
                            onChange={e => onChangeLngInput(e, index)}
                            value={point.lng}
                            type="number"
                            disabled={mode === "view"}
                            onWheel={e => e.target.blur()}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
        )}
        <Box className={classes.boxArea}>
          <Typography marginBottom="4px" variant="subtitle2">
            Diện tích
          </Typography>
          <TextField
            value={areaInKm2.toFixed(2)}
            autoFocus={!mode === "view"}
            autoComplete="off"
            InputProps={{ readOnly: true }}
            variant="outlined"
            size="small"
            className={classes.areaInput}
          />
          <Typography marginBottom="4px" variant="subtitle2">
            km2
          </Typography>
        </Box>
        {mode !== "view" && (
          <Box sx={{ padding: "16px 0 0" }}>
            <Button fullWidth variant="contained" size="large" onClick={onSubmit}>
              Lưu
            </Button>
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default memo(LayerCreateNewAreaDialog);

const useStyles = makeStyles(theme => ({
  header: {
    width: "100%",
    backgroundColor: "#1d83c3",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "4px 4px 0 0",
    padding: "8px 16px",
    color: "#fff",
    fontSize: "16px",
    fontWeight: "bold",
    cursor: "move",
    userSelect: "none",
  },

  body: {
    padding: "16px",
  },

  latLngPointInput: {
    width: "100%",
    padding: "4px 8px",
    borderRadius: "2px",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    outline: "none",
    background: "#fff",
  },

  inputRoot: {
    marginBottom: "16px",
    width: "100%",
  },

  boxCordinates: {
    width: "100%",
    display: "flex",
    marginBottom: "16px",
    justifyContent: "space-between",
  },
  inputCordinates: {
    width: "48%",
  },
  boxArea: {
    display: "flex",
    marginTop: "16px",
    alignItems: "center",
  },
  areaInput: {
    margin: "0 6px",
  },
}));
