import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getEventWarnings: ["data"],
  changeNotifyOption: ["data"],
  deleteEventWarning: ["data"],
  createEventWarning: ["data"],
  updateEventWarning: ["data"],
  getEventWarningNotifications: ["data"],
  getTopEventWarningNotifications: ["data"],
  exportEventWarningNotifications: ["data"],

  eventWarningSet: ["data"],
  eventWarningReset: [],
  eventWarningUpdateIsNew: ["data"],
});
export const EventWarningTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  listEventWarning: [],
  totalPageEventWarning: 1,
  totalElementEventWarning: 0,
  isOpenCreateEventWarning: false,
  isOpenEditEventWarning: false,
  dataEventWarning: null,
  listEventWarningNotification: [],
  totalPageEventWarningNotification: 1,
  totalElementEventWarningNotification: 0,
  is_new: 0,

  isReGetEventWarnings: false,
};

/* ------------- Reducers ------------- */
export const request = (state = INITIAL_STATE, action) => {
  let data = action.data || {};
  return {
    ...state,
    ...data,
    isFetching: true,
  };
};

export const set = (state = INITIAL_STATE, action) => {
  let data = action.data || {};

  return {
    ...state,
    ...data,
  };
};

export const updateIsNew = (state = INITIAL_STATE, action) => {
  let data = action.data || {};
  return {
    ...state,
    is_new: state.is_new + data,
  };
};

export const reset = () => INITIAL_STATE;

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_EVENT_WARNINGS]: request,
  [Types.CHANGE_NOTIFY_OPTION]: request,
  [Types.DELETE_EVENT_WARNING]: request,
  [Types.CREATE_EVENT_WARNING]: request,
  [Types.UPDATE_EVENT_WARNING]: request,
  [Types.GET_EVENT_WARNING_NOTIFICATIONS]: request,
  [Types.GET_TOP_EVENT_WARNING_NOTIFICATIONS]: request,
  [Types.EXPORT_EVENT_WARNING_NOTIFICATIONS]: request,

  [Types.EVENT_WARNING_SET]: set,
  [Types.EVENT_WARNING_RESET]: reset,
  [Types.EVENT_WARNING_UPDATE_IS_NEW]: updateIsNew,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
