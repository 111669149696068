import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ShipActions from "redux-store/ship.redux";
import {
  Box,
  Button,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { convertCoordinates, deepCloneJsonObject } from "utils";

export default function TableShipAfterFilter({ size, bounds, onClose }) {
  const dispatch = useDispatch();

  const filterShipDataTable = useSelector(state => state.shipRedux.filterShipDataTable);
  const filterShipDataTablePage = useSelector(state => state.shipRedux.filterShipDataTablePage);
  const filterShipDataTableTotalPages = useSelector(state => state.shipRedux.filterShipDataTableTotalPages);

  const [pagination, setPagination] = useState({ page: 1, total: 1 });
  const [selectedDataTable, setSelectedDataTable] = useState([]);

  const onChangePage = (e, value) => {
    let pages = Object.keys(filterShipDataTable);
    if (pages.includes("" + value)) {
      setSelectedDataTable(deepCloneJsonObject(filterShipDataTable[value]));
      setPagination(state => ({ ...state, page: value }));
    } else {
      dispatch(ShipActions.requestGetFilterShipInDataTable({ bounds: bounds, size: size, page: value }));
    }
  };

  const onExportReport = () => {
    dispatch(
      ShipActions.requestGetFilterShipInDataTable({
        bounds: bounds,
        size: size,
        page: pagination.page,
        isExport: true,
      }),
    );
  };

  useEffect(() => {
    if (Object.keys(filterShipDataTable).length > 0) {
      let data = filterShipDataTable[filterShipDataTablePage];
      setPagination(state => ({ ...state, page: filterShipDataTablePage, total: filterShipDataTableTotalPages }));
      setSelectedDataTable(deepCloneJsonObject(data));
    }
  }, [filterShipDataTable]);

  return (
    <Fragment>
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">STT</TableCell>
              <TableCell align="center">Tên tàu</TableCell>
              <TableCell align="center">IMO</TableCell>
              <TableCell align="center">MMSI</TableCell>
              <TableCell align="center">Loại tàu</TableCell>
              <TableCell align="center">Quốc tịch</TableCell>
              <TableCell align="center">Cảng biển</TableCell>
              <TableCell align="center">Tải trọng</TableCell>
              <TableCell align="center">Vị trí</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedDataTable.map((data, index) => (
              <TableRow key={data.uid}>
                <TableCell align="center">{(pagination.page - 1) * size + index + 1}</TableCell>
                <TableCell align="center">{data.ship_name}</TableCell>
                <TableCell align="center">{data.IMO}</TableCell>
                <TableCell align="center">{data.mmsi_code}</TableCell>
                <TableCell align="center">{data.ship_type.name}</TableCell>
                <TableCell align="center">
                  {data.country_code === "Unknown" ? "Chưa cập nhật" : data.country_code}
                </TableCell>
                <TableCell align="center">{data.main_fishing_port || "Chưa cập nhật"}</TableCell>
                <TableCell align="center">{data.capacity} tấn</TableCell>
                <TableCell align="center">
                  {convertCoordinates(Number(data.lat).toFixed(5), Number(data.lon).toFixed(5))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="space-between" alignItems="center" paddingTop={3}>
        <Pagination
          onChange={onChangePage}
          count={pagination.total}
          page={pagination.page}
          variant="outlined"
          color="primary"
        />
        <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
          <Button variant="outlined" disableElevation onClick={onExportReport}>
            Xuất báo cáo
          </Button>
          <Button variant="contained" disableElevation onClick={onClose}>
            Đóng
          </Button>
        </Box>
      </Box>
    </Fragment>
  );
}
