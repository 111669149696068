import L from "leaflet";
import { useMapContext } from "./useMapContext";

export const useWatchListMarket = () => {
  const { watchListLayerRef } = useMapContext();

  const clear = () => {
    if (watchListLayerRef.current) {
      watchListLayerRef.current.clear();
    }
  };

  const refresh = () => {
    if (watchListLayerRef.current) {
      watchListLayerRef.current.redraw();
    }
  };
  const createIcon = (length, course, color) => {
    let scaleSize;
    if (length <= 20 || !length) {
      scaleSize = 0.9;
    } else if (length > 20 && length <= 50) {
      scaleSize = 1;
    } else if (length > 50 && length <= 100) {
      scaleSize = 1.1;
    } else if (length > 100 && length <= 200) {
      scaleSize = 1.2;
    } else if (length > 200 && length <= 300) {
      scaleSize = 1.3;
    } else {
      scaleSize = 1.4;
    }

    return L.icon({
      iconSize: [28 * scaleSize, 28 * scaleSize],
      iconAnchor: [14 * scaleSize, 14 * scaleSize],
      iconUrl:
        "data:image/svg+xml;base64," +
        btoa(`<svg height="100" width="100" xmlns="http://www.w3.org/2000/svg">
        <circle r="45" cx="50" cy="50" fill="${color}" opacity="0.9" />
      </svg>`),
    });
  };

  const createMarker = (lat, lng, icon, info) => {
    const marker = L.marker([lat, lng], { icon: icon });
    return marker;
  };

  const show = (points = []) => {
    const markers = points
      ?.filter(point => point)
      .map(point => {
        const marker = createMarker(
          point.ship_lat,
          point.ship_long,
          createIcon(point.length, point.course, point.watchListColor),
          point,
        );
        return marker;
      });

    watchListLayerRef.current.addMarkers(markers);
  };

  const remove = (markers = []) => {
    watchListLayerRef.current.removeMarkers(markers);
  };

  return {
    clear,
    refresh,
    show,
    remove,
  };
};
