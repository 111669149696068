import { AppConstant } from "const";
import { createReducer, createActions } from "reduxsauce";
import { getShipAround } from "sagas/ship.saga";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  requestGetShipTypes: ["data"],
  requestSearchShips: ["data"],
  requestGetTrackingShips: ["data"],
  requestFilterShips: ["data"],
  requestTrackingShips: ["data"],
  requestTrackingShip: ["data"],
  requestFilterShipsInDialog: ["data"],
  requestAddViolationShip: ["data"],
  requestDeleteViolationShip: ["data"],
  requestEditViolationShip: ["data"],
  requestGetFilterShipInDataTable: ["data"],
  getCrawlDataHistory: ["data"],
  getShipInArea: ["data"],
  getCrawlShipUpdate: ["data"],
  getListShipLost: ["data"],
  getListShipByDay: ["data"],
  getShipsAround: ["data"],

  shipSet: ["data"],
  shipReset: [],
});

export const ShipTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  shipTypes: [],
  filterShipList: { status: "idle", data: [] },
  filterTrackingShipList: { status: "idle", data: [] },
  filterDialogShipList: [],
  trackingShips: [],
  selectedShips: [],
  filterShipResult: [],
  filterShipSelect: [],
  showOtherShip: true,
  groupShip: [],
  shipOnMap: [],
  shipsAround: null,
  shipsAroundCircle: null,
  msk: [],
  filterClusteredResult: [],
  trackingShipsLocationList: [],
  shipConditions: {
    foreign_f: false,
    capacity_min: AppConstant.SHIP_CAPACITY.min,
    capacity_max: AppConstant.SHIP_CAPACITY.max,
    source_type_list: [],
    group_ids: [],
    handleGroupMembers: {},
    group_members: [],
    custom_area_list: [],
    default_area_list: [],
    area_list: [],
    ports: [],
    ship_type_list: [],
    country_code_list: [],
  },
  filterShipDataTable: {},
  filterShipDataTablePage: 1,
  filterShipDataTableTotalPages: 1,
  trackingShip: null,
  errors: null,
  foreignShipNumber: 0,
  startLoadingTime: null,
  violationShipNumber: null,
  isSearchingManageScreen: false,
  isSearchingTrackingScreen: false,
  isTracking: false,
  isSearching: false,
  isFetchingLocation: false,
  isFetching: false,
  isSearchingInDialog: false,
  isDeleteViolationShipSuccess: false,
  isEditViolationShipSuccess: false,
  isChangingFilter: false,
  viewFromAreaTime: false,
  trackingUidList: [],
  shipInAreaData: {
    listData: {},
  },
  isFetchingAreaData: false,

  dataHistory: [],
  isCrawlDataDone: false,
  isClusteredDone: false,
  isOpenPopupHover: false,
  shipInfoHover: {},

  trackingLayerList: [],
  colorTrackingLayer: null,
  colorShipList: null,

  listShipLostData: null,
  shipLostChartData: null,
  getListShipLostStatus: AppConstant.API_STATUS.nothing,
  totalShipInDatabase: 0,

  listShipByDayData: null,
  getListShipByDayStatus: AppConstant.API_STATUS.nothing,
};

/* ------------- Reducers ------------- */

export const request = (state = INITIAL_STATE, action) => {
  let data = action.data || {};
  return {
    ...state,
    ...data,
    isFetching: true,
  };
};

export const set = (state = INITIAL_STATE, action) => {
  let data = action.data || {};

  return {
    ...state,
    ...data,
  };
};

export const reset = () => INITIAL_STATE;

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.REQUEST_GET_SHIP_TYPES]: request,
  [Types.REQUEST_SEARCH_SHIPS]: request,
  [Types.REQUEST_GET_TRACKING_SHIPS]: request,
  [Types.REQUEST_FILTER_SHIPS]: request,
  [Types.REQUEST_TRACKING_SHIPS]: request,
  [Types.REQUEST_TRACKING_SHIP]: request,
  [Types.REQUEST_FILTER_SHIPS_IN_DIALOG]: request,
  [Types.REQUEST_ADD_VIOLATION_SHIP]: request,
  [Types.REQUEST_DELETE_VIOLATION_SHIP]: request,
  [Types.REQUEST_EDIT_VIOLATION_SHIP]: request,
  [Types.REQUEST_GET_FILTER_SHIP_IN_DATA_TABLE]: request,
  [Types.GET_CRAWL_DATA_HISTORY]: request,
  [Types.GET_SHIP_IN_AREA]: request,
  [Types.GET_CRAWL_SHIP_UPDATE]: request,
  [Types.GET_LIST_SHIP_LOST]: request,
  [Types.GET_LIST_SHIP_BY_DAY]: request,
  [Types.GET_SHIPS_AROUND]: request,

  [Types.SHIP_SET]: set,
  [Types.SHIP_RESET]: reset,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
