import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { AppConstant, LangConstant } from "const";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import ConversationRedux from "redux-store/conversation.redux";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useState } from "react";
import { format } from "date-fns";
import { ArrowDownward } from "@mui/icons-material";
import { saveAs } from "file-saver";
import { ConversationService } from "services";
import { now } from "moment";
import { handleSendNotiInSaga } from "utils";

function createData(name, calories, fat, carbs, action, protein, time, icon) {
  return { name, calories, fat, carbs, action, protein, time, icon };
}

const TableHistory = ({ valueInput }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const sucessHistory = useSelector(state => state.conversationRedux.sucessHistory);
  const panigation1 = useSelector(state => state.conversationRedux.pagination);
  const totalPageHistory = useSelector(state => state.conversationRedux.totalPageHistory);
  const uploadFileSuccess = useSelector(state => state.conversationRedux.uploadFileSuccess);

  const [valuePicker, setValuePicker] = useState(now);
  const [valueSelect, setValueSelect] = useState(0);
  const [valueTypeData] = useState();
  const [pagination, setPagination] = useState(1);
  const [resultRowTable, setResuRowltTable] = useState();
  const [action, setAction] = useState(1);

  const onClickFile = async item => {
    if (item.attachment == null) {
      handleSendNotiInSaga(LangConstant.MS_FILE_NOT_EXIST, "error");
    } else {
      const data = item.attachment.uid;

      let fileResponse = await ConversationService.exportFileSaga(data);
      if (fileResponse.status === 200) {
        handleSendNotiInSaga(LangConstant.MS_EXPORT_DATA_SUCCESS, "success");
        saveAs(
          new File([fileResponse.data], item.attachment.name, {
            type: fileResponse.data.type,
          }),
        );
      } else if (fileResponse.status === 404) {
        handleSendNotiInSaga(LangConstant.MS_NOT_HAVE_NEW_DATA, "success");
      } else {
        handleSendNotiInSaga(fileResponse.data?.error, "success");
      }
    }
  };

  useEffect(() => {
    if (sucessHistory) {
      let result = [];
      sucessHistory.map(item => {
        return result.push(
          createData(
            item,
            item.attachment === null ? "" : item.attachment.name,
            item.action === null
              ? ""
              : item.action === 1
                ? "Toàn bộ"
                : item.action === 2
                  ? "Tàu thuyền"
                  : item.action === 3
                    ? "Cảng biển"
                    : item.action === 5
                      ? "Hành trình"
                      : item.action === 7
                        ? "Vi phạm"
                        : item.action === 8
                          ? "Bản đồ"
                          : "",
            item.action === null ? "" : Object.values(AppConstant.EXPORT_IMPORT_FILE_ACTION)[item.action],
            item.user !== null ? item.user.name : "",
            item.status !== null ? item.status : "",
            item.created !== null ? item.created : "",
            <IconButton onClick={() => onClickFile(item)}>
              <ArrowDownward />
            </IconButton>,
          ),
        );
      });
      setResuRowltTable(result);
    }
  }, [sucessHistory]);

  useEffect(() => {
    dispatch(
      ConversationRedux.getHistory({
        filter: valueInput,
        page: pagination,
        from: valuePicker && new Date(valuePicker).getTime(),
        status: valueSelect >= 1 ? valueSelect - 1 : null,
        action: action,
        data_type: valueTypeData,
      }),
    );
  }, [valueInput, pagination, valuePicker, valueSelect, valueTypeData, uploadFileSuccess, action]);

  const onChangePage = (e, value) => {
    setPagination(value);
  };

  return (
    <>
      <Typography variant="h6">Lịch sử nhập xuất dữ liệu</Typography>
      <Box className={classes.inputSearch}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            value={valuePicker}
            inputFormat="dd/MM/yyyy"
            onChange={newValue => {
              setValuePicker(newValue);
            }}
            renderInput={params => <TextField size="small" {...params} />}
          />
        </LocalizationProvider>
        <Box style={{ width: 200, padding: "0 10px" }}>
          <FormControl fullWidth size="small">
            <InputLabel>{LangConstant.TXT_STATUS}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label={LangConstant.L_STATUS}
              value={valueSelect}
              defaultValue={"Tất cả"}
              onChange={e => setValueSelect(e.target.value)}
            >
              <MenuItem value="0">Tất cả</MenuItem>
              <MenuItem value="3">Đang xử lý</MenuItem>
              <MenuItem value="1">Bị lỗi</MenuItem>
              <MenuItem value="2">Thành công</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box style={{ width: 200, padding: "0 10px" }}>
          <FormControl size="small" fullWidth>
            <InputLabel>Hành Động</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Hành Động"
              value={action}
              onChange={e => setAction(e.target.value)}
            >
              <MenuItem value="1">Xuất tất cả file</MenuItem>
              <MenuItem value="0">Nhập file</MenuItem>
              <MenuItem value="2">Xuất dữ liệu tàu</MenuItem>
              <MenuItem value="3">Xuất dữ liệu cảng biển</MenuItem>
              {/*<MenuItem value="4">Xuất dữ liệu bến cảng</MenuItem>*/}
              <MenuItem value="5">Xuất dữ liệu hành trình</MenuItem>
              <MenuItem value="7">Xuất dữ liệu tàu vi phạm</MenuItem>
              <MenuItem value="8">Nhập dữ liệu bản đồ</MenuItem>
              <MenuItem value="9">Tải file lên hệ thống</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <TableContainer sx={{ height: 400, overflow: "auto" }} component={Paper}>
        <Table stickyHeader sx={{ overflow: "scroll" }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell width="100px">{LangConstant.TXT_STT}</TableCell>
              <TableCell align="center">{LangConstant.TXT_NAME_FILE}</TableCell>
              <TableCell align="center">{LangConstant.TXT_DATA_TYPE}</TableCell>
              <TableCell align="center">{LangConstant.TXT_ACTION}</TableCell>
              <TableCell align="center">{LangConstant.TXT_STATUS}</TableCell>
              <TableCell align="center">{LangConstant.TXT_PEOPLE_AWAKENING}</TableCell>
              <TableCell align="center">{LangConstant.TXT_TIME_AWAKENING}</TableCell>
              <TableCell align="center">{LangConstant.TXT_MANIPULATION}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resultRowTable &&
              resultRowTable.map((row, index) => {
                return (
                  <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {(pagination - 1) * panigation1 + index + 1}
                    </TableCell>
                    <TableCell align="center">{row.calories}</TableCell>
                    <TableCell align="center">{row.fat}</TableCell>
                    <TableCell align="center">{row.carbs}</TableCell>
                    <TableCell align="center">
                      {row.protein === 0 ? "Lỗi" : row.protein === 1 ? "Thành công" : "Đang xử lý"}
                    </TableCell>
                    <TableCell align="center">{row.action}</TableCell>
                    <TableCell align="center">{format(row?.time, "dd/MM/yyyy HH:mm:ss")}</TableCell>
                    <TableCell align="center">{row.icon}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className={classes.panigationPadding}>
        <Pagination count={Number(totalPageHistory)} shape="rounded" onChange={onChangePage} />
      </Box>
    </>
  );
};
export default TableHistory;

const useStyles = makeStyles(theme => ({
  panigationPadding: {
    padding: "20px 0",
  },
  inputSearch: {
    paddingBottom: 10,
    display: "flex",
    alignItems: "center",
  },
}));
