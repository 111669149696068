import { LangConstant, SystemConstant } from "const";

const useUserActionTypeList = () => {
  const actionList = [
    {
      label: LangConstant.TXT_ALL,
      value: SystemConstant.USER_ACTION.ALL,
    },
    {
      label: LangConstant.OBJ_USER_ACTION.LOGIN,
      value: SystemConstant.USER_ACTION.LOGIN,
    },
    {
      label: LangConstant.OBJ_USER_ACTION.LOGOUT,
      value: SystemConstant.USER_ACTION.LOGOUT,
    },
    {
      label: LangConstant.OBJ_USER_ACTION.CHANGE_PASSWORD,
      value: SystemConstant.USER_ACTION.CHANGE_PASSWORD,
    },
    {
      label: LangConstant.OBJ_USER_ACTION.TURN_ON_2_FACTOR_AUTHENTICATION,
      value: SystemConstant.USER_ACTION.TURN_ON_2_FACTOR_AUTHENTICATION,
    },
    {
      label: LangConstant.OBJ_USER_ACTION.TURN_OFF_2_FACTOR_AUTHENTICATION,
      value: SystemConstant.USER_ACTION.TURN_OFF_2_FACTOR_AUTHENTICATION,
    },
    {
      label: LangConstant.OBJ_USER_ACTION.CREATE_FOLDER,
      value: SystemConstant.USER_ACTION.CREATE_FOLDER,
    },
    {
      label: LangConstant.OBJ_USER_ACTION.CREATE_REPORT,
      value: SystemConstant.USER_ACTION.CREATE_REPORT,
    },
    {
      label: LangConstant.OBJ_USER_ACTION.SHARE_FILE,
      value: SystemConstant.USER_ACTION.SHARE_FILE,
    },
    {
      label: LangConstant.OBJ_USER_ACTION.SHARE_INFORMATION,
      value: SystemConstant.USER_ACTION.SHARE_INFORMATION,
    },
    {
      label: LangConstant.OBJ_USER_ACTION.DELETE_DATA,
      value: SystemConstant.USER_ACTION.DELETE_DATA,
    },
  ];

  return { actionList };
};
export default useUserActionTypeList;
