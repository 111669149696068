import { format as StringFormat } from "react-string-format";
import { FormatConstant, LangConstant } from "const";
import {
  format as DateFormat,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  isSameYear,
} from "date-fns";

export const convertTimeStampToDate = (timeStamp, formatConstant = "HH:mm dd/MM/yyyy ") => {
  let resultDate;
  if (timeStamp) {
    if (formatConstant) {
      resultDate = DateFormat(new Date(timeStamp * 1000), formatConstant);
    } else {
      resultDate = new Date(timeStamp * 1000);
    }
  }
  return resultDate;
};

export function formatSizeUnits(bytes) {
  if (bytes) {
    bytes = (bytes / 1048576).toFixed(2) + " MB";
  }
  return bytes;
}

export const convertMillisecondsToHours = milliseconds => {
  let seconds = milliseconds / 1000;
  let minutes = seconds / 60;
  let hours = minutes / 60;

  return hours;
};

export const convertMillisecondsToDateTime = (milliseconds, locale = "en-GB") => {
  const date = new Date(milliseconds);

  return date.toLocaleString(locale);
};

export const getStartOfDay = (day = new Date()) => {
  let result = getTimestamp(new Date(day.setHours(0, 0, 0)));

  return result;
};

export const getEndOfDay = (day = new Date()) => {
  let result = getTimestamp(new Date(day.setHours(23, 59, 59)));
  return result;
};

export const getTimestamp = date => {
  let tmpDate = new Date(date);
  return Math.floor(tmpDate.getTime() / 1000);
};

export const getCreatedTime = (created, getLabel) => {
  let result = "";
  if (created) {
    let currentDate = new Date();
    let differenceDaysWithCurrent = differenceInDays(currentDate, created);
    let differenceHoursWithCurrent = differenceInHours(currentDate, created);
    let differenceMinutesWithCurrent = differenceInMinutes(currentDate, created);
    let differenceSecsWithCurrent = differenceInSeconds(currentDate, created);
    if (differenceDaysWithCurrent >= 1) {
      if (differenceDaysWithCurrent >= 7) {
        let isSame = isSameYear(created, currentDate);
        let primaryFormat = isSame ? FormatConstant.FM_DD_MM : FormatConstant.FM_DD_MM_YYYY;
        result = DateFormat(created, primaryFormat);
      } else {
        result = StringFormat(LangConstant.FM_NEW_CREATED_BY_DAY, differenceDaysWithCurrent);
      }
    } else if (differenceHoursWithCurrent >= 1) {
      result = StringFormat(LangConstant.FM_NEW_CREATED_BY_HOUR, differenceHoursWithCurrent);
    } else if (differenceMinutesWithCurrent >= 1) {
      result = StringFormat(LangConstant.FM_NEW_CREATED_BY_MINUTE, differenceMinutesWithCurrent);
    } else if (differenceSecsWithCurrent < 60) {
      result = StringFormat(LangConstant.FM_NEW_CREATED_BY_SECOND, differenceSecsWithCurrent);
    }
  }
  return result;
};
