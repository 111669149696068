import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ShipActions from "redux-store/ship.redux";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Popover,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Close, Delete, Search } from "@mui/icons-material";
import { deepCloneJsonObject } from "utils";
import { AppConstant } from "const";

const AddViolationShip = ({ open, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isSearchingInDialog = useSelector(state => state.shipRedux.isSearchingInDialog);
  const filterDialogShipList = useSelector(state => state.shipRedux.filterDialogShipList);
  const violationShipNumber = useSelector(state => state.shipRedux.violationShipNumber);

  const [searchEl, setSearchEl] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [selectedShips, setSelectedShips] = useState([]);
  const [reason, setReason] = useState("");
  const [status, setStatus] = useState(AppConstant.VIOLATION_STATUS.NOT_PROCESSING.key);
  const [errors, setErrors] = useState({ ship: "", reason: "" });

  const onCloseDialog = () => {
    dispatch(ShipActions.shipSet({ violationShipNumber: null, filterDialogShipList: [] }));
    setStatus(AppConstant.VIOLATION_STATUS.NOT_PROCESSING.key);
    setErrors({ ship: "", reason: "" });
    setSelectedShips([]);
    setSearchInput("");
    setReason("");
    onClose();
  };

  const onFocusSearch = e => {
    setSearchEl(e.target.parentElement.parentElement);
    setErrors(state => ({ ...state, ship: "" }));
  };

  const onChangeSearchInput = e => {
    e.preventDefault();
    setSearchInput(e.target.value);
    dispatch(ShipActions.shipSet({ filterDialogShipList: [] }));
    dispatch(ShipActions.requestFilterShipsInDialog({ filter: e.target.value }));
  };

  const onClosePopover = () => {
    setSearchEl(null);
  };

  const onSubmitSearchShip = e => {
    e.preventDefault();
    dispatch(ShipActions.shipSet({ filterDialogShipList: [] }));
    dispatch(ShipActions.requestFilterShipsInDialog({ filter: searchInput }));
  };

  const onToggleChangeSelect = ship => {
    if (selectedShips.find(s => s.uid === ship.uid)) {
      setSelectedShips(state => state.filter(s => s.uid !== ship.uid));
    } else {
      setSelectedShips(state => deepCloneJsonObject([...state, ship]));
    }
  };

  const onChangeReason = e => {
    setReason(e.target.value);
    setErrors(state => ({ ...state, reason: "" }));
  };

  const onChangeStatus = e => {
    setStatus(e.target.value);
  };

  const onSubmit = e => {
    let isValidated = true;

    if (selectedShips.length === 0) {
      isValidated = false;
      setErrors(state => ({ ...state, ship: "Vui lòng chọn ít nhất một tàu" }));
    }

    if (reason.length === 0) {
      isValidated = false;
      setErrors(state => ({ ...state, reason: "Vui lòng không để trống trường này" }));
    }

    if (isValidated) {
      dispatch(
        ShipActions.requestAddViolationShip({ ships: selectedShips.map(s => s.uid), note: reason, status: status }),
      );
    }
  };

  useEffect(() => {
    if (violationShipNumber !== null) {
      onCloseDialog();
    }
  }, [violationShipNumber]);

  return (
    <Dialog open={open} onClose={onCloseDialog}>
      <Box className={classes.cont}>
        <Box className={classes.header}>
          <Typography variant="h6">Thêm tàu vào danh sách</Typography>
          <IconButton size="small" onClick={onCloseDialog}>
            <Close fontSize="small" />
          </IconButton>
        </Box>
        <Box className={classes.body}>
          <form onSubmit={onSubmitSearchShip}>
            <FormControl fullWidth>
              <OutlinedInput
                size="small"
                type="text"
                placeholder="Nhập tên tàu, IMO hoặc MMSI để tìm kiếm"
                value={searchInput}
                onFocus={onFocusSearch}
                onChange={onChangeSearchInput}
                startAdornment={
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                }
              />
            </FormControl>
            <Typography variant="caption" color="error">
              {errors.ship}
            </Typography>
            <Popover
              open={Boolean(searchEl)}
              anchorEl={searchEl}
              onClose={onClosePopover}
              disableAutoFocus={true}
              disableEnforceFocus={true}
              disableRestoreFocus={true}
              classes={{ paper: classes.paper }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {isSearchingInDialog ? (
                <Box display="flex" justifyContent="flex-start" alignItems="center" gap="6px">
                  <Search color="primary" />
                  <Typography>Đang tìm kiếm kết quả...</Typography>
                </Box>
              ) : (
                <Fragment>
                  <Typography variant="h6" style={{ marginBottom: 4 }}>
                    Kết quả tìm kiếm:
                  </Typography>
                  {filterDialogShipList.length > 0 ? (
                    <Box className={classes.resultCont}>
                      {filterDialogShipList.map((ship, index) => (
                        <Box key={index}>
                          <FormControlLabel
                            label={
                              <Typography>
                                {ship.name} ({AppConstant.DATA_SOURCES[ship?.source_type]})
                                {ship.mmsi ? <p style={{ fontSize: "12px" }}>MMSI: {ship.mmsi}</p> : <p></p>}
                              </Typography>
                            }
                            control={
                              <Checkbox
                                value={ship.uid}
                                onChange={() => onToggleChangeSelect(ship)}
                                checked={selectedShips.map(s => s.uid).includes(ship.uid)}
                              />
                            }
                          />
                        </Box>
                      ))}
                    </Box>
                  ) : (
                    <Typography variant="caption">Không có kết quả tìm kiếm</Typography>
                  )}
                </Fragment>
              )}
            </Popover>
          </form>
          <Box marginTop={2}>
            <Typography variant="h6" style={{ marginBottom: selectedShips.length > 0 ? 8 : 0 }}>
              Các tàu đã chọn:
            </Typography>
            <Box style={{ maxHeight: "20vh", overflowY: "scroll" }}>
              {selectedShips.length > 0 ? (
                selectedShips.map(ship => (
                  <Box key={ship.uid} className={classes.selectedShip} marginLeft={1}>
                    <Typography>
                      &#x2022; {ship.name} ({AppConstant.DATA_SOURCES[ship?.source_type]})
                      {ship.mmsi ? <p style={{ fontSize: "12px" }}>MMSI: {ship.mmsi}</p> : <p></p>}
                    </Typography>
                    <IconButton size="small" onClick={() => onToggleChangeSelect(ship)}>
                      <Delete fontSize="small" />
                    </IconButton>
                  </Box>
                ))
              ) : (
                <Typography variant="caption" style={{ marginLeft: 8 }}>
                  &#x2022; Chưa có tàu được chọn
                </Typography>
              )}
            </Box>
          </Box>
          <Box marginBottom={2}>
            <FormLabel required className={classes.formLabel} htmlFor="violate-ship-reason">
              Lý do
            </FormLabel>
            <OutlinedInput
              id="violate-ship-reason"
              size="small"
              fullWidth
              multiline
              rows={4}
              placeholder="Nhập lý do..."
              value={reason}
              onChange={onChangeReason}
            />
            <Typography variant="caption" color="error">
              {errors.reason}
            </Typography>
          </Box>
          <Box marginBottom={2}>
            <FormLabel required className={classes.formLabel} htmlFor="violate-ship-status">
              Trạng thái
            </FormLabel>
            <select id="violate-ship-status" className={classes.status} value={status} onChange={onChangeStatus}>
              <option value={AppConstant.VIOLATION_STATUS.NOT_PROCESSING.key}>
                {AppConstant.VIOLATION_STATUS.NOT_PROCESSING.title}
              </option>
              <option value={AppConstant.VIOLATION_STATUS.PROCESSED.key}>
                {AppConstant.VIOLATION_STATUS.PROCESSED.title}
              </option>
            </select>
          </Box>
        </Box>
        <Box className={classes.footer}>
          <Button fullWidth variant="contained" onClick={onSubmit}>
            Thêm tàu
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AddViolationShip;

const useStyles = makeStyles(theme => ({
  cont: {
    width: 600,
  },

  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 16,
  },

  body: {
    padding: 16,
    paddingTop: 0,
  },

  paper: {
    width: 552,
    maxWidth: "unset",
    maxHeight: "unset",
    marginTop: 4,
    padding: 8,
  },

  resultCont: {
    maxHeight: "30vh",
    height: "100%",
    overflowY: "scroll",
  },

  selectedShip: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 8,
  },

  formLabel: {
    marginTop: 8,
    marginBottom: 8,
    display: "inline-block",
    color: "#000000",
    fontSize: 18,
    fontWeight: 400,
  },

  status: {
    display: "block",
    width: "100%",
    padding: 10,
    border: "1px solid #cecece",
    borderRadius: 4,
    cursor: "pointer",
    outline: "none",
  },

  footer: {
    padding: 16,
    paddingTop: 0,
  },
}));
