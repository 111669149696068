import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConversationRedux from "redux-store/conversation.redux";

const PopupConfirmDelete = props => {
  const { open, onClose, checked, data, resetData, setCheckedCheckbox } = props;
  const dispatch = useDispatch();
  const deleteListShip = useSelector(state => state.conversationRedux.deleteListShip);

  useEffect(() => {
    if (deleteListShip) {
      onClose();
      resetData();
      setCheckedCheckbox();
    }
    dispatch(
      ConversationRedux.conversationSet({
        deleteListShip: false,
      }),
    );
  }, [deleteListShip]);

  const onClickDelete = () => {
    dispatch(
      ConversationRedux.deleteListShip({
        uids: [checked],
      }),
    );
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={onClose}>
      <DialogTitle>Xác nhận xóa</DialogTitle>
      <DialogContent style={{ paddingTop: 10 }}>
        {checked?.length > 1
          ? `Bạn có muốn xóa ${checked?.length} tàu không?`
          : `Bạn có muốn xóa tàu ${data?.name} không?`}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickDelete}>Đồng ý</Button>
        <Button onClick={onClose}>Thoát</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupConfirmDelete;
