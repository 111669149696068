import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import { Publish } from "@mui/icons-material";
import { LangConstant } from "const";
import FolderAction from "redux-store/folder.redux";
import FolderRedux from "redux-store/folder.redux";
import { debounce } from "lodash";
import { TextField, Button, Radio, Typography } from "@mui/material";
import { FOLDER_ACCESS_TYPE } from "const/app.const";
import PopupUser from "./PopupUser";
import TableUserAccess from "./TableUserAccess";
import TableFileUpload from "./TableFileUpload";
import ConfirmCancelDialog from "../../../components/ConfirmCancelDialog";
import FileUploadNotiDialog from "./FileUploadNotiDialog";

const EditFolder = () => {
  const dispatch = useDispatch();

  const fileList = useSelector(state => state.folderRedux.fileList);
  const dataFolder = useSelector(state => state.folderRedux.dataFolder);
  const folderDetail = useSelector(state => state.folderRedux.folderDetail);

  const [folderName, setFolderName] = useState("");
  const [description, setDescription] = useState("");

  const [searchFile, setSearchFile] = useState("");
  const [accessType, setAccessType] = useState(FOLDER_ACCESS_TYPE.PRIVATE);
  const [searchUser, setSearchUser] = useState("");
  const [listUser, setListUser] = useState([]);
  const [isShowPopupUser, setIsShowPopupUser] = useState(false);
  const [isOpenCancelConfirm, setIsOpenCancelConfirm] = useState(false);
  const [isOpenFileUploadNoti, setIsOpenFileUploadNoti] = useState(false);
  useEffect(() => {
    dispatch(FolderRedux.getFolder(dataFolder));
  }, [dataFolder]);

  const onChangeFile = event => {
    const files = event.target.files;
    if (files.length > 10) {
      setIsOpenFileUploadNoti(true);
    } else {
      let formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.size / 1024 / 1024 / 1024 < 1.5) {
          formData.append("file", file);
        } else {
          setIsOpenFileUploadNoti(true);
        }
      }
      dispatch(
        FolderRedux.uploadFileFolder({
          formData,
          headerConfig: {
            headers: {
              // mime_type: file.name.substring(file.name.lastIndexOf(".") + 1),
            },
          },
          callbackError: () => {
            setIsOpenFileUploadNoti(true);
          },
        }),
      );
    }
    event.target.value = null;
  };

  const onClickEdit = () => {
    dispatch(
      FolderAction.updateFolder({
        name: folderName,
        description: description,
        access: accessType,
        user_uids: accessType === FOLDER_ACCESS_TYPE.PUBLIC ? listUser.map(user => user.uid) : [],
        attachment_uids: fileList.map(file => file.uid),
        uid: dataFolder.uid,
      }),
    );
  };

  const enableButtonEdit = () => {
    return folderName;
  };

  useEffect(() => {
    return () => dispatch(FolderAction.folderReset({}));
  }, []);

  useEffect(() => {
    setAccessType(folderDetail?.access);
    setFolderName(folderDetail?.name);
    setDescription(folderDetail?.description);
    setListUser(folderDetail?.users || []);
    dispatch(
      FolderAction.folderSet({
        fileList: folderDetail?.attachments || [],
      }),
    );
  }, [folderDetail]);

  const onClickClose = () => {
    dispatch(FolderAction.folderSet({ isOpenEditFolder: false }));
  };
  const handleDeleteUser = uid => {
    setListUser(listUser.filter(user => user.uid !== uid));
  };

  return (
    <>
      <Button sx={{ border: "1px solid" }} onClick={() => onClickClose()}>
        {LangConstant.TXT_SHOW_LIST_FOLDER}
      </Button>
      <Box sx={{ marginTop: 4 }}>
        <Box sx={{ fontWeight: "bold", fontSize: "20px" }}>1. {LangConstant.TXT_FOLDER_INFO} </Box>

        <Box sx={{ marginTop: 2, display: "flex", paddingRight: "10%" }}>
          <TextField
            sx={{ width: 300, marginRight: "15%" }}
            fullWidth
            size="small"
            id="folder_id"
            label={LangConstant.TXT_FOLDER_ID}
            variant="outlined"
            value={`FOLDER_${folderDetail?.id}`}
            disabled
          />

          <TextField
            sx={{ width: 300, marginRight: "15%" }}
            fullWidth
            size="small"
            id="folder_id"
            label={LangConstant.TXT_FOLDER_NAME}
            variant="outlined"
            onChange={e => setFolderName(e.target.value)}
            required
            value={folderName}
            inputProps={{ maxLength: 125 }}
          />
        </Box>

        <Box marginTop={2}>
          <TextField
            sx={{ width: "100%" }}
            fullWidth
            size="small"
            id="folder_id"
            label={LangConstant.TXT_FOLDER_DESCRIPTION}
            variant="outlined"
            onChange={e => setDescription(e.target.value)}
            value={description}
            multiline
            rows={2}
            inputProps={{ maxLength: 225 }}
          />
        </Box>

        <Box sx={{ marginTop: 2 }}>
          <Box sx={{ fontWeight: "bold", fontSize: "20px" }}>2. {LangConstant.TXT_FILE_REPORT}</Box>
          <Box sx={{ marginTop: 2, display: "flex", justifyContent: "space-between" }}>
            <TextField
              sx={{ width: 500 }}
              onChange={debounce(e => {
                setSearchFile(e.target.value);
              }, 500)}
              fullWidth
              size="small"
              id="search_box_event_warning"
              label={LangConstant.L_SEARCH}
              variant="outlined"
            />

            <Button color="warning" variant="contained" endIcon={<Publish />} component="label">
              Upload File
              <input
                accept=".xls, .xlsx, .doc, .docx, .jpg, .png, .ppt, .pptx"
                onChange={onChangeFile}
                type="file"
                hidden
                multiple
              />
            </Button>
          </Box>
          <TableFileUpload searchFile={searchFile} />
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ fontWeight: "bold", fontSize: "20px" }}>3. {LangConstant.TXT_FILE_REPORT_ACCESS}</Box>
            <Box sx={{ display: "flex" }}>
              <Box
                onClick={() => {
                  setAccessType(FOLDER_ACCESS_TYPE.PRIVATE);
                }}
                sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              >
                <Radio checked={accessType === FOLDER_ACCESS_TYPE.PRIVATE} />
                <Typography>{LangConstant.TXT_FOLDER_ONLY_ME}</Typography>
              </Box>
              <Box
                onClick={() => {
                  setAccessType(FOLDER_ACCESS_TYPE.PUBLIC);
                }}
                sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              >
                <Radio checked={accessType === FOLDER_ACCESS_TYPE.PUBLIC} />
                <Typography>{LangConstant.TXT_FOLDER_ANOTHER_USER}</Typography>
              </Box>
            </Box>
          </Box>
          {accessType === FOLDER_ACCESS_TYPE.PUBLIC && (
            <>
              <Box sx={{ marginTop: 2, display: "flex", justifyContent: "space-between" }}>
                <TextField
                  sx={{ width: 500 }}
                  onChange={debounce(e => {
                    setSearchUser(e.target.value);
                  }, 500)}
                  fullWidth
                  size="small"
                  id="search_box_event_warning"
                  label={LangConstant.L_SEARCH}
                  variant="outlined"
                />

                <Button
                  color="warning"
                  variant="contained"
                  component="label"
                  onClick={() => {
                    setIsShowPopupUser(true);
                  }}
                >
                  {LangConstant.L_ADD_USER_FOLDER_REPORT}
                </Button>
              </Box>
              <TableUserAccess searchUser={searchUser} listUser={listUser} handleDeleteUser={handleDeleteUser} />
            </>
          )}
        </Box>
        <Box sx={{ marginTop: 8, paddingRight: "10%", display: "flex", justifyContent: "flex-end" }}>
          <Button
            sx={{ color: "red", border: "1px solid", marginRight: "5%" }}
            onClick={() => {
              if (
                folderName !== folderDetail?.name ||
                description !== folderDetail?.description ||
                accessType !== folderDetail?.access ||
                listUser !== folderDetail?.users ||
                fileList !== folderDetail?.attachments
              ) {
                setIsOpenCancelConfirm(true);
              } else {
                onClickClose();
              }
            }}
          >
            {LangConstant.TXT_BUTTON_CANCEL}
          </Button>
          <Button
            sx={{ color: "cornflowerblue", border: "1px solid" }}
            onClick={() => onClickEdit()}
            disabled={!enableButtonEdit()}
          >
            {LangConstant.TXT_BUTTON_UPDATE}
          </Button>
        </Box>
      </Box>
      <PopupUser
        title={LangConstant.L_ADD_USER_FOLDER_REPORT}
        isShow={isShowPopupUser}
        onClose={() => setIsShowPopupUser(false)}
        listUser={listUser}
        onApply={setListUser}
      />
      <ConfirmCancelDialog
        open={isOpenCancelConfirm}
        onClose={() => onClickClose()}
        onCancel={() => setIsOpenCancelConfirm(false)}
      />
      <FileUploadNotiDialog open={isOpenFileUploadNoti} onClose={() => setIsOpenFileUploadNoti(false)} />
    </>
  );
};
export default EditFolder;
