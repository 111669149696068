import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useDispatch } from "react-redux";
import PortAction from "redux-store/port.redux";

const ConfirmDeleteHarbor = props => {
  const { open, onClose, data, lastItem } = props;
  const dispatch = useDispatch();

  const onClickDeleteHabor = () => {
    dispatch(PortAction.deletePorts(data));
    onClose();
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={onClose}>
      <DialogTitle>Xác nhận xóa</DialogTitle>
      <DialogContent style={{ paddingTop: 10 }}>
        {lastItem ? `Bạn muốn xóa cảng ${lastItem?.name} không?` : `Bạn muốn xóa ${data?.length} cảng không?`}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClickDeleteHabor}>
          Đồng ý
        </Button>
        <Button onClick={onClose}>Thoát</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteHarbor;
