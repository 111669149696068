import React, { memo, useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Popover,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ConversationActions from "redux-store/conversation.redux";
import ShipActions from "redux-store/ship.redux";
import { handleSendNotiInSaga, deepCloneJsonObject } from "utils";
import { LangConstant } from "const";
import { CirclePicker } from "react-color";
import { Delete, Search } from "@mui/icons-material";
import { AppConstant } from "const";

const LayerCreateGroupMonitoringDialog = ({ isOpenCreateGroup, setIsOpenCreateGroup }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const createGroupShip = useSelector(state => state.conversationRedux.createGroupShip);
  const isSearchingInDialog = useSelector(state => state.shipRedux.isSearchingInDialog);
  const filterDialogShipList = useSelector(state => state.shipRedux.filterDialogShipList);

  const [groupMonitoringName, setGroupMonitoringName] = useState("");
  const [groupMonitoringReason, setGroupMonitoringReason] = useState("");
  const [color, setColor] = useState("#ffff");
  const [searchInput, setSearchInput] = useState("");
  const [errors, setErrors] = useState({ ship: "", reason: "" });
  const [searchEl, setSearchEl] = useState(null);
  const [selectedShips, setSelectedShips] = useState([]);

  const onClickAddListShip = () => {
    if (Boolean(groupMonitoringName) && Boolean(groupMonitoringReason)) {
      dispatch(
        ConversationActions.createShipGroup({
          name: groupMonitoringName,
          type: 1,
          note: groupMonitoringReason,
          color: color,
          ships: selectedShips.map(s => s.uid),
        }),
      );
      dispatch(ConversationActions.conversationSet({ createGroupShip: false }));
    } else {
      handleSendNotiInSaga(LangConstant.MS_ERROR_GROUP_NAME_EMPTY, "error");
    }
  };

  useEffect(() => {
    if (createGroupShip && isOpenCreateGroup) {
      setIsOpenCreateGroup(false);
      dispatch(ConversationActions.getGroupShip({}));
      dispatch(ShipActions.requestGetTrackingShips());
    }
  }, [createGroupShip]);

  const onFocusSearch = e => {
    setSearchEl(e.target.parentElement.parentElement);
    setErrors(state => ({ ...state, ship: "" }));
  };

  const onChangeSearchInput = e => {
    e.preventDefault();
    setSearchInput(e.target.value);
    dispatch(ShipActions.shipSet({ filterDialogShipList: [] }));
    dispatch(ShipActions.requestFilterShipsInDialog({ filter: e.target.value }));
  };

  const onClosePopover = () => {
    setSearchEl(null);
  };

  const onSubmitSearchShip = e => {
    e.preventDefault();
    dispatch(ShipActions.shipSet({ filterDialogShipList: [] }));
    dispatch(ShipActions.requestFilterShipsInDialog({ filter: searchInput }));
  };
  const onToggleChangeSelect = ship => {
    if (selectedShips.find(s => s.uid === ship.uid)) {
      setSelectedShips(state => state.filter(s => s.uid !== ship.uid));
    } else {
      setSelectedShips(state => deepCloneJsonObject([...state, ship]));
    }
  };

  return (
    <>
      <Dialog open={isOpenCreateGroup} onClose={() => setIsOpenCreateGroup(false)} fullWidth>
        <DialogTitle>Thêm danh sách giám sát</DialogTitle>
        <DialogContent>
          <Typography variant="p" className={classes.title}>
            1. Điền thông tin
          </Typography>
          <DialogContent>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                size="small"
                onChange={e => setGroupMonitoringName(e.target.value)}
                placeholder="Nhập tên danh sách giám sát "
                variant="outlined"
                value={groupMonitoringName}
              />
            </Box>

            <Box className={classes.paddingInput}>
              <TextField
                onChange={e => setGroupMonitoringReason(e.target.value)}
                fullWidth
                value={groupMonitoringReason}
                size="small"
                placeholder="Lý do giám sát "
                variant="outlined"
              />
            </Box>

            {/* <Box className={classes.selectColorShip}>
              Màu
              <ColorSketchPicker onChangeComplete={color => setColor(color)} height="40px" />
            </Box> */}
          </DialogContent>
          <Typography variant="p" className={classes.title}>
            2. Chọn màu
          </Typography>
          <DialogContent>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CirclePicker onChangeComplete={color => setColor(color.hex)} width={550} />
            </Box>
          </DialogContent>
          <Typography variant="p" className={classes.title}>
            3. Chọn tàu
          </Typography>
          <DialogContent>
            <form onSubmit={onSubmitSearchShip}>
              <FormControl fullWidth>
                <OutlinedInput
                  size="small"
                  type="text"
                  placeholder="Nhập tên tàu, IMO hoặc MMSI để tìm kiếm"
                  value={searchInput}
                  onFocus={onFocusSearch}
                  onChange={onChangeSearchInput}
                  startAdornment={
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Typography variant="caption" color="error">
                {errors.ship}
              </Typography>
              <Popover
                open={Boolean(searchEl)}
                anchorEl={searchEl}
                onClose={onClosePopover}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                disableRestoreFocus={true}
                classes={{ paper: classes.paper }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {isSearchingInDialog ? (
                  <Box display="flex" justifyContent="flex-start" alignItems="center" gap="6px">
                    <Search color="primary" />
                    <Typography>Đang tìm kiếm kết quả...</Typography>
                  </Box>
                ) : (
                  <Fragment>
                    <Typography variant="h6" style={{ marginBottom: 4 }}>
                      Kết quả tìm kiếm:
                    </Typography>
                    {filterDialogShipList.length > 0 ? (
                      <Box className={classes.resultCont}>
                        {filterDialogShipList.map((ship, index) => (
                          <Box key={index}>
                            <FormControlLabel
                              label={
                                <Typography>
                                  {ship.name} ({AppConstant.DATA_SOURCES[ship?.source_type]})
                                  {ship.mmsi ? <p style={{ fontSize: "12px" }}>MMSI: {ship.mmsi}</p> : <p></p>}
                                </Typography>
                              }
                              control={
                                <Checkbox
                                  value={ship.uid}
                                  onChange={() => onToggleChangeSelect(ship)}
                                  checked={selectedShips.map(s => s.uid).includes(ship.uid)}
                                />
                              }
                            />
                          </Box>
                        ))}
                      </Box>
                    ) : (
                      <Typography variant="caption">Không có kết quả tìm kiếm</Typography>
                    )}
                  </Fragment>
                )}
              </Popover>
            </form>
            <Box marginTop={2}>
              <Box style={{ maxHeight: "200px", overflowY: "scroll" }}>
                {selectedShips.length > 0 ? (
                  selectedShips.map(ship => (
                    <Box key={ship.uid} className={classes.selectedShip} marginLeft={1}>
                      <Typography>
                        &#x2022; {ship.name} ({AppConstant.DATA_SOURCES[ship?.source_type]})
                        {ship.mmsi ? <p style={{ fontSize: "12px" }}>MMSI: {ship.mmsi}</p> : <p></p>}
                      </Typography>
                      <IconButton size="small" onClick={() => onToggleChangeSelect(ship)}>
                        <Delete fontSize="small" />
                      </IconButton>
                    </Box>
                  ))
                ) : (
                  <Typography variant="caption" style={{ marginLeft: 8 }}>
                    &#x2022; Chưa có tàu được chọn
                  </Typography>
                )}
              </Box>
            </Box>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClickAddListShip}>Lưu</Button>
          <Button onClick={() => setIsOpenCreateGroup(false)}>Hủy</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default memo(LayerCreateGroupMonitoringDialog);

const useStyles = makeStyles(() => ({
  paddingInput: {
    marginTop: "10px",
  },
  title: { fontWeight: "bold", color: "#1976d2" },
  selectColorShip: {
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: 8,
    marginTop: 8,
    "&>button": {
      minWidth: 20,
    },
  },

  resultCont: {
    maxHeight: "30vh",
    height: "100%",
    overflowY: "scroll",
  },

  selectedShip: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 8,
  },
  paper: {
    width: 500,
    maxWidth: "unset",
    maxHeight: "unset",
    marginTop: 4,
    padding: 8,
  },
}));
