import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import MonitoringShipList from "./MonitoringShipList";
import { LangConstant } from "const";
import { useSelector } from "react-redux";

export default function MonitoringShip({ setSelectedGroupShips }) {
  const classes = useStyles();

  const trackingShips = useSelector(state => state.shipRedux.trackingShips);
  const isFetching = useSelector(state => state.shipRedux.isFetching);

  const [isOpen, setIsOpen] = useState(false);

  const onClickOpen = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <Box className={classes.monitoringShipContainer}>
      <Box onClick={() => onClickOpen()} className={classes.monitoringShipBox}>
        <Typography className={classes.monitoringListTitle}>{LangConstant.TXT_MONITORING_LIST}</Typography>
        {isOpen ? (
          <KeyboardArrowUp className={classes.colorBlack} />
        ) : (
          <KeyboardArrowDown className={classes.colorBlack} />
        )}
      </Box>
      {isOpen ? (
        isFetching ? (
          <Typography variant="subtitle2">Đang tải dữ liệu</Typography>
        ) : trackingShips.length === 0 ? (
          <Typography variant="subtitle2">Không có dữ liệu</Typography>
        ) : (
          trackingShips.map(item => (
            <MonitoringShipList item={item} key={item.uid} setSelectedGroupShips={setSelectedGroupShips} />
          ))
        )
      ) : (
        <></>
      )}
    </Box>
  );
}
const useStyles = makeStyles(theme => ({
  monitoringShipBox: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 4,
    "&:hover": {
      cursor: "pointer",
    },
  },

  monitoringListTitle: {
    fontSize: "16px",
    color: "#000",
    userSelect: "none",
  },

  colorBlack: {
    color: "#000",
  },

  shipListManageButtonBox: {
    marginTop: 16,
  },

  shipListManageButton: {
    fontSize: 12,
    width: "100%",
  },
}));
