import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  commonSet: ["data"],
  commonReset: [],
});

export const CommonTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  isFetching: false,
  isLoading: false,
  errors: null,
};

/* ------------- Reducers ------------- */

export const request = (state = INITIAL_STATE, action) => {
  let data = action.data || {};
  return {
    ...state,
    ...data,
    isFetching: true,
  };
};

export const set = (state = INITIAL_STATE, action) => {
  let data = action.data || {};

  return {
    ...state,
    ...data,
  };
};

export const reset = () => INITIAL_STATE;

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.COMMON_SET]: set,
  [Types.COMMON_RESET]: reset,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
