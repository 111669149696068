import { ApiConstant, EventConstant, LangConstant, SystemConstant } from "const";
import { call, put } from "redux-saga/effects";
import { EventWarningService } from "services";
import EventWarningAction from "../redux-store/eventwarning.redux";
import { handleOpenLoading, downloadBlob, handleSendNotiInSaga } from "utils";

const sendNotify = (message, type = "success") => {
  const event = new CustomEvent(EventConstant.GLOBAL_EVENTS.appendNotification, { detail: { message, type } });
  window.dispatchEvent(event);
};

const MESSAGE_ERROR = LangConstant.TXT_MESSAGE_ERROR;

export function* getEventWarningManager(action) {
  try {
    handleOpenLoading(true);
    const { data } = action;
    let response = yield call(EventWarningService.getEventWarningList, data);

    if (response.status === ApiConstant.STT_OK) {
      yield put(
        EventWarningAction.eventWarningSet({
          listEventWarning: response.data.data,
          totalPageEventWarning: response.data.total_page,
          totalElementEventWarning: response.data.total,
          isReGetEventWarnings: false,
        }),
      );
    } else {
      yield put(EventWarningAction.eventWarningReset());
    }
    handleOpenLoading(false);
  } catch (error) {
    yield put(EventWarningAction.eventWarningReset());
    console.log(error);
    handleOpenLoading(false);
  }
}

export function* changeNotifyOption(action) {
  try {
    const { data } = action;
    let response = yield call(EventWarningService.changeNotifyOption, {
      event_warning_uid: data.event_warning_uid,
      receive_notification_f: data.receive_notification_f,
    });

    if (response.status === ApiConstant.STT_OK) {
      yield put(
        EventWarningAction.eventWarningSet({
          isReGetEventWarnings: true,
        }),
      );
      if (data.receive_notification_f === SystemConstant.RECEIVE_NOTIFY_OPTION.ON_RECEIVE_NOTIFICATION)
        sendNotify("Đã bật thông báo về cấu hình " + data.event_id, "success");
      else sendNotify("Đã tắt thông báo về cấu hình " + data.event_id, "success");
    } else {
      sendNotify(MESSAGE_ERROR, "error");
    }
  } catch (error) {
    sendNotify(MESSAGE_ERROR, "error");
    console.log(error);
  }
}

export function* deleteEventWarning(action) {
  try {
    const { data } = action;
    let response = yield call(EventWarningService.deleteEventWarning, data);

    if (response.status === ApiConstant.STT_OK) {
      yield put(
        EventWarningAction.eventWarningSet({
          isReGetEventWarnings: true,
        }),
      );
      sendNotify("Đã xoá thành công cấu hình ", "success");
    } else {
      sendNotify(MESSAGE_ERROR, "error");
    }
  } catch (error) {
    sendNotify(MESSAGE_ERROR, "error");
    console.log(error);
  }
}

export function* createEventWarning(action) {
  try {
    const { data } = action;
    let response = yield call(EventWarningService.createEventWarning, data);

    if (response.status === ApiConstant.STT_OK) {
      yield put(
        EventWarningAction.eventWarningSet({
          isReGetEventWarnings: true,
          isOpenCreateEventWarning: false,
        }),
      );
      sendNotify("Đã tạo thành công cấu hình ", "success");
    } else {
      sendNotify(MESSAGE_ERROR, "error");
    }
  } catch (error) {
    sendNotify(MESSAGE_ERROR, "error");
    console.log(error);
  }
}

export function* updateEventWarning(action) {
  try {
    const { data } = action;
    let response = yield call(EventWarningService.updateEventWarning, data);

    if (response.status === ApiConstant.STT_OK) {
      yield put(
        EventWarningAction.eventWarningSet({
          isReGetEventWarnings: true,
          isOpenEditEventWarning: false,
        }),
      );
      sendNotify("Đã cập nhật thành công cấu hình ", "success");
    } else {
      sendNotify(MESSAGE_ERROR, "error");
    }
  } catch (error) {
    sendNotify(MESSAGE_ERROR, "error");
    console.log(error);
  }
}

export function* getEventWarningNotificationManager(action) {
  try {
    const { data } = action;
    let response = yield call(EventWarningService.getEventWarningNotification, data);
    if (response.status === ApiConstant.STT_OK) {
      handleOpenLoading(false);
      yield put(
        EventWarningAction.eventWarningSet({
          listEventWarningNotification: response.data.data,
          totalPageEventWarningNotification: response.data.total_page,
          totalElementEventWarningNotification: response.data.total,
        }),
      );
    } else {
      handleOpenLoading(false);
      yield put(EventWarningAction.eventWarningReset());
    }
  } catch (error) {
    handleOpenLoading(false);
    yield put(EventWarningAction.eventWarningReset());
    console.log(error);
  }
}

export function* getTopEventWarningNotificationManager(action) {
  try {
    let response = yield call(EventWarningService.getEventWarningNotification, {
      page: 0,
      size: 5,
      filter: "",
    });
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        EventWarningAction.eventWarningSet({
          listTopEventWarningNotification: response.data.data,
          is_new: response.data.is_new,
        }),
      );
    } else {
      yield put(EventWarningAction.eventWarningReset());
    }
  } catch (error) {
    yield put(EventWarningAction.eventWarningReset());
    console.log(error);
  }
}

export function* exportEventWarningNotification(action) {
  try {
    const { data } = action;
    let response = yield call(EventWarningService.exportEventWarningNotification, data);
    const dataResponse = response.data;
    if (response.status === ApiConstant.STT_OK) {
      downloadBlob(dataResponse, "Danh_sach_canh_bao.xlsx");
      handleSendNotiInSaga(LangConstant.MS_EXPORT_PROFILE_SUCCESS, "success");
    } else {
      handleSendNotiInSaga(LangConstant.MS_EXPORT_PROFILE_FAILED, "error");
    }
    handleOpenLoading(false);
  } catch (error) {
    handleOpenLoading(false);
    handleSendNotiInSaga(LangConstant.MS_EXPORT_PROFILE_FAILED, "error");
    console.log(error);
  }
}
