import * as React from "react";
import { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  Pagination,
} from "@mui/material";
import { Edit, Delete, Download } from "@mui/icons-material";
import FolderAction from "redux-store/folder.redux";
import { format } from "date-fns";
import { LangConstant, AppConstant } from "const";
import Cookies from "js-cookie";
import { debounce } from "lodash";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import { hasRoleScopes } from "utils";
import DownloadFilesDialog from "./DownloadFilesDialog";

const TableFolderManagement = () => {
  const dispatch = useDispatch();

  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [dataToDelete, setDataToDelete] = useState(null);
  const [isOpenDownload, setIsOpenDownload] = useState(false);
  const [dataToDownload, setDataToDownload] = useState(null);

  const currentUserId = Cookies.get(AppConstant.KEY_USER_ID);
  const pageSize = 20;
  const listFolder = useSelector(state => state.folderRedux.listFolder);
  const isReGetFolders = useSelector(state => state.folderRedux.isReGetFolders);
  const totalPage = useSelector(state => state.folderRedux.totalPageFolder);
  const totalSize = useSelector(state => state.folderRedux.totalElementFolder);

  const onChangePage = (e, value) => {
    setPage(value);
  };

  const onOpenCreate = () => {
    dispatch(FolderAction.folderSet({ isOpenCreateFolder: true }));
  };
  const onOpenEdit = data => {
    dispatch(
      FolderAction.folderSet({
        isOpenEditFolder: true,
        dataFolder: data,
      }),
    );
  };

  const downloadFolder = uid => {
    dispatch(
      FolderAction.downloadFolder({
        uid: uid,
      }),
    );
  };

  useEffect(() => {
    dispatch(
      FolderAction.getFolders({
        page: page - 1,
        size: pageSize,
        name: searchInput,
      }),
    );
  }, [page, isReGetFolders, searchInput]);

  return (
    <>
      <Box sx={{ fontWeight: "bold", fontSize: "20px", color: "cornflowerblue" }}>
        {LangConstant.L_MANAGER_FOLDER_REPORT}: {totalSize}
      </Box>

      <Box sx={{ marginTop: 2, display: "flex", justifyContent: "space-between" }}>
        <TextField
          sx={{ width: 500 }}
          onChange={debounce(e => {
            setSearchInput(e.target.value);
          }, 500)}
          fullWidth
          size="small"
          id="search_box_event_warning"
          label={LangConstant.L_SEARCH}
          variant="outlined"
        />
        {hasRoleScopes(AppConstant.SCOPES.FOLDER_CREATE) && (
          <Button variant="contained" onClick={() => onOpenCreate()}>
            {LangConstant.L_BTN_ADD_NEW}
          </Button>
        )}
      </Box>

      <TableContainer style={{ height: 700 }}>
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="40px">{LangConstant.TXT_STT}</TableCell>
              <TableCell align="center">{LangConstant.TXT_FILE_ID}</TableCell>
              <TableCell align="center">{LangConstant.TXT_FOLDER_REPORT_NAME}</TableCell>
              <TableCell align="center">{LangConstant.TXT_FOLDER_REPORT_QUANTITY}</TableCell>
              <TableCell align="center">{LangConstant.TXT_FOLDER_REPORT_OWNER}</TableCell>
              <TableCell align="center">{LangConstant.TXT_TIME_UPDATE}</TableCell>
              <TableCell align="center">{LangConstant.TXT_MANIPULATION}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {listFolder &&
              listFolder.map((data, index) => {
                return (
                  <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} key={data.uid} id={data.uid}>
                    <TableCell>{(page - 1) * pageSize + index + 1}</TableCell>
                    <TableCell align="center">{`FOLDER_${data.id}`}</TableCell>
                    <TableCell align="center">{data.name}</TableCell>
                    <TableCell align="center"> {data.total_file} </TableCell>
                    <TableCell align="center">{data.owner?.name || ""}</TableCell>
                    <TableCell align="center">{data.modified ? format(data.modified, "dd/MM/yyyy") : ""}</TableCell>
                    <TableCell align="center" sx={{ display: "flex", justifyContent: "space-around" }}>
                      <Fragment>
                        {hasRoleScopes(AppConstant.SCOPES.FOLDER_UPDATE) && (
                          <IconButton
                            disabled={data.owner.uid && currentUserId !== data.owner.uid}
                            title={"Chỉnh sửa"}
                            onClick={() => onOpenEdit(data)}
                          >
                            <Edit />
                          </IconButton>
                        )}
                        {hasRoleScopes(AppConstant.SCOPES.FOLDER_DELETE) && (
                          <IconButton
                            disabled={data.owner.uid && currentUserId !== data.owner.uid}
                            title={"Xoá folder"}
                            onClick={() => {
                              setIsOpenDelete(true);
                              setDataToDelete(data);
                            }}
                          >
                            <Delete />
                          </IconButton>
                        )}
                        <IconButton
                          title={"Tải xuống"}
                          onClick={() => {
                            setIsOpenDownload(true);
                            setDataToDownload(data);
                          }}
                        >
                          <Download />
                        </IconButton>
                      </Fragment>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ padding: "10px" }}>
        <Pagination onChange={onChangePage} sx={{ paddingTop: 2 }} count={totalPage} variant="outlined" page={page} />
      </Box>
      <ConfirmDeleteDialog data={dataToDelete} open={isOpenDelete} onClose={() => setIsOpenDelete(false)} />
      <DownloadFilesDialog data={dataToDownload} open={isOpenDownload} onClose={() => setIsOpenDownload(false)} />
    </>
  );
};

export default TableFolderManagement;
