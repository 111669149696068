import React, { useEffect, useState } from "react";
import { Table, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { convertTimeStampToDate } from "utils/date.utils";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import ShipAction from "redux-store/ship.redux";
import { isToday } from "date-fns";

const DataCrawlHistory = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const reduxData = useSelector(state => state.shipRedux.dataHistory);

  const [history, setHistory] = useState([]);
  const [totalTodayNewShip, setTotalTodayNewShip] = useState(0);
  const [totalTodayNewLocation, setTotalTodayNewLocation] = useState(0);
  const [totalShip, setTotalShip] = useState(0);

  useEffect(() => {
    dispatch(ShipAction.getCrawlDataHistory());
  }, []);

  useEffect(() => {
    if (reduxData && reduxData.length > 0 && JSON.stringify(reduxData) !== JSON.stringify(history)) {
      setHistory(reduxData);
      let newShip = 0;
      let newLocation = 0;
      let totalShipInDb = 0;

      reduxData.forEach(item => {
        if (isToday(item.time)) {
          newShip += item.totalNewShip;
          newLocation += item.totalNewLocation;
          if (item.totalShip && item.totalShip > totalShipInDb) {
            totalShipInDb = item.totalShip;
          }
        }
      });
      setTotalShip(totalShipInDb);
      setTotalTodayNewLocation(newLocation);
      setTotalTodayNewShip(newShip);
    }
  }, [reduxData]);

  return (
    <>
      <h2>Số lượng tàu mới trong ngày: {totalTodayNewShip}</h2>
      <h2>Số lượng vị trí mới trong ngày: {totalTodayNewLocation}</h2>
      <h2>Tổng số lượng tàu trong cơ sở dữ liệu: {totalShip}</h2>
      <Table>
        <TableHead>
          <TableRow className={classes.tableHeadRow}>
            <TableCell>
              <Typography className={classes.tableCell}>STT</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.tableCell}>Thời gian</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.tableCell}>Số tàu mới</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.tableCell}>Số địa điểm mới</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.tableCell}>Nguồn Marine Traffic</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.tableCell}>Nguồn GSTC</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.tableCell}>Nguồn Vshipel</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.tableCell}>Nguồn Vessel Finder</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.tableCell}>Dung lượng database</Typography>
            </TableCell>
          </TableRow>
          {history.map((his, index) => (
            <TableRow key={`${his.totalNewShip}${index}`}>
              <TableCell>
                <Typography className={classes.tableCell}>{index + 1}</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.tableCell}>
                  {"" + convertTimeStampToDate(his.time / 1000, "dd/MM/yyyy")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.tableCell}>{his.totalNewShip}</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.tableCell}>{his.totalNewLocation}</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.tableCell}>{his.fromMarineTraffic}</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.tableCell}>{his.fromGstc}</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.tableCell}>{his.fromVshipel}</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.tableCell}>{his.fromVesselFinder}</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.tableCell}>
                  {"" + his.size + " (" + Math.round(his.size / 1024 / 1024) + " Gb)"}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableHead>
      </Table>
    </>
  );
};

DataCrawlHistory.propTypes = {};

const useStyles = makeStyles(() => ({
  tableCell: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default DataCrawlHistory;
