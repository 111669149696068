import React, { Suspense, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce, uniqBy } from "lodash";
import { makeStyles } from "@mui/styles";
import { Box, Typography, Checkbox, FormGroup, TextField, InputAdornment } from "@mui/material";
import { KeyboardArrowUp, KeyboardArrowDown, Search, RestartAlt } from "@mui/icons-material";
import StringFormat from "string-format";
import ShipActions from "redux-store/ship.redux";
import { LangConstant, SystemConstant } from "const";
import { LazyScroller } from "components";
import { useMapContext, useFlickerMarker } from "hooks";
import ColorSketchPicker from "components/ColorSketchPicker";
import ConversationActions from "redux-store/conversation.redux";

function MonitoringShipList({ item }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const shipArr = item.ships;
  const { map, mapState, flickerLayerRef } = useMapContext();

  const shipConditions = useSelector(state => state.shipRedux.shipConditions);
  const { createFlickerMarker } = useFlickerMarker(flickerLayerRef);
  const filterShipResult = useSelector(state => state.shipRedux.filterShipResult);

  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [showList, setShowList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [limit, setLimit] = useState(SystemConstant.LIMIT_PAGING_DEFAULT);
  const trackingShips = useSelector(state => state.shipRedux.trackingShips);

  const onScrollToBottom = () => {
    if (limit < shipArr.length) {
      setLimit(state => state + SystemConstant.LIMIT_PAGING_DEFAULT);
    }
  };

  const onClickOpen = () => {
    setIsOpen(prev => !prev);
  };

  const onChangeSearchValue = debounce(value => {
    setSearchValue(value);
  }, 1000);

  const onFilterShip = e => {
    e.preventDefault();
  };

  const isAllSelected = shipArr.length > 0 && selected.length === shipArr.length;
  const updateSelectTrackingShips = selectedList =>
    trackingShips.map(trackingShip => {
      if (trackingShip.uid === item.uid) {
        return {
          ...trackingShip,
          selected: selectedList,
        };
      } else {
        return trackingShip;
      }
    });

  const [timeoutId, setTimeoutId] = useState(null);

  const updateColorTrackingShips = color => {
    dispatch(
      ShipActions.shipSet({
        trackingShips: trackingShips.map(trackingShipItem => {
          if (trackingShipItem.uid === item.uid) {
            return { ...trackingShipItem, color: color };
          } else {
            return trackingShipItem;
          }
        }),
      }),
    );
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }

    setTimeoutId(
      setTimeout(() => {
        updateGroupShip(color);
        setTimeoutId(null);
      }, 5000),
    );
  };

  const updateGroupShip = color => {
    dispatch(
      ConversationActions.updateGroupShip({
        ...item,
        color: color,
        ships: item?.ships.map(ship => {
          return ship.uid;
        }),
      }),
    );
  };
  useEffect(() => {
    dispatch(
      ShipActions.shipSet({
        trackingShips: updateSelectTrackingShips(selected),
      }),
    );
  }, [selected]);
  const onChangeValue = value => {
    if (value === "all") {
      if (selected.length === shipArr.length) {
        setSelected([]);
      } else {
        let members = shipArr.map(item => item.uid);
        setSelected(members);
      }
    } else {
      let list = [...selected];
      let index = list.indexOf(value);
      if (index === -1) {
        list.push(value);
      } else {
        list.splice(index, 1);
      }
      setSelected(list);
    }
  };

  const onUpdateNewest = item => {
    let groupIds = [item.uid];
    dispatch(
      ShipActions.shipSet({
        isChangingFilter: true,
        shipConditions: Object.assign(shipConditions, { group_ids: groupIds, handleGroupMembers: shipArr }),
      }),
    );
    dispatch(ShipActions.requestFilterShips({ bounds: map.current.getBounds(), updateF: 1 }));
  };

  useEffect(() => {
    if (shipArr.length > 0) {
      let tmpFilterArr = uniqBy([...shipArr], "uid");
      if (searchValue) {
        let normalizedSearchValue = searchValue.toLowerCase();
        tmpFilterArr = tmpFilterArr.filter(item => item.name.toLowerCase().includes(normalizedSearchValue));
      }
      tmpFilterArr.slice(0, limit > showList.length ? showList.length : limit);
      setShowList(tmpFilterArr.slice(0, limit));
    }
  }, [searchValue, shipArr, limit]);

  const onJumpToShipNotification = (filterShip, data) => {
    const shipNotification = filterShip.find(ship => ship?.ship_uid === data.uid);
    if (mapState === "loaded" && shipNotification) {
      map.current.setView([shipNotification.ship_lat, shipNotification.ship_long], 12);
      createFlickerMarker(shipNotification);
    }
  };

  return (
    <Box className={classes.monitoringShipBox}>
      <Box className={classes.filterSelectedAllBox}>
        <Box className={classes.selectAllBox}>
          <Checkbox
            value="all"
            onChange={() => onChangeValue("all")}
            checked={isAllSelected}
            className={classes.selectAllCheckBox}
          />
        </Box>
        <Box className={classes.monitoringShipTitleBox}>
          <Box className={classes.monitoringShipTitle} onClick={() => onClickOpen()}>
            <Typography fontSize="13px" title={item.name ? item.name : "Danh sách chưa đặt tên"}>
              {StringFormat(
                LangConstant.FM_MONITORING_AREA,
                item.name ? item.name.slice(0, 20) + (item.name.length > 20 ? "..." : "") : "Chưa đặt tên",
                shipArr.length,
              )}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box className={classes.selectColor}>
              <ColorSketchPicker
                onChangeComplete={color => {
                  updateColorTrackingShips(color);
                }}
                height="40px"
                stylesPopover={{ right: -66 }}
                defaultColor={item?.color}
              />
            </Box>
            <Box
              className={classes.btnUpdateNewest}
              title="Cập nhật vị trí mới nhất"
              onClick={() => onUpdateNewest(item)}
            >
              <RestartAlt fontSize="small" />
            </Box>
            {isOpen ? (
              <KeyboardArrowUp className={classes.colorBlack} />
            ) : (
              <KeyboardArrowDown className={classes.colorBlack} />
            )}
          </Box>
        </Box>
      </Box>
      <Box>
        {isOpen && (
          <>
            <form onSubmit={onFilterShip}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                variant="outlined"
                placeholder={LangConstant.P_INPUT_SEARCH}
                size="small"
                onChange={e => onChangeSearchValue(e.target.value)}
                autoComplete="off"
                className={classes.textField}
              />
            </form>
            <FormGroup
              classes={{
                root: classes.shipBox,
              }}
            >
              <LazyScroller className={classes.chatList} onScrollToBottom={onScrollToBottom}>
                <Suspense fallback={<Box></Box>}>
                  {showList.length > 0 &&
                    limit &&
                    showList.map(item => (
                      <Box className={classes.shipDeail}>
                        <Checkbox
                          value={item.uid}
                          onChange={() => {
                            onChangeValue(item.uid);
                          }}
                          checked={selected.includes(item.uid)}
                        />
                        <Typography
                          className={classes.selectBox}
                          onClick={e => {
                            if (selected.includes(item.uid)) {
                              onJumpToShipNotification(filterShipResult, item);
                            } else {
                              onChangeValue(item.uid);
                            }
                          }}
                        >
                          {item.name}
                        </Typography>
                      </Box>
                    ))}
                </Suspense>
              </LazyScroller>
            </FormGroup>
          </>
        )}
      </Box>
    </Box>
  );
}

export default MonitoringShipList;

const useStyles = makeStyles(theme => ({
  monitoringShipBox: {
    background: "#fff",
  },

  monitoringShipTitle: {
    display: "flex",
    alignItems: "center",
    userSelect: "none",
    paddingRight: "30px",
    "&:hover": {
      cursor: "pointer",
    },
  },

  selectAllBox: {
    display: "flex",
    alignItems: "center",
  },

  selectBox: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    textDecorationLine: "underline",
  },

  filterSelectedAllBox: {
    display: "flex",
    alignItems: "center",
  },

  shipBox: {
    display: "grid",
    gridTemplateColumns: "auto",
    marginBottom: 18,
    paddingLeft: 24,
    maxHeight: 400,
    borderBottom: "1px solid rgba(0, 0, 0, 0.2)",

    "&::-webkit-scrollbar": {
      width: "0.5em",
    },
    "&::-webkit-scrollbar-track": {
      background: "#F1F1F1",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
      borderRadius: 8,
    },
  },

  monitoringShipTitleBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },

  nameShip: {
    fontSize: "14px",
    color: "#000",
    lineHeight: "100%",
    display: "flex",
    alignItems: "center",
    userSelect: "none",
  },

  colorBlack: {
    color: "#000",
  },

  chatList: {
    height: "100%",
    overflow: "auto",
    maxHeight: 400,
    display: "flex",
    flexDirection: "column",
  },

  textField: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "unset",
      borderColor: "unset !important",
    },
  },

  btnUpdateNewest: {
    marginRight: "10px",
    border: "2px solid #000",
    borderRadius: "8px",
    width: "35px",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      background: "#2f94f9",
    },
  },
  selectColor: {
    marginRight: "10px",
  },

  shipDeail: {
    display: "flex",
  },
}));
