import React from "react";
import TablePortManagement from "./TablePortManagement";

const PortManagement = () => {
  // This actually is HarborManagement
  // Rename needed
  return (
    <div style={{ padding: 30 }}>
      <TablePortManagement />
    </div>
  );
};

export default PortManagement;
