import L from "leaflet";
import "leaflet-draw";

export const GLOBAL_EVENTS = {
  addTrackingShipData: "global:addTrackingShipData",
  optionButtonChanged: "global:optionButtonChanged",
  newVersionDetected: "global:newVersionDetected",
  forceUpdate: "global:forceUpdate",
  askUpdate: "global:askUpdate",
  swiperUpdated: "global:swiperUpdated",
  appendNotification: "global:appendNotification",
  openLoading: "global:openLoading",
};

export const MAP_EVENTS = {
  movestart: "map:movestart",
  moveend: "map:moveend",
  zoomend: "map:zoomend",
  contextmenu: "map:contextmenu",
  mousemove: "map:mousemove",
  mouseout: "map:mouseout",
};

export const MAP_DRAW_EVENTS = {
  DRAWSTART: L.Draw.Event.DRAWSTART,
  DRAWSTOP: L.Draw.Event.DRAWSTOP,
  CREATED: L.Draw.Event.CREATED,
  EDITSTART: L.Draw.Event.EDITSTART,
  EDITSTOP: L.Draw.Event.EDITSTOP,
  EDITED: L.Draw.Event.EDITED,
  DELETESTART: L.Draw.Event.DELETESTART,
  DELETESTOP: L.Draw.Event.DELETESTOP,
  DELETED: L.Draw.Event.DELETED,
};

export const GATEWAY_EVENTS = {
  syncShipInfo: "gateway:syncShipInfo",
  syncShipLocation: "gateway:syncShipLocation",
};

export const TRIOS_EVENTS = {
  receiveThirdToken: "trios:receiveThirdToken",
  requestGetThirdToken: "trios:requestGetThirdToken",
};

export const POINT_EVENTS = {
  clickPoint: "point:clickPoint",
  createPoint: "point:createPoint",
  clickMeasure: "point:clickMeasure",
};
