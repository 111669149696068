import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { LangConstant } from "const";
import { useDispatch } from "react-redux";
import EventWarningAction from "redux-store/eventwarning.redux";
import StringFormat from "string-format";
import { Close } from "@mui/icons-material";
import { useNotificationQueue } from "hooks";

const ConfirmChangeNotifyDialog = props => {
  const { open, onClose, data } = props;
  const dispatch = useDispatch();
  const { setNotificationQueue } = useNotificationQueue();

  const onClickNotify = () => {
    const option = data.receive_notification_f ? 0 : 1;
    if (data.receive_notification_f) {
      setNotificationQueue([]);
    }
    dispatch(
      EventWarningAction.changeNotifyOption({
        receive_notification_f: option,
        event_warning_uid: data.uid,
        event_id: data.event_id,
      }),
    );
    onClose();
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={onClose}>
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        {data
          ? data.receive_notification_f
            ? LangConstant.TXT_TURN_OFF_NOTIFY_EVENT
            : LangConstant.TXT_TURN_ON_NOTIFY_EVENT
          : ""}
        <IconButton onClick={onClose} sx={{ marginLeft: "auto" }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ paddingTop: 10 }}>
        {data
          ? data.receive_notification_f
            ? StringFormat(LangConstant.FM_CONFIRM_TURN_OFF_NOTIFY, data.event_id)
            : StringFormat(LangConstant.FM_CONFIRM_TURN_ON_NOTIFY, data.event_id)
          : ""}
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
        <Button variant="contained" onClick={onClickNotify}>
          {LangConstant.TXT_YES}
        </Button>
        <Button onClick={onClose} sx={{ color: "white", backgroundColor: "red" }}>
          {LangConstant.TXT_NO}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmChangeNotifyDialog;
