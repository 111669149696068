import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ShareProfile from "./ShareProfile";
import ShowDetailsProfile from "./ShowDetailsProfile";
import UserRedux from "redux-store/user.redux";
import Cookies from "js-cookie";
import { AppConstant } from "const";
import ChangeAvatar from "./ChangeAvatar";
import UserActionTable from "../CurrentUserAction/UserActionTable";

const Profile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const userInfo = useSelector(state => state.userRedux.userInfo);

  useEffect(() => {
    dispatch(UserRedux.getUserInfo(Cookies.get(AppConstant.KEY_USER_ID)));
    return () => dispatch(UserRedux.userSet({ userInfo: null }));
  }, []);

  return userInfo ? (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Box className={classes.containerAvatar}>
          <ChangeAvatar userInfo={userInfo} />
          <ShareProfile userInfo={userInfo} />
        </Box>
      </Grid>
      <Grid item xs={9}>
        <ShowDetailsProfile userInfo={userInfo} />
        <Box className={classes.containerUserActionTable}>
          <UserActionTable userId={Cookies.get(AppConstant.KEY_USER_ID)} />
        </Box>
      </Grid>
    </Grid>
  ) : (
    <Fragment />
  );
};

export default Profile;

const useStyles = makeStyles(() => ({
  containerAvatar: {
    textAlign: "center",
    margin: "100px auto",
  },

  containerUserActionTable: {
    padding: "0px 10px",
    marginRight: "50px",
  },
}));
