import { useEffect } from "react";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import TableFolderManagement from "./TableFolderManagement";
import CreateFolder from "./CreateFolder";
import EditFolder from "./EditFolder";
import FolderAction from "redux-store/folder.redux";

const FolderManagement = () => {
  const dispatch = useDispatch();
  const isOpenCreate = useSelector(state => state.folderRedux.isOpenCreateFolder);
  const isOpenEdit = useSelector(state => state.folderRedux.isOpenEditFolder);

  useEffect(() => {
    return () => {
      dispatch(
        FolderAction.folderSet({
          isOpenCreateFolder: false,
          isOpenEditFolder: false,
        }),
      );
    };
  }, []);

  return (
    <Box style={{ padding: 30 }}>
      {!isOpenCreate && !isOpenEdit && <TableFolderManagement />}
      {isOpenCreate && <CreateFolder />}
      {isOpenEdit && <EditFolder />}
    </Box>
  );
};

export default FolderManagement;
