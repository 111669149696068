import React from "react";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ApiConstant, AppConstant, LangConstant } from "const";
import { useNotification } from "hooks";
import { ShipService } from "services";
import { handleOpenLoading } from "utils";
import { useDispatch } from "react-redux";
import ShipRedux from "redux-store/ship.redux";

const DialogConfirmRemoveListShip = ({ open, onCloseDialogConfirm, listShipRemove, lostType }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notification = useNotification();

  const onConfirmRemove = async () => {
    const response = await ShipService.removeListShipLost({
      ship_uids: listShipRemove,
    });

    if (response.status === ApiConstant.STT_OK) {
      notification.send(LangConstant.TXT_REMOVE_LIST_SHIP_SUCCESS, "success");
      dispatch(
        ShipRedux.getListShipLost({
          statistic_type: lostType,
          export_f: AppConstant.IS_EXPORT_FILE.no,
          page: AppConstant.FIRST_PAGE,
          size: AppConstant.PAGE_SIZE,
        }),
      );
      handleOpenLoading(true);
    } else {
      notification.send(LangConstant.TXT_ERROR_CONTENT, "error");
    }
    onCloseDialogConfirm();
  };

  return (
    <Dialog open={open} onClose={onCloseDialogConfirm} fullWidth>
      <DialogTitle className={classes.dialogTitle}>{LangConstant.TXT_CONFIRM_REMOVE_LIST_SHIP}</DialogTitle>
      <DialogActions className={classes.buttonAction}>
        <Button variant="outlined" onClick={onCloseDialogConfirm}>
          {LangConstant.TXT_CANCEL}
        </Button>
        <Button variant="contained" onClick={onConfirmRemove}>
          {LangConstant.TXT_CONFIRM}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfirmRemoveListShip;

const useStyles = makeStyles({
  dialogTitle: {
    margin: "8px",
    textAlign: "center",
  },

  buttonAction: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "16px",
  },
});
