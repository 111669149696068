import { call, put, select } from "redux-saga/effects";
import AreaActions from "redux-store/area.redux";
import ShipActions from "redux-store/ship.redux";
import { ApiConstant, AppConstant, LangConstant } from "const";
import { AreaService } from "services";
import { deepCloneJsonObject, handleOpenLoading, handleSendNotiInSaga } from "utils";

export function* getDefaultArea() {
  try {
    let response = yield call(AreaService.getArea, { type: AppConstant.DEFAULT_AREA_KEY });

    if (response.status === ApiConstant.STT_OK && response.data.data) {
      yield put(
        AreaActions.areaSet({
          defaultAreaList: response.data.data,
        }),
      );
    } else {
      yield put(
        AreaActions.areaSet({
          errors: response,
        }),
      );
    }
  } catch (error) {
    console.error(error);
  }
}

export function* getCustomArea() {
  try {
    let response = yield call(AreaService.getArea, { type: AppConstant.CUSTOM_AREA_KEY });

    if (response.status === ApiConstant.STT_OK && response.data.data) {
      yield put(
        AreaActions.areaSet({
          customAreaList: response.data.data,
        }),
      );
    } else {
      yield put(
        AreaActions.areaSet({
          errors: response,
        }),
      );
    }
  } catch (error) {
    console.error(error);
  }
}

export function* getPointArea() {
  try {
    let response = yield call(AreaService.getArea, { type: AppConstant.POINT_AREA_KEY });

    if (response.status === ApiConstant.STT_OK && response.data.data) {
      yield put(
        AreaActions.areaSet({
          pointAreaList: response.data.data,
        }),
      );
    } else {
      yield put(
        AreaActions.areaSet({
          errors: response,
        }),
      );
    }
  } catch (error) {
    console.error(error);
  }
}

export function* createCustomArea({ data }) {
  try {
    let coordinateList = data.points.map(point => ({ lat: point.lat, long: point.lng }));
    let firstCoordinate = deepCloneJsonObject(coordinateList[0]);
    let payload = {
      name: data.name,
      center_lat: data.center_lat,
      center_lon: data.center_lon,
      radius: data.radius / AppConstant.METER_TO_NAUTICAL_MILE,
      coordinate_list: [...coordinateList, firstCoordinate],
      type: AppConstant.CUSTOM_AREA_KEY,
    };
    let response = yield call(AreaService.createCustomArea, payload);

    if (response.status === ApiConstant.STT_OK && response.data.data) {
      yield put(
        AreaActions.areaSet({
          customArea: response.data.data,
        }),
      );
    } else {
      yield put(
        AreaActions.areaSet({
          errors: response,
        }),
      );
    }
  } catch (error) {
    console.error(error);
  }
}

export function* updateCustomArea({ data }) {
  try {
    let coordinateList = data.points;
    let firstCoordinate = deepCloneJsonObject(coordinateList[0]);
    let payload = {
      name: data.name,
      coordinate_list: [...coordinateList, firstCoordinate],
      uid: data.uid,
      center_lat: data.center_lat,
      center_lon: data.center_lon,
      radius: data.radius / AppConstant.METER_TO_NAUTICAL_MILE,
    };

    let response = yield call(AreaService.updateCustomArea, payload);
    if (response.status === ApiConstant.STT_OK && response.data.data) {
      let customAreaList = yield select(state => state.areaRedux.customAreaList);
      let selectedAreaList = yield select(state => state.areaRedux.selectedAreaList);

      let newCustomAreaList = customAreaList.map(area =>
        response.data.data.uid === area.uid ? response.data.data : area,
      );

      let newSelectedAreaList = selectedAreaList?.map(area =>
        response.data.data.uid === area.uid ? response.data.data : area,
      );

      yield put(
        AreaActions.areaSet({
          editedArea: response.data.data,
          customAreaList: newCustomAreaList,
          selectedAreaList: newSelectedAreaList,
        }),
      );
    } else {
      yield put(
        AreaActions.areaSet({
          errors: response,
        }),
      );
    }
  } catch (error) {
    console.error(error);
  }
}

export function* createPointArea({ data }) {
  try {
    let payload = {
      name: data.pointName,
      center_lat: data.centerLat,
      center_lon: data.centerLon,
      description: data.description,
      color: data.color,
      type: AppConstant.POINT_AREA_KEY,
    };
    handleOpenLoading(true);
    let response = yield call(AreaService.createCustomArea, payload);
    if (response.status === ApiConstant.STT_OK && response.data.data) {
      yield put(
        AreaActions.areaSet({
          isFetching: true,
        }),
      );
      yield getPointArea();
      handleSendNotiInSaga(LangConstant.MS_CREATE_POINT_AREA_SUCCESS, "success");
    } else {
      handleSendNotiInSaga(LangConstant.TXT_MESSAGE_ERROR, "error");
    }
    handleOpenLoading(false);
  } catch (error) {
    console.error(error);
    handleOpenLoading(false);
    handleSendNotiInSaga(LangConstant.TXT_MESSAGE_ERROR, "error");
  }
}

export function* updatePointArea({ data }) {
  try {
    let payload = {
      name: data.pointName,
      center_lat: data.centerLat,
      center_lon: data.centerLon,
      description: data.description,
      color: data.color,
      type: AppConstant.POINT_AREA_KEY,
      uid: data.uid,
    };
    handleOpenLoading(true);
    let response = yield call(AreaService.updateCustomArea, payload);
    if (response.status === ApiConstant.STT_OK && response.data.data) {
      yield put(
        AreaActions.areaSet({
          isFetching: true,
        }),
      );
      yield getPointArea();
      handleSendNotiInSaga(LangConstant.MS_UPDATE_POINT_AREA_SUCCESS, "success");
    } else {
      handleSendNotiInSaga(LangConstant.TXT_MESSAGE_ERROR, "error");
    }
    handleOpenLoading(false);
  } catch (error) {
    console.error(error);
    handleOpenLoading(false);
    handleSendNotiInSaga(LangConstant.TXT_MESSAGE_ERROR, "error");
  }
}

export function* deletePointArea({ data }) {
  try {
    let payload = {
      uid: data.uid,
    };

    let response = yield call(AreaService.deleteCustomArea, payload);
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        AreaActions.areaSet({
          isFetching: true,
        }),
      );
      yield getPointArea();
      handleSendNotiInSaga(LangConstant.MS_DELETE_POINT_AREA_SUCCESS, "success");
    } else {
      handleSendNotiInSaga(LangConstant.TXT_MESSAGE_ERROR, "error");
    }
    handleOpenLoading(false);
  } catch (error) {
    console.error(error);
    handleOpenLoading(false);
    handleSendNotiInSaga(LangConstant.TXT_MESSAGE_ERROR, "error");
  }
}

export function* deleteCustomArea({ data }) {
  try {
    let payload = {
      uid: data.uid,
    };

    let response = yield call(AreaService.deleteCustomArea, payload);
    if (response.status === ApiConstant.STT_OK) {
      let customAreaList = yield select(state => state.areaRedux.customAreaList);
      let selectedAreaList = yield select(state => state.areaRedux.selectedAreaList);
      let newCustomAreaList = customAreaList.filter(area => data.uid !== area.uid);
      let newSelectedAreaList = selectedAreaList?.filter(area => data.uid !== area.uid);

      yield put(
        AreaActions.areaSet({
          deleteAreaStatus: "success",
          customAreaList: newCustomAreaList,
          selectedAreaList: newSelectedAreaList,
        }),
      );
    } else {
      yield put(
        AreaActions.areaSet({
          errors: response,
          deleteAreaStatus: "error",
        }),
      );
    }
  } catch (error) {
    console.error(error);
    yield put(
      AreaActions.areaSet({
        errors: error,
        deleteAreaStatus: "error",
      }),
    );
  }
}

export function* uploadKmlFile({ data }) {
  try {
    let formData = new FormData();
    formData.append("file", data.file);
    formData.append("area_name", data.areaName);
    let mimeT = data.file.name.split(".")[1];
    formData.append("mime_type", mimeT);
    let payload = { formData: formData };

    let res = yield call(AreaService.uploadKmlFile, payload);

    if (res.status === ApiConstant.STT_OK) {
      yield put(
        AreaActions.areaSet({
          isUploadKmlSuccess: true,
          kmlUid: res.data.data.uid,
          uploadKmlStatus: "success",
        }),
      );

      yield getCustomArea();
    } else {
      yield put(
        AreaActions.areaSet({
          isUploadKmlSuccess: false,
          errors: res,
          uploadKmlStatus: "error",
        }),
      );
    }
  } catch (error) {
    console.trace(error);
    yield put(
      AreaActions.areaSet({
        isUploadKmlSuccess: false,
        errors: error,
        uploadKmlStatus: "error",
      }),
    );
  }
}

export function* getKmlFiles({ data }) {
  try {
    handleOpenLoading(true);
    let res = yield call(AreaService.getKmlFile, { uid: data.uid });
    if (res.status === ApiConstant.STT_OK) {
      let fileType = res.headers["content-type"].split("/")[1];
      let kmlFiles = yield select(state => state.areaRedux.kmlFiles);
      let kmlFileClone = deepCloneJsonObject(kmlFiles);
      kmlFileClone[data.uid] = { file: res.data, type: fileType };
      yield put(AreaActions.areaSet({ kmlFiles: kmlFileClone }));
    }
    handleOpenLoading(false);
  } catch (error) {
    yield put(ShipActions.shipSet({ errors: error }));
    console.error(error);
    handleOpenLoading(false);
  }
}

export function* getTypeArea(action) {
  try {
    const { data } = action;
    let response = yield call(AreaService.getTypeAreaSaga, data);
    if (response.status === ApiConstant.STT_OK && response.data.data) {
      yield put(
        AreaActions.areaSet({
          getTypeArea: response.data.data,
        }),
      );
    } else {
      yield put(
        AreaActions.areaSet({
          errors: response,
        }),
      );
    }
  } catch (error) {
    console.error(error);
  }
}

export function* getWeatherPoint({ data }) {
  try {
    let response = yield call(AreaService.getWeatherPoint, data);

    if (response.status === ApiConstant.STT_OK && response.data.data) {
      yield put(
        AreaActions.areaSet({
          pointWeather: response.data.data,
        }),
      );
    } else {
      yield put(
        AreaActions.areaSet({
          errors: response,
        }),
      );
    }
  } catch (error) {
    console.error(error);
  }
}
