import L from "leaflet";
import { useMapContext } from "./useMapContext";
import { EventConstant } from "const";
import { EventBus } from "EventBus";

export const usePointMarker = () => {
  const { pointLayerRef, placeholderVectorLayerRef, setHoverPoint } = useMapContext();

  const clear = () => {
    if (pointLayerRef.current) {
      pointLayerRef.current.clear();
    }
  };

  const refresh = () => {
    if (pointLayerRef.current) {
      pointLayerRef.current.redraw();
    }
  };
  const createIconPoint = color => {
    return L.icon({
      iconSize: [20, 20],
      iconAnchor: [10, 10],
      iconUrl:
        "data:image/svg+xml;base64," +
        btoa(`<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="${color}" version="1.1" id="Capa_1" width="800px" height="800px" viewBox="0 0 395.71 395.71" xml:space="preserve">
                <g>
                  <path d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738   c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388   C335.179,61.609,273.569,0,197.849,0z M197.849,88.138c27.13,0,49.191,22.062,49.191,49.191c0,27.115-22.062,49.191-49.191,49.191   c-27.114,0-49.191-22.076-49.191-49.191C148.658,110.2,170.734,88.138,197.849,88.138z"/>
                </g>
              </svg>`),
    });
  };

  const createMarker = (lat, lng, icon, info) => {
    const marker = L.marker([lat, lng], { icon: icon });
    marker.on("mouseover", function () {
      const placeholderMarker = L.marker([lat, lng], { icon: L.divIcon() });
      placeholderVectorLayerRef.current.addLayer(placeholderMarker);
      placeholderMarker.on("mouseover", function (e) {
        setHoverPoint({ info: info, event: e.originalEvent });
      });
      placeholderMarker.on("click", function (e) {
        EventBus.emit(EventConstant.POINT_EVENTS.clickMeasure, e);
        EventBus.emit(EventConstant.POINT_EVENTS.clickPoint, { event: e, info: info });
      });
    });
    marker.on("mouseout", function (e) {
      placeholderVectorLayerRef.current.clearLayers();
      setHoverPoint({ info: null, event: null });
    });

    return marker;
  };

  const show = (points = []) => {
    const markers = points.map(point => {
      const marker = createMarker(point.center_lat, point.center_lon, createIconPoint(point?.color || "#000"), point);
      return marker;
    });

    pointLayerRef.current.addMarkers(markers);
  };

  const remove = (markers = []) => {
    pointLayerRef.current.removeMarkers(markers);
  };

  return {
    clear,
    refresh,
    show,
    remove,
  };
};
