import { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  IconButton,
  Typography,
  Tab,
  Tabs,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";
import { convertMillisecondsToDateTime } from "utils/date.utils";
import { convertCoordinates } from "utils";
import { ShipService } from "services";

const ShipDetailDialog = ({ open, onClose, ship }) => {
  const classes = useStyles();

  const onCloseDialog = () => {
    onClose();
  };
  const [value, setValue] = useState("info");
  const [currentTrackingShipDefault, setCurrentTrackingShipDefault] = useState({});
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (!ship?.ship_uid) return;
    const now = new Date();
    const start = new Date(now.getTime() - 24 * 60 * 60 * 1000).getTime();
    let payload = {
      start: start,
      end: now.getTime(),
      ship_uid_list: [ship?.ship_uid],
    };
    ShipService.trackingShips(payload).then(res => {
      setCurrentTrackingShipDefault(res?.data?.data?.[0]);
    });
  }, [ship]);

  return (
    <>
      <Dialog open={open} onClose={onCloseDialog} maxWidth="xl" fullWidth>
        <Box className={classes.header}>
          <Typography className="header-title">{`Thông tin tàu [${ship?.ship_name}] `}</Typography>
          <IconButton size="small" onClick={onCloseDialog}>
            <Close fontSize="small" />
          </IconButton>
        </Box>
        <Box className={classes.body}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab value="info" label="Thông tin tàu" />
              <Tab value="history" label="Lịch sử" />
            </Tabs>
          </Box>
        </Box>
        {value === "info" ? (
          <Box className={classes.detail}>
            <table className={classes.popupTable}>
              <tbody>
                <tr>
                  <td colspan="2" className={classes.tableHeader}>
                    Thông tin tàu cá
                  </td>
                </tr>
                <tr>
                  <th className={classes.th}>Số đăng ký</th>
                  <td className={classes.td}>{ship?.ship_name}</td>
                </tr>
                <tr>
                  <th className={classes.th}>Cảng đăng ký</th>
                  <td className={classes.td}>{ship?.main_fishing_port}</td>
                </tr>
                <tr>
                  <th className={classes.th}>Chiều dài lớn nhất</th>
                  <td className={classes.td}>{ship?.length}</td>
                </tr>
                <tr>
                  <th className={classes.th}>Chiều rộng lớn nhất</th>
                  <td className={classes.td}>{ship?.width}</td>
                </tr>
                <tr>
                  <th className={classes.th}>Công suất máy chính </th>
                  <td className={classes.td}>{ship?.capacity}</td>
                </tr>
                <tr>
                  <td colspan="2" className={classes.tableHeader}>
                    Thông tin chủ tàu
                  </td>
                </tr>
                <tr>
                  <th className={classes.th}>Chủ tàu</th>
                  <td className={classes.td}>{ship?.ship_owner?.name}</td>
                </tr>
                <tr>
                  <th className={classes.th}>CCCD</th>
                  <td className={classes.td}>{ship?.ship_owner?.citizen_identification}</td>
                </tr>
                <tr>
                  <th className={classes.th}>Địa chỉ</th>
                  <td className={classes.td}>{ship?.ship_owner?.address}</td>
                </tr>
                <tr>
                  <th className={classes.th}>Di động</th>
                  <td className={classes.td}>{ship?.ship_owner?.phone}</td>
                </tr>{" "}
                <tr>
                  <td colspan="2" className={classes.tableHeader}>
                    Thông tin thuyền trưởng
                  </td>
                </tr>
                <tr>
                  <th className={classes.th}>Thuyền trưởng</th>
                  <td className={classes.td}>{ship?.ship_captain?.name}</td>
                </tr>
                <tr>
                  <th className={classes.th}>CCCD</th>
                  <td className={classes.td}>{ship?.ship_captain?.citizen_identification}</td>
                </tr>
                <tr>
                  <th className={classes.th}>Địa chỉ</th>
                  <td className={classes.td}>{ship?.ship_captain?.address}</td>
                </tr>
                <tr>
                  <th className={classes.th}>Di động</th>
                  <td className={classes.td}>{ship?.ship_captain?.phone}</td>
                </tr>
              </tbody>
            </table>
          </Box>
        ) : (
          <Box className={classes.detail}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD_COLUMNS.map(column => (
                      <TableCell key={column.id} align={column.align} style={{ top: 57, minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentTrackingShipDefault &&
                    currentTrackingShipDefault.location_history.map((locationData, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">{convertMillisecondsToDateTime(locationData.time)}</TableCell>
                          <TableCell align="center">
                            {convertCoordinates(
                              Number(locationData.lat).toFixed(5),
                              Number(locationData.long).toFixed(5),
                            )}
                          </TableCell>
                          <TableCell align="center">{locationData.speed} knot</TableCell>
                          <TableCell align="center">{locationData.course}°</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Dialog>
    </>
  );
};

export default ShipDetailDialog;
const TABLE_HEAD_COLUMNS = [
  { id: "index", label: "STT", minWidth: 20, align: "center" },
  { id: "timestamp", label: "Thời gian", minWidth: 90, align: "center" },
  { id: "location", label: "Vị trí", minWidth: 90, align: "center" },
  { id: "speed", label: "Vận tốc", minWidth: 50, align: "center" },
  { id: "direction", label: "Hướng", minWidth: 50, align: "center" },
];

const useStyles = makeStyles(theme => ({
  body: {
    padding: 16,
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 16,
    "& > .header-title": {
      textTransform: "uppercase",
      fontSize: 16,
      fontWeight: "bold",
    },
  },

  detail: {
    width: "100%",
    padding: "20px",
    maxHeight: "500px",
    overflow: "auto",
  },

  popupTable: {
    width: "100%",
    padding: "20px",
  },
  tableHeader: {
    fontSize: 16,
    padding: "10px",
    backgroundColor: "#d9edf7",
    border: "1px solid #010101",
  },
  th: {
    padding: "10px",
    border: "1px solid #010101",
  },
  td: {
    padding: "10px",
    border: "1px solid #010101",
  },
}));
