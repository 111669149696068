import { call, put } from "redux-saga/effects";
import WeatherAction from "redux-store/weather.redux";
import { ApiConstant } from "const";
import { WeatherService } from "services";
import { getPointsFromLatLongBounds, toCamel } from "utils";

export function* getWeatherData() {
  try {
    let bounds = getPointsFromLatLongBounds();

    let response = yield call(WeatherService.getWeatherData, { zoom: 5, ...bounds });
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        WeatherAction.weatherSet({
          windPointArray: toCamel(response.data.data || []),
        }),
      );
    }
  } catch (error) {
    console.error(error);
  }
}
