import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { LangConstant, SystemConstant } from "const";
import { useClickOutside } from "hooks";
import { convertMillisecondsToDateTime } from "utils/date.utils";

const DetailNotification = props => {
  const { open, onClose, data } = props;
  const ref = useClickOutside(() => onClose);

  return (
    data && (
      <Dialog open={open} sx={{ maxWidth: "xs" }} fullWidth onClose={onClose} ref={ref}>
        <DialogTitle>{LangConstant.TXT_SHOW_DETAIL}</DialogTitle>
        <DialogContent sx={{ paddingTop: 10 }}>
          <Typography>
            {LangConstant.TXT_SHIP_NAME}: {data.content.slice(data.content.indexOf("[") + 1, data.content.indexOf("]"))}
          </Typography>

          <Typography>
            {LangConstant.TXT_EVENT_WARNING_TYPE}:{" "}
            {Object.values(SystemConstant.TYPE_ACTION_WARNING).find(item => item.id === data.type)?.name || ""}
          </Typography>

          <Typography>
            {LangConstant.TXT_TIME}: {convertMillisecondsToDateTime(data.created)}
          </Typography>

          <Typography>
            {LangConstant.TXT_DESCRIPTION}: {data.content}
          </Typography>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    )
  );
};

export default DetailNotification;
