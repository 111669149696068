import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { Box, Button, FormControl, IconButton, OutlinedInput, Typography } from "@mui/material";
import ColorSketchPicker from "components/ColorSketchPicker";
import { makeStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";
import clsx from "clsx";
import dayjs from "dayjs";
import { EventConstant, FormatConstant, SystemConstant } from "const";
import { convertCoordIntToObject, convertHex2rgba, getPositionOfPopup, toDecimalDegree, dragElement } from "utils";
import { useMapContext, useNotification } from "hooks";
import { EventBus } from "EventBus";
import AreaAction from "redux-store/area.redux";

const LayerCreatePointDialog = () => {
  const classes = useStyles();
  const notification = useNotification();
  const dispatch = useDispatch();
  const { map, mapState, turnOnMeasure } = useMapContext();
  const [isEdit, setIsEdit] = useState(false);

  const isFetching = useSelector(state => state.areaRedux.isFetching);

  const [dialogPositions, setDialogPositions] = useState({ top: 0, left: 0, bottom: 0, right: 0, display: "none" });

  const [payload, setPayload] = useState({
    pointName: "",
    centerLat: 0,
    centerLon: 0,
    description: "",
    created: dayjs().format(FormatConstant.FM_DD_MM_YYY_HH_MM_DAY_JS),
    latDms: { degrees: 0, minutes: 0, seconds: 0 },
    lngDms: { degrees: 0, minutes: 0, seconds: 0 },
  });

  const handleContextClick = e => {
    const pointerEvent = e.originalEvent;
    const { top, left, bottom, right } = getPositionOfPopup(pointerEvent);
    const latDms = convertCoordIntToObject(e.latlng.lat);
    const lngDms = convertCoordIntToObject(e.latlng.lng);
    setDialogPositions({ top, left, bottom, right, display: "block" });
    setPayload({
      pointName: "",
      description: "",
      created: dayjs().format(FormatConstant.FM_DD_MM_YYY_HH_MM_DAY_JS),
      centerLat: e.latlng.lat,
      centerLon: e.latlng.lng,
      latDms: latDms,
      lngDms: lngDms,
    });
  };

  const handleChangeNormalInput = e => {
    setPayload(state => ({ ...state, [e.target.name]: e.target.value }));
  };

  const handleChangeLat = (d, m, s) => {
    setPayload(state => ({
      ...state,
      centerLat: toDecimalDegree(d, m, s),
      latDms: { degrees: d, minutes: m, seconds: s },
    }));
  };

  const handleChangeColor = color => {
    setPayload(state => ({ ...state, color: color }));
  };

  const handleChangeLng = (d, m, s) => {
    setPayload(state => ({
      ...state,
      centerLon: toDecimalDegree(d, m, s),
      lngDms: { degrees: d, minutes: m, seconds: s },
    }));
  };

  const handleClose = () => {
    setDialogPositions(state => ({ ...state, display: "none" }));
  };

  const handleCreatePoint = e => {
    if (parseInt(Cookies.get("role")) !== SystemConstant.ROLE.staff) {
      handleContextClick(e);
      setIsEdit(false);
    }
  };

  const handleClickEdit = ({ event, info }) => {
    handleContextClick(event);
    setIsEdit(info);
    setPayload(state => ({
      ...state,
      centerLat: info.center_lat,
      centerLon: info.center_lon,
      description: info.description,
      pointName: info.name,
      color: info.color,
      created: info.created,
    }));
  };

  const handleDelete = () => {
    dispatch(AreaAction.deletePointArea(isEdit));
  };

  const handleSubmit = () => {
    if (!payload.pointName) {
      notification.send("Vui lòng nhập tên điểm", "error");
    } else if (!payload.latDms.degrees && !payload.latDms.minutes && !payload.latDms.seconds) {
      notification.send("Vui lòng nhập đầy đủ vĩ độ", "error");
    } else if (!payload.lngDms.degrees && !payload.lngDms.minutes && !payload.lngDms.seconds) {
      notification.send("Vui lòng nhập đầy đủ kinh độ", "error");
    } else {
      if (!isEdit) {
        dispatch(AreaAction.requestCreatePointArea(payload));
      } else {
        dispatch(AreaAction.requestUpdatePointArea({ ...payload, uid: isEdit.uid }));
      }
    }
  };

  const handleStopPropagation = e => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (map.current && mapState === "loaded") {
      EventBus.on(EventConstant.POINT_EVENTS.createPoint, handleCreatePoint);
      if (!turnOnMeasure) {
        EventBus.on(EventConstant.POINT_EVENTS.clickPoint, handleClickEdit);
      }

      dragElement("layer-create-point-dialog-");
    }

    return () => {
      EventBus.off(EventConstant.POINT_EVENTS.createPoint, handleCreatePoint);
      EventBus.off(EventConstant.POINT_EVENTS.clickPoint, handleClickEdit);
    };
  }, [map, mapState, turnOnMeasure]);

  useEffect(() => {
    if (isFetching) {
      dispatch(AreaAction.requestGetPointArea({ isFetching: false }));
      dispatch(
        AreaAction.areaSet({
          isFetching: false,
        }),
      );
      handleClose();
    }
  }, [isFetching]);

  return (
    <Box
      className={classes.root}
      onClick={handleStopPropagation}
      onDoubleClick={handleStopPropagation}
      onWheel={handleStopPropagation}
      sx={dialogPositions}
      component="section"
      id="layer-create-point-dialog-"
    >
      <Box className={classes.addFormHeader} id="layer-create-point-dialog-header">
        <Typography className={classes.headerTitle}>
          {isEdit ? "Sửa điểm trên bản đồ" : "Thêm điểm trên bản đồ"}
        </Typography>
        <IconButton className={classes.closeBtn} onClick={handleClose}>
          <Close />
        </IconButton>
      </Box>
      <Box className={classes.formBody}>
        <FormControl size="small" className={classes.formInputField}>
          <Typography className={classes.formLabel}>Tên điểm: </Typography>
          <OutlinedInput
            placeholder="Nhập tên điểm"
            name="pointName"
            value={payload.pointName}
            onChange={handleChangeNormalInput}
            classes={{
              root: classes.inputRoot,
              input: classes.formInput,
            }}
          />
        </FormControl>
        <Box className={classes.formInputField}>
          <Typography className={classes.formLabel}>Vĩ độ: </Typography>
          <Box className={classes.coordBox}>
            <OutlinedInput
              size="small"
              placeholder="Độ"
              value={payload.latDms.degrees}
              inputProps={{ type: "number", min: -90, max: 90 }}
              onChange={e => handleChangeLat(e.target.value, payload.latDms.minutes, payload.latDms.seconds)}
              endAdornment={<Typography className={classes.inputUnit}>&deg;</Typography>}
              classes={{
                root: classes.coordInput,
                input: clsx(classes.formInput, classes.coordPaddingInput),
              }}
            />
            <OutlinedInput
              size="small"
              placeholder="Phút"
              value={payload.latDms.minutes}
              inputProps={{ type: "number", min: 0, max: 60 }}
              onChange={e => handleChangeLat(payload.latDms.degrees, e.target.value, payload.latDms.seconds)}
              endAdornment={<Typography className={classes.inputUnit}>'</Typography>}
              classes={{
                root: classes.coordInput,
                input: clsx(classes.formInput, classes.coordPaddingInput),
              }}
            />
            <OutlinedInput
              size="small"
              placeholder="Giây"
              value={payload.latDms.seconds}
              inputProps={{ type: "number", min: 0, max: 60 }}
              onChange={e => handleChangeLat(payload.latDms.degrees, payload.latDms.minutes, e.target.value)}
              endAdornment={<Typography className={classes.inputUnit}>"</Typography>}
              classes={{
                root: classes.coordInput,
                input: clsx(classes.formInput, classes.coordPaddingInput),
              }}
            />
          </Box>
        </Box>
        <Box className={classes.formInputField}>
          <Typography className={classes.formLabel}>Kinh độ: </Typography>
          <Box className={classes.coordBox}>
            <OutlinedInput
              size="small"
              placeholder="Độ"
              value={payload.lngDms.degrees}
              inputProps={{ type: "number", min: -180, max: 180 }}
              onChange={e => handleChangeLng(e.target.value, payload.lngDms.minutes, payload.lngDms.seconds)}
              endAdornment={<Typography className={classes.inputUnit}>&deg;</Typography>}
              classes={{
                root: classes.coordInput,
                input: clsx(classes.formInput, classes.coordPaddingInput),
              }}
            />
            <OutlinedInput
              size="small"
              placeholder="Phút"
              value={payload.lngDms.minutes}
              inputProps={{ type: "number", min: 0, max: 60 }}
              onChange={e => handleChangeLng(payload.lngDms.degrees, e.target.value, payload.lngDms.seconds)}
              endAdornment={<Typography className={classes.inputUnit}>'</Typography>}
              classes={{
                root: classes.coordInput,
                input: clsx(classes.formInput, classes.coordPaddingInput),
              }}
            />
            <OutlinedInput
              size="small"
              placeholder="Giây"
              value={payload.lngDms.seconds}
              inputProps={{ type: "number", min: 0, max: 60 }}
              onChange={e => handleChangeLng(payload.lngDms.degrees, payload.lngDms.minutes, e.target.value)}
              endAdornment={<Typography className={classes.inputUnit}>"</Typography>}
              classes={{
                root: classes.coordInput,
                input: clsx(classes.formInput, classes.coordPaddingInput),
              }}
            />
          </Box>
        </Box>
        <FormControl size="small" className={classes.formInputField}>
          <Typography className={classes.formLabel}>Thời gian: </Typography>
          <OutlinedInput
            type="datetime-local"
            value={payload.created}
            name="created"
            onChange={handleChangeNormalInput}
            classes={{
              root: classes.inputRoot,
              input: classes.formInput,
            }}
          />
        </FormControl>
        <FormControl size="small" className={classes.formInputField}>
          <Typography className={classes.formLabel}>Ghi chú: </Typography>
          <OutlinedInput
            multiline
            minRows={5}
            name="description"
            placeholder="Ghi chú"
            value={payload.description}
            onChange={handleChangeNormalInput}
            classes={{
              root: clsx(classes.inputRoot, classes.noteInput),
              input: classes.formInput,
            }}
          />
        </FormControl>
        <FormControl size="small" className={classes.formInputField}>
          <Typography className={classes.formLabel}>Chọn màu: </Typography>
          <Box className={classes.inputRoot}>
            <ColorSketchPicker
              onChangeComplete={color => {
                handleChangeColor(color);
              }}
              height="40px"
              stylesPopover={{ top: 2, right: -30 }}
              defaultColor={payload.color || "#000"}
            />
          </Box>
        </FormControl>
      </Box>
      <Box className={classes.actionContainer}>
        <Button className={classes.saveBtn} disableElevation variant="contained" onClick={handleSubmit}>
          Lưu
        </Button>
        {isEdit && (
          <Button variant="outlined" disableElevation onClick={handleDelete}>
            Xóa
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default memo(LayerCreatePointDialog);

const useStyles = makeStyles(() => ({
  root: {
    position: "fixed",
    width: 400,
    backgroundColor: "#fff",
    zIndex: 1000,
    boxShadow: "0px 0px 2px 2px rgba(0, 0, 0, 0.25)",
    borderRadius: 4,
    cursor: "auto",
  },

  addFormHeader: {
    height: "fit-content",
    width: "100%",
    backgroundColor: "#1976d2",
    padding: "10px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    color: "#fff",
    borderRadius: "4px 4px 0 0",
    cursor: "all-scroll",
  },

  closeBtn: {
    color: "white",
    position: "absolute",
    right: 10,
    top: "50%",
    transform: "translateY(-50%)",
    padding: 6,
    "&>svg": {
      fontSize: 16,
    },
  },

  formBody: {
    padding: "8px 15px 0",
    position: "relative",
  },

  loadingContainer: {
    padding: "8px 15px 0",
    position: "absolute",
    background: convertHex2rgba("#808080", 0.9),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    zIndex: 1001,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  loading: {},

  loadingText: {
    fontSize: 15,
    fontWeight: 450,
    marginTop: 20,
  },

  headerTitle: {
    fontSize: 15,
    fontWeight: 450,
  },

  formInputField: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },

  inputRoot: {
    height: 36,
    width: "65%",
    "&>.MuiInputAdornment-root": {
      "&>button": {
        padding: 2,
        marginRight: "auto",
      },
    },
  },

  coordInput: {
    width: "30%",
    height: 32,
    paddingRight: 0,
  },

  formInput: {
    padding: "0 10px",
    maxHeight: "100%",
    height: "100%",
    fontSize: 16,
  },

  formLabel: {
    fontSize: 15,
    fontWeight: 500,
    minWidth: "max-content",
    marginRight: 20,
    width: "calc(35% - 20px)",
  },

  coordBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "65%",
  },

  noteInput: {
    height: "auto",
    padding: "3px 10px",
    "&>textarea": {
      maxHeight: 150,
      overflow: "auto !important",
      padding: 0,
    },
  },

  actionContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "20px 15px",
    alignItems: "center",
    gap: 30,
  },

  saveBtn: {
    backgroundColor: "#1976d2",
    borderRadius: 4,
    textTransform: "none",
  },

  inputUnit: {
    backgroundColor: "#DDD",
    height: "100%",
    padding: "0 8px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },

  coordPaddingInput: {
    paddingRight: 0,
  },

  shipType: {
    fontSize: 15,
    fontWeight: 500,
    marginTop: 13,
  },

  createdErr: {
    color: "red",
    height: "100%",
    alignItems: "center",
    marginRight: 8,
    fontSize: 15,
    fontWeight: 500,
  },
}));
