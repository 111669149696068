import { createReducer, createActions } from "reduxsauce";
import { hasLogin } from "utils";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  createUser: ["data"],
  deleteUser: ["data"],
  changePassword: ["data"],
  editUser: ["data"],
  getHistory: ["data"],
  getUserList: ["data"],
  resetPassword: ["data"],
  createShip: ["data"],
  getListShip: ["data"],
  getShipType: ["data"],
  deleteListShip: ["data"],
  updateShip: ["data"],
  createShipGroup: ["data"],
  getHarbors: ["data"],
  createHarbors: ["data"],
  createMorePort: ["data"],
  getPortManager: ["data"],
  deleteHarbors: ["data"],
  updateHarborShip: ["data"],
  getGroupShip: ["data"],
  deleteGroupShip: ["data"],
  updateGroupShip: ["data"],
  deleteGroupDetailShip: ["data"],
  uploadFile: ["data"],
  exportFile: ["data"],
  importFile: ["data"],
  getGroupShipDetail: ["data"],
  getListShipWatch: ["data"],
  getListDetails: ["data"],

  getDetailsGroup: ["data"],

  getstatistic: ["data"],
  updateStatistic: ["data"],
  deleteStatistic: ["data"],

  conversationSet: ["data"],
  conversationReset: [],
});

export const ConversationTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  isFetching: false,
  isLoginSuccess: hasLogin(),
  createUserSuccess: false,
  errCreateUserFailed: false,
  sucessHistory: false,
  userList: [],
  sucessResestPassword: false,
  successGetUser: [],
  successResestPassword: false,
  changePasswordSuccess: false,
  successDeleteUser: false,
  successEditUser: false,
  isManageShip: true,
  isShowShip: true,
  sucessCreateShip: false,
  getListShip: false,
  getTypeShip: [],
  deleteListShip: false,
  createGroupShip: false,
  getPortManager: false,
  createMorePort: false,
  getHarbors: false,
  deleteHarbors: false,
  updateHarborShip: false,
  createPortSuccsess: false,
  errLongtitude: false,
  errLangtitude: false,
  errName: false,
  getGroupsucsess: [],
  deleteMuchGroupShips: false,
  deleteGroupShip: false,
  errCreateGroupShips: false,
  checkbox: [],
  uploadFileSuccess: false,
  getDetailGroup: [],
  pagination: false,
  getListShipSuccess: false,
  errLoginFailure: false,
  errLinkTriosFailure: false,
  listShipWatch: false,
  updateShip: false,
  allItemsDetailsGroups: false,
  isCreateShipSuccess: false,

  totalPageListShip: false,
  getDetailGroupShip: false,
  totalPageUser: false,
  totalPageHistory: false,
  totalPageHarbors: 1,
  totalPageGrouShip: false,
  totalPageDetailGroup: false,
  totalPagePort: 1,

  getStatistic: false,
  updateStatisticSucess: false,
  dataLocalStorage: false,
  deleteStatistic: false,
  getStatisticDetail: false,
  searchStatistic: false,
  tolal: false,
  loading: false,
  getStatistics: false,

  paramShiptyeNationality: false,
  paramShiptyeSearchShip: false,
  paramShiptyeShips: false,
  paramShiptyePanigation: false,

  errCreateShip: false,
  errEditShip: false,
  errChangePassword: false,
  errEmailEditUser: false,
  errPermission: false,
  errorDuplicatedName: null,
  updateShipErrorMsg: null,
  createShipErrorMsg: null,

  isClusteredShip: true,
  isShowShipName: false,
  currentZoom: null,
};

/* ------------- Reducers ------------- */

export const request = (state = INITIAL_STATE, action) => {
  let data = action.data || {};
  return {
    ...state,
    ...data,
    isFetching: true,
  };
};

export const set = (state = INITIAL_STATE, action) => {
  let data = action.data || {};

  return {
    ...state,
    ...data,
  };
};

export const reset = () => INITIAL_STATE;

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.CREATE_USER]: request,
  [Types.DELETE_USER]: request,
  [Types.CHANGE_PASSWORD]: request,
  [Types.EDIT_USER]: request,
  [Types.GET_HISTORY]: request,
  [Types.GET_USER_LIST]: request,
  [Types.RESET_PASSWORD]: request,
  [Types.CREATE_SHIP]: request,
  [Types.GET_LIST_SHIP]: request,
  [Types.GET_SHIP_TYPE]: request,
  [Types.DELETE_LIST_SHIP]: request,
  [Types.UPDATE_SHIP]: request,
  [Types.CREATE_SHIP_GROUP]: request,
  [Types.GET_HARBORS]: request,
  [Types.CREATE_HARBORS]: request,
  [Types.CREATE_MORE_PORT]: request,
  [Types.GET_PORT_MANAGER]: request,
  [Types.DELETE_HARBORS]: request,
  [Types.UPDATE_HARBOR_SHIP]: request,
  [Types.GET_GROUP_SHIP]: request,
  [Types.DELETE_GROUP_SHIP]: request,
  [Types.UPDATE_GROUP_SHIP]: request,
  [Types.DELETE_GROUP_DETAIL_SHIP]: request,
  [Types.UPLOAD_FILE]: request,
  [Types.EXPORT_FILE]: request,
  [Types.IMPORT_FILE]: request,
  [Types.GET_GROUP_SHIP_DETAIL]: request,
  [Types.GET_DETAILS_GROUP]: request,
  [Types.GETSTATISTIC]: request,
  [Types.UPDATE_STATISTIC]: request,
  [Types.DELETE_STATISTIC]: request,
  [Types.GET_LIST_SHIP_WATCH]: request,
  [Types.GET_LIST_DETAILS]: request,

  [Types.CONVERSATION_SET]: set,
  [Types.CONVERSATION_RESET]: reset,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
