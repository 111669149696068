import React, { memo, useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { Download, Pause, PlayArrow, ShareLocation } from "@mui/icons-material";
import { debounce } from "lodash";
import { useAnimationTrackingLayer, useExportTrackingVideo, useMapContext, useNotification } from "hooks";
import { DEFAULT_VIDEO_TRACKING_CONFIGS } from "pages/HomePage";
import { ShipLoadingAnimationImage } from "theme/images";

const LayerShipTrackingVideo = () => {
  const classes = useStyles();
  const notification = useNotification();
  const { videoTrackingOptions, setVideoTrackingOptions, sidebarMode } = useMapContext();
  const animationTrackingLayer = useAnimationTrackingLayer();
  const exportTrackingVideo = useExportTrackingVideo();

  const intervalRef = useRef();
  const inputRef = useRef();
  const lastAnimationTimestamp = useRef(0);
  const pauseAnimationTimestamp = useRef(0);

  const [exportVideoConfigs, setExportVideoConfigs] = useState({ open: false, exporting: false, fps: 24 });

  const stopPropagation = e => {
    e.stopPropagation();
  };

  const handleOpenExportVideoDialog = () => {
    setExportVideoConfigs(state => ({ ...state, open: true }));
  };

  const handleCloseExportVideoDialog = () => {
    setExportVideoConfigs({ open: false, exporting: false, fps: 24 });
  };

  const handleChangeFps = e => {
    setExportVideoConfigs(state => ({ ...state, fps: e.target.value }));
  };

  const handleGenerateVideo = () => {
    setExportVideoConfigs(state => ({ ...state, exporting: true }));
    startAnimationForExportVideo(exportVideoConfigs.fps);
    exportTrackingVideo.capture({
      id: "map",
      framerate: exportVideoConfigs.fps,
      duration: videoTrackingOptions.totalDuration,
      onEnd: handleGenerateVideoDone,
    });
  };

  const handleGenerateVideoDone = blob => {
    notification.send("Tạo video hoàn thành");
    setExportVideoConfigs({ open: false, exporting: false, fps: 24 });
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const base64data = reader.result;
      const aTag = document.createElement("a");
      aTag.href = base64data;
      aTag.download = "tracking";
      aTag.click();
      aTag.remove();
    };
  };

  const startAnimationForExportVideo = framerate => {
    window.cancelAnimationFrame(intervalRef.current);
    inputRef.current.value = 0;
    animationTrackingLayer.updateVideoTrackingConfigs(videoTrackingOptions, 2, 0);
    animationTrackingLayer.stop();
    animationTrackingLayer.play();
  };

  const handleGetCurrentDuration = value => {
    const { index, duration } = videoTrackingOptions.durationSteps.reduce(
      (obj, currentDuration, index) => {
        const newDuration = obj.currentDuration + currentDuration;
        if (newDuration <= value) {
          obj.index = index;
          obj.duration = obj.currentDuration;
        }
        obj.currentDuration = newDuration;
        return obj;
      },
      {
        index: 0,
        duration: 0,
        currentDuration: 0,
      },
    );

    return { index, duration };
  };

  const startAnimation = () => {
    if (videoTrackingOptions.play === false) {
      window.cancelAnimationFrame(intervalRef.current);
      animationTrackingLayer.updateVideoTrackingConfigs(videoTrackingOptions, videoTrackingOptions.speed, 0);
      animationTrackingLayer.play();
      animationTrackingLayer.openPopup(videoTrackingOptions);
      intervalRef.current = window.requestAnimationFrame(handleInterval(videoTrackingOptions.speed));
      setVideoTrackingOptions(state => ({ ...state, play: true }));
    }
  };

  const restartAnimation = () => {
    window.cancelAnimationFrame(intervalRef.current);
    inputRef.current.value = 0;
    animationTrackingLayer.updateVideoTrackingConfigs(videoTrackingOptions, videoTrackingOptions.speed, 0);
    animationTrackingLayer.play();
    animationTrackingLayer.openPopup(videoTrackingOptions);
    intervalRef.current = window.requestAnimationFrame(handleInterval(videoTrackingOptions.speed));
    setVideoTrackingOptions(state => ({ ...state, play: true }));
  };

  const pauseAnimation = () => {
    if (videoTrackingOptions.play === true && pauseAnimationTimestamp.current === 0) {
      pauseAnimationTimestamp.current = Date.now() / 1000;
      window.cancelAnimationFrame(intervalRef.current);
      animationTrackingLayer.pause();
      setVideoTrackingOptions(state => ({ ...state, play: false }));
      console.log("Pause Timestamp:", pauseAnimationTimestamp.current);
    }
  };

  const resumeAnimation = () => {
    if (videoTrackingOptions.play === false) {
      window.cancelAnimationFrame(intervalRef.current);
      animationTrackingLayer.resume();
      animationTrackingLayer.openPopup(videoTrackingOptions);
      intervalRef.current = window.requestAnimationFrame(handleInterval(videoTrackingOptions.speed));
      setVideoTrackingOptions(state => ({ ...state, play: true }));
    }
  };

  const stopAnimation = (props = {}) => {
    inputRef.current.value = 0;
    lastAnimationTimestamp.current = 0;
    pauseAnimationTimestamp.current = 0;
    window.cancelAnimationFrame(intervalRef.current);
    animationTrackingLayer.stop();
    setVideoTrackingOptions(state => ({ ...state, play: false, ...props }));
  };

  const resetAnimation = () => {
    animationTrackingLayer.stop();
    inputRef.current.value = 0;
    lastAnimationTimestamp.current = 0;
    pauseAnimationTimestamp.current = 0;
    window.cancelAnimationFrame(intervalRef.current);
    animationTrackingLayer.updateVideoTrackingConfigs(videoTrackingOptions, videoTrackingOptions.speed, 0);
    setVideoTrackingOptions(state => ({ ...state, play: false }));
  };

  const handleTogglePlay = () => {
    if (parseFloat(inputRef.current.value) >= videoTrackingOptions.totalDuration) {
      restartAnimation();
    } else {
      if (videoTrackingOptions.play) {
        pauseAnimation();
      } else {
        if (parseFloat(inputRef.current.value) > 0) {
          resumeAnimation();
        } else {
          startAnimation();
        }
      }
    }
  };

  const handleInterval = speed => {
    return timestamp => {
      if (parseFloat(inputRef.current?.value) >= videoTrackingOptions.totalDuration) {
        resetAnimation();
      } else {
        if (lastAnimationTimestamp.current === 0) lastAnimationTimestamp.current = timestamp;
        const currentValue =
          pauseAnimationTimestamp.current === 0
            ? parseFloat(inputRef.current.value) + (timestamp - lastAnimationTimestamp.current) * speed
            : parseFloat(inputRef.current.value) +
              (timestamp - (timestamp - pauseAnimationTimestamp.current) - pauseAnimationTimestamp.current) * speed;
        inputRef.current.value = currentValue;
        lastAnimationTimestamp.current = timestamp;
        pauseAnimationTimestamp.current = 0;
        intervalRef.current = window.requestAnimationFrame(handleInterval(speed));
      }
    };
  };

  const handleChangeVideoSpeed = speed => {
    if (videoTrackingOptions.speed !== speed) {
      const value = inputRef.current.value;
      const { index, duration } = handleGetCurrentDuration(value);
      window.cancelAnimationFrame(intervalRef.current);
      inputRef.current.value = duration;
      intervalRef.current = window.requestAnimationFrame(handleInterval(speed));
      animationTrackingLayer.updateVideoTrackingConfigs(videoTrackingOptions, speed, index);
      animationTrackingLayer.stop();
      animationTrackingLayer.play();
      animationTrackingLayer.openPopup(videoTrackingOptions);
      setVideoTrackingOptions(state => ({ ...state, speed: speed, play: true }));
    }
  };

  const handleShowShipInfo = key => {
    const newState = !videoTrackingOptions[key];
    setVideoTrackingOptions(state => ({ ...state, [key]: newState }));
    animationTrackingLayer.openPopup({ ...videoTrackingOptions, [key]: newState });
  };

  const handleChangeTrackingInput = e => {
    const value = e.target.value;
    const { index, duration } = handleGetCurrentDuration(value);
    window.cancelAnimationFrame(intervalRef.current);
    animationTrackingLayer.updateVideoTrackingConfigs(videoTrackingOptions, videoTrackingOptions.speed, index);
    animationTrackingLayer.stop();
    animationTrackingLayer.play();
    animationTrackingLayer.openPopup(videoTrackingOptions);
    inputRef.current.value = duration;
    intervalRef.current = window.requestAnimationFrame(handleInterval(videoTrackingOptions.speed));
    setVideoTrackingOptions(state => ({ ...state, play: true }));
  };

  useEffect(() => {
    if (videoTrackingOptions.shipUid) {
      stopAnimation();
    }
  }, [videoTrackingOptions.shipUid]);

  useEffect(() => {
    if (videoTrackingOptions.open === false) {
      stopAnimation();
    }
  }, [videoTrackingOptions.open]);

  useEffect(() => {
    stopAnimation(DEFAULT_VIDEO_TRACKING_CONFIGS);
    animationTrackingLayer.clear();
  }, [sidebarMode]);

  return (
    <Box
      className={classes.videoTrackingContainer}
      onClick={stopPropagation}
      onMouseMove={stopPropagation}
      onMouseDown={stopPropagation}
      onDoubleClick={stopPropagation}
      sx={{ display: videoTrackingOptions.open ? "flex" : "none" }}
      component="section"
    >
      <Box className={classes.logoContainer}>
        <Box title={videoTrackingOptions.shipName}>
          <Box className={classes.logo}>
            <ShareLocation htmlColor="#1976d2" />
          </Box>
          <Typography variant="caption" className={`${classes.shipName} limit-one-line`}>
            Tàu: {videoTrackingOptions.shipName}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.trackingContainer}>
        <Box className={classes.timeTrackingContainer}>
          <Box className={classes.playButton}>
            <IconButton onClick={handleTogglePlay}>
              {videoTrackingOptions.play ? <Pause htmlColor="#fff" /> : <PlayArrow htmlColor="#fff" />}
            </IconButton>
          </Box>
          <Box
            component="input"
            type="range"
            min={0}
            defaultValue={0}
            max={videoTrackingOptions.totalDuration}
            sx={{ width: "100%" }}
            ref={inputRef}
            onChange={debounce(e => handleChangeTrackingInput(e), 250)}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box className={classes.speedController}>
            <Box className={classes.whiteColor}>Tốc độ:</Box>
            {SPEED_BUTTONS.map(speed => (
              <Button
                key={speed}
                onClick={() => handleChangeVideoSpeed(speed)}
                className={`${classes.speedButton} ${videoTrackingOptions.speed === speed && "active"}`}
                size="small"
              >
                {speed}x
              </Button>
            ))}
          </Box>
          <Box className={classes.additionalButtons}>
            {SHIP_INFO_BUTTON.map((info, index) => (
              <Button
                key={index}
                size="small"
                variant={videoTrackingOptions[info.key] ? "contained" : "outlined"}
                className={classes.whiteColor}
                onClick={() => handleShowShipInfo(info.key)}
              >
                {info.title}
              </Button>
            ))}
          </Box>
        </Box>
      </Box>
      <Box className={classes.actionContainer}>
        <IconButton onClick={handleOpenExportVideoDialog}>
          <Download htmlColor="#fff" />
        </IconButton>
      </Box>
      <Dialog open={exportVideoConfigs.open} fullWidth>
        <DialogTitle>
          {exportVideoConfigs.exporting ? "Đang tạo video dữ liệu hành trình" : "Tạo video dữ liệu hành trình"}
        </DialogTitle>
        {exportVideoConfigs.exporting ? (
          <DialogContent>
            <DialogContentText variant="caption" marginBottom="8px">
              *Lưu ý: Vui lòng không rời khỏi màn hình này khi đang tạo video
            </DialogContentText>
            <img src={ShipLoadingAnimationImage} alt="Loading" width="100%" height="100%" />
          </DialogContent>
        ) : (
          <DialogContent>
            <DialogContentText marginBottom="8px">Chọn chất lượng video muốn tạo:</DialogContentText>
            <FormControl>
              <RadioGroup value={exportVideoConfigs.fps} onChange={handleChangeFps}>
                {EXPORT_VIDEO_FPS_CONFIGS.map((configs, index) => (
                  <FormControlLabel
                    key={index}
                    value={configs.fps}
                    control={<Radio size="small" />}
                    label={configs.title}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <DialogContentText variant="caption" color="error">
              *Lưu ý: Video chất lượng cao sẽ tốn dung lượng lưu trữ và thời gian chờ lâu
            </DialogContentText>
          </DialogContent>
        )}
        {!exportVideoConfigs.exporting && (
          <DialogActions>
            <Button variant="text" onClick={handleCloseExportVideoDialog}>
              Hủy
            </Button>
            <Button variant="contained" onClick={handleGenerateVideo} disableElevation>
              Tạo Video
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </Box>
  );
};

export default memo(LayerShipTrackingVideo);

const SPEED_BUTTONS = [1, 2, 3];

const SHIP_INFO_BUTTON = [
  {
    key: "showNamePopup",
    title: "Tên tàu",
  },
  {
    key: "showMMSIPopup",
    title: "MMSI",
  },
  {
    key: "showSpeedPopup",
    title: "Tốc độ",
  },
];

const EXPORT_VIDEO_FPS_CONFIGS = [
  {
    fps: 24,
    title: "Chất lượng cao",
  },
  {
    fps: 12,
    title: "Chất lượng trung bình",
  },
  {
    fps: 6,
    title: "Chất lượng thấp",
  },
];

const useStyles = makeStyles(theme => ({
  videoTrackingContainer: {
    position: "absolute",
    bottom: "40px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "1000px",
    maxWidth: "80%",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    zIndex: 1000,
    padding: "16px",
    borderRadius: "4px",
    alignItems: "center",
    justifyContent: "center",
    cursor: "auto",
  },

  logoContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    width: "100px",
  },

  logo: {
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#fff",
    borderRadius: "4px",
    marginBottom: "4px",
  },

  trackingContainer: {
    width: "calc(100% - 140px)",
    padding: "0 16px",
  },

  timeTrackingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  speedController: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
  },

  speedButton: {
    fontSize: 10,
    border: "1px solid #1976d2",
    width: 20,
    minWidth: 20,
    marginLeft: "8px",
    height: 20,
    color: "#fff",
    "&.active": {
      background: "#1976d2",
    },
  },

  playButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "16px",
  },

  additionalButtons: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "4px",
  },

  actionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100px",
  },

  whiteColor: {
    color: "#fff !important",
  },

  shipName: {
    width: "80px",
    display: "block",
    color: "#fff",
  },
}));
