import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useArea,
  useCanvasMarker,
  useClusteredMarker,
  useMapContext,
  useWeatherMarker,
  useVectorMarker,
  useWatchListMarket,
  usePointMarker,
} from "hooks";
import LayerMousePosition from "./LayerMousePosition";
import LayerTopRight from "./LayerTopRight";
import LayerBottomRight from "./LayerBottomRight/LayerBottomRight";
import LayerContextMenuDialog from "./LayerContextMenuDialog";
import LayerShipInfoTooltip from "./LayerShipInfoTooltip";
import LayerShipInfoDialog from "./LayerShipInfoDialog";
import LayerDrawShape from "./LayerDrawShape";
import LayerShipTrackingVideo from "./LayerShipTrackingVideo";
import ShipActions from "redux-store/ship.redux";
import { useNotification, useFlickerMarker } from "hooks";
import LayerPointInfoTooltip from "./LayerPointInfoTooltip";
import { EventBus } from "EventBus";
import { EventConstant } from "const";
import L from "leaflet";

const MapLayers = () => {
  const {
    map,
    mapState,
    isClusteredShip,
    isShowShip,
    isShowShipName,
    isShowShipSpeed,
    isShowWeather,
    selectedAreaList,
    flickerLayerRef,
    setShowMenuLayer,
    metresPerPixel,
  } = useMapContext();
  const dispatch = useDispatch();

  const filterShipResult = useSelector(state => state.shipRedux.filterShipResult);
  const windPointArray = useSelector(state => state.weatherRedux.windPointArray);
  const trackingShip = useSelector(state => state.shipRedux.trackingShip);
  const pointAreaList = useSelector(state => state.areaRedux.pointAreaList);
  const shipsAround = useSelector(state => state.shipRedux.shipsAround);
  const shipsAroundCircle = useSelector(state => state.shipRedux.shipsAroundCircle);

  const clusterMarker = useClusteredMarker();
  const canvasMarker = useCanvasMarker();
  const vectorMarker = useVectorMarker();
  const pointMarker = usePointMarker();
  const watchListMarket = useWatchListMarket();
  const weatherMarker = useWeatherMarker();
  const areaControl = useArea();
  const notification = useNotification();
  const { createFlickerMarker } = useFlickerMarker(flickerLayerRef);

  useEffect(() => {
    const updateVisibleElements = () => {
      if (mapState === "loaded" && (filterShipResult.length >= 0 || shipsAround?.length >= 0)) {
        const visibleBounds = map.current.getBounds(); // Assuming 'map' is your map instance
        const shipResult = (shipsAround || filterShipResult).filter(ship => {
          const shipPosition = L.latLng(ship.ship_lat, ship.ship_long); // Assuming each ship has 'lat' and 'lng' properties
          return visibleBounds.contains(shipPosition);
        });
        canvasMarker.clear();
        clusterMarker.clear();
        vectorMarker.clear();
        watchListMarket.clear();
        const root = document.documentElement;
        root.style.setProperty("--velocityLength", isShowShipSpeed ? 0.15 : 0);
        if (isShowShip) {
          if (isClusteredShip) {
            clusterMarker.showMarkerWithName(shipResult, isShowShipName, shipsAroundCircle?.radius);
            vectorMarker.clear();
          } else {
            canvasMarker.show(shipResult, isShowShipName && shipResult.length < 100, shipsAroundCircle?.radius);
            watchListMarket.show(shipResult?.filter(item => item.watchListColor));
            if (isShowShipSpeed) {
              vectorMarker.show(shipResult, metresPerPixel);
            }
          }
        }
      }
    };

    updateVisibleElements();
  }, [
    map,
    mapState,
    filterShipResult,
    isClusteredShip,
    isShowShip,
    isShowShipName,
    isShowShipSpeed,
    shipsAround,
    metresPerPixel,
  ]);

  useEffect(() => {
    if (mapState === "loaded" && pointAreaList.length >= 0) {
      pointMarker.clear();
      pointMarker.show(pointAreaList);
    }
  }, [mapState, pointAreaList]);

  useEffect(() => {
    if (!trackingShip?.resetLocation) return;
    const oldTrackkingShip = filterShipResult.find(ship => ship.ship_uid === trackingShip.ship_uid);
    if (
      oldTrackkingShip &&
      (trackingShip?.lat !== oldTrackkingShip?.ship_lat || trackingShip?.lon !== oldTrackkingShip?.ship_long)
    ) {
      notification.send("Tàu này đã được cập nhật vị trí mới", "info");
      map.current.setView([trackingShip.lat, trackingShip.lon]);
      createFlickerMarker({ ...oldTrackkingShip, ship_lat: trackingShip.lat, ship_long: trackingShip.lon });
      const newFilterShipResult = filterShipResult.map(ship => {
        if (ship.ship_uid === trackingShip.ship_uid) {
          return {
            ...ship,
            ship_lat: trackingShip.lat,
            ship_long: trackingShip.lon,
            course: trackingShip.course,
            speed: trackingShip.speed,
          };
        } else {
          return ship;
        }
      });
      dispatch(
        ShipActions.shipSet({
          filterShipResult: newFilterShipResult,
        }),
      );
    } else {
      notification.send("Tàu này giữ nguyên vị trí", "info");
    }
    setShowMenuLayer({ event: null, info: null });
  }, [trackingShip]);

  useEffect(() => {
    if (mapState === "loaded") {
      areaControl.clear();
      areaControl.show(
        shipsAroundCircle ? selectedAreaList.concat([{ ...shipsAroundCircle, type: "polygon" }]) : selectedAreaList,
      );
    }
  }, [selectedAreaList, mapState, shipsAroundCircle]);

  useEffect(() => {
    if (mapState === "loaded") {
      if (isShowWeather) {
        weatherMarker.show(windPointArray);
      } else {
        weatherMarker.clear();
      }
    }
  }, [isShowWeather, windPointArray, mapState]);

  return (
    <Fragment>
      <LayerMousePosition />
      <LayerTopRight />
      <LayerBottomRight />
      <LayerContextMenuDialog />
      <LayerShipInfoTooltip />
      <LayerShipInfoDialog />
      <LayerDrawShape />
      <LayerShipTrackingVideo />
      <LayerPointInfoTooltip />
    </Fragment>
  );
};

export default MapLayers;
