import { ApiConstant, AppConstant, LangConstant } from "const";
import { call, put } from "redux-saga/effects";
import { UserActionService } from "services";
import UserActions from "redux-store/userAction.redux";
import { toCamel, toSnake } from "utils";
import { saveAs } from "file-saver";
import { handleSendNotiInSaga } from "utils";

export function* getUserActionListByUser({ data }) {
  try {
    if (data.action === 0) {
      data.action = null;
    }

    let response = yield call(UserActionService.getUserActionListByUser, toSnake(data));

    if (response.status === ApiConstant.STT_OK) {
      let responseData = toCamel(response.data);
      yield put(
        UserActions.userActionSet({
          userActionList: responseData,
          isFetching: false,
        }),
      );
    } else {
      yield put(UserActions.userActionReset());
    }
  } catch (error) {
    yield put(UserActions.userActionReset());
  }
}

export function* exportUserActionListByUser({ data }) {
  try {
    if (data.action === 0) {
      data.action = null;
    }
    let response = yield call(UserActionService.getUserActionListByUser, toSnake(data));

    if (response.status === ApiConstant.STT_OK) {
      saveAs(
        new File([response.data], LangConstant.TXT_USER_ACTION_PERSONAL, {
          type: AppConstant.MIMETYPE_EXCEL,
        }),
      );
    } else {
      yield put(UserActions.userActionSet({ errors: response }));
      handleSendNotiInSaga(LangConstant.MS_EXPORT_FAILED, "error");
    }
  } catch (error) {
    yield put(UserActions.userActionSet({ errors: error }));
  }
}

export function* getUserActionListAllUser({ data }) {
  try {
    if (data.action === 0) {
      data.action = null;
    }

    let response = yield call(UserActionService.getUserActionListAllUser, toSnake(data));

    if (response.status === ApiConstant.STT_OK) {
      let responseData = toCamel(response.data);
      yield put(
        UserActions.userActionSet({
          userActionAllList: responseData,
          isFetching: false,
        }),
      );
    } else {
      yield put(UserActions.userActionReset());
    }
  } catch (error) {
    yield put(UserActions.userActionReset());
  }
}

export function* exportUserActionListAllUser({ data }) {
  try {
    if (data.action === 0) {
      data.action = null;
    }
    let response = yield call(UserActionService.getUserActionListAllUser, toSnake(data));

    if (response.status === ApiConstant.STT_OK) {
      saveAs(
        new File([response.data], LangConstant.TXT_USER_ACTION_HISTORY, {
          type: AppConstant.MIMETYPE_EXCEL,
        }),
      );
    } else {
      yield put(UserActions.userActionSet({ errors: response }));
      handleSendNotiInSaga(LangConstant.MS_EXPORT_FAILED, "error");
    }
  } catch (error) {
    yield put(UserActions.userActionSet({ errors: error }));
  }
}

export function* getSummaryAction({ data }) {
  try {
    let response = yield call(UserActionService.getSummaryAction, toSnake(data));

    if (response.status === ApiConstant.STT_OK) {
      let responseData = toCamel(response.data);
      yield put(
        UserActions.userActionSet({
          userActionSummaryList: responseData,
          isFetching: false,
        }),
      );
    } else {
      yield put(UserActions.userActionReset());
    }
  } catch (error) {
    yield put(UserActions.userActionReset());
  }
}

export function* exportSummaryAction({ data }) {
  try {
    let response = yield call(UserActionService.getSummaryAction, toSnake(data));

    if (response.status === ApiConstant.STT_OK) {
      saveAs(
        new File([response.data], LangConstant.TXT_USER_ACTION_SUMMARY, {
          type: AppConstant.MIMETYPE_EXCEL,
        }),
      );
    } else {
      yield put(UserActions.userActionSet({ errors: response }));
      handleSendNotiInSaga(LangConstant.MS_EXPORT_FAILED, "error");
    }
  } catch (error) {
    yield put(UserActions.userActionSet({ errors: error }));
  }
}

export function* getTotalAction({ data }) {
  try {
    let response = yield call(UserActionService.getTotalAction, toSnake(data));

    if (response.status === ApiConstant.STT_OK) {
      let responseData = toCamel(response.data);
      yield put(
        UserActions.userActionSet({
          userActionTotalList: responseData.data,
          isFetching: false,
        }),
      );
    } else {
      yield put(UserActions.userActionReset());
    }
  } catch (error) {
    yield put(UserActions.userActionReset());
  }
}
