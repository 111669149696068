import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Edit, Delete } from "@mui/icons-material";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  Typography,
  Pagination,
} from "@mui/material";
import StringFormat from "string-format";
import DeleteConfirmDialog from "./DeleteConfirmDialog";
import { PathConstant } from "const";

export const PERMISSION_PAGE_SIZE = 10;
export const PERRMISSION_ID_TEMPLATE = "NHOMQUYEN_{id}";

const PermissionTable = ({ rows, page, totalItems, totalPages, onChangePage, onDelete }) => {
  const [deleteDialogConfigs, setDeleteDialogConfigs] = useState(null);

  const handleChangePage = (_, value) => {
    if (onChangePage && page !== value) onChangePage(value);
  };

  const handleDeleteGroup = row => {
    setDeleteDialogConfigs(row);
  };

  const handleDenyDeleteGroup = () => {
    setDeleteDialogConfigs(null);
  };

  const handleAcceptDeleteGroup = () => {
    if (onDelete && deleteDialogConfigs) {
      onDelete(deleteDialogConfigs.uid);
      setDeleteDialogConfigs(null);
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">STT</TableCell>
              <TableCell align="center">Mã nhóm quyền</TableCell>
              <TableCell align="center">Tên nhóm quyền</TableCell>
              <TableCell align="center">Số lượng thành viên</TableCell>
              <TableCell align="center">Thao tác</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows && rows.length > 0 ? (
              rows.map((row, index) => (
                <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell align="center">{index + 1 + (page - 1) * PERMISSION_PAGE_SIZE}</TableCell>
                  <TableCell align="center">{StringFormat(PERRMISSION_ID_TEMPLATE, { id: row.id })}</TableCell>
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">{row.total_user}</TableCell>
                  <TableCell align="center">
                    <IconButton size="small" onClick={() => handleDeleteGroup(row)}>
                      <Delete fontSize="small" />
                    </IconButton>
                    <Link to={PathConstant.ADMIN_UPDATE_PERMISSION.replace(":permissionGroupUid", row.uid)}>
                      <IconButton size="small">
                        <Edit fontSize="small" />
                      </IconButton>
                    </Link>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell colSpan={5} align="center">
                  Không có dữ liệu
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={classes.footer}>
        <Typography variant="caption">Số lượng nhóm quyền: {totalItems || 0}</Typography>
        <Pagination page={page} color="primary" count={totalPages} onChange={handleChangePage} />
      </Box>
      <DeleteConfirmDialog
        open={Boolean(deleteDialogConfigs)}
        id={deleteDialogConfigs?.id || ""}
        onAccept={handleAcceptDeleteGroup}
        onDeny={handleDenyDeleteGroup}
      />
    </>
  );
};

export default PermissionTable;

const classes = {
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "12px",
  },
};
