import React, { Fragment, memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import L from "leaflet";
import lodash from "lodash";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
} from "@mui/material";
import { Close, Delete, Edit, Sailing } from "@mui/icons-material";
import { AppConstant } from "const";
import { useLoading, useMapContext, useNotification } from "hooks";
import AreaActions from "redux-store/area.redux";
import LayerSearchShipInAreaDialog from "./LayerSearchShipInAreaDialog";
import LayerCreateNewAreaDialog from "./LayerCreateNewAreaDialog";
import ConfirmDialog from "components/ConfirmDialog";
import Cookies from "js-cookie";

const LayerAreaManagementDialog = () => {
  const classes = useStyles();
  const notification = useNotification();
  const loading = useLoading();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(null);
  const [fileName, setFileName] = React.useState("");
  const [fileDialog, setFileDialog] = React.useState(undefined);
  const [confirmOpenDeleteArea, setConfirmOpenDeleteArea] = useState(false);
  const [confirmOpenEditArea, setConfirmOpenEditArea] = useState(false);

  const customAreaList = useSelector(state => state.areaRedux.customAreaList);
  const uploadKmlStatus = useSelector(state => state.areaRedux.uploadKmlStatus);
  const deleteAreaStatus = useSelector(state => state.areaRedux.deleteAreaStatus);
  const editedArea = useSelector(state => state.areaRedux.editedArea);
  const kmlFiles = useSelector(state => state.areaRedux.kmlFiles);

  const {
    map,
    mapState,
    isOpenAreaManagementDialog,
    setIsOpenAreaManagementDialog,
    areaManagementConfigs,
    setAreaManagementConfigs,
    selectedAreaList,
    setSelectedAreaList,
  } = useMapContext();

  const rerenderInput = useRef();
  const [selectedArea, setSelectedArea] = useState({ area: null, type: "idle" });
  const stopPropagation = e => {
    e.stopPropagation();
  };

  const handleCloseDialog = () => {
    setIsOpenAreaManagementDialog(false);
    setAreaManagementConfigs(state => ({ ...state, status: "idle" }));
  };

  const handleEnableDrawRectangle = () => {
    document.querySelector("a.leaflet-draw-draw-rectangle").click();
    setAreaManagementConfigs(state => ({ ...state, status: "drawing_rectangle" }));
  };

  const handleEnableDrawPolygon = () => {
    document.querySelector("a.leaflet-draw-draw-polygon").click();
    setAreaManagementConfigs(state => ({ ...state, status: "drawing_polygon" }));
  };

  const handleEnableDrawCircle = () => {
    document.querySelector("a.leaflet-draw-draw-circle").click();
    setAreaManagementConfigs(state => ({ ...state, status: "drawing_circle" }));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = event => {
    setFileName(event.target.value);
  };

  const handleUploadFile = e => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file) {
        const ext = file.name.split(".").pop();
        if (AppConstant.ACCEPTED_FILE_EXTENSIONS.includes(ext)) {
          setFileDialog(file);
          handleClickOpen();
        } else {
          notification.send(
            `Vui lòng chọn file nằm trong những định dạng sau: ${AppConstant.ACCEPTED_FILE_EXTENSIONS.join(", ")}`,
            "error",
          );
        }
      } else {
        setAreaManagementConfigs(state => ({ ...state, status: "idle" }));
      }
    } else {
      setAreaManagementConfigs(state => ({ ...state, status: "idle" }));
    }
    rerenderInput.current.value = null;
  };

  const handleToggleShowDraw = area => {
    const uid = area.uid;
    const existedArea = selectedAreaList?.find(currentArea => currentArea.uid === uid);
    if (Boolean(existedArea)) {
      setSelectedAreaList(state => [...state].filter(currentArea => currentArea.uid !== uid));
    } else {
      if (area.attachment_uid && !kmlFiles[area.attachment_uid]) {
        dispatch(AreaActions.requestGetKmlFile({ uid: area.attachment_uid }));
      }
      // handleJumpToAreaLocation(area);
      setSelectedAreaList(state => [...state, { ...area, type: area.attachment_uid ? "file" : "polygon" }]);
    }
  };

  const handleJumpToAreaLocation = area => {
    if (mapState === "loaded" && area.coordinate_list && area.coordinate_list.length > 0) {
      const polygon = L.polygon(area.coordinate_list.map(coord => [coord.lat, coord.long]));
      map.current.fitBounds(polygon.getBounds(), { maxZoom: 6, animate: false });
    }
  };

  const handleShowShipListInArea = area => {
    setAreaManagementConfigs(state => ({ ...state, status: "show_ships_in_area" }));
    setSelectedArea(state => ({ ...state, area: area, type: "show" }));
  };

  const handleEditAreaLatlngs = area => {
    setAreaManagementConfigs(state => ({ ...state, status: "edit_area" }));
    setSelectedArea(state => ({
      ...state,
      area: lodash.cloneDeep({ ...area, radius: area.radius * AppConstant.METER_TO_NAUTICAL_MILE }),
      type: "edit",
    }));
  };

  const handleConfirmDelete = area => {
    loading.open();
    const uid = area.uid;
    const existedArea = selectedAreaList?.find(currentArea => currentArea.uid === uid);
    if (Boolean(existedArea)) setSelectedAreaList(state => [...state].filter(currentArea => currentArea.uid !== uid));
    setAreaManagementConfigs(state => ({ ...state, status: "delete_area" }));
    dispatch(AreaActions.requestDeleteCustomArea({ uid: area.uid }));
    setConfirmOpenDeleteArea(null);
  };

  const handleCloseSearchShipInArea = () => {
    setSelectedArea(state => ({ ...state, area: null, type: "idle" }));
    setAreaManagementConfigs(state => ({ ...state, status: "idle" }));
  };

  const handleConfirmEdit = () => {
    setAreaManagementConfigs(state => ({ ...state, status: "idle" }));
    setSelectedArea(state => ({ ...state, area: null, type: "idle" }));
    setConfirmOpenEditArea(false);
  };

  const handleEditArea = () => {
    loading.open();
    dispatch(
      AreaActions.requestUpdateCustomArea({
        uid: selectedArea.area.uid,
        name: selectedArea.area.name,
        points: selectedArea.area.coordinate_list,
        center_lat: selectedArea.area.center_lat,
        center_lon: selectedArea.area.center_lon,
        radius: selectedArea.area.radius,
      }),
    );
  };

  const handleChangeAreaName = e => {
    setSelectedArea(state => ({ ...state, area: { ...state.area, name: e.target.value } }));
  };

  const handleChangeAreaInput = (e, inputName) => {
    setSelectedArea(state => ({ ...state, area: { ...state.area, [inputName]: e.target.value } }));
  };

  const handleChangeLatInput = (e, index) => {
    // Vĩ độ
    const value = e.target.value;
    if (value !== "") {
      const newLatlngs = lodash.cloneDeep(selectedArea.area.coordinate_list);
      newLatlngs[index]["lat"] = parseFloat(value);
      setSelectedArea(state => ({ ...state, area: { ...state.area, coordinate_list: newLatlngs } }));
    }
  };

  const handleChangeLngInput = (e, index) => {
    // Kinh độ
    const value = e.target.value;
    if (value !== "") {
      const newLatlngs = lodash.cloneDeep(selectedArea.area.coordinate_list);
      newLatlngs[index]["long"] = parseFloat(value);
      setSelectedArea(state => ({ ...state, area: { ...state.area, coordinate_list: newLatlngs } }));
    }
  };

  useEffect(() => {
    if (uploadKmlStatus === "success") {
      notification.send("Tạo khu vực mới thành công");
    } else if (uploadKmlStatus === "error") {
      notification.send("Đã xảy ra lỗi trong quá trình tạo khu vực. Vui lòng thử lại sau", "error");
    }

    setAreaManagementConfigs(state => ({ ...state, status: "idle" }));
    dispatch(AreaActions.areaSet({ uploadKmlStatus: "idle" }));
    rerenderInput.current.value = null;
    loading.close();
  }, [uploadKmlStatus]);

  useEffect(() => {
    if (deleteAreaStatus === "success") {
      notification.send("Xóa khu vực mới thành công", "success");
    } else if (deleteAreaStatus === "error") {
      notification.send("Đã xảy ra lỗi trong quá trình tạo khu vực. Vui lòng thử lại sau", "error");
    }

    setAreaManagementConfigs(state => ({ ...state, status: "idle" }));
    dispatch(AreaActions.areaSet({ deleteAreaStatus: "idle" }));
    loading.close();
  }, [deleteAreaStatus]);

  useEffect(() => {
    let list = selectedAreaList?.map(state => ({ ...state, is_new: 1 }));
    setSelectedAreaList(list);
  }, [kmlFiles]);

  useEffect(() => {
    loading.close();
    if (editedArea) {
      notification.send("Chỉnh khu vực thành công");
      dispatch(AreaActions.requestGetDefaultArea());
      dispatch(AreaActions.requestGetCustomArea());
      dispatch(AreaActions.areaSet({ editedArea: null }));
      setAreaManagementConfigs(state => ({ ...state, status: "idle" }));
      setSelectedArea(state => ({ ...state, area: null, type: "idle" }));
      setSelectedAreaList(state =>
        state.map(area => (area.uid === editedArea.uid ? { ...editedArea, type: "polygon" } : area)),
      );
    }
  }, [editedArea]);

  useEffect(() => {
    if (!customAreaList?.length) return;
    const selectedAreaListDefault = customAreaList.filter(area =>
      localStorage.getItem(AppConstant.LOCAL_STORAGE_KEY.selectedAreaList)?.includes(area.uid),
    );
    if (selectedAreaListDefault?.length === 0) {
      setSelectedAreaList([]);
    } else {
      selectedAreaListDefault?.forEach(area => {
        if (area.attachment_uid && !kmlFiles[area.attachment_uid]) {
          dispatch(AreaActions.requestGetKmlFile({ uid: area.attachment_uid }));
        }
      });
      setSelectedAreaList(
        selectedAreaListDefault?.map(area => {
          return { ...area, type: area.attachment_uid ? "file" : "polygon" };
        }),
      );
    }
  }, [customAreaList]);

  return (
    <>
      <Box
        className={isOpenAreaManagementDialog ? classes.openDragDropElem : classes.closeDragDropElem}
        onDoubleClick={stopPropagation}
        component="section"
      >
        <Box marginBottom={1} className={classes.wrapper}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            marginBottom={1}
            className={classes.drawAreaHeader}
          >
            <Typography>Tạo khu vực mới</Typography>
            <IconButton size="small" onClick={handleCloseDialog} disabled={areaManagementConfigs.status !== "idle"}>
              <Close
                fontSize="small"
                htmlColor={areaManagementConfigs.status === "idle" ? "#fff" : "rgba(0, 0, 0, 0.5)"}
              />
            </IconButton>
          </Box>
          <Box
            paddingX={1}
            paddingTop={1}
            onMouseDown={stopPropagation}
            onMouseMove={stopPropagation}
            onDoubleClick={stopPropagation}
          >
            <Button
              variant="contained"
              className={classes.drawButton}
              onClick={handleEnableDrawRectangle}
              disableElevation
              fullWidth
              disabled={areaManagementConfigs.status !== "idle" && areaManagementConfigs.status !== "drawing_rectangle"}
            >
              Theo hình chữ nhật
            </Button>
            <Button
              variant="contained"
              className={classes.drawButton}
              onClick={handleEnableDrawCircle}
              disableElevation
              fullWidth
              disabled={areaManagementConfigs.status !== "idle" && areaManagementConfigs.status !== "drawing_circle"}
            >
              Theo hình tròn
            </Button>
            <Button
              variant="contained"
              className={classes.drawButton}
              onClick={handleEnableDrawPolygon}
              disableElevation
              fullWidth
              disabled={areaManagementConfigs.status !== "idle" && areaManagementConfigs.status !== "drawing_polygon"}
            >
              Theo điểm
            </Button>
            <Button
              variant="contained"
              className={classes.drawButton}
              disableElevation
              fullWidth
              disabled={areaManagementConfigs.status !== "idle"}
            >
              <label htmlFor="KML_INPUT_ID" className={classes.uploadKmlLabel}>
                Tải file
              </label>
            </Button>
            <input
              ref={rerenderInput}
              type="file"
              id="KML_INPUT_ID"
              style={{ display: "none" }}
              onChange={handleUploadFile}
            />
          </Box>
        </Box>
        <Box
          paddingX={1}
          paddingBottom={2}
          onMouseDown={stopPropagation}
          onMouseMove={stopPropagation}
          onDoubleClick={stopPropagation}
        >
          <Box marginBottom={1} display="flex" alignItems="center" justifyContent="space-between">
            <Typography>Khu vực</Typography>
            <Typography variant="caption">Số lượng: {customAreaList.length}</Typography>
          </Box>
          <Box className={classes.customAreaCont} onDoubleClick={stopPropagation}>
            {customAreaList.map(area => (
              <Box
                key={area.uid}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginBottom={1}
                onDoubleClick={stopPropagation}
              >
                <Button
                  size="small"
                  disableElevation
                  disableTouchRipple
                  disableFocusRipple
                  disableRipple
                  fullWidth
                  title={area.attachment_uid ? "Khu vực nhập từ file: " + area.name : "Khu vực tự vẽ: " + area.name}
                  onDoubleClick={stopPropagation}
                  onClick={() => handleToggleShowDraw(area)}
                  variant={selectedAreaList?.map(a => a.uid).includes(area.uid) ? "contained" : "outlined"}
                  disabled={areaManagementConfigs.status !== "idle"}
                >
                  {area.name.slice(0, 16)}
                  {area.name.length > 16 ? "..." : ""}
                </Button>
                <Box display="flex" alignItems="center" justifyContent="space-between" marginLeft={1}>
                  <IconButton
                    size="small"
                    onClick={() => handleShowShipListInArea(area)}
                    title="Tìm kiếm tàu trong khu vực"
                    disabled={areaManagementConfigs.status !== "idle" || area.attachment_uid !== null}
                  >
                    <Sailing fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    title={
                      area.attachment_uid
                        ? "Không thể sửa tọa độ của khu vực được tải lên từ file"
                        : "Sửa toạ độ của khu vực"
                    }
                    onClick={() => handleEditAreaLatlngs(area)}
                    disabled={
                      areaManagementConfigs.status !== "idle" ||
                      area.attachment_uid !== null ||
                      (area?.user !== null && Cookies.get(AppConstant.KEY_USER_ID) !== area?.user?.uid)
                    }
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    title="Xoá khu vực"
                    onClick={() => setConfirmOpenDeleteArea(area)}
                    disabled={
                      areaManagementConfigs.status !== "idle" ||
                      Cookies.get(AppConstant.KEY_USER_ID) !== area?.user?.uid
                    }
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      {selectedArea.type === "show" && selectedArea.area ? (
        <LayerSearchShipInAreaDialog onClose={handleCloseSearchShipInArea} data={selectedArea.area} />
      ) : (
        <Fragment />
      )}
      <LayerCreateNewAreaDialog
        mode="edit"
        open={Boolean(selectedArea.type === "edit" && selectedArea.area)}
        name={selectedArea?.area?.name || ""}
        latlngs={selectedArea?.area?.coordinate_list?.map(coord => ({ ...coord, lng: coord.long })) || []}
        onClose={() => setConfirmOpenEditArea(true)}
        onSubmit={handleEditArea}
        onChangeAreaName={handleChangeAreaName}
        onChangeLatInput={handleChangeLatInput}
        onChangeLngInput={handleChangeLngInput}
        onChangeAreaInput={handleChangeAreaInput}
        type={selectedArea?.area?.radius && "circle"}
        center_lon={selectedArea?.area?.center_lon}
        center_lat={selectedArea?.area?.center_lat}
        radius={selectedArea?.area?.radius}
      />
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Tên tệp tin: {fileDialog?.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>Vui lòng nhập tên khu vực:</DialogContentText>
          <TextField autoFocus margin="dense" id="name" type="text" fullWidth onChange={handleChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (fileName) {
                loading.open();
                setAreaManagementConfigs(state => ({ ...state, status: "uploading_file" }));
                dispatch(AreaActions.uploadKmlFile({ file: fileDialog, areaName: fileName }));
              } else {
                notification.send("Vui lòng nhập tên cho khu vực", "error");
              }
              handleClose();
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={Boolean(confirmOpenDeleteArea)}
        onClose={() => setConfirmOpenDeleteArea(null)}
        onConfirm={() => handleConfirmDelete(confirmOpenDeleteArea)}
        title=""
        message={`Bạn có muốn xoá khu vực '${confirmOpenDeleteArea?.name || ""}' không?`}
      />
      <ConfirmDialog
        open={Boolean(confirmOpenEditArea)}
        onClose={() => setConfirmOpenEditArea(null)}
        onConfirm={() => handleConfirmEdit()}
        title=""
        message={`Dữ liệu của bạn sẽ bị mất. Bạn có chắc chắn muốn thực hiện hành động này?`}
      />
    </>
  );
};

export default memo(LayerAreaManagementDialog);

const useStyles = makeStyles(theme => ({
  openDragDropElem: {
    position: "fixed",
    top: "70px",
    right: "60px",
    width: 300,
    display: "block",
    cursor: "default",
    zIndex: 1000,
    background: "#fff",
    boxSizing: "border-box",
    borderRadius: 4,
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  },

  closeDragDropElem: {
    display: "none",
  },

  openLocationDialogElem: {
    position: "absolute",
    top: "1vh",
    left: "20%",
    width: 500,
    display: "block",
    cursor: "default",
    zIndex: 10001,
    background: "#fff",
    boxSizing: "border-box",
    boxShadow: "1px 1px 8px 2px #000",
    transform: "translateX(-50%)",
    borderRadius: 4,
  },

  closeLocationDialogElem: {
    display: "none",
  },

  drawButton: {
    marginBottom: 8,
  },

  header: {
    width: "100%",
    height: 32,
    backgroundColor: "#1d83c3",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "4px 4px 0 0",
  },

  dragHeader: {
    height: 32,
    flexShrink: 0,
  },

  inputRoot: {
    padding: "0 4px",
    "& input": {
      color: "#fefefe",
      fontSize: 14,
    },
  },

  locationTable: {
    borderCollapse: "collapse",
    marginBottom: 8,
    width: "100%",
    "& tr:not(.no-border)": {
      border: "1px solid #000000",
      textAlign: "center",
    },
    "& td:not(.no-border)": {
      border: "1px solid #000000",
      textAlign: "center",
    },
    "& tr.no-border": {
      fontSize: 14,
      "& p": {
        padding: 4,
      },
    },
    "& th": {
      border: "1px solid #000000",
      textAlign: "center",
    },
  },

  body: {
    padding: "8px",
    maxHeight: 500,
    overflowY: "scroll",
  },

  drawAreaHeader: {
    backgroundColor: "#1d83c3",
    color: "#ffffff",
    padding: "4px 8px",
    borderRadius: "4px 4px 0 0",
    marginBottom: 0,
  },

  wrapper: {
    borderBottom: "1px solid #999999",
  },

  latLngPointInput: {
    width: 140,
    border: "none",
    outline: "none",
  },

  customAreaCont: {
    height: "100%",
    maxHeight: 300,
    overflowY: "scroll",
  },

  uploadKmlLabel: {
    display: "block",
    width: "100%",
    height: "100%",
    cursor: "pointer",
    marginBottom: 0,
  },
}));
