import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ShipActions from "redux-store/ship.redux";
import { makeStyles } from "@mui/styles";
import { FormGroup, FormControlLabel, Radio, RadioGroup, TextField, Box } from "@mui/material";
import { AppConstant, FormatConstant } from "const";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export default function FilterByTime() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const shipConditions = useSelector(state => state.shipRedux.shipConditions);
  let currentDate = new Date();

  const [selected, setSelected] = useState(24);
  const [startTime, setStartTime] = useState(currentDate.getTime() - AppConstant.ONE_DAY);
  const [endTime, setEndTime] = useState(currentDate.getTime());

  const onChangeValue = event => {
    let value = event.target.value;
    setSelected(parseInt(value));
  };

  useEffect(() => {
    dispatch(
      ShipActions.shipSet({
        isChangingFilter: true,
        shipConditions: Object.assign(shipConditions, {
          time_ticks: selected,
          startTime: selected === 0 ? startTime : null,
          endTime: selected === 0 ? endTime : null,
        }),
      }),
    );
  }, [selected, startTime, endTime]);

  return (
    <FormGroup
      classes={{
        root: classes.filterByShipTypeBox,
      }}
    >
      <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
        {AppConstant.FILTER_SHIP_BY_TIME.map(item => (
          <FormControlLabel
            key={item.id}
            control={<Radio value={item.id} onChange={onChangeValue} checked={selected === item.id} />}
            label={item.name}
            classes={{
              label: classes.dataResourceTitle,
            }}
          />
        ))}
      </RadioGroup>
      {selected === 0 && (
        <>
          <Box style={{ paddingBottom: 10 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label="Từ ngày"
                inputFormat={FormatConstant.FM_DD_MM_YYY_HH_MM}
                onChange={newValue => {
                  if (newValue) {
                    setStartTime(newValue.getTime());
                  }
                }}
                value={startTime}
                maxDate={new Date(endTime)}
                renderInput={params => <TextField size="small" {...params} style={{ width: "100%" }} />}
              />
            </LocalizationProvider>
          </Box>
          <Box style={{ paddingBottom: 10 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label="Tới ngày"
                inputFormat={FormatConstant.FM_DD_MM_YYY_HH_MM}
                value={endTime}
                minDate={new Date(startTime)}
                onChange={newValue => {
                  if (newValue) {
                    setEndTime(newValue.getTime());
                  }
                }}
                maxDate={new Date()}
                renderInput={params => <TextField size="small" {...params} style={{ width: "100%" }} />}
              />
            </LocalizationProvider>
          </Box>
        </>
      )}
    </FormGroup>
  );
}

const useStyles = makeStyles(theme => ({
  filterByShipTypeBox: {
    display: "grid",
    background: "#fff",
    gridTemplateColumns: "auto",
    padding: "0 8px 0 24px",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    borderRadius: "4px",
  },

  dataResourceTitle: {
    fontSize: "14px",
    color: "#000",
    lineHeight: "100%",
    display: "flex",
    alignItems: "center",
    userSelect: "none",
  },
}));
