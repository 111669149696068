import { ApiConstant, SystemConstant } from "const";
import { createApiWithToken } from "./config/api.config";
import StringFormat from "string-format";

export const getUserActionListByUser = data => {
  const { uid, ...payload } = data;
  return createApiWithToken().get(StringFormat(ApiConstant.GET_USER_ACTION_LIST_BY_USER, uid), payload, {
    responseType: data.export_f === SystemConstant.IS_EXPORT.TRUE ? "blob" : "json",
  });
};

export const getUserActionListAllUser = data => {
  return createApiWithToken().get(ApiConstant.GET_USER_ACTION_LIST_ALL_USER, data, {
    responseType: data.export_f === SystemConstant.IS_EXPORT.TRUE ? "blob" : "json",
  });
};

export const getSummaryAction = data => {
  return createApiWithToken().get(ApiConstant.GET_USER_ACTION_SUMMARY, data, {
    responseType: data.export_f === SystemConstant.IS_EXPORT.TRUE ? "blob" : "json",
  });
};

export const getTotalAction = data => {
  return createApiWithToken().get(ApiConstant.GET_USER_ACTION_TOTAL, data);
};
