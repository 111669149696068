import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography, TextField, Grid } from "@mui/material";
import { isEqual } from "lodash";
import featureScopes from "static/feature-scopes.json";
import FeatureDropdown from "./FeatureDropdown";
import AddUserTable from "./AddUserTable";
import AddUserDialog from "./AddUserDialog";
import { PathConstant } from "const";
import CancelConfirmDialog from "./CancelConfirmDialog";

const PermissionForm = ({ name, description, scopes, users, onSubmit, mode }) => {
  const navigate = useNavigate();

  const [permissionInfo, setPermissionInfo] = useState({ name: name || "", description: description || "" });
  const [selectedScopes, setSelectedScopes] = useState(scopes || []);
  const [selectedUsers, setSelectedUsers] = useState(users || []);
  const [isOpenAddUserDialog, setIsOpenAddUserDialog] = useState(false);
  const [isOpenCancelConfirmDialog, setIsOpenCancelConfirmDialog] = useState(false);
  const [errors, setErrors] = useState({ name: "", users: "" });

  const defaultState = useMemo(
    () => ({
      permissionInfo: { name: name || "", description: description || "" },
      selectedScopes: scopes || [],
      selectedUsers: users || [],
    }),
    [name, description, scopes, users],
  );

  const handleSubmit = e => {
    e.preventDefault();

    let isValidated = true;
    setErrors(state => ({ ...state, name: "", users: "" }));

    if (!permissionInfo.name) {
      isValidated = false;
      setErrors(state => ({ ...state, name: "Trường dữ liệu không được để trống" }));
    }

    if (selectedUsers.length === 0) {
      isValidated = false;
      setErrors(state => ({ ...state, users: "Trường dữ liệu không được để trống" }));
    }

    if (isValidated && onSubmit) {
      onSubmit({
        name: permissionInfo.name,
        description: permissionInfo.description,
        scopes: selectedScopes,
        userUids: selectedUsers.map(user => user.uid),
      });
    }
  };

  const handleChangeInput = e => {
    setPermissionInfo(state => ({ ...state, [e.target.name]: e.target.value }));
  };

  const handleChangeScopes = (feature, features) => {
    const isListFeature = feature.id.endsWith("_LIST");
    const isUpdateFeature = feature.id.endsWith("_UPDATE");
    const isDetailFeature = feature.id.endsWith("_DETAIL");
    const isFeatureSelected = selectedScopes.includes(feature.id);
    const baseFeatureId = feature.id.substring(0, feature.id.lastIndexOf("_"));
    const listFeatureId = `${baseFeatureId}_LIST`;
    const detailFeatureId = `${baseFeatureId}_DETAIL`;
    const updateFeatureId = `${baseFeatureId}_UPDATE`;

    // Nếu đã có trong list, change tương đương với unchecked
    if (isFeatureSelected) {
      const newScopes = isListFeature
        ? // Nếu là list feature, remove tất cả các scope liên quan đến feature đó
          selectedScopes.filter(scope => !scope.startsWith(baseFeatureId))
        : isDetailFeature
          ? // Nếu là detail feature, remove cả update và detail
            selectedScopes.filter(scope => !scope.startsWith(detailFeatureId) && !scope.startsWith(updateFeatureId))
          : // Nếu khác 2 trường hợp trên, chỉ loại bỏ chính scope đó
            selectedScopes.filter(scope => scope !== feature.id);

      setSelectedScopes(newScopes);
    } else {
      const shouldAddListFeature = !isListFeature && features.some(item => item.id === listFeatureId);
      const shouldAddUpdateFeature = isUpdateFeature && features.some(item => item.id === detailFeatureId);

      setSelectedScopes(currentScopes => [
        ...currentScopes,
        feature.id,
        ...(shouldAddListFeature ? [listFeatureId] : []),
        ...(shouldAddUpdateFeature ? [detailFeatureId] : []),
      ]);
    }
  };
  const handleDeleteUser = deletedUser => {
    setSelectedUsers(state => state.filter(user => user.uid !== deletedUser.uid));
  };

  const handleOpenAddUserDialog = () => {
    setIsOpenAddUserDialog(true);
  };

  const handleAddUsers = newSelectedUsers => {
    setSelectedUsers(newSelectedUsers);
    setIsOpenAddUserDialog(false);
  };

  const handleCloseAddUserDialog = () => {
    setIsOpenAddUserDialog(false);
  };

  const handleClickCancel = () => {
    const currentState = { permissionInfo, selectedScopes, selectedUsers };
    if (isEqual(defaultState, currentState)) {
      navigate(PathConstant.ADMIN_PERMISSION_LIST);
    } else {
      setIsOpenCancelConfirmDialog(true);
    }
  };

  const handleAcceptCancel = () => {
    setIsOpenCancelConfirmDialog(false);
  };

  const handleDenyCancel = () => {
    navigate(PathConstant.ADMIN_PERMISSION_LIST);
    setIsOpenCancelConfirmDialog(false);
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Box marginBottom="32px">
        <Typography variant="subtitle1" marginBottom="16px">
          1. Thông tin nhóm quyền
        </Typography>
        <Box sx={classes.nameInputContainer}>
          <Box sx={classes.nameInput}>
            <TextField
              fullWidth
              defaultValue={name || ""}
              onChange={handleChangeInput}
              name="name"
              label="Tên nhóm quyền *"
              variant="outlined"
              size="small"
              inputProps={{ maxLength: 125 }}
              error={Boolean(errors.name)}
            />
            <Typography variant="caption" color="error">
              {errors.name}
            </Typography>
          </Box>
          <Box sx={classes.nameInput}>
            <TextField
              fullWidth
              defaultValue={description || ""}
              onChange={handleChangeInput}
              name="description"
              label="Mô tả nhóm quyền"
              variant="outlined"
              size="small"
              inputProps={{ maxLength: 225 }}
            />
          </Box>
        </Box>
      </Box>
      <Box marginBottom="32px">
        <Typography variant="subtitle1" marginBottom="16px">
          2. Chức năng
        </Typography>
        <Grid container spacing={2}>
          {featureScopes.map((feature, index) => (
            <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
              <FeatureDropdown
                name={feature.name}
                features={feature.features}
                scopes={selectedScopes}
                onChange={handleChangeScopes}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box marginBottom="32px">
        <Typography variant="subtitle1" marginBottom="16px">
          3. Người dùng thuộc nhóm quyền
        </Typography>
        <AddUserTable rows={selectedUsers} onDelete={handleDeleteUser} onClickAddUser={handleOpenAddUserDialog} />
        <Typography variant="caption" color="error">
          {errors.users}
        </Typography>
        <AddUserDialog
          onAdd={handleAddUsers}
          onClose={handleCloseAddUserDialog}
          open={isOpenAddUserDialog}
          currentSelectedUsers={selectedUsers}
        />
      </Box>
      <Box sx={classes.buttonContainer}>
        <Button variant="outlined" color="error" onClick={handleClickCancel}>
          Huỷ
        </Button>
        <Button variant="contained" color="primary" type="submit">
          {mode === "edit" ? "Cập nhật" : "Tạo mới"}
        </Button>
      </Box>
      <CancelConfirmDialog open={isOpenCancelConfirmDialog} onAccept={handleAcceptCancel} onDeny={handleDenyCancel} />
    </Box>
  );
};

export default PermissionForm;

const classes = {
  nameInputContainer: {
    display: "flex",
    alignItems: "flex-start",
    gap: "8px",
  },

  nameInput: {
    width: "100%",
    maxWidth: "500px",
  },

  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "8px",
  },
};
