import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Pagination,
  Button,
  Typography,
} from "@mui/material";
import { AppConstant, LangConstant } from "const";
import { debounce } from "lodash";
import FolderAction from "redux-store/folder.redux";
import { handleOpenLoading } from "utils";
import { makeStyles } from "@mui/styles";
import { convertMillisecondsToDateTime } from "utils/date.utils";
import { FolderService } from "services";

const TableNotifyUserManagement = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);

  const listFolderNotification = useSelector(state => state.folderRedux.listFolderNotification);
  const totalPage = useSelector(state => state.folderRedux.totalPageFolderNotification);
  const totalSize = useSelector(state => state.folderRedux.totalElementFolderNotification);

  const onChangePage = (e, value) => {
    setPage(value);
  };

  const onHandleSearch = () => {
    handleOpenLoading();
    setPage(AppConstant.FIRST_PAGE);
    dispatch(
      FolderAction.getFolderNotifications({
        page: AppConstant.FIRST_PAGE,
        size: AppConstant.PAGE_SIZE,
        filter: searchInput,
      }),
    );
  };

  useEffect(() => {
    handleOpenLoading();
    dispatch(
      FolderAction.getFolderNotifications({
        page: page,
        size: AppConstant.PAGE_SIZE,
        filter: searchInput,
      }),
    );
  }, [page]);

  const onReadAll = event => {
    FolderService.readAllFolderNotification().then(res => {
      dispatch(
        FolderAction.folderSet({
          listFolderNotification: [...listFolderNotification].map(item => {
            return { ...item, is_read: true };
          }),
          is_new: 0,
        }),
      );
    });
  };

  return (
    <>
      <Box sx={{ fontWeight: "bold", fontSize: "20px", color: "cornflowerblue" }}>
        {LangConstant.TXT_NOTIFICATON}: {totalSize}
      </Box>
      <Box sx={{ marginTop: 2, display: "flex", justifyContent: "start" }}>
        <TextField
          sx={{ width: 500 }}
          onChange={debounce(e => {
            setSearchInput(e.target.value);
          }, 500)}
          fullWidth
          size="small"
          id="search_box_event_warning"
          label={LangConstant.L_SEARCH}
          variant="outlined"
        />
        <Button sx={{ ml: 3 }} variant="contained" onClick={onHandleSearch}>
          {LangConstant.TXT_SEARCH}
        </Button>
        <Typography
          sx={{ ml: 3, marginLeft: "auto", marginRight: "20px" }}
          className={classes.buttonMenu}
          onClick={() => {
            onReadAll();
          }}
        >
          Đánh dấu đã đọc
        </Typography>
      </Box>

      <TableContainer style={{ height: 700 }}>
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="40px">{LangConstant.TXT_STT}</TableCell>
              <TableCell align="center">{LangConstant.TXT_TIME}</TableCell>
              <TableCell align="center">{LangConstant.TXT_CONTENT}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listFolderNotification &&
              listFolderNotification.map((data, index) => {
                return (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    key={data.uid}
                    id={data.uid}
                    className={{ [classes.notRead]: !data.is_read }}
                  >
                    <TableCell>{(page - 1) * AppConstant.PAGE_SIZE + index + 1}</TableCell>
                    <TableCell align="center">{convertMillisecondsToDateTime(data.created)}</TableCell>
                    <TableCell align="center">{data?.content}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ padding: "10px" }}>
        <Pagination onChange={onChangePage} sx={{ paddingTop: 2 }} count={totalPage} variant="outlined" page={page} />
      </Box>
    </>
  );
};
const useStyles = makeStyles(theme => ({
  notRead: {
    "& td": { fontWeight: "bold" },
    boxShadow: "0px 0px 3px rgba(0,0,0,.5)",
  },
  buttonMenu: {
    display: "flex",
    color: "#1976d2",
    width: "fit-content",
    cursor: "pointer",
  },
}));
export default TableNotifyUserManagement;
