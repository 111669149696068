import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogActions, DialogTitle, Button, Typography, CircularProgress, DialogContent } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AuthRedux from "redux-store/auth.redux";
import { useLoading } from "hooks";
import { ApiConstant, AppConstant, EventConstant, LangConstant } from "const";
import { Box } from "@mui/system";

const LinkTriosDialog = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const loading = useLoading();

  const connectTriosStatus = useSelector(state => state.authRedux.connectTriosStatus);

  const onSubmit = e => {
    loading.open();
    e.preventDefault();
    window.electronEvents.dispatchEvent(
      EventConstant.TRIOS_EVENTS.requestGetThirdToken,
      ApiConstant.CREATE_TOKEN_LINK_TRIOS,
    );

    dispatch(
      AuthRedux.authSet({
        connectTriosStatus: {
          status: {
            getThirdToken: AppConstant.API_STATUS.calling,
          },
          message: AppConstant.CONNECT_TRIOS_MESSAGE_TYPE.nothing,
        },
      }),
    );
  };

  const onClose = () => {
    dispatch(
      AuthRedux.authSet({
        loginType: AppConstant.LOGIN_TYPE.normal,
        connectTriosStatus: {
          status: {
            getThirdToken: AppConstant.API_STATUS.nothing,
          },
          message: AppConstant.CONNECT_TRIOS_MESSAGE_TYPE.nothing,
        },
      }),
    );

    loading.close();
  };

  return connectTriosStatus.status.getThirdToken === AppConstant.API_STATUS.calling ? (
    <>
      <Dialog open onClose={onClose}>
        <DialogContent className={classes.loading}>
          <Typography variant="h5" className={classes.titleDialogLinkTrios}>
            {LangConstant.TXT_WAIT_LINK_TRIOS}
          </Typography>
          <Box>
            <CircularProgress color="inherit" />
          </Box>
          <Typography className={classes.contentDialogLinkTrios}>{LangConstant.TXT_AUTHENTICATING}</Typography>
          <Typography className={classes.contentDialogLinkTrios}>{LangConstant.TXT_INFO_LINK_TRIOS}</Typography>
        </DialogContent>
      </Dialog>
    </>
  ) : (
    <Dialog open onClose={onClose}>
      <DialogTitle>
        <Typography variant="h5" className={classes.titleDialogLinkTrios}>
          {LangConstant.TXT_REQUEST_LINK_TRIOS}
        </Typography>
      </DialogTitle>
      <DialogActions className={classes.dialogActions}>
        <Button variant="outlined" onClick={onClose} sx={{ mr: 2 }}>
          {LangConstant.TXT_BUTTON_CANCEL}
        </Button>
        <Button variant="contained" onClick={onSubmit}>
          {LangConstant.TXT_CONTINUE}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LinkTriosDialog;

const useStyles = makeStyles(theme => ({
  dialogContent: {
    textAlign: "center",
    margin: "0px 50px",
  },

  loading: {
    textAlign: "center",
    margin: "auto 50px",
  },

  otpInput: {
    display: "flex",
    justifyContent: "space-evenly",
  },

  errorMessage: {
    fontSize: 12,
    color: "red",
  },

  timeoutBox: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 8,
  },

  dialogActions: {
    padding: "30px 60px",
    justifyContent: "center",
  },

  sendOtp: {
    color: "#00327E",
    fontWeight: 500,
  },

  titleDialogLinkTrios: {
    wordBreak: "break-word",
    fontWeight: 600,
    marginTop: 8,
    padding: "8px 16px",
  },

  contentDialogLinkTrios: {
    wordBreak: "break-word",
    padding: 16,
  },
}));
