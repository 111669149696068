import React, { Fragment, useEffect, useState, useImperativeHandle, forwardRef } from "react";
import {
  Box,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { AppConstant, LangConstant } from "const";
import { useDispatch, useSelector } from "react-redux";
import ShipRedux from "redux-store/ship.redux";
import FilterByNation from "pages/HomePage/Sidebar/Filter/FilterByNation";
import { makeStyles } from "@mui/styles";
import { countries } from "countries-list";
import { convertCoordinates } from "utils";
import { convertTimeStampToDate } from "utils/date.utils";

const ListShipByDayTable = forwardRef(({ type, time }, ref) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const listShipByDayData = useSelector(state => state.shipRedux.listShipByDayData);

  const [countryCode, setCountryCode] = useState();
  const [pagination, setPagination] = useState({
    page: listShipByDayData?.page || AppConstant.FIRST_PAGE,
    size: listShipByDayData?.size || AppConstant.PAGE_SIZE,
  });
  useImperativeHandle(ref, () => {
    return {
      onHandleSearchByDay(searchData) {
        setPagination({
          ...pagination,
          page: AppConstant.FIRST_PAGE,
        });
        dispatch(
          ShipRedux.getListShipByDay({
            ...searchData,
            country_code: type === AppConstant.REPORT_DAY_TYPE.foreignShip.value ? countryCode : undefined,
          }),
        );
      },
    };
  }, [countryCode]);

  const onChangePage = (event, value) => {
    setPagination({
      ...pagination,
      page: value,
    });
    dispatch(
      ShipRedux.getListShipByDay({
        type: type,
        time: time,
        page: value,
        size: pagination.size,
        country_code: type === AppConstant.REPORT_DAY_TYPE.foreignShip.value ? countryCode : undefined,
      }),
    );
  };

  useEffect(() => {
    setPagination({
      ...pagination,
      page: AppConstant.FIRST_PAGE,
    });
    dispatch(
      ShipRedux.getListShipByDay({
        type: type,
        time: time,
        page: pagination.page,
        size: pagination.size,
        country_code: type === AppConstant.REPORT_DAY_TYPE.foreignShip.value ? countryCode : undefined,
      }),
    );
  }, [countryCode]);

  return (
    <Fragment>
      {type === AppConstant.REPORT_DAY_TYPE.foreignShip.value && (
        <Box className={classes.filterNation}>
          <Typography sx={{ mr: 2 }}>{LangConstant.TXT_NATIONALITY}</Typography>
          <FilterByNation setCountryCode={setCountryCode} />
        </Box>
      )}
      <TableContainer sx={{ height: 400 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">{LangConstant.TXT_STT}</TableCell>
              <TableCell align="center">{LangConstant.TXT_SHIP_NAME}</TableCell>
              <TableCell align="center">{LangConstant.TXT_TIME}</TableCell>
              <TableCell align="center">{LangConstant.TXT_LOCATION}</TableCell>
              <TableCell align="center">{LangConstant.TXT_PLACE}</TableCell>
              <TableCell align="center">{LangConstant.TXT_SPEED_AVG}</TableCell>
              <TableCell align="center">{LangConstant.TXT_ACTIVE}</TableCell>
              <TableCell align="center">{LangConstant.TXT_NATIONALITY}</TableCell>
            </TableRow>
          </TableHead>
          {listShipByDayData?.data && listShipByDayData?.data.length > 0 ? (
            <TableBody>
              {listShipByDayData?.data.map((data, index) => (
                <TableRow key={data.ship_uid}>
                  <TableCell align="center">{(pagination.page - 1) * pagination.size + index + 1}</TableCell>
                  <TableCell align="center">{data.shipName}</TableCell>
                  <TableCell align="center">{convertTimeStampToDate(data.locationList[0].time / 1000)}</TableCell>
                  <TableCell align="center">
                    {convertCoordinates(data.locationList[0].lat, data.locationList[0].lon)}
                  </TableCell>
                  <TableCell align="center">{data.note}</TableCell>
                  <TableCell align="center">{data.avgSpeed}</TableCell>
                  <TableCell align="center">{data.moveStatus}</TableCell>
                  <TableCell align="center">{countries[data.countryCode]?.name || ""}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={10} className={classes.noData}>
                  {LangConstant.TXT_NO_DATA}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {listShipByDayData?.totalPage > 0 && (
        <Box className={classes.paginationContainer}>
          <Pagination
            onChange={onChangePage}
            count={listShipByDayData?.totalPage}
            page={pagination.page}
            variant="outlined"
            color="primary"
          />
        </Box>
      )}
    </Fragment>
  );
});

export default ListShipByDayTable;

const useStyles = makeStyles({
  filterNation: {
    display: "flex",
    alignItems: "center",
  },

  noData: {
    textAlign: "center",
    color: "#999999",
  },

  paginationContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "32px",
  },
});
