import React, { useState } from "react";
import {
  InputAdornment,
  TextField,
  Button,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  Typography,
  Pagination,
} from "@mui/material";
import { Delete, Search } from "@mui/icons-material";

const PAGE_SIZE = 10;

const AddUserTable = ({ rows, onDelete, onClickAddUser }) => {
  const [page, setPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");

  const handleInputChange = e => {
    setSearchInput(e.target.value);
  };

  const handleDeleteUser = user => {
    if (onDelete) onDelete(user);
  };

  const handleChangePage = (_, value) => {
    if (page !== value) setPage(value);
  };

  const handleClickAddUser = () => {
    if (onClickAddUser) onClickAddUser();
  };

  const filteredRows = rows ? rows.filter(row => row.name.toLowerCase().includes(searchInput.toLowerCase())) : [];
  const paginationRows = filteredRows.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);

  return (
    <Box>
      <Box sx={classes.searchContainer}>
        <TextField
          size="small"
          label="Tìm kiếm"
          variant="outlined"
          value={searchInput}
          sx={classes.searchInput}
          onChange={handleInputChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <Button variant="outlined" onClick={handleClickAddUser}>
          Thêm mới
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">STT</TableCell>
              <TableCell align="center">Họ và tên</TableCell>
              <TableCell align="center">Phòng ban</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Số điện thoại</TableCell>
              <TableCell align="center">Hành động</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows && rows.length > 0 ? (
              paginationRows.map((row, index) => (
                <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell align="center">{index + 1 + (page - 1) * PAGE_SIZE}</TableCell>
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">{row.department || "-"}</TableCell>
                  <TableCell align="center">{row.email}</TableCell>
                  <TableCell align="center">{row.phone_number || "-"}</TableCell>
                  <TableCell align="center">
                    <IconButton size="small" onClick={() => handleDeleteUser(row)}>
                      <Delete fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell colSpan={6} align="center">
                  Không có dữ liệu
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={classes.footer}>
        <Typography variant="caption">Số lượng người dùng đã chọn: {rows ? rows.length : 0}</Typography>
        <Pagination
          page={page}
          color="primary"
          count={Math.ceil(rows ? rows.length / PAGE_SIZE : 0)}
          onChange={handleChangePage}
        />
      </Box>
    </Box>
  );
};

export default AddUserTable;

const classes = {
  searchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "24px",
  },

  searchInput: {
    width: 500,
  },

  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "12px",
  },
};
