import L from "leaflet";
import { angle360 } from "utils";
import { DEFAULT_VIDEO_TRACKING_CONFIGS } from "pages/HomePage";
import { useMapContext } from "./useMapContext";

export const useAnimationTrackingLayer = () => {
  const { map, animateMarker } = useMapContext();

  const createTrackingDuration = (bounds, duration, removeOnEnd, speed) => {
    const angle = angle360(bounds[0]["lat"], bounds[0]["lng"], bounds[1]["lat"], bounds[1]["lng"]) - 20;
    return {
      angle: angle,
      polyline: L.motion.polyline(
        bounds,
        { color: "transparent", weight: 1 },
        {
          auto: false,
          duration: duration + speed * 2 - 20,
          easing: L.Motion.Ease.linear,
        },
        {
          removeOnEnd: removeOnEnd,
          showMarker: false,
          icon: L.divIcon({
            originalAngle: angle,
            className: "div-icon leaflet-tracking-div-icon z-index-10001",
            html: `
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" style="transform: rotate(${angle}deg) scale(1.2, 1.6);">
                <g id="Arrow_GPS" data-name="Arrow GPS">
                  <path d="M26.9336,28.6411l-10-26a1,1,0,0,0-1.8672,0l-10,26A1,1,0,0,0,6.6,29.8L16,22.75l9.4,7.05a1,1,0,0,0,1.5332-1.1587Z" style="fill:red"/>
                </g>
              </svg>
            `,
          }),
        },
      ),
    };
  };

  const createTrackingSequence = (positions, framerate, speed) => {
    const sequenceConfigs = positions.reduce((trackingMotionList, curPosition, index) => {
      const nextPosition = positions[index + 1];
      if (nextPosition) {
        const currentMilliseconds = curPosition.time;
        const nextMilliseconds = nextPosition.time;
        const currentDuration = (nextMilliseconds - currentMilliseconds) / 1000;
        const duration = createTrackingDuration(
          [curPosition, nextPosition],
          framerate ? currentDuration * framerate : currentDuration / speed,
          index + 1 !== positions.length - 1,
          speed,
        );
        trackingMotionList.push({
          duration: currentDuration,
          motion: duration.polyline,
          angle: duration.angle,
        });
      }

      return trackingMotionList;
    }, []);

    return {
      sequenceConfigs: sequenceConfigs,
      sequence: L.motion.seq(sequenceConfigs.map(track => track.motion)),
    };
  };

  const initVideoTrackingConfigs = currentTrackingShip => {
    if (animateMarker.current) map.current.removeLayer(animateMarker.current);
    const positions = currentTrackingShip.location_history
      .sort((prev, next) => prev.time - next.time)
      .map(location => ({ ...location, lng: location.long }));
    const { sequenceConfigs, sequence } = createTrackingSequence(positions, 0, 1);
    const durationSteps = sequenceConfigs.map(track => track.duration);
    const totalDuration = sequenceConfigs.reduce((currentDuration, motion) => {
      currentDuration += motion.duration;
      return currentDuration;
    }, 0);
    animateMarker.current = sequence;
    map.current.addLayer(animateMarker.current);
    const speeds = positions.map(position => position.speed);
    const newConfigs = Object.assign({}, DEFAULT_VIDEO_TRACKING_CONFIGS, {
      shipUid: currentTrackingShip.ship_uid,
      shipName: currentTrackingShip.ship_name,
      shipMMSI: currentTrackingShip.mmsi_code,
      positions: positions,
      totalDuration: totalDuration,
      durationSteps: durationSteps,
      speeds: speeds,
    });
    const markers = getMarkers();
    speeds.forEach((_, index) => {
      const marker = markers[index];
      if (marker) {
        marker.customShipInfo = {
          name: currentTrackingShip.ship_name,
          mmsi: currentTrackingShip.ship_mmsi,
          originalIcon: marker.getIcon(),
          speed: speeds[index + 1],
        };
      }
    });
    return newConfigs;
  };

  const updateVideoTrackingConfigs = (currentState, speed, startIndex, framerate = 0) => {
    if (animateMarker.current) map.current.removeLayer(animateMarker.current);
    const positions = currentState.positions.filter((_, index) => index >= startIndex);
    const newSequence = createTrackingSequence(positions, framerate, speed);
    animateMarker.current = newSequence.sequence;
    map.current.addLayer(animateMarker.current);
    const markers = getMarkers();
    currentState.speeds.forEach((_, index) => {
      const marker = markers[index];
      if (marker) {
        marker.customShipInfo = {
          name: currentState.shipName,
          mmsi: currentState.shipMMSI,
          originalIcon: marker.getIcon(),
          speed: currentState.speeds[index + 1],
        };
      }
    });
  };

  const play = () => {
    if (animateMarker.current) {
      animateMarker.current.motionStart();
      console.log("Tracking Status: Play");
    }
  };

  const pause = () => {
    if (animateMarker.current) {
      animateMarker.current.motionPause();
      console.log("Tracking Status: Pause");
    }
  };

  const resume = () => {
    if (animateMarker.current) {
      animateMarker.current.motionResume();
      console.log("Tracking Status: Resume");
    }
  };

  const stop = () => {
    if (animateMarker.current) {
      animateMarker.current.motionStop();
      console.log("Tracking Status: Stop");
    }
  };

  const getMarkers = () => {
    if (!animateMarker.current) return [];
    return animateMarker.current.getMarkers().map(markerArray => markerArray[0]);
  };

  const openPopup = ({ showNamePopup, showMMSIPopup, showSpeedPopup }) => {
    const markers = getMarkers();
    markers.forEach(marker => {
      if (showNamePopup || showMMSIPopup || showSpeedPopup) {
        const content = [];
        if (showNamePopup && marker.customShipInfo.name) content.push(marker.customShipInfo.name);
        if (showMMSIPopup && marker.customShipInfo.mmsi) content.push(marker.customShipInfo.mmsi);
        if (showSpeedPopup && marker.customShipInfo.speed !== null) content.push(marker.customShipInfo.speed + " knot");
        marker.setIcon(
          L.divIcon({
            originalAngle: marker.customShipInfo.originalIcon.options.originalAngle,
            className: "div-icon leaflet-tracking-div-icon z-index-10001",
            html: `
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" style="transform: rotate(${
                marker.customShipInfo.originalIcon.options.originalAngle
              }deg) scale(1.2, 1.6);">
                <g id="Arrow_GPS" data-name="Arrow GPS">
                  <path d="M26.9336,28.6411l-10-26a1,1,0,0,0-1.8672,0l-10,26A1,1,0,0,0,6.6,29.8L16,22.75l9.4,7.05a1,1,0,0,0,1.5332-1.1587Z" style="fill:red"/>
                </g>
              </svg>
              <span class="leaflet-animation-ship-element">${content.join(", ")}</span>
            `,
          }),
        );
      } else {
        marker.setIcon(marker.customShipInfo.originalIcon);
      }
    });
  };

  const clear = () => {
    if (animateMarker.current) {
      animateMarker.current.clearLayers();
    }
  };

  return {
    initVideoTrackingConfigs,
    play,
    pause,
    resume,
    stop,
    updateVideoTrackingConfigs,
    openPopup,
    clear,
  };
};
