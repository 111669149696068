import L from "leaflet";
import { useSelector } from "react-redux";
import { ApiConstant, SystemConstant } from "const";
import { useMapContext } from "./useMapContext";
import JSZip from "jszip";

export const useArea = () => {
  const { areaLayer } = useMapContext();

  const kmlFiles = useSelector(state => state.areaRedux.kmlFiles);

  const show = (shapes = []) => {
    if (!window.JSZip) {
      window.JSZip = JSZip;
    }
    shapes.forEach(shape => {
      if (shape.type === "polygon") {
        if (shape.radius) {
          const circle = L.circle([shape.center_lat, shape.center_lon], {
            radius: shape.radius,
          });
          areaLayer.current.addLayer(circle);
        } else {
          const polygon = L.polygon(shape.coordinate_list.map(coord => [coord.lat, coord.long]));
          areaLayer.current.addLayer(polygon);
        }
      } else if (shape.type === "file") {
        const area = kmlFiles[shape.attachment_uid];
        if (area && area.file) {
          const xml = area.file;
          const isKml = area.type === SystemConstant.KML_MIME_TYPE;
          const isKmz = area.type === SystemConstant.KMZ_MIME_TYPE;
          if (isKml) {
            const track = new L.KML(new DOMParser().parseFromString(xml, "text/xml"));
            areaLayer.current.addLayer(track);
          } else if (isKmz) {
            const kmz = new L.KMZLayer();
            kmz.load(`${ApiConstant.API_BASE_URL}/attachment/area/${shape.attachment_uid}`);
            areaLayer.current.addLayer(kmz);
          } else {
            areaLayer.current.addLayer(L.vectorGrid.slicer(xml));
          }
        }
      }
    });
  };

  const clear = () => {
    areaLayer.current.clearLayers();
  };

  return { show, clear };
};
