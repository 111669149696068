import { Delete, Edit, RestartAlt } from "@mui/icons-material";
import {
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { AppConstant, LangConstant, PathConstant } from "const";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { hasRoleScopes, toCamel } from "utils";
import { makeStyles } from "@mui/styles";
import Cookies from "js-cookie";
import { Link, generatePath } from "react-router-dom";

const UserManagamentTable = ({
  pagination,
  setEditUser,
  setDataEdit,
  setIshow,
  setResetPassword,
  setChangePassword,
  setPagination,
}) => {
  const classes = useStyles();

  const roleId = Cookies.get(AppConstant.KEY_ROLE_ID);
  const userId = Cookies.get(AppConstant.KEY_USER_ID);
  const successGetUser = useSelector(state => state.conversationRedux.successGetUser);
  const panigation1 = useSelector(state => state.conversationRedux.pagination);
  const totalPageUser = useSelector(state => state.conversationRedux.totalPageUser);

  const [rowTable, setRowTable] = useState([]);

  const onChangePage = (e, value) => {
    setPagination(value);
  };

  useEffect(() => {
    let result = [];
    successGetUser.forEach((item, index) => {
      item = toCamel(item);
      result.push({
        ...item,
        action: (
          <Fragment>
            <IconButton
              disabled={!hasRoleScopes(AppConstant.SCOPES.USER_UPDATE)}
              onClick={() => {
                setEditUser(true);
                setDataEdit(item);
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              disabled={parseInt(roleId) >= item.power || !hasRoleScopes(AppConstant.SCOPES.USER_DELETE)}
              onClick={() => {
                setIshow(true);
                setDataEdit(item);
              }}
            >
              <Delete />
            </IconButton>
            <IconButton
              onClick={() => {
                if (userId === item.uid) {
                  setChangePassword(true);
                  setDataEdit(item);
                } else {
                  setResetPassword(true);
                  setDataEdit(item);
                }
              }}
            >
              <RestartAlt />
            </IconButton>
          </Fragment>
        ),
      });
    });
    setRowTable(result);
  }, [successGetUser]);

  return (
    <Fragment>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table stickyHeader sx={{ overflow: "scroll" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>STT</TableCell>
              <TableCell>{LangConstant.TXT_NAME}</TableCell>
              <TableCell align="center">{LangConstant.TXT_SYSTEM}</TableCell>
              <TableCell align="center">{LangConstant.TXT_NUMBER}</TableCell>
              <TableCell align="center">{LangConstant.TXT_EMAIL}</TableCell>
              <TableCell align="center">{LangConstant.TXT_USER_RIGHTS}</TableCell>
              <TableCell align="center">{LangConstant.TXT_TIME_SYSTEM} </TableCell>
              <TableCell align="center">{LangConstant.TXT_MANIPULATION}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowTable.length ? (
              rowTable.map((row, index) => (
                <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {(pagination - 1) * panigation1 + index + 1}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {hasRoleScopes(AppConstant.SCOPES.USER_DETAIL) ? (
                      <Link
                        to={generatePath(PathConstant.ADMIN_USER_DETAIL, {
                          userId: row.uid,
                        })}
                      >
                        {row.name}
                      </Link>
                    ) : (
                      row.name
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {row.triosPhoneNumber ? LangConstant.TXT_TRIOS : LangConstant.TXT_GST}
                  </TableCell>
                  <TableCell className={classes.emailInput} align="center">
                    {row.phoneNumber}
                  </TableCell>
                  <TableCell className={classes.emailInput} align="center">
                    {row.email}
                  </TableCell>
                  <TableCell className={classes.itemSub} align="center">
                    {AppConstant.ROLE_NAME[row.power]}
                  </TableCell>
                  <TableCell align="center">{row.department}</TableCell>
                  <TableCell align="center">{row.action}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow></TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination sx={{ pt: 2 }} onChange={onChangePage} count={Number(totalPageUser)} variant="outlined" />
    </Fragment>
  );
};

export default UserManagamentTable;

const useStyles = makeStyles({
  tableContainer: {
    height: 700,
    overflow: "auto",
  },
});
