import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { Search } from "@mui/icons-material/";
import { useEffect } from "react";
import ConversationRedux from "redux-store/conversation.redux";
import { useDispatch, useSelector } from "react-redux";
import { getContry } from "utils";
import { useRef } from "react";
import countryList from "react-select-country-list";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { handleSendNotiInSaga } from "utils";
import { LangConstant } from "const";

function createData(
  stt,
  ships,
  shipTypeName,
  name,
  idShip,
  imoShip,
  mmsiShip,
  chants,
  supplier,
  nationality,
  manipulation,
) {
  return { stt, ships, shipTypeName, name, idShip, imoShip, mmsiShip, chants, supplier, nationality, manipulation };
}

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const AddWatchlist = ({ open, onClose, button, groupShip }) => {
  const classes = useStyles();
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const options = useMemo(() => countryList().getData(), []);

  const listShipWatch = useSelector(state => state.conversationRedux.listShipWatch);
  const getTypeShip = useSelector(state => state.conversationRedux.getTypeShip);
  const sucessCreateShip = useSelector(state => state.conversationRedux.sucessCreateShip);
  const deleteListShip = useSelector(state => state.conversationRedux.deleteListShip);
  const createGroupShip = useSelector(state => state.conversationRedux.createGroupShip);
  const addGroupShip = useSelector(state => state.conversationRedux.addGroupShip);
  const errCreateGroupShips = useSelector(state => state.conversationRedux.errCreateGroupShips);
  const totalPageListShip = useSelector(state => state.conversationRedux.totalPageListShip);

  const [ships, setShips] = useState("");
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [supervisor, setSupervisor] = useState(false);
  const [nationality, setNationality] = useState("");
  const [checkedCheckbox, setCheckedCheckbox] = useState([]);
  const [searchShip, setSearchShip] = useState("");
  const [rowTable, setRowTable] = useState([]);
  const [pagination, setPagination] = useState(1);
  const typingTimeout = useRef(null);
  const [reason, setReason] = useState({
    name: "",
    note: "",
  });

  const onChangeNationality = event => {
    setNationality(event.target.value);
  };

  const onChangePage = (e, value) => {
    setPagination(value);
  };

  const onChangeSearch = e => {
    const value = e.target.value;
    if (typingTimeout.current) {
      clearTimeout(typingTimeout.current);
    }
    typingTimeout.current = setTimeout(() => {
      setSearchShip(value);
    }, 500);
  };

  const onClickAddWatchList = () => {
    dispatch(
      ConversationRedux.createShipGroup({
        name: reason.name,
        ships: checkedCheckbox,
        type: 1,
        note: reason.note,
      }),
    );
  };

  const onClickAddShipForGroup = () => {
    if (!checkedCheckbox || checkedCheckbox.length === 0)
      handleSendNotiInSaga(LangConstant.MS_NOT_SELECTED_SHIP, "error");
    else if (groupShip) {
      dispatch(
        ConversationRedux.updateGroupShip({
          uid: groupShip.uid,
          note: groupShip.note,
          ships: [...checkedCheckbox],
        }),
      );
    }
  };

  const onChangeReason = e => {
    setReason({ ...reason, [e.target.name]: e.target.value });
  };

  const onClickCheckbox = id => {
    setCheckedCheckbox(pre => (pre.includes(id) ? checkedCheckbox.filter(item => item !== id) : [...pre, id]));
  };

  useEffect(() => {
    setOpenBackDrop(true);
    dispatch(
      ConversationRedux.getListShipWatch({
        country_code: nationality,
        filter: searchShip,
        ship_type: ships,
        page: pagination,
      }),
    );
  }, [searchShip, ships, sucessCreateShip, pagination, nationality, deleteListShip, getTypeShip]);

  useEffect(() => {
    if (addGroupShip) {
      onClose();
      setSupervisor(false);
      setCheckedCheckbox([]);
    }
    dispatch(ConversationRedux.conversationReset());
  }, [addGroupShip]);

  useEffect(() => {
    dispatch(
      ConversationRedux.conversationSet({
        checkbox: checkedCheckbox,
      }),
    );
  }, [checkedCheckbox]);

  useEffect(() => {
    if (listShipWatch.length >= 0) {
      setOpenBackDrop(false);
    }
  }, [listShipWatch]);

  useEffect(() => {
    if (listShipWatch && listShipWatch.length) {
      let result = [];
      listShipWatch.map(itemContry => {
        return result.push(
          createData(
            itemContry && itemContry.uid !== null ? itemContry?.uid : " ",
            itemContry && itemContry.ship_type !== null ? itemContry.ship_type : "",
            itemContry && itemContry.ship_type_name !== null ? itemContry.ship_type_name : "",
            itemContry && itemContry.ship_name !== null ? itemContry.ship_name : "",
            itemContry && itemContry.uid !== null ? itemContry.uid : "",
            itemContry && itemContry.IMO !== null ? itemContry.IMO : "",
            itemContry && itemContry.mmsi_code !== null ? itemContry.mmsi_code : "",
            itemContry && itemContry.call_sign !== null ? itemContry.call_sign : "",
            itemContry && itemContry.supplier !== null ? itemContry.supplier : "",
            itemContry && itemContry.country_code !== null
              ? itemContry.country_code === "UNKNOWN"
                ? "Không xác định"
                : getContry(options, itemContry.country_code).map(item => item.label)
              : "",
          ),
        );
      });
      setRowTable(result);
    }
  }, [listShipWatch, ships]);

  useEffect(() => {
    dispatch(ConversationRedux.getShipType());
  }, [sucessCreateShip, ships]);

  useEffect(() => {
    if (createGroupShip) {
      setSupervisor(false);
      onClose();
      setCheckedCheckbox([]);
    }
    dispatch(ConversationRedux.conversationReset());
  }, [createGroupShip]);

  useEffect(() => {
    if (errCreateGroupShips) {
      handleSendNotiInSaga(LangConstant.MS_SHIP_IS_EXSITED, "error");
      navigator("/admin/watchList");
    }
    dispatch(
      ConversationRedux.conversationSet({
        errCreateGroupShips: false,
      }),
    );
  }, [errCreateGroupShips]);

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
        <DialogTitle>Thêm tàu vào danh sách giám sát</DialogTitle>
        <DialogContent style={{ overflow: "hidden" }}>
          <DialogContent>
            <Box className={classes.searchShip}>
              <TextField
                fullWidth
                onChange={onChangeSearch}
                placeholder="Tìm kiếm tàu"
                size="small"
                label="Tìm kiếm tàu"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box className={classes.flexFillter}>
              <Box className={classes.paddingSelect}>
                <FormControl size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">Quốc tịch</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={nationality}
                    label="Quốc tịch"
                    onChange={onChangeNationality}
                  >
                    <MenuItem value="">Tất cả</MenuItem>
                    {options &&
                      options.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <Box className={classes.containerSelect}>
                <FormControl size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">Chọn tàu</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={ships}
                    label="Chọn tàu"
                    onChange={e => setShips(e.target.value)}
                  >
                    <MenuItem value="">Tất cả</MenuItem>
                    {getTypeShip &&
                      getTypeShip.map((item, index) => (
                        <MenuItem key={index} value={item.type}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <TableContainer sx={{ height: 600, overflow: "auto" }} component={Paper}>
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell width="50"></TableCell>
                    <TableCell align="center">Loại tàu</TableCell>
                    <TableCell align="center">Tên tàu</TableCell>
                    <TableCell align="center">Số IM0</TableCell>
                    <TableCell align="center">Số MMSI</TableCell>
                    <TableCell align="center">Hô hiệu</TableCell>
                    <TableCell align="center">Nhà cung cấp</TableCell>
                    <TableCell align="center">Quốc tịch</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listShipWatch.length === 0 ? (
                    <h2>Dữ liệu chưa có</h2>
                  ) : (
                    rowTable.map((row, index) => (
                      <TableRow key={row.stt} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell>
                          <Checkbox onClick={() => onClickCheckbox(row.stt)} color="warning" {...label} />
                        </TableCell>
                        <TableCell align="center">{row.shipTypeName}</TableCell>
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell align="center">{row.imoShip}</TableCell>
                        <TableCell align="center">{row.mmsiShip}</TableCell>
                        <TableCell align="center">{row.chants}</TableCell>
                        <TableCell align="center">{row.supplier}</TableCell>
                        <TableCell align="center">{row.nationality}</TableCell>
                        <TableCell align="center">{row.manipulation}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Box className={classes.flexPanigation}>
            <Pagination onChange={onChangePage} sx={{ paddingTop: 2 }} count={+totalPageListShip} shape="rounded" />
          </Box>
          {button ? (
            <Button onClick={onClickAddShipForGroup}>Thêm</Button>
          ) : (
            <Button onClick={() => setSupervisor(true)}>Lưu</Button>
          )}
          <Button onClick={onClose}>Hủy</Button>
        </DialogActions>
        <Backdrop sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }} open={openBackDrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
      {supervisor && (
        <Dialog open={supervisor} onClose={() => setSupervisor(false)} maxWidth="xs" fullWidth>
          <DialogTitle>Nhập lý do giám sát</DialogTitle>
          <DialogContent>
            <DialogContent>
              <Box className={classes.paddingTopTitle}>
                <TextField
                  onChange={onChangeReason}
                  name="name"
                  fullWidth
                  size="small"
                  label=" Nhập tên danh tên sách giám sát"
                  variant="outlined"
                />
              </Box>
              <Box className={classes.paddingTopTitle}>
                <TextField
                  onChange={onChangeReason}
                  name="note"
                  fullWidth
                  size="small"
                  label="Nhập lý do giám sát"
                  variant="outlined"
                />
              </Box>
            </DialogContent>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClickAddWatchList}>Thêm tàu </Button>
            <Button onClick={() => setSupervisor(false)}>Hủy</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default AddWatchlist;

const useStyles = makeStyles(theme => ({
  containerSelect: {
    width: 200,
  },
  flexPanigation: {
    display: "flex",
    width: "100%",
  },
  buttonAddPadding: {
    paddingRight: 10,
  },
  flexFillter: {
    display: "flex",
    alignItems: "center",
    padding: "5px 0",
  },
  paddingSelect: {
    paddingRight: 10,
    width: 200,
  },
  searchShip: {
    margin: "auto",
    width: 400,
  },
  inputShips: {
    padding: "10px 10px",
  },
  pagination: {
    paddingTop: 20,
  },
  paddingInput: {
    padding: "10px 0",
  },
  paddingTopTitle: {
    paddingTop: 10,
  },
}));
