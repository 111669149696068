import { ApiConstant, LangConstant } from "const";
import Cookies from "js-cookie";
import { call, put, select } from "redux-saga/effects";
import { ConversationService } from "services";
import { handleSendNotiInSaga, toSnake } from "utils";
import ConversationAction from "../redux-store/conversation.redux";
import UserAction from "../redux-store/user.redux";
import { handleOpenLoading } from "utils";

export function* createUser(action) {
  try {
    const { data } = action;
    handleOpenLoading(true);
    let response = yield call(ConversationService.createUserSaga, data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationAction.conversationSet({
          createUserSuccess: true,
        }),
      );
    } else if (response.status === ApiConstant.STT_BAD_REQUEST) {
      yield put(
        ConversationAction.conversationSet({
          errCreateUserFailed: true,
        }),
      );
    } else {
      yield put(
        ConversationAction.conversationSet({
          errors: response,
        }),
      );
    }
    handleOpenLoading(false);
  } catch (error) {
    console.log(error);
    handleOpenLoading(false);
  }
}

export function* deleteUser(action) {
  try {
    const { data } = action;

    let response = yield call(ConversationService.deleteUserSaga, data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationAction.conversationSet({
          successDeleteUser: true,
        }),
      );
    } else {
      yield put(
        ConversationAction.conversationSet({
          errors: response,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* changePassword(action) {
  try {
    const { data } = action;

    let response = yield call(ConversationService.changePasswordSaga, data);

    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationAction.conversationSet({
          changePasswordSuccess: true,
          errChangePassword: false,
        }),
      );
    } else if (response.status === ApiConstant.STT_UNAUTHORIZED) {
      yield put(
        ConversationAction.conversationSet({
          errChangePassword: true,
        }),
      );
    } else {
      yield put(
        ConversationAction.conversationSet({
          errors: response,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* editUser(action) {
  try {
    const { data } = action;
    const userInfo = yield select(state => state.userRedux.userInfo);
    handleOpenLoading(true);
    let response = yield call(ConversationService.editUserSaga, data);

    if (response.status === ApiConstant.STT_OK) {
      yield put(
        UserAction.userSet({
          userInfo: { ...userInfo, ...response.data?.data },
        }),
      );
      yield put(
        ConversationAction.conversationSet({
          successEditUser: true,
        }),
      );
      handleSendNotiInSaga(LangConstant.MS_EDIT_PROFILE_SUCCESS, "success");
    } else if (response.status === ApiConstant.STT_BAD_REQUEST) {
      yield put(
        ConversationAction.conversationSet({
          errEmailEditUser: true,
        }),
      );
      handleSendNotiInSaga(LangConstant.MS_EDIT_PROFILE_FAILED, "error");
    } else if (response.status === ApiConstant.STT_FORBIDDEN) {
      yield put(
        ConversationAction.conversationSet({
          errPermission: true,
        }),
      );
      handleSendNotiInSaga(LangConstant.MS_EDIT_PROFILE_FAILED, "error");
    } else {
      handleSendNotiInSaga(LangConstant.MS_EDIT_PROFILE_FAILED, "error");
    }
    handleOpenLoading(false);
  } catch (error) {
    console.log(error);
    handleSendNotiInSaga(LangConstant.MS_EDIT_PROFILE_FAILED, "error");
    handleOpenLoading(false);
  }
}

export function* getHistory(action) {
  try {
    const { data } = action;

    let response = yield call(ConversationService.getHistorySaga, data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationAction.conversationSet({
          sucessHistory: response.data.data,
          pagination: response.data.size,
          totalPageHistory: response.data.total_page,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getListUsers(action) {
  try {
    const { data } = action;
    handleOpenLoading(true);
    let response = yield call(ConversationService.getUserList, data);

    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationAction.conversationSet({
          userList: response.data.data,
          successGetUser: response.data.data,
          pagination: response.data.size,
          totalPageUser: response.data.total_page,
        }),
      );
    }
    handleOpenLoading(false);
  } catch (error) {
    console.log(error);
    handleOpenLoading(false);
  }
}

export function* resetPassword(action) {
  try {
    const { data } = action;

    let response = yield call(ConversationService.resetPasswordSaga, data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationAction.conversationSet({
          successResestPassword: true,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* createShip(action) {
  try {
    const { data } = action;

    let response = yield call(ConversationService.createShipSaga, data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationAction.conversationSet({
          sucessCreateShip: true,
          isCreateShipSuccess: true,
        }),
      );
    } else if (response.status === ApiConstant.STT_BAD_REQUEST) {
      yield put(
        ConversationAction.conversationSet({
          createShipErrorMsg: response.data.message,
        }),
      );
    } else if (response.status === ApiConstant.STT_INTERNAL_SERVER) {
      yield put(
        ConversationAction.conversationSet({
          errCreateShip: true,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getListShip(action) {
  try {
    const { data } = action;

    let response = yield call(ConversationService.getListShipSaga, data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationAction.conversationSet({
          getListShip: response.data.data,
          pagination: response.data.size,
          getListShipSuccess: true,
          totalPageListShip: response.data.total_page,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getShipType(action) {
  try {
    const { data } = action;

    let response = yield call(ConversationService.getShipTypeSaga, data);

    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationAction.conversationSet({
          getTypeShip: response.data.data,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* deleteListShip(action) {
  try {
    const { data } = action;
    handleOpenLoading(true);
    let response = yield call(ConversationService.deleteListSaga, { ids: data.uids.join() });

    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationAction.conversationSet({
          deleteListShip: true,
        }),
      );
      handleSendNotiInSaga(LangConstant.MS_DELETE_SUCCESS, "success");
    } else {
      handleSendNotiInSaga(LangConstant.MS_DELETE_ERROR, "error");
    }
    handleOpenLoading(false);
  } catch (error) {
    console.log(error);
    handleSendNotiInSaga(LangConstant.MS_DELETE_ERROR, "error");
    handleOpenLoading(false);
  }
}

export function* updateShip(action) {
  try {
    const { data } = action;

    let response = yield call(ConversationService.updateShipSaga, data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationAction.conversationSet({
          updateShip: true,
        }),
      );
    } else if (response.status === 400) {
      yield put(
        ConversationAction.conversationSet({
          errEditShip: true,
          updateShipErrorMsg: response.data.message,
        }),
      );
    } else if (response.status === 409) {
      yield put(
        ConversationAction.conversationSet({
          errEditShip: response.data.message,
        }),
      );
    } else if (response.status === 404) {
      yield put(
        ConversationAction.conversationSet({
          errEditShip: true,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* createGroupShip(action) {
  try {
    const { data } = action;

    let response = yield call(ConversationService.createGroupShipSaga, data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationAction.conversationSet({
          createGroupShip: true,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getHarbors(action) {
  try {
    const { data } = action;

    let response = yield call(ConversationService.getHarborsSaga, data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationAction.conversationSet({
          getHarbors: response.data.data,
          pagination: response.data.size,
          totalPageHarbors: response.data.total_page,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* createHarbors(action) {
  try {
    const { data } = action;

    let response = yield call(ConversationService.createHarborsSaga, data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationAction.conversationSet({
          createMorePort: true,
        }),
      );
    }
    if (response.status === ApiConstant.STT_BAD_REQUEST) {
      yield put(
        ConversationAction.conversationSet({
          errLongtitude: response.data.error.longitude,
          errLangtitude: response.data.error.latitude,
          errorDuplicatedName: response.data.error.name,
          errName: response.data.error.name,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* createMorePort(action) {
  try {
    const { data } = action;

    let response = yield call(ConversationService.createMorePortSaga, data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationAction.conversationSet({
          createPortSuccsess: true,
        }),
      );
    }
    if (response.status === ApiConstant.STT_BAD_REQUEST) {
      yield put(
        ConversationAction.conversationSet({
          errLongtitude: response.data.error.longitude,
          errLangtitude: response.data.error.latitude,
          errName: response.data.error.name,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getPortManager(action) {
  try {
    const { data } = action;

    let response = yield call(ConversationService.getPortManagerSaga, data);

    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationAction.conversationSet({
          getPortManager: response.data.data,
          pagination: response.data.size,
          totalPagePort: response.data.total_page,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* deleteHarbors(action) {
  try {
    const { data } = action;

    let response = yield call(ConversationService.deleteHarborsSaga, { uids: data.uids.join() });
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationAction.conversationSet({
          deleteHarbors: true,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* updateHarbors(action) {
  try {
    const { data } = action;

    let response = yield call(ConversationService.updateHarborsSaga, data);

    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationAction.conversationSet({
          updateHarborShip: true,
        }),
      );
    }
    if (response.status === ApiConstant.STT_BAD_REQUEST) {
      yield put(
        ConversationAction.conversationSet({
          errLongtitude: response.data.error.longitude,
          errLangtitude: response.data.error.latitude,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getGroupShip(action) {
  try {
    const { data } = action;
    handleOpenLoading(true);
    let response = yield call(ConversationService.getGroupShipSaga, data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationAction.conversationSet({
          getGroupsucsess: response.data.data,
          pagination: response.data.size,
          totalPageGrouShip: response.data.total_page,
        }),
      );
    }
    handleOpenLoading(false);
  } catch (error) {
    handleOpenLoading(false);
    console.log(error);
  }
}

export function* deleteGroupShip(action) {
  try {
    const { data } = action;
    handleOpenLoading(true);
    let response = yield call(ConversationService.deleteGroupShipSaga, { uids: data.uids.join() });
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationAction.conversationSet({
          deleteMuchGroupShips: true,
        }),
      );
    }
    handleOpenLoading(false);
  } catch (error) {
    handleOpenLoading(false);
    console.log(error);
  }
}

export function* updateShipGroup(action) {
  try {
    const { data } = action;
    let response = yield call(ConversationService.updateShipGroupSaga, data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationAction.conversationSet({
          addGroupShip: true,
        }),
      );
    }
    if (response.status === ApiConstant.STT_NOT_FOUND) {
      yield put(
        ConversationAction.conversationSet({
          errCreateGroupShips: true,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* deleteGroupDetailShip(action) {
  try {
    const { data } = action;
    let response = yield call(ConversationService.deleteGroupDetailShipSaga, data);

    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationAction.conversationSet({
          deleteGroupShip: true,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* uploadFile(action) {
  try {
    const { data } = action;
    let formData = new FormData();
    formData.append("file", data);
    const arr = data.name.split(".");
    formData.append("mime_type", arr[arr.length - 1]);

    let response = yield call(ConversationService.uploadFileSaga, formData);

    if (response.status === ApiConstant.STT_OK) {
      Cookies.set("uidExportAll", response.data.data.uid);
      yield put(
        ConversationAction.conversationSet({
          uploadFileSuccess: true,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* exportFile(action) {
  try {
    const { data } = action;

    let response = yield call(ConversationService.exportFileSaga, toSnake(data));
    return response;
  } catch (error) {
    console.log(error);
  }
}

export function* importFile(action) {
  try {
    const { data } = action;

    yield call(ConversationService.importFileSaga, toSnake(data));
  } catch (error) {
    console.log(error);
  }
}

export function* getGroupShipDetail(action) {
  try {
    const { data } = action;
    let response = yield call(ConversationService.getGroupShipDetailSaga, toSnake(data));

    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationAction.conversationSet({
          getDetailGroup: response.data.data,
          totalPageDetailGroup: response.data.total_page,
          pagination: response.data.size,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getDetailGroup(action) {
  try {
    const { data } = action;
    let response = yield call(ConversationService.getDetailGroupSaga, toSnake(data));

    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationAction.conversationSet({
          getDetailGroupShip: response.data.data,
        }),
      );
    }
  } catch (err) {
    console.log(err);
  }
}
export function* getStatistic(action) {
  try {
    const { data } = action;
    let response = yield call(ConversationService.getStatisticSaga, data);

    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationAction.conversationSet({
          getStatistic: response.data.data,
          getStatisticDetail: response.data.size,
          tolal: response.data.total_page,
          getStatistics: true,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* updateStatistic(action) {
  try {
    const { data } = action;
    let response = yield call(ConversationService.updateStatisticSaga, data);

    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationAction.conversationSet({
          updateStatisticSucess: true,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* deleteStatistic(action) {
  try {
    const { data } = action;
    let response = yield call(ConversationService.deleteStatisticSaga, data);

    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationAction.conversationSet({
          deleteStatistic: true,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getListShipWatch(action) {
  try {
    const { data } = action;
    let response = yield call(ConversationService.getListShipWatchSaga, data);

    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationAction.conversationSet({
          listShipWatch: response.data.data,
          totalPageListShip: response.data.total_page,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getListDeatails(action) {
  try {
    const { data } = action;
    let response = yield call(ConversationService.getListDeatailsSaga, data);

    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationAction.conversationSet({
          allItemsDetailsGroups: response.data.data,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}
