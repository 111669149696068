import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ShipActions from "redux-store/ship.redux";
import { makeStyles } from "@mui/styles";
import { Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import { AppConstant } from "const";

export default function FilterByDataResource() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const shipConditions = useSelector(state => state.shipRedux.shipConditions);

  const [selected, setSelected] = useState([]);

  const onChangeValue = event => {
    let value = event.target.value;
    let list = [...selected];
    let index = list.indexOf(value);
    index === -1 ? list.push(value) : list.splice(index, 1);
    setSelected(list);
  };

  useEffect(() => {
    let data = selected.map(str => parseInt(str));
    dispatch(
      ShipActions.shipSet({
        isChangingFilter: true,
        shipConditions: Object.assign(shipConditions, { source_type_list: data }),
      }),
    );
  }, [selected]);

  return (
    <FormGroup
      classes={{
        root: classes.filterByShipTypeBox,
      }}
    >
      {AppConstant.FILTER_SHIP_BY_RESOURCES.map(item => (
        <FormControlLabel
          key={item.id}
          control={<Checkbox value={item.id} onChange={onChangeValue} checked={selected.includes(item.id)} />}
          label={item.name}
          classes={{
            label: classes.dataResourceTitle,
          }}
        />
      ))}
    </FormGroup>
  );
}

const useStyles = makeStyles(theme => ({
  filterByShipTypeBox: {
    display: "grid",
    background: "#fff",
    gridTemplateColumns: "auto",
    paddingLeft: "16px",
  },

  dataResourceTitle: {
    fontSize: "14px",
    color: "#000",
    lineHeight: "100%",
    display: "flex",
    alignItems: "center",
    userSelect: "none",
  },
}));
