import { ApiConstant } from "const";
import { createApiWithToken } from "services/config/api.config";
import StringFormat from "string-format";

export const getPermissionGroup = (page, size, name) =>
  createApiWithToken().get(ApiConstant.GET_PERMISSION_GROUP, { page, size, name });

export const deletePermissionGroup = uid =>
  createApiWithToken().delete(StringFormat(ApiConstant.DELETE_PERMISSION_GROUP, { uid }));

export const createPermissionGroup = data => createApiWithToken().post(ApiConstant.POST_CREATE_PERMISSION_GROUP, data);

export const getPermissionDetails = uid =>
  createApiWithToken().get(StringFormat(ApiConstant.GET_PERMISSION_DETAILS, { uid }));

export const updatePermissionGroup = data =>
  createApiWithToken().put(StringFormat(ApiConstant.PUT_UPDATE_PERMISSION, { uid: data.uid }), data);
