import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  IconButton,
  DialogTitle,
  TextField,
  Typography,
  FormControl,
  DialogContent,
  OutlinedInput,
  InputAdornment,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AuthRedux from "redux-store/auth.redux";
import { useLoading } from "hooks";
import { LangConstant } from "const";
import { Box } from "@mui/system";
import { Close, Visibility, VisibilityOff } from "@mui/icons-material";
import ConversationRedux from "redux-store/conversation.redux";

const LinkTriosDialogForm = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const loading = useLoading();

  const [showPassword, setShowPassword] = useState(false);
  const [errEmail, setErrEmail] = useState("");
  const linkTriosPopupToken = useSelector(state => state.authRedux.linkTriosPopup.token);
  const errLinkTriosFailure = useSelector(state => state.conversationRedux.errLinkTriosFailure);
  const [errLogin, setErrLogin] = useState("");

  const [valueInputLogin, setValueInputLogin] = useState({
    username: "",
    password: "",
  });

  const onClose = () => {
    dispatch(
      AuthRedux.authSet({
        linkTriosPopup: {
          token: null,
        },
      }),
    );

    loading.close();
  };

  const handleFormSubmit = () => {
    let isValidated = true;

    const regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(valueInputLogin.username)) {
      setErrEmail("Email không đúng định dạng");
      isValidated = false;
    } else {
      setErrEmail("");
    }

    if (valueInputLogin && isValidated) {
      dispatch(
        AuthRedux.linkTriosSubmit({
          user_name: valueInputLogin.username,
          password: valueInputLogin.password,
          trios_token: linkTriosPopupToken,
        }),
      );
    }
  };
  useEffect(() => {
    if (errLinkTriosFailure) {
      setErrLogin("Email hoặc mật khẩu không chính xác");
    }
    loading.close();
    dispatch(
      ConversationRedux.conversationSet({
        errLinkTriosFailure: false,
      }),
    );
  }, [errLinkTriosFailure]);

  const onChangeValueLogin = e => {
    setValueInputLogin({ ...valueInputLogin, [e.target.name]: e.target.value });
  };

  const handleClickShowPassword = () => setShowPassword(show => !show);

  return (
    <>
      <Dialog open onClose={onClose}>
        <DialogTitle>
          <Box className={classes.flexIcon}>
            <Box>{LangConstant.TXT_NOTIFICATON}</Box>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent className={classes.loading}>
          <Typography className={classes.contentDialogLinkTrios}>{LangConstant.TXT_FORM_LINK_TRIOS}</Typography>

          <Typography my={1}>{LangConstant.TXT_EMAIL}</Typography>
          <TextField
            className={classes.textField}
            onChange={onChangeValueLogin}
            fullWidth
            my={1}
            autoComplete="off"
            id="username"
            name="username"
            value={valueInputLogin.username}
            placeholder="Nhập email"
          />
          <Box className={classes.errorMessage}>{errEmail}</Box>
          <Typography my={1}>{LangConstant.TXT_PASSWORD}</Typography>
          <Box>
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                onChange={onChangeValueLogin}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                      type="button"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                autoComplete="off"
                name="password"
                placeholder="Nhập mật khẩu"
              />
            </FormControl>{" "}
            {errLogin && <Box className={classes.errorMessage}>{errLogin}</Box>}
            <Box mt={2}>
              <Button
                variant="contained"
                fullWidth
                size="large"
                onClick={() => {
                  handleFormSubmit();
                }}
                disabled={valueInputLogin.username === "" || valueInputLogin.password === ""}
              >
                {LangConstant.TXT_BUTTON_TRIOS_LINK}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LinkTriosDialogForm;

const useStyles = makeStyles(theme => ({
  dialogContent: {
    textAlign: "center",
    margin: "0px 50px",
  },
  flexIcon: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 3,
  },
  contentDialogLinkTrios: {
    whiteSpace: "pre-wrap",
  },
  errorMessage: {
    fontSize: 12,
    color: "red",
    marginBottom: "8px",
  },
}));
