import permissions from "static/permissions.json";

export const PERMISSION_ARRAY = permissions;

export const PERMISSION_ACCESSIBILITY = {
  view: 1,
  edit: 2,
};

export const ROLE = {
  system_admin: 1,
  admin: 2,
  manager: 3,
  staff: 4,
};

export const SHIP_TYPE = {
  FISHING: 1,
  CARGO_VESSEL: 2,
  UNKNOWN: 3,
  PASSENGER_VESSEL: 4,
  MARINE_SHIP: 5,
  TANKER: 8,
  RESERVED_FOR_FUTURE_USE: 10,
  WING_IN_GROUND: 20,
  TOWING: 31,
  DREDGER: 33,
  DIVING: 34,
  MILITARY: 35,
  SAILING: 36,
  PLEASURE_CRAFT: 37,
  RESERVED: 39,
  HIGH_SPEED_CRAFT: 40,
  PILOT: 50,
  SAR: 51,
  TUG: 52,
  TENDER: 53,
  ANTI_POLLUTION_EQUIPMENT: 54,
  LAW_ENFORCEMENT: 55,
  SPARE_LOCAL_VESSEL: 56,
  MEDICAL: 58,
  NAVIGATION_AID: 99,
};

export const SHIP_STATUS = {
  INACTIVE: 0,
  ACTIVE: 1,
  LOST_CONNECTION: 2,
  NEAR_BOUNDARY: 3,
  OUT_SIDE_BOUNDARY: 4,
  LOST_CONNECTION_NEAR: 5,
};

export const LIMIT_PAGING_DEFAULT = 20;

export const KML_MIME_TYPE = "vnd.google-earth.kml+xml";

export const KMZ_MIME_TYPE = "octet-stream";

export const SYSTEM_MODE = {
  WAN: 0,
  INTERNET: 1,
};

export const FLAG_SEND_TO_SGW = 1;

export const USER_ACTION = {
  ALL: 0,
  LOGIN: 1,
  LOGOUT: 2,
  CHANGE_PASSWORD: 3,
  TURN_ON_2_FACTOR_AUTHENTICATION: 4,
  TURN_OFF_2_FACTOR_AUTHENTICATION: 5,
  CREATE_FOLDER: 6,
  CREATE_REPORT: 7,
  SHARE_FILE: 8,
  SHARE_INFORMATION: 9,
  DELETE_DATA: 10,
};

export const STATUS_ACTION = {
  FAIL: 0,
  SUCCESS: 1,
};

export const TYPE_ACTION_WARNING = {
  CHANGE_DESTINATION: {
    id: 1,
    name: "Thay đổi điểm đến hành trình",
  },
  CHANGE_DEPARTURE: {
    id: 2,
    name: "Thay đổi điểm đi hành trình",
  },
  CHANGE_STATUS: {
    id: 3,
    name: "Thay đổi trạng thái",
  },
  CHANGE_ETA: {
    id: 4,
    name: "Thay đổi ETA",
  },
  NEAR_BY_ANOTHER_SHIP: {
    id: 5,
    name: "Đang ở gần tàu khác",
  },
  CHANGE_SHIP_DETAIL: {
    id: 6,
    name: "Thay đổi thông tin tàu",
  },
  CHANGE_SPEED: {
    id: 7,
    name: "Thay đổi về vận tốc",
  },
  CHANGE_COURSE: {
    id: 8,
    name: "Thay đổi về hướng",
  },
  CHANGE_DRAFT: {
    id: 9,
    name: "Thay đổi về mớn nước",
  },
  MOVE_IN_AREA: {
    id: 10,
    name: "Di chuyển vào khu vực",
  },
  MOVE_OUT_AREA: {
    id: 11,
    name: "Di chuyển ra khu vực",
  },
};

export const TYPE_NOTIFICATION = {
  NOTIFY_TYPE_WARNING: 0,
  NOTIFY_TYPE_NOTIFICATION: 1,
};

export const IS_EXPORT = {
  FALSE: 0,
  TRUE: 1,
};

export const RECEIVE_NOTIFY_OPTION = {
  OFF_RECEIVE_NOTIFICATION: 0,
  ON_RECEIVE_NOTIFICATION: 1,
};
