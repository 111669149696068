import React, { Fragment, useEffect, useState, useMemo } from "react";
import ReportActions from "redux-store/report.redux";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  DialogActions,
} from "@mui/material";
import { FormatConstant, LangConstant, AppConstant } from "const";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import CloseIcon from "@mui/icons-material/Close";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useDispatch, useSelector } from "react-redux";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { deepCloneJsonObject } from "utils";
import { getStartOfDay, getEndOfDay, getTimestamp } from "utils/date.utils";
import StatisticalDetail from "./StatisticalDetail";
import getCountries from "react-select-country-list";

export default function StatisticByArea({ onClose, open }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const id = "statistic-by-area-container";

  const customAreaList = useSelector(state => state.areaRedux.customAreaList);
  const defaultAreaList = useSelector(state => state.areaRedux.defaultAreaList);
  const areaReportData = useSelector(state => state.reportRedux.areaReportData);
  const isFetchingReport = useSelector(state => state.reportRedux.isFetchingReport);
  const countries = useMemo(getCountries, []);
  const shipTypes = useSelector(state => state.shipRedux.shipTypes);

  const [areaList, setAreaList] = useState([]);
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedShip, setSelectedShip] = useState(null);
  const [pagination, setPagination] = useState({ total: 1, page: 1 });
  const [reportData, setReportData] = useState({});
  const [isInit, setIsInit] = useState(true);
  const [datetime, setDatetime] = useState({
    start: getStartOfDay() * 1000,
    end: getEndOfDay() * 1000,
  });

  const [selectedCountry, setSelectedCountry] = useState(AppConstant.ALL_VALUE);
  const [selectedShipType, setSelectedShipType] = useState(AppConstant.DEFAULT_SHIP_TYPE.type);

  const onChangeAreaValue = e => {
    let area = areaList.find(a => a.uid === e.target.value);
    setSelectedArea(area);
  };

  const onChangeStartDay = value => {
    setDatetime(state => ({ ...state, start: getTimestamp(value) * 1000 }));
  };

  const onChangeEndDay = value => {
    setDatetime(state => ({ ...state, end: getTimestamp(value) * 1000 }));
  };

  const onSubmit = e => {
    setPagination(state => ({ ...state, page: 1 }));
    setIsInit(false);
    dispatch(ReportActions.reportSet({ areaReportData: {} }));
    dispatch(
      ReportActions.getReportByType({
        area: selectedArea,
        start: datetime.start,
        end: datetime.end,
        countryCode: selectedCountry,
        shipType: selectedShipType,
        isExport: 0,
      }),
    );
  };

  const onExportFile = e => {
    dispatch(
      ReportActions.getReportByType({
        area: selectedArea,
        start: datetime.start,
        end: datetime.end,
        countryCode: selectedCountry,
        shipType: selectedShipType,
        isExport: 1,
        fileName: "thong_ke_so_lan_vao_ra_khu_vuc.xlsx",
      }),
    );
  };

  const onChangePage = (e, value) => {
    setPagination(state => ({ ...state, page: value }));
  };

  const onShowDetailReport = data => {
    setSelectedShip(deepCloneJsonObject(data));
  };

  const onCloseDetailReport = () => {
    setSelectedShip(null);
  };

  const onCloseDialog = () => {
    dispatch(ReportActions.reportSet({ areaReportData: {} }));
    setPagination(state => ({ ...state, page: 1 }));
    setIsInit(true);
    onClose();
  };

  const onChangeCountry = e => {
    setSelectedCountry(e.target.value);
  };

  const onChangeShipType = e => {
    const value = parseInt(e.target.value);
    const selectedShipType =
      value === -1 ? AppConstant.DEFAULT_SHIP_TYPE : shipTypes.find(shipType => shipType.type === value);
    setSelectedShipType(selectedShipType.type);
  };

  useEffect(() => {
    let totalAreaList = defaultAreaList
      .concat(customAreaList.filter(item => defaultAreaList.map(a => a.uid).indexOf(item.uid) < 0))
      .filter(s => s.coordinate_list !== null && s.coordinate_list.length > 0);
    setAreaList(totalAreaList);
  }, [customAreaList, defaultAreaList]);

  useEffect(() => {
    if (areaList.length > 0) {
      setSelectedArea(deepCloneJsonObject(areaList[0]));
    }
  }, [areaList]);

  useEffect(() => {
    let reportDataArray = Object.values(areaReportData);
    let totalPages = Math.ceil(reportDataArray.length / PAGE_SIZE);
    let reportDataPagination = {};

    for (let i = 0; i < totalPages; i++) {
      reportDataPagination[i + 1] = reportDataArray.slice(i * PAGE_SIZE, (i + 1) * PAGE_SIZE);
    }

    setReportData(reportDataPagination);
    setPagination(state => ({ ...state, total: totalPages }));
  }, [areaReportData]);

  const onMinimize = event => {
    event.preventDefault();
    event.stopPropagation();

    let parentContainer = document.getElementById(id);
    if (parentContainer) {
      parentContainer.style.transitionDuration = "500ms";
      parentContainer.style.transform = 'scale(0.3)';
      parentContainer.style.bottom = "-160px";
      parentContainer.style.right = "-70%";

      let childContainer = parentContainer.children;
      if (childContainer) {
        childContainer[0].style.display = "none";
      }

      setTimeout(() => {
        parentContainer.style.removeProperty("transition-duration");
      }, 506);
    }
    // dragElement(id);
  };

  const onMaximize = () => {
    let parentContainer = document.getElementById(id);
    if (parentContainer) {
      parentContainer.style.transitionDuration = "500ms";
      parentContainer.style.transform = 'scale(1)';
      parentContainer.style.top = "0px";
      parentContainer.style.bottom = "0px";
      parentContainer.style.right = "0px";
      parentContainer.style.left = "0px";

      let childContainer = parentContainer.children;
      if (childContainer) {
        childContainer[0].style.display = "flex";
      }

      setTimeout(() => {
        parentContainer.style.removeProperty("transition-duration");
      }, 506);
    }
  };

  return (
    <Fragment>
      <Dialog open={open} maxWidth="lg" fullWidth onClose={onCloseDialog} id={id}>
        <DialogTitle>
          <Box className={classes.statisticByAreaTitleBox}>
            <Typography variant="caption" className={classes.statisticByAreaTitle}>
              {LangConstant.TXT_STATISTICAL_AREA_TITLE}
            </Typography>
            <IconButton size="medium" onClick={onCloseDialog}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent style={{ overflow: "hidden" }} onClick={onMaximize}>
          <DialogContent style={{ padding: 0 }}>
            <Box className={classes.areaChooseContainer}>
              {selectedArea && (
                <select value={selectedArea.uid} onChange={onChangeAreaValue} className={classes.areaSelector}>
                  {areaList.map((item, index) => (
                    <option key={index} value={item.uid}>
                      {item.name}
                    </option>
                  ))}
                </select>
              )}
              <FormControl sx={{ minWidth: 150 }}>
                <InputLabel id="ship-type-select">Loại tàu</InputLabel>
                <Select
                  size="small"
                  labelId="ship-type-select"
                  value={selectedShipType}
                  label="Loại tàu"
                  onChange={onChangeShipType}
                >
                  <MenuItem value={AppConstant.DEFAULT_SHIP_TYPE.type}>{AppConstant.DEFAULT_SHIP_TYPE.name}</MenuItem>
                  {shipTypes.map(shipType => (
                    <MenuItem value={shipType.type} key={shipType.uid}>
                      {shipType.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 150 }}>
                <InputLabel id="country-select">Quốc tịch</InputLabel>
                <Select
                  size="small"
                  labelId="country-select"
                  value={selectedCountry}
                  label="Quốc tịch"
                  onChange={onChangeCountry}
                >
                  <MenuItem value={AppConstant.ALL_VALUE}>Tất cả</MenuItem>
                  {countries.data.map(country => (
                    <MenuItem key={country.value} value={country.value}>
                      {country.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label={LangConstant.L_FROM_DAY}
                  value={datetime.start}
                  onChange={onChangeStartDay}
                  renderInput={params => <TextField size="small" {...params} />}
                  className={classes.dateTimePickerBox}
                  inputFormat={FormatConstant.FM_DD_MM_YYY_HH_MM}
                />
                <DateTimePicker
                  label={LangConstant.L_TO_DAY}
                  value={datetime.end}
                  onChange={onChangeEndDay}
                  renderInput={params => <TextField size="small" {...params} />}
                  className={classes.dateTimePickerBox}
                  inputFormat={FormatConstant.FM_DD_MM_YYY_HH_MM}
                />
              </LocalizationProvider>
              <Box className={classes.showStatisticButtonBox}>
                <Button variant="contained" onClick={onSubmit} className={classes.submitButton} disableElevation>
                  {LangConstant.TXT_SHOW_STATISTIC}
                </Button>
              </Box>
            </Box>
            <TableContainer sx={{ height: 400 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{LangConstant.TXT_STT}</TableCell>
                    <TableCell align="center">{LangConstant.TXT_SHIP_NAME}</TableCell>
                    <TableCell align="center">{LangConstant.TXT_IMO}</TableCell>
                    <TableCell align="center">{LangConstant.TXT_MMSI}</TableCell>
                    <TableCell align="center">{LangConstant.TXT_SHIP_TYPE}</TableCell>
                    <TableCell align="center">{LangConstant.TXT_NATIONALITY}</TableCell>
                    <TableCell align="center">{LangConstant.TXT_TIMES_GO_OUT_AREA}</TableCell>
                    <TableCell align="center">{LangConstant.TXT_TIMES_GO_TO_AREA}</TableCell>
                  </TableRow>
                </TableHead>
                {reportData[pagination.page] && reportData[pagination.page].length > 0 ? (
                  <TableBody>
                    {reportData[pagination.page].map((data, index) => (
                      <TableRow
                        key={data.ship_uid}
                        onClick={() => onShowDetailReport(data)}
                        className={classes.tableRow}
                      >
                        <TableCell align="center">{(pagination.page - 1) * PAGE_SIZE + index + 1}</TableCell>
                        <TableCell align="center">{data.ship_name}</TableCell>
                        <TableCell align="center">{data.IMO}</TableCell>
                        <TableCell align="center">{data.mmsi_code}</TableCell>
                        <TableCell align="center">{data.ship_type === "Unknown" ? "" : data.ship_type}</TableCell>
                        <TableCell align="center">{data.country_code === "Unknown" ? "" : data.country_code}</TableCell>
                        <TableCell align="center">{data.number_out}</TableCell>
                        <TableCell align="center">{data.number_in}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      {isInit ? (
                        <TableCell colSpan={10} style={{ textAlign: "center", color: "#999999" }}>
                          Nhấn xem thống kê để tải dữ liệu
                        </TableCell>
                      ) : isFetchingReport ? (
                        <TableCell colSpan={10} style={{ textAlign: "center", color: "green" }}>
                          Đang thống kê số liệu
                        </TableCell>
                      ) : (
                        <TableCell colSpan={10} style={{ textAlign: "center", color: "#999999" }}>
                          Không có dữ liệu
                        </TableCell>
                      )}
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Pagination
                style={{ marginTop: 16 }}
                onChange={onChangePage}
                count={pagination.total}
                page={pagination.page}
                variant="outlined"
                color="primary"
              />
            </Box>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Box display="flex" marginRight={"16px"} className={classes.boxCont}>
            <Button
              variant="contained"
              onClick={onExportFile}
              color="primary"
              disableElevation
              className={classes.exportFileBtn}
              disabled={pagination.total === 0}
            >
              Xuất báo cáo
            </Button>
            <Button variant="contained" onClick={onMinimize}>
              Thu nhỏ
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <StatisticalDetail
        open={Boolean(selectedShip)}
        data={selectedShip}
        onClose={onCloseDetailReport}
        timestamp={datetime}
      />
    </Fragment>
  );
}

const PAGE_SIZE = 10;

const useStyles = makeStyles(theme => ({
  container: {
    width: 1200,
    display: "block",
    border: "1px solid #000",
    cursor: "pointer",
    zIndex: 100,
    background: "#fff",
    padding: "0 20px 12px",
    minHeight: 400,
  },

  openDragDropElem: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "2px 2px 4px 0px #999999",
    borderRadius: 4,
    zIndex: 100,
  },

  closeDragDropElem: {
    display: "none",
  },

  statisticByAreaTitleBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  statisticByAreaTitle: {
    fontSize: 20,
    fontWeight: 500,
  },

  areaChooseContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },

  areaSelectBox: {
    width: 240,
    marginLeft: 20,
  },

  areaChooseBox: {
    display: "flex",
    alignItems: "center",
    width: 200,
  },

  showStatisticButtonBox: {
    margin: "20px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  shipName: {
    color: "#1D83C3",
    textDecoration: "underline",
    cursor: "pointer",
  },

  dateTimePickerBox: {
    margin: "0 4px",
  },

  areaSelector: {
    padding: 10,
    border: "1px solid #c4c4c4",
    borderRadius: 4,
    fontSize: 14,
    outline: "none",
    marginRight: 4,
  },

  submitButton: {},

  tableRow: {
    cursor: "pointer",
  },
  boxCont: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 8,
  },
}));
