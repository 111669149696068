import React, { useEffect, useRef } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

const LazyScroller = ({
  onScrollToTop,
  onScrollToBottom,
  children,
  className,
  initOnBottom,
  scrollToChildId,
  ...otherProps
}) => {
  const classes = useStyles();
  const scrollerRef = useRef();

  const onComponentScroll = e => {
    let element = e.target;
    if (element.scrollTop === 0) {
      onComponentScrollTop().then(element => {
        if (element) {
          element.scrollTop = 1;
        }
      });
    }

    if (element.scrollTop + element.clientHeight >= element.scrollHeight) {
      onScrollToBottom();
    }
  };

  const onComponentScrollTop = () => {
    return new Promise((resolve, reject) => {
      try {
        onScrollToTop();
        resolve(scrollerRef.current);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  };

  useEffect(() => {
    if (scrollerRef.current) {
      let element = scrollerRef.current;

      let timeout;
      if (initOnBottom) {
        timeout = setTimeout(() => {
          element.scrollTop = element.scrollHeight;
        }, 1);
      }

      element.addEventListener("scroll", onComponentScroll);

      return () => {
        element.removeEventListener("scroll", onComponentScroll);
        clearTimeout(timeout);
      };
    }
  }, [scrollerRef]);

  useEffect(() => {
    let timeout;
    if (scrollToChildId) {
      let element = document.getElementById(scrollToChildId);
      if (element) {
        timeout = setTimeout(() => {
          element.scrollIntoView({ block: "center" });
        }, 1);
      }
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [scrollToChildId]);

  return (
    <Box className={clsx(classes.infiniteScrollRoot, className)} ref={scrollerRef} {...otherProps}>
      {children}
    </Box>
  );
};

export default LazyScroller;

LazyScroller.propTypes = {
  onScrollToTop: PropTypes.func,
  onScrollToBottom: PropTypes.func,

  className: PropTypes.string,

  initOnBottom: PropTypes.bool,
};

LazyScroller.defaultProps = {
  onScrollToTop: () => {},
  onScrollToBottom: () => {},

  className: "",

  initOnBottom: false,
};

const useStyles = makeStyles(theme => ({
  infiniteScrollRoot: {
    overflowY: "auto",
  },
}));
