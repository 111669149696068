import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { LangConstant } from "const";

const FileUploadNotiDialog = props => {
  const { open, onClose } = props;

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={onClose}>
      <DialogTitle>{LangConstant.TXT_NOTIFICATON}</DialogTitle>
      <DialogContent style={{ paddingTop: 10 }}>{LangConstant.TXT_FOLDER_FILE_ERROR}</DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button variant="contained" onClick={onClose}>
          {LangConstant.TXT_UNDERSTOOD}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileUploadNotiDialog;
