import { memo } from "react";
import { makeStyles } from "@mui/styles";
import { convertMillisecondsToDateTime } from "utils/date.utils";
import { useMapContext } from "hooks";
import { getPositionOfPopup } from "utils";

const LayerPointInfoTooltip = () => {
  const { hoverPoint } = useMapContext();

  const classes = useStyles();

  const { top, left, bottom, right } = hoverPoint.event
    ? getPositionOfPopup(hoverPoint.event)
    : { top: 0, left: 0, bottom: 0, right: 0 };
  return (
    <section
      className={Boolean(hoverPoint.info) ? classes.pointPopUpHover : classes.pointPopUpHoverClose}
      style={{ top: top, left: left, bottom: bottom, right: right }}
    >
      <p className={classes.pointPopUpHoverContent} style={{ marginBottom: "4px" }}>
        <strong>{hoverPoint?.info?.name}</strong>
      </p>
      {hoverPoint?.info?.description && (
        <>
          <p className={classes.pointPopUpHoverContent}>
            Mô tả: <strong> {hoverPoint?.info?.description}</strong>
          </p>
        </>
      )}
      <p className={classes.pointPopUpHoverContent}>
        Thời gian tạo: <strong>{convertMillisecondsToDateTime(hoverPoint?.info?.created)}</strong>
      </p>
    </section>
  );
};

export default memo(LayerPointInfoTooltip);

const useStyles = makeStyles(() => ({
  pointPopUpHover: {
    width: "fit-content",
    height: "fit-content",
    backgroundColor: "rgba(255, 255, 255, 0.95)",
    position: "fixed",
    display: "block",
    cursor: "default",
    zIndex: 1000,
    borderRadius: 2,
    boxSizing: "border-box",
    fontFamily: "Roboto Condensed",
    padding: 5,
    lineHeight: 0.7,
  },

  pointPopUpHoverClose: {
    display: "none",
  },

  pointPopUpHoverContent: {
    fontFamily: "Arial",
    lineHeight: 1.3,
    margin: 0,
    fontSize: "13.5px",
  },
}));
