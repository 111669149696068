import { useNavigate } from "react-router-dom";
import { useLoading, useNotification } from "hooks";
import { PermissionService } from "services";
import { PathConstant } from "const";
import { toSnake } from "utils";

export const useUpdatePermission = () => {
  const loading = useLoading();
  const notification = useNotification();
  const navigate = useNavigate();

  const deletePermissionGroup = async uid => {
    try {
      loading.open();
      const response = await PermissionService.deletePermissionGroup(uid);
      loading.close();
      return response.ok;
    } catch (error) {
      loading.close();
      console.error(error);
      return false;
    }
  };

  const updatePermissionGroup = async payload => {
    try {
      loading.open();
      const response = await PermissionService.updatePermissionGroup(toSnake(payload));
      loading.close();
      if (response.ok) {
        notification.send("Chỉnh sửa dữ liệu thành công", "success");
        navigate(PathConstant.ADMIN_PERMISSION_LIST);
      } else {
        notification.send("Đã có lỗi xảy ra, vui lòng thử lại sau", "error");
      }
    } catch (error) {
      loading.close();
      notification.send("Đã có lỗi xảy ra, vui lòng thử lại sau", "error");
      console.error(error);
    }
  };

  return { deletePermissionGroup, updatePermissionGroup };
};
