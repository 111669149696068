import React, { useState, useEffect } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";

const ColorSketchPicker = props => {
  const { defaultColor, onChangeComplete, stylesPopover } = props;
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState(defaultColor || "#fff");
  useEffect(() => {
    setColor(defaultColor || "#000");
  }, [defaultColor]);
  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = color => {
    setColor(color.hex);
  };

  const handleComplete = color => {
    onChangeComplete(color.hex);
  };

  const styles = reactCSS({
    default: {
      container: {
        height: "28px",
        position: "relative",
      },
      color: {
        width: "30px",
        height: "18px",
        borderRadius: "2px",
        // background: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`,
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.15)",
        display: "inline-block",
        cursor: "pointer",
        // transform: "translate(2px, 2px)",
        marginLeft: "4px",
        height: 28,
      },
      popover: {
        position: "absolute",
        zIndex: 1000,
        ...stylesPopover,
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  return (
    <div style={styles.container}>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={{ ...styles.color, background: `${color}` }} />
      </div>
      {displayColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <SketchPicker color={color} onChange={handleChange} onChangeComplete={handleComplete} disableAlpha={true} />
        </div>
      ) : null}
    </div>
  );
};

export default ColorSketchPicker;
