import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppConstant } from "const";
import ShipActions from "redux-store/ship.redux";
import SliderCapacity from "./SliderCapacity";

export default function FilterByCapacity({ data = AppConstant.SHIP_CAPACITY }) {
  const dispatch = useDispatch();

  const shipConditions = useSelector(state => state.shipRedux.shipConditions);
  const [dataCapacity, setDataCapacity] = useState(data);

  const onChangeData = (min, max) => {
    dispatch(
      ShipActions.shipSet({
        isChangingFilter: true,
        shipConditions: Object.assign(shipConditions, { capacity_min: min, capacity_max: max }),
      }),
    );
  };

  useEffect(() => {
    setDataCapacity({
      min: shipConditions.capacity_min,
      max: shipConditions.capacity_max,
    });
  }, [shipConditions.capacity_min, shipConditions.capacity_max]);

  return <SliderCapacity onChange={onChangeData} data={dataCapacity} />;
}
