import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TableHistory from "./TableHistory";
import { Publish } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import ConversationRedux from "redux-store/conversation.redux";
import Cookies from "js-cookie";
import Tabhistory from "./Tabhistory";
import { hasRoleScopes } from "utils";
import { AppConstant, LangConstant } from "const";
import { handleSendNotiInSaga } from "utils";

const History = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const uploadFileSuccess = useSelector(state => state.conversationRedux.uploadFileSuccess);

  const [valueFile, setValueFile] = useState();
  const [valueInput] = useState();

  const onChangeFile = event => {
    setValueFile(event.target.files[0]);
  };

  const onClickFile = () => {
    dispatch(ConversationRedux.uploadFile(valueFile));
    handleSendNotiInSaga(LangConstant.MS_WAITING_UPLOAD_FILE, "success");
  };

  useEffect(() => {
    if (uploadFileSuccess) {
      const file = Cookies.get("uidExportAll");
      dispatch(ConversationRedux.importFile({ uid: file }));
      dispatch(ConversationRedux.conversationReset());
    }
  }, [uploadFileSuccess]);

  return (
    <Box className={classes.container}>
      <TableHistory valueInput={valueInput} />
      {hasRoleScopes(AppConstant.SCOPES.EXPORT_DATA) && <Tabhistory />}
      {hasRoleScopes(AppConstant.SCOPES.IMPORT_DATA) && (
        <>
          <Typography variant="h6">Nhập dữ liệu</Typography>
          <Box className={classes.paddingButtonFile}>
            <Box className={classes.buttonBarr}>
              <Button color="warning" variant="contained" endIcon={<Publish />} component="label">
                Upload File
                <input onChange={onChangeFile} type="file" hidden />
              </Button>
            </Box>
            <Button
              onClick={onClickFile}
              disabled={Boolean(valueFile ? false : true)}
              color="warning"
              variant="contained"
            >
              Gửi file
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default History;

const useStyles = makeStyles(theme => ({
  container: {
    padding: 30,
  },
  radioButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paddingButtonFile: {
    display: "flex",
    paddingBottom: 10,
    paddingTop: 10,
  },
  buttonBarr: {
    padding: "0 10px",
  },
  buttonFlex: {
    padding: "5px 0px",
    width: 400,
    margin: "auto",
  },
}));
