import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Add } from "@mui/icons-material/";
import { makeStyles } from "@mui/styles";
import ConversationActions from "redux-store/conversation.redux";
import ShipActions from "redux-store/ship.redux";
import { handleSendNotiInSaga } from "utils";
import { LangConstant } from "const";
import LayerCreateGroupMonitoringDialog from "./LayerCreateGroupMonitoringDialog";

const LayerAddTrackingShipDialog = ({ isOpenAddShipToGroup, setIsOpenAddShipToGroup }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const trackingShip = useSelector(state => state.shipRedux.trackingShip);
  const groupList = useSelector(state => state.conversationRedux.getGroupsucsess);
  const addGroupShip = useSelector(state => state.conversationRedux.addGroupShip);
  const errCreateGroupShips = useSelector(state => state.conversationRedux.errCreateGroupShips);

  const [selectedShipGroup, setSelectedShipGroup] = useState("");
  const [monitoringReason, setMonitoringReason] = useState("");

  const [creatGroupMonitoring, setCreatGroupMonitoring] = useState(false);

  const onClickAddShipToGroup = ship => {
    dispatch(
      ConversationActions.updateGroupShip({
        uid: selectedShipGroup,
        ship_note: monitoringReason,
        ships: [ship.ship_uid],
      }),
    );
  };

  useEffect(() => {
    if (addGroupShip && isOpenAddShipToGroup) {
      setIsOpenAddShipToGroup(false);
      setMonitoringReason("");
      handleSendNotiInSaga(LangConstant.MS_ADD_SHIP_TO_GROUP_SUCCESS, "success");
      dispatch(ShipActions.requestGetTrackingShips());
      dispatch(
        ConversationActions.conversationSet({
          addGroupShip: false,
        }),
      );
    }

    if (errCreateGroupShips) {
      handleSendNotiInSaga(LangConstant.MS_ADD_SHIP_TO_GROUP_ERROR, "error");
    }
  }, [addGroupShip, errCreateGroupShips]);

  return (
    <>
      <Dialog open={isOpenAddShipToGroup} onClose={() => setIsOpenAddShipToGroup(false)} maxWidth="xs" fullWidth>
        <DialogTitle>Thêm vào danh sách giám sát</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FormControl fullWidth size="small" sx={{ margin: "8px 0" }}>
              <InputLabel id="shipGroupList">Danh sách giám sát</InputLabel>
              <Select
                label="Danh sách giám sát"
                onChange={e => {
                  setSelectedShipGroup(e.target.value);
                }}
                value={selectedShipGroup}
              >
                <MenuItem disabled value={""}></MenuItem>
                {groupList.map(item => (
                  <MenuItem key={item.uid} value={item.uid}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box className={classes.btnAdd} onClick={() => setCreatGroupMonitoring(true)}>
              <Add fontSize="medium" sx={{ color: "cornflowerblue" }} />
            </Box>
          </Box>
          <Box className={classes.paddingInput}>
            <TextField
              onChange={e => setMonitoringReason(e.target.value)}
              fullWidth
              value={monitoringReason}
              size="small"
              placeholder="Lý do giám sát "
              variant="outlined"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setIsOpenAddShipToGroup(false)}>
            Hủy
          </Button>
          <Button variant="contained" disableElevation onClick={() => onClickAddShipToGroup(trackingShip)}>
            Lưu
          </Button>
        </DialogActions>
      </Dialog>

      <LayerCreateGroupMonitoringDialog
        isOpenCreateGroup={creatGroupMonitoring}
        setIsOpenCreateGroup={setCreatGroupMonitoring}
      />
    </>
  );
};

export default memo(LayerAddTrackingShipDialog);

const useStyles = makeStyles(() => ({
  container: {
    position: "fixed",
    top: 80,
    right: 8,
    width: 300,
    backgroundColor: "#fff",
    padding: 8,
    borderRadius: 2,
    zIndex: 1000,
    cursor: "auto",
    boxShadow:
      "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
  },

  shipPopup: {
    width: "100%",
    minWidth: 300,
  },

  popupDesc: {
    width: 85,
    padding: "2px 4px",
    fontSize: "14px",
    lineHeight: "18px",
    "& ~ td": {
      padding: "2px 4px",
      fontSize: "14px",
      lineHeight: "22px",
    },
  },

  popupTable: {
    border: "1px solid #999999",
    borderCollapse: "collapse",
    width: "100%",
    marginBottom: 8,
    "& th": {
      border: "1px solid #999999",
      borderCollapse: "collapse",
    },
    "& td": {
      border: "1px solid #999999",
      borderCollapse: "collapse",
    },
    "& tr": {
      border: "1px solid #999999",
      borderCollapse: "collapse",
    },
  },

  popupTitle: {
    margin: "8px 0 !important",
    fontWeight: 600,
    cursor: "move",
  },

  buttonWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 4,
  },

  shipPopupButton: {
    flex: 1,
    height: 34,
    minWidth: "unset",
  },

  closeButton: {
    "& > svg": {
      fontSize: 14,
    },
  },

  loadingBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "40px",
  },

  btnAdd: {
    marginLeft: "10px",
    width: "42px",
    height: "40px",
    border: "2px solid cornflowerblue",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    cursor: "pointer",
  },
}));
