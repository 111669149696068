import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "react-otp-input";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import AuthRedux from "redux-store/auth.redux";
import { useLoading } from "hooks";
import { AppConstant, PathConstant } from "const";
import { loginOtpErrorMessage } from "utils";

const OtpDialog = ({ username, password }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const loading = useLoading();

  const loginOtpStatus = useSelector(state => state.authRedux.loginOtpStatus);

  const [otp, setOtp] = useState("");
  const [errMessageOtp, setErrMessageOtp] = useState("");
  const [seconds, setSeconds] = useState(AppConstant.OTP_MAX_TIME);
  const time = useRef(null);

  const onFormSubmit = e => {
    loading.open();
    e.preventDefault();
    dispatch(
      AuthRedux.verifyOtp({
        username: username,
        password: password,
        otpCode: otp,
      }),
    );
  };

  const onOtpSend = () => {
    loading.open();
    dispatch(
      AuthRedux.sendOtp({
        username: username,
        password: password,
      }),
    );
  };

  const onClose = () => {
    dispatch(
      AuthRedux.authSet({
        loginType: AppConstant.LOGIN_TYPE.normal,
      }),
    );
    setErrMessageOtp("");
  };

  useEffect(() => {
    if (
      loginOtpStatus.status.verifyOtp === AppConstant.API_STATUS.error ||
      loginOtpStatus.status.sendOtp === AppConstant.API_STATUS.error
    ) {
      setErrMessageOtp(loginOtpErrorMessage(loginOtpStatus.message));
    } else if (loginOtpStatus.status.verifyOtp === AppConstant.API_STATUS.success) {
      dispatch(
        AuthRedux.authSet({
          loginOtpStatus: {
            status: {
              verifyOtp: AppConstant.API_STATUS.nothing,
            },
            message: {
              messageError: AppConstant.OTP_ERROR_TYPE.nothing,
              turn: AppConstant.OTP_RETRY_LIMIT,
            },
          },
        }),
      );
      navigate(PathConstant.HOME);
    } else if (loginOtpStatus.status.sendOtp === AppConstant.API_STATUS.success) {
      clearTimeout(time.current);
      setSeconds(AppConstant.OTP_MAX_TIME);
      setErrMessageOtp("");
      dispatch(
        AuthRedux.authSet({
          loginOtpStatus: {
            status: {
              sendOtp: AppConstant.API_STATUS.nothing,
            },
            message: {
              messageError: AppConstant.OTP_ERROR_TYPE.nothing,
              turn: AppConstant.OTP_RETRY_LIMIT,
            },
          },
        }),
      );
    }

    loading.close();
  }, [loginOtpStatus.status]);

  useEffect(() => {
    if (seconds > 0) {
      time.current = setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setErrMessageOtp("Mã xác thực hết hạn, vui lòng chọn Gửi lại OTP");
    }
  }, [seconds]);

  return (
    <Dialog open onClose={onClose}>
      <form className={classes.formLogin} onSubmit={onFormSubmit}>
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h5" fontWeight={600} mt={2}>
            Phần mềm giám sát
          </Typography>
          <Typography>Đăng nhập hệ thống</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography variant="h4" fontWeight={400} mt={2}>
            Nhập Smart OTP
          </Typography>
          <Typography>Chúng tôi đã gửi Smart OTP đến địa chỉ email {username} của bạn</Typography>
          <Box className={classes.otpInput}>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              inputStyle={{ padding: 5, margin: 10, width: 40 }}
              renderInput={props => <input {...props} />}
            />
          </Box>
          <Box className={classes.errorMessage}>{errMessageOtp}</Box>
          <Box className={classes.timeoutBox}>
            {loginOtpStatus.message.messageError === 2 ? (
              <Typography></Typography>
            ) : (
              <Typography>00:{("0" + seconds).slice(-2)}</Typography>
            )}
            <Button className={classes.sendOtp} onClick={onOtpSend} disabled={seconds > 0}>
              Gửi lại OTP
            </Button>
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button fullWidth variant="contained" type="submit" disabled={otp.length < 6}>
            Xác nhận
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default OtpDialog;

const useStyles = makeStyles(theme => ({
  dialogContent: {
    textAlign: "center",
    margin: "0px 50px",
  },

  otpInput: {
    display: "flex",
    justifyContent: "space-evenly",
  },

  errorMessage: {
    fontSize: 12,
    color: "red",
  },

  timeoutBox: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 8,
  },

  dialogActions: {
    padding: "30px 60px",
  },

  sendOtp: {
    color: "#00327E",
    fontWeight: 500,
  },
}));
