import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { LangConstant } from "const";
import { useDispatch } from "react-redux";
import FolderAction from "redux-store/folder.redux";
import StringFormat from "string-format";

const ConfirmDeleteDialog = props => {
  const { open, onClose, data } = props;
  const dispatch = useDispatch();

  const onClickButtonDelete = () => {
    dispatch(
      FolderAction.deleteFolder({
        uid: data.uid,
      }),
    );
    onClose();
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={onClose}>
      <DialogTitle>{LangConstant.TXT_CONFIRM_DELETE_FOLDER}</DialogTitle>
      <DialogContent style={{ paddingTop: 10 }}>
        {data && StringFormat(LangConstant.FM_CONFIRM_DELETE, data.name)}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClickButtonDelete}>
          {LangConstant.TXT_BUTTON_AGREE}
        </Button>
        <Button onClick={onClose}>{LangConstant.TXT_BUTTON_CANCEL}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteDialog;
