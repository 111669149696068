import { API_CONFIG_REG, createApiWithToken } from "services/config/api.config";
import { ApiConstant } from "const";

export const loginPageSaga = data => createApiWithToken().post(ApiConstant.LOGIN_PAGE, data);

export const sendOtpSaga = data => createApiWithToken().post(ApiConstant.SEND_OTP, data);

export const verifyOtpSaga = data => createApiWithToken().post(ApiConstant.VERIFY_OTP, data);

export const loginTriosSaga = data =>
  createApiWithToken().post(ApiConstant.LOGIN_TRIOS, data, { headers: ApiConstant.API_HEADER_TEXT });

export const linkTriosSaga = data =>
  createApiWithToken(API_CONFIG_REG, data.token).post(ApiConstant.LINK_TRIOS, data.data, {
    headers: ApiConstant.API_HEADER_TEXT,
  });
export const linkTriosSubmitSaga = data => createApiWithToken().post(ApiConstant.LINK_TRIOS, data);

export const refreshTokenSaga = data => createApiWithToken().post(ApiConstant.REFRESH_TOKEN, data);

export const logout = () => createApiWithToken().post(ApiConstant.LOGOUT);
