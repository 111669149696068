import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { Add, Edit, RemoveRedEye, Search } from "@mui/icons-material/";
import CreateAddShip from "./CreateAddShip";
import ViewShipManagement from "pages/AdminDashboard/ShipManagement/ViewShipManagement";
import { useEffect } from "react";
import ConversationRedux from "redux-store/conversation.redux";
import { useDispatch, useSelector } from "react-redux";
import { getContry, hasRoleScopes, toCamel } from "utils";
import { useRef } from "react";
import countryList from "react-select-country-list";
import { useMemo } from "react";
import EditShipManagents from "./EditShipManagents";
import PopupConfirmDelete from "./PopupConfirmDelete";
import { SCOPES } from "const/app.const";
import { LangConstant } from "const";
import { handleSendNotiInSaga } from "utils";

function createData(
  stt,
  ships,
  name,
  shipTypeName,
  idShip,
  imoShip,
  mmsiShip,
  chants,
  supplier,
  nationality,
  manipulation,
) {
  return { stt, ships, name, shipTypeName, idShip, imoShip, mmsiShip, chants, supplier, nationality, manipulation };
}

const TableShipManagement = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const options = useMemo(() => countryList().getData(), []);
  const getListShip = useSelector(state => state.conversationRedux.getListShip);
  const getTypeShip = useSelector(state => state.conversationRedux.getTypeShip);
  const sucessCreateShip = useSelector(state => state.conversationRedux.sucessCreateShip);
  const deleteListShip = useSelector(state => state.conversationRedux.deleteListShip);
  const addGroupShip = useSelector(state => state.conversationRedux.addGroupShip);
  const panigation1 = useSelector(state => state.conversationRedux.pagination);
  const totalPageListShip = useSelector(state => state.conversationRedux.totalPageListShip);
  const updateShip = useSelector(state => state.conversationRedux.updateShip);
  const groupList = useSelector(state => state.conversationRedux.getGroupsucsess);

  const [ships, setShips] = useState("");
  const [nationality, setNationality] = useState("");
  const [isShowBackDrop, setisShowBackDrop] = useState(false);
  const [addCreateShip, setAddCreateShip] = useState(false);
  const [popupEditShips, setPopupEditShips] = useState(false);
  const [viewShipManagement, setViewShipManagement] = useState(false);
  const [addListShip, setAddListShip] = useState(false);
  const [checkedCheckbox, setCheckedCheckbox] = useState([]);
  const [searchShip, setSearchShip] = useState("");
  const [rowTable, setRowTable] = useState([]);
  const [pagination, setPagination] = useState(1);
  const typingTimeout = useRef(null);
  const [monitoringReason, setMonitoringReason] = useState("");
  const [confirmDeleteMany, setConfirmDeleteMany] = useState(false);
  const [dataConfirm, setDataConfirm] = useState();
  const [selectedShipGroup, setSelectedShipGroup] = useState(null);

  const onChangeNationality = event => {
    setNationality(event.target.value);
  };

  const onClickAddShip = () => {
    setAddCreateShip(true);
  };

  const onClickAddListShip = () => {
    dispatch(
      ConversationRedux.updateGroupShip({
        uid: selectedShipGroup,
        ship_note: monitoringReason,
        ships: [...checkedCheckbox],
      }),
    );
  };

  const onClickDeleteMuch = () => {
    setConfirmDeleteMany(true);
  };

  const onChangePage = (e, value) => {
    setPagination(value);
  };

  const onChangeSearch = e => {
    const value = e.target.value;
    if (typingTimeout.current) {
      clearTimeout(typingTimeout.current);
    }
    typingTimeout.current = setTimeout(() => {
      setSearchShip(value);
    }, 500);
  };

  const onClickCheckbox = id => {
    setCheckedCheckbox(pre => {
      const isChecked = checkedCheckbox.includes(id);
      if (isChecked) {
        return checkedCheckbox.filter(item => item !== id);
      } else {
        setCheckedCheckbox(pre => [...pre, id]);
      }
    });
  };

  const onClickEdit = itemContry => {
    setPopupEditShips(true);
    dispatch(
      ConversationRedux.getDetailsGroup({
        uid: itemContry.uid,
      }),
    );
  };

  const onClickView = itemContry => {
    dispatch(
      ConversationRedux.getDetailsGroup({
        uid: itemContry.uid,
      }),
    );
    setViewShipManagement(true);
  };

  useEffect(() => {
    setisShowBackDrop(true);
    dispatch(
      ConversationRedux.getListShip({
        country_code: nationality,
        filter: searchShip,
        ship_type: ships,
        page: pagination,
        size: 20,
      }),
    );

    dispatch(ConversationRedux.getGroupShip({}));
  }, [searchShip, ships, sucessCreateShip, pagination, nationality, deleteListShip, addGroupShip, updateShip]);

  useEffect(() => {
    dispatch(ConversationRedux.getShipType());
    setCheckedCheckbox([]);
  }, [sucessCreateShip, ships, getListShip]);

  useEffect(() => {
    if (getListShip.length >= 0) {
      setisShowBackDrop(false);
    }
  }, [getListShip]);

  useEffect(() => {
    if (addGroupShip) {
      setAddListShip(false);
      setMonitoringReason("");
      handleSendNotiInSaga(LangConstant.MS_ADD_SHIP_TO_GROUP_SUCCESS, "success");
      dispatch(
        ConversationRedux.conversationSet({
          addGroupShip: false,
        }),
      );
    }
  }, [addGroupShip]);

  useEffect(() => {
    if (getListShip) {
      let result = [];
      const toCamelConvert = toCamel(getListShip);
      toCamelConvert?.map(itemContry => {
        return result.push(
          createData(
            itemContry && itemContry.uid !== null ? itemContry?.uid : " ",
            itemContry && itemContry.shipType !== null ? itemContry.shipType : "",
            itemContry && itemContry.shipName !== null ? itemContry.shipName : "",
            itemContry && itemContry.shipTypeName !== null ? itemContry.shipTypeName : "",
            itemContry && itemContry.uid !== null ? itemContry.uid : "",
            itemContry && itemContry.imo !== null ? itemContry.imo : "",
            itemContry && itemContry.mmsiCode !== null ? itemContry.mmsiCode : "",
            itemContry && itemContry.callSign !== null ? itemContry.callSign : "",
            itemContry && itemContry.supplier !== null ? itemContry.supplier : "",
            itemContry && itemContry.countryCode !== null
              ? itemContry.countryCode === "Unknown"
                ? "Không xác định"
                : getContry(options, itemContry.countryCode).map(item => item.label)
              : "",
            <React.Fragment>
              {hasRoleScopes(SCOPES.SHIP_UPDATE) && (
                <IconButton onClick={() => onClickEdit(itemContry)}>
                  <Edit />
                </IconButton>
              )}
              {hasRoleScopes(SCOPES.SHIP_DETAIL) && (
                <IconButton onClick={() => onClickView(itemContry)}>
                  <RemoveRedEye />
                </IconButton>
              )}
            </React.Fragment>,
          ),
        );
      });
      setRowTable(result);
    }
  }, [getListShip]);

  useEffect(() => {
    if (deleteListShip) setCheckedCheckbox([]);
    dispatch(
      ConversationRedux.conversationSet({
        deleteListShip: false,
      }),
    );
  }, [deleteListShip]);

  return (
    <>
      <Box className={classes.searchShip}>
        <TextField
          fullWidth
          onChange={onChangeSearch}
          placeholder="Tìm kiếm tàu"
          size="small"
          label="Tìm kiếm tàu"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box className={classes.flexFillter}>
        {hasRoleScopes(SCOPES.SHIP_CREATE) && (
          <Button
            style={{ marginRight: 10 }}
            onClick={onClickAddShip}
            endIcon={<Add />}
            variant="contained"
            color="warning"
          >
            Thêm tàu
          </Button>
        )}
        <Button
          disabled={checkedCheckbox.length > 0 ? false : true}
          style={{ marginRight: 10 }}
          onClick={() => setAddListShip(true)}
          variant="contained"
          color="warning"
        >
          Thêm vào danh sách giám sát
        </Button>
        {hasRoleScopes(SCOPES.SHIP_DELETE) && (
          <Button
            onClick={onClickDeleteMuch}
            variant="contained"
            color="warning"
            disabled={checkedCheckbox.length > 0 ? false : true}
          >
            Xoá tàu
          </Button>
        )}
      </Box>
      <Box className={classes.flexFillter}>
        <Box className={classes.paddingSelect}>
          <FormControl size="small" fullWidth>
            <InputLabel id="demo-simple-select-label">Quốc tịch</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={nationality}
              label="Quốc tịch"
              onChange={onChangeNationality}
            >
              <MenuItem value="">Tất cả</MenuItem>
              {options &&
                options.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <Box className={classes.containerSelect}>
          <FormControl size="small" fullWidth>
            <InputLabel id="demo-simple-select-label">Chọn loại tàu</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={ships}
              label="Chọn loại tàu"
              onChange={e => setShips(e.target.value)}
            >
              <MenuItem value="">Tất cả</MenuItem>
              {getTypeShip &&
                getTypeShip.map((item, index) => (
                  <MenuItem key={index} value={item.type}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <TableContainer sx={{ height: 600, overflow: "auto" }} component={Paper}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="50"></TableCell>
              <TableCell width="50">STT</TableCell>
              <TableCell align="center">Loại tàu</TableCell>
              <TableCell align="center">Tên tàu</TableCell>
              <TableCell align="center">Số IM0</TableCell>
              <TableCell align="center">Số MMSI</TableCell>
              <TableCell align="center">Hô hiệu</TableCell>
              <TableCell align="center">Nhà cung cấp</TableCell>
              <TableCell align="center">Quốc tịch</TableCell>
              <TableCell align="center">Thao tác</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowTable &&
              rowTable.map((row, index) => (
                <TableRow key={row.stt} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell>
                    <Checkbox
                      checked={checkedCheckbox.includes(row.stt)}
                      onClick={() => {
                        onClickCheckbox(row.stt);
                        setDataConfirm(row);
                      }}
                      color="warning"
                    />
                  </TableCell>
                  <TableCell align="center">{(pagination - 1) * panigation1 + index + 1}</TableCell>
                  <TableCell align="center">{row.shipTypeName}</TableCell>
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">{row.imoShip}</TableCell>
                  <TableCell align="center">{row.mmsiShip}</TableCell>
                  <TableCell align="center">{row.chants}</TableCell>
                  <TableCell align="center">{row.supplier}</TableCell>
                  <TableCell align="center">{row.nationality}</TableCell>
                  <TableCell align="center">{row.manipulation}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className={classes.pagination}>
        <Pagination
          onChange={onChangePage}
          sx={{ paddingTop: 2 }}
          page={pagination}
          count={Number(totalPageListShip)}
          shape="rounded"
        />
      </Box>
      {addCreateShip && <CreateAddShip open={true} onClose={() => setAddCreateShip(false)} />}
      <EditShipManagents open={Boolean(popupEditShips)} onClose={() => setPopupEditShips(false)} />
      <ViewShipManagement open={viewShipManagement} onClose={() => setViewShipManagement(false)} />
      {addListShip && (
        <Dialog open={addListShip} onClose={() => setAddListShip(false)} maxWidth="xs" fullWidth>
          <DialogTitle>Thêm vào danh sách giám sát</DialogTitle>
          <DialogContent>
            <FormControl fullWidth size="small" className={classes.shipGroupInput}>
              <InputLabel id="shipGroupList">Danh sách giám sát</InputLabel>
              <Select
                label="Danh sách giám sát"
                onChange={e => {
                  setSelectedShipGroup(e.target.value);
                }}
                value={selectedShipGroup}
              >
                {groupList.map(item => (
                  <MenuItem value={item.uid}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box className={classes.paddingInput}>
              <TextField
                onChange={e => setMonitoringReason(e.target.value)}
                fullWidth
                value={monitoringReason}
                size="small"
                placeholder="Lý do giám sát "
                variant="outlined"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClickAddListShip}>Lưu</Button>
            <Button onClick={() => setAddListShip(false)}>Hủy</Button>
          </DialogActions>
        </Dialog>
      )}
      <PopupConfirmDelete
        data={dataConfirm}
        resetData={() => setDataConfirm(null)}
        checked={checkedCheckbox}
        open={confirmDeleteMany}
        onClose={() => setConfirmDeleteMany(false)}
        setCheckedCheckbox={() => setCheckedCheckbox([])}
      />
      <Backdrop sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }} open={isShowBackDrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default TableShipManagement;

const useStyles = makeStyles(theme => ({
  containerSelect: {
    width: 200,
  },

  buttonAddPadding: {
    paddingRight: 10,
  },

  flexFillter: {
    display: "flex",
    alignItems: "center",
    padding: "5px 0",
  },

  paddingSelect: {
    paddingRight: 10,
    width: 200,
  },

  searchShip: {
    margin: "auto",
    width: 400,
    marginBottom: "10px",
  },

  inputShips: {
    padding: "10px 10px",
  },

  pagination: {
    paddingTop: 20,
  },

  paddingInput: {
    padding: "10px 0",
  },

  shipGroupInput: {
    marginBottom: 10,
  },
}));
