/* eslint-disable no-useless-escape */
import { EventBus } from "EventBus";
import { AppConstant, LangConstant, EventConstant, PathConstant, SystemConstant } from "const";
import Cookies from "js-cookie";

export const IS_ADMIN = () => {
  const output = [SystemConstant.ROLE.system_admin, SystemConstant.ROLE.admin].includes(
    parseInt(Cookies.get(AppConstant.KEY_ROLE_ID)),
  );

  return output;
};

export const hasLogin = () => Boolean(Cookies.get(AppConstant.KEY_ACCESS_TOKEN));
export const hasRoleScopes = scope =>
  IS_ADMIN() || Boolean(Cookies.get(AppConstant.KEY_SCOPES)?.split(",")?.includes(scope));

export const handlingLogin = data => {
  Cookies.set(AppConstant.KEY_ACCESS_TOKEN, data.access_token, { expires: 30 });
  Cookies.set(AppConstant.KEY_REFRESH_TOKEN, data.refresh_token, { expires: 30 });
  Cookies.set(AppConstant.KEY_ROLE_ID, data.role, { expires: 30 });
  Cookies.set(AppConstant.KEY_USER_ID, data.user_id, { expires: 30 });
  Cookies.set(AppConstant.KEY_USER_NAME, data.name, { expires: 30 });
  Cookies.set(AppConstant.KEY_SCOPES, data.scopes.toString(), { expires: 30 });
};

export const uploadFileSuccess = data => {
  Cookies.set(AppConstant.KEY_UID_UPLOAD, data);
};
export const regEx =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const flattenObject = ob => {
  let tmpObj = {};

  for (let i in ob) {
    if (!ob.hasOwnProperty(i)) continue;
    if (typeof ob[i] == "object" && ob[i] !== null) {
      let flatObject = flattenObject(ob[i]);
      for (let x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;
        tmpObj[x] = flatObject[x];
      }
    } else {
      tmpObj[i] = ob[i];
    }
  }
  return tmpObj;
};

export const toCamel = obj => {
  var newObj, origKey, newKey, value;
  if (obj instanceof Array) {
    return obj.map(function (value) {
      if (typeof value === "object") {
        value = toCamel(value);
      }
      return value;
    });
  } else {
    newObj = {};
    for (origKey in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, origKey)) {
        newKey = snakeToCamelCase(origKey);
        value = obj[origKey];
        if (value instanceof Array || (value && value.constructor === Object)) {
          value = toCamel(value);
        }
        newObj[newKey] = value;
      }
    }
  }
  return newObj;
};

export const snakeToCamelCase = str =>
  str.toLowerCase().replace(/([-_][a-z])/g, group => group.toUpperCase().replace("-", "").replace("_", ""));

export const getContry = (memberArray, search = "VN") => {
  let filterItems = [];
  if (memberArray && memberArray.length > 0) {
    filterItems = memberArray.filter(member => member.value === search);
  }
  return filterItems;
};

export const dragElement = id => {
  var elmnt = document.getElementById(id);
  var pos1 = 0,
    pos2 = 0,
    pos3 = 0,
    pos4 = 0;
  if (document.getElementById(elmnt.id + "header")) {
    document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
    elmnt.onmousedown = function (e) {
      e = e || window.event;
      if (!e.target.closest(".sketch-picker")) {
        e.stopPropagation();
      }
    };
    elmnt.addEventListener("wheel", function (e) {
      e.stopPropagation();
    });
    elmnt.addEventListener("dblclick", function (e) {
      e.stopPropagation();
    });
  } else {
    elmnt.onmousedown = dragMouseDown;
  }

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    e.stopPropagation();
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    e.stopPropagation();
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    elmnt.style.top = elmnt.offsetTop - pos2 + "px";
    elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
  }

  function closeDragElement() {
    document.onmouseup = null;
    document.onmousemove = null;
  }
};

export const getFilterArray = (memberArray, search) => {
  let filterItems = [];
  if (memberArray && memberArray.length > 0) {
    filterItems = memberArray.filter(member => textNormalize(member.ship_name).includes(textNormalize(search)));
  }
  return filterItems;
};

export const textNormalize = str => {
  return removeVietnameseTones(str).toLowerCase();
};

export const removeVietnameseTones = str => {
  if (!str) return "";
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, " ");
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
  return str;
};

export const toSnake = obj => {
  var newObj, origKey, newKey, value;
  if (obj instanceof Array) {
    return obj.map(function (value) {
      if (typeof value === "object") {
        value = toSnake(value);
      }
      return value;
    });
  } else {
    newObj = {};
    for (origKey in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, origKey)) {
        newKey = camelToSnakeCase(origKey);
        value = obj[origKey];
        if (value instanceof Array || (value && value.constructor === Object)) {
          value = toSnake(value);
        }
        newObj[newKey] = value;
      }
    }
  }
  return newObj;
};

export const camelToSnakeCase = str =>
  (str.charAt(0).toLowerCase() + str.slice(1) || str).replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const fillterArrayDetail = (arr = [], search, nationality = "") => {
  let resultFilter = [];
  if (arr.length > 0 && arr) {
    resultFilter = arr.filter(item => item.name === search);
    return resultFilter;
  }
  if (nationality) {
    getContry();
  }
};

export const filter = (arrOld, seacrch = "", countryLis = "", ship = "") => {
  let resultFilter = [];
  if (seacrch.length > 0) {
    resultFilter = arrOld.filter(item => item.name === seacrch);
    return resultFilter;
  } else if (countryLis.length > 0) {
    resultFilter = arrOld.filter(item => item.name === countryLis);
    return resultFilter;
  } else if (ship.length > 0) {
    resultFilter = arrOld.filter(item => item.name === ship);
    return resultFilter;
  }
};

export const removeDuplicateInArray = arr => {
  let tmpArray = new Set(arr);
  arr = [...tmpArray];
  return arr;
};

export const getDegFrom2PointsObj = (start, end) => {
  return (Math.atan2(end.long - start.long, end.lat - start.lat) * 180) / Math.PI;
};

export const getDegFrom2PointsArray = (start, end) => {
  return (Math.atan2(end[1] - start[1], end[0] - start[0]) * 180) / Math.PI;
};

export const getDegFrom2PointsLatLong = (startLat = 0, startLong = 0, endLat = 0, endLong = 0) => {
  return (Math.atan2(endLong - startLong, endLat - startLat) * 180) / Math.PI;
};

/**
 * Converts decimal degrees to degrees minutes seconds.
 *
 * @param dd the decimal degrees value.
 * @param isLng specifies whether the decimal degrees value is a longitude.
 * @return degrees minutes seconds string in the format 49°15'51.35"N
 */
export const convertToDms = (dd, isLng) => {
  let dir = dd < 0 ? (isLng ? "W" : "S") : isLng ? "E" : "N";

  let absDd = Math.abs(dd);
  let deg = absDd | 0;
  let frac = absDd - deg;
  let min = (frac * 60) | 0;
  let sec = frac * 3600 - min * 60;
  // Round it to 2 decimal points.
  sec = Math.round(sec * 100) / 100;

  return deg + "°" + min + "'" + sec + '"' + dir;
};

export const getDistanceFromLatLongInKm = (lat1, lon1, lat2, lon2) => {
  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  let R = 6371; // Radius of the earth in km
  let dLat = deg2rad(lat2 - lat1); // deg2rad below
  let dLon = deg2rad(lon2 - lon1);
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c; // Distance in km

  return d;
};

export const getDistanceFromLatLongInKmByObj = (start, end) => {
  let startLong = start.long || start.lng;
  let endLong = end.long || end.lng;
  return getDistanceFromLatLongInKm(start.lat, startLong, end.lat, endLong);
};

export const getDistanceFromLatLongInNauticalMilesByObj = (start, end) => {
  return getDistanceFromLatLongInKmByObj(start, end) * 0.53996;
};

/**
 *
 * @param {LatLngBounds} bounds
 */
export const get4PointsFromLatLongBounds = bounds => {
  return {
    northWest: bounds.getNorthWest(),
    northEast: bounds.getNorthEast(),
    southEast: bounds.getSouthEast(),
    southWest: bounds.getSouthWest(),
  };
};

/**
 *
 * @param {LatLngBounds} bounds
 */
export const getPointsFromLatLongBounds = bounds => {
  if (!bounds)
    return {
      from_lat: 0.703107,
      from_long: 23.926013,
      to_lat: 96.240234,
      to_long: 120.629883,
    };

  let northEast = bounds.getNorthEast();
  let southWest = bounds.getSouthWest();

  return {
    from_lat: southWest.lat,
    from_long: southWest.lng,
    to_lat: northEast.lat,
    to_long: northEast.lng,
  };
};

export const isInsideBoundary = (point, bounds) => {
  let northEast = bounds.getNorthEast();
  let southWest = bounds.getSouthWest();
  return (
    point.lat < northEast.lat && point.lat > southWest.lat && point.lng < northEast.lng && point.lng > southWest.lng
  );
};

export const convertDegToLatLongDirection = deg => {
  if (deg === null || deg === undefined) return "Không xác định";

  return Number((deg + 180).toFixed(4)) + "°";
};

export const deepCloneJsonObject = json => {
  if (!json) return null;
  return JSON.parse(JSON.stringify(json));
};

export const getCenterPointFrom2Points = (startPoint, endPoint) => {
  let centerLat = (parseFloat(startPoint.lat) + parseFloat(endPoint.lat)) / 2;
  let centerLng = (parseFloat(startPoint.lng) + parseFloat(endPoint.lng)) / 2;

  return { lat: centerLat, lng: centerLng };
};

export const getListCenterPointsInPolyline = polylinePoints => {
  let totalPointLength = polylinePoints.length;
  let results = polylinePoints.reduce((arr, point, index, originArray) => {
    let nextIndex = index + 1;
    if (nextIndex === totalPointLength) return arr;
    let nextPoint = originArray[nextIndex];
    let centerPoint = getCenterPointFrom2Points(point, nextPoint);
    let distanceInMiles = getDistanceFromLatLongInNauticalMilesByObj(point, nextPoint);
    let distanceInKm = getDistanceFromLatLongInKmByObj(point, nextPoint);
    arr.push({ ...centerPoint, miles: distanceInMiles, km: distanceInKm });
    return arr;
  }, []);

  return results;
};

export const getPositionOfPopup = event => {
  console.log("event", event);
  let results = { top: 0, left: 0, bottom: 0, right: 0 };
  let offsetHeight = document.body.offsetHeight;
  let offsetWidth = document.body.offsetWidth;
  let ox1 = event.pageX;
  let ox2 = offsetWidth - ox1;
  let oy1 = event.pageY;
  let oy2 = offsetHeight - oy1;
  let ox1y1 = { name: "ox1y1", square: ox1 * oy1, axis: { ox1, ox2, oy1, oy2 } };
  let ox2y1 = { name: "ox2y1", square: ox2 * oy1, axis: { ox1, ox2, oy1, oy2 } };
  let ox2y2 = { name: "ox2y2", square: ox2 * oy2, axis: { ox1, ox2, oy1, oy2 } };
  let ox1y2 = { name: "ox1y2", square: ox1 * oy2, axis: { ox1, ox2, oy1, oy2 } };
  let axisList = [ox1y1, ox2y1, ox2y2, ox1y2];
  let max = Math.max(...axisList.map(axis => axis.square));
  let screen = axisList.find(axis => axis.square === max);
  switch (screen.name) {
    case "ox1y1":
      results = {
        top: "unset",
        left: "unset",
        bottom: oy2 + 2 + "px",
        right: ox2 + 2 + "px",
      };
      break;
    case "ox2y1":
      results = {
        top: "unset",
        left: ox1 + 2 + "px",
        bottom: oy2 + 2 + "px",
        right: "unset",
      };
      break;
    case "ox2y2":
      results = {
        top: oy1 + 2 + "px",
        left: ox1 + 2 + "px",
        bottom: "unset",
        right: "unset",
      };
      break;
    case "ox1y2":
      results = {
        top: oy1 + 2 + "px",
        left: "unset",
        bottom: "unset",
        right: ox2 + 2 + "px",
      };
      break;

    default:
      break;
  }

  return results;
};

export const splitBoundsTo6Parts = bounds => {
  let result = [];
  let { northEast, southWest } = get4PointsFromLatLongBounds(bounds);
  let latRange = (Math.abs(northEast.lat) - Math.abs(southWest.lat)) / 3;
  let lngRange = (Math.abs(northEast.lng) - Math.abs(southWest.lng)) / 2;
  for (let h = 0; h < 2; h++) {
    let latMax = southWest.lat + (h + 1) * latRange;
    let latMin = southWest.lat + h * latRange;
    for (let v = 0; v < 3; v++) {
      let lngMax = southWest.lng + (v + 1) * lngRange;
      let lngMin = southWest.lng + v * lngRange;
      result.push({ from_lat: latMin, from_long: lngMin, to_lat: latMax, to_long: lngMax });
    }
  }

  return result;
};

export const convertFileSizeUnit = (size = 0) => {
  let result = "";

  if (size >= 1000 * 1000 * 1000) {
    let gb = (size / (1000 * 1000 * 1000)).toFixed(3);
    result = `${gb} GB`;
  } else if (size >= 1000 * 1000) {
    let mb = (size / (1000 * 1000)).toFixed(3);
    result = `${mb} MB`;
  } else if (size >= 1000) {
    let kb = (size / 1000).toFixed(3);
    result = `${kb} KB`;
  } else if (size > 0) {
    result = `${size} byte`;
  } else {
    result = "0 byte";
  }

  return result;
};

export const downloadBlob = (blob, name = "file.txt") => {
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = blobUrl;
  link.download = name;
  link.target = "_blank";
  document.body.appendChild(link);
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );
  document.body.removeChild(link);
};

export const convertNauticalMileToKm = miles => {
  return parseFloat(miles) * 1.852;
};

export const convertKmToNm = km => {
  return (parseFloat(km) / 1.852).toFixed(2);
};

export const convertJson2Xlsx = async (json, fileName) => {
  const worksheet = window.xlsx_module.utils.json_to_sheet(json);
  const workbook = window.xlsx_module.utils.book_new();
  window.xlsx_module.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  window.xlsx_module.writeFile(workbook, fileName + ".xlsx");
};

export const toDegreesMinutesAndSeconds = coordinate => {
  var absolute = Math.abs(coordinate);
  var degrees = Math.floor(absolute);
  var minutesNotTruncated = (absolute - degrees) * 60;
  var minutes = Math.floor(minutesNotTruncated);
  var seconds = Math.floor((minutesNotTruncated - minutes) * 60);

  return degrees + "\u00B0" + minutes + "'" + seconds + '"';
};

export const convertCoordinates = (lat, lng) => {
  if (!(lat && lng)) return "";

  lat = Number(lat);
  lng = Number(lng);

  if (isNaN(lat) || isNaN(lng)) return "";

  var latitude = toDegreesMinutesAndSeconds(lat);
  var latitudeCardinal = lat >= 0 ? "N" : "S";

  var longitude = toDegreesMinutesAndSeconds(lng);
  var longitudeCardinal = lng >= 0 ? "E" : "W";

  return latitude + latitudeCardinal + " \n" + longitude + longitudeCardinal;
};

export const convert2Coords = (lat, lng) => {
  if (!(lat && lng)) return "";

  lat = Number(lat);
  lng = Number(lng);

  if (isNaN(lat) || isNaN(lng)) return "";

  var latitude = toDegreesMinutesAndSeconds(lat);
  var latitudeCardinal = lat >= 0 ? "N" : "S";

  var longitude = toDegreesMinutesAndSeconds(lng);
  var longitudeCardinal = lng >= 0 ? "E" : "W";

  return {
    lat: latitude + latitudeCardinal,
    lng: longitude + longitudeCardinal,
  };
};

export const removeUndefinedValueInArray = arr => {
  const filtered = arr.filter(el => el !== null && el !== undefined);
  return filtered;
};

export const onLogout = (withNavigate = true) => {
  Cookies.remove(AppConstant.KEY_ACCESS_TOKEN, { expires: 30 });
  Cookies.remove(AppConstant.KEY_REFRESH_TOKEN, { expires: 30 });
  Cookies.remove(AppConstant.KEY_ROLE_ID, { expires: 30 });
  Cookies.remove(AppConstant.KEY_USER_ID, { expires: 30 });
  Cookies.remove(AppConstant.KEY_TYPE_LOGIN, { expires: 30 });
  Cookies.remove(AppConstant.KEY_USER_NAME, { expires: 30 });
  Cookies.remove(AppConstant.KEY_SCOPES, { expires: 30 });
  if (withNavigate) window.history.navigate(PathConstant.LOGIN_PAGE);
};

export const convertCoordIntToObject = coordinate => {
  var absolute = Math.abs(coordinate);
  var degrees = Math.floor(absolute);
  var minutesNotTruncated = (absolute - degrees) * 60;
  var minutes = Math.floor(minutesNotTruncated);
  var seconds = Math.floor((minutesNotTruncated - minutes) * 60);

  return {
    degrees,
    minutes,
    seconds,
  };
};

export const convertHex2rgba = (hex, alpha = 1) => {
  try {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  } catch (error) {
    return "";
  }
};

export const getRandomColor = () => {
  return "#" + Math.floor(Math.random() * 16777215).toString(16);
};
export const getShipFishingColor = status => {
  switch (status) {
    case SystemConstant.SHIP_STATUS.INACTIVE:
      return "#125675";
    case SystemConstant.SHIP_STATUS.ACTIVE:
      return "#26ae43";
    case SystemConstant.SHIP_STATUS.LOST_CONNECTION:
      return "#999999";
    case SystemConstant.SHIP_STATUS.NEAR_BOUNDARY:
      return "#252771";
    case SystemConstant.SHIP_STATUS.OUT_SIDE_BOUNDARY:
      return "#fda422";
    default:
      return "#89c4fa";
  }
};
export const getShipTypeColor = shipType => {
  switch (shipType) {
    case SystemConstant.SHIP_TYPE.FISHING:
      return "#FE9B00";
    case SystemConstant.SHIP_TYPE.CARGO_VESSEL:
      return "#0499D7";
    case SystemConstant.SHIP_TYPE.PASSENGER_VESSEL:
      return "#0C028A";
    case SystemConstant.SHIP_TYPE.UNKNOWN:
      return "#a5aeab";
    case SystemConstant.SHIP_TYPE.DIVING:
      return "#6bf7f9";
    case SystemConstant.SHIP_TYPE.TOWING:
      return "#caf727";
    case SystemConstant.SHIP_TYPE.TANKER:
      return "#eb1b23";
    case SystemConstant.SHIP_TYPE.PILOT:
      return "#7368ed";
    case SystemConstant.SHIP_TYPE.TUG:
      return "#057d54";
    case SystemConstant.SHIP_TYPE.SAR:
      return "#ff4c04";
    case SystemConstant.SHIP_TYPE.SAILING:
      return "#e2efae";
    case SystemConstant.SHIP_TYPE.TENDER:
      return "#f79683";
    case SystemConstant.SHIP_TYPE.MEDICAL:
      return "#26fff3";
    case SystemConstant.SHIP_TYPE.MILITARY:
      return "#9cbc6f";
    case SystemConstant.SHIP_TYPE.DREDGER:
      return "#9106fd";
    case SystemConstant.SHIP_TYPE.WING_IN_GROUND:
      return "#fe00d2";
    case SystemConstant.SHIP_TYPE.HIGH_SPEED_CRAFT:
      return "#f5fe00";
    case SystemConstant.SHIP_TYPE.MARINE_SHIP:
      return "#384e92";
    case SystemConstant.SHIP_TYPE.PLEASURE_CRAFT:
      return "magenta";
    case SystemConstant.SHIP_TYPE.RESERVED_FOR_FUTURE_USE:
      return "#b4ffb4";
    case SystemConstant.SHIP_TYPE.RESERVED:
      return "#00ff51";
    case SystemConstant.SHIP_TYPE.ANTI_POLLUTION_EQUIPMENT:
      return "#423829";
    case SystemConstant.SHIP_TYPE.LAW_ENFORCEMENT:
      return "#a67acf";
    case SystemConstant.SHIP_TYPE.SPARE_LOCAL_VESSEL:
      return "#38334f";
    default:
      return "orange";
  }
};

export const toDecimalDegree = (hour = 0, min = 0, second = 0) => {
  let min2Decimal = min / 60;
  let sec2Decimal = second / 3600;
  return Number(hour) + Number(min2Decimal) + Number(sec2Decimal);
};

export const onReturnToDefaultMap = currentMap => {
  if (!(currentMap && currentMap.setView)) return;

  currentMap.setView(AppConstant.DEFAULT_POSITION, 6);
};

export const angle = (cx, cy, ex, ey) => {
  var dy = ey - cy;
  var dx = ex - cx;
  var theta = Math.atan2(dy, dx);
  theta *= 180 / Math.PI;
  return theta;
};

export const angle360 = (cx, cy, ex, ey) => {
  var theta = angle(cx, cy, ex, ey);
  if (theta < 0) theta = 360 + theta;
  return Math.ceil(theta) + 24;
};

export const getRandomShipColor = () => {
  const colors = [
    "#FFF78A",
    "#FFE382",
    "#FFC47E",
    "#FFAD84",
    "#FDF7E4",
    "#FAEED1",
    "#DED0B6",
    "#BBAB8C",
    "#E48F45",
    "#F5CCA0",
    "#F05941",
    "#9FBB73",
    "#F1EB90",
    "#F3B664",
    "#EC8F5E",
    "#F8DE22",
    "#F94C10",
    "#FF6666",
    "#FF8989",
    "#FCAEAE",
    "#FFEADD",
  ];

  return colors[Math.floor(Math.random() * colors.length)];
};

export const errorVerifyOtp = message => {
  if (message?.resend_count !== undefined) {
    const errorOtp =
      message.resend_count && message.resend_count <= AppConstant.OTP_RETRY_LIMIT && message.block_time === null;
    return errorOtp ? AppConstant.OTP_ERROR_TYPE.wrongOTP : AppConstant.OTP_ERROR_TYPE.block;
  } else if (message === "You are in block time") {
    return AppConstant.OTP_ERROR_TYPE.block;
  } else if (message === "Your OTP is Expired") {
    return AppConstant.OTP_ERROR_TYPE.otpExpired;
  } else {
    return AppConstant.OTP_ERROR_TYPE.other;
  }
};

export const copyToClipboard = async textToCopy => {
  // Navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(textToCopy);
  } else {
    // Use the 'out of viewport hidden text area' trick
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    // Move textarea out of the viewport so it's not visible
    textArea.style.position = "absolute";
    textArea.style.left = "-999999px";
    document.body.prepend(textArea);
    textArea.select();
    try {
      document.execCommand("copy");
    } catch (error) {
      console.error(error);
    } finally {
      textArea.remove();
    }
  }
};

export const connectTriosMessageResponse = message => {
  let typeError;
  switch (message) {
    case "This account was linked with another account":
      typeError = AppConstant.CONNECT_TRIOS_MESSAGE_TYPE.linked;
      break;
    case "Your trios account is not linked":
      typeError = AppConstant.CONNECT_TRIOS_MESSAGE_TYPE.unlinked;
      break;
    case "Token from Trios is invalid":
      typeError = AppConstant.CONNECT_TRIOS_MESSAGE_TYPE.other;
      break;
    default:
      typeError = AppConstant.CONNECT_TRIOS_MESSAGE_TYPE.nothing;
      break;
  }
  return typeError;
};

export const connectTriosMessageNotice = connectTriosMessage => {
  let messageError;
  switch (connectTriosMessage) {
    case AppConstant.CONNECT_TRIOS_MESSAGE_TYPE.linked:
      messageError = LangConstant.TXT_LINKED_TRIOS;
      break;
    case AppConstant.CONNECT_TRIOS_MESSAGE_TYPE.unlinked:
      messageError = LangConstant.TXT_UNLINKED_TRIOS;
      break;
    case AppConstant.CONNECT_TRIOS_MESSAGE_TYPE.success:
      messageError = LangConstant.TXT_LINK_TRIOS_SUCCES;
      break;
    case AppConstant.CONNECT_TRIOS_MESSAGE_TYPE.other:
      messageError = LangConstant.TXT_ERROR_CONTENT;
      break;
    default:
      messageError = "";
      break;
  }
  return messageError;
};

export const loginOtpErrorMessage = message => {
  let messageError;
  switch (message.messageError) {
    case AppConstant.OTP_ERROR_TYPE.nothing:
      messageError = "";
      break;
    case AppConstant.OTP_ERROR_TYPE.wrongOTP:
      messageError = "Mã xác thực không chính xác, bạn còn " + message.turn + " lần nhập lại";
      break;
    case AppConstant.OTP_ERROR_TYPE.block:
      messageError = "Mã xác thực không chính xác, vui lòng thử lại sau 5 phút";
      break;
    case AppConstant.OTP_ERROR_TYPE.otpExpired:
      messageError = "Mã xác thực hết hạn, vui lòng chọn Gửi lại OTP";
      break;
    default:
      messageError = "Đã xảy ra lỗi, vui lòng thử lại";
      break;
  }
  return messageError;
};

export const handleSendNotiInSaga = (message, type = "success") => {
  const event = new CustomEvent(EventConstant.GLOBAL_EVENTS.appendNotification, { detail: { message, type } });
  window.dispatchEvent(event);
};

export const handleOpenLoading = (open = true) => {
  EventBus.emit(EventConstant.GLOBAL_EVENTS.openLoading, open);
};

export const convertBytes = byteSize => {
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;
  const gigabyte = megabyte * 1024;
  if (byteSize < kilobyte) {
    return byteSize + " B";
  } else if (byteSize < megabyte) {
    return (byteSize / kilobyte).toFixed(2) + " KB";
  } else if (byteSize < gigabyte) {
    return (byteSize / megabyte).toFixed(2) + " MB";
  } else {
    return (byteSize / gigabyte).toFixed(2) + " GB";
  }
};

export const isHiddenByAnotherElement = element => {
  const rect = element.getBoundingClientRect();
  const topElement = document.elementFromPoint((rect.left + rect.right) / 2, (rect.top + rect.bottom) / 2);
  return topElement !== element;
};

export const checkAndSetOpacityPopup = () => {
  const elements = document.querySelectorAll(".leaflet-popup-content");
  elements.forEach(element => {
    if (isHiddenByAnotherElement(element)) {
      element.parentElement.parentElement.style.opacity = 0;
    } else {
      element.parentElement.parentElement.style.opacity = 1;
    }
  });
};
export const metresPerPixel = map => {
  const root = document.documentElement;
  const calculatedValue =
    (40075016.686 * Math.abs(Math.cos((map.getCenter().lat * Math.PI) / 180))) / Math.pow(2, map.getZoom() + 8);
  console.log(calculatedValue / 10, "calculatedValue");
  root.style.setProperty("--metresPerPixel", calculatedValue);
  return calculatedValue;
};
