import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { AppConstant, LangConstant } from "const";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import ConversationRedux from "redux-store/conversation.redux";
import { handleSendNotiInSaga } from "utils";

const AddPortManagement = ({ open, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const getPortManager = useSelector(state => state.conversationRedux.getPortManager);
  const createMorePort = useSelector(state => state.conversationRedux.createMorePort);
  const errLangtitude = useSelector(state => state.conversationRedux.errLangtitude);
  const errLongtitude = useSelector(state => state.conversationRedux.errLongtitude);
  const errorDuplicatedName = useSelector(state => state.conversationRedux.errorDuplicatedName);

  const [selectPortManager, setSelectPortManager] = useState();
  const [errPhoneNumber, setErrPhoneNumber] = useState("");
  const [errSelect, setErrSelect] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [errName, setErrName] = useState();
  const [valueInput, setValueInput] = useState({
    seaportName: "",
    portName: "",
    numberOfBridges: "",
    DWT: "",
    height: "",
    minimumDepth: "",
    maximumFlowDepth: "",
    longStream: "",
    wattage: "",
    warehouseArea: "",
    companyName: "",
    address: "",
    numberFax: "",
    latitude: "",
    longitude: "",
  });

  const onClickAddPort = () => {
    if (!Boolean(valueInput?.portName)) {
      setErrName(LangConstant.TXT_VALIDATE_EMPTY);
    } else {
      setErrName("");
    }

    if (!Boolean(selectPortManager)) {
      setErrSelect(LangConstant.TXT_VALIDATE_EMPTY);
    } else {
      setErrSelect("");
    }

    if (Boolean(valueInput.portName) && Boolean(selectPortManager)) {
      dispatch(
        ConversationRedux.createHarbors({
          address: valueInput.address,
          capacity: +valueInput.warehouseArea,
          company_name: valueInput.companyName,
          country_code: "VN",
          fax: valueInput.numberFax,
          latitude: +valueInput.latitude,
          longitude: +valueInput.longitude,
          name: valueInput.portName,
          phone_number: phoneNumber,
          square: valueInput.warehouseArea,
          power: valueInput.wattage,
          quay_number: +valueInput.numberOfBridges,
          quay_length: +valueInput.height,
          length: +valueInput.longStream,
          depth_max: +valueInput.maximumFlowDepth,
          depth_min: +valueInput.minimumDepth,
          source_type: 4,
          port_uid: selectPortManager,
          status: 0,
        }),
      );
    }
  };

  const onChangeInput = e => {
    setValueInput({ ...valueInput, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (createMorePort) {
      handleSendNotiInSaga(LangConstant.MS_SUCCESS_CREATE_PORT, "success");
      setErrPhoneNumber("");
      onClose();
      setSelectPortManager("");
      dispatch(ConversationRedux.getPortManager());
      dispatch(
        ConversationRedux.conversationSet({
          createMorePort: false,
          errName: false,
        }),
      );
    }
  }, [createMorePort]);

  useEffect(() => {
    if (errorDuplicatedName) {
      dispatch(
        ConversationRedux.conversationSet({
          errorDuplicatedName: null,
        }),
      );

      setErrName("Tên bến cảng đã tồn tại!");
    }
  }, [errorDuplicatedName]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{LangConstant.TXT_ADD_MORE}</DialogTitle>
      <DialogContent>
        <DialogContent>
          <Box className={classes.paddingTopSelect}>
            <Box className={classes.paddingInput}>
              <TextField
                onChange={onChangeInput}
                name="portName"
                size="small"
                fullWidth
                label={LangConstant.L_NAME_MORE_PORT}
                variant="outlined"
              />
              {errName ? (
                <Typography variant="h4" style={{ fontSize: 10, color: "red" }}>
                  {errName}
                </Typography>
              ) : (
                ""
              )}
            </Box>
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-simple-select-label">Chọn cảng biển</InputLabel>
              <Select
                onChange={e => setSelectPortManager(e.target.value)}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label={LangConstant.L_OPTION_MORE}
              >
                {getPortManager &&
                  getPortManager.map(item => (
                    <MenuItem key={item.uid} value={item.uid}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {errSelect ? (
              <Typography variant="h4" style={{ fontSize: 10, color: "red" }}>
                {errSelect}
              </Typography>
            ) : (
              ""
            )}
          </Box>

          <Typography>{LangConstant.TXT_INFORMATION}</Typography>
          <TextField
            onChange={onChangeInput}
            name="numberOfBridges"
            type="number"
            size="small"
            fullWidth
            label={LangConstant.L_NUMBER_OF_DEMAND}
            variant="outlined"
          />
          <Box className={classes.paddingInput}>
            <TextField
              onChange={onChangeInput}
              name="DWT"
              type="number"
              size="small"
              fullWidth
              label={LangConstant.L_DWT}
              variant="outlined"
            />
          </Box>
          <TextField
            onChange={onChangeInput}
            name="height"
            type="number"
            size="small"
            fullWidth
            label={LangConstant.L_HEIGHT}
            variant="outlined"
          />
          <Box className={classes.paddingInput}>
            <TextField
              onChange={onChangeInput}
              type="number"
              size="small"
              fullWidth
              label={LangConstant.L_MINIMUM_DEPTH}
              variant="outlined"
              name="minimumDepth"
            />
          </Box>
          <TextField
            onChange={onChangeInput}
            name="maximumFlowDepth"
            type="number"
            size="small"
            fullWidth
            label={LangConstant.L_MAXMUM_FLOW_DEPTH}
            variant="outlined"
          />
          <Box className={classes.paddingInput}>
            <TextField
              onChange={onChangeInput}
              name="longStream"
              type="number"
              size="small"
              fullWidth
              label={LangConstant.L_LONG_STREAM}
              variant="outlined"
            />
          </Box>
          <TextField
            onChange={onChangeInput}
            name="wattage"
            type="number"
            size="small"
            fullWidth
            label={LangConstant.L_POWER}
            variant="outlined"
          />
          <Box className={classes.paddingInput}>
            <TextField
              onChange={onChangeInput}
              type="number"
              size="small"
              fullWidth
              label={LangConstant.L_SQUARE}
              variant="outlined"
              name="warehouseArea"
            />
          </Box>
          <TextField
            onChange={onChangeInput}
            name="companyName"
            size="small"
            fullWidth
            label={LangConstant.L_COMPANY_NAME}
            variant="outlined"
          />
          <Box className={classes.paddingInput}>
            <TextField
              onChange={onChangeInput}
              name="address"
              size="small"
              fullWidth
              label={LangConstant.L_ADDRESS}
              variant="outlined"
            />
          </Box>
          <Box className={classes.paddingFax}>
            <TextField
              onChange={onChangeInput}
              name="numberFax"
              type="number"
              size="small"
              fullWidth
              label={LangConstant.L_FAX}
              variant="outlined"
            />
          </Box>
          <PhoneInput
            value={phoneNumber}
            onChange={phone => setPhoneNumber(phone)}
            country="vn"
            onlyCountries={AppConstant.COUNTRY}
          />
          {errPhoneNumber ? (
            <Typography variant="h4" style={{ fontSize: 10, color: "red" }}>
              {errPhoneNumber}
            </Typography>
          ) : (
            ""
          )}
          <Box className={classes.paddingInput}>
            <TextField
              onChange={onChangeInput}
              name="longitude"
              type="number"
              size="small"
              fullWidth
              label={LangConstant.L_LONGITUDE}
              variant="outlined"
            />
            {errLongtitude ? (
              <Typography variant="h4" style={{ color: "red", fontSize: 10 }}>
                {errLongtitude}
              </Typography>
            ) : (
              ""
            )}
          </Box>
          <TextField
            onChange={onChangeInput}
            name="latitude"
            type="number"
            size="small"
            fullWidth
            label={LangConstant.L_LANGITUDE}
            variant="outlined"
          />
          {errLangtitude || !errLangtitude ? (
            <Typography variant="h4" style={{ color: "red", fontSize: 10 }}>
              {errLangtitude}
            </Typography>
          ) : (
            ""
          )}
        </DialogContent>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickAddPort}>{LangConstant.TXT_BUTTON_ADD}</Button>
        <Button onClick={onClose}>{LangConstant.TXT_BUTTON_CANCEL}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPortManagement;

const useStyles = makeStyles(theme => ({
  paddingInput: {
    padding: "10px 0",
  },
  paddingTopSelect: {
    paddingTop: 10,
  },
  paddingFax: {
    paddingBottom: 10,
  },
}));
