import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Button, Dialog, Typography } from "@mui/material";
import { ApiConstant, PathConstant } from "const";
import { onLogout } from "utils";
import ConversationActions from "redux-store/conversation.redux";
import { useNotificationQueue } from "hooks";

const TrackingUserActivity = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const status = useSelector(state => state.errorRedux.status);
  const isLoginSuccess = useSelector(state => state.conversationRedux.isLoginSuccess);
  const { setNotificationQueue } = useNotificationQueue();

  const timeout = useRef(null);
  const [isError, setIsError] = useState(false);

  const onClickLogout = () => {
    setNotificationQueue([]);
    onLogout();
    setIsError(false);
    dispatch(ConversationActions.conversationReset());
    dispatch(ConversationActions.conversationSet({ isLoginSuccess: false }));
    navigate(PathConstant.LOGIN_PAGE);
  };

  const handleActivity = () => {
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      dispatch(ConversationActions.conversationReset());
      dispatch(ConversationActions.conversationSet({ isLoginSuccess: false }));
      setIsError(true);
      setNotificationQueue([]);
      onLogout(false);
    }, 1800000); // 1 hour
  };

  useEffect(() => {
    if (timeout.current) clearTimeout(timeout.current);
    if (isLoginSuccess) window.addEventListener("mousemove", handleActivity);

    return () => {
      window.removeEventListener("mousemove", handleActivity);
    };
  }, [isLoginSuccess]);

  useEffect(() => {
    if (status) {
      let isAuthenticate = status === ApiConstant.STT_UNAUTHORIZED;
      if (isAuthenticate) {
        dispatch(ConversationActions.conversationReset());
        dispatch(ConversationActions.conversationSet({ isLoginSuccess: false }));
        setIsError(true);
        onLogout(false);
      }
    }
  }, [status]);

  return (
    isError && (
      <Dialog
        open={isError}
        classes={{
          root: classes.errorContainer,
          paper: classes.errorPaper,
        }}
      >
        <Typography className={classes.errorContent}>Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại</Typography>
        <Button variant="contained" onClick={onClickLogout} className={classes.actionButton}>
          Chấp nhận
        </Button>
      </Dialog>
    )
  );
};

export default TrackingUserActivity;

const useStyles = makeStyles(() => ({
  errorContainer: {
    zIndex: 1600,
  },

  errorPaper: {
    padding: 20,
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  errorContent: {
    fontSize: 20,
    fontWeight: 600,
  },

  actionButton: {
    marginTop: 20,
  },
}));
