import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Box, IconButton, Typography, Switch, FormControlLabel, Button } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useMapContext } from "hooks";
import MonitoringShips from "./components/MonitoringShips";
import ShipActions from "redux-store/ship.redux";
import { removeDuplicateInArray } from "utils";
import LayerCreateGroupMonitoringDialog from "./LayerCreateGroupMonitoringDialog";

const LayerWatchListSettingDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const groupShip = useSelector(state => state.shipRedux.groupShip);
  const shipOnMap = useSelector(state => state.shipRedux.shipOnMap);
  const trackingShips = useSelector(state => state.shipRedux.trackingShips);
  const [creatGroupMonitoring, setCreatGroupMonitoring] = useState(false);

  const { showMenuWatchList, setShowMenuWatchList, areaManagementConfigs, showOtherShip, setShowOtherShip } =
    useMapContext();

  const stopPropagation = e => {
    e.stopPropagation();
  };

  const handleCloseDialog = () => {
    setShowMenuWatchList(false);
  };

  useEffect(() => {
    let groupShipSelected =
      trackingShips
        ?.flatMap(trackingShip =>
          (trackingShip?.selected || [])?.map(ship => {
            const shipItem = groupShip?.find(groupShip => groupShip?.ship_uid === ship);
            if (shipItem) return { ...shipItem, watchListColor: trackingShip?.color };
            return null;
          }),
        )
        .filter(ship => ship !== null)
        .filter((ship, index, self) => index === self.map(ship => ship?.ship_uid).indexOf(ship?.ship_uid)) || [];
    dispatch(
      ShipActions.shipSet({
        filterShipResult: removeDuplicateInArray(groupShipSelected?.concat(showOtherShip ? shipOnMap : [])).filter(
          item => item !== undefined,
        ),
      }),
    );
  }, [showOtherShip, groupShip, shipOnMap, trackingShips]);

  return (
    <>
      <Box
        className={showMenuWatchList ? classes.openDragDropElem : classes.closeDragDropElem}
        onDoubleClick={stopPropagation}
        component="section"
      >
        <Box marginBottom={1} className={classes.wrapper}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            marginBottom={1}
            className={classes.drawWatchListHeader}
          >
            <Typography>Cài đặt danh sách tàu giám sát</Typography>
            <IconButton size="small" onClick={handleCloseDialog} disabled={areaManagementConfigs.status !== "idle"}>
              <Close
                fontSize="small"
                htmlColor={areaManagementConfigs.status === "idle" ? "#fff" : "rgba(0, 0, 0, 0.5)"}
              />
            </IconButton>
          </Box>
          <Box
            paddingX={1}
            paddingTop={1}
            onMouseDown={stopPropagation}
            onMouseMove={stopPropagation}
            onDoubleClick={stopPropagation}
          >
            <FormControlLabel
              control={
                <Switch checked={showOtherShip} onChange={() => setShowOtherShip(!showOtherShip)} name="gilad" />
              }
              label="Hiển thị các tàu khác"
            />
          </Box>
        </Box>
        <Box
          onMouseDown={stopPropagation}
          onMouseMove={stopPropagation}
          onDoubleClick={stopPropagation}
          className={classes.monitoringShipsBox}
        >
          <MonitoringShips />
        </Box>
        <Box
          marginX={1}
          marginY={1}
          onMouseDown={stopPropagation}
          onMouseMove={stopPropagation}
          onDoubleClick={stopPropagation}
        >
          <Button variant="text" onClick={() => setCreatGroupMonitoring(true)}>
            Tạo danh sách giám sát mới
          </Button>
        </Box>
      </Box>
      <LayerCreateGroupMonitoringDialog
        isOpenCreateGroup={creatGroupMonitoring}
        setIsOpenCreateGroup={setCreatGroupMonitoring}
      />
    </>
  );
};

export default memo(LayerWatchListSettingDialog);

const useStyles = makeStyles(() => ({
  openDragDropElem: {
    position: "fixed",
    top: "100px",
    right: "60px",
    width: 344,
    display: "block",
    cursor: "default",
    zIndex: 1000,
    background: "#fff",
    boxSizing: "border-box",
    borderRadius: 4,
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  },

  closeDragDropElem: {
    display: "none",
  },

  header: {
    width: "100%",
    height: 32,
    backgroundColor: "#1d83c3",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "4px 4px 0 0",
  },

  body: {
    padding: "8px",
    maxHeight: 500,
    overflowY: "scroll",
  },

  drawWatchListHeader: {
    backgroundColor: "#1d83c3",
    color: "#ffffff",
    padding: "4px 8px",
    borderRadius: "4px 4px 0 0",
    marginBottom: 0,
  },

  wrapper: {
    borderBottom: "1px solid #999999",
  },
  monitoringShipsBox: {
    width: "100%",
    maxHeight: 500,
    overflow: "auto",
    padding: 2,
  },
}));
