import React, { Fragment, useEffect, useState } from "react";
import { Download, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AppConstant, LangConstant } from "const";
import { useDispatch, useSelector } from "react-redux";
import ShipRedux from "redux-store/ship.redux";
import DialogConfirmRemoveListShip from "./DialogConfirmRemoveListShip";
import { handleOpenLoading } from "utils";
import FilterByNation from "pages/HomePage/Sidebar/Filter/FilterByNation";
import { convertMillisecondsToDateTime } from "utils/date.utils";
import { deepCloneJsonObject, convertCoordinates } from "utils";
import { useMapContext, useFlickerMarker } from "hooks";

const ListShipLostTable = ({ lostType, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const listShipLostData = useSelector(state => state.shipRedux.listShipLostData);
  const getListShipLostStatus = useSelector(state => state.shipRedux.getListShipLostStatus);
  const customAreaList = useSelector(state => state.areaRedux.customAreaList);
  const defaultAreaList = useSelector(state => state.areaRedux.defaultAreaList);
  const shipTypes = useSelector(state => state.shipRedux.shipTypes);
  const filterShipResult = useSelector(state => state.shipRedux.filterShipResult);
  const { map, mapState, flickerLayerRef } = useMapContext();

  const [listShipRemove, setListShipRemove] = useState([]);
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [filterValue, setFilterValue] = useState({
    valueSearch: "",
    shipType: AppConstant.VALUE_ALL,
  });
  const [listShipLostDataWithPage, setListShipLostDataWithPage] = useState([]);
  const [countryCode, setCountryCode] = useState();
  const areaList = defaultAreaList
    .concat(customAreaList.filter(item => defaultAreaList.map(a => a.uid).indexOf(item.uid) < 0))
    .filter(s => s.coordinate_list !== null && s.coordinate_list.length > 0);
  const [selectedArea, setSelectedArea] = useState(deepCloneJsonObject(areaList[0]));
  const [filterDevice, setFilterDevice] = useState(null);
  const [filterCareer, setFilterCareer] = useState(null);

  const paginationListShipWithPage = (listShip, page = AppConstant.FIRST_PAGE, size = AppConstant.PAGE_SIZE) => {
    const start = (page - 1) * size;
    const end = page * size;
    return { ...listShip, page: page, data: listShip?.data?.slice(start, end) };
  };

  useEffect(() => {
    setListShipLostDataWithPage(paginationListShipWithPage(listShipLostData));
  }, [listShipLostData]);

  const onJumpToShipNotification = (event, data) => {
    onClose(event);
    dispatch(ShipRedux.requestFilterShips({ bounds: map.current.getBounds(), selectShip: data?.uid }));
  };

  const onDownloadListShip = () => {
    dispatch(
      ShipRedux.getListShipLost({
        statistic_type: lostType,
        export_f: AppConstant.IS_EXPORT_FILE.yes,
        filter: !filterValue.valueSearch ? undefined : filterValue.valueSearch,
        ship_type: filterValue.shipType === AppConstant.VALUE_ALL ? undefined : filterValue.shipType,
        page: AppConstant.FIRST_PAGE,
        size: AppConstant.PAGE_SIZE,
        country_code: countryCode,
        area_uid: selectedArea?.uid,
      }),
    );
    handleOpenLoading(true);
  };

  const onChangeFilterValue = event => {
    setFilterValue({ ...filterValue, [event.target.name]: event.target.value });
  };

  const onChangeFilterDevice = event => {
    setFilterDevice(event.target.value);
  };

  const onChangeFilterCareer = event => {
    setFilterCareer(event.target.value);
  };

  const onGetListShipByFilter = () => {
    dispatch(
      ShipRedux.getListShipLost({
        statistic_type: lostType,
        export_f: AppConstant.IS_EXPORT_FILE.no,
        filter: !filterValue.valueSearch ? undefined : filterValue.valueSearch,
        ship_type: filterValue.shipType === AppConstant.VALUE_ALL ? undefined : filterValue.shipType,
        page: AppConstant.FIRST_PAGE,
        size: AppConstant.PAGE_SIZE,
        country_code: countryCode,
        area_uid: selectedArea?.uid,
        device_type: filterDevice,
        career: filterCareer,
      }),
    );
    handleOpenLoading(true);
  };

  const onChangeListShipRemove = event => {
    if (event.target.checked) {
      setListShipRemove([...listShipRemove, event.target.value]);
    } else {
      const listShipRemoveTmp = listShipRemove.filter(item => item !== event.target.value);
      setListShipRemove([...listShipRemoveTmp]);
    }
  };

  const onRemoveListShip = () => {
    setOpenDialogConfirm(true);
  };

  const onCloseDialogConfirm = () => {
    setOpenDialogConfirm(false);
  };

  const onChangePage = (event, newPage) => {
    setListShipLostDataWithPage(paginationListShipWithPage(listShipLostData, newPage));
  };

  const onChangeAreaValue = e => {
    let area = areaList.find(a => a.uid === e.target.value);
    setSelectedArea(area);
  };

  useEffect(() => {
    if (
      getListShipLostStatus === AppConstant.API_STATUS.success ||
      getListShipLostStatus === AppConstant.API_STATUS.error
    ) {
      handleOpenLoading(false);
    }
    dispatch(
      ShipRedux.shipSet({
        getListShipLostStatus: AppConstant.API_STATUS.nothing,
      }),
    );
  }, [getListShipLostStatus]);

  return (
    <Fragment>
      <Typography className={classes.titleTable}>
        {lostType === AppConstant.SHIP_LOST_TYPE.disconnected
          ? LangConstant.TXT_SHIP_LIST_LOST
          : LangConstant.TXT_SHIP_LIST_LOST_SIGNAL}
      </Typography>
      <Box className={classes.selectHeaderTable}>
        <Box sx={{ display: "flex" }}>
          <FormControl sx={{ width: 250 }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder={LangConstant.TXT_SEARCH}
              size="small"
              name="valueSearchName"
              label="Biển số tàu"
              onChange={onChangeFilterValue}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          <FormControl sx={{ width: 200, ml: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder={LangConstant.TXT_SEARCH}
              size="small"
              name="valueSearchDevice"
              onChange={onChangeFilterDevice}
              label="Thiết bị"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          <FormControl sx={{ width: 200, ml: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder={LangConstant.TXT_SEARCH}
              size="small"
              name="valueSearchCareer"
              onChange={onChangeFilterCareer}
              label="Nghề"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          <FormControl sx={{ width: 200, ml: 2 }}>
            <InputLabel>{LangConstant.L_CHOOSE_AREA}</InputLabel>
            <Select
              size="small"
              value={selectedArea?.uid}
              name="areaUid"
              label={LangConstant.L_CHOOSE_AREA}
              onChange={onChangeAreaValue}
            >
              {areaList.length > 0 &&
                areaList.map((item, index) => (
                  <MenuItem value={item.uid} key={index}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <Button className={classes.buttonTable} type="submit" variant="contained" onClick={onGetListShipByFilter}>
            {LangConstant.TXT_SEARCH}
          </Button>
        </Box>
        <Box>
          {lostType === AppConstant.SHIP_LOST_TYPE.disconnected && (
            <Button
              className={classes.buttonTable}
              type="submit"
              variant="contained"
              onClick={onRemoveListShip}
              disabled={listShipRemove.length === 0}
            >
              {LangConstant.TXT_UNFOLLOW}
            </Button>
          )}
          <Button className={classes.buttonTable} type="submit" variant="outlined" onClick={onDownloadListShip}>
            <Download className={classes.icon} />
            {LangConstant.TXT_DOWNLOAD}
          </Button>
        </Box>
      </Box>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {lostType === AppConstant.SHIP_LOST_TYPE.disconnected && <TableCell align="center"></TableCell>}
              <TableCell align="center">{LangConstant.TXT_STT}</TableCell>
              <TableCell align="center">{LangConstant.TXT_SHIP_LICENSE_PLATE}</TableCell>
              <TableCell align="center">{LangConstant.TXT_DEVICE}</TableCell>
              <TableCell align="center">{LangConstant.TXT_SHIP_CAREER}</TableCell>
              <TableCell align="center">{LangConstant.TXT_SHIP_LENGTH}</TableCell>
              <TableCell align="center">{LangConstant.TXT_MAIN_FISHING_PORT}</TableCell>
              <TableCell align="center">{LangConstant.TXT_OWNER}</TableCell>
              <TableCell align="center">{LangConstant.TXT_ADDRESS}</TableCell>
              <TableCell align="center">{LangConstant.TXT_NUMBER}</TableCell>
              <TableCell align="center">{LangConstant.TXT_LOCATION_LAST}</TableCell>
              <TableCell align="center">{LangConstant.TXT_LAST_UPDATE_DATA}</TableCell>
              <TableCell align="center">{LangConstant.TXT_NUMBER_HOURS_LOST}</TableCell>
            </TableRow>
          </TableHead>
          {listShipLostData?.data?.length > 0 ? (
            <TableBody>
              {listShipLostDataWithPage?.data?.map((data, index) => (
                <TableRow key={index}>
                  {lostType === AppConstant.SHIP_LOST_TYPE.disconnected && (
                    <TableCell align="center">
                      <Checkbox
                        value={data.ship.uid}
                        onChange={onChangeListShipRemove}
                        checked={listShipRemove.includes(data.ship.uid)}
                      />
                    </TableCell>
                  )}
                  <TableCell align="center">
                    {(listShipLostDataWithPage.page - 1) * AppConstant.PAGE_SIZE + index + 1}
                  </TableCell>
                  <TableCell align="center">{data?.ship?.shipName}</TableCell>
                  <TableCell align="center">{data?.ship?.deviceType}</TableCell>
                  <TableCell align="center">{data?.ship?.career}</TableCell>
                  <TableCell align="center">{data?.ship?.length} (m)</TableCell>
                  <TableCell align="center">{data.port}</TableCell>
                  <TableCell align="center">{data?.shipOwner?.name}</TableCell>
                  <TableCell align="center">{data?.shipOwner?.address}</TableCell>
                  <TableCell align="center">{data?.shipOwner?.phone}</TableCell>
                  <TableCell
                    align="center"
                    onClick={e => onJumpToShipNotification(e, data?.ship)}
                    sx={{ cursor: "pointer", color: "#1976d2", fontWeight: "450" }}
                  >
                    {convertCoordinates(Number(data?.location?.location?.lat), Number(data?.location?.location?.lon))}
                  </TableCell>
                  <TableCell align="center">{convertMillisecondsToDateTime(data?.location?.crawlTime)}</TableCell>
                  <TableCell align="center">
                    {Math.round(Math.abs((Date.now() - data.location.crawlTime) / AppConstant.ONE_HOUR))} (giờ)
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={13}>
                  {LangConstant.TXT_NO_DATA}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="end" alignItems="center" mt={2}>
        <Pagination
          sx={{ mt: 4 }}
          onChange={onChangePage}
          count={listShipLostDataWithPage?.totalPage}
          page={listShipLostDataWithPage?.page || 1}
          variant="outlined"
          color="primary"
        />
      </Box>
      <DialogConfirmRemoveListShip
        open={openDialogConfirm}
        onCloseDialogConfirm={onCloseDialogConfirm}
        listShipRemove={listShipRemove}
        lostType={lostType}
      />
    </Fragment>
  );
};

export default ListShipLostTable;

const useStyles = makeStyles({
  titleTable: {
    fontWeight: 600,
    marginBottom: "24px",
  },

  selectHeaderTable: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 4,
  },

  buttonTable: {
    height: 40,
    marginLeft: "16px",
  },

  icon: {
    marginRight: 5,
  },
});
