import { useState } from "react";
import { Box, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { AppConstant, LangConstant } from "const";
import UserActionHistory from "./UserActionHistory";
import UserActionSummary from "./UserActionSummary";
import { hasRoleScopes } from "utils";

const UserActionManagement = () => {
  const [value, setValue] = useState(
    hasRoleScopes(AppConstant.SCOPES.USER_ACTION_MN_LIST)
      ? AppConstant.USER_ACTION_MN_TAB.HISTORY
      : AppConstant.USER_ACTION_MN_TAB.SUMMARY,
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ typography: "body1" }}>
      <TabContext value={value}>
        <Box>
          <TabList onChange={handleChange}>
            {hasRoleScopes(AppConstant.SCOPES.USER_ACTION_MN_LIST) && (
              <Tab label={LangConstant.TXT_USER_ACTION_HISTORY} value={AppConstant.USER_ACTION_MN_TAB.HISTORY} />
            )}
            {hasRoleScopes(AppConstant.SCOPES.USER_ACTION_SUMMARY_LIST) && (
              <Tab label={LangConstant.TXT_USER_ACTION_SUMMARY} value={AppConstant.USER_ACTION_MN_TAB.SUMMARY} />
            )}
          </TabList>
        </Box>
        <TabPanel value={AppConstant.USER_ACTION_MN_TAB.HISTORY}>
          <UserActionHistory />
        </TabPanel>
        <TabPanel value={AppConstant.USER_ACTION_MN_TAB.SUMMARY}>
          <UserActionSummary />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default UserActionManagement;
