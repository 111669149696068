import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button, Typography } from "@mui/material";

export default function SecondSubMenu({ items }) {
  const classes = useStyles();

  return (
    <Box className={classes.multipleMenuBox}>
      {items.map(item => (
        <Box key={item.id}>
          {item.submenu ? (
            <Button
              variant="outlined"
              startIcon={item.icon}
              fullWidth={true}
              classes={{
                root: classes.button,
                startIcon: classes.startIconBox,
                endIcon: classes.endIconBox,
              }}
            >
              <Typography variant="caption" className={classes.buttonTitle}>
                {item.title}
              </Typography>
            </Button>
          ) : (
            <Button
              variant="outlined"
              startIcon={item.icon}
              fullWidth
              classes={{
                root: classes.button,
                startIcon: classes.startIconBox,
              }}
            >
              <Typography variant="caption" className={classes.buttonTitle}>
                {item.title}
              </Typography>
            </Button>
          )}
        </Box>
      ))}
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  buttonTitle: {
    flex: 2,
    textAlign: "left",
    fontSize: 12,
    fontWeight: "500",
    color: "#000",
  },

  button: {
    justifyContent: "flex-start",
    margin: "4px 0",
  },

  startIconBox: {
    color: "#000",
  },

  endIconBox: {
    color: "#000",
  },
  multipleMenuBox: {
    position: "absolute",
    top: "-2px",
    left: 348,
    width: 300,
    padding: "4px 8px",
    border: "2px solid #1D83C3",
    background: "#fff",
  },
  flexTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  bodyTitle: {
    fontSize: 12,
    padding: "10px 0",
  },
  boxshadownSearch: {
    width: "100%",
    boxShadow: " 0px 1px 5px #ccc",
    height: 200,
    overflow: "auto",
  },
  resultSearch: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  nameShip: {
    fontSize: 14,
    color: "#000000",
    flex: 1,
    paddingLeft: 10,
    overflow: "hidden",
  },
  noteShip: {
    fontSize: 12,
    padding: "10px 0",
  },
  centerButton: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  errInput: {
    fontSize: 12,
    color: "red",
  },
  formto: {
    padding: "20px  0px 15px 0",
  },
  marginButton: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
}));
