import React, { useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { useDispatch, useSelector } from "react-redux";
import ShipActions from "redux-store/ship.redux";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LangConstant } from "const";

export default function FilterByNation({ setCountryCode, sx }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const shipConditions = useSelector(state => state.shipRedux.shipConditions);

  const [selected, setSelected] = useState("");

  const onHandleSelectCode = code => {
    setSelected(code);
    setCountryCode(code);
  };

  useEffect(() => {
    dispatch(
      ShipActions.shipSet({
        isChangingFilter: true,
        shipConditions: Object.assign(shipConditions, { country_code_list: [selected] }),
      }),
    );
  }, [selected]);

  return (
    <Box className={classes.chooseNationBox} sx={sx}>
      <ReactFlagsSelect
        placeholder={LangConstant.P_CHOOSE_NATION}
        searchable
        fullWidth
        selected={selected}
        onSelect={code => onHandleSelectCode(code)}
      />
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  chooseNationBox: {
    margin: 4,
  },
}));
