import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button, Checkbox, IconButton, Pagination, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { Edit, RemoveRedEye } from "@mui/icons-material";
import AddPortManagement from "./AddPortManagement";
import { useState } from "react";
import ConversationRedux from "redux-store/conversation.redux";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import EditPortManagement from "./EditPortManagement";
import ViewPortManagement from "./ViewPortManagement";
import { LangConstant } from "const";
import { format } from "date-fns";
import ConfirmDeletePort from "./ConfirmDeletePort";
import { hasRoleScopes } from "utils";
import { SCOPES } from "const/app.const";

function createData(stt, name, unit, address, phoneNumber, fax, timeUpdate, more, manipulation) {
  return { stt, name, unit, address, phoneNumber, fax, timeUpdate, more, manipulation };
}

const TablePortManagement = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const getHarbors = useSelector(state => state.conversationRedux.getHarbors);
  const deleteHarbors = useSelector(state => state.conversationRedux.deleteHarbors);
  const updateHarborShip = useSelector(state => state.conversationRedux.updateHarborShip);
  const createPortSuccsess = useSelector(state => state.conversationRedux.createPortSuccsess);
  const createMorePort = useSelector(state => state.conversationRedux.createMorePort);
  const paginationObj = useSelector(state => state.conversationRedux.pagination);
  const totalPageHarbors = useSelector(state => state.conversationRedux.totalPageHarbors);

  const [openPortManagement, setOpenPortManagement] = useState(false);
  const [data, setData] = useState();
  const [checkedCheckbox, setCheckedCheckbox] = useState([]);
  const [openEditPortManagement, setOpenEditPortManagement] = useState(false);
  const [viewsPortManagement, setViewPortManagement] = useState(false);
  const [pagination, setPagination] = useState(1);
  const [resultTable, setResultTable] = useState();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [dataConfirm, setDataConfirm] = useState("");

  const onClickCheckbox = id => {
    setCheckedCheckbox(pre => {
      const isChecked = checkedCheckbox.includes(id);
      if (isChecked) {
        return checkedCheckbox.filter(item => item !== id);
      } else {
        setCheckedCheckbox(pre => [...pre, id]);
      }
    });
  };

  const onClickDeleteCheckbox = () => {
    setConfirmDelete(true);
  };

  useEffect(() => {
    if (getHarbors && getHarbors.length) {
      let result = [];
      getHarbors?.forEach((item, index) => {
        result.push(
          createData(
            item.uid !== null ? item.uid : "",
            item.name !== null ? item.name : " ",
            item.company_name !== null ? item.company_name : "",
            item.address !== null ? item.address : "",
            item.phone_number !== null ? item.phone_number : "",
            item.fax !== null ? item.fax : "",
            item.modified !== null ? item.modified : "",
            item.port_name !== null ? item.port_name : "",
            <React.Fragment>
              {hasRoleScopes(SCOPES.HARBOR_UPDATE) && (
                <IconButton
                  onClick={() => {
                    setOpenEditPortManagement(true);
                    setData(item);
                  }}
                >
                  <Edit />
                </IconButton>
              )}
              {hasRoleScopes(SCOPES.HARBOR_DETAIL) && (
                <IconButton
                  onClick={() => {
                    setViewPortManagement(true);
                    setData(item);
                  }}
                >
                  <RemoveRedEye />
                </IconButton>
              )}
            </React.Fragment>,
          ),
        );
      });
      setResultTable(result);
    }
  }, [getHarbors]);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const ConversationAction = (e, value) => {
    setPagination(value);
  };

  useEffect(() => {
    dispatch(
      ConversationRedux.getHarbors({
        name: searchInput,
        page: pagination,
      }),
    );
  }, [pagination, searchInput, deleteHarbors, updateHarborShip, createPortSuccsess, createMorePort]);

  useEffect(() => {
    dispatch(ConversationRedux.getPortManager());
  }, []);

  useEffect(() => {
    if (deleteHarbors) setCheckedCheckbox([]);
    dispatch(
      ConversationRedux.conversationSet({
        deleteHarbors: false,
      }),
    );
  }, [deleteHarbors]);

  return (
    <>
      <Box className={classes.searchInput}>
        <TextField
          onChange={e => setSearchInput(e.target.value)}
          fullWidth
          size="small"
          label={LangConstant.L_SEARCH_SHIP}
          variant="outlined"
        />
      </Box>
      <Box className={classes.paddingButton}>
        <Box className={classes.buttonPaddingadd}>
          {hasRoleScopes(SCOPES.HARBOR_CREATE) && (
            <Button value={searchInput} onClick={() => setOpenPortManagement(true)} color="warning" variant="contained">
              {LangConstant.TXT_ADD_MORE}
            </Button>
          )}
        </Box>
        {hasRoleScopes(SCOPES.HARBOR_DELETE) && (
          <Button
            onClick={onClickDeleteCheckbox}
            disabled={Boolean(checkedCheckbox.length > 0 ? "" : "disabled")}
            variant="contained"
            color="warning"
          >
            {LangConstant.TXT_BUTTON_DELETE}
          </Button>
        )}
      </Box>
      <TableContainer style={{ height: 700 }} component={Paper}>
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="50px"></TableCell>
              <TableCell width="50px">STT</TableCell>
              <TableCell align="center">{LangConstant.TXT_TABLE_NAME_PORT}</TableCell>
              <TableCell align="center">Cảng biến</TableCell>
              <TableCell align="center">{LangConstant.TXT_MANAGEMENT_UNIT}</TableCell>
              <TableCell align="center">{LangConstant.TXT_ADDRESS}</TableCell>
              <TableCell align="center">{LangConstant.TXT_NUMBER}</TableCell>
              <TableCell align="center">{LangConstant.TXT_FAX}</TableCell>
              <TableCell align="center">{LangConstant.TXT_TIME_UPDATE}</TableCell>
              <TableCell align="center">{LangConstant.TXT_MANIPULATION}</TableCell>
            </TableRow>
          </TableHead>
          {
            <TableBody>
              {resultTable &&
                resultTable.map((row, index) => {
                  return (
                    <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell>
                        <Checkbox
                          checked={checkedCheckbox.includes(row.stt)}
                          onClick={() => {
                            onClickCheckbox(row.stt);
                            setDataConfirm(row);
                          }}
                          color="warning"
                          {...label}
                        />
                      </TableCell>
                      <TableCell align="center">{(pagination - 1) * paginationObj + index + 1}</TableCell>
                      <TableCell align="center">{row.name}</TableCell>
                      <TableCell align="center">{row.more}</TableCell>
                      <TableCell align="center">{row.unit}</TableCell>
                      <TableCell align="center">{row.address}</TableCell>
                      <TableCell align="center">{row.phoneNumber}</TableCell>
                      <TableCell align="center">{row.fax}</TableCell>
                      <TableCell align="center">{row.timeUpdate ? format(row.timeUpdate, "dd/MM/yyyy") : ""}</TableCell>
                      <TableCell align="center">{row.manipulation}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          }
        </Table>
      </TableContainer>
      <AddPortManagement open={openPortManagement} onClose={() => setOpenPortManagement(false)} />
      <EditPortManagement
        dataEdit={data}
        open={openEditPortManagement}
        onClose={() => setOpenEditPortManagement(false)}
      />
      <ViewPortManagement dataView={data} open={viewsPortManagement} onClose={() => setViewPortManagement(false)} />
      <Box className={classes.panigationPadding}>
        <Pagination
          onChange={ConversationAction}
          sx={{ paddingTop: 2 }}
          count={+totalPageHarbors}
          variant="outlined"
          page={pagination}
        />
      </Box>
      <ConfirmDeletePort
        data={dataConfirm}
        checked={checkedCheckbox}
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
      />
    </>
  );
};
export default TablePortManagement;

const useStyles = makeStyles(theme => ({
  searchInput: {
    margin: "auto",
    width: 400,
  },

  paddingButton: {
    paddingBottom: 20,
    display: "flex",
  },
  buttonPaddingadd: {
    display: "flex",
    paddingRight: 10,
  },
  panigationPadding: {
    padding: "10px 0",
  },
  paddingPort: {
    paddingLeft: 10,
  },
}));
