/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from "react-simple-captcha";
import QueryString from "query-string";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff, Replay } from "@mui/icons-material";
import Cookies from "js-cookie";
import { ApiConstant, AppConstant, EventConstant, LangConstant, PathConstant } from "const";
import { useLoading, useNotification } from "hooks";
import { connectTriosMessageNotice } from "utils";
import { LoginBackgroundImage } from "theme/images";
import AuthRedux from "redux-store/auth.redux";
import ConversationRedux from "redux-store/conversation.redux";
import OtpDialog from "./OtpDialog";
import LinkTriosDialog from "./LinkTriosDialog";
import LinkTriosDialogForm from "./LinkTriosDialogForm";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const loading = useLoading();
  const notification = useNotification();

  const isLoginSuccess = useSelector(state => state.conversationRedux.isLoginSuccess);
  const errLoginFailure = useSelector(state => state.conversationRedux.errLoginFailure);
  const loginType = useSelector(state => state.authRedux.loginType);
  const loginSuccessValue = useSelector(state => state.authRedux.loginSuccessValue);
  const connectTriosStatus = useSelector(state => state.authRedux.connectTriosStatus);
  const linkTriosPopupToken = useSelector(state => state.authRedux.linkTriosPopup.token);

  const [showPassword, setShowPassword] = useState(false);
  const [errLogin, setErrLogin] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errCaptcha, setErrCaptcha] = useState("");
  const [checkedLogin, setCheckedLogin] = useState(false);
  const [valueInputLogin, setValueInputLogin] = useState({
    username: "",
    password: "",
    captcha: "",
  });

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleFormSubmit = e => {
    e.preventDefault();
    let isValidated = true;

    const regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(valueInputLogin.username)) {
      setErrEmail("Email không đúng định dạng");
      isValidated = false;
    } else {
      setErrEmail("");
    }

    if (!validateCaptcha(valueInputLogin.captcha)) {
      setErrCaptcha("Mã CAPTCHA nhập vào vào không chính xác");
      isValidated = false;
    } else {
      setErrCaptcha("");
    }

    if (valueInputLogin && isValidated) {
      loading.open();
      dispatch(
        AuthRedux.loginPage({
          username: valueInputLogin.username,
          password: valueInputLogin.password,
          is_remember: checkedLogin,
        }),
      );
    }
  };

  const onChangeValueLogin = e => {
    setValueInputLogin({ ...valueInputLogin, [e.target.name]: e.target.value });
  };

  const onChangeChecked = e => {
    setCheckedLogin(e.target.checked);
  };

  const onReloadCaptcha = () => {
    loadCaptchaEnginge(6);
  };

  const onGetThirdParty = () => {
    window.electronEvents.dispatchEvent(
      EventConstant.TRIOS_EVENTS.requestGetThirdToken,
      ApiConstant.CREATE_TOKEN_LOGIN_TRIOS,
    );
    dispatch(
      AuthRedux.authSet({
        connectTriosStatus: {
          status: {
            getThirdToken: AppConstant.API_STATUS.calling,
          },
          message: AppConstant.CONNECT_TRIOS_MESSAGE_TYPE.nothing,
        },
      }),
    );
  };

  useEffect(() => {
    if ("caches" in window) {
      caches.keys().then(names => {
        names.forEach(name => {
          caches.delete(name);
        });
      });
    }
    onReloadCaptcha();
    let userNameCookies = Cookies.get(LangConstant.TXT_USER_NAME_LOCAL);
    let passWordCookies = Cookies.get(LangConstant.TXT_PASSWORD_LOCAL);
    if (Boolean(userNameCookies) && Boolean(passWordCookies)) {
      setCheckedLogin(Boolean(Cookies.get(LangConstant.TXT_REMEMBER_PASSWORD)));
      setValueInputLogin({
        username: userNameCookies,
        password: passWordCookies,
      });
    }
  }, []);

  useEffect(() => {
    if (!window.electronEvents) return;
    if (connectTriosStatus.status.getThirdToken === AppConstant.API_STATUS.calling) {
      window.electronEvents.addListener(EventConstant.TRIOS_EVENTS.receiveThirdToken, async (event, payload) => {
        const queryUrl = payload.substring(payload.indexOf("?") + 1);
        const eventParams = QueryString.parse(queryUrl);
        if (eventParams.type) {
          if (eventParams.token) {
            if (parseInt(eventParams.type) === AppConstant.LOGIN_TYPE.linkTrios) {
              dispatch(
                AuthRedux.linkTrios({
                  data: eventParams.token,
                  token: loginSuccessValue.access_token,
                  loginSuccessValue: loginSuccessValue,
                }),
              );
            } else {
              dispatch(AuthRedux.loginTrios(eventParams.token));
            }
          } else {
            dispatch(
              AuthRedux.authSet({
                connectTriosStatus: {
                  status: {
                    getThirdToken: AppConstant.API_STATUS.error,
                  },
                  message: AppConstant.CONNECT_TRIOS_MESSAGE_TYPE.other,
                },
                loginType: AppConstant.LOGIN_TYPE.normal,
                loginSuccessValue: null,
              }),
            );
          }
        }
      });
    }
    loading.close();
  }, [window.electronEvents, connectTriosStatus.status.getThirdToken]);

  useEffect(() => {
    if (errLoginFailure) {
      setErrLogin("Email hoặc mật khẩu không chính xác");
    }
    loading.close();
    dispatch(
      ConversationRedux.conversationSet({
        errLoginFailure: false,
      }),
    );
  }, [errLoginFailure]);

  useEffect(() => {
    if (isLoginSuccess) {
      navigate(PathConstant.HOME);
    }
    dispatch(
      AuthRedux.authSet({
        connectTriosStatus: {
          status: {
            getThirdToken: AppConstant.API_STATUS.nothing,
            linkTrios: AppConstant.API_STATUS.nothing,
            loginTrios: AppConstant.API_STATUS.nothing,
          },
          message: AppConstant.CONNECT_TRIOS_MESSAGE_TYPE.nothing,
        },
        loginType: AppConstant.LOGIN_TYPE.normal,
        loginSuccessValue: null,
      }),
    );
    loading.close();
  }, [isLoginSuccess]);

  useEffect(() => {
    if (connectTriosStatus.message === AppConstant.CONNECT_TRIOS_MESSAGE_TYPE.success) {
      notification.send(connectTriosMessageNotice(connectTriosStatus.message), "success");
    } else if (connectTriosStatus.message !== AppConstant.CONNECT_TRIOS_MESSAGE_TYPE.nothing) {
      notification.send(connectTriosMessageNotice(connectTriosStatus.message), "error");
    }
    loading.close();
  }, [connectTriosStatus.message]);

  useEffect(() => {
    if (loginType === AppConstant.LOGIN_TYPE.normal) onReloadCaptcha();
  }, [loginType]);

  return (
    <Box className={classes.container}>
      <Box className={classes.containerSub}>
        {linkTriosPopupToken && <LinkTriosDialogForm />}
        {loginType === AppConstant.LOGIN_TYPE.linkTrios ? (
          <LinkTriosDialog />
        ) : (
          <form className={classes.formLogin} onSubmit={handleFormSubmit}>
            <Typography variant="h3" align="center" my={1}>
              {LangConstant.TXT_MONITORING_SOFTWARE}
            </Typography>
            <Typography my={1}>{LangConstant.TXT_EMAIL}</Typography>
            <TextField
              className={classes.textField}
              onChange={onChangeValueLogin}
              fullWidth
              my={1}
              autoComplete="off"
              id="username"
              name="username"
              value={valueInputLogin.username}
              placeholder="Nhập email"
            />
            <Box className={classes.errorMessage}>{errEmail}</Box>
            <Typography my={1}>{LangConstant.TXT_PASSWORD}</Typography>
            <Box>
              <FormControl fullWidth variant="outlined">
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  onChange={onChangeValueLogin}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                        type="button"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  autoComplete="off"
                  name="password"
                  placeholder="Nhập mật khẩu"
                />
              </FormControl>
            </Box>
            {errLogin && <Box className={classes.errorMessage}>{errLogin}</Box>}
            <Box className={classes.formCaptcha}>
              <TextField
                className={classes.textFieldCaptcha}
                onChange={onChangeValueLogin}
                autoComplete="off"
                id="captcha"
                name="captcha"
                placeholder="Nhập mã CAPTCHA"
              />
              <Box className={classes.captchaContainer}>
                <LoadCanvasTemplate reloadText={" "} />
              </Box>
              <IconButton size="small" onClick={onReloadCaptcha} edge="end" type="button">
                <Replay fontSize="small" />
              </IconButton>
            </Box>
            <Box className={classes.errorMessage}>{errCaptcha}</Box>
            <FormControlLabel
              onChange={onChangeChecked}
              control={<Checkbox />}
              label={LangConstant.L_REMEMBER_PASSWORD}
              my={1}
              checked={checkedLogin}
            />
            <Box mt={2}>
              <Button
                disabled={valueInputLogin.username === "" || valueInputLogin.password === "" ? true : false}
                color="primary"
                variant="contained"
                fullWidth
                size="large"
                type="submit"
              >
                {LangConstant.TXT_BUTTON_LOGIN}
              </Button>
            </Box>
            <Typography align="center" mt={2}>
              Hoặc
            </Typography>
            <Box mt={2}>
              <Button variant="contained" fullWidth size="large" onClick={onGetThirdParty}>
                {LangConstant.TXT_BUTTON_TRIOS_LOGIN}
              </Button>
            </Box>
          </form>
        )}
      </Box>
      {loginType === AppConstant.LOGIN_TYPE.otp && (
        <OtpDialog username={valueInputLogin.username} password={valueInputLogin.password} />
      )}
    </Box>
  );
};

export default Login;

const useStyles = makeStyles(theme => ({
  formLogin: {
    width: 500,
  },

  container: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${LoginBackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "top left",
    backgroundRepeat: "no-repeat",
  },

  containerSub: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "0 auto",
    alignItems: "center",
    padding: "32px",
    borderRadius: "2px",
    background: "#fff",
    boxShadow: "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.4) 0px 8px 16px -8px",
  },

  captchaContainer: {
    flex: 4,
    height: "56px",
    border: "1px solid #e0e0e0",
    borderRadius: "4px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  formCaptcha: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "32px 0px 8px 0px",
    gap: "16px",
  },

  textFieldCaptcha: {
    flex: 5,
  },

  errorMessage: {
    fontSize: 12,
    color: "red",
  },
}));
