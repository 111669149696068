import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  requestGetDefaultArea: ["data"],
  requestGetCustomArea: ["data"],
  requestGetPointArea: ["data"],
  requestGetKmlFile: ["data"],
  getTypeArea: ["data"],
  requestCreateCustomArea: ["data"],
  requestUpdateCustomArea: ["data"],
  requestDeleteCustomArea: ["data"],
  uploadKmlFile: ["data"],
  requestCreatePointArea: ["data"],
  requestUpdatePointArea: ["data"],
  deletePointArea: ["data"],
  areaSet: ["data"],
  areaReset: [],
  getWeatherPoint: ["data"],
});

export const AreaTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  defaultAreaList: [],
  customAreaList: [],
  pointAreaList: [],
  selectedAreaList: [],
  selectedKmlFiles: [],
  showArea: [],
  kmlFiles: {},

  isFetching: false,
  deleteAreaStatus: "idle",
  getTypeArea: false,
  isUploadKmlSuccess: true,
  uploadKmlStatus: "idle",

  customArea: null,
  editedArea: null,
  errors: null,
  kmlUid: null,
};

/* ------------- Reducers ------------- */

export const request = (state = INITIAL_STATE, action) => {
  let data = action.data || {};
  return {
    ...state,
    ...data,
    isFetching: true,
  };
};

export const set = (state = INITIAL_STATE, action) => {
  let data = action.data || {};

  return {
    ...state,
    ...data,
  };
};

export const reset = () => INITIAL_STATE;

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.REQUEST_GET_DEFAULT_AREA]: request,
  [Types.REQUEST_GET_CUSTOM_AREA]: request,
  [Types.REQUEST_GET_KML_FILE]: request,
  [Types.REQUEST_GET_POINT_AREA]: request,
  [Types.GET_WEATHER_POINT]: request,

  [Types.GET_TYPE_AREA]: request,

  [Types.REQUEST_CREATE_CUSTOM_AREA]: request,
  [Types.REQUEST_UPDATE_CUSTOM_AREA]: request,
  [Types.REQUEST_DELETE_CUSTOM_AREA]: request,
  [Types.REQUEST_CREATE_POINT_AREA]: request,
  [Types.REQUEST_UPDATE_POINT_AREA]: request,
  [Types.DELETE_POINT_AREA]: request,
  [Types.UPLOAD_KML_FILE]: request,

  [Types.AREA_SET]: set,
  [Types.AREA_RESET]: reset,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
