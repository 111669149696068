import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AppConstant } from "const";
import { PROVINCES, SHIP_STATUS } from "const/app.const";
import React, { useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import ConversationRedux from "redux-store/conversation.redux";
import { convertTimeStampToDate } from "utils/date.utils";
import { Close } from "@mui/icons-material";
import clsx from "clsx";
import { convertCoordIntToObject } from "utils";

const ViewShipManagement = ({ open, onClose }) => {
  const getTypeShip = useSelector(state => state.conversationRedux.getTypeShip);
  const dataEdit = useSelector(state => state.conversationRedux.getDetailGroupShip);
  const updateShip = useSelector(state => state.conversationRedux.updateShip);
  const classes = useStyles();
  const dispatch = useDispatch();

  const [backDropEditShip, setBackDropEditShip] = useState(false);
  const [statusactivated, setStatusActivated] = useState(dataEdit?.status);
  const [statusShip, setStatusShip] = useState(dataEdit?.GPS_status);
  const [nation, setNation] = useState(dataEdit?.country_code);
  const [provincesVN, setProvincesVN] = useState(dataEdit?.province);
  const [shipName, setShipName] = useState(dataEdit?.ship_name);
  const [IMO, setIMO] = useState(null);
  const [AIS, setAIS] = useState(null);
  const [MMSI, setMMSI] = useState(dataEdit?.mmsi_code);
  const [callSign, setCallSign] = useState(dataEdit?.callSign);
  const [citizenIdentification, setCitizenIdentification] = useState(dataEdit?.ship_owner?.citizen_identification);
  const [licensePlate, setlicensePlate] = useState(dataEdit?.license_plate);
  const [supplier, setSupplier] = useState(dataEdit?.supplier);
  const [mainFishingPort, setMainFishingPort] = useState(dataEdit?.main_fishing_port);
  const [subFishingPort, setsubFishingPort] = useState(dataEdit?.sub_fishing_port);
  const [career, setCareer] = useState(dataEdit?.career);
  const [ownerName, setOwnerName] = useState(dataEdit?.ship_owner?.name);
  const [ownercitizenIdentification, setOwnerownercitizenIdentification] = useState(
    dataEdit?.ship_owner?.citizen_identification,
  );
  const [ownerEmail, setOwnerEmail] = useState(dataEdit?.ship_owner?.email);
  const [ownerAddress, setOwnerAddress] = useState(dataEdit?.ship_owner?.address);
  const [phoneNumberOwner, setPhoneNumberOwner] = useState();
  const [ownerFax, setOwnerFax] = useState(dataEdit?.ship_owner?.fax);
  const [captionName, setCaptionName] = useState(dataEdit?.ship_captain?.name);
  const [captionAddress, setCaptionAddress] = useState(dataEdit?.ship_captain?.address);
  const [selectType, setSelectType] = useState(dataEdit?.ship_type?.name);
  const [captionNumber, setCaptionNumber] = useState(dataEdit?.ship_captain?.phone);
  const [dateOfManufactor, setDateOfManufactor] = useState(dataEdit?.date_of_manufactor);
  const [expirationDate, setexpirationDate] = useState(dataEdit?.expiration_date);
  const [captionWeight, setCaptionWeight] = useState(dataEdit?.capacity);
  const [captionFax, setCaptionFax] = useState(dataEdit?.ship_captain?.fax);
  const [power, setPower] = useState(dataEdit?.capacity);
  const [captionColor, setCaptionColor] = useState(dataEdit?.color);
  const [numberOfSailor, setNumberOfSailor] = useState(dataEdit?.number_of_sailor);
  const [captionFishingSpeed, setCaptionFishingSpeed] = useState(dataEdit?.fishing_speed);
  const [captionMaxSpeed, setCaptionMaxSpeed] = useState(dataEdit?.max_speed);
  const [captiondesignLength, setCaptiondesignLength] = useState(dataEdit?.length);
  const [captionWidth, setCaptionWidth] = useState(dataEdit?.width);
  const [designLength, setDesignLength] = useState(dataEdit?.design_length);
  const [freeSpeed, setFreeSpeed] = useState(dataEdit?.free_speed);
  const [shipLat, setShipLat] = useState({
    deg: 0,
    min: 0,
    sec: 0,
  });
  const [shipLng, setShipLng] = useState({
    deg: 0,
    min: 0,
    sec: 0,
  });

  const onChangeProvincesVN = e => {
    setProvincesVN(e.target.value);
  };

  useEffect(() => {
    setFreeSpeed(dataEdit?.free_speed);
  }, [dataEdit?.free_speed]);

  useEffect(() => {
    setDesignLength(dataEdit?.design_length);
  }, [dataEdit?.design_length]);

  useEffect(() => {
    setNation(dataEdit?.country_code);
  }, [dataEdit?.country_code]);

  useEffect(() => {
    setShipName(dataEdit?.ship_name);
  }, [dataEdit?.ship_name]);

  useEffect(() => {
    setIMO(dataEdit?.IMO);
  }, [dataEdit?.IMO]);

  useEffect(() => {
    setMMSI(dataEdit?.mmsi_code);
  }, [dataEdit?.mmsi_code]);

  useEffect(() => {
    setCallSign(dataEdit?.callSign);
  }, [dataEdit?.callSign]);

  useEffect(() => {
    setCitizenIdentification(dataEdit?.ship_owner?.citizen_identification);
  }, [dataEdit?.ship_owner?.citizen_identification]);

  useEffect(() => {
    setlicensePlate(dataEdit?.license_plate);
  }, [dataEdit?.license_plate]);

  useEffect(() => {
    setSupplier(dataEdit?.supplier);
  }, [dataEdit?.supplier]);

  useEffect(() => {
    setMainFishingPort(dataEdit?.main_fishing_port);
  }, [dataEdit?.main_fishing_port]);

  useEffect(() => {
    setsubFishingPort(dataEdit?.sub_fishing_port);
  }, [dataEdit?.sub_fishing_port]);

  useEffect(() => {
    setCareer(dataEdit?.career);
  }, [dataEdit?.career]);

  useEffect(() => {
    setStatusShip(dataEdit?.GPS_status);
  }, [dataEdit?.GPS_status]);

  useEffect(() => {
    setOwnerName(dataEdit?.ship_owner?.name);
  }, [dataEdit?.ship_owner?.name]);

  useEffect(() => {
    setOwnerEmail(dataEdit?.ship_owner?.email);
  }, [dataEdit?.ship_owner?.email]);

  useEffect(() => {
    setOwnerAddress(dataEdit?.ship_owner?.address);
  }, [dataEdit?.ship_owner?.address]);

  useEffect(() => {
    setPhoneNumberOwner(dataEdit?.ship_owner?.phone);
  }, [dataEdit?.ship_owner?.phone]);

  useEffect(() => {
    setOwnerFax(dataEdit?.ship_owner?.fax);
  }, [dataEdit?.ship_owner?.fax]);

  useEffect(() => {
    setCaptionName(dataEdit?.ship_captain?.name);
  }, [dataEdit?.ship_captain?.name]);

  useEffect(() => {
    setCaptionAddress(dataEdit?.ship_captain?.address);
  }, [dataEdit?.ship_captain?.address]);

  useEffect(() => {
    setProvincesVN(dataEdit?.province);
  }, [dataEdit?.province]);

  useEffect(() => {
    getTypeShip &&
      getTypeShip
        ?.filter(filterUidShip => filterUidShip.name === dataEdit?.ship_type?.name)
        .forEach(uidNew => setSelectType(uidNew.uid));
  }, [dataEdit?.ship_type?.name, getTypeShip]);

  useEffect(() => {
    setCaptionNumber(dataEdit?.ship_captain?.phone);
  }, [dataEdit?.ship_captain?.phone]);

  useEffect(() => {
    setCaptionWeight(dataEdit?.capacity);
  }, [dataEdit?.capacity]);

  useEffect(() => {
    setCaptionFax(dataEdit?.ship_captain?.fax);
  }, [dataEdit?.ship_captain?.fax]);

  useEffect(() => {
    setPower(dataEdit?.power);
  }, [dataEdit?.power]);

  useEffect(() => {
    setCaptionColor(dataEdit?.color);
  }, [dataEdit?.color]);

  useEffect(() => {
    setNumberOfSailor(dataEdit?.number_of_sailor);
  }, [dataEdit?.number_of_sailor]);

  useEffect(() => {
    setexpirationDate(convertTimeStampToDate(dataEdit?.expiration_date / 1000));
  }, [dataEdit?.expiration_date]);

  useEffect(() => {
    setDateOfManufactor(convertTimeStampToDate(dataEdit?.date_of_manufactor / 1000));
  }, [dataEdit?.date_of_manufactor]);

  useEffect(() => {
    setCaptionFishingSpeed(dataEdit?.fishing_speed);
  }, [dataEdit?.fishing_speed]);

  useEffect(() => {
    setCaptionMaxSpeed(dataEdit?.max_speed);
  }, [dataEdit?.max_speed]);

  useEffect(() => {
    setCaptiondesignLength(dataEdit?.length);
  }, [dataEdit?.length]);

  useEffect(() => {
    setCaptionWidth(dataEdit?.width);
  }, [dataEdit?.width]);

  useEffect(() => {
    if (updateShip) {
      onClose();
      setBackDropEditShip(false);
      dispatch(
        ConversationRedux.conversationSet({
          updateShip: false,
        }),
      );
    }
  }, [updateShip]);

  useEffect(() => {
    setAIS(dataEdit?.device_code);
  }, [dataEdit]);

  useEffect(() => {
    setStatusActivated(dataEdit?.status);
  }, [dataEdit?.status]);

  useEffect(() => {
    if (dataEdit.lat) {
      let obj = convertCoordIntToObject(dataEdit.lat);
      setShipLat({
        deg: obj.degrees,
        min: obj.minutes,
        sec: obj.seconds,
      });
    }

    if (dataEdit.lon) {
      let obj = convertCoordIntToObject(dataEdit.lon);
      setShipLng({
        deg: obj.degrees,
        min: obj.minutes,
        sec: obj.seconds,
      });
    }
  }, [dataEdit]);

  return (
    <Dialog open={Boolean(open)} onClose={onClose}>
      <DialogTitle>
        <Box className={classes.flexTitle}>
          <Typography>Xem dữ liệu tàu</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.inputPaddingTop}>
          <TextField
            disabled
            size="small"
            fullWidth
            value={shipName || ""}
            defaultValue={dataEdit?.ship_name}
            label="Tên tàu"
            variant="outlined"
            onChange={e => setShipName(e.target.value)}
          />
        </Box>
        <Box className={classes.paddingItem}>
          <FormControl disabled size="small" fullWidth>
            <InputLabel>Loại tàu</InputLabel>
            <Select
              label="Loại tàu"
              defaultValue={selectType}
              value={selectType}
              onChange={e => setSelectType(e.target.value)}
            >
              {getTypeShip &&
                getTypeShip.map(item => (
                  <MenuItem key={item.uid} value={item.uid}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <ReactFlagsSelect
          disabled
          placeholder="Chọn quốc gia"
          searchable
          fullWidth
          defaultValue={dataEdit?.country_code}
          selected={nation}
          onSelect={code => setNation(code)}
        />
        <Box className={classes.paddingItem}>
          {nation === "VN" ? (
            <Box>
              <FormControl disabled fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Tỉnh thành Việt Nam</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={provincesVN}
                  onChange={onChangeProvincesVN}
                  label="tỉnh thành Việt Nam"
                  defaultValue={dataEdit?.province}
                >
                  {PROVINCES.map((item, index) => (
                    <MenuItem key={index} value={item.contry}>
                      {item.contry}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          ) : (
            ""
          )}
        </Box>
        <TextField
          disabled
          fullWidth
          size="small"
          value={IMO || ""}
          label="Số hiệu IMO"
          onChange={e => setIMO(e.target.value)}
        />
        <Box style={{ paddingTop: 15 }}>
          <TextField
            disabled
            fullWidth
            size="small"
            value={AIS || ""}
            label="Số hiệu AIS"
            onChange={e => setAIS(e.target.value)}
          />
        </Box>
        <Box className={classes.paddingItem}>
          <TextField
            disabled
            fullWidth
            size="small"
            value={MMSI || ""}
            defaultValue={dataEdit?.MMSI}
            label="Số hiệu MMSI"
            variant="outlined"
            onChange={e => setMMSI(e.target.value)}
          />
        </Box>
        <TextField
          disabled
          fullWidth
          size="small"
          value={callSign || ""}
          defaultValue={dataEdit?.callSign}
          label="Hô hiệu"
          variant="outlined"
          onChange={e => setCallSign(e.target.value)}
        />
        <Box className={classes.paddingItem}>
          <TextField
            disabled
            fullWidth
            size="small"
            value={citizenIdentification || ""}
            defaultValue={dataEdit?.ship_owner?.citizen_identification}
            label="ID Nhận dạng"
            variant="outlined"
            onChange={e => setCitizenIdentification(e.target.value)}
          />
        </Box>
        <TextField
          disabled
          fullWidth
          size="small"
          value={licensePlate || ""}
          defaultValue={dataEdit?.license_plate}
          label="Biển số tàu"
          variant="outlined"
          onChange={e => setlicensePlate(e.target.value)}
        />
        <Box className={classes.paddingItem}>
          <TextField
            disabled
            fullWidth
            size="small"
            value={supplier || ""}
            defaultValue={dataEdit?.supplier}
            label="Nhà cung cấp"
            variant="outlined"
            onChange={e => setSupplier(e.target.value)}
          />
        </Box>
        <TextField
          disabled
          fullWidth
          size="small"
          value={mainFishingPort || ""}
          defaultValue={dataEdit?.main_fishing_port}
          label="Cảng cá chính"
          variant="outlined"
          onChange={e => setMainFishingPort(e.target.value)}
        />
        <Box className={classes.paddingItem}>
          <TextField
            disabled
            fullWidth
            size="small"
            value={subFishingPort || ""}
            defaultValue={dataEdit?.sub_fishing_port}
            label="Cảng cá phụ"
            variant="outlined"
            onChange={e => setsubFishingPort(e.target.value)}
          />
        </Box>
        <TextField
          disabled
          fullWidth
          size="small"
          value={career || ""}
          defaultValue={dataEdit?.career}
          label="ngành nghề"
          variant="outlined"
          onChange={e => setCareer(e.target.value)}
        />
        <Box className={classes.paddingItem}>
          <FormControl size="small" fullWidth>
            <InputLabel id="demo-simple-select-label">Trạng thái kích hoạt</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={statusactivated || ""}
              onChange={e => setStatusActivated(e.target.value)}
              label="Trạng thái kích hoạt"
              defaultValue={dataEdit?.status === 0 ? 0 : 1}
            >
              <MenuItem value={1}>Kích hoạt</MenuItem>
              <MenuItem value={0}>Chưa kích hoạt</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <FormControl disabled size="small" fullWidth>
          <InputLabel id="demo-simple-select-label">Trạng thái tàu</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={statusShip || ""}
            onChange={e => setStatusShip(e.target.value)}
            label="Trạng thái tàu"
            defaultValue={dataEdit?.GPS_status}
          >
            {SHIP_STATUS.map((ship, index) => (
              <MenuItem key={index} value={ship.statusShip}>
                {ship.statusShip}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography variant="h6">Chủ tàu</Typography>
        <Box className={classes.paddingItem}>
          <TextField
            disabled
            fullWidth
            size="small"
            value={ownerName || ""}
            defaultValue={dataEdit?.ship_owner?.name}
            label="Tên chủ tàu"
            variant="outlined"
            onChange={e => setOwnerName(e.target.value)}
          />
        </Box>
        <TextField
          disabled
          fullWidth
          size="small"
          value={ownercitizenIdentification || ""}
          defaultValue={dataEdit?.ship_owner?.citizen_identification}
          label="CMND"
          variant="outlined"
          onChange={e => setOwnerownercitizenIdentification(e.target.value)}
        />
        <Box className={classes.paddingItem}>
          <TextField
            disabled
            fullWidth
            size="small"
            value={ownerEmail || ""}
            defaultValue={dataEdit?.ship_owner?.email}
            label="Email"
            variant="outlined"
            onChange={e => setOwnerEmail(e.target.value)}
          />
        </Box>
        <TextField
          disabled
          fullWidth
          size="small"
          value={ownerAddress || ""}
          defaultValue={dataEdit?.ship_owner?.address}
          label="Địa chỉ "
          variant="outlined"
          onChange={e => setOwnerAddress(e.target.value)}
        />
        <Box className={classes.paddingItem}>
          <PhoneInput
            disabled
            value={dataEdit?.ship_owner?.phone || phoneNumberOwner}
            onChange={phone => setPhoneNumberOwner(phone)}
            country="vn"
            onlyCountries={AppConstant.COUNTRY}
            defaultValue={dataEdit?.ship_owner?.phone}
          />
        </Box>
        <TextField
          disabled
          fullWidth
          size="small"
          value={ownerFax || ""}
          defaultValue={dataEdit?.ship_owner?.fax}
          label="Số fax "
          variant="outlined"
          onChange={e => setOwnerFax(e.target.value)}
        />
        <Typography variant="h6">Thuyền trưởng</Typography>
        <TextField
          disabled
          fullWidth
          size="small"
          value={captionName || ""}
          defaultValue={dataEdit?.ship_captain?.name}
          label="Họ và tên"
          variant="outlined"
          onChange={e => setCaptionName(e.target.value)}
        />
        <Box className={classes.paddingItem}>
          <TextField
            disabled
            fullWidth
            size="small"
            value={captionAddress || ""}
            defaultValue={dataEdit?.ship_captain?.address}
            label="Địa chỉ"
            variant="outlined"
            onChange={e => setCaptionAddress(e.target.value)}
          />
        </Box>
        <PhoneInput
          disabled
          value={dataEdit?.ship_captain?.phone || captionNumber}
          onChange={phone => setCaptionNumber(phone)}
          country="vn"
          onlyCountries={AppConstant.COUNTRY}
          defaultValue={dataEdit?.ship_captain?.phone}
        />
        <Box className={classes.paddingItem}>
          <TextField
            disabled
            fullWidth
            size="small"
            value={captionWeight || ""}
            defaultValue={dataEdit?.capacity}
            label="Trọng lượng"
            variant="outlined"
            onChange={e => setCaptionWeight(e.target.value)}
          />
        </Box>
        <TextField
          disabled
          fullWidth
          size="small"
          value={captionFax || ""}
          defaultValue={dataEdit?.ship_captain?.fax}
          label="Số fax"
          variant="outlined"
          onChange={e => setCaptionFax(e.target.value)}
        />
        <Box className={classes.paddingItem}>
          <TextField
            disabled
            fullWidth
            size="small"
            value={power || ""}
            defaultValue={dataEdit?.power}
            label="Công suất"
            variant="outlined"
            onChange={e => setPower(e.target.value)}
          />
        </Box>
        <TextField
          disabled
          fullWidth
          size="small"
          value={captionColor || ""}
          defaultValue={dataEdit?.color}
          label="Màu vỏ"
          variant="outlined"
          onChange={e => setCaptionColor(e.target.value)}
        />
        <Box className={classes.paddingItem}>
          <TextField
            disabled
            fullWidth
            size="small"
            value={numberOfSailor || ""}
            defaultValue={dataEdit?.number_of_sailor}
            label="Số thuyền viên"
            variant="outlined"
            onChange={e => setNumberOfSailor(e.target.value)}
          />
        </Box>
        <Box className={classes.paddingBottom}>
          <LocalizationProvider size="small" dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              disabled
              label="Ngày sản xuất"
              value={dateOfManufactor}
              onChange={newValue => {
                setDateOfManufactor(newValue);
              }}
              renderInput={params => <TextField size="small" {...params} />}
            />
          </LocalizationProvider>
          <LocalizationProvider size="small" dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              disabled
              label="Ngày hết hạn"
              value={expirationDate}
              onChange={newValue => {
                setexpirationDate(newValue);
              }}
              renderInput={params => <TextField size="small" {...params} />}
            />
          </LocalizationProvider>
        </Box>
        <Box className={classes.paddingBottom}>
          <TextField
            disabled
            fullWidth
            size="small"
            value={String(captionAddress)}
            defaultValue={dataEdit?.ship_captain?.address}
            label="Vận tốc tàu"
            variant="outlined"
            onChange={e => setCaptionAddress(e.target.value)}
          />
        </Box>
        <TextField
          disabled
          fullWidth
          size="small"
          value={captionFishingSpeed || ""}
          defaultValue={dataEdit?.fishing_speed}
          label="Tốc độ đánh bắt"
          variant="outlined"
          onChange={e => setCaptionFishingSpeed(e.target.value)}
        />
        <Box className={classes.paddingItem}>
          <TextField
            disabled
            fullWidth
            size="small"
            value={captionMaxSpeed || ""}
            defaultValue={dataEdit?.max_speed}
            label="Tốc độ tối đa"
            variant="outlined"
            onChange={e => setCaptionMaxSpeed(e.target.value)}
          />
        </Box>
        <TextField
          disabled
          fullWidth
          size="small"
          value={captiondesignLength || ""}
          defaultValue={dataEdit?.length}
          label="Chiều dài tàu"
          variant="outlined"
          onChange={e => setCaptiondesignLength(e.target.value)}
        />
        <Box className={classes.paddingItem}>
          <TextField
            disabled
            fullWidth
            size="small"
            value={captionWidth || ""}
            defaultValue={dataEdit?.width}
            label="Chiều rộng tàu"
            variant="outlined"
            onChange={e => setCaptionWidth(e.target.value)}
          />
        </Box>
        <Box className={classes.paddingBottom}>
          <TextField
            disabled
            onChange={e => setFreeSpeed(e.target.value)}
            type="number"
            fullWidth
            value={freeSpeed || ""}
            size="small"
            label="Tốc độ rơi tự do"
            variant="outlined"
          />
        </Box>
        <TextField
          disabled
          onChange={e => setDesignLength(e.target.value)}
          type="number"
          value={designLength || ""}
          fullWidth
          size="small"
          label="Chiều dài thiết kế"
          variant="outlined"
        />

        <Box className={classes.paddingBottom}>
          <Box className={classes.formInputField}>
            <Typography className={classes.formLabel}>Vĩ độ: </Typography>
            <Box className={classes.coordBox}>
              <OutlinedInput
                classes={{
                  root: classes.coordInput,
                  input: clsx(classes.formInput, classes.coordPaddingInput),
                }}
                size="small"
                placeholder="Độ"
                value={shipLat.deg}
                inputProps={{
                  type: "number",
                  min: -90,
                  max: 90,
                }}
                onChange={e => {}}
                disabled
                endAdornment={<Typography className={classes.inputUnit}>&deg;</Typography>}
              />
              <OutlinedInput
                classes={{
                  root: classes.coordInput,
                  input: clsx(classes.formInput, classes.coordPaddingInput),
                }}
                size="small"
                placeholder="Phút"
                value={shipLat.min}
                inputProps={{
                  type: "number",
                  min: 0,
                  max: 60,
                }}
                onChange={e => {}}
                disabled
                endAdornment={<Typography className={classes.inputUnit}>'</Typography>}
              />
              <OutlinedInput
                classes={{
                  root: classes.coordInput,
                  input: clsx(classes.formInput, classes.coordPaddingInput),
                }}
                size="small"
                placeholder="Giây"
                value={shipLat.sec}
                inputProps={{
                  type: "number",
                  min: 0,
                  max: 60,
                }}
                disabled
                onChange={e => {}}
                endAdornment={<Typography className={classes.inputUnit}>"</Typography>}
              />
            </Box>
          </Box>
          <Box className={classes.formInputField}>
            <Typography className={classes.formLabel}>Kinh độ: </Typography>
            <Box className={classes.coordBox}>
              <OutlinedInput
                classes={{
                  root: classes.coordInput,
                  input: clsx(classes.formInput, classes.coordPaddingInput),
                }}
                size="small"
                placeholder="Độ"
                disabled
                value={shipLng.deg}
                inputProps={{
                  type: "number",
                  min: -180,
                  max: 180,
                }}
                onChange={e => {}}
                endAdornment={<Typography className={classes.inputUnit}>&deg;</Typography>}
              />
              <OutlinedInput
                classes={{
                  root: classes.coordInput,
                  input: clsx(classes.formInput, classes.coordPaddingInput),
                }}
                size="small"
                placeholder="Phút"
                value={shipLng.min}
                inputProps={{
                  type: "number",
                  min: 0,
                  max: 60,
                }}
                disabled
                onChange={e => {}}
                endAdornment={<Typography className={classes.inputUnit}>'</Typography>}
              />
              <OutlinedInput
                classes={{
                  root: classes.coordInput,
                  input: clsx(classes.formInput, classes.coordPaddingInput),
                }}
                disabled
                size="small"
                placeholder="Giây"
                value={shipLng.sec}
                inputProps={{
                  type: "number",
                  min: 0,
                  max: 60,
                }}
                onChange={e => {}}
                endAdornment={<Typography className={classes.inputUnit}>"</Typography>}
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Thoát
        </Button>
      </DialogActions>
      <Backdrop sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }} open={backDropEditShip}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};

export default ViewShipManagement;

const useStyles = makeStyles(theme => ({
  paddingItem: {
    padding: "15px 0",
  },
  titleShipOwner: {
    color: "#000",
  },
  containerPicker: {
    display: "flex",
  },
  subPickerPadding: {
    paddingLeft: 10,
  },
  inputPaddingTop: {
    paddingTop: 10,
  },
  titleFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  paddingBottom: {
    paddingBottom: 15,
  },

  flexTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  coordInput: {
    width: "30%",
    height: 32,
    paddingRight: 0,
  },

  formInput: {
    padding: "0 10px",
    maxHeight: "100%",
    height: "100%",
    fontSize: 14,
  },

  coordPaddingInput: {
    paddingRight: 0,
  },

  coordBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },

  inputUnit: {
    backgroundColor: "#DDD",
    height: "100%",
    padding: "0 8px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },

  formLabel: {
    fontSize: 15,
    fontWeight: 500,
    minWidth: "max-content",
    marginRight: 20,
    marginTop: 10,
    width: "calc(35% - 20px)",
  },
}));
