import React, { memo, useEffect, useState } from "react";
import { useMapContext } from "hooks";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { EventConstant } from "const";
import { convert2Coords } from "utils";
import { EventBus } from "EventBus";

const LayerMousePosition = () => {
  const classes = useStyles();
  const { map, mapState } = useMapContext();

  const [mousePosition, setMousePosition] = useState({ lat: null, lng: null });

  const handleMouseMove = e => {
    setMousePosition({
      lat: e.latlng.lat,
      lng: e.latlng.lng,
    });
  };

  const handleMouseOut = () => {
    setMousePosition({ lat: null, lng: null });
  };

  useEffect(() => {
    if (map.current && mapState === "loaded") {
      EventBus.on(EventConstant.MAP_EVENTS.mousemove, handleMouseMove);
      EventBus.on(EventConstant.MAP_EVENTS.mouseout, handleMouseOut);
    }

    return () => {
      EventBus.off(EventConstant.MAP_EVENTS.mousemove, handleMouseMove);
      EventBus.off(EventConstant.MAP_EVENTS.mouseout, handleMouseOut);
    };
  }, [map, mapState]);

  const position =
    mousePosition.lat && mousePosition.lng ? convert2Coords(mousePosition.lat, mousePosition.lng) : { lat: 0, lng: 0 };

  return (
    mousePosition.lat &&
    mousePosition.lng && (
      <Box className={classes.cursorLocateBox} component="section">
        <Typography className={classes.currentMousePosition}>
          {position.lat}
          {"   "}
          {position.lng}
        </Typography>
      </Box>
    )
  );
};

export default memo(LayerMousePosition);

const useStyles = makeStyles(() => ({
  cursorLocateBox: {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    borderRadius: 2,
    padding: "2px 8px",
    position: "absolute",
    bottom: 30,
    left: 0,
    zIndex: 1000,
    margin: "5px",
    border: "2px solid #777",
  },

  currentMousePosition: {
    width: "100%",
    textAlign: "center",
    fontSize: "12px !important",
  },
}));
