import { Box } from "@mui/material";
import React from "react";
import TableWatchlist from "./TableWatchlist";

const WatchList = () => {
  return (
    <Box style={{ padding: 30 }}>
      <TableWatchlist />
    </Box>
  );
};

export default WatchList;
