import { LangConstant } from "const";

export const onHandleErrorChangePassword = (valueChangePassword, errorChangePassword) => {
  const regexPassword = /^(?=.*[0-9])(?=.*[A-Za-z])(?=.*\W)(?!.* )[^\s].{8,32}$/;

  let errorChangePasswordTmp = { ...errorChangePassword };

  if (valueChangePassword.currentPassword.length < 8) {
    errorChangePasswordTmp.currentPassword = LangConstant.MS_PASSWORD_LENGTH_INVALID;
  } else {
    errorChangePasswordTmp.currentPassword = "";
  }

  if (valueChangePassword.newPassword !== valueChangePassword.confirmPassword) {
    errorChangePasswordTmp.confirmPassword = LangConstant.MS_PASSWORD_CONFIRM_MISMATCH;
  } else {
    errorChangePasswordTmp.confirmPassword = "";
  }

  if (!regexPassword.test(valueChangePassword.newPassword)) {
    errorChangePasswordTmp.newPassword = LangConstant.MS_PASSWORD_INVALID;
  } else if (valueChangePassword.newPassword === valueChangePassword.currentPassword) {
    errorChangePasswordTmp.newPassword = LangConstant.MS_NEW_SAME_CURRENT_PASSWORD;
  } else {
    errorChangePasswordTmp.newPassword = "";
  }

  return errorChangePasswordTmp;
};
