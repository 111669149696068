import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import ShipActions from "redux-store/ship.redux";
import { makeStyles } from "@mui/styles";
import { Checkbox, FormGroup, FormControlLabel, Box } from "@mui/material";
import countryList from "react-select-country-list";

export default function FilterByManyNation() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const countryCodes = useMemo(() => countryList().getData(), []);
  const shipConditions = useSelector(state => state.shipRedux.shipConditions);

  const [selected, setSelected] = useState(shipConditions.country_code_list.map(type => type.uid || type));
  const [checkedAll, setCheckedAll] = useState(
    shipConditions.country_code_list.length === 0 || shipConditions.country_code_list.length === countryCodes.length,
  );

  const onChangeValue = event => {
    const value = event.target.value;
    const list = [...selected];
    if (value) {
      setCheckedAll(false);
      const index = list.indexOf(value);
      index === -1 ? list.push(value) : list.splice(index, 1);
      setSelected(list);
      dispatch(
        ShipActions.shipSet({
          isChangingFilter: true,
          shipConditions: Object.assign(shipConditions, { country_code_list: list }),
        }),
      );
    } else {
      if (!checkedAll) {
        setSelected(countryCodes.map(data => data.value));
        dispatch(
          ShipActions.shipSet({
            shipConditions: Object.assign(shipConditions, { country_code_list: countryCodes.map(data => data.value) }),
          }),
        );
      } else {
        setSelected([]);
        dispatch(
          ShipActions.shipSet({
            shipConditions: Object.assign(shipConditions, { country_code_list: [] }),
          }),
        );
      }
      setCheckedAll(!checkedAll);
    }
  };

  useEffect(() => {
    setSelected(shipConditions.country_code_list.map(type => type.uid || type));
  }, [shipConditions.country_code_list]);

  return (
    <FormGroup
      classes={{
        root: classes.filterByShipTypeBox,
      }}
    >
      <FormControlLabel
        key="all"
        control={<Checkbox value={null} onChange={onChangeValue} checked={checkedAll} />}
        label={<Box className={classes.nameAndIcon}> Tất cả </Box>}
        classes={{
          root: classes.controlForm,
          label: classes.shipTypeTitle,
        }}
      />
      {countryCodes.map(item => (
        <FormControlLabel
          key={item.value}
          control={<Checkbox value={item.value} onChange={onChangeValue} checked={selected.includes(item.value)} />}
          label={<Box className={classes.nameAndIcon}>{item.label}</Box>}
          classes={{
            root: classes.controlForm,
            label: classes.shipTypeTitle,
          }}
        />
      ))}
    </FormGroup>
  );
}

const useStyles = makeStyles(theme => ({
  filterByShipTypeBox: {
    display: "grid",
    background: "#fff",
    gridTemplateColumns: "auto",
    paddingLeft: "16px",
    width: "100%",
  },

  selectFilterCheckBox: {
    display: "flex",
  },

  shipTypeTitle: {
    fontSize: "14px",
    color: "#000",
    lineHeight: "100%",
    display: "flex",
    alignItems: "center",
    userSelect: "none",
    width: "100%",
  },

  nameAndIcon: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },

  controlForm: {
    width: "100%",
    display: "flex",
    gap: "4px",
  },

  navigationIcon: {
    fill: "pink",
    padding: "3px",
    userSelect: "none",
    display: "inline-block",
    flexShrink: 0,
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontSize: "24px",
  },
}));
