import React, { memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BadgeOutlined, CloudQueue, DirectionsBoat, FormatShapes, ScatterPlot, Moving, Map } from "@mui/icons-material";
import { useMapContext, useNotification } from "hooks";
import WatchListIcon from "theme/icons/WatchListIcon";
import { AppConstant } from "const";

const LayerTopRight = () => {
  const classes = useStyles();

  const {
    isShowWeather,
    setIsShowWeather,
    isShowShip,
    setIsShowShip,
    isClusteredShip,
    setIsClusteredShip,
    isShowShipName,
    setIsShowShipName,
    isShowShipSpeed,
    setIsShowShipSpeed,
    setIsOpenAreaManagementDialog,
    setShowMenuWatchList,
    setShowBackgroundMapList,
  } = useMapContext();
  const notification = useNotification();
  const dispatch = useDispatch();

  const handleClickAreaManagement = () => {
    setIsOpenAreaManagementDialog(true);
    setShowMenuWatchList(false);
    setShowBackgroundMapList(false);
  };

  const handleClickShowWeather = () => {
    setIsShowWeather(!isShowWeather);
  };

  const handleClickShowShip = () => {
    setIsShowShip(!isShowShip);
  };

  const handleClickClusteredShip = () => {
    setIsClusteredShip(!isClusteredShip);
  };

  const handleClickShowShipName = () => {
    if (isShowShipName) {
      setIsShowShipName(false);
    } else {
      setIsShowShipName(true);
    }
  };
  const handleClickShowSpeed = () => {
    if (isShowShipSpeed) {
      setIsShowShipSpeed(false);
    } else {
      setIsShowShipSpeed(true);
    }
  };

  const handleClickOpenWatchList = () => {
    setShowMenuWatchList(true);
    setIsOpenAreaManagementDialog(false);
    setShowBackgroundMapList(false);
  };

  const handleClickOpenMapList = () => {
    setShowBackgroundMapList(true);
    setShowMenuWatchList(false);
    setIsOpenAreaManagementDialog(false);
  };

  return (
    <Box className={classes.layerTopRightCont} component="section">
      <Button onClick={handleClickAreaManagement} className={classes.button} disableRipple title={"Quản lý khu vực"}>
        <FormatShapes fontSize="small" />
      </Button>
      <Button
        onClick={handleClickOpenWatchList}
        className={clsx(classes.button)}
        disableRipple
        title={"Cài đặt hiển thị danh sách giám sát"}
      >
        <WatchListIcon />
      </Button>
      <Button onClick={handleClickOpenMapList} className={clsx(classes.button)} disableRipple title={"Nền bản đồ"}>
        <Map />
      </Button>
      <Button
        onClick={handleClickShowWeather}
        className={clsx(classes.button, isShowWeather ? classes.active : "")}
        title={"Hiển thị thời tiết"}
      >
        <CloudQueue fontSize="small" />
      </Button>
      <Button
        onClick={handleClickShowShip}
        className={clsx(classes.button, isShowShip ? classes.active : "")}
        title={"Bật / Tắt tàu"}
      >
        <DirectionsBoat fontSize="small" />
      </Button>
      <Button
        onClick={handleClickClusteredShip}
        className={clsx(classes.button, isClusteredShip ? classes.active : "")}
        title={isClusteredShip ? "Hiển thị tất cả tàu" : "Hiển thị tàu theo nhóm"}
      >
        <ScatterPlot fontSize="small" />
      </Button>
      <Button
        onClick={handleClickShowShipName}
        className={clsx(classes.button, isShowShipName ? classes.active : "")}
        title={"Hiển thị tên tàu"}
      >
        <BadgeOutlined fontSize="small" />
      </Button>
      <Button
        onClick={handleClickShowSpeed}
        className={clsx(classes.button, isShowShipSpeed ? classes.active : "")}
        title={"Hiển thị vận tốc, hướng"}
      >
        <Moving fontSize="small" />
      </Button>
    </Box>
  );
};

export default memo(LayerTopRight);

const useStyles = makeStyles(theme => ({
  layerTopRightCont: {
    position: "fixed",
    zIndex: 1000,
    top: 70,
    right: 12,
    borderRadius: "4px",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #999999",
  },

  button: {
    width: 34,
    height: 34,
    padding: 0,
    margin: 0,
    minWidth: "unset",
    borderRadius: 0,
    background: "#ffffff !important",
    color: "#464646",
    borderBottom: "1px solid #999999",
    "&:last-child": {
      borderBottom: "none",
    },
    "&:hover": {
      background: "#f4f4f4 !important",
    },
  },

  active: {
    background: "#1976d2 !important",
    color: "#fff !important",
    "&:hover": {
      background: "#1976d2 !important",
      color: "#fff !important",
    },
  },
}));
