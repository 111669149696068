import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { BellImage } from "theme/images";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import EventWarningAction from "redux-store/eventwarning.redux";
import { EventWarningService } from "services";
import { convertMillisecondsToDateTime } from "utils/date.utils";
import { useNavigate } from "react-router-dom";
import { PathConstant } from "const";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useMapContext, useFlickerMarker, useNotification } from "hooks";

export default function WarningNotification() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const notification = useNotification();

  const dispatch = useDispatch();
  const handleOpenNotificationList = event => {
    dispatch(EventWarningAction.getTopEventWarningNotifications());
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const is_new = useSelector(state => state.eventWarningRedux.is_new);
  const listTopEventWarningNotification = useSelector(state => state.eventWarningRedux.listTopEventWarningNotification);
  const { map, mapState, flickerLayerRef } = useMapContext();
  const filterShipResult = useSelector(state => state.shipRedux.filterShipResult);
  const { createFlickerMarker } = useFlickerMarker(flickerLayerRef);

  const onReadAll = event => {
    EventWarningService.readAllEventWarningNotification().then(res => {
      dispatch(
        EventWarningAction.eventWarningSet({
          listTopEventWarningNotification: [...listTopEventWarningNotification].map(item => {
            return { ...item, is_read: true };
          }),
          is_new: 0,
        }),
      );
    });
  };

  const onJumpToShipNotification = (filterShip, data) => {
    handleClose();
    const shipNotification = filterShip.find(ship => ship.ship_uid === data.ship_uid);
    if (mapState === "loaded" && shipNotification) {
      map.current.setView([shipNotification.ship_lat, shipNotification.ship_long], 12);
      createFlickerMarker(shipNotification);
    } else {
      notification.send(
        "Không tìm thấy vị trí tàu trong khoảng thời gian xem dữ liệu. Vui lòng thử lọc dữ liệu theo một khoảng thời gian khác.",
        "info",
      );
    }
    if (data.is_read) return;
    EventWarningService.getEventWarningNotificationDetail(data.uid).then(res => {
      dispatch(
        EventWarningAction.eventWarningSet({
          listTopEventWarningNotification: [...listTopEventWarningNotification].map(item => {
            return item.uid === data.uid ? { ...item, is_read: true } : item;
          }),
          is_new: is_new - 1,
        }),
      );
    });
  };

  return (
    <div>
      <Badge
        color="secondary"
        badgeContent={is_new}
        // invisible={!is_new}
        className={classes.badge}
      >
        <img
          src={BellImage}
          alt="Loading"
          width="auto"
          height="31px"
          className={classes.bell}
          onClick={handleOpenNotificationList}
        />
      </Badge>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {listTopEventWarningNotification?.length ? (
          listTopEventWarningNotification?.map((item, index) => (
            <MenuItem onClick={() => onJumpToShipNotification(filterShipResult, item)}>
              <Box sx={{ opacity: !item?.is_read ? "1" : "0.5" }}>
                <Typography sx={{ color: "#000000" }} variant="p">
                  {item?.content}
                </Typography>
                <Typography sx={{ color: "#9095A1", display: "flex" }}>
                  <Typography width={160}>{convertMillisecondsToDateTime(item.created)}</Typography>
                  {!item?.is_read && <FiberManualRecordIcon color="error" sx={{ fontSize: 14 }} />}
                </Typography>
              </Box>
            </MenuItem>
          ))
        ) : (
          <Box className={classes.noNotification}>Chưa có thông báo</Box>
        )}
        <Box className={classes.buttonsMenu}>
          <Typography
            className={classes.buttonMenu}
            onClick={() => {
              navigate(PathConstant.ADMIN_NOTIFICATION_EVENT_WARNING_MANAGEMENT);
            }}
          >
            Xem thêm
          </Typography>
          <Typography
            className={classes.buttonMenu}
            onClick={() => {
              onReadAll();
            }}
          >
            Đánh dấu đã đọc
          </Typography>
        </Box>
      </Menu>
    </div>
  );
}
const useStyles = makeStyles(theme => ({
  bell: {
    cursor: "pointer",
  },
  badge: {
    "& .MuiBadge-badge": {
      backgroundColor: "#d32f2f",
    },
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  buttonMenu: {
    display: "flex",
    color: "#1976d2",
    width: "fit-content",
    cursor: "pointer",
    fontWeight: "bold",
  },
  buttonsMenu: {
    display: "flex",
    width: "100%",
    padding: "0 16px",
    justifyContent: "space-between",
  },
  noNotification: {
    width: "450px",
    height: "300px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    color: "#727272",
  },
}));
