import React, { Fragment, useState, useRef } from "react";
import { Close } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Tab, TextField, Typography, DialogActions } from "@mui/material";
import { ApiConstant, AppConstant, FormatConstant, LangConstant } from "const";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { makeStyles } from "@mui/styles";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ListShipReportDayTable from "./ListShipByDayTable";
import { ShipService } from "services";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { useDispatch } from "react-redux";
import ShipRedux from "redux-store/ship.redux";
import { downloadBlob } from "utils";

const ReportByDay = ({ open, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const listShipRef = useRef();
  const id = "report-by-day-container";

  const [tabValue, setTabVale] = useState(AppConstant.REPORT_DAY_TYPE.foreignShip.value);
  const [date, setDate] = useState(new Date().getTime());

  const onHandleChangeTab = (event, value) => {
    setTabVale(value);
    dispatch(
      ShipRedux.getListShipByDay({
        type: value,
        time: date,
        page: AppConstant.FIRST_PAGE,
        size: AppConstant.PAGE_SIZE,
      }),
    );
  };

  const onCloseDialog = () => {
    onClose();
  };

  const onChangeDate = event => {
    setDate(event.getTime());
  };

  const onHandleSearchByDay = () => {
    listShipRef.current.onHandleSearchByDay({
      type: tabValue,
      time: date,
      page: AppConstant.FIRST_PAGE,
      size: AppConstant.PAGE_SIZE,
    });
  };

  const onHandleExportReport = async () => {
    const response = await ShipService.exportListShipByDay({
      time: date,
    });

    if (response.status === ApiConstant.STT_OK) {
      downloadBlob(response.data, LangConstant.TXT_LIST_SHIP_BY_DAY_FILE_NAME);
    }
  };

  useState(() => {
    dispatch(
      ShipRedux.getListShipByDay({
        type: tabValue,
        time: date,
        page: AppConstant.FIRST_PAGE,
        size: AppConstant.PAGE_SIZE,
      }),
    );
  }, []);

  const onMinimize = event => {
    event.preventDefault();
    event.stopPropagation();

    let parentContainer = document.getElementById(id);
    if (parentContainer) {
      parentContainer.style.transitionDuration = "500ms";
      parentContainer.style.transform = 'scale(0.3)';
      parentContainer.style.bottom = "-160px";
      parentContainer.style.right = "-70%";

      let childContainer = parentContainer.children;
      if (childContainer) {
        childContainer[0].style.display = "none";
      }

      setTimeout(() => {
        parentContainer.style.removeProperty("transition-duration");
      }, 506);
    }
    // dragElement(id);
  };

  const onMaximize = () => {
    let parentContainer = document.getElementById(id);
    if (parentContainer) {
      parentContainer.style.transitionDuration = "500ms";
      parentContainer.style.transform = 'scale(1)';
      parentContainer.style.top = "0px";
      parentContainer.style.bottom = "0px";
      parentContainer.style.right = "0px";
      parentContainer.style.left = "0px";

      let childContainer = parentContainer.children;
      if (childContainer) {
        childContainer[0].style.display = "flex";
      }

      setTimeout(() => {
        parentContainer.style.removeProperty("transition-duration");
      }, 506);
    }
  };

  return (
    <Fragment>
      <Dialog open={open} maxWidth="lg" fullWidth onClose={onCloseDialog} id={id}>
        <DialogTitle>
          <Box className={classes.reportByDayTitleBox}>
            <Typography variant="caption" className={classes.reportByDayTitle}>
              {LangConstant.TXT_REPORT_BY_DAY}
            </Typography>
            <IconButton size="medium" onClick={onCloseDialog}>
              <Close fontSize="inherit" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.dialogContent} onClick={onMaximize}>
          <Box className={classes.actionContainer}>
            <Box className={classes.dateSearchContainer}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={LangConstant.L_DATE_REPORT}
                  value={date}
                  onChange={onChangeDate}
                  renderInput={params => <TextField size="small" {...params} />}
                  className={classes.dateTimePickerBox}
                  format={FormatConstant.FM_DD_MM_YYYY}
                />
              </LocalizationProvider>
              <Button variant="contained" onClick={onHandleSearchByDay} className={classes.submitButton}>
                {LangConstant.TXT_SEARCH}
              </Button>
            </Box>
            <Button variant="contained" onClick={onHandleExportReport} className={classes.exportButton}>
              {LangConstant.TXT_EXPORT_REPORT}
            </Button>
          </Box>
          <TabContext value={tabValue}>
            <Box className={classes.tabContainer}>
              <TabList onChange={onHandleChangeTab}>
                {Object.values(AppConstant.REPORT_DAY_TYPE).map(item => (
                  <Tab className={classes.tab} label={item.title} value={item.value} key={item.value} />
                ))}
              </TabList>
            </Box>
            {Object.values(AppConstant.REPORT_DAY_TYPE).map(item => (
              <TabPanel value={item.value} key={item.value}>
                <ListShipReportDayTable type={tabValue} time={date} ref={listShipRef} />
              </TabPanel>
            ))}
          </TabContext>
        </DialogContent>
        <DialogActions>
          <Box display="flex" marginRight={"16px"}>
            <Button variant="contained" onClick={onMinimize}>
              Thu nhỏ
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ReportByDay;

const useStyles = makeStyles({
  dialogContent: {
    overflow: "hidden",
  },

  reportByDayTitleBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  reportByDayTitle: {
    fontSize: 20,
    fontWeight: 500,
  },

  actionContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
  },

  dateSearchContainer: {
    marginTop: "8px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },

  dateTimePickerBox: {
    margin: "0 4px",
  },

  submitButton: {
    marginLeft: "16px",
  },

  exportButton: {
    minWidth: "fit-content",
    height: "40px",
    marginRight: "24px",
  },

  tabContainer: {
    marginTop: "24px",
    "& .Mui-selected": {
      color: "white",
      background: "#1976D2",
    },
  },

  tab: {
    width: "250px",
    color: "#595959",
    background: " #D9D9D9",
    borderTopLeftRadius: "25px",
    borderTopRightRadius: "25px",
    marginRight: "4px",
  },
});
