import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LangConstant } from "const";
import { useNotification } from "hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConversationRedux from "redux-store/conversation.redux";
import { onHandleErrorChangePassword } from "utils/user.utils";

const DialogChangePassword = ({ onCloseDialogPass }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notification = useNotification();

  const errChangePassword = useSelector(state => state.conversationRedux.errChangePassword);
  const changePasswordSuccess = useSelector(state => state.conversationRedux.changePasswordSuccess);

  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [valueChangePassword, setValueChangePassword] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errorChangePassword, setErrorChangePassword] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const onClose = () => {
    setErrorChangePassword({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    onCloseDialogPass();
    dispatch(
      ConversationRedux.conversationSet({
        errChangePassword: false,
      }),
    );
  };

  const onFormSubmit = event => {
    event.preventDefault();

    let errorChangePasswordTmp = onHandleErrorChangePassword(valueChangePassword, errorChangePassword);
    setErrorChangePassword(errorChangePasswordTmp);

    if (
      valueChangePassword.currentPassword &&
      valueChangePassword.newPassword &&
      valueChangePassword.confirmPassword &&
      !errorChangePasswordTmp.currentPassword &&
      !errorChangePasswordTmp.newPassword &&
      !errorChangePasswordTmp.confirmPassword
    ) {
      dispatch(
        ConversationRedux.changePassword({
          currentPassword: valueChangePassword.currentPassword,
          newPassword: valueChangePassword.newPassword,
          reNewPassword: valueChangePassword.confirmPassword,
        }),
      );
    }
  };

  const onHandleShowPassword = event => {
    setShowPassword({ ...showPassword, [event.currentTarget.name]: !showPassword[event.currentTarget.name] });
  };

  const onChangeValue = event => {
    setValueChangePassword({ ...valueChangePassword, [event.target.name]: event.target.value });
    setErrorChangePassword({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  useEffect(() => {
    if (changePasswordSuccess) {
      onCloseDialogPass();
      notification.send(LangConstant.MS_CHANGE_PASSWORD_SUCCESS, "success");
    }
    if (errChangePassword) {
      setErrorChangePassword({
        ...errorChangePassword,
        currentPassword: LangConstant.MS_PASSWORD_UNAUTHORIZED,
      });
    }
    dispatch(
      ConversationRedux.conversationSet({
        changePasswordSuccess: false,
        errChangePassword: false,
      }),
    );
  }, [changePasswordSuccess, errChangePassword]);

  return (
    <Dialog classes={{ paper: classes.paper }} open onClose={onClose}>
      <form className={classes.formChangePassword} onSubmit={onFormSubmit}>
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h5" className={classes.dialogTitle}>
            {LangConstant.OBJ_USER_ACTION.CHANGE_PASSWORD}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <InputLabel>{LangConstant.L_PASSWORD_PRESENT}</InputLabel>
          <TextField
            onChange={onChangeValue}
            variant="outlined"
            class={classes.inputChangePassword}
            required
            fullWidth
            name="currentPassword"
            type={showPassword.currentPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={onHandleShowPassword} name="currentPassword" edge="end">
                    {showPassword.currentPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errorChangePassword.currentPassword && (
            <Box className={classes.errorMessage}>{errorChangePassword.currentPassword}</Box>
          )}
          <InputLabel>{LangConstant.L_NEW_PASSWORD}</InputLabel>
          <TextField
            onChange={onChangeValue}
            variant="outlined"
            class={classes.inputChangePassword}
            required
            fullWidth
            name="newPassword"
            type={showPassword.newPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={onHandleShowPassword} name="newPassword" edge="end">
                    {showPassword.newPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errorChangePassword.newPassword && (
            <Box className={classes.errorMessage}>{errorChangePassword.newPassword}</Box>
          )}
          <InputLabel>{LangConstant.L_CONFIRM_PASSWORD}</InputLabel>
          <TextField
            onChange={onChangeValue}
            variant="outlined"
            class={classes.inputChangePassword}
            required
            fullWidth
            name="confirmPassword"
            type={showPassword.confirmPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={onHandleShowPassword} name="confirmPassword" edge="end">
                    {showPassword.confirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errorChangePassword.confirmPassword && (
            <Box className={classes.errorMessage}>{errorChangePassword.confirmPassword}</Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button className={classes.buttonSubmit} variant="outlined" onClick={onClose}>
            {LangConstant.TXT_CANCEL}
          </Button>
          <Button
            className={classes.buttonSubmit}
            variant="contained"
            type="submit"
            disabled={
              !valueChangePassword.currentPassword ||
              !valueChangePassword.newPassword ||
              !valueChangePassword.confirmPassword
            }
          >
            {LangConstant.TXT_BUTTON_CHANGE_PASSWORD}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogChangePassword;

const useStyles = makeStyles(() => ({
  paper: {
    width: "70%",
  },

  formChangePassword: {
    padding: "16px",
  },

  dialogTitle: {
    fontWeight: 600,
  },

  inputChangePassword: {
    margin: "8px auto",
  },

  errorMessage: {
    fontSize: 12,
    color: "red",
    marginBottom: "8px",
  },

  buttonSubmit: {
    marginRight: "16px",
    marginBottom: "16px",
  },
}));
