import React from "react";
import { HarborTable } from "./components";
import { Box } from "@mui/material";

const HaborManagement = () => {
  return (
    <Box style={{ padding: 30 }}>
      <HarborTable />
    </Box>
  );
};

HaborManagement.propTypes = {};

export default HaborManagement;
