import React from "react";
import { Box, CardMedia, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LangConstant } from "const";
import HorizontalBar from "./HorizontalBar";
import { PageIcon } from "theme/images";

export default function Navbar() {
  const classes = useStyles();
  return (
    <Box className={classes.navbarContainer}>
      <Box className={classes.leftNavbar}>
        <Box className={classes.logoContainer}>
          <Box className={classes.logoBox}>
            <CardMedia component="img" image={PageIcon} />
          </Box>
          <Box className={classes.pageTitleBox}>
            <Typography className={classes.pageTitle}>{LangConstant.TXT_PAGE_TITLE}</Typography>
          </Box>
        </Box>
        <Box className={classes.hotline}>
          Hotline Trực ban TTCH CSB: <p className={classes.hotlinePhone}> {` 567.271`}</p>
        </Box>
      </Box>
      <HorizontalBar />
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  navbarContainer: {
    height: 60,
    background: "#fff",
    display: "flex",
    width: "100vw",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;",
    overflow: "unset",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 1001,
  },

  logoContainer: {
    width: "360px",
    background: "#fff",
    height: "40px",
    display: "flex",
    alignItems: "center",
  },

  logoBox: {
    width: 40,
    height: 40,
    background: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 8,
  },

  pageTitleBox: {
    display: "flex",
    justifyContent: "center",
    boxSizing: "border-box",
    padding: "0 4px",
    alignItems: "center",
  },

  pageTitle: {
    textTransform: "uppercase",
    fontWeight: "500",
    whiteSpace: "pre",
    paddingLeft: 8,
    fontSize: 14,
  },

  hotline: {
    fontSize: "13px",
    color: "#000",
    paddingRight: 20,
    marginLeft: 8,
    display: "flex",
    lineHeight: "15px",
    height: "15px",
  },
  hotlinePhone: {
    color: "red",
    marginLeft: 8,
  },
}));
