import { memo, useEffect, useState, useRef } from "react";
import Cookies from "js-cookie";
import { Box, Button, Typography, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SystemConstant } from "const";
import { getPositionOfPopup } from "utils";
import { useMapContext } from "hooks";
import ShipActions from "redux-store/ship.redux";
import { useDispatch } from "react-redux";
import { EventBus } from "EventBus";
import { EventConstant, AppConstant } from "const";

const LayerContextMenuDialog = () => {
  const classes = useStyles();
  const { showMenuLayer, setWeatherDialog } = useMapContext();
  const { event, info } = showMenuLayer;
  const dispatch = useDispatch();
  const [dialogPositions, setDialogPositions] = useState({ top: 0, left: 0, bottom: 0, right: 0, display: "none" });
  const [loading, setLoading] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handleClose();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (event) {
      const pointerEvent = event.originalEvent;
      const { top, left, bottom, right } = getPositionOfPopup(pointerEvent);
      setLoading(false);
      setDialogPositions({ top, left, bottom, right, display: "block" });
    } else {
      setDialogPositions({ top: 0, left: 0, bottom: 0, right: 0, display: "none" });
    }
  }, [showMenuLayer]);

  const handleClose = () => {
    setDialogPositions(state => ({ ...state, display: "none" }));
  };

  const handleStopPropagation = e => {
    e.stopPropagation();
  };

  return (
    <Box
      ref={ref}
      className={classes.root}
      onClick={handleStopPropagation}
      onDoubleClick={handleStopPropagation}
      onMouseDown={handleStopPropagation}
      onMouseMove={handleStopPropagation}
      onWheel={handleStopPropagation}
      sx={dialogPositions}
      component="section"
    >
      <Box className={classes.formBody}>
        {parseInt(Cookies.get("role")) !== SystemConstant.ROLE.staff && (
          <Button
            variant="text"
            onClick={() => {
              dispatch(
                ShipActions.requestTrackingShip({
                  ...info,
                  uid: info.ship_uid,
                  distance: info.distance,
                  resetLocation: true,
                  update_f: true,
                }),
              );
              setLoading(true);
            }}
            fullWidth={true}
            classes={{
              root: classes.button,
              startIcon: classes.startIconBox,
              endIcon: classes.endIconBox,
            }}
            endIcon={loading ? <CircularProgress size={16} /> : <Box width={16}></Box>}
          >
            <Typography variant="caption" className={classes.buttonTitle}>
              Cập nhật vị trí
            </Typography>
          </Button>
        )}
        <Button
          variant="text"
          onClick={() => {
            const element = document.querySelector(".leaflet-ruler.leaflet-control");
            if (element && !element.classList.contains("leaflet-ruler-clicked")) {
              element.click();
              EventBus.emit(EventConstant.POINT_EVENTS.clickMeasure, event);
              setDialogPositions({ top: 0, left: 0, bottom: 0, right: 0, display: "none" });
            }
          }}
          fullWidth={true}
          classes={{
            root: classes.button,
            startIcon: classes.startIconBox,
            endIcon: classes.endIconBox,
          }}
        >
          <Typography variant="caption" className={classes.buttonTitle}>
            Đo khoảng cách từ đây
          </Typography>
        </Button>
        {AppConstant.CURRENT_SYSTEM_MODE === SystemConstant.SYSTEM_MODE.INTERNET && (
          <Button
            variant="text"
            onClick={() => {
              setWeatherDialog({ event: event });
              setDialogPositions({ top: 0, left: 0, bottom: 0, right: 0, display: "none" });
            }}
            fullWidth={true}
            classes={{
              root: classes.button,
              startIcon: classes.startIconBox,
              endIcon: classes.endIconBox,
            }}
          >
            {AppConstant.CURRENT_SYSTEM_MODE === 1 && (
              <Typography variant="caption" className={classes.buttonTitle}>
                Dự báo thời tiết - (Nguồn WWO)
              </Typography>
            )}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default memo(LayerContextMenuDialog);

const useStyles = makeStyles(() => ({
  root: {
    position: "fixed",
    width: 225,
    backgroundColor: "#fff",
    zIndex: 1000,
    boxShadow: "0px 0px 2px 2px rgba(0, 0, 0, 0.25)",
    borderRadius: 4,
    cursor: "auto",
  },

  formBody: {
    padding: "2px",
    position: "relative",
  },

  button: {
    justifyContent: "normal",
  },
}));
