import React, { memo, useEffect } from "react";
import L from "leaflet";
import { EventConstant } from "const";
import { useMapContext } from "hooks";
import { EventBus } from "EventBus";
import MapLayers from "./layers";
import { checkAndSetOpacityPopup, metresPerPixel } from "utils";

const Map = () => {
  const {
    isShowSidebar,
    map,
    mapState,
    setHoverShip,
    hoverShip,
    hoverPoint,
    setHoverPoint,
    videoTrackingOptions,
    setZoomSize,
    setMetresPerPixel,
  } = useMapContext();

  const handleMapMoveStart = e => {
    EventBus.emit(EventConstant.MAP_EVENTS.movestart, e);
  };

  const handleMapMoveEnd = e => {
    EventBus.emit(EventConstant.MAP_EVENTS.moveend, e);
    checkAndSetOpacityPopup();
    setMetresPerPixel(metresPerPixel(map?.current));
  };

  const handleMapZoomEnd = e => {
    EventBus.emit(EventConstant.MAP_EVENTS.zoomend, e);
    checkAndSetOpacityPopup();
    setMetresPerPixel(metresPerPixel(map?.current));
    setZoomSize(e.target._zoom);
  };

  const handleMapContextMenu = e => {
    EventBus.emit(EventConstant.MAP_EVENTS.contextmenu, e);
  };

  const handleMapMouseMove = e => {
    EventBus.emit(EventConstant.MAP_EVENTS.mousemove, e);
  };

  const handleMapMouseOut = e => {
    EventBus.emit(EventConstant.MAP_EVENTS.mouseout, e);
  };

  const handleMapDrawStart = e => {
    EventBus.emit(EventConstant.MAP_DRAW_EVENTS.DRAWSTART, e);
  };

  const handleMapDrawStop = e => {
    EventBus.emit(EventConstant.MAP_DRAW_EVENTS.DRAWSTOP, e);
  };

  const handleMapDrawCreated = e => {
    EventBus.emit(EventConstant.MAP_DRAW_EVENTS.CREATED, e);
  };

  const handleMapDrawEditStart = e => {
    EventBus.emit(EventConstant.MAP_DRAW_EVENTS.EDITSTART, e);
  };

  const handleMapDrawEditStop = e => {
    EventBus.emit(EventConstant.MAP_DRAW_EVENTS.EDITSTOP, e);
  };

  const handleMapDrawEdited = e => {
    EventBus.emit(EventConstant.MAP_DRAW_EVENTS.EDITED, e);
  };

  const handleMapDrawDeleteStart = e => {
    EventBus.emit(EventConstant.MAP_DRAW_EVENTS.DELETESTART, e);
  };

  const handleMapDrawDeleteStop = e => {
    EventBus.emit(EventConstant.MAP_DRAW_EVENTS.DELETESTOP, e);
  };

  const handleMapDrawDeleted = e => {
    EventBus.emit(EventConstant.MAP_DRAW_EVENTS.DELETED, e);
  };

  const handleCloseShipTooltipAndPopup = () => {
    // if (clickedShip.event || clickedShip.info) setClickedShip({ event: null, info: null });
    if (hoverShip.event || hoverShip.info) setHoverShip({ event: null, info: null });
    if (hoverPoint.event || hoverPoint.info) setHoverPoint({ event: null, info: null });
  };

  useEffect(() => {
    if (mapState === "loaded") {
      map.current.on("movestart", handleMapMoveStart);
      map.current.on("moveend", handleMapMoveEnd);
      map.current.on("zoomend", handleMapZoomEnd);
      map.current.on("contextmenu", handleMapContextMenu);
      map.current.on("mousemove", handleMapMouseMove);
      map.current.on("mouseout", handleMapMouseOut);
      map.current.on(L.Draw.Event.DRAWSTART, handleMapDrawStart);
      map.current.on(L.Draw.Event.DRAWSTOP, handleMapDrawStop);
      map.current.on(L.Draw.Event.CREATED, handleMapDrawCreated);
      map.current.on(L.Draw.Event.EDITSTART, handleMapDrawEditStart);
      map.current.on(L.Draw.Event.EDITSTOP, handleMapDrawEditStop);
      map.current.on(L.Draw.Event.EDITED, handleMapDrawEdited);
      map.current.on(L.Draw.Event.DELETESTART, handleMapDrawDeleteStart);
      map.current.on(L.Draw.Event.DELETESTOP, handleMapDrawDeleteStop);
      map.current.on(L.Draw.Event.DELETED, handleMapDrawDeleted);
    }

    return () => {
      map.current.off("movestart", handleMapMoveStart);
      map.current.off("moveend", handleMapMoveEnd);
      map.current.off("zoomend", handleMapZoomEnd);
      map.current.off("contextmenu", handleMapContextMenu);
      map.current.off("mousemove", handleMapMouseMove);
      map.current.off("mouseout", handleMapMouseOut);
      map.current.off(L.Draw.Event.DRAWSTART, handleMapDrawStart);
      map.current.off(L.Draw.Event.DRAWSTOP, handleMapDrawStop);
      map.current.off(L.Draw.Event.CREATED, handleMapDrawCreated);
      map.current.off(L.Draw.Event.EDITSTART, handleMapDrawEditStart);
      map.current.off(L.Draw.Event.EDITSTOP, handleMapDrawEditStop);
      map.current.off(L.Draw.Event.EDITED, handleMapDrawEdited);
      map.current.off(L.Draw.Event.DELETESTART, handleMapDrawDeleteStart);
      map.current.off(L.Draw.Event.DELETESTOP, handleMapDrawDeleteStop);
      map.current.off(L.Draw.Event.DELETED, handleMapDrawDeleted);
    };
  }, [mapState]);

  useEffect(() => {
    EventBus.on(EventConstant.MAP_EVENTS.moveend, handleCloseShipTooltipAndPopup);
    return () => EventBus.off(EventConstant.MAP_EVENTS.moveend, handleCloseShipTooltipAndPopup);
  });

  return (
    <div
      style={{
        height: "100%",
        marginLeft: isShowSidebar ? "360px" : "0px",
        width: isShowSidebar ? "calc(100vw - 360px)" : "100vw",
      }}
    >
      <div
        id="map"
        style={{
          height: "100%",
          width: "100%",
          transition: "all .1s linear",
          outline: "none",
        }}
        className={videoTrackingOptions.open ? "video-tracking" : ""}
      >
        <MapLayers />
      </div>
    </div>
  );
};

export default memo(Map);
