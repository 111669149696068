import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getUserInfo: ["data"],
  exportUsersInfo: ["data"],
  exportUserInfo: ["data"],
  changeAvatar: ["data"],
  changeTwoFA: [],

  userSet: ["data"],
  userReset: [],
});

export const UserTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  userInfo: null,
  isChangeTwoFASuccess: false,
};

/* ------------- Reducers ------------- */

export const request = (state = INITIAL_STATE, action) => {
  let data = action.data || {};
  return {
    ...state,
    ...data,
    isFetching: true,
  };
};

export const set = (state = INITIAL_STATE, action) => {
  let data = action.data || {};

  return {
    ...state,
    ...data,
  };
};

export const reset = () => INITIAL_STATE;

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_USER_INFO]: request,
  [Types.EXPORT_USERS_INFO]: request,
  [Types.EXPORT_USER_INFO]: request,
  [Types.CHANGE_AVATAR]: request,
  [Types.CHANGE_TWO_FA]: request,

  [Types.USER_SET]: set,
  [Types.USER_RESET]: reset,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
