import { UserService } from "services";
import { ApiConstant, LangConstant } from "const";
import UserAction from "redux-store/user.redux";
import { call, put, select } from "redux-saga/effects";
import { toCamel, handleSendNotiInSaga, downloadBlob } from "utils";

export function* getUserInfo(action) {
  try {
    const { data } = action;
    const userInfo = yield select(state => state.userRedux.userInfo);

    let response = yield call(UserService.getUserInfo, data);
    const dataResponse = response.data?.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        UserAction.userSet({
          userInfo: { ...userInfo, ...toCamel(dataResponse) },
        }),
      );
    } else {
      yield put(
        UserAction.userSet({
          userInfo: null,
        }),
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      UserAction.userSet({
        userInfo: null,
      }),
    );
  }
}

export function* exportUsersInfo(action) {
  try {
    const { data } = action;

    let response = yield call(UserService.exportUsersInfo, data);

    const dataResponse = response.data;
    if (response.status === ApiConstant.STT_OK) {
      downloadBlob(dataResponse, "danh_sach_nguoi_dung.xlsx");
      handleSendNotiInSaga(LangConstant.MS_EXPORT_PROFILE_SUCCESS, "success");
    } else {
      handleSendNotiInSaga(LangConstant.MS_EXPORT_PROFILE_FAILED, "error");
    }
  } catch (error) {
    handleSendNotiInSaga(LangConstant.MS_EXPORT_PROFILE_FAILED, "error");
    console.log(error);
  }
}

export function* exportUserInfo(action) {
  try {
    const { data } = action;

    let response = yield call(UserService.exportUserInfo, data);

    const dataResponse = response.data;
    if (response.status === ApiConstant.STT_OK) {
      downloadBlob(dataResponse, "thong_tin_ca_nhan.xlsx");
      handleSendNotiInSaga(LangConstant.MS_EXPORT_PROFILE_SUCCESS, "success");
    } else {
      handleSendNotiInSaga(LangConstant.MS_EXPORT_PROFILE_FAILED, "error");
    }
  } catch (error) {
    handleSendNotiInSaga(LangConstant.MS_EXPORT_PROFILE_FAILED, "error");
    console.log(error);
  }
}

export function* changeAvatar(action) {
  try {
    const { data } = action;
    const userInfo = yield select(state => state.userRedux.userInfo);

    let response = yield call(UserService.changeAvatar, data);
    const dataResponse = response.data?.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        UserAction.userSet({
          userInfo: { ...userInfo, ...toCamel(dataResponse) },
        }),
      );
      handleSendNotiInSaga(LangConstant.MS_EDIT_PROFILE_SUCCESS, "success");
    } else {
      handleSendNotiInSaga(LangConstant.MS_EDIT_PROFILE_FAILED, "error");
    }
  } catch (error) {
    handleSendNotiInSaga(LangConstant.MS_EDIT_PROFILE_FAILED, "error");
    console.log(error);
  }
}

export function* changeTwoFA() {
  try {
    let response = yield call(UserService.changeTwoFA);

    if (response.status === ApiConstant.STT_OK) {
      yield put(
        UserAction.userSet({
          isChangeTwoFASuccess: true,
        }),
      );
    } else {
      yield put(
        UserAction.userSet({
          isChangeTwoFASuccess: false,
        }),
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      UserAction.userSet({
        isChangeTwoFASuccess: false,
      }),
    );
  }
}
