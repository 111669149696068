import UserActionTable from "pages/AdminDashboard/CurrentUserAction/UserActionTable";
import { useParams } from "react-router";

const UserActionPersonal = () => {
  const { userId } = useParams();

  return <UserActionTable userId={userId} />;
};

export default UserActionPersonal;
