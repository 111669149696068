import { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Download, Search } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { EditUserPermissionPopup, PopupConfirm } from "components";
import DialogCreateUser from "./DialogCreateUser";
import { AppConstant, LangConstant } from "const";
import { useDispatch, useSelector } from "react-redux";
import ConversationRedux from "redux-store/conversation.redux";
import UserRedux from "redux-store/user.redux";
import PopupResetPassword from "./PopupResetPassword";
import Cookies from "js-cookie";
import UserManagamentTable from "./UserManagamentTable";
import { hasRoleScopes } from "utils";
import { debounce } from "lodash";

const UserManagement = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const roleId = Cookies.get(AppConstant.KEY_ROLE_ID);
  const successGetUser = useSelector(state => state.conversationRedux.successGetUser);
  const successDeleteUser = useSelector(state => state.conversationRedux.successDeleteUser);
  const successEditUser = useSelector(state => state.conversationRedux.successEditUser);
  const createUserSuccess = useSelector(state => state.conversationRedux.createUserSuccess);
  const successResestPassword = useSelector(state => state.conversationRedux.successResestPassword);

  const [open, setOpen] = useState(false);
  const [isShow, setIshow] = useState(false);
  const [dataEdit, setDataEdit] = useState("");
  const [editUser, setEditUser] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [pagination, setPagination] = useState(1);
  const [valueSearch, setValueSearch] = useState();
  const [filterValue, setFilterValue] = useState({
    application: AppConstant.APPLICATION_TYPE_ALL,
    role: AppConstant.ROLE_NAME_ALL,
  });

  const onChangeFilterValue = event => {
    setFilterValue({ ...filterValue, [event.target.name]: parseInt(event.target.value) });
  };

  const onExportListUsers = () => {
    const usersIdList = successGetUser.map(item => item.uid);
    dispatch(
      UserRedux.exportUsersInfo({
        name: valueSearch === null ? undefined : valueSearch,
        role: filterValue.role === AppConstant.ROLE_NAME_ALL ? undefined : filterValue.role,
        application: filterValue.application === AppConstant.APPLICATION_TYPE_ALL ? undefined : filterValue.application,
        export_f: 1,
      }),
    );
  };

  useEffect(() => {
    dispatch(
      ConversationRedux.getUserList({
        page: pagination,
        name: valueSearch === null ? undefined : valueSearch,
        role: filterValue.role === AppConstant.ROLE_NAME_ALL ? undefined : filterValue.role,
        application: filterValue.application === AppConstant.APPLICATION_TYPE_ALL ? undefined : filterValue.application,
      }),
    );
    dispatch(
      ConversationRedux.conversationSet({
        successDeleteUser: false,
        successEditUser: false,
        createUserSuccess: false,
        successResestPassword: false,
      }),
    );
  }, [
    successDeleteUser,
    successEditUser,
    pagination,
    createUserSuccess,
    successResestPassword,
    filterValue,
    valueSearch,
    open,
  ]);

  useEffect(() => {
    if (successGetUser.length === 0 && pagination !== 1) {
      dispatch(
        ConversationRedux.getUserList({
          page: 1,
          name: valueSearch === null ? undefined : valueSearch,
          role: filterValue.role === AppConstant.ROLE_NAME_ALL ? undefined : filterValue.role,
          application:
            filterValue.application === AppConstant.APPLICATION_TYPE_ALL ? undefined : filterValue.application,
        }),
      );
      setPagination(1);
    }
  }, [successGetUser]);

  return (
    <Box className={classes.container}>
      {parseInt(roleId) <= AppConstant.ROLE.MANAGER ? (
        <Box className={classes.headerContainer}>
          <FormControl sx={{ width: 400 }}>
            <TextField
              sx={{ marginY: 2 }}
              onChange={debounce(e => {
                setValueSearch(e.target.value);
              }, 500)}
              fullWidth
              size="small"
              placeholder={LangConstant.L_SEARCH}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <Box>
            <Button color="warning" variant="outlined" onClick={onExportListUsers}>
              <Download className={classes.icon} />
              {LangConstant.TXT_EXPORT_BY_EXCEL}
            </Button>
            {hasRoleScopes(AppConstant.SCOPES.USER_CREATE) && (
              <Button
                color="warning"
                variant="contained"
                className={classes.buttonAddUser}
                onClick={() => setOpen(true)}
              >
                {LangConstant.TXT_ADD_USER}
              </Button>
            )}
          </Box>
        </Box>
      ) : (
        ""
      )}
      <Box className={classes.filterContainer}>
        <FormControl size="small">
          <InputLabel className={classes.labelFilter}>{LangConstant.TXT_SYSTEM}</InputLabel>
          <Select
            className={classes.selectFilter}
            name="application"
            value={filterValue.application}
            onChange={onChangeFilterValue}
          >
            {Object.keys(AppConstant.APPLICATION_TYPE)
              .reverse()
              .map((value, index) => (
                <MenuItem key={index} value={value}>
                  {AppConstant.APPLICATION_TYPE[value]}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl size="small">
          <InputLabel className={classes.labelFilter}>{LangConstant.TXT_PERMISSION}</InputLabel>
          <Select className={classes.selectFilter} name="role" value={filterValue.role} onChange={onChangeFilterValue}>
            {Object.keys(AppConstant.ROLE_NAME).map((value, index) => (
              <MenuItem key={index} value={value}>
                {AppConstant.ROLE_NAME[value]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <UserManagamentTable
        pagination={pagination}
        setEditUser={setEditUser}
        setDataEdit={setDataEdit}
        setIshow={setIshow}
        setResetPassword={setResetPassword}
        setChangePassword={setChangePassword}
        setPagination={setPagination}
      />
      {open && <DialogCreateUser setOpen={setOpen} open={open} />}
      {editUser && (
        <EditUserPermissionPopup dataEdit={dataEdit} disable open={editUser} onClose={() => setEditUser(false)} />
      )}
      <PopupConfirm
        dataEdit={dataEdit}
        title={LangConstant.TXT_CONFIRM_DELETE_USER}
        isShow={isShow}
        onClose={() => setIshow(false)}
      />
      <PopupConfirm
        dataEdit={dataEdit}
        title={LangConstant.TXT_CONFIRM_CHANGE_PASSWORD}
        password
        isShow={changePassword}
        onClose={() => setChangePassword(false)}
      />
      <PopupResetPassword data={dataEdit} open={resetPassword} onClose={() => setResetPassword(false)} />
    </Box>
  );
};

export default UserManagement;

const useStyles = makeStyles({
  container: {
    padding: 30,
  },

  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 20,
    marginBottom: 20,
  },

  filterContainer: {
    padding: 10,
    display: "flex",
    justifyContent: "end",
    marginTop: 10,
    marginBottom: 10,
  },

  buttonAddUser: {
    marginLeft: 20,
  },

  icon: {
    marginRight: 5,
  },

  selectFilter: {
    marginLeft: 20,
    width: 210,
  },

  labelFilter: {
    marginLeft: 20,
    background: "white",
    padding: "0px 5px",
  },
});
