import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ShipActions from "redux-store/ship.redux";
import WeatherActions from "redux-store/weather.redux";
import { some, debounce } from "lodash";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormGroup,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  Search,
  SailingOutlined,
  Cached,
  DeleteOutline,
} from "@mui/icons-material";
import { AppConstant, EventConstant, LangConstant, SystemConstant } from "const";
import FilterTypes from "./Filter/FilterTypes";
import ShipList from "./ShipMonitoring/ShipList";
import StringFormat from "string-format";
import TableShipAfterFilter from "./Filter/TableShipAfterFilter";
import clsx from "clsx";
import ConversationRedux from "redux-store/conversation.redux";
import { useMapContext, useFlickerMarker } from "hooks";
import { EventBus } from "EventBus";
import { SCOPES } from "const/app.const";
import { hasRoleScopes } from "utils";

const ShipManage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { map, mapState, sidebarMode, flickerLayerRef } = useMapContext();

  const addGroupShip = useSelector(state => state.conversationRedux.addGroupShip);
  const filterShipSelect = useSelector(state => state.shipRedux.filterShipSelect);
  const filterShipList = useSelector(state => state.shipRedux.filterShipList);
  const filterShipDataTable = useSelector(state => state.shipRedux.filterShipDataTable);
  const shipConditions = useSelector(state => state.shipRedux.shipConditions);

  const [chooseType, setChooseType] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [shipSearchList] = useState([]);
  const [isOpenTableData, setIsOpenTableData] = useState(false);
  const [isOpenSearchDialog, setIsOpenSearchDialog] = useState(false);
  const [searchAnchorEl, setSearchAnchorEl] = useState(null);
  const [selectedShips, setSelectedShips] = useState([]);
  const [searchShipHistory, setSearchShipHistory] = useState(
    JSON.parse(localStorage.getItem(AppConstant.LOCAL_STORAGE_KEY.shipSearchHistory) || "[]"),
  );
  const { createFlickerMarker } = useFlickerMarker(flickerLayerRef);

  const onClickChooseType = item => {
    if (chooseType.includes(parseInt(item.id))) {
      const temp = chooseType.filter(type => type !== item.id);
      setChooseType(temp);
    } else {
      setChooseType(prev => [...prev, item.id]);
    }
  };

  const onChangeText = e => {
    setSearchText(e);
  };

  const onCloseTableData = () => {
    setIsOpenTableData(false);
    dispatch(
      ShipActions.shipSet({
        filterShipDataTable: {},
        filterShipDataTablePage: 1,
        filterShipDataTableTotalPages: 1,
      }),
    );
  };

  const onClickSearch = () => {
    dispatch(ShipActions.requestFilterShips({ bounds: map.current.getBounds() }));
  };

  const searchByShipsList = ship => {
    dispatch(ShipActions.requestFilterShips({ bounds: map.current.getBounds(), selectShip: ship?.uid }));

    if (!searchShipHistory.map(data => data.uid).includes(ship.uid)) setSearchShipHistory(state => [ship, ...state]);
  };

  useEffect(() => {
    //Save to local storage
    localStorage.setItem(
      AppConstant.LOCAL_STORAGE_KEY.shipSearchHistory,
      JSON.stringify(searchShipHistory.filter((data, index) => index < 5)),
    );
  }, [searchShipHistory]);

  const onFocusSearchInput = e => {
    setSearchAnchorEl(e.target.parentElement.parentElement);
    setIsOpenSearchDialog(filterShipList.status !== "idle");
  };

  const onBlurSearchInput = () => {
    setSearchAnchorEl(null);
    setIsOpenSearchDialog(false);
  };

  const onResetFilter = () => {
    window.location.reload();
  };

  const onShowDataTable = () => {
    dispatch(
      ShipActions.requestGetFilterShipInDataTable({
        bounds: map.current.getBounds(),
        size: DATA_TABLE_PAGE_SIZE,
        page: 1,
      }),
    );
  };

  const onSendRequestSearchToSGW = () => {
    EventBus.emit(EventConstant.GATEWAY_EVENTS.syncShipInfo, searchText);
  };

  const onDeleteHistorySearchShip = ship => {
    setSearchShipHistory(l => l.filter(data => data.uid !== ship.uid));
  };

  useEffect(() => {
    if (searchText) {
      dispatch(ShipActions.shipSet({ filterShipList: { status: "pending", data: [] } }));
      dispatch(ShipActions.requestSearchShips({ filter: searchText, isManageShip: sidebarMode === "management" }));
    } else {
      dispatch(ShipActions.shipSet({ filterShipList: { status: "idle", data: [] } }));
    }
  }, [searchText]);

  useEffect(() => {
    if (Object.values(filterShipDataTable).length > 0) {
      setIsOpenTableData(true);
    }
  }, [filterShipDataTable]);

  useEffect(() => {
    if (mapState === "loaded") {
      const fromTime = Math.floor(new Date().getTime() / 1000);
      if (hasRoleScopes(SCOPES.GROUP_SHIP_LIST)) {
        dispatch(ConversationRedux.getGroupShip({ from: fromTime }));
      }

      dispatch(ShipActions.requestFilterShips({ bounds: map.current.getBounds() }));
    }
  }, [mapState, addGroupShip]);

  useEffect(() => {
    dispatch(ShipActions.requestGetTrackingShips());
  }, []);

  useEffect(() => {
    if (filterShipList.status !== "idle") {
      setIsOpenSearchDialog(true);
    }
  }, [filterShipList]);

  useEffect(() => {
    dispatch(
      ShipActions.shipSet({
        shipConditions: Object.assign(shipConditions, { handleGroupMembers: selectedShips.map(ship => ship) }),
      }),
    );
  }, [selectedShips]);

  useEffect(() => {
    if (filterShipSelect?.length === 1) {
      const shipNotification = filterShipSelect[0];
      if (mapState === "loaded" && shipNotification) {
        map.current.setView([shipNotification.ship_lat, shipNotification.ship_long], 12);
        createFlickerMarker(shipNotification);
      }
    }
  }, [filterShipSelect]);

  return (
    <Box className={clsx(classes.shipManageContainer, { hidden: sidebarMode === "tracking" })}>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        fullWidth
        variant="outlined"
        placeholder={LangConstant.P_INPUT_SEARCH}
        size="small"
        onChange={debounce(e => {
          onChangeText(e.target.value);
        }, 500)}
        onFocus={onFocusSearchInput}
        autoComplete="off"
        className={classes.searchTextfield}
      />
      <Popover
        open={isOpenSearchDialog}
        anchorEl={searchAnchorEl}
        onClose={onBlurSearchInput}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        disableRestoreFocus={true}
        classes={{ paper: classes.searchDialog }}
        keepMounted={true}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {filterShipList.status !== "idle" ? (
          filterShipList.status === "pending" ? (
            <>
              <Box className={classes.dialogNotification}>
                <Search fontSize="small" color="primary" className={classes.notificationIcon} />
                <Typography>Đang tìm kiếm kết quả...</Typography>
              </Box>
            </>
          ) : filterShipList.data.length > 0 ? (
            <>
              <Typography paddingBottom={1} variant="h6">
                Kết quả tìm kiếm:
              </Typography>
              <FormGroup classes={{ root: classes.formRow }}>
                {filterShipList.data.map(ship => (
                  <Box className={classes.chooseShip} onClick={() => searchByShipsList(ship)}>
                    <SailingOutlined className={classes.notificationIcon} />
                    <Box>
                      <Typography>{ship.name}</Typography>
                      <Typography display="block" variant="caption">
                        {ship.mmsi ? "MMSI: " + ship.mmsi : <></>}{" "}
                        {"(" + AppConstant.DATA_SOURCES[ship?.source_type] + ")"}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </FormGroup>
            </>
          ) : (
            <>
              <Typography paddingBottom={1} variant="h6">
                Kết quả tìm kiếm:
              </Typography>
              <Typography variant="caption" display="flex" alignItems="center" justifyContent="space-between">
                Không có kết quả tìm kiếm
                {AppConstant.CURRENT_SYSTEM_MODE === SystemConstant.SYSTEM_MODE.WAN && (
                  <IconButton
                    onClick={onSendRequestSearchToSGW}
                    sx={{ justifyContent: "flex-start" }}
                    fullWidth
                    title={LangConstant.TXT_SEND_REQUEST_TO_SGW}
                  >
                    <Cached />
                  </IconButton>
                )}
              </Typography>
            </>
          )
        ) : (
          <></>
        )}
      </Popover>
      {shipSearchList.length > 0 && (
        <>
          <Box className={classes.shipQuantity}>
            <Typography className={classes.quantityShipTitle}>
              {StringFormat(LangConstant.FM_QUANTITY_SHIP, shipSearchList.length)}
            </Typography>
          </Box>
          <ShipList data={shipSearchList} />
        </>
      )}

      {searchShipHistory.length > 0 && (
        <>
          <Typography variant="h6" marginBottom={1}>
            {LangConstant.L_HISTORY_SEARCH_SHIP_LIST}
          </Typography>
          <FormGroup classes={{ root: classes.formRow }}>
            {searchShipHistory.map((ship, index) => (
              <Box className={classes.selectedShip} key={ship.uid}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <SailingOutlined className={classes.notificationIcon} />
                  <Box>
                    <Box
                      display="flex"
                      alignItems="flex-end"
                      gap="4px"
                      onClick={() => searchByShipsList(ship)}
                      sx={{ cursor: "pointer" }}
                    >
                      <Typography>{ship.name}</Typography>
                      <Typography variant="caption">
                        {"(" + AppConstant.DATA_SOURCES[ship?.source_type] + ")"}
                      </Typography>
                    </Box>
                    <Typography display="block" variant="caption">
                      {ship.mmsi ? "MMSI: " + ship.mmsi : <></>}
                    </Typography>
                  </Box>
                </Box>
                <IconButton size="small" onClick={e => onDeleteHistorySearchShip(ship)}>
                  <DeleteOutline className={classes.deleteShipButton} fontSize="small" />
                </IconButton>
              </Box>
            ))}
          </FormGroup>
          <Box component="hr" />
        </>
      )}

      <Box className={classes.filterVesselContainer}>
        <Box className={classes.filterShipDataTitleBox}>
          <Typography className={classes.filterShipDataTitle}>{LangConstant.TXT_FILTER_SHIP_DATA}</Typography>
        </Box>
        <Box className={classes.filterTypeContainer}>
          {FilterTypes.filter(filterType => {
            if (!hasRoleScopes(SCOPES.GROUP_SHIP_LIST)) {
              return filterType.id !== 7;
            }
            return true;
          }).map((item, index) => (
            <Box key={item.id}>
              <Box onClick={() => onClickChooseType(item)} className={classes.filterTypeBox}>
                <Typography className={classes.filterTypeTitle}>
                  {StringFormat(LangConstant.FM_FILTER_TYPE, index, item.title)}
                </Typography>
                <>
                  {chooseType.includes(parseInt(item.id)) ? (
                    <KeyboardArrowUp className={classes.colorBlack} />
                  ) : (
                    <KeyboardArrowDown className={classes.colorBlack} />
                  )}
                </>
              </Box>
              <Box
                className={clsx(classes.optionContainer, {
                  [classes.optionContainerHidden]: !chooseType.includes(parseInt(item.id)),
                  [classes.scrollable]: item.scrollabel,
                })}
              >
                <item.Children />
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box className={classes.buttonWrapper}>
        <Button variant="outlined" onClick={onShowDataTable} fullWidth>
          Xem dữ liệu dạng bảng
        </Button>
        <Box
          className={classes.actionContainer}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
          marginBottom={1}
        >
          <Button
            variant="contained"
            onClick={onResetFilter}
            disableElevation
            fullWidth
            className={classes.resetButton}
          >
            {LangConstant.TXT_RESET_FILTER}
          </Button>
          <Button
            variant="contained"
            onClick={onClickSearch}
            disableElevation
            fullWidth
            className={classes.resetButton}
          >
            {LangConstant.TXT_SEARCH}
          </Button>
        </Box>
      </Box>
      {mapState === "loaded" && (
        <Dialog open={isOpenTableData} onClose={onCloseTableData} maxWidth="lg" fullWidth>
          <DialogTitle id="alert-dialog-title">{LangConstant.TXT_SHIP_LIST}</DialogTitle>
          <DialogContent>
            <TableShipAfterFilter
              size={DATA_TABLE_PAGE_SIZE}
              bounds={map.current.getBounds()}
              onClose={onCloseTableData}
            />
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default ShipManage;

const DATA_TABLE_PAGE_SIZE = 10;

const useStyles = makeStyles(theme => ({
  shipManageContainer: {
    width: "inherit",
    height: "calc(100vh - 60px)",
    background: "#fff",
    padding: "8px 8px 0 8px",
    overflowY: "auto",
    boxSizing: "border-box",

    "&::-webkit-scrollbar": {
      width: "0",
    },
  },

  filterTypeBox: {
    background: "#fff",
    display: "flex",
    width: "100%",
    height: 40,
    alignItems: "center",
    justifyContent: "space-between",
    boxSizing: "border-box",
    "&:hover": {
      cursor: "pointer",
    },
  },

  filterShipDataTitleBox: {
    marginTop: 8,
  },

  filterShipDataTitle: {
    fontSize: 20,
    fontWeight: 600,
  },

  filterTypeTitle: {
    fontSize: "16px",
    color: "#000",
    userSelect: "none",
    marginTop: "16px",
  },

  showDataWithTableBox: {
    marginTop: 8,
  },

  buttonWrapper: {
    marginTop: 16,
    display: "flex",
    flexDirection: "column",
    "& > button": {
      marginBottom: 8,
      boxShadow: "unset",
    },
  },

  viewDataTitle: {
    fontSize: "14px",
    color: "#000",
    lineHeight: "100%",
    display: "flex",
    alignItems: "center",
    userSelect: "none",
  },

  colorBlack: {
    color: "#000",
  },

  quantityShipTitle: {
    fontSize: 13,
    fontWeight: 400,
  },

  searchDialog: {
    left: "8px !important",
    marginTop: 4,
    width: "344px",
    padding: 8,
  },

  dialogNotification: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  notificationIcon: {
    marginRight: 8,
  },

  label: {
    userSelect: "none",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },

  formRow: {
    maxHeight: 400,
    overflow: "auto",
    display: "block",
    flexDirection: "row",
    width: "100%",

    "& > label": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      margin: 0,
    },
  },

  rowRoot: {
    width: "100%",
  },

  selectedShip: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },

  deleteShipButton: {
    cursor: "pointer",
  },

  searchTextfield: {
    margin: "8px 0",
  },

  optionContainer: {
    width: "100%",
    height: "100%",
    display: "block",
    paddingTop: "8px",
  },

  optionContainerHidden: {
    display: "none",
  },

  scrollable: {
    maxHeight: 400,
    overflowY: "scroll",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    borderRadius: 4,
    borderRight: "unset",

    "&::-webkit-scrollbar": {
      width: "0.5em",
    },
    "&::-webkit-scrollbar-track": {
      background: "#F1F1F1",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
      borderRadius: 8,
    },
  },

  actionContainer: {
    display: "flex",
    justifyContent: "center",
  },
  buttonMenu: {
    color: "#1976d2",
    cursor: "pointer",
    fontWeight: "450",
  },
  chooseShip: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    width: "100%",
    "&:hover": {
      background: "#f4f4f4 !important",
    },
  },
}));
