import { call, put, select, all } from "redux-saga/effects";
import { uniqWith, isEqual, sortBy } from "lodash";
import ShipActions from "redux-store/ship.redux";
import AreaActions from "redux-store/area.redux";
import CommonActions from "redux-store/common.redux";
import MapActions from "redux-store/map.redux";
import { ApiConstant, AppConstant, LangConstant } from "const";
import {
  deepCloneJsonObject,
  downloadBlob,
  getPointsFromLatLongBounds,
  removeDuplicateInArray,
  toCamel,
  toSnake,
} from "utils";
import { ShipService } from "services";
import { saveAs } from "file-saver";
import { handleSendNotiInSaga } from "utils";

export function* getShipTypes() {
  try {
    let res = yield call(ShipService.getShipTypes);

    if (res.status === ApiConstant.STT_OK && res.data.data) {
      yield put(
        ShipActions.shipSet({
          shipTypes: toCamel(res.data.data),
          isFetching: false,
        }),
      );
    } else {
      yield put(
        ShipActions.shipSet({
          errors: res,
        }),
      );
    }
  } catch (error) {
    yield put(
      ShipActions.shipSet({
        errors: error,
      }),
    );
    console.error(error);
  }
}

export function* searchShipsByCondition({ data }) {
  try {
    if (data && data.filter) {
      const res = yield call(ShipService.getShips, { filter: data.filter });
      if (res.status === ApiConstant.STT_OK && res.data.data) {
        if (data.isManageShip) {
          yield put(
            ShipActions.shipSet({
              filterShipList: {
                status: "fulfilled",
                data: res.data.data.map(ship => ({
                  name: ship.ship_name,
                  uid: ship.uid,
                  type: ship.ship_type,
                  source_type: ship.source_type,
                  mmsi: ship.mmsi_code,
                  imo: ship.imo,
                })),
              },
            }),
          );
        } else {
          yield put(
            ShipActions.shipSet({
              filterTrackingShipList: {
                status: "fulfilled",
                data: res.data.data.map(ship => ({
                  name: ship.ship_name,
                  uid: ship.uid,
                  type: ship.ship_type,
                  source_type: ship.source_type,
                  mmsi: ship.mmsi_code,
                  imo: ship.imo,
                })),
              },
            }),
          );
        }
      } else {
        yield put(
          ShipActions.shipSet({
            errors: res,
            filterShipList: {
              status: "rejected",
              data: [],
            },
            filterTrackingShipList: {
              status: "rejected",
              data: [],
            },
          }),
        );
      }
    }
  } catch (error) {
    yield put(
      ShipActions.shipSet({
        errors: error,
        filterShipList: {
          status: "rejected",
          data: [],
        },
        filterTrackingShipList: {
          status: "rejected",
          data: [],
        },
      }),
    );
    console.error(error);
  }
}

export function* getTrackingShips() {
  try {
    yield put(ShipActions.shipSet({ isFetching: true }));
    let res = yield call(ShipService.getTrackingShips);

    if (res.status === ApiConstant.STT_OK) {
      yield put(
        ShipActions.shipSet({
          trackingShips: uniqWith(res.data.data.groups, isEqual),
          foreignShipNumber: res.data.data.number_foreign_ship,
          isFetching: false,
        }),
      );
    } else {
      yield put(ShipActions.shipSet({ errors: res }));
    }
  } catch (error) {
    yield put(ShipActions.shipSet({ errors: error }));
    console.log(error);
  }
}

export function* requestFilterShips({ data }) {
  try {
    yield put(CommonActions.commonSet({ isLoading: true }));
    yield put(ShipActions.shipSet({ isClusteredDone: false }));
    let bounds = data.bounds;
    let selectShip = data.selectShip;
    let shipConditions = yield select(state => state.shipRedux.shipConditions);
    let selectedShips = yield select(state => state.shipRedux.selectedShips);
    let defaultAreaList = yield select(state => state.areaRedux.defaultAreaList);
    let customAreaList = yield select(state => state.areaRedux.customAreaList);
    let oldList = yield select(state => state.shipRedux.filterShipResult);
    let zoom = yield select(state => state.mapRedux.zoom);
    let trackingFromManage = yield select(state => state.shipRedux.trackingFromManage);
    let selectedShipUids = selectedShips.map(ship => ship.uid);
    let viewFromAreaTime = yield select(state => state.shipRedux.viewFromAreaTime);

    let national = shipConditions.country_code_list.filter(nation => nation !== "");
    let timeX = shipConditions.time_ticks;
    let startTime = shipConditions.startTime;
    let endTime = shipConditions.endTime;
    let areaList = shipConditions.custom_area_list?.concat(shipConditions.default_area_list);
    let groupMembers = Object.values(shipConditions.handleGroupMembers).reduce((result, currentShip) => {
      result.push(currentShip.uid);
      return result;
    }, []);

    let handleShipConditions = selectShip
      ? Object.assign({
          foreign_f: false,
          capacity_min: AppConstant.SHIP_CAPACITY.min,
          capacity_max: AppConstant.SHIP_CAPACITY.max,
          source_type_list: [],
          group_ids: [],
          handleGroupMembers: {},
          custom_area_list: [],
          default_area_list: [],
          area_list: [],
          ports: [],
          ship_type_list: [],
          country_code_list: [],
          group_members: [selectShip],
        })
      : Object.assign(
          shipConditions,
          {
            country_code_list: national,
            area_list: areaList || [],
            group_members: removeDuplicateInArray(
              groupMembers?.concat([...selectedShipUids, trackingFromManage]).filter(uid => Boolean(uid)),
            ),
            size: 10000,
            zoom: zoom,
          },
          getPointsFromLatLongBounds(bounds),
        );

    let { handleGroupMembers, custom_area_list, default_area_list, ...payload } = handleShipConditions;
    let map = [];
    let hourStamp = new Date().setHours(new Date().getHours(), 59, 59, 999);
    const toDate = new Date(hourStamp).getTime();
    for (let i = 0; i < 1; i++) {
      let clone = deepCloneJsonObject({
        ...payload,
        from_date: selectShip ? 0 : timeX ? (viewFromAreaTime ? -1 : toDate - timeX * 3600 * 1000) : startTime,
        to_date: timeX ? (viewFromAreaTime ? -1 : toDate) : endTime,
        update_f: data?.updateF,
      });
      clone.index = i + 1;
      map.push(call(ShipService.getLocation2, clone));
    }
    let res2 = yield all(map);
    let res4 = res2.reduce(function (res3, cur) {
      return res3?.concat(cur?.data?.data?.ship_on_map ? cur?.data?.data?.ship_on_map : []);
    }, []);
    let groupShip = res2.reduce(function (res3, cur) {
      return res3?.concat(cur?.data?.data?.group_ship ? cur?.data?.data?.group_ship : []);
    }, []);
    let shipOnMap = res2.reduce(function (res3, cur) {
      return res3?.concat(cur?.data?.data?.ship_on_map ? cur?.data?.data?.ship_on_map : []);
    }, []);
    if (oldList.length !== res4.length || (oldList.length === res4.length && !isEqual(sortBy(oldList), sortBy(res4)))) {
      if (selectShip) {
        yield put(
          ShipActions.shipSet({
            filterShipResult: removeDuplicateInArray((res4 || [])?.concat(oldList || [])),
            filterShipSelect: res4,
          }),
        );
      } else {
        yield put(
          ShipActions.shipSet({
            groupShip: groupShip,
            shipOnMap: shipOnMap,
            filterShipSelect: selectShip ? res4 : null,
          }),
        );
      }
    }
    if (res4.length < handleGroupMembers.length) {
      let notification = "Không có dữ liệu toạ độ về tàu: ";
      let shipNotExists = groupMembers.filter(uid => !res4.map(ship => ship.ship_uid).includes(uid));
      for (let i = 0; i < shipNotExists.length; ++i) {
        let e = handleGroupMembers.find(obj => obj.uid === shipNotExists[i]);
        yield put(ShipActions.shipSet({ errors: { message: notification + e.name } }));
      }
    }

    yield put(
      ShipActions.shipSet({
        isFetching: false,
        isFetchingLocation: false,
        isChangingFilter: false,
        viewFromAreaTime: false,
        isClusteredDone: true,
        shipConditions: { ...shipConditions, group_ids: [], handleGroupMembers: [] },
      }),
    );

    let handleAreaList = defaultAreaList?.concat(customAreaList);
    let selectedAreaList = handleAreaList.filter(area => payload.area_list.includes(area.uid));

    yield put(
      AreaActions.areaSet({
        selectedAreaList: selectedAreaList,
        isFetching: false,
        viewFromAreaTime: false,
      }),
    );

    yield put(
      MapActions.mapSet({
        oldBounds: bounds,
      }),
    );

    yield put(
      CommonActions.commonSet({
        isLoading: false,
        viewFromAreaTime: false,
      }),
    );

    // yield getTrackingShips();
  } catch (error) {
    yield put(
      CommonActions.commonSet({
        isLoading: false,
        viewFromAreaTime: false,
      }),
    );
    yield put(
      ShipActions.shipSet({
        errors: error,
        isFetchingLocation: false,
        viewFromAreaTime: false,
      }),
    );
    console.log(error);
  }
}

export function* getFilterShipInDataTable({ data }) {
  try {
    yield put(CommonActions.commonSet({ isLoading: true }));
    let exportF = data.isExport ? 1 : 0;
    let bounds = data.bounds;
    let page = data.page || 1;
    let shipConditions = yield select(state => state.shipRedux.shipConditions);
    let selectedShips = yield select(state => state.shipRedux.selectedShips);
    let zoom = yield select(state => state.mapRedux.zoom);
    let selectedShipUids = selectedShips.map(ship => ship.uid);
    let national = shipConditions.country_code_list.filter(nation => nation !== "");
    let areaList = shipConditions.custom_area_list?.concat(shipConditions.default_area_list);
    let groupMembers = Object.values(shipConditions.handleGroupMembers).reduce((result, currentArray) => {
      return result?.concat(currentArray);
    }, []);
    let sourceTypeList =
      shipConditions.source_type_list.length > 0 || !data.sourceTypeList
        ? shipConditions.source_type_list
        : data.sourceTypeList;

    let handleShipConditions = Object.assign(
      shipConditions,
      {
        country_code_list: national,
        area_list: areaList,
        group_members: removeDuplicateInArray(groupMembers?.concat(selectedShipUids)),
        size: data.size,
        page: page,
        zoom: zoom,
        export_flag: exportF,
        ship_status: data.shipStatus,
        source_type_list: sourceTypeList,
      },
      getPointsFromLatLongBounds(bounds),
    );

    let { handleGroupMembers, custom_area_list, default_area_list, ...payload } = handleShipConditions;

    let timeX = shipConditions.time_ticks;
    let startTime = shipConditions.startTime;
    let endTime = shipConditions.endTime;

    let hourStamp = new Date().setHours(new Date().getHours(), 59, 59, 999);
    const toDate = new Date(hourStamp).getTime();
    let clone = deepCloneJsonObject({
      ...payload,
      from_date: timeX ? toDate - timeX * 3600 * 1000 : startTime,
      to_date: timeX ? toDate : endTime,
    });

    let res = yield call(ShipService.getFilterShipInDataTable, clone);

    if (res.status === ApiConstant.STT_OK) {
      if (exportF) {
        downloadBlob(res.data, "du_lieu_tau_thuyen.xlsx");
      } else {
        let data = res.data.data;
        let filterShipDataTable = yield select(state => state.shipRedux.filterShipDataTable);
        let cloneDataTable = deepCloneJsonObject(filterShipDataTable);
        cloneDataTable[page] = data;

        yield put(
          ShipActions.shipSet({
            isFetching: false,
            filterShipDataTable: cloneDataTable,
            filterShipDataTablePage: res.data?.page || 1,
            filterShipDataTableTotalPages: res.data?.total_page || 1,
          }),
        );
      }
    } else {
      yield put(
        ShipActions.shipSet({
          isFetching: false,
          errors: res,
        }),
      );
    }

    yield put(
      CommonActions.commonSet({
        isLoading: false,
      }),
    );
    yield put(
      MapActions.mapSet({
        oldBounds: bounds,
      }),
    );
  } catch (error) {
    yield put(
      CommonActions.commonSet({
        isLoading: false,
      }),
    );
    yield put(
      ShipActions.shipSet({
        errors: error,
      }),
    );
    console.log(error);
  }
}

export function* trackingShips({ data }) {
  try {
    yield put(
      CommonActions.commonSet({
        isLoading: true,
      }),
    );
    let exportF = data.isExport ? 1 : 0;
    let timeStart = parseInt(data.start);
    let timeEnd = parseInt(data.end);
    let shipUidList = data.ships.map(ship => ship.uid);
    let payload = { start: timeStart, end: timeEnd, ship_uid_list: shipUidList, export_f: exportF };
    let res = yield call(ShipService.trackingShips, payload);
    if (res.status === ApiConstant.STT_OK) {
      let resData = res.data.data;
      if (exportF === 1) {
        downloadBlob(res.data, "bao_cao_hanh_trinh.xlsx");
      } else {
        yield put(
          ShipActions.shipSet({
            trackingShipsLocationList: resData,
            isFetching: false,
            isTracking: true,
            trackingUidList: shipUidList,
          }),
        );
      }
      yield put(
        CommonActions.commonSet({
          isLoading: false,
        }),
      );
    } else {
      yield put(
        CommonActions.commonSet({
          isLoading: false,
        }),
      );
      yield put(
        ShipActions.shipSet({
          errors: res,
        }),
      );
    }

    yield put(
      CommonActions.commonSet({
        isLoading: false,
      }),
    );
  } catch (error) {
    yield put(
      CommonActions.commonSet({
        isLoading: false,
      }),
    );
    yield put(
      ShipActions.shipSet({
        errors: error,
      }),
    );
    console.log(error);
  }
}

export function* trackingShip({ data }) {
  try {
    let res = yield call(ShipService.trackingShip, {
      uid: data.uid,
      update_f: data?.update_f,
    });
    if (res.status === ApiConstant.STT_OK) {
      let ship = res.data.data;
      yield put(
        ShipActions.shipSet({
          trackingShip: {
            ...ship,
            ship_lat: data.ship_lat,
            ship_long: data.ship_long,
            prev_ship_lat: data.prev_ship_lat,
            prev_ship_long: data.prev_ship_long,
            time: ship.time,
            distance: data.distance,
            resetLocation: data?.resetLocation,
          },
          isFetching: false,
          startLoadingTime: null,
        }),
      );

      yield put(
        CommonActions.commonSet({
          isLoading: false,
        }),
      );
    } else {
      yield put(
        CommonActions.commonSet({
          isLoading: false,
        }),
      );

      yield put(
        ShipActions.shipSet({
          startLoadingTime: null,
        }),
      );

      yield put(
        ShipActions.shipSet({
          errors: res,
        }),
      );
    }
  } catch (error) {
    yield put(
      ShipActions.shipSet({
        startLoadingTime: null,
      }),
    );

    yield put(
      CommonActions.commonSet({
        isLoading: false,
      }),
    );
    yield put(
      ShipActions.shipSet({
        errors: error,
      }),
    );
    console.log(error);
  }
}

export function* getCrawlDataHistory() {
  try {
    let res = yield call(ShipService.getCrawlDataHistory);
    if (res.status === ApiConstant.STT_OK) {
      let history = res.data.data;
      yield put(
        ShipActions.shipSet({
          dataHistory: toCamel(history),
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* searchShipsInDialog({ data }) {
  try {
    if (data && data.filter) {
      yield put(
        ShipActions.shipSet({
          isSearchingInDialog: true,
        }),
      );

      let res = yield call(ShipService.getShips, { filter: data.filter });

      if (res.status === ApiConstant.STT_OK && res.data.data) {
        yield put(
          ShipActions.shipSet({
            filterDialogShipList: res.data.data.map(ship => ({
              name: ship.ship_name,
              uid: ship.uid,
              source_type: ship.source_type,
              mmsi: ship.mmsi_code,
              imo: ship.imo,
            })),
            isFetching: false,
          }),
        );
      } else {
        yield put(
          ShipActions.shipSet({
            errors: res,
          }),
        );
      }

      yield put(
        ShipActions.shipSet({
          isSearchingInDialog: false,
        }),
      );
    } else {
      yield put(
        ShipActions.shipSet({
          isSearchingInDialog: false,
        }),
      );
    }
  } catch (error) {
    yield put(
      ShipActions.shipSet({
        errors: error,
        isSearchingInDialog: false,
      }),
    );
    console.error(error);
  }
}

export function* addViolationShip({ data }) {
  try {
    yield put(CommonActions.commonSet({ isLoading: true }));

    let res = yield addMultipleViolationShips(data.ships, {
      note: data.note,
      statistic_location_status: data.status,
      violation_level: "Không xác định",
    });

    yield put(
      ShipActions.shipSet({
        violationShipNumber: res,
      }),
    );
    yield put(
      CommonActions.commonSet({
        isLoading: false,
      }),
    );
  } catch (error) {
    yield put(
      CommonActions.commonSet({
        isLoading: false,
      }),
    );
    yield put(
      ShipActions.shipSet({
        errors: error,
      }),
    );
    console.error(error);
  }
}

export function* deleteViolationShip({ data }) {
  try {
    yield put(
      CommonActions.commonSet({
        isLoading: true,
      }),
    );
    let res = yield call(ShipService.deleteViolationShip, {
      uid: data.uid,
    });
    if (res.status === ApiConstant.STT_OK) {
      yield put(
        ShipActions.shipSet({
          isDeleteViolationShipSuccess: true,
        }),
      );
    } else {
      yield put(
        ShipActions.shipSet({
          errors: res,
        }),
      );
    }
    yield put(
      CommonActions.commonSet({
        isLoading: false,
      }),
    );
  } catch (error) {
    yield put(
      CommonActions.commonSet({
        isLoading: false,
      }),
    );
    yield put(
      ShipActions.shipSet({
        errors: error,
      }),
    );
    console.error(error);
  }
}

export function* editViolationShip({ data }) {
  try {
    yield put(
      CommonActions.commonSet({
        isLoading: true,
      }),
    );
    let res = yield call(ShipService.editViolationShip, {
      uid: data.uid,
      note: data.note,
      status: parseInt(data.status),
      violation_level: data.level,
    });
    if (res.status === ApiConstant.STT_OK) {
      yield put(
        ShipActions.shipSet({
          isEditViolationShipSuccess: true,
        }),
      );
    } else {
      yield put(
        ShipActions.shipSet({
          errors: res,
        }),
      );
    }
    yield put(
      CommonActions.commonSet({
        isLoading: false,
      }),
    );
  } catch (error) {
    yield put(
      CommonActions.commonSet({
        isLoading: false,
      }),
    );
    yield put(
      ShipActions.shipSet({
        errors: error,
      }),
    );
    console.error(error);
  }
}

function* addMultipleViolationShips(ships, payload, count = 0) {
  if (ships.length > 1) {
    let ship = ships.pop();
    let res = yield call(ShipService.addViolationShip, {
      ...payload,
      uid: ship,
    });
    if (res.status === ApiConstant.STT_OK) count += 1;
    return yield addMultipleViolationShips(ships, payload, count);
  } else {
    let ship = ships.pop();
    let res = yield call(ShipService.addViolationShip, {
      ...payload,
      uid: ship,
    });
    if (res.status === ApiConstant.STT_OK) {
      return count + 1;
    } else {
      return 0;
    }
  }
}

export function* getShipInArea(action) {
  try {
    let response = yield call(ShipService.getShipInArea, toSnake(action.data));
    let isExporting = Boolean(action.data.exportF);

    if (response.status === ApiConstant.STT_OK) {
      if (isExporting) {
        saveAs(
          new File([response.data], "DuLieuTauThuocKhuVucGiamSat.xlsx", {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }),
        );
      } else {
        let responseData = toCamel(response.data);
        let shipInAreaData = yield select(state => state.shipRedux.shipInAreaData);

        yield put(
          ShipActions.shipSet({
            shipInAreaData: {
              listData: {
                ...shipInAreaData.listData,
                [responseData.page + 1]: responseData.data,
              },
              ...responseData.data,
              page: responseData.page + 1,
            },
            isFetchingAreaData: false,
          }),
        );
      }
    } else {
      yield put(
        ShipActions.shipSet({
          isFetchingAreaData: false,
        }),
      );
      if (isExporting) {
        handleSendNotiInSaga(LangConstant.MS_EXPORT_FAILED, "error");
      } else {
        yield put(
          ShipActions.shipSet({
            shipInAreaData: {
              listData: {},
            },
            isFetchingAreaData: false,
          }),
        );
        handleSendNotiInSaga(LangConstant.MS_ERROR_DATA_AREA, "error");
      }
    }
  } catch (error) {
    yield put(
      ShipActions.shipSet({
        isFetchingAreaData: false,
        shipInAreaData: {
          listData: [],
        },
      }),
    );
    console.log(error);
  }
}

export function* getCrawlShipUpdate({ data }) {
  try {
    yield put(ShipActions.shipSet({ isCrawlDataDone: false }));
    yield call(ShipService.crawlShipToUpdate, { uid: data.uid });
    yield put(ShipActions.shipSet({ isCrawlDataDone: true }));
  } catch (error) {
    console.log(error);
  }
}

export function* getListShipLost(action) {
  try {
    const shipLostChartData = yield select(state => state.shipRedux.shipLostChartData);

    let response = yield call(ShipService.getListShipLost, action.data);

    if (response.status === ApiConstant.STT_OK) {
      const dataResponse = response.data;
      if (dataResponse.data) {
        const dataResponseToCamel = toCamel(dataResponse.data);

        const chartDataResponse = {
          internalSea: dataResponseToCamel.internalSea,
          vnSea: dataResponseToCamel.vnSea,
          total: dataResponseToCamel.shipData.total,
        };
        yield put(
          ShipActions.shipSet({
            getListShipLostStatus: AppConstant.API_STATUS.success,
            shipLostChartData: action.data.filter || action.data.ship_type ? shipLostChartData : chartDataResponse,
            listShipLostData: dataResponseToCamel.shipData,
            totalShipInDatabase: dataResponseToCamel.totalShip,
          }),
        );
      } else {
        downloadBlob(
          dataResponse,
          action.data.statistic_type === AppConstant.SHIP_LOST_TYPE.disconnected
            ? LangConstant.TXT_LIST_SHIP_LOST_FILE_NAME
            : LangConstant.TXT_LIST_SHIP_LOST_SIGNAL_FILE_NAME,
        );
        yield put(
          ShipActions.shipSet({
            getListShipLostStatus: AppConstant.API_STATUS.success,
          }),
        );
      }
    } else {
      yield put(
        ShipActions.shipSet({
          getListShipLostStatus: AppConstant.API_STATUS.error,
          listShipLostData: null,
        }),
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      ShipActions.shipSet({
        getListShipLostStatus: AppConstant.API_STATUS.error,
        listShipLostData: null,
      }),
    );
  }
}

export function* getListShipByDay(action) {
  try {
    let response = yield call(ShipService.getListShipByDay, action.data);

    const dataResponse = toCamel(response.data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ShipActions.shipSet({
          getListShipByDayStatus: AppConstant.API_STATUS.success,
          listShipByDayData: dataResponse,
        }),
      );
    } else {
      yield put(
        ShipActions.shipSet({
          getListShipByDayStatus: AppConstant.API_STATUS.error,
          listShipByDayData: null,
        }),
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      ShipActions.shipSet({
        getListShipByDayStatus: AppConstant.API_STATUS.error,
        listShipByDayData: null,
      }),
    );
  }
}

export function* getShipsAround({ data }) {
  const { payload, circle } = data;
  try {
    let shipConditions = yield select(state => state.shipRedux.shipConditions);
    let res = yield call(ShipService.getShipsAround, { ...shipConditions, ...payload });
    if (res.status === ApiConstant.STT_OK && res.data.data) {
      yield put(
        ShipActions.shipSet({
          shipsAround: res?.data?.data?.ship_on_map || [],
          shipsAroundCircle: circle,
        }),
      );
    } else {
      yield put(
        ShipActions.shipSet({
          errors: res,
        }),
      );
    }
  } catch (error) {
    yield put(
      ShipActions.shipSet({
        errors: error,
      }),
    );
    console.error(error);
  }
}
