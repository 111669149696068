import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Delete, Download } from "@mui/icons-material";
import { LangConstant, ApiConstant } from "const";
import FolderRedux from "redux-store/folder.redux";
import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  Typography,
} from "@mui/material";
import { convertBytes } from "utils";
import { makeStyles } from "@mui/styles";
import { FolderService } from "services";
import { saveAs } from "file-saver";
import { handleSendNotiInSaga } from "utils";

const TableFileUpload = props => {
  const { searchFile } = props;
  const dispatch = useDispatch();
  const classes = useStyle();

  const fileList = useSelector(state => state.folderRedux.fileList);

  const handleDeleteFile = uid => {
    dispatch(FolderRedux.deleteFileFolder(uid));
  };

  const handleDownloadFile = async attachments => {
    let response = await FolderService.exportFolderFileReport(attachments.uid);
    if (response.status === ApiConstant.STT_OK) {
      saveAs(
        new File([response.data], attachments.name, {
          type: attachments.mime_type,
        }),
      );
    } else {
      handleSendNotiInSaga(LangConstant.TXT_MESSAGE_ERROR, "error");
    }
  };

  return (
    <TableContainer style={{ maxHeight: 700 }}>
      <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell width="40px">{LangConstant.TXT_STT}</TableCell>
            <TableCell align="center">{LangConstant.TXT_FILE_REPORT_NAME}</TableCell>
            <TableCell align="center">{LangConstant.TXT_FILE_REPORT_TYPE}</TableCell>
            <TableCell align="center">{LangConstant.TXT_FILE_REPORT_SIZE}</TableCell>
            <TableCell width="40px" palign="center">
              {LangConstant.TXT_ACTION}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {fileList &&
            fileList
              .filter(file => file?.name?.includes(searchFile))
              .map((data, index) => {
                return (
                  <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} key={data.uid} id={data.uid}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">
                      <Typography className={classes.nameFolderUpload}>{data?.name}</Typography>
                    </TableCell>
                    <TableCell align="center">{data.mime_type}</TableCell>
                    <TableCell align="center">{convertBytes(data.size)}</TableCell>
                    <TableCell align="center" sx={{ display: "flex", justifyContent: "space-around" }}>
                      <Fragment>
                        <IconButton title={"Xóa"} onClick={() => handleDeleteFile(data.uid)}>
                          <Delete />
                        </IconButton>
                        <IconButton title={"Dowload"} onClick={() => handleDownloadFile(data)}>
                          <Download />
                        </IconButton>
                      </Fragment>
                    </TableCell>
                  </TableRow>
                );
              })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default TableFileUpload;

const useStyle = makeStyles({
  nameFolderUpload: {
    maxWidth: "400px",
    overflow: "hidden",
    display: "inline-block",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});
