import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  IconButton,
} from "@mui/material";
import { LangConstant } from "const";
import { useDispatch, useSelector } from "react-redux";
import FolderAction from "redux-store/folder.redux";
import FolderRedux from "redux-store/folder.redux";
import { Close } from "@mui/icons-material";

const DownloadFilesDialog = props => {
  const { open, onClose, data } = props;
  const dispatch = useDispatch();
  const attachments = useSelector(state => state.folderRedux?.folderDetail?.attachments);

  const onClickButtonDownload = () => {
    dispatch(
      FolderAction.downloadFilesFolder({
        uid: data.uid,
        attachment_uids: selected,
      }),
    );
    onClose();
  };
  useEffect(() => {
    dispatch(FolderRedux.getFolder(data));
  }, [data, open]);

  useEffect(() => {
    console.log(attachments);
  }, [attachments]);

  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAllChange = event => {
    setSelectAll(event.target.checked);
    setSelected(event.target.checked ? attachments?.map(attachment => attachment.uid) : []);
  };

  const handleSelectChange = (event, uid) => {
    if (event.target.checked) {
      setSelected(prev => [...prev, uid]);
    } else {
      setSelected(prev => prev.filter(id => id !== uid));
      setSelectAll(false);
    }
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={onClose}>
      <DialogTitle>
        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="h6">{LangConstant.TXT_CONFIRM_DOWNLOAD_FOLDER}</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent style={{ paddingTop: 0 }}>
        <div>{LangConstant.TXT_CONFIRM_DOWNLOAD_FOLDER_MSG}</div>
        <Box sx={{ marginTop: 2, display: "flex", paddingRight: "10%", flexDirection: "column" }}>
          <FormControlLabel
            control={<Checkbox checked={selectAll} onChange={handleSelectAllChange} />}
            label="Tất cả báo cáo"
          />
          {attachments?.map((attachment, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={selected.includes(attachment.uid)}
                  onChange={event => handleSelectChange(event, attachment.uid)}
                />
              }
              label={attachment.name}
              style={{ paddingLeft: "20px" }}
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClickButtonDownload} disabled={!selected?.length}>
          {LangConstant.TXT_BUTTON_DOWNLOAD_FILE}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DownloadFilesDialog;
