import React, { memo, useState } from "react";
import { Box, Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FishingShipIcon } from "theme/icons";
import { getShipFishingColor } from "utils";
import { SystemConstant, AppConstant } from "const";
import { useSelector } from "react-redux";
import FishingShipListDialog from "./FishingShipListDialog";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const LayerBottomRight = () => {
  const classes = useStyles();
  const filterShipResult = useSelector(state => state.shipRedux.filterShipResult);
  const fishingShips = status =>
    filterShipResult
      ?.filter(point => point?.source_type === AppConstant.SOURCE_DATA.gstc && point?.status === status)
      .sort((a, b) => b.crawl_time - a.crawl_time);

  const [open, setOpen] = useState(false);
  const [dataDialog, setDataDialog] = useState([]);
  const [titleDialog, setTitleDialog] = useState("");
  const [shipStatus, setShipStatus] = useState(null);

  return (
    <>
      <Accordion
        className={classes.layerBottomRightCont}
        sx={{
          ".MuiAccordionSummary-content": {
            margin: 0,
          },
        }}
        defaultExpanded
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            minHeight: "24px",
            height: "24px",
            padding: "0 0 0 8px",
            "&.Mui-expanded": {
              minHeight: "24px",
              height: "24px",
            },
          }}
        >
          <Typography variant="h6" className={classes.accordionSummary}>
            Danh sách tàu cá
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ "&.MuiAccordionDetails-root": { padding: 0 } }}>
          <Box component="section">
            <Box
              className={classes.detail}
              onClick={() => {
                setOpen(true);
                setDataDialog(fishingShips(SystemConstant.SHIP_STATUS.ACTIVE));
                setTitleDialog("Danh sách tàu kết nối");
                setShipStatus(SystemConstant.SHIP_STATUS.ACTIVE);
              }}
            >
              <FishingShipIcon
                className={classes.detailIcon}
                fillColor={getShipFishingColor(SystemConstant.SHIP_STATUS.ACTIVE)}
              />
              {`[${fishingShips(SystemConstant.SHIP_STATUS.ACTIVE).length}] Tàu kết nối`}
            </Box>

            <Box
              className={classes.detail}
              onClick={() => {
                setOpen(true);
                setDataDialog(fishingShips(SystemConstant.SHIP_STATUS.LOST_CONNECTION));
                setTitleDialog("Danh sách tàu mất kết nối ngoài khơi");
                setShipStatus(SystemConstant.SHIP_STATUS.LOST_CONNECTION);
              }}
            >
              <FishingShipIcon
                className={classes.detailIcon}
                fillColor={getShipFishingColor(SystemConstant.SHIP_STATUS.LOST_CONNECTION)}
              />
              {`[${fishingShips(SystemConstant.SHIP_STATUS.LOST_CONNECTION).length}] Tàu mất kết nối ngoài khơi`}
            </Box>
            <Box
              className={classes.detail}
              onClick={() => {
                setOpen(true);
                setDataDialog(fishingShips(SystemConstant.SHIP_STATUS.LOST_CONNECTION_NEAR));
                setTitleDialog("Danh sách tàu mất kết nối trong bờ");
                setShipStatus(SystemConstant.SHIP_STATUS.LOST_CONNECTION_NEAR);
              }}
            >
              <FishingShipIcon
                className={classes.detailIcon}
                fillColor={getShipFishingColor(SystemConstant.SHIP_STATUS.LOST_CONNECTION_NEAR)}
              />
              {`[${fishingShips(SystemConstant.SHIP_STATUS.LOST_CONNECTION_NEAR).length}] Tàu mất kết nối trong bờ`}
            </Box>
            <Box
              className={classes.detail}
              onClick={() => {
                setOpen(true);
                setDataDialog(fishingShips(SystemConstant.SHIP_STATUS.NEAR_BOUNDARY));
                setTitleDialog("Danh sách tàu gần ranh giới");
                setShipStatus(SystemConstant.SHIP_STATUS.NEAR_BOUNDARY);
              }}
            >
              <FishingShipIcon
                className={classes.detailIcon}
                fillColor={getShipFishingColor(SystemConstant.SHIP_STATUS.NEAR_BOUNDARY)}
              />
              {`[${fishingShips(SystemConstant.SHIP_STATUS.NEAR_BOUNDARY).length}] Tàu gần ranh giới`}
            </Box>
            <Box
              className={classes.detail}
              onClick={() => {
                setOpen(true);
                setDataDialog(fishingShips(SystemConstant.SHIP_STATUS.OUT_SIDE_BOUNDARY));
                setTitleDialog("Danh sách tàu ra khỏi vùng ranh giới");
                setShipStatus(SystemConstant.SHIP_STATUS.OUT_SIDE_BOUNDARY);
              }}
            >
              <FishingShipIcon
                className={classes.detailIcon}
                fillColor={getShipFishingColor(SystemConstant.SHIP_STATUS.OUT_SIDE_BOUNDARY)}
              />
              {`[${fishingShips(SystemConstant.SHIP_STATUS.OUT_SIDE_BOUNDARY).length}] Tàu ra khỏi vùng ranh giới`}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <FishingShipListDialog
        data={dataDialog}
        open={open}
        onClose={() => setOpen(false)}
        titleDialog={titleDialog}
        shipStatus={shipStatus}
      />
    </>
  );
};

export default memo(LayerBottomRight);

const useStyles = makeStyles(theme => ({
  layerBottomRightCont: {
    position: "fixed",
    zIndex: 1000,
    bottom: 10,
    right: 12,
    borderRadius: "4px",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #999999",
    background: "#ffffff",
    padding: "4px",
  },
  detail: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    margin: "2px 0",
    cursor: "pointer",
  },
  detailIcon: {
    width: "16px",
    height: "16px",
  },
  accordionSummary: {
    fontSize: "14px",
  },
}));
