import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Close, CalendarMonth, DirectionsBoat } from "@mui/icons-material";
import { LangConstant } from "const";
import { convertMillisecondsToDateTime } from "utils/date.utils";
import { deepCloneJsonObject } from "utils";

export default function StatisticalDetail({ data, onClose, open, timestamp }) {
  const classes = useStyles();

  const [moveInLocations, setMoveInLocations] = useState([]);
  const [moveOutLocations, setMoveOutLocations] = useState([]);

  useEffect(() => {
    let locations = deepCloneJsonObject(data?.location_list || []);
    setMoveOutLocations(locations.filter(l => l.statistic_location_status === MOVE_OUT));
    setMoveInLocations(locations.filter(l => l.statistic_location_status === MOVE_IN));
  }, [data]);

  return (
    <Dialog open={open} maxWidth="md" fullWidth classes={{ paper: classes.cont }}>
      <Box className={classes.statisticDetailTitleBox}>
        <Typography variant="caption" className={classes.statisticDetailTitle}>
          {LangConstant.TXT_STATISTICAL_DETAIL_TITLE}
        </Typography>
        <IconButton size="medium" onClick={onClose}>
          <Close fontSize="inherit" />
        </IconButton>
      </Box>
      <Box className={classes.infoDetailContainer}>
        <Box className={classes.infoGeneralContainer}>
          <Box className={classes.detailInfoBox}>
            <Box className={classes.generalBox}>
              <DirectionsBoat fontSize="small" className={classes.icon} />
              <Typography variant="caption">{data?.ship_name || "Tàu chưa đặt tên"}</Typography>
            </Box>
            <Box className={classes.generalBox}>
              <CalendarMonth fontSize="small" className={classes.icon} />
              <Typography variant="caption">
                {convertMillisecondsToDateTime(timestamp?.start || 0)} -{" "}
                {convertMillisecondsToDateTime(timestamp?.end || 0)}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.tableBox}>
          <Box className={classes.infoTable}>
            <Typography variant="caption"></Typography>
          </Box>
          <TableContainer sx={{ maxHeight: 200 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ width: "10%" }}>
                    {LangConstant.TXT_STT}
                  </TableCell>
                  <TableCell align="center" sx={{ width: "70%" }}>
                    {LangConstant.TXT_LOCATION_WHEN_GO_OUT_AREA}
                  </TableCell>
                  <TableCell align="center" sx={{ width: "20%" }}>
                    {LangConstant.TXT_TIME}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {moveOutLocations.length > 0 ? (
                  moveOutLocations.map((location, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {location.lat} - {location.lon}
                      </TableCell>
                      <TableCell align="center">{convertMillisecondsToDateTime(location.time)}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="center" style={{ color: "#999" }}></TableCell>
                    <TableCell align="center" style={{ color: "#999" }}>
                      Không có dữ liệu
                    </TableCell>
                    <TableCell align="center" style={{ color: "#999" }}></TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box className={classes.tableBox}>
          <Box className={classes.infoTable}>
            <Typography variant="caption"></Typography>
          </Box>
          <TableContainer sx={{ maxHeight: 200 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ width: "10%" }}>
                    {LangConstant.TXT_STT}
                  </TableCell>
                  <TableCell align="center" sx={{ width: "70%" }}>
                    {LangConstant.TXT_LOCATION_WHEN_GO_TO_AREA}
                  </TableCell>
                  <TableCell align="center" sx={{ width: "20%" }}>
                    {LangConstant.TXT_TIME}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {moveInLocations.length > 0 ? (
                  moveInLocations.map((location, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {location.lat} - {location.lon}
                      </TableCell>
                      <TableCell align="center">{convertMillisecondsToDateTime(location.time)}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="center" style={{ color: "#999" }}></TableCell>
                    <TableCell align="center" style={{ color: "#999" }}>
                      Không có dữ liệu
                    </TableCell>
                    <TableCell align="center" style={{ color: "#999" }}></TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Dialog>
  );
}

const MOVE_IN = 0;
const MOVE_OUT = 1;

const useStyles = makeStyles(theme => ({
  cont: {
    padding: "0 16px 16px",
  },

  statisticDetailTitleBox: {
    height: 60,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  statisticDetailTitle: {
    fontSize: 20,
    fontWeight: 500,
  },

  infoDetailContainer: {
    padding: 4,
  },

  infoGeneralBox: {
    padding: "0 8px",
  },

  infoGeneralTitleBox: {
    fontSize: 20,
    fontWeight: 500,
  },

  detailInfoBox: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  generalBox: {
    display: "flex",
    alignItems: "center",
    marginRight: 16,
  },

  detailInfoTitle: {
    marginRight: 8,
    fontSize: 16,
    fontWeight: 500,
  },

  infoTable: {
    display: "flex",
  },

  tableBox: {
    border: "1px solid #999",
    marginTop: 8,
    padding: 8,
    borderRadius: 2,
  },

  times: {
    marginLeft: 8,
  },

  resourceSelectBox: {
    width: 240,
  },

  icon: {
    marginRight: 4,
  },
}));
