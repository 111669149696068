import React, { useRef } from "react";
import { Edit } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import ViewShipManagement from "pages/AdminDashboard/ShipManagement/ViewShipManagement";
import { useEffect } from "react";
import { useState } from "react";
import AddWatchlist from "./AddWatchlist";
import ConversationRedux from "redux-store/conversation.redux";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { PopupDeleteMany } from "components";
import { hasRoleScopes } from "utils";
import { SCOPES } from "const/app.const";
import { handleSendNotiInSaga } from "utils";
import { LangConstant } from "const";

function createData(stt, ships, name, idShip, imoShip, mmsiShip, chants, supplier) {
  return { stt, ships, name, idShip, imoShip, mmsiShip, chants, supplier };
}

const TableWatchlist = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const typeping = useRef(null);
  const navigator = useNavigate();
  const getGroupsucsess = useSelector(state => state.conversationRedux.getGroupsucsess);
  const createGroupShip = useSelector(state => state.conversationRedux.createGroupShip);
  const addGroupShip = useSelector(state => state.conversationRedux.addGroupShip);
  const deleteGroupShip = useSelector(state => state.conversationRedux.deleteGroupShip);
  const deleteMuchGroupShips = useSelector(state => state.conversationRedux.deleteMuchGroupShips);
  const panigation1 = useSelector(state => state.conversationRedux.pagination);
  const totalPageGrouShip = useSelector(state => state.conversationRedux.totalPageGrouShip);

  const [checkedCheckbox, setCheckedCheckbox] = useState([]);
  const [openWatchlist, setOpenWatchlist] = useState(false);
  const [viewShip, setViewShip] = useState(false);
  const [rowResult, setRowResult] = useState([]);
  const [searchWatchList, setSearchWatchlist] = useState("");
  const [isShowTable, setIsShowTable] = useState(true);
  const [pagination, setPagination] = useState(1);
  const [confirmDeleteMany, setConfirmDeleteMany] = useState(false);
  const [userDelete, setUserDelete] = useState();
  const [addListShip, setAddListShip] = useState(false);
  const [monitoringListName, setInputShipName] = useState("");
  const [monitoringReason, setMonitoringReason] = useState("");

  const onClickCheckBox = id => {
    setCheckedCheckbox(pre => (pre.includes(id) ? pre.filter(item => item !== id) : [...pre, id]));
  };

  const onClickDeleteMuch = () => {
    setConfirmDeleteMany(true);
  };

  const onChangePage = (e, value) => {
    setPagination(value);
  };

  const onClickItem = item => {
    setIsShowTable(false);
  };

  const onChangeSearchWatchList = e => {
    const value = e.target.value;
    if (typeping.current) {
      clearTimeout(typeping.current);
    }
    typeping.current = setTimeout(() => {
      setSearchWatchlist(value);
    }, 500);
  };

  const onClickAddListShip = () => {
    if (Boolean(monitoringListName)) {
      dispatch(
        ConversationRedux.createShipGroup({
          name: monitoringListName,
          type: 1,
          note: monitoringReason,
        }),
      );
    } else {
      handleSendNotiInSaga(LangConstant.MS_ERROR_GROUP_NAME_EMPTY, "error");
    }
  };

  useEffect(() => {
    dispatch(
      ConversationRedux.getGroupShip({
        page: pagination,
        filter: searchWatchList,
      }),
    );

    if (createGroupShip) {
      setAddListShip(false);
    }
  }, [searchWatchList, createGroupShip, addGroupShip, pagination, deleteGroupShip, deleteMuchGroupShips]);

  useEffect(() => {
    if (getGroupsucsess) {
      let result = [];
      getGroupsucsess.map(item => {
        return result.push(
          createData(
            item,
            item.name && item.name,
            item.ships.length && item.ships.length,
            item?.user?.name && item?.user?.name,
            item.modifined && item.modifined
              ? format(item.modifined, "dd/MM/yyyy")
              : format(item.created, "dd/MM/yyyy"),
            format(item?.created, "dd/MM/yyyy"),
            item.note,
            <React.Fragment>
              {hasRoleScopes(SCOPES.GROUP_SHIP_UPDATE) && (
                <IconButton
                  onClick={() => {
                    onClickItem(item);
                    navigator(`/admin/group-details/${item.uid}`, {
                      replace: true,
                    });
                  }}
                >
                  <Edit />
                </IconButton>
              )}
            </React.Fragment>,
          ),
        );
      });

      setRowResult(result);
    }
  }, [getGroupsucsess]);

  useEffect(() => {
    if (!addListShip) {
      setInputShipName("");
      setMonitoringReason("");
    }
  }, [addListShip]);

  return (
    <>
      {isShowTable && (
        <Box>
          <Box className={classes.searchInput}>
            <TextField
              onChange={onChangeSearchWatchList}
              fullWidth
              size="small"
              label="Tìm kiếm tàu cảng"
              variant="outlined"
            />
          </Box>
          <Box className={classes.flexBoxButton}>
            <Box style={{ padding: "0 10px" }}>
              {hasRoleScopes(SCOPES.GROUP_SHIP_DELETE) && (
                <Button
                  onClick={onClickDeleteMuch}
                  disabled={Boolean(checkedCheckbox.length > 0) ? "" : Boolean("disabled")}
                  color="warning"
                  variant="contained"
                >
                  Xóa danh sách giám sát
                </Button>
              )}

              {hasRoleScopes(SCOPES.GROUP_SHIP_CREATE) && (
                <Button
                  style={{ marginRight: 10 }}
                  onClick={() => setAddListShip(true)}
                  variant="contained"
                  color="warning"
                  className={classes.addListBtn}
                >
                  Thêm danh sách giám sát
                </Button>
              )}
            </Box>

            {/*
              //TODO: xuất file trong details group
              <Box className={classes.buttonBarr}>
                <Button
                  onClick={onClickExpostFile}
                  color="warning"
                  variant="contained"
                  type="submit"
                  endIcon={<Download />}
                >
                  Xuất File
                </Button>
              </Box>
      */}
          </Box>
          <TableContainer sx={{ height: 700, overflow: "auto" }} component={Paper}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width="50"></TableCell>
                  <TableCell width="50">STT</TableCell>
                  <TableCell align="center">Tên nhóm</TableCell>
                  <TableCell align="center">Số lượng tàu</TableCell>
                  <TableCell align="center">Người tạo</TableCell>
                  <TableCell align="center">Thời gian chỉnh sửa gần nhất</TableCell>
                  <TableCell align="center">Thời gian tạo</TableCell>
                  <TableCell align="center">Lý do giám sát</TableCell>
                  <TableCell align="center">Thao tác</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowResult &&
                  rowResult.map((row, index) => (
                    <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell>
                        <Checkbox
                          checked={checkedCheckbox.includes(row.stt.uid)}
                          onClick={() => {
                            onClickCheckBox(row.stt.uid);
                            setUserDelete(row);
                          }}
                          color="warning"
                        />
                      </TableCell>
                      <TableCell align="center">{(pagination - 1) * panigation1 + index + 1}</TableCell>
                      <TableCell align="center">{row.ships}</TableCell>
                      <TableCell align="center">{row.name}</TableCell>
                      <TableCell align="center">{row.idShip}</TableCell>
                      <TableCell align="center">{row.imoShip}</TableCell>
                      <TableCell align="center">{row.mmsiShip}</TableCell>
                      <TableCell align="center">{row.chants}</TableCell>
                      <TableCell align="center">{row.supplier}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <AddWatchlist open={openWatchlist} onClose={() => setOpenWatchlist(false)} />
          <ViewShipManagement open={viewShip} onClose={() => setViewShip(false)} />
          <Box className={classes.panigationPadding}>
            <Pagination count={+totalPageGrouShip} onChange={onChangePage} shape="rounded" page={pagination} />
          </Box>
          <PopupDeleteMany
            setCheckedCheckbox={() => setCheckedCheckbox([])}
            checked={checkedCheckbox}
            onClose={() => setConfirmDeleteMany(false)}
            open={confirmDeleteMany}
            data={userDelete}
            resetData={() => setUserDelete(null)}
          />
        </Box>
      )}

      <Dialog open={addListShip} onClose={() => setAddListShip(false)} maxWidth="xs" fullWidth>
        <DialogTitle>Thêm danh sách giám sát</DialogTitle>
        <DialogContent>
          <DialogContent>
            <TextField
              fullWidth
              size="small"
              onChange={e => setInputShipName(e.target.value)}
              placeholder="Nhập tên danh sách giám sát "
              variant="outlined"
              value={monitoringListName}
            />
            <Box className={classes.paddingInput}>
              <TextField
                onChange={e => setMonitoringReason(e.target.value)}
                fullWidth
                value={monitoringReason}
                size="small"
                placeholder="Lý do giám sát "
                variant="outlined"
              />
            </Box>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClickAddListShip}>Lưu</Button>
          <Button onClick={() => setAddListShip(false)}>Hủy</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TableWatchlist;

const useStyles = makeStyles(theme => ({
  flexBox: {
    display: "flex",
    alignItems: "center",
    padding: "10px 0",
  },

  widthDatePicker: {
    width: 200,
    paddingRight: 10,
  },

  searchInput: {
    margin: "auto",
    width: 400,
    marginBottom: "20px",
  },

  flexBoxButton: {
    display: "flex",
    paddingBottom: 10,
  },

  panigationPadding: {
    padding: "10px 0",
  },

  paddingInput: {
    padding: "10px 0",
  },

  addListBtn: {
    marginLeft: 10,
  },
}));
