import React, { useEffect, useState } from "react";
import { useNotification } from "hooks";
import { useDispatch } from "react-redux";
import UserRedux from "redux-store/user.redux";
import { AttachtmentService } from "services";
import { Edit } from "@mui/icons-material";
import { Avatar, Box, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ApiConstant, LangConstant, PathConstant } from "const";
import { useLocation } from "react-router";

const ChangeAvatar = ({ userInfo }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notification = useNotification();
  const location = useLocation();

  const [srcImg, setSrcImg] = useState();

  const onChangeAvt = async event => {
    const file = event.target.files[0];
    if (file) {
      if (file.size / 1024 / 1024 < 5) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        let formData = new FormData();
        formData.append("file", file);
        formData.append("type", "image");
        dispatch(UserRedux.changeAvatar(formData));
      } else {
        notification.send(LangConstant.MS_UPLOAD_AVATAR_TOO_LARGE, "error");
      }
    }
  };

  useEffect(async () => {
    if (userInfo && userInfo?.avatar) {
      const res = await AttachtmentService.getAvatar(userInfo.avatar);
      if (res.status === ApiConstant.STT_OK) {
        const imageBlob = await res.data;
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setSrcImg(imageObjectURL);
      }
    } else {
      setSrcImg(null);
    }
  }, [userInfo?.avatar]);

  return (
    <Box className={classes.containerAvatar}>
      <Box className={classes.containerChangeAvatar}>
        <Avatar className={classes.avatar} src={srcImg} />
        {location.pathname === PathConstant.ADMIN_PROFILE && (
          <IconButton className={classes.iconButtonEdit} component="label">
            <Edit className={classes.iconEdit} />
            <input className={classes.inputAvatar} accept="image/*" type="file" hidden onChange={onChangeAvt} />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default ChangeAvatar;

const useStyles = makeStyles(() => ({
  containerAvatar: {
    display: "flex",
    justifyContent: "center",
  },

  containerChangeAvatar: {
    position: "relative",
  },

  avatar: {
    width: 150,
    height: 150,
  },

  iconButtonEdit: {
    width: "30px",
    height: "30px",
    zIndex: 999,
    position: "absolute",
    bottom: "10px",
    right: "10px",
    background: "#e65100",
    "&:hover": {
      background: "#e65100",
    },
  },

  iconEdit: {
    width: "20px",
    color: "#ffffff",
  },

  inputAvatar: {
    display: "none",
  },
}));
