import React, { useEffect, useState } from "react";
import {
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import resources from "static/crawler-resources.json";
import { convertMillisecondsToDateTime } from "utils/date.utils";
import { convertCoordinates } from "utils";

const NearAreaDialog = ({ open, onClose, ships }) => {
  const [area, setArea] = useState(AREAS[0]);
  const [ship, setShip] = useState(ships[0]);

  const handleChangeArea = e => {
    const areaKey = e.target.value;
    setArea(AREAS.find(area => area.key === areaKey));
  };

  const handleChangeShip = e => {
    const shipUid = e.target.value;
    setShip(ships.find(ship => ship.ship_uid === shipUid));
  };

  const findSourceName = id => {
    return resources.find(resource => parseInt(resource.id) === id)?.name || "-";
  };

  const handleClose = () => {
    if (onClose) onClose();
    setArea(AREAS[0]);
    setShip(ships[0]);
  };

  useEffect(() => {
    if (ships) {
      setArea(AREAS[0]);
      setShip(ships[0]);
    }
  }, [ships]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle display="flex" alignItems="center" justifyContent="space-between">
        <Typography component="span" variant="h6">
          Danh sách khu vực lân cận
        </Typography>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box display="inline-flex" alignItems="center" gap="8px" marginRight="48px">
          <label>Khu vực:</label>
          <select className="common-input" value={area.key} onChange={handleChangeArea}>
            {AREAS.map(areaConst => (
              <option key={areaConst.key} value={areaConst.key}>
                {areaConst.title}
              </option>
            ))}
          </select>
        </Box>
        <Box display="inline-flex" alignItems="center" gap="8px">
          <label>Tên tàu:</label>
          <select className="common-input" value={ship.ship_uid} onChange={handleChangeShip}>
            {ships.map(shipResponse => (
              <option key={shipResponse.ship_uid} value={shipResponse.ship_uid}>
                {shipResponse.ship_name}
              </option>
            ))}
          </select>
        </Box>
      </DialogContent>
      <DialogContent>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">STT</TableCell>
                  <TableCell align="center">Thời gian</TableCell>
                  <TableCell align="center">Khu vực</TableCell>
                  <TableCell align="center">Tình trạng</TableCell>
                  <TableCell align="center">Vị trí</TableCell>
                  <TableCell align="center">Nguồn dữ liệu</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ship.location_history.map((location, index) => (
                  <TableRow key={index} role="checkbox" tabIndex={-1}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{convertMillisecondsToDateTime(location.time)}</TableCell>
                    <TableCell align="center">{location[area.key] ? location[area.key]["name"] : "-"}</TableCell>
                    <TableCell align="center">{location[area.key] ? location[area.key]["status"] : "-"}</TableCell>
                    <TableCell align="center" sx={{ whiteSpace: "pre-line" }}>
                      {convertCoordinates(Number(location.lat).toFixed(5), Number(location.long).toFixed(5))}
                    </TableCell>
                    <TableCell align="center">{findSourceName(location.source_type)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default NearAreaDialog;

const AREAS = [
  { key: "port", title: "Cảng biển" },
  { key: "exclusive_sea", title: "Vùng đặc quyền kinh tế" },
  { key: "internal_sea", title: "Vùng nội thuỷ" },
  { key: "territorial_sea", title: "Vùng lãnh hải" },
];
