import { ApiConstant } from "const";
import { createApiWithToken } from "services/config/api.config";
import StringFormat from "string-format";

export const getPorts = () => createApiWithToken().get(ApiConstant.GET_ALL_PORTS);

export const deletePorts = data => {
  let baseUrl = ApiConstant.DELETE_PORTS;
  data.forEach((uid, index) => {
    if (index === 0) {
      baseUrl += "?uids=";
    }
    if (index < data.length - 1) {
      baseUrl += `${uid},`;
    } else {
      baseUrl += uid;
    }
  });
  return createApiWithToken().delete(baseUrl);
};

export const updatePort = data => {
  let baseUrl = ApiConstant.UPDATE_PORTS;

  return createApiWithToken().put(StringFormat(baseUrl, data.uid), data);
};
