import L from "leaflet";
import { useMapContext } from "./useMapContext";

export const useVectorMarker = () => {
  const { vectorLayerRef, setHoverShip, placeholderVectorLayerRef, setClickedShip } = useMapContext();

  const clear = () => {
    if (vectorLayerRef.current) {
      vectorLayerRef.current.clear();
    }
  };

  const refresh = () => {
    if (vectorLayerRef.current) {
      vectorLayerRef.current.redraw();
    }
  };
  const createIconVector = (course, speed, metresPerPixel) => {
    if (speed === null) {
      speed = 0;
    }
    return L.icon({
      iconSize: [20, (speed * 400) / metresPerPixel],
      iconAnchor: [0, -5],
      rotationAngle: course - 180,
      iconUrl:
        "data:image/svg+xml;base64," +
        btoa(`<svg height="210" width="300" xmlns="http://www.w3.org/2000/svg">
        <line x1="0" y1="0" x2="14" y2="10000" style="stroke:black;stroke-width:20" />
      </svg>
      `),
    });
  };

  const createMarker = (lat, lng, icon, info) => {
    const marker = L.marker([lat, lng], { icon: icon });
    marker.on("mouseover", function () {
      const placeholderMarker = L.marker([lat, lng], { icon: L.divIcon() });
      placeholderVectorLayerRef.current.addLayer(placeholderMarker);
      placeholderMarker.on("mouseover", function (e) {
        setHoverShip({ info: info, event: e.originalEvent });
      });
      placeholderMarker.on("click", function (e) {
        setClickedShip({ info: info, event: e.originalEvent });
      });
    });
    marker.on("mouseout", function (e) {
      placeholderVectorLayerRef.current.clearLayers();
      setHoverShip({ info: null, event: null });
    });

    return marker;
  };

  const show = (points = [], metresPerPixel) => {
    const markers = points.map(point => {
      const marker = createMarker(
        point.ship_lat,
        point.ship_long,
        createIconVector(point.course, point.speed, metresPerPixel),
        point,
      );
      return marker;
    });

    vectorLayerRef.current.addMarkers(markers);
  };

  const remove = (markers = []) => {
    vectorLayerRef.current.removeMarkers(markers);
  };

  return {
    clear,
    refresh,
    show,
    remove,
  };
};
