import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { FileDownloadOutlined } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import StringFormat from "string-format";
import { debounce } from "lodash";
import { startOfDay } from "date-fns";
import { AppConstant, FormatConstant, LangConstant, SystemConstant } from "const";
import { convertMillisecondsToDateTime } from "utils/date.utils";
import UserActionRedux from "redux-store/userAction.redux";
import useUserActionTypeList from "../CurrentUserAction/hooks/useUserActionTypeList";
import { hasRoleScopes } from "utils";

const UserActionHistory = () => {
  const list = useSelector(state => state.userActionRedux.userActionAllList);

  const { actionList } = useUserActionTypeList();

  const classes = useStyles();
  const dispatch = useDispatch();

  const [searchInput, setSearchInput] = useState("");
  const [action, setAction] = useState(SystemConstant.USER_ACTION.ALL);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [pagination, setPagination] = useState(1);

  const onSelectActionType = event => {
    setPagination(1);
    setAction(event.target.value);
  };

  const getNameAction = action => {
    return actionList.find(item => action === item.value).label;
  };

  const onChangeSearchInput = debounce(value => {
    setPagination(1);
    setSearchInput(value);
  }, 500);

  const onChangeFromDate = value => {
    setPagination(1);
    if (value) {
      setFromDate(startOfDay(value).getTime());
    } else {
      setFromDate(null);
    }
  };

  const onChangeToDate = value => {
    setPagination(1);
    if (value) {
      setToDate(startOfDay(value).getTime());
    } else {
      setToDate(null);
    }
  };

  const onChangePagination = (event, value) => {
    setPagination(1);
    setPagination(value);
  };

  const onClickDownload = () => {
    dispatch(
      UserActionRedux.exportUserActionListAllUser({
        exportF: 1,
        action: action,
        search: searchInput,
        start: fromDate,
        end: toDate ? toDate + AppConstant.TIMESTAMP_ONE_DAY : toDate,
      }),
    );
  };

  useEffect(() => {
    dispatch(
      UserActionRedux.getUserActionListAllUser({
        page: pagination,
        action: action,
        search: searchInput,
        start: fromDate,
        end: toDate ? toDate + AppConstant.TIMESTAMP_ONE_DAY : toDate,
      }),
    );
  }, [pagination, action, searchInput, fromDate, toDate]);

  return (
    <Box>
      <Box sx={{ display: "flex", gap: 2, marginTop: 1 }}>
        <TextField
          onChange={event => onChangeSearchInput(event.target.value)}
          size="small"
          label={LangConstant.L_SEARCH}
          variant="outlined"
          sx={{ width: 350 }}
        />

        <FormControl size="small" sx={{ width: 250 }}>
          <InputLabel id="select-user-action-type">{LangConstant.TXT_ACTION}</InputLabel>
          <Select
            labelId="select-user-action-type"
            label={LangConstant.TXT_ACTION}
            value={action}
            onChange={onSelectActionType}
          >
            {actionList.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={LangConstant.L_FROM_DAY}
            inputFormat={FormatConstant.FM_DD_MM_YYYY}
            onChange={value => onChangeFromDate(value)}
            value={fromDate}
            maxDate={new Date()}
            renderInput={params => <TextField size="small" {...params} />}
          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={LangConstant.L_TO_DAY}
            inputFormat={FormatConstant.FM_DD_MM_YYYY}
            onChange={value => onChangeToDate(value)}
            value={toDate}
            maxDate={new Date()}
            renderInput={params => <TextField size="small" {...params} />}
          />
        </LocalizationProvider>
      </Box>

      <Box>
        <Box className={classes.BtnBox}>
          <Typography color="#418DFF" fontWeight="700" align="center">
            {StringFormat(LangConstant.FM_ALL_EVENT, list.total)}
          </Typography>
          {hasRoleScopes(AppConstant.SCOPES.USER_ACTION_MN_EXPORT) && (
            <Button variant="outlined" startIcon={<FileDownloadOutlined />} onClick={onClickDownload}>
              {LangConstant.L_DOWNLOAD_LIST}
            </Button>
          )}
        </Box>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.cellHeader}>{LangConstant.TXT_STT}</TableCell>
                <TableCell className={classes.cellHeader}>{LangConstant.TXT_NAME}</TableCell>
                <TableCell className={classes.cellHeader}>{LangConstant.L_EMAIL}</TableCell>
                <TableCell className={classes.cellHeader}>{LangConstant.TXT_TIME}</TableCell>
                <TableCell className={classes.cellHeader}>{LangConstant.TXT_IP_ADDRESS}</TableCell>
                <TableCell className={classes.cellHeader}>{LangConstant.TXT_ACTION}</TableCell>
                <TableCell className={classes.cellHeader}>{LangConstant.TXT_STATUS}</TableCell>
              </TableRow>
            </TableHead>
            {
              <TableBody>
                {list.data &&
                  list.data.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell align="center">{(pagination - 1) * list.size + index + 1}</TableCell>
                        <TableCell align="center">{row.user.name}</TableCell>
                        <TableCell align="center">{row.user.email}</TableCell>
                        <TableCell align="center">{convertMillisecondsToDateTime(row.created)}</TableCell>
                        <TableCell align="center">{row.ipAddress}</TableCell>
                        <TableCell align="center">{getNameAction(row.action)}</TableCell>
                        <TableCell align="center">
                          {row.status === SystemConstant.STATUS_ACTION.SUCCESS
                            ? LangConstant.TXT_SUCCESS
                            : LangConstant.TXT_FAIL}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            }
          </Table>
        </TableContainer>

        <Box className={classes.panigationPadding}>
          <Pagination
            onChange={onChangePagination}
            sx={{ paddingTop: 2 }}
            count={+list.totalPage}
            variant="outlined"
            page={pagination}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default UserActionHistory;

const useStyles = makeStyles({
  cellHeader: {
    fontWeight: "bold",
    textAlign: "center",
  },

  BtnBox: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 16,
    marginBottom: 4,
    alignItems: "center",
  },
});
