import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, IconButton, Typography, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Close, AccessTime } from "@mui/icons-material";
import { convertHex2rgba, getPositionOfPopup, convertCoordinates, dragElement } from "utils";
import { useMapContext, useNotification } from "hooks";
import AreaActions from "redux-store/area.redux";

const LayerWeatherPointDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { weatherDialog, setWeatherDialog } = useMapContext();
  const pointWeather = useSelector(state => state.areaRedux.pointWeather);

  const [dialogPositions, setDialogPositions] = useState({ top: 0, left: 0, bottom: 0, right: 0, display: "none" });
  const [weatherData, setWeatherData] = useState();
  const [currentData, setCurrentData] = useState();
  const [selectData, setSelectData] = useState();

  const handleContextClick = e => {
    const pointerEvent = e.originalEvent;
    const { top, left, bottom, right } = getPositionOfPopup(pointerEvent);
    setDialogPositions({ top, left, bottom, right, display: "block" });
  };

  const handleClose = () => {
    setDialogPositions(state => ({ ...state, display: "none" }));
  };

  const handleStopPropagation = e => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (weatherDialog.event) {
      handleContextClick(weatherDialog.event);
      dispatch(
        AreaActions.getWeatherPoint({
          lat: weatherDialog.event.latlng.lat,
          lon: weatherDialog.event.latlng.lng,
        }),
      );
    } else {
      handleClose();
    }
    dragElement("layer-weather-point-dialog-");
  }, [weatherDialog]);

  useEffect(() => {
    const now = new Date();
    const hours = now.getHours();
    const _weatherData = pointWeather?.map(item => {
      const date = new Date(item.date);

      // Get the day of the week as a number (0-6)
      const dayOfWeekNumber = date.getDay();

      // Optionally, map the number to the weekday name
      const weekdays = ["Chủ nhật", "Thứ 2", "Thứ 3", "Thứ 4", "Thứ 5", "Thứ 6", "Thứ 7"];
      const dayOfWeekName = weekdays[dayOfWeekNumber];
      return { ...item, dayOfWeekName: dayOfWeekName };
    });

    setWeatherData(_weatherData);
    setCurrentData(_weatherData?.[0]?.hourly?.[Math.floor(hours / 3)]);
    setSelectData(_weatherData?.[0]);
  }, [pointWeather]);

  return (
    <Box
      className={classes.root}
      onClick={handleStopPropagation}
      onDoubleClick={handleStopPropagation}
      onWheel={handleStopPropagation}
      sx={dialogPositions}
      component="section"
      id="layer-weather-point-dialog-"
    >
      <Box className={classes.addFormHeader} id="layer-weather-point-dialog-header">
        <Typography className={classes.headerTitle}>Thông tin thời tiết tham khảo</Typography>
        <IconButton className={classes.closeBtn} onClick={handleClose}>
          <Close />
        </IconButton>
      </Box>
      <Box className={classes.formBody}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell rowSpan={2} className={classes.rightTable}>
                <Box className={classes.rightBoxMenu}>
                  <span className={classes.spanLatlon}>
                    Vị trí:
                    <br />
                    {weatherDialog.event &&
                      convertCoordinates(
                        Number(weatherDialog.event.latlng.lat),
                        Number(weatherDialog.event.latlng.lng),
                      )}
                    <br />
                    <img id="spanNgay" src={currentData?.weather_icon_url?.[0]?.value}></img>
                    <br />
                    <h5>{currentData?.temp_c} °C</h5>
                  </span>
                </Box>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <span
                  className={classes.currentDay}
                >{`${selectData?.dayOfWeekName || ""} - ${selectData?.date || ""}`}</span>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <span
                  className={classes.dayTempC}
                >{`${selectData?.min_temp_c || ""} °C - ${selectData?.max_temp_c || ""} °C`}</span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={3}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.wwoTieude}>Giờ</TableCell>
                      <TableCell className={classes.wwoTieudeIcon}>
                        <AccessTime />
                      </TableCell>
                      <TableCell className={classes.wwoGio}>0H</TableCell>
                      <TableCell className={classes.wwoGio}>3H</TableCell>
                      <TableCell className={classes.wwoGio}>6H</TableCell>
                      <TableCell className={classes.wwoGio}>9H</TableCell>
                      <TableCell className={classes.wwoGio}>12H</TableCell>
                      <TableCell className={classes.wwoGio}>15H</TableCell>
                      <TableCell className={classes.wwoGio}>18H</TableCell>
                      <TableCell className={classes.wwoGio}>21H</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.wwoTieude}></TableCell>
                      <TableCell className={classes.wwoTieudeIcon}></TableCell>
                      {selectData?.hourly?.map(item => (
                        <TableCell className={classes.wwo_img_weather}>
                          <img className={classes.wwo_img_weather_icon} src={item?.weather_icon_url?.[0]?.value} />
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.wwoTieude}>Nhiệt độ</TableCell>
                      <TableCell className={classes.wwoTieudeIcon}>°C</TableCell>
                      {selectData?.hourly?.map(item => (
                        <TableCell className={classes.wwo_cell}>{item.temp_c}</TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.wwoTieude}>Gió</TableCell>
                      <TableCell className={classes.wwoTieudeIcon}>(km/h)</TableCell>
                      {selectData?.hourly?.map(item => (
                        <TableCell className={classes.wwo_cell}>{item.wind_speed_kmph}</TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.wwoTieude}>Hướng gió</TableCell>
                      <TableCell className={classes.wwoTieudeIcon}></TableCell>
                      {selectData?.hourly?.map(item => (
                        <TableCell className={classes.wwo_cell}>{item.wind_dir_degree}</TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.wwoTieude}>Mưa</TableCell>
                      <TableCell className={classes.wwoTieudeIcon}>mm</TableCell>
                      {selectData?.hourly?.map(item => (
                        <TableCell className={classes.wwo_cell}>{item.precip_mm}</TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.wwoTieude}>Độ ẩm</TableCell>
                      <TableCell className={classes.wwoTieudeIcon}>%</TableCell>
                      {selectData?.hourly?.map(item => (
                        <TableCell className={classes.wwo_cell}>{item.humidity}</TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.wwoTieude}>Sóng</TableCell>
                      <TableCell className={classes.wwoTieudeIcon}>m</TableCell>
                      {selectData?.hourly?.map(item => (
                        <TableCell className={classes.wwo_cell}>{item.swell_height_m}</TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.wwoTieude}>Hướng sóng</TableCell>
                      <TableCell className={classes.wwoTieudeIcon}></TableCell>
                      {selectData?.hourly?.map(item => (
                        <TableCell className={classes.wwo_cell}>{item.swell_dir}</TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.wwoTieude}>Tầm nhìn xa</TableCell>
                      <TableCell className={classes.wwoTieudeIcon}>km</TableCell>
                      {selectData?.hourly?.map(item => (
                        <TableCell className={classes.wwo_cell}>{item.visibility}</TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.wwoTieude}>Mây che phủ</TableCell>
                      <TableCell className={classes.wwoTieudeIcon}>%</TableCell>
                      {selectData?.hourly?.map(item => (
                        <TableCell className={classes.wwo_cell}>{item.cloud_cover}</TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.wwoTieude}>Nhiệt độ nước</TableCell>
                      <TableCell className={classes.wwoTieudeIcon}>°C</TableCell>
                      {selectData?.hourly?.map(item => (
                        <TableCell className={classes.wwo_cell}>{item.water_temp_c}</TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.wwoTieude}></TableCell>
                      <TableCell className={classes.wwoTieudeIcon}></TableCell>
                      <TableCell colSpan={8}>
                        <div className={classes.wwoButton}>
                          {weatherData?.map(item => (
                            <Button
                              variant={item.date === selectData?.date ? "contained" : "outlined"}
                              onClick={() => {
                                setSelectData(item);
                              }}
                            >
                              {item.dayOfWeekName}
                            </Button>
                          ))}
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <Box className={classes.actionContainer}>
        <Button
          disableElevation
          variant="outlined"
          onClick={() => {
            setWeatherDialog({ event: null });
          }}
        >
          Thoát
        </Button>
      </Box>
    </Box>
  );
};

export default memo(LayerWeatherPointDialog);

const useStyles = makeStyles(() => ({
  root: {
    position: "fixed",
    width: 800,
    backgroundColor: "#fff",
    zIndex: 1000,
    boxShadow: "0px 0px 2px 2px rgba(0, 0, 0, 0.25)",
    borderRadius: 4,
    cursor: "auto",
  },

  addFormHeader: {
    height: "fit-content",
    width: "100%",
    backgroundColor: "#1976d2",
    padding: "10px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    color: "#fff",
    borderRadius: "4px 4px 0 0",
    cursor: "all-scroll",
  },

  closeBtn: {
    color: "white",
    position: "absolute",
    right: 10,
    top: "50%",
    transform: "translateY(-50%)",
    padding: 6,
    "&>svg": {
      fontSize: 16,
    },
  },

  formBody: {
    display: "block",
    color: "#243879",
  },

  loadingContainer: {
    padding: "8px 15px 0",
    position: "absolute",
    background: convertHex2rgba("#808080", 0.9),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    zIndex: 1001,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  loading: {},

  headerTitle: {
    fontSize: 15,
    fontWeight: 450,
  },

  actionContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "20px 15px",
    alignItems: "center",
    gap: 30,
  },

  rightTable: {
    borderRight: "1px solid #dddddd",
    width: "110px",
  },

  rightBoxMenu: {
    "text-align": "center",
    width: "110px",
  },
  spanLatlon: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#243879",
  },
  currentDay: {
    padding: "15px",
    fontSize: 16,
    fontWeight: "bold",
  },
  dayTempC: {
    textAlign: "right",
    fontSize: "16px",
    fontWeight: "bold",
    padding: "15px",
  },
  tableCell: {
    height: 28,
    padding: "5px 0",
  },
  wwoGio: {
    backgroundColor: "#3598dc",
    color: "#fff",
    padding: "3px 0",
    textAlign: "center",
    borderBottom: "none",
    borderTop: "none",
  },
  wwoTieude: {
    textAlign: "left",
    padding: "0 0 0 5px",
    width: 100,
    borderBottom: "none",
    borderTop: "none",
  },
  wwoTieudeIcon: {
    paddingLeft: 5,
    width: 50,
    borderBottom: "none",
    borderTop: "none",
    padding: 5,
  },
  wwo_img_weather: {
    padding: "1px 0px",
    borderBottom: "none",
    borderTop: "none",
    textAlign: "center",
  },
  wwo_img_weather_icon: {
    width: "36px",
    height: "27px",
    textAlign: "center",
  },
  wwo_cell: {
    borderBottom: "none",
    textAlign: "center",
    padding: 0,
    height: 30,
    lineHeight: 1.2,
  },
}));
