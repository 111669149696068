import { AppConstant } from "const";
import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  loginPage: ["data"],
  sendOtp: ["data"],
  verifyOtp: ["data"],
  loginTrios: ["data"],
  linkTrios: ["data"],

  authSet: ["data"],
  authReset: [],
  linkTriosPopup: ["data"],
  linkTriosSubmit: ["data"],
});

export const AuthTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  loginSuccessValue: null,
  loginType: AppConstant.LOGIN_TYPE.normal,
  loginOtpStatus: {
    status: {
      sendOtp: AppConstant.API_STATUS.nothing,
      verifyOtp: AppConstant.API_STATUS.nothing,
    },
    message: {
      messageError: AppConstant.OTP_ERROR_TYPE.nothing,
      turn: AppConstant.OTP_RETRY_LIMIT,
    },
  },
  connectTriosStatus: {
    status: {
      getThirdToken: AppConstant.API_STATUS.nothing,
      linkTrios: AppConstant.API_STATUS.nothing,
      loginTrios: AppConstant.API_STATUS.nothing,
    },
    message: AppConstant.CONNECT_TRIOS_MESSAGE_TYPE.nothing,
  },
  linkTriosPopup: {
    token: null,
  },
};

/* ------------- Reducers ------------- */

export const request = (state = INITIAL_STATE, action) => {
  let data = action.data || {};
  return {
    ...state,
    ...data,
    isFetching: true,
  };
};

export const set = (state = INITIAL_STATE, action) => {
  let data = action.data || {};

  return {
    ...state,
    ...data,
  };
};

export const reset = () => INITIAL_STATE;

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.LOGIN_PAGE]: request,
  [Types.SEND_OTP]: request,
  [Types.VERIFY_OTP]: request,
  [Types.LOGIN_TRIOS]: request,
  [Types.LINK_TRIOS]: request,
  [Types.LINK_TRIOS_SUBMIT]: request,

  [Types.AUTH_SET]: set,
  [Types.AUTH_RESET]: reset,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
