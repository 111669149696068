import { useRef } from "react";
import domtoimage from "dom-to-image";
import { VIDEO_CANVAS_PREVIEW_ID } from "pages/HomePage";

export const useExportTrackingVideo = () => {
  const firstTimeStamp = useRef(0);
  const interval = useRef(null);
  const chunks = useRef([]);
  const images = useRef([]);

  const capture = ({ id, framerate, duration, onEnd }) => {
    const element = document.getElementById(id);
    const canvas = document.getElementById(VIDEO_CANVAS_PREVIEW_ID);
    canvas.width = element.clientWidth;
    canvas.height = element.clientHeight;
    const context = canvas.getContext("2d");
    const videoStream = canvas.captureStream(framerate);
    const mediaRecorder = new window.MediaRecorder(videoStream, { mimeType: "video/webm" });
    console.log("mediaRecorder", mediaRecorder);

    let startTime = 0;
    let endTime = 0;

    function stop() {
      Promise.all(images.current).then(urls => {
        startTime = Date.now();
        console.log("Start recording canvas", startTime);
        mediaRecorder.start();
        urls.forEach((url, index) => {
          const img = new Image();
          img.src = url;
          img.style.width = canvas.width + "px";
          img.style.height = canvas.height + "px";
          img.onload = () => {
            setTimeout(
              () => {
                context.clearRect(0, 0, canvas.width, canvas.height);
                context.drawImage(img, 0, 0);
                mediaRecorder.requestData();
                if (index === urls.length - 1) {
                  setTimeout(() => {
                    endTime = Date.now();
                    console.log("Finish recording canvas", endTime);
                    mediaRecorder.stop();
                  }, 2000);
                }
              },
              (1000 / framerate) * index,
            );
          };
        });
      });
    }

    let lastFrameTime = Date.now();

    function start(timestamp) {
      const currentTime = Date.now();
      const timeSinceLastFrame = currentTime - lastFrameTime;
      if (firstTimeStamp.current === 0) {
        firstTimeStamp.current = timestamp;
        interval.current = window.requestAnimationFrame(start);
      } else {
        if (timestamp - firstTimeStamp.current >= duration / 2 + 1000) {
          window.cancelAnimationFrame(interval.current);
          stop();
        } else {
          if (timeSinceLastFrame > 1000 / framerate) {
            lastFrameTime = currentTime;
            const promise = domtoimage.toJpeg(element, {
              filter: node => node.nodeName !== "SECTION" && node.className !== "leaflet-control-container",
            });
            console.log(
              "Taking screenshoot:",
              images.current.length + 1,
              (timestamp - firstTimeStamp.current) / 1000,
              promise,
            );
            images.current.push(promise);
          }
          interval.current = window.requestAnimationFrame(start);
        }
      }
    }

    mediaRecorder.ondataavailable = function (e) {
      console.log("Push chunk", e.data);
      chunks.current.push(e.data);
    };

    mediaRecorder.onstop = function () {
      const blob = new Blob(chunks.current, { type: mediaRecorder.mimeType });
      firstTimeStamp.current = 0;
      interval.current = null;
      chunks.current = [];
      images.current = [];
      context.clearRect(0, 0, canvas.width, canvas.height);
      console.log("Exporting video", endTime - startTime, blob);
      onEnd(blob);
    };

    interval.current = window.requestAnimationFrame(start);
  };

  return { capture };
};
