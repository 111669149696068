import React, { useEffect, useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ReactSlider from "react-slider";
import { AppConstant, LangConstant } from "const";

export default function SliderCapacity({ data, onChange }) {
  const classes = useStyles();

  const [minData, setMinData] = useState(data.min);
  const [maxData, setMaxData] = useState(data.max);

  const onChangeMinData = e => {
    let value = e.target.value;
    if (value === "") value = 0;
    value = parseInt(value);
    if (value < 0) {
      value = 0;
    } else if (value > AppConstant.SHIP_CAPACITY.max) {
      value = AppConstant.SHIP_CAPACITY.max;
    }

    setMinData(value);
  };

  const onChangeMaxData = e => {
    let value = e.target.value;
    if (value === "") value = 0;
    value = parseInt(value);
    if (value < 0) {
      value = 0;
    } else if (value > AppConstant.SHIP_CAPACITY.max) {
      value = AppConstant.SHIP_CAPACITY.max;
    }

    setMaxData(value);
  };

  useEffect(() => {
    onChange(parseInt(minData), parseInt(maxData));
  }, [minData, maxData]);

  useEffect(() => {
    setMinData(data.min);
    setMaxData(data.max);
  }, [data]);

  return (
    <Box className={classes.capacityBox}>
      <ReactSlider
        value={[minData, maxData]}
        className={classes.slider}
        trackClassName={classes.tracker}
        min={AppConstant.SHIP_CAPACITY.min}
        max={AppConstant.SHIP_CAPACITY.max}
        minDistance={50}
        step={50}
        withTracks={true}
        pearling={true}
        renderThumb={props => {
          return <Box {...props} className={classes.thumb}></Box>;
        }}
        renderTrack={props => {
          return <Box {...props} className={classes.track}></Box>;
        }}
        onChange={([min, max]) => {
          setMinData(min);
          setMaxData(max);
          onChange(min, max);
        }}
      />

      <Box className={classes.valueWrapper}>
        <Box className={classes.valueTitle}>
          <Typography className={classes.labelValue}>{LangConstant.TXT_ENTER_THE_VALUE}</Typography>
        </Box>
        <Box className={classes.input}>
          <TextField
            hiddenLabel
            id="filled-hidden-label-small"
            variant="filled"
            size="small"
            type="number"
            value={minData}
            onChange={onChangeMinData}
          />
        </Box>
        <Box className={classes.dash}>
          <Typography className={classes.labelValue}>{LangConstant.TXT_DASH}</Typography>
        </Box>
        <Box className={classes.input}>
          <TextField
            hiddenLabel
            id="filled-hidden-label-small"
            variant="filled"
            size="small"
            type="number"
            value={maxData}
            onChange={onChangeMaxData}
          />
        </Box>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  capacityBox: {
    padding: "10px 10px",
    background: "#fff",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    borderRadius: 4,
  },

  capacityTitleBox: {
    padding: "10px 0",
  },

  slider: {
    marginTop: 10,
    cursor: "pointer",
    width: "100%",
  },

  track: {
    backgroundColor: "gray",
    height: 2,
    "&:nthChild(2)": {
      backgroundColor: "#383838",
    },
  },

  thumb: {
    width: 12,
    height: 12,
    background: "#fff",
    border: "2px solid #383838",
    borderRadius: "50%",
    cursor: "pointer",
    position: "absolute",
    top: -7,
  },

  valueWrapper: {
    marginTop: 32,
    display: "flex",
    justifyContent: "space-between",
  },

  valueTitle: {
    display: "flex",
    alignItems: "center",
  },

  input: {
    width: 100,
  },

  dash: {
    display: "flex",
    alignItems: "center",
  },

  labelValue: {
    fontSize: 14,
    color: "#000",
  },
}));
