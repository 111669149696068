import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { Fragment, useMemo, useState } from "react";
import { Delete, ArrowBack, RemoveRedEye } from "@mui/icons-material";
import countryList from "react-select-country-list";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getContry } from "utils";
import { format } from "date-fns";
import ConversationRedux from "redux-store/conversation.redux";
import AddWatchlist from "./AddWatchlist";
import { useNavigate, useParams } from "react-router-dom";
import ViewDetailsWatchList from "./ViewDetailsWatchList";
import { handleSendNotiInSaga } from "utils";
import { LangConstant } from "const";

function createData(
  stt,
  name,
  uid,
  imo,
  mmsi_code,
  call_sign,
  typeship,
  plate_licence,
  country_code,
  created,
  action,
  note,
) {
  return { stt, name, uid, imo, mmsi_code, call_sign, typeship, plate_licence, country_code, created, action, note };
}

const EditWatchList = () => {
  const classes = useStyles();
  let { id } = useParams();
  const navigator = useNavigate();

  const options = useMemo(() => countryList().getData(), []);
  const dispatch = useDispatch();
  const getTypeShip = useSelector(state => state.conversationRedux.getTypeShip);
  const checkbox = useSelector(state => state.conversationRedux.checkbox);
  const addGroupShip = useSelector(state => state.conversationRedux.addGroupShip);
  const getDetailGroup = useSelector(state => state.conversationRedux.getDetailGroup);
  const sucessCreateShip = useSelector(state => state.conversationRedux.sucessCreateShip);
  const pagination = useSelector(state => state.conversationRedux.pagination);
  const totalPageDetailGroup = useSelector(state => state.conversationRedux.totalPageDetailGroup);
  const allItemsDetailsGroups = useSelector(state => state.conversationRedux.allItemsDetailsGroups);

  const [page, setPage] = useState(1);
  const [valueInputDetail, setValueInputDetail] = useState("");
  const [nationality, setNationality] = useState("");
  const [ships, setShips] = useState("");
  const [showTable, setShowTable] = useState();
  const [isShowAddShip, setIsShowAddShip] = useState(false);
  const [note, setNote] = useState(getDetailGroup?.note);
  const [reasonName, setReasonName] = useState(getDetailGroup?.name);
  const [confirmdeleteDetails, setConfirmDeleteDetails] = useState(false);
  const [itemDelte, setItemDelete] = useState("");
  const [viewItem, setViewItem] = useState("");
  const [isShowViewWatchlist, setIsShowViewWatchlist] = useState(false);

  const onClickDelete = item => {
    setConfirmDeleteDetails(true);
    setItemDelete(item);
  };

  const onClickDeletConfirm = () => {
    let newArr = [...showTable];
    newArr = newArr.filter(itemId => itemId.uid !== itemDelte.uid);
    setShowTable(newArr);
    dispatch(
      ConversationRedux.deleteGroupDetailShip({
        uid: id,
        ships: itemDelte.uid,
      }),
    );
    setConfirmDeleteDetails(false);
    dispatch(
      ConversationRedux.conversationSet({
        allItemsDetailsGroups: Object.assign(allItemsDetailsGroups, {
          ships: allItemsDetailsGroups.ships.filter(data => data.uid !== itemDelte.uid),
        }),
      }),
    );
  };

  useEffect(() => {
    setNote(getDetailGroup?.note);
  }, [getDetailGroup?.note]);

  const onClickAddListDetail = () => {
    const arrUidsDetailsGroup = allItemsDetailsGroups?.ships?.map(item => item.uid);
    dispatch(
      ConversationRedux.updateGroupShip({
        uid: id,
        note: note,
        name: reasonName,
        ships: [...arrUidsDetailsGroup, ...checkbox],
      }),
    );
  };

  useEffect(() => {
    setReasonName(getDetailGroup?.name);
  }, [getDetailGroup?.name]);

  useEffect(() => {
    if (id) {
      dispatch(
        ConversationRedux.getGroupShipDetail({
          uid: id,
          filter: valueInputDetail,
          country_code: nationality,
          ship_type: ships,
          page: page,
        }),
      );
    }
  }, [nationality, page, ships, valueInputDetail, sucessCreateShip]);

  useEffect(() => {
    if (addGroupShip) {
      handleSendNotiInSaga(LangConstant.MS_UPDATE_SUCCESS, "success");
      navigator("/admin/watchList");
      dispatch(
        ConversationRedux.conversationSet({
          addGroupShip: false,
        }),
      );
    }
  }, [addGroupShip]);

  useEffect(() => {
    if (id) {
      dispatch(
        ConversationRedux.getListDetails({
          uid: id,
          size: 1000,
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (getDetailGroup) {
      let result = [];
      getDetailGroup?.ships?.map((item, index) => {
        return result.push(
          createData(
            index,
            item.name,
            item.uid,
            item.imo,
            item.mmsi_code,
            item.call_sign,
            getTypeShip && getTypeShip.filter(itemShip => itemShip.type === item.type).map(item => item.name),
            item.plate_licence,
            item.country_code === "Unknown"
              ? "Không xác định"
              : getContry(options, item?.ship_detail?.country_code).map(item => item.label),
            format(item?.created, "dd/MM/yyyy"),
            <Fragment>
              <IconButton onClick={() => onClickDelete(item)}>
                <Delete />
              </IconButton>
              <IconButton
                onClick={() => {
                  setViewItem(item);
                  setIsShowViewWatchlist(true);
                }}
              >
                <RemoveRedEye />
              </IconButton>
            </Fragment>,
            item.note,
          ),
        );
      });
      setShowTable(result);
    }
  }, [getDetailGroup]);

  return (
    <>
      <Box style={{ width: "100%" }}>
        <DialogTitle>
          <Box className={classes.arrBackflex}>
            <IconButton onClick={() => navigator("/admin/watchList")}>
              <ArrowBack />
            </IconButton>
            <Typography>{getDetailGroup.name}</Typography>
          </Box>
          <Box className={classes.titleInputCenter}>
            <TextField
              onChange={e => setValueInputDetail(e.target.value)}
              fullWidth
              size="small"
              label="Tìm kiếm"
              variant="outlined"
            />
          </Box>
          <Box className={classes.paddingNameShip}>
            <Box>
              <Typography>Tên danh sách</Typography>
              <TextField
                style={{ width: 200 }}
                size="small"
                variant="outlined"
                onChange={e => setReasonName(e.target.value)}
                value={reasonName}
              />
            </Box>
            <Box style={{ marginLeft: 10, width: 200 }}>
              <Typography>Lý do giám sát</Typography>
              <TextField size="small" value={note} variant="outlined" onChange={e => setNote(e.target.value)} />
            </Box>
          </Box>
          <Button onClick={onClickAddListDetail} color="warning" variant="contained">
            Lưu
          </Button>
          <Box style={{ display: "flex", padding: "10px 0" }}>
            <FormControl style={{ width: 200 }} size="small">
              <InputLabel id="demo-simple-select-label">Quốc tịch</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Quốc tịch"
                value={nationality}
                onChange={e => setNationality(e.target.value)}
              >
                <MenuItem value="">Tất cả</MenuItem>
                {options &&
                  options.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl style={{ width: 200, marginLeft: 10 }} size="small">
              <InputLabel id="demo-simple-select-label">Loại tàu</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={ships}
                label="Loại tàu"
                onChange={e => setShips(e.target.value)}
              >
                <MenuItem value="">Tất cả</MenuItem>
                {getTypeShip &&
                  getTypeShip.map((item, index) => (
                    <MenuItem key={index} value={item.type}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.containerTitle}>
            <Button onClick={() => setIsShowAddShip(true)} color="warning" variant="contained">
              Thêm tàu
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ overflow: "hidden" }}>
          <TableContainer sx={{ height: 500, overflow: "auto" }} component={Paper}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width="50">STT</TableCell>
                  <TableCell align="center">Tên tàu</TableCell>
                  <TableCell align="center">Lý do giám sát</TableCell>
                  <TableCell align="center">IMO</TableCell>
                  <TableCell align="center">MMSI</TableCell>
                  <TableCell align="center">Hô hiệu</TableCell>
                  <TableCell align="center">Loại tàu</TableCell>
                  <TableCell align="center">Nhà cung cấp</TableCell>
                  <TableCell align="center">Quốc tịch</TableCell>
                  <TableCell align="center">Thời gian tạo</TableCell>
                  <TableCell align="center">Thao tác</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {showTable &&
                  showTable.map((row, index) => {
                    return (
                      <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell align="right">{(page - 1) * pagination + index + 1}</TableCell>
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell align="center">{row.note}</TableCell>
                        <TableCell align="center">{row.imo}</TableCell>
                        <TableCell align="center">{row.mmsi_code}</TableCell>
                        <TableCell align="center">{row.call_sign}</TableCell>
                        <TableCell align="center">{row.typeship}</TableCell>
                        <TableCell align="center">{row.plate_licence}</TableCell>
                        <TableCell align="center">{row.country_code}</TableCell>
                        <TableCell align="center">{row.created}</TableCell>
                        <TableCell align="center">{row.action}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box className={classes.paddingPanigation}>
            <Pagination onChange={(e, value) => setPage(value)} count={+totalPageDetailGroup} variant="outlined" />
          </Box>
        </DialogContent>
        <AddWatchlist
          button
          open={isShowAddShip}
          onClose={() => setIsShowAddShip(false)}
          groupShip={{ uid: id, note: note, name: reasonName }}
        />
      </Box>
      {confirmdeleteDetails && (
        <Dialog
          open={confirmdeleteDetails}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Xác nhận xóa </DialogTitle>
          <DialogContent>{`Bạn có muốn xóa ${itemDelte.name} không?`}</DialogContent>
          <DialogActions>
            <Button onClick={onClickDeletConfirm}>Đồng ý</Button>
            <Button onClick={() => setConfirmDeleteDetails(false)} autoFocus>
              Thoát
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <ViewDetailsWatchList
        dataView={viewItem}
        open={isShowViewWatchlist}
        onClose={() => setIsShowViewWatchlist(false)}
      />
    </>
  );
};

export default EditWatchList;

const useStyles = makeStyles(theme => ({
  containerTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 10,
  },
  titleInputCenter: {
    textAlign: "center",
    paddingBottom: 10,
    width: 400,
    margin: "0 auto",
  },
  paddingNameShip: {
    padding: "10px 0",
    alignItems: "center",
    display: "flex",
  },
  arrBackflex: {
    display: "flex",
    alignItems: "center",
  },
  paddingButtonSave: {
    paddingLeft: 10,
  },
  paddingPanigation: {
    padding: "10px 0",
  },
}));
