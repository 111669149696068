import shipStatus from "static/ship-status.json";
import provinces from "static/provinces.json";
import crawlerResources from "static/crawler-resources.json";
import filterShipByTimes from "static/filter-times.json";
import shipTypes from "static/ship-types.json";

// Key constants
export const KEY_ACCESS_TOKEN = "KEY_ACCESS_TOKEN";
export const KEY_ROLE_ID = "KEY_ROLE_ID";
export const KEY_USER_ID = "KEY_USER_ID";
export const KEY_UID_UPLOAD = "KEY_UID_UPLOAD";
export const KEY_USER_NAME = "KEY_USER_NAME";
export const KEY_TYPE_LOGIN = "KEY_TYPE_LOGIN";
export const KEY_REFRESH_TOKEN = "KEY_REFRESH_TOKEN";
export const KEY_SCOPES = "KEY_SCOPES";

// Filter constants in search sidebar
export const FILTER_SHIP_BY_SEA_AREA_ID = 1;
export const FILTER_SHIP_BY_DRAW_AREA_ID = 2;
export const FILTER_SHIP_BY_RESOURCES = crawlerResources;
export const FILTER_SHIP_BY_TIME = filterShipByTimes;

// Country flag configs for phone input library
export const COUNTRY = ["vn"];

export const SHIP_STATUS = shipStatus;

// User role constants
export const ROLE = { MANAGER: 3, STAFF: 4, ADMIN: 2, SYSTEM_ADMIN: 1 };

// Viet Nam provinces
export const PROVINCES = provinces;

export const DATA_SOURCES = crawlerResources.reduce((output, source) => {
  output[source.id] = source.name;
  return output;
}, {});

export const SHIP_TYPES = shipTypes;

export const DEFAULT_AREA_KEY = 0;

export const WEATHER_KEY = 1;

export const CUSTOM_AREA_KEY = 2;

export const POINT_AREA_KEY = 3;

export const TYPE_KML = 4;

export const SHIP_CAPACITY = { min: 0, max: 1000000 };

export const OTP_RETRY_LIMIT = 3;

export const OTP_MAX_TIME = 60;

export const NAVBAR_ITEM_KEY = {
  manageShip: 1,
  trackingShip: 2,
};

export const LOCAL_STORAGE_KEY = {
  shipTrackingHistory: "shipTrackingHistory",
  shipSearchHistory: "shipSearchHistory",
  settingShowWeather: "settingShowWeather",
  settingShowShip: "settingShowShip",
  settingShowClusteredShip: "settingShowClusteredShip",
  settingShowShipName: "settingShowShipName",
  settingShowSpeed: "settingShowSpeed",
  selectedAreaList: "selectedAreaList",
  showOtherShip: "showOtherShip",
  backgroundMap: "backgroundMap",
};

export const STATISTIC_TYPE = {
  area: 5,
};

export const USER_TYPE = {
  user: { key: 3, title: "Người dùng" },
  system: { key: 2, title: "Hệ thống" },
};

export const VIOLATION_STATUS = {
  NOT_PROCESSING: { key: 0, title: "Chưa xử lý" },
  PROCESSING: { key: 1, title: "Đang xử lý" },
  PROCESSED: { key: 2, title: "Đã xử lý" },
};

export const BACKGROUND_JOB_TYPE = {
  IMPORT_FILE: 0,
  EXPORT_FILE: 1,
};

export const BACKGROUND_JOB_STATUS = {
  ERROR: 0,
  FINISHED: 1,
  PROCESSING: 2,
};

export const EXPORT_IMPORT_FILE_ACTION = {
  IMPORT_DATA: "Nhập dữ liệu",
  EXPORT_ALL_DATA: "Xuất tất cả dữ liệu",
  EXPORT_SHIP_DATA: "Xuất dữ liệu tàu",
  EXPORT_PORT_DATA: "Xuất dữ liệu cảng",
  EXPORT_HARBOR_DATA: "Xuất dữ liệu bến cảng",
  EXPORT_LOCATION_HISTORY_DATA: "Xuất dữ liệu lịch sử hành trình",
  EXPORT_TRACKING_SHIP_DATA: "Xuất dữ liệu theo dõi tàu thuyền",
  EXPORT_VIOLATION_SHIP_DATA: "Xuất dữ liệu tàu vi phạm",
  IMPORT_MAP_DATA: "Nhập dữ liệu bản đồ",
  UPLOAD: "Tải tệp tin lên máy chủ",
};

export const EXPORT_FILE_ACTION = {
  EXPORT_ALL_DATA: { key: 1, title: "xuất tất cả dữ liệu" },
  EXPORT_SHIP_DATA: { key: 2, title: "xuất dữ liệu tàu thuyền" },
  EXPORT_PORT_DATA: { key: 3, title: "xuất dữ liệu cảng biển" },
  EXPORT_LOCATION_HISTORY_DATA: { key: 5, title: "xuất dữ liệu lịch sử hành trình" },
  EXPORT_TRACKING_SHIP_DATA: { key: 7, title: "xuất dữ liệu theo dõi tàu thuyền" },
  EXPORT_VIOLATION_SHIP_DATA: { key: 8, title: "xuất dữ liệu tàu vi phạm" },
};

export const USER_ACTION_MN_TAB = {
  HISTORY: 1,
  SUMMARY: 2,
};

export const ACCEPTED_FILE_EXTENSIONS = ["geojson", "json", "gpx", "kml", "xml", "kmz"];

export const KNOT_TO_KMH = 1.852;

export const DEFAULT_POSITION = [15.4881, 114.4048]; // Biển Đông

export const TYPE_CREATED_MANUALLY = 5;

export const SOURCE_DATA = {
  vesselFinder: 4,
  other: 5,
  vishipel: 1,
  marineTraffic: 2,
  gstc: 3,
};

export const SOURCE_DATA_NAME = {
  1: "Vishipel",
  2: "Marine Traffic",
  3: "Giám sát tàu cá",
  4: "Vessel finder",
  5: "Khác",
};

export const AIS_TYPES = [SOURCE_DATA.vishipel, SOURCE_DATA.vesselFinder, SOURCE_DATA.marineTraffic];

export const MIMETYPE_EXCEL = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

// Mode system WAN-0; INTERNET-1
export const CURRENT_SYSTEM_MODE = parseInt(window.env?.MODE_SYSTEM || process.env?.REACT_APP_MODE_SYSTEM);

export const MAP_URL =
  process.env?.REACT_APP_MAP_URL ||
  window.env?.MAP_URL ||
  "https://mt1.google.com/vt/lyrs=m@113&hl=en&&x={x}&y={y}&z={z}&hl=vi";

export const ROLE_NAME_ALL = 0;

export const APPLICATION_TYPE_ALL = 2;

export const PAGE_SIZE = 10;

export const FIRST_PAGE = 1;

export const ONE_HOUR = 60 * 60 * 1000;

export const ONE_DAY = 24 * 60 * 60 * 1000;

export const VALUE_ALL = 0;

export const TIMESTAMP_ONE_DAY = 86400000;

export const METER_TO_NAUTICAL_MILE = 0.0005399568;

export const LOGIN_TYPE = {
  normal: 0,
  otp: 1,
  linkTrios: 2,
  loginTrios: 3,
};

export const API_STATUS = {
  nothing: "nothing",
  calling: "pending",
  success: "success",
  error: "error",
};

export const OTP_ERROR_TYPE = {
  nothing: 0,
  wrongOTP: 1,
  block: 2,
  otpExpired: 3,
  other: 4,
};

export const CONNECT_TRIOS_MESSAGE_TYPE = {
  nothing: 0,
  linked: 1,
  unlinked: 2,
  success: 3,
  other: 4,
};

export const ROLE_NAME = {
  0: "Tất cả",
  1: "Quản trị viên hệ thống",
  2: "Quản trị viên phòng",
  3: "Quản lý",
  4: "Nhân viên",
};

export const APPLICATION_TYPE = {
  2: "Tất cả",
  1: "Giám sát tàu",
  0: "TRIOS",
};

export const OTP_2FA_MODE = {
  off: 0,
  on: 1,
};

export const EVENT_WARNING = {
  USING_STATUS: "Đang sử dụng",
  NOT_USE_STATUS: "Không sử dụng",
};

export const FOLDER_ACCESS_TYPE = {
  PUBLIC: "PUBLIC",
  PRIVATE: "PRIVATE",
};

export const SHIP_LENGTH_TYPE = {
  over: "Từ 24m",
  under24m: "Dưới 24m",
  undefine: "Không xác định kích thước",
};

export const AREA_TYPE = {
  internalSea: "Nội thủy",
  vnSea: "Vùng biển Việt Nam",
};

export const COLOR_REPORT_LOST_SHIP = { over: "#418dff", under24m: "#fab740", undefine: "#75af73" };

export const IS_EXPORT_FILE = {
  no: 0,
  yes: 1,
};

export const SHIP_LOST_TYPE = {
  lostSignal: 9,
  disconnected: 10,
};

export const SCOPES = {
  SHIP_DETAIL: "SHIP_DETAIL",
  SHIP_CREATE: "SHIP_CREATE",
  SHIP_UPDATE: "SHIP_UPDATE",
  SHIP_DELETE: "SHIP_DELETE",
  GROUP_SHIP_LIST: "GROUP_SHIP_LIST",
  GROUP_SHIP_DETAIL: "GROUP_SHIP_DETAIL",
  GROUP_SHIP_CREATE: "GROUP_SHIP_CREATE",
  GROUP_SHIP_UPDATE: "GROUP_SHIP_UPDATE",
  GROUP_SHIP_DELETE: "GROUP_SHIP_DELETE",
  PORT_LIST: "PORT_LIST",
  PORT_DETAIL: "PORT_DETAIL",
  PORT_CREATE: "PORT_CREATE",
  PORT_UPDATE: "PORT_UPDATE",
  PORT_DELETE: "PORT_DELETE",
  HARBOR_LIST: "HARBOR_LIST",
  HARBOR_DETAIL: "HARBOR_DETAIL",
  HARBOR_CREATE: "HARBOR_CREATE",
  HARBOR_UPDATE: "HARBOR_UPDATE",
  HARBOR_DELETE: "HARBOR_DELETE",
  USER_LIST: "USER_LIST",
  USER_DETAIL: "USER_DETAIL",
  USER_CREATE: "USER_CREATE",
  USER_UPDATE: "USER_UPDATE",
  USER_DELETE: "USER_DELETE",
  CRAWLING_HISTORY: "CRAWLING_HISTORY",
  USER_ACTIVITY: "USER_ACTIVITY",
  LOCATION_HISTORY: "LOCATION_HISTORY",
  STATISTIC_VIOLATION_SHIP: "STATISTIC_VIOLATION_SHIP",
  STATISTIC_AREA: "STATISTIC_AREA",
  STATISTIC_DAILY_REPORT: "STATISTIC_DAILY_REPORT",
  IMPORT_DATA: "IMPORT_DATA",
  EXPORT_DATA: "EXPORT_DATA",
  STATISTIC_SHIP_AMOUNT: "STATISTIC_SHIP_AMOUNT",
  STATISTIC_SHIP_AMOUNT_IN: "STATISTIC_SHIP_AMOUNT_IN",
  STATISTIC_SHIP_AMOUNT_OUT: "STATISTIC_SHIP_AMOUNT_OUT",
  USER_ACTION_MN_LIST: "USER_ACTION_MN_LIST",
  USER_ACTION_SUMMARY_LIST: "USER_ACTION_SUMMARY_LIST",
  USER_ACTION_MN_EXPORT: "USER_ACTION_MN_EXPORT",
  USER_ACTION_SUMMARY_EXPORT: "USER_ACTION_SUMMARY_EXPORT",
  EVENT_WARNING_LIST: "EVENT_WARNING_LIST",
  EVENT_WARNING_CREATE: "EVENT_WARNING_CREATE",
  EVENT_WARNING_UPDATE: "EVENT_WARNING_UPDATE",
  EVENT_WARNING_NOTIFY: "EVENT_WARNING_NOTIFY",
  EVENT_WARNING_DELETE: "EVENT_WARNING_DELETE",
  NOTIFY_LIST: "NOTIFY_LIST",
  NOTIFY_EXPORT: "NOTIFY_EXPORT",
  FOLDER_CREATE: "FOLDER_CREATE",
  FOLDER_UPDATE: "FOLDER_UPDATE",
  FOLDER_DELETE: "FOLDER_DELETE",
};

export const REPORT_DAY_TYPE = {
  foreignShip: {
    value: "6",
    title: "Tàu nước ngoài",
  },
  marinePoliceShip: {
    value: "7",
    title: "Tàu cảnh sát biển",
  },
  iuuShip: {
    value: "8",
    title: "Tàu vi phạm đánh bắt IUU",
  },
};

export const CAUSE_TYPE = {
  user: 0,
  ship: 1,
};

export const ALL_VALUE = "all";

export const DEFAULT_SHIP_TYPE = {
  name: "Tất cả",
  type: -1,
  uid: ALL_VALUE,
};

export const BACKGROUND_MAP_LIST = [
  {
    title: "Bản đồ mặc định",
    url: CURRENT_SYSTEM_MODE
      ? "https://mt1.google.com/vt/lyrs=m@113&hl=en&&x={x}&y={y}&z={z}&hl=vi"
      : MAP_URL + "/image?type=0&x={x}&y={y}&z={z}",
  },
  {
    title: "Bản đồ vệ tinh",
    url: CURRENT_SYSTEM_MODE
      ? "https://mt1.google.com/vt/lyrs=y&hl=pl&&x={x}&y={y}&z={z}&hl=vi"
      : MAP_URL + "/image?type=1&x={x}&y={y}&z={z}",
  },
  {
    title: "Bản đồ nền tối",
    url: CURRENT_SYSTEM_MODE
      ? "https://api.mapbox.com/styles/v1/mapbox-official/clmowmvem022a01r76lwl43e1/tiles/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibWFwYm94LW9mZmljaWFsIiwiYSI6ImNrcHBnMG5yMDA2eWgyb2s5MDI4NG10OXAifQ.S65o8bcu6oqsp6HQnWYJfw"
      : MAP_URL + "/image?type=2&x={x}&y={y}&z={z}",
  },
  {
    title: "Hải đồ",
    url: CURRENT_SYSTEM_MODE
      ? "https://www.marinetraffic.com/TMS/1.0.0/TX97/{z}/{x}/{y}.png?v=3"
      : MAP_URL + "/image?type=3&x={x}&y={y}&z={z}",
  },
  {
    title: "Đại dương học",
    url: CURRENT_SYSTEM_MODE
      ? "https://ibasemaps-api.arcgis.com/arcgis/rest/services/Ocean/World_Ocean_Base/MapServer/tile/{z}/{y}/{x}?token=AAPK2e2c1b00867a434681d9afe64695bd4fFVGgUOQZGe8I84kGwNOoSAbTZQsw5A5KB1iS-ijh3l1UgL26bHLr4OOtla4VluFg&language=vi"
      : MAP_URL + "/image?type=4&x={x}&y={y}&z={z}",
  },
];
