import { Box } from "@mui/material";
import TableNotificationManagement from "./TableNotificationManagement";

const NotificationEventWarningManagement = () => {
  return (
    <Box style={{ padding: 30 }}>
      <TableNotificationManagement />
    </Box>
  );
};

export default NotificationEventWarningManagement;
