import React, { memo, useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Close, ExpandLess, ExpandMore } from "@mui/icons-material";
import { convertMillisecondsToDateTime, getCreatedTime } from "utils/date.utils";
import { AppConstant, SystemConstant } from "const";
import { convertCoordinates, dragElement } from "utils";

const LayerShipDetailDialog = ({ open, onClose, ship }) => {
  const id = SHIP_DETAIL_DIALOG_ID;

  const classes = useStyles();

  const [isMoreDetailsShow, setIsMoreDetailsShow] = useState(false);

  useEffect(() => {
    if (id) {
      dragElement(id);
    }
  }, [id]);

  useEffect(() => {
    if (!open) {
      setIsMoreDetailsShow(false);
    }
  }, [open]);

  const isFishingShip = ship?.ship_type?.type === SystemConstant.SHIP_TYPE.FISHING;
  const isFishingMonitored = ship?.source_type === AppConstant.SOURCE_DATA.gstc;

  return (
    <Box id={id} className={open ? classes.openDragDropElem : classes.closeDragDropElem} component="section">
      <Box className={classes.cont}>
        <Box className={classes.header} id={id + "header"}>
          <Typography variant="h6">{ship?.ship_name}</Typography>
          <IconButton size="small" onClick={onClose}>
            <Close fontSize="small" htmlColor="white" />
          </IconButton>
        </Box>
        <Box className={classes.body}>
          <div>
            <Typography className={classes.title}>Thông tin chung:</Typography>
            <ul className={classes.list} style={{ marginBottom: 0 }}>
              {ship?.ship_type?.name && <li>- Loại tàu: {ship?.ship_type?.name || "Chưa cập nhật"}</li>}
              <li>- Quốc gia: {ship?.country_code || "Chưa cập nhật"}</li>
              <li>- Tốc độ: {ship?.speed !== null ? `${ship?.speed} knot` : "Không có dữ liệu"}</li>
              <li>- Hướng: {ship?.course !== null ? `${ship?.course} độ` : "Không có dữ liệu"}</li>
              {(ship?.ship_lat || ship?.ship_long) && (
                <li>- Vị trí: {convertCoordinates(Number(ship?.ship_lat), Number(ship?.ship_long))}</li>
              )}
              {ship?.time && (
                <li>
                  - Thời gian cập nhật: {ship?.time && convertMillisecondsToDateTime(ship?.time)}
                  {" (" + getCreatedTime(ship?.time) + ")"}
                </li>
              )}
              {ship?.IMO && !isFishingMonitored && <li>- IMO: {ship?.IMO || "Chưa cập nhật"}</li>}
              {ship?.mmsi_code && !isFishingMonitored && <li>- MMSI: {ship?.mmsi_code || "Chưa cập nhật"}</li>}
              {isFishingMonitored && ship?.license_plate && <li>- Biển số tàu: {ship?.license_plate}</li>}
              {!isFishingMonitored && <li>- Hô hiệu: {ship?.callSign || "Chưa cập nhật"}</li>}
              {isFishingMonitored && <li>- Mã thiết bị AIS/VMS: {ship?.device_code || "Chưa cập nhật"}</li>}
              {isFishingMonitored && <li>- Nhà cung cấp: {ship?.device_type ? ship?.device_type : "Chưa cập nhật"}</li>}
              {isFishingMonitored && ship?.phone_number && (
                <li>- Số điện thoại: {ship?.phone_number || "Chưa cập nhật"}</li>
              )}
              {isFishingMonitored && ship?.satellite_number && (
                <li>- Số điện thoại vệ tinh: {ship?.satellite_number || "Chưa cập nhật"}</li>
              )}
              {isFishingMonitored && ship?.province && <li>- Tỉnh: {ship?.province || "Chưa cập nhật"}</li>}

              <li>- Điểm đến:&nbsp; {ship?.destination ? ship?.destination : "Chưa cập nhật"} </li>
              <li>- E.T.A:&nbsp; {Boolean(ship?.eta) ? convertMillisecondsToDateTime(ship?.eta) : "Chưa cập nhật"} </li>
              {ship?.source_type && (
                <li>- Nguồn dữ liệu: {AppConstant.DATA_SOURCES[ship?.source_type] || "Chưa cập nhật"}</li>
              )}
            </ul>
          </div>

          <div className={isMoreDetailsShow ? classes.openShowMoreDetails : classes.closeShowMoreDetails}>
            <ul className={classes.list}>
              {isFishingMonitored && <li>- Cảng chính: {ship?.main_fishing_port || "Chưa cập nhật"}</li>}
              {isFishingMonitored && <li>- Cảng phụ: {ship?.sub_fishing_port || "Chưa cập nhật"}</li>}
              {isFishingMonitored && ship?.VHF && <li>- VHF: {ship?.VHF || "Chưa cập nhật"}</li>}
              {isFishingMonitored && ship?.HF && <li>- HF: {ship?.HF || "Chưa cập nhật"}</li>}
              {isFishingMonitored && ship?.career && <li>- Ngành nghề: {ship?.career || "Chưa cập nhật"}</li>}
              <li>- Chiều dài: {ship?.length ? ship?.length + " m" : "Chưa cập nhật"}</li>
              <li>- Chiều rộng: {ship?.width ? ship?.width + " m" : "Chưa cập nhật"}</li>
              <li>- Khối lượng tàu: {ship?.weight ? ship?.weight + " tấn" : "Chưa cập nhật"}</li>
              <li>- Mớn nước: {ship?.vessel_draft ? ship?.vessel_draft + " m" : "Chưa cập nhật"}</li>
              <li>- Tải trọng: {ship?.capacity ? ship?.capacity + " tấn" : "Chưa cập nhật"}</li>
              {ship?.GPS_status && <li>- Trạng thái GPS: {ship?.GPS_status || "Chưa cập nhật"}</li>}
              {!isFishingMonitored && <li>- Năm sản xuất: {ship?.yob || "Chưa cập nhật"}</li>}
              {isFishingMonitored && (
                <li>
                  - Ngày sản xuất:&nbsp;
                  {ship?.date_of_manufactor
                    ? convertMillisecondsToDateTime(ship?.date_of_manufactor * 1000)
                    : "Chưa cập nhật"}
                </li>
              )}
              {isFishingMonitored && ship?.expiration_date && (
                <li>
                  - Ngày hết hạn:&nbsp;
                  {ship?.expiration_date && ship?.date_of_manufactor
                    ? convertMillisecondsToDateTime(ship?.expiration_date + ship?.date_of_manufactor * 1000)
                    : "Chưa cập nhật"}
                </li>
              )}
              {isFishingMonitored && Boolean(ship?.fishing_velocity) && (
                <li>
                  - Tốc độ đánh bắt:&nbsp;
                  {ship?.fishing_velocity ? "~" + ship?.fishing_velocity?.toFixed(1) + " knot" : "Chưa cập nhật"}
                </li>
              )}
            </ul>
            {ship?.source_type === 4 && (
              <>
                <Typography className={classes.title}>Thông tin chủ tàu:</Typography>
                <ul className={classes.list}>
                  {ship?.ship_owner?.name !== "Unknown" && (
                    <li>- Họ và tên: {ship?.ship_owner?.name || "Chưa cập nhật"}</li>
                  )}
                  {<li>- Địa chỉ: {ship?.ship_owner?.address || "Chưa cập nhật"}</li>}
                  <li>- Email: {ship?.ship_owner?.email || "Chưa cập nhật"}</li>
                </ul>
              </>
            )}
            {isFishingMonitored && (
              <>
                <Typography className={classes.title}>Thông tin chủ tàu:</Typography>
                <ul className={classes.list}>
                  {ship?.ship_owner?.name && <li>- Họ và tên: {ship?.ship_owner?.name || "Chưa cập nhật"}</li>}
                  {ship?.ship_owner?.address && <li>- Địa chỉ: {ship?.ship_owner?.address || "Chưa cập nhật"}</li>}
                  {ship?.ship_owner?.phone && <li>- Số điện thoại: {ship?.ship_owner?.phone || "Chưa cập nhật"}</li>}
                  {ship?.ship_owner?.fax && <li>- Số fax: {ship?.ship_owner?.fax || "Chưa cập nhật"}</li>}
                  {ship?.ship_owner?.email && <li>- Email: {ship?.ship_owner?.email || "Chưa cập nhật"}</li>}
                  {ship?.ship_owner?.country_code && (
                    <li>- Quốc tịch: {ship?.ship_owner?.country_code || "Chưa cập nhật"}</li>
                  )}
                  {ship?.ship_owner?.citizen_identification && (
                    <li>- CMT/CCCD: {ship?.ship_owner?.citizen_identification || "Chưa cập nhật"}</li>
                  )}
                </ul>
                <Typography className={classes.title}>Thông tin thuyền trưởng:</Typography>
                <ul className={classes.list}>
                  {ship?.ship_captain?.name && <li>- Họ và tên: {ship?.ship_captain?.name || "Chưa cập nhật"}</li>}
                  {ship?.ship_captain?.address && <li>- Địa chỉ: {ship?.ship_captain?.address || "Chưa cập nhật"}</li>}
                  {ship?.ship_captain?.phone && (
                    <li>- Số điện thoại: {ship?.ship_captain?.phone || "Chưa cập nhật"}</li>
                  )}
                  {ship?.ship_captain?.fax && <li>- Số fax: {ship?.ship_captain?.fax || "Chưa cập nhật"}</li>}
                  {ship?.ship_captain?.email && <li>- Email: {ship?.ship_captain?.email || "Chưa cập nhật"}</li>}
                  {ship?.ship_captain?.country_code && (
                    <li>- Quốc tịch: {ship?.ship_captain?.country_code || "Chưa cập nhật"}</li>
                  )}
                  {ship?.ship_captain?.citizen_identification && (
                    <li>- CMT/CCCD: {ship?.ship_captain?.citizen_identification || "Chưa cập nhật"}</li>
                  )}
                </ul>
              </>
            )}
          </div>
        </Box>
      </Box>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Box
          className={classes.btnShowMoreDetails}
          onClick={() => setIsMoreDetailsShow(!isMoreDetailsShow)}
          title={!isMoreDetailsShow ? "Xem thêm thông tin" : "Ẩn bớt thông tin"}
        >
          {!isMoreDetailsShow ? <ExpandMore /> : <ExpandLess />}
        </Box>
      </div>
    </Box>
  );
};

export default memo(LayerShipDetailDialog);

const SHIP_DETAIL_DIALOG_ID = "ship-detail-dialog";

const useStyles = makeStyles(theme => ({
  openDragDropElem: {
    position: "fixed",
    display: "block",
    cursor: "default",
    zIndex: 1000,
    background: "#fff",
    boxSizing: "border-box",
    borderRadius: 4,
    width: "fit-content",
    height: "fit-content",
    overflow: "hidden",
    top: "50%",
    left: "50%",
    boxShadow:
      "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
  },

  closeDragDropElem: {
    display: "none",
  },

  header: {
    cursor: "move",
    padding: "4px 8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "white",
    background: "#1976d2",
  },

  body: {
    height: "max-content",
    overflow: "auto",
    maxHeight: 400,
    padding: "8px 0",
  },

  title: {
    fontWeight: "bold",
    top: 0,
    backgroundColor: "#fff",
    marginLeft: "8px",
  },

  list: {
    marginTop: 0,
    listStyleType: "none",
    padding: "0 10px",
    marginBottom: "10px",
  },

  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    "& > button": {
      minWidth: 50,
    },
  },

  shipPopupButton: {
    margin: 10,
  },

  btnShowMoreDetails: {
    width: "100%",
    height: "48px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    color: "#1976d2",
    background: "linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.2))",
  },

  openShowMoreDetails: {
    display: "block",
  },

  closeShowMoreDetails: {
    display: "none",
  },
}));
