import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Dialog,
  FormControl,
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";
import { useMapContext, useFlickerMarker } from "hooks";
import { convertCoordinates } from "utils";
import { ShipService } from "services";
import { convertMillisecondsToDateTime } from "utils/date.utils";
import ShipDetailDialog from "./ShipDetailDialog";
import { LangConstant, AppConstant } from "const";
import ShipActions from "redux-store/ship.redux";

const FishingShipListDialog = ({ open, onClose, data: dataShips, titleDialog, shipStatus }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({ total: 1, page: 1 });
  const [paginationData, setPaginationData] = useState({});
  const { map, mapState, flickerLayerRef } = useMapContext();
  const filterShipResult = useSelector(state => state.shipRedux.filterShipResult);
  const { createFlickerMarker } = useFlickerMarker(flickerLayerRef);
  const [shipDetails, setShipDetails] = useState([]);
  const [trackingShip, setTrackingShip] = useState({});
  const [isOpenShipDetailDialog, setIsOpenShipDetailDialog] = useState(false);
  const onCloseShipDetailDialog = () => {
    setIsOpenShipDetailDialog(false);
  };
  const onOpenShipDetailDialog = ship => {
    ShipService.trackingShip({ uid: ship.uid }).then(res => {
      setTrackingShip(res?.data?.data);
      setIsOpenShipDetailDialog(true);
    });
  };

  const onCloseDialog = () => {
    onClose();
    onCloseShipDetailDialog();
  };

  useEffect(() => {
    if (dataShips?.length > 0) {
      ShipService.getShips({ ship_uids: paginationData[pagination.page]?.map(ship => ship?.ship_uid) }).then(res => {
        const mergedData = res?.data?.data.map(item => ({
          ...item,
          ...paginationData[pagination.page].find(dataShip => dataShip.ship_uid === item.uid),
        }));
        setShipDetails(mergedData);
      });
      setShipDetails([]);
    }
  }, [paginationData, pagination]);

  useEffect(() => {
    const dataArr = dataShips
      .sort((a, b) => {
        return b.created - a.created;
      })
      .filter(ship => {
        const name = ship.name.toLowerCase() || "";
        const isMatchingSearchValue = name.includes(searchText.toLowerCase() || searchText);
        return isMatchingSearchValue;
      });

    const totalPages = Math.ceil(dataArr.length / PAGE_SIZE);
    const dataPagination = {};

    for (let i = 0; i < totalPages; i++) {
      dataPagination[i + 1] = dataArr.slice(i * PAGE_SIZE, (i + 1) * PAGE_SIZE);
    }
    setPaginationData(dataPagination);
    setPagination(state => ({ ...state, page: 1, total: totalPages }));
  }, [dataShips, searchText]);

  const onChangeSearchText = e => {
    let value = e.target.value;
    setSearchText(value);
  };

  const onSubmit = e => {
    e.preventDefault();
  };

  const onChangePage = (e, value) => {
    setPagination(state => ({ ...state, page: value }));
  };

  const onJumpToShipNotification = (filterShip, data) => {
    onCloseDialog();
    const shipNotification = filterShip.find(ship => ship?.ship_uid === data.ship_uid);
    if (mapState === "loaded" && shipNotification) {
      map.current.setView([shipNotification.ship_lat, shipNotification.ship_long], 12);
      createFlickerMarker(shipNotification);
    }
  };

  const onExportReport = () => {
    dispatch(
      ShipActions.requestGetFilterShipInDataTable({
        bounds: map.current.getBounds(),
        isExport: true,
        shipStatus: shipStatus,
        sourceTypeList: AppConstant.FILTER_SHIP_BY_RESOURCES.filter(
          data => data.name === LangConstant.TXT_SHIP_MONITORING,
        ).map(data => parseInt(data.id)),
      }),
    );
  };

  return (
    <>
      <Dialog open={open} onClose={onCloseDialog} maxWidth="xl" fullWidth>
        <Box className={classes.header}>
          <Typography className="header-title">{titleDialog}</Typography>
          <IconButton size="small" onClick={onCloseDialog}>
            <Close fontSize="small" />
          </IconButton>
        </Box>
        <Box className={classes.body}>
          <form className={classes.formCont} onSubmit={onSubmit}>
            <Box sx={{ display: "contents" }}>
              <FormControl sx={{ width: 400 }}>
                <TextField
                  id="violate-user-text-seach"
                  label="Tìm kiếm"
                  variant="outlined"
                  size="small"
                  placeholder="Tìm kiếm theo tên tàu"
                  className={classes.textfield}
                  onChange={onChangeSearchText}
                  value={searchText}
                />
              </FormControl>

              <Button variant="contained" sx={{ width: 200 }} onClick={onExportReport}>
                {LangConstant.TXT_EXPORT_BY_EXCEL}
              </Button>
            </Box>
          </form>
          <TableContainer sx={{ height: 600 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="center">STT</TableCell>
                  <TableCell align="center">Tên tàu</TableCell>
                  <TableCell align="center">Chủ tàu</TableCell>
                  <TableCell align="center">Thuyền trưởng</TableCell>
                  <TableCell align="center">Vị trí</TableCell>
                  <TableCell align="center">Thiết bị</TableCell>
                  <TableCell align="center">Cập nhật</TableCell>
                </TableRow>
              </TableHead>
              {Object.keys(paginationData).length > 0 ? (
                <TableBody>
                  {shipDetails.map((ship, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{(pagination.page - 1) * PAGE_SIZE + index + 1}</TableCell>
                      <TableCell
                        className={classes.buttonMenu}
                        align="center"
                        onClick={() => onOpenShipDetailDialog(ship)}
                      >
                        {ship?.name}
                      </TableCell>
                      <TableCell align="center">{ship?.ship_owner?.name}</TableCell>
                      <TableCell align="center">{ship?.ship_captain?.name}</TableCell>
                      <TableCell
                        className={classes.buttonMenu}
                        align="center"
                        onClick={() => onJumpToShipNotification(filterShipResult, ship)}
                      >
                        {convertCoordinates(Number(ship?.ship_lat), Number(ship?.ship_long))}
                      </TableCell>{" "}
                      <TableCell align="center">{ship?.device_type}</TableCell>
                      <TableCell align="center">{convertMillisecondsToDateTime(ship?.crawl_time)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={13} style={{ color: "#999" }}>
                      Không có dữ liệu
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
            <Pagination
              style={{ marginTop: 16 }}
              onChange={onChangePage}
              count={pagination.total}
              page={pagination.page}
              variant="outlined"
              color="primary"
            />
          </Box>
        </Box>
      </Dialog>
      <ShipDetailDialog open={isOpenShipDetailDialog} onClose={onCloseShipDetailDialog} ship={trackingShip} />
    </>
  );
};

export default FishingShipListDialog;

const PAGE_SIZE = 10;

const useStyles = makeStyles(theme => ({
  body: {
    padding: 16,
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 16,
    "& > .header-title": {
      textTransform: "uppercase",
      fontSize: 16,
      fontWeight: "bold",
    },
  },

  formCont: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex",
  },

  textfield: {
    "& input::placeholder": {
      fontSize: 14,
    },
  },

  buttonMenu: {
    color: "#1976d2",
    cursor: "pointer",
    fontWeight: "450",
  },
}));
