import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PermissionService } from "services";
import { useLoading, useNotification } from "hooks";

export const usePermissionDetails = () => {
  const { permissionGroupUid } = useParams();

  const notification = useNotification();
  const loading = useLoading();

  const [permission, setPermission] = useState(null);

  const handleGetPermissionDetails = async () => {
    try {
      loading.open();
      const response = await PermissionService.getPermissionDetails(permissionGroupUid);
      loading.close();
      if (response.ok && response.data.data) {
        setPermission(response.data.data);
      } else {
        notification.send("Đã có lỗi xảy ra, vui lòng thử lại sau", "error");
      }
    } catch (error) {
      loading.close();
      console.error(error);
      notification.send("Đã có lỗi xảy ra, vui lòng thử lại sau", "error");
    }
  };

  useEffect(() => {
    handleGetPermissionDetails();
  }, []);

  return { permission, permissionGroupUid };
};
