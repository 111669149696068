import { Share } from "@mui/icons-material";
import { Box, Typography, Button } from "@mui/material";
import { AppConstant, FormatConstant, LangConstant } from "const";
import StringFormat from "string-format";
import { copyToClipboard } from "utils";

const ShareProfile = ({ userInfo }) => {
  const onShareProfileToTrios = () => {
    copyToClipboard(
      StringFormat(FormatConstant.FM_SHARING_PROFILE, {
        name: userInfo?.name || "",
        email: userInfo?.email || "",
        role: AppConstant.ROLE_NAME[userInfo?.power] || "",
        department: userInfo?.department || "",
        phone: userInfo?.phoneNumber || "",
      }),
    );
  };

  return (
    <Box>
      <Typography m={2} variant="h5">
        {userInfo?.name || ""}
      </Typography>
      <Typography mt={2} mb={1}>
        {LangConstant.TXT_SHARE_PROFILE_TO_TRIOS}
      </Typography>
      <Button variant="contained" color="warning" onClick={onShareProfileToTrios}>
        <Share />
        {LangConstant.TXT_SHARE}
      </Button>
    </Box>
  );
};

export default ShareProfile;
