import React, { useState, createContext, useContext } from "react";

// Create a context for the notification queue
const NotificationQueueContext = createContext();

// Create a provider component
export const NotificationQueueProvider = ({ children }) => {
  const [notificationQueue, setNotificationQueue] = useState([]);

  return (
    <NotificationQueueContext.Provider value={{ notificationQueue, setNotificationQueue }}>
      {children}
    </NotificationQueueContext.Provider>
  );
};

// Create a custom hook to use the notification queue
export const useNotificationQueue = () => {
  return useContext(NotificationQueueContext);
};
