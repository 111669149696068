import { ApiConstant } from "const";
import { createApiWithToken, createApiDownloadExcel } from "services/config/api.config";
import StringFormat from "string-format";

export const getEventWarningList = data => createApiWithToken().get(ApiConstant.GET_EVENT_WARNINGS, data);
export const changeNotifyOption = data => createApiWithToken().post(ApiConstant.CHANGE_NOTIFY_OPTION, data);
export const deleteEventWarning = data => createApiWithToken().delete(ApiConstant.GET_EVENT_WARNINGS, data);
export const createEventWarning = data => createApiWithToken().post(ApiConstant.GET_EVENT_WARNINGS, data);
export const updateEventWarning = data => createApiWithToken().put(ApiConstant.GET_EVENT_WARNINGS, data);
export const getEventWarningNotification = data =>
  createApiWithToken().get(ApiConstant.GET_EVENT_WARNING_NOTIFICATIONS, data);
export const exportEventWarningNotification = data =>
  createApiDownloadExcel().get(ApiConstant.GET_EVENT_WARNING_NOTIFICATIONS, data);
export const getEventWarningNotificationDetail = uid =>
  createApiWithToken().get(StringFormat(ApiConstant.GET_EVENT_WARNING_NOTIFICATIONS_DETAIL, { uid }));
export const readAllEventWarningNotification = data =>
  createApiWithToken().get(ApiConstant.READ_ALL_EVENT_WARNING_NOTIFICATIONS, data);
export const deleteEventWarningNotify = data => {
  return createApiWithToken().delete(ApiConstant.GET_EVENT_WARNING_NOTIFICATION + "?uids=" + data);
};
