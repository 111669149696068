import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { EventWarningService } from "services";
import { handleSendNotiInSaga } from "utils";
import { LangConstant } from "const";
const PopupConfirmDelete = props => {
  const { open, onClose, checked, onReset } = props;

  const onClickDelete = () => {
    EventWarningService.deleteEventWarningNotify(checked.join("&uids="))
      .then(res => {
        if (res.status === 200) {
          onReset();
          onClose();
        } else {
          handleSendNotiInSaga(LangConstant.MS_DELETE_ERROR, "error");
        }
      })
      .catch(() => handleSendNotiInSaga(LangConstant.MS_DELETE_ERROR, "error"));
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={onClose}>
      <DialogTitle>Xác nhận xóa</DialogTitle>
      <DialogContent style={{ paddingTop: 10 }}>{`Bạn có muốn xóa ${checked?.length} cảnh báo không?`}</DialogContent>
      <DialogActions>
        <Button onClick={onClickDelete}>Đồng ý</Button>
        <Button onClick={onClose}>Thoát</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupConfirmDelete;
