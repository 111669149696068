import { AuthService } from "services";
import { ApiConstant, AppConstant, LangConstant } from "const";
import { connectTriosMessageResponse, errorVerifyOtp, handleOpenLoading, handlingLogin } from "utils";
import AuthAction from "redux-store/auth.redux";
import Conversation from "redux-store/conversation.redux";
import { call, put } from "redux-saga/effects";
import { handleSendNotiInSaga } from "utils";

export function* loginPage(action) {
  try {
    const { data } = action;

    let response = yield call(AuthService.loginPageSaga, data);
    const dataLogin = response.data?.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        AuthAction.authSet({
          loginType: dataLogin.type,
        }),
      );
      if (dataLogin.type === AppConstant.LOGIN_TYPE.normal) {
        handlingLogin({ ...dataLogin, is_remember: data.is_remember });
        yield put(
          Conversation.conversationSet({
            isLoginSuccess: true,
          }),
        );
      } else {
        yield put(
          AuthAction.authSet({
            loginSuccessValue: { ...dataLogin },
          }),
        );
      }
    } else if (response.status === ApiConstant.STT_UNAUTHORIZED) {
      yield put(
        Conversation.conversationSet({
          errLoginFailure: true,
          isLoginSuccess: false,
        }),
      );
    } else {
      handleOpenLoading(false);
      yield put(
        Conversation.conversationSet({
          isLoginSuccess: false,
        }),
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      Conversation.conversationSet({
        isLoginSuccess: false,
      }),
    );
  }
}

export function* sendOtp(action) {
  try {
    const { data } = action;

    const response = yield call(AuthService.sendOtpSaga, data);
    const messageResponse = response?.data?.message;

    if (response.status === ApiConstant.STT_OK) {
      yield put(
        AuthAction.authSet({
          loginOtpStatus: {
            status: {
              sendOtp: AppConstant.API_STATUS.success,
            },
            message: {
              messageError: AppConstant.OTP_ERROR_TYPE.nothing,
            },
          },
        }),
      );
    } else {
      yield put(
        AuthAction.authSet({
          loginOtpStatus: {
            status: {
              sendOtp: AppConstant.API_STATUS.error,
            },
            message: {
              messageError: errorVerifyOtp(messageResponse),
              turn: 0,
            },
          },
        }),
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      AuthAction.authSet({
        loginOtpStatus: {
          status: {
            sendOtp: AppConstant.API_STATUS.error,
          },
          message: {
            messageError: AppConstant.OTP_ERROR_TYPE.other,
          },
        },
      }),
    );
  }
}

export function* verifyOtp(action) {
  try {
    const { data } = action;

    const response = yield call(AuthService.verifyOtpSaga, data);
    const dataLogin = response?.data?.data;
    const messageResponse = response?.data?.message;
    if (response.status === ApiConstant.STT_OK) {
      handlingLogin({ ...dataLogin, is_remember: data.is_remember });
      yield put(
        AuthAction.authSet({
          loginOtpStatus: {
            status: {
              verifyOtp: AppConstant.API_STATUS.success,
            },
            message: {
              messageError: AppConstant.OTP_ERROR_TYPE.nothing,
              turn: AppConstant.OTP_RETRY_LIMIT,
            },
          },
        }),
      );
      yield put(
        Conversation.conversationSet({
          isLoginSuccess: true,
        }),
      );
    } else {
      yield put(
        AuthAction.authSet({
          loginOtpStatus: {
            status: {
              verifyOtp: AppConstant.API_STATUS.error,
            },
            message: {
              messageError: errorVerifyOtp(messageResponse),
              turn: AppConstant.OTP_RETRY_LIMIT - messageResponse.resend_count,
            },
          },
        }),
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      AuthAction.authSet({
        loginOtpStatus: {
          status: {
            verifyOtp: AppConstant.API_STATUS.error,
          },
          message: {
            messageError: AppConstant.OTP_ERROR_TYPE.other,
          },
        },
      }),
    );
  }
}

export function* loginTrios(action) {
  try {
    const { data } = action;

    let response = yield call(AuthService.loginTriosSaga, data);
    const dataLogin = response?.data?.data;
    const messageResponse = response?.data?.message;

    if (response.status === ApiConstant.STT_OK) {
      handlingLogin({ ...dataLogin, is_remember: data.is_remember });
      yield put(
        AuthAction.authSet({
          connectTriosStatus: {
            status: {
              loginTrios: AppConstant.API_STATUS.success,
              getThirdToken: AppConstant.API_STATUS.nothing,
            },
            message: AppConstant.CONNECT_TRIOS_MESSAGE_TYPE.success,
          },
          loginType: AppConstant.LOGIN_TYPE.normal,
          linkTriosPopup: {
            token: null,
          },
        }),
      );
      yield put(
        Conversation.conversationSet({
          isLoginSuccess: true,
        }),
      );
    } else {
      yield put(
        AuthAction.authSet({
          connectTriosStatus: {
            status: {
              loginTrios: AppConstant.API_STATUS.error,
              getThirdToken: AppConstant.API_STATUS.nothing,
            },
            message: connectTriosMessageResponse(messageResponse),
          },
          loginType: AppConstant.LOGIN_TYPE.normal,
          linkTriosPopup: {
            token: data,
          },
        }),
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      AuthAction.authSet({
        connectTriosStatus: {
          status: {
            loginTrios: AppConstant.API_STATUS.error,
            getThirdToken: AppConstant.API_STATUS.nothing,
          },
          message: AppConstant.CONNECT_TRIOS_MESSAGE_TYPE.other,
        },
        loginType: AppConstant.LOGIN_TYPE.normal,
        loginSuccessValue: null,
      }),
    );
  }
}

export function* linkTriosSubmit(action) {
  try {
    const { data } = action;

    let response = yield call(AuthService.linkTriosSubmitSaga, data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(AuthAction.loginTrios(data.trios_token));
    } else if (response.status === ApiConstant.STT_UNAUTHORIZED) {
      yield put(
        Conversation.conversationSet({
          errLinkTriosFailure: true,
        }),
      );
    } else if (response.status === ApiConstant.STT_BAD_REQUEST) {
      handleSendNotiInSaga(LangConstant.TXT_LINKED_ANOTHER_TRIOS, "error");
    }
  } catch (error) {
    yield put(
      Conversation.conversationSet({
        errLinkTriosFailure: true,
      }),
    );
  }
}

export function* linkTrios(action) {
  try {
    const { data } = action;

    let response = yield call(AuthService.linkTriosSaga, data);
    const messageResponse = response?.data?.message;

    if (response.status === ApiConstant.STT_OK) {
      handlingLogin({ ...data.loginSuccessValue });
      yield put(
        AuthAction.authSet({
          connectTriosStatus: {
            status: {
              linkTrios: AppConstant.API_STATUS.success,
              getThirdToken: AppConstant.API_STATUS.nothing,
            },
            message: AppConstant.CONNECT_TRIOS_MESSAGE_TYPE.success,
          },
          loginType: AppConstant.LOGIN_TYPE.normal,
          loginSuccessValue: null,
        }),
      );
      yield put(
        Conversation.conversationSet({
          isLoginSuccess: true,
        }),
      );
    } else {
      yield put(
        AuthAction.authSet({
          connectTriosStatus: {
            status: {
              linkTrios: AppConstant.API_STATUS.error,
              getThirdToken: AppConstant.API_STATUS.nothing,
            },
            message: connectTriosMessageResponse(messageResponse),
          },
          loginType: AppConstant.LOGIN_TYPE.normal,
          loginSuccessValue: null,
        }),
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      Conversation.conversationSet({
        isLoginSuccess: false,
      }),
    );
    yield put(
      AuthAction.authSet({
        connectTriosStatus: {
          status: {
            linkTrios: AppConstant.API_STATUS.error,
            getThirdToken: AppConstant.API_STATUS.nothing,
          },
          message: AppConstant.CONNECT_TRIOS_MESSAGE_TYPE.other,
        },
        loginType: AppConstant.LOGIN_TYPE.normal,
        loginSuccessValue: null,
      }),
    );
  }
}
