import { call, put } from "redux-saga/effects";
import ReportActions from "redux-store/report.redux";
import CommonActions from "redux-store/common.redux";
import { ApiConstant, AppConstant } from "const";
import { ReportService } from "services";
import { downloadBlob } from "utils";

export function* getReportByType({ data }) {
  try {
    let areaUid = data?.area?.uid || null;
    let type = data.type || AppConstant.STATISTIC_TYPE.area;
    let payload = {
      area_uid: areaUid || null,
      start: data.start || null,
      end: data.end || null,
      country_code: data.countryCode ? (data.countryCode === "all" ? null : data.countryCode) : null,
      ship_type: data.shipType ? (data.shipType === -1 ? null : data.shipType) : null,
      statistic_type: type,
      export_f: data.isExport ? 1 : 0,
    };
    yield put(ReportActions.reportSet({ isFetchingReport: true }));
    yield put(CommonActions.commonSet({ isLoading: true }));

    // Calling API
    let res = yield call(ReportService.getReportByType, payload);
    if (res.status === ApiConstant.STT_OK) {
      if (data.isExport) {
        downloadBlob(res.data, data?.fileName || "bao_cao_tau_vi_pham.xlsx");
      } else {
        if (type === AppConstant.STATISTIC_TYPE.area) {
          yield put(
            ReportActions.reportSet({
              areaReportData: res.data.data,
            }),
          );
        } else if ([AppConstant.USER_TYPE.system.key, AppConstant.USER_TYPE.user.key].includes(type)) {
          yield put(
            ReportActions.reportSet({
              violateReportData: res.data.data,
            }),
          );
        }
      }
    }

    yield put(ReportActions.reportSet({ isFetchingReport: false }));
    yield put(CommonActions.commonSet({ isLoading: false }));
  } catch (error) {
    yield put(ReportActions.reportSet({ isFetchingReport: false }));
    yield put(CommonActions.commonSet({ isLoading: false }));
    console.error(error);
  }
}
