import React, { Fragment, useEffect } from "react";
import { debounce } from "lodash";
import {
  Button,
  Checkbox,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import ConversationAction from "redux-store/conversation.redux";
import { useDispatch, useSelector } from "react-redux";
import { AppConstant, LangConstant } from "const";
import { AddMorePort } from ".";
import PortAction from "redux-store/port.redux";
import { Edit } from "@mui/icons-material";
import PhoneInput from "react-phone-input-2";
import ConfirmDeleteHarbor from "./ConfirmDeleteHabor";
import { hasRoleScopes } from "utils";
import { SCOPES } from "const/app.const";

const HarborTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const paginationObj = useSelector(state => state.conversationRedux.pagination);
  const totalPagePort = useSelector(state => state.conversationRedux.totalPagePort);
  const ports = useSelector(state => state.conversationRedux.getPortManager);
  const isDeleteSuccess = useSelector(state => state.portRedux.isDeleteSuccess);
  const isUpdatePortSuccess = useSelector(state => state.portRedux.isUpdatePortSuccess);
  const createPortSuccsess = useSelector(state => state.conversationRedux.createPortSuccsess);

  const [selectedPortUid, setSelectedPortUid] = useState([]);
  const [pagination, setPagination] = useState(1);
  const [isOpenAddPortDialog, setIsOpenAddPortDialog] = useState(false);
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [showData, setShowData] = useState([]);
  const [editingData, setEditingData] = useState(null);
  const [editForm, setEditForm] = useState({
    name: editingData?.name,
    address: editingData?.address,
    phone: editingData?.phone,
    latitude: editingData?.latitude.toFixed(2),
    longitude: editingData?.longitude.toFixed(2),
    fax: editingData?.fax,
  });

  const onSave = () => {
    dispatch(
      PortAction.updatePort({
        uid: editingData.uid,
        ...editForm,
        latitude: editForm.latitude.toString(),
        longitude: editForm.longitude.toString(),
      }),
    );
  };

  const onChangeEditForm = ({ name, data }) => {
    setEditForm({
      ...editForm,
      [name]: data,
    });
  };

  const onOpenEditDialog = data => {
    setEditForm({
      ...data,
      name: data?.name || "",
      address: data?.address || "",
      phone: data?.phone || "",
      latitude: data?.latitude.toFixed(2) || 0,
      longitude: data?.longitude.toFixed(2) || 0,
      fax: data?.fax || "",
    });
    setEditingData(data);
  };

  const onClickCheckbox = id => {
    setSelectedPortUid(pre => (pre.includes(id) ? pre.filter(item => item !== id) : [...pre, id]));
  };

  const onClickDeleteCheckbox = () => {
    setIsShowConfirmDelete(true);
  };

  const onChangePage = (e, value) => {
    setPagination(value);
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(
        PortAction.portSet({
          isDeleteSuccess: false,
        }),
      );
      setShowData(pre => pre.filter(item => !selectedPortUid.includes(item.uid)));
      setSelectedPortUid([]);
      setIsShowConfirmDelete(false);
    }
  }, [isDeleteSuccess]);

  useEffect(() => {
    dispatch(
      ConversationAction.getPortManager({
        name: searchInput,
        page: pagination,
      }),
    );
  }, [pagination, searchInput, createPortSuccsess]);

  useEffect(() => {
    if (ports) {
      setShowData(ports);
    }
  }, [ports]);

  useEffect(() => {
    if (isUpdatePortSuccess) {
      dispatch(
        PortAction.portSet({
          isUpdatePortSuccess: false,
        }),
      );
      setShowData(ports =>
        [...ports].map(port => {
          if (port.uid === editForm.uid) {
            return editForm;
          } else return port;
        }),
      );
      setEditingData(null);
    }
  }, [isUpdatePortSuccess]);

  return (
    <>
      <Box className={classes.searchInput}>
        <TextField
          onChange={debounce(e => {
            setSearchInput(e.target.value);
          }, 500)}
          fullWidth
          size="small"
          label="Tìm kiếm cảng biển"
          variant="outlined"
        />
      </Box>
      <Box className={classes.paddingButton}>
        <Box className={classes.buttonPaddingadd}>
          {hasRoleScopes(SCOPES.PORT_CREATE) && (
            <Button onClick={() => setIsOpenAddPortDialog(true)} color="warning" variant="contained">
              {LangConstant.TXT_MORE_PORT}
            </Button>
          )}
        </Box>
        {hasRoleScopes(SCOPES.PORT_DELETE) && (
          <Button
            onClick={onClickDeleteCheckbox}
            disabled={Boolean(selectedPortUid.length > 0 ? "" : "disabled")}
            variant="contained"
            color="warning"
          >
            {LangConstant.TXT_BUTTON_DELETE}
          </Button>
        )}
      </Box>
      <TableContainer style={{ height: 700 }} component={Paper}>
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="50px"></TableCell>
              <TableCell width="50px">STT</TableCell>
              <TableCell align="center">Tên</TableCell>
              <TableCell align="center">Địa chỉ</TableCell>
              <TableCell align="center">Mã quốc gia</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">SĐT</TableCell>
              <TableCell align="center">Số FAX</TableCell>
              <TableCell align="center">Kinh độ</TableCell>
              <TableCell align="center">Vĩ độ</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {showData &&
              showData.length > 0 &&
              showData.map((row, index) => {
                return (
                  <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell>
                      <Checkbox
                        checked={selectedPortUid.includes(row.uid)}
                        onClick={() => {
                          onClickCheckbox(row.uid);
                        }}
                        color="warning"
                      />
                    </TableCell>
                    <TableCell align="center">{(pagination - 1) * paginationObj + index + 1}</TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.address}</TableCell>
                    <TableCell align="center">{row.country_code}</TableCell>
                    <TableCell align="center">{row.email}</TableCell>
                    <TableCell align="center">{row.phoneNumber}</TableCell>
                    <TableCell align="center">{row.fax}</TableCell>
                    <TableCell align="center">{Number(row?.latitude)?.toFixed(2)}</TableCell>
                    <TableCell align="center">{Number(row?.longitude)?.toFixed(2)}</TableCell>
                    <TableCell>
                      <Fragment>
                        {hasRoleScopes(SCOPES.PORT_DETAIL) && (
                          <IconButton
                            onClick={() => {
                              onOpenEditDialog(row);
                            }}
                          >
                            <Edit />
                          </IconButton>
                        )}
                      </Fragment>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box className={classes.panigationPadding}>
        <Pagination
          onChange={onChangePage}
          sx={{ paddingTop: 2 }}
          count={totalPagePort}
          variant="outlined"
          page={pagination}
        />
      </Box>

      <AddMorePort open={isOpenAddPortDialog} onClose={() => setIsOpenAddPortDialog(false)} />
      <ConfirmDeleteHarbor
        data={selectedPortUid}
        open={isShowConfirmDelete}
        onClose={() => setIsShowConfirmDelete(false)}
        lastItem={showData.find(item => item.id === selectedPortUid[selectedPortUid.length - 1])}
      ></ConfirmDeleteHarbor>

      <Dialog
        open={Boolean(editingData)}
        onClose={() => setEditingData(null)}
        classes={{
          paper: classes.editPaper,
        }}
        maxWidth="md"
      >
        <Typography className={classes.editTitle}>
          Sửa thông tin bến cảng "<b>{editingData?.name}</b>"
        </Typography>
        <FormControl size="small" fullWidth>
          <InputLabel id="demo-simple-select-label">Tên bến cảng</InputLabel>
          <OutlinedInput
            id="demo-simple-select"
            defaultValue={editForm.name}
            onChange={e =>
              onChangeEditForm({
                name: "name",
                data: e.target.value,
              })
            }
            label="Tên bến cảng"
          />
        </FormControl>
        <FormControl size="small" fullWidth>
          <InputLabel id="demo-simple-select-label">Địa chỉ</InputLabel>
          <OutlinedInput
            defaultValue={editForm.address}
            id="demo-simple-select"
            name="address"
            onChange={e =>
              onChangeEditForm({
                name: "address",
                data: e.target.value,
              })
            }
            label="Địa chỉ"
          />
        </FormControl>
        {/* <FormControl size="small" fullWidth>
          <InputLabel id="demo-simple-select-label">Đơn vị quản lý</InputLabel>
          <OutlinedInput
            id="demo-simple-select"
            onChange={e =>
              onChangeEditForm({
                name: "managementDepartment",
                data: e.target.value,
              })
            }
            label="Quyền người dùng"
          />
        </FormControl> */}
        <PhoneInput
          value={editForm.phone}
          onChange={phone =>
            onChangeEditForm({
              name: "phone",
              data: phone,
            })
          }
          country="vn"
          onlyCountries={AppConstant.COUNTRY}
        />
        <FormControl size="small" fullWidth>
          <InputLabel id="demo-simple-select-label">Kinh độ</InputLabel>
          <OutlinedInput
            id="demo-simple-select"
            defaultValue={editForm.latitude}
            onChange={e =>
              onChangeEditForm({
                name: "latitude",
                data: Number(e.target.value),
              })
            }
            label="Kinh độ"
          />
        </FormControl>
        <FormControl size="small" fullWidth>
          <InputLabel id="demo-simple-select-label">Vĩ độ</InputLabel>
          <OutlinedInput
            id="demo-simple-select"
            defaultValue={editForm.longitude}
            onChange={e =>
              onChangeEditForm({
                name: "longitude",
                data: Number(e.target.value),
              })
            }
            label="Vĩ độ"
          />
        </FormControl>
        <FormControl size="small" fullWidth>
          <InputLabel id="demo-simple-select-label">FAX</InputLabel>
          <OutlinedInput
            id="demo-simple-select"
            defaultValue={editForm.fax}
            onChange={e =>
              onChangeEditForm({
                name: "fax",
                data: e.target.value,
              })
            }
            label="Fax"
          />
        </FormControl>
        <Box className={classes.editAction}>
          <Button onClick={() => setEditingData(null)}>Hủy</Button>
          <Button variant="contained" onClick={onSave}>
            Lưu
          </Button>
        </Box>
      </Dialog>
    </>
  );
};
export default HarborTable;

const useStyles = makeStyles(theme => ({
  searchInput: {
    margin: "auto",
    width: 400,
  },

  paddingButton: {
    paddingBottom: 20,
    display: "flex",
  },

  buttonPaddingadd: {
    display: "flex",
    paddingRight: 10,
  },

  panigationPadding: {
    padding: "10px 0",
  },

  paddingPort: {
    paddingLeft: 10,
  },

  confirmDeletePaper: {
    padding: 15,
  },

  deleteMessage: {
    fontSize: 17,
    fontWeight: 450,
  },

  actionContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 10,
    "&>button": {
      textTransform: "none",
    },
  },

  editPaper: {
    padding: 40,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "&>*": {
      "&:not(:last-child)": {
        marginBottom: 20,
      },
    },
  },

  editTitle: {
    fontSize: 15,
  },

  editAction: {
    display: "flex",
    justifyContent: "flex-end",
    "&>*": {
      textTransform: "none",
      fontSize: 15,
      fontWeight: 600,
      marginLeft: 10,
    },
  },
}));
