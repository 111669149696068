import { useRef } from "react";
import L from "leaflet";
import { convert2Coords } from "utils";
import { convertMillisecondsToDateTime } from "utils/date.utils";
import { useMapContext } from "./useMapContext";
import { AppConstant } from "const";

export const useTrackingLayer = () => {
  const { trackingLayer, map } = useMapContext();

  const trackingShips = useRef({});

  const createIcon = (course, color) => {
    const html = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" style="width: auto; height: 24px; transform: rotate(${course}deg); opacity: 0.5;">
    <g id="Arrow_GPS" data-name="Arrow GPS">
                      <path d="M26.9336,28.6411l-10-26a1,1,0,0,0-1.8672,0l-10,26A1,1,0,0,0,6.6,29.8L16,22.75l9.4,7.05a1,1,0,0,0,1.5332-1.1587Z" style="fill: ${color}; stroke: black"/>
                    </g>
                  </svg>`;

    return new L.DivIcon({
      html: html,
      className: "leaflet-tracking-div-icon",
    });
  };

  const createMarker = (lat, lng, icon, info) => {
    const marker = L.marker([lat, lng], { icon: icon });
    const coords = convert2Coords(info.location.lat, info.location.long);
    marker.bindPopup(
      [
        "Tên tàu: " + info.ship_name,
        "Thời gian: " + convertMillisecondsToDateTime(info.location.time),
        "Tọa độ: " + coords.lat + " - " + coords.lng,
        "Vận tốc: " + info.location.speed + " knot",
        "Hướng: " + info.angle + "°",
      ].join("<br/>"),
      {
        closeButton: true,
        closeOnEscapeKey: false,
        autoClose: false,
        closeOnClick: false,
      },
    );
    marker.custom_angle = info.angle;
    return marker;
  };

  const show = (ships = []) => {
    ships.forEach(ship => {
      trackingShips.current[ship.ship_uid] = { polylines: [], markers: {}, color: ship.color, lastMarker: null };
    });

    ships.forEach(ship => {
      const locations = ship.locations.sort((prev, next) => prev.time - next.time);
      const splitLocations = locations.reduce((result, location, index, array) => {
        if (index === 0) {
          result.push([location]);
        } else {
          const prevLocation = array[index - 1];
          const timeDiff = location.time - prevLocation.time;

          if (timeDiff > 6 * AppConstant.ONE_HOUR) {
            result.push([location]);
          } else {
            result[result.length - 1].push(location);
          }
        }

        return result;
      }, []);
      let lastTime = null;

      const filterMarkersLocations = locations.filter(marker => {
        if (lastTime === null) {
          lastTime = marker.time;
          return true;
        }

        const thirtyMinutesInMilliseconds = 30 * 60 * 1000;
        if (marker.time - lastTime >= thirtyMinutesInMilliseconds) {
          lastTime = marker.time;
          return true;
        }

        return false;
      });
      filterMarkersLocations.forEach((location, index) => {
        const course = location.course;
        const info = { ...ship, location, angle: course };
        const marker = createMarker(location.lat, location.long, createIcon(course, ship.color), info);
        trackingShips.current[ship.ship_uid]["markers"][index] = marker;
        trackingLayer.current.addLayer(marker);

        if (index === filterMarkersLocations.length - 1) {
          trackingShips.current[ship.ship_uid]["lastMarker"] = marker;
        }
      });

      splitLocations.forEach((locationsArr, index) => {
        const polyline = L.polyline(
          locationsArr.map(location => L.latLng(location.lat, location.long)),
          {
            color: ship.color,
          },
        );
        trackingLayer.current.addLayer(polyline);
        trackingShips.current[ship.ship_uid]["polylines"].push(polyline);
        if (index !== splitLocations.length - 1) {
          const lastLocation = locationsArr[locationsArr.length - 1];
          const nextLocation = splitLocations[index + 1][0];
          const arrowHead = L.polyline(
            [L.latLng(lastLocation.lat, lastLocation.long), L.latLng(nextLocation.lat, nextLocation.long)],
            {
              color: ship.color,
              weight: 2,
              dashArray: [4, 4],
              arrowHead: 10,
            },
          );
          trackingLayer.current.addLayer(arrowHead);
          trackingShips.current[ship.ship_uid]["polylines"].push(arrowHead);
        }
      });
    });
  };

  const clear = () => {
    trackingShips.current = {};
    trackingLayer.current.clearLayers();
  };

  const closeAllPopups = () => {
    Object.values(trackingShips.current).forEach(ship => {
      Object.values(ship.markers).forEach(marker => {
        marker.closePopup();
      });
    });
  };

  const changeColor = (uid, color) => {
    Object.values(trackingShips.current[uid]["markers"]).forEach(marker => {
      marker.setIcon(createIcon(marker.custom_angle, color));
    });
    const currentLastMarker = trackingShips.current[uid]["lastMarker"];
    trackingShips.current[uid]["lastMarker"] = currentLastMarker.setIcon(
      createIcon(currentLastMarker.custom_angle, color),
    );
    trackingShips.current[uid].polylines.forEach(polyline => polyline.setStyle({ color: color }));
    trackingShips.current[uid].polylines.forEach(polyline => polyline.redraw());
  };

  const hidden = () => {
    map.current.removeLayer(trackingLayer.current);
  };

  const visibility = () => {
    map.current.addLayer(trackingLayer.current);
  };

  return { show, clear, closeAllPopups, changeColor, hidden, visibility, trackingShips };
};
