import { EventBus } from "EventBus";
import { EventConstant } from "const";

export const useLoading = () => {
  const open = () => {
    EventBus.emit(EventConstant.GLOBAL_EVENTS.openLoading, true);
  };

  const close = () => {
    EventBus.emit(EventConstant.GLOBAL_EVENTS.openLoading, false);
  };

  return { open, close };
};
