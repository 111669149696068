// API Configs
export const API_BASE_URL = window.env?.BASE_URL || process.env?.REACT_APP_BASE_URL || "";
export const API_TIMEOUT = 300000;
export const API_HEADER_DEFAULT = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const API_HEADER_TEXT = {
  "Content-Type": "text/plain",
};

// Socket
export const WEB_SOCKET_URL =
  window.env?.WEB_SOCKET_URL ||
  process.env?.REACT_APP_WEB_SOCKET_URL ||
  `${API_BASE_URL.endsWith("/") ? API_BASE_URL : API_BASE_URL + "/"}ws`;

// HTTP Status Code
export const STT_OK = 200;
export const STT_BAD_REQUEST = 400;
export const STT_UNAUTHORIZED = 401;
export const STT_FORBIDDEN = 403;
export const STT_NOT_FOUND = 404;
export const STT_INTERNAL_SERVER = 500;
export const STT_DUPLICATE_MESSAGE = 700;
export const STT_MAINTAIN = 503;

// Auth API Endpoints
export const LOGIN_PAGE = "auth/login";
export const CREATE_USER_LOGIN = "auth/signup";
export const SEND_OTP = "auth/otp/send";
export const VERIFY_OTP = "auth/otp/verify";
export const LOGIN_TRIOS = "auth/login_trios";
export const LINK_TRIOS = "auth/link_trios";
export const REFRESH_TOKEN = "auth/refresh-token";
export const LOGOUT = "auth/logout";

// User API Endpoints
export const USER_API = "api/user";
export const DELETE_USER = "user";
export const CHANGE_PASSWORD = "user/change-password";
export const USER = "user/{0}";
export const GET_USER_LOGIN = "user/list";
export const MODULE = "user/me"; // MODULE la cai qq gi?
export const RESET_PASSWORD = "user/reset-password/{0}";
export const PERMISSION = "user/permission?role={0}";
export const GET_HISTORY = "user-activity";
export const EXPORT_USERS_INFO = "/user/information/export";
export const CHANGE_AVATAR = "/user/avatar";
export const CHANGE_TWO_FA = "/auth/otp";
export const GET_FILTER_USERS = "user/list";

// Ship API Endpoints
export const CREATE_SHIP = "ship";
export const GET_LIST_SHIP = "ship";
export const DETELE_LIST_SHIP = "ship";
export const GET_SHIPS = "ship";
export const GET_TYPE_SHIP = "ship_type";
export const UPDATE_SHIP = "ship/{0}";
export const CREATE_GROUP_SHIP = "ship/group";
export const GET_GROUP_SHIP = "ship/group";
export const DELETE_GROUP_SHIP = "ship/group";
export const GET_GROUP_DETAILS_SHIP = "ship/{0}";
export const TRACKING_SHIP = "ship/{0}";
export const UPDATE_SHIP_GROUP = "ship/group/{0}";
export const GET_IMPORT_GROUP_DETAIL = "ship/group/{0}";
export const DELETE_GROUP_DETAIL_SHIP = "ship/group/{0}/{1}";
export const GET_SHIP_TYPES = "ship_type";
export const GET_SHIP_IN_AREA = "/ship/tracking/{0}"; // Area UID
export const CRAWL_SHIP_TO_UPDATE = "ship/crawl_ship_update/{0}";
export const GET_DATA_HISTORY = "ship/data-history"; // Replace with correct api path later when api is up and running
export const GET_ALL_TRACKING_SHIPS = "ship/tracking/all";
export const GET_SHIP_AROUND = "ship/around";

// Port API Endpoints
export const CREATE_MORE_PORE = "port";
export const GET_PORT_MANAGER = "port";
export const DELETE_PORTS = "port";
export const UPDATE_PORTS = "port/{0}";
export const GET_ALL_PORTS = "port/all";
export const GET_HARBOR = "port/harbor";
export const CREATE_HARBOR_SHIP = "port/harbor";
export const DELETER_HARBOR_SHIP = "port/harbor";
export const UPDATE_HARBOR_SHIP = "port/harbor/{0}";

// Attactment API Endpoints
export const UPLOAD_FILE = "attachment";
export const EXPORT_FILE = "attachment/{0}";
export const GET_ATTACHMENT = "attachment/{0}";
export const EXPORT_FILE_ALL = "attachment/export";
export const IMPORT_FILE = "attachment/import?uid={0}";
export const GET_AVATAR = "attachment/avatar/{0}";

// Area API Endpoints
export const GET_AREA = "area";
export const GET_TYPE_AREA = "area";
export const CREATE_CUSTOM_AREA = "area";
export const UPDATE_DELETE_AREA = "area/{0}";
export const GET_WEATHER_DATA = "area/weather";
export const GET_WEATHER_DATA_DETAIL = "area/weather/detail";

// Location API Endpoints
export const GET_LOCATION = "location/clustered";
export const GET_LOCATION2 = "location/clustered";
export const TRACKING_SHIPS = "location/list";
export const GET_FILTER_SHIP_IN_DATA_TABLE = "location/export_excel_ship_detail";

// Statistic API Endpoints
export const GET_STATISTICS = "statistic";
export const UPDATE_STATISTICS = "statistic/ship/{0}";
export const DELETE_STATISTICS = "statistic/ship/{0}";
export const ADD_VIOLATION_SHIP = "statistic/ship/add/{0}";
export const DELETE_VIOLATION_SHIP = "statistic/ship/{0}";
export const EDIT_VIOLATION_SHIP = "statistic/ship/{0}";
export const GET_LIST_SHIP_LOST = "statistic/lost_connection";
export const REMOVE_LIST_SHIP_LOST = "statistic/lost_connection/remove_ship";
export const GET_LIST_SHIP_BY_DAY = "statistic/daily_report";
export const EXPORT_LIST_SHIP_BY_DAY = "statistic/daily_report/export";

// Create third party token
export const CREATE_TOKEN_LINK_TRIOS = "trios://?service_id=2&callbackUrl=gst-desktop://?type=2";
export const CREATE_TOKEN_LOGIN_TRIOS = "trios://?service_id=2&callbackUrl=gst-desktop://?type=3";

// UserAction API Endpoints
export const GET_USER_ACTION_LIST_BY_USER = "user_action/user/{0}";
export const GET_USER_ACTION_LIST_ALL_USER = "user_action/list";
export const GET_USER_ACTION_SUMMARY = "user_action/summary";
export const GET_USER_ACTION_TOTAL = "user_action/total";

// Event warning API Endpoints
export const GET_EVENT_WARNINGS = "event_warning";
export const CHANGE_NOTIFY_OPTION = "event_warning/register";
export const GET_EVENT_WARNING_NOTIFICATION = "event_warning/notify";
export const GET_EVENT_WARNING_NOTIFICATIONS = "event_warning/notify/list";
export const GET_EVENT_WARNING_NOTIFICATIONS_DETAIL = "event_warning/notify/{uid}";
export const READ_ALL_EVENT_WARNING_NOTIFICATIONS = "/event_warning/notify/read-all";

// Folder Report API Endpoints
export const GET_FOLDER = "folder";
export const DELETE_FOLDER = "folder/{uid}";
export const GET_DETAIL_FOLDER = "folder/{uid}";
export const FOLDER_UPLOAD_FILE = "folder/upload-file";
export const GET_FOLDER_NOTIFICATIONS = "folder/notify";
export const GET_FOLDER_NOTIFICATIONS_DETAIL = "folder/notify/{uid}";
export const READ_ALL_FOLDER_NOTIFICATIONS = "/folder/notify/read-all";

// Permission API Endpoints
export const GET_PERMISSION_GROUP = "permission-group";
export const DELETE_PERMISSION_GROUP = "permission-group/{uid}";
export const POST_CREATE_PERMISSION_GROUP = "permission-group";
export const GET_PERMISSION_DETAILS = "permission-group/{uid}";
export const PUT_UPDATE_PERMISSION = "permission-group/{uid}";
