import * as React from "react";
import { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  Pagination,
} from "@mui/material";
import { Edit, Delete, NotificationsActive, NotificationsOff } from "@mui/icons-material";
import EventWarningAction from "redux-store/eventwarning.redux";
import ShipActions from "redux-store/ship.redux";
import AreaActions from "redux-store/area.redux";
import { convertMillisecondsToDateTime } from "utils/date.utils";
import { LangConstant, AppConstant } from "const";
import Cookies from "js-cookie";
import { debounce } from "lodash";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import { hasRoleScopes } from "utils";
import ConfirmChangeNotifyDialog from "./ConfirmChangeNotifyDialog";

const TableEventWarningManagement = () => {
  const dispatch = useDispatch();

  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenChangeNotify, setIsOpenChangeNotify] = useState(false);
  const [dataToDelete, setDataToDelete] = useState(null);
  const [dataToChangeNotify, setDataToChangeNotify] = useState(null);

  const currentUserId = Cookies.get(AppConstant.KEY_USER_ID);
  const pageSize = 20;
  const listEventWarning = useSelector(state => state.eventWarningRedux.listEventWarning);
  const isReGetEventWarnings = useSelector(state => state.eventWarningRedux.isReGetEventWarnings);
  const totalPage = useSelector(state => state.eventWarningRedux.totalPageEventWarning);
  const totalSize = useSelector(state => state.eventWarningRedux.totalElementEventWarning);

  const onChangePage = (e, value) => {
    setPage(value);
  };

  const onOpenCreate = () => {
    dispatch(
      EventWarningAction.eventWarningSet({
        isOpenCreateEventWarning: true,
      }),
    );
    dispatch(AreaActions.areaSet({ selectedAreaList: [] }));
    dispatch(
      ShipActions.shipSet({
        shipConditions: {
          ship_type_list: [],
          group_ids: [],
          country_code_list: [],
        },
      }),
    );
  };

  const onOpenEdit = data => {
    dispatch(
      EventWarningAction.eventWarningSet({
        isOpenEditEventWarning: true,
        dataEventWarning: data,
      }),
    );
    dispatch(AreaActions.areaSet({ selectedAreaList: data.area_list }));
    dispatch(
      ShipActions.shipSet({
        shipConditions: {
          ship_type_list: data.ship_type_list,
          country_code_list: data.country_code_list === null ? [] : data.country_code_list,
          group_ids: data.group_list.map(g => g.uid),
          capacity_min: data.min_weight === null ? AppConstant.SHIP_CAPACITY.min : data.min_weight,
          capacity_max: data.max_weight === null ? AppConstant.SHIP_CAPACITY.max : data.max_weight,
        },
      }),
    );
  };

  useEffect(() => {
    dispatch(
      EventWarningAction.getEventWarnings({
        page: page - 1,
        size: pageSize,
        filter: searchInput,
      }),
    );
  }, [page, isReGetEventWarnings, searchInput]);

  return (
    <>
      <Box sx={{ fontWeight: "bold", fontSize: "20px", color: "cornflowerblue" }}>
        Danh sách cấu hình cảnh báo: {totalSize}
      </Box>

      <Box sx={{ marginTop: 2, display: "flex", justifyContent: "space-between" }}>
        <TextField
          sx={{ width: 500 }}
          onChange={debounce(e => {
            setSearchInput(e.target.value);
          }, 500)}
          fullWidth
          size="small"
          id="search_box_event_warning"
          label={LangConstant.L_SEARCH}
          variant="outlined"
        />
        {hasRoleScopes(AppConstant.SCOPES.EVENT_WARNING_CREATE) && (
          <Button variant="contained" onClick={() => onOpenCreate()}>
            {LangConstant.L_BTN_ADD_NEW}
          </Button>
        )}
      </Box>

      <TableContainer style={{ height: 700 }}>
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="40px">{LangConstant.TXT_STT}</TableCell>
              <TableCell align="center">{LangConstant.TXT_USER_CREATE}</TableCell>
              <TableCell align="center">{LangConstant.TXT_TIME_CREATE}</TableCell>
              <TableCell align="center">{LangConstant.TXT_STATUS}</TableCell>
              <TableCell align="center">{LangConstant.TXT_EVENT_WARNING_NAME}</TableCell>
              <TableCell align="center">{LangConstant.TXT_MANIPULATION}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {listEventWarning &&
              listEventWarning.map((data, index) => {
                return (
                  <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} key={data.uid} id={data.uid}>
                    <TableCell>{(page - 1) * pageSize + index + 1}</TableCell>
                    <TableCell align="center">{data.owner.name}</TableCell>
                    <TableCell align="center"> {convertMillisecondsToDateTime(data.created)} </TableCell>
                    {data.receive_notification_f ? (
                      <TableCell align="center" sx={{ color: "green", minWidth: "100px" }}>
                        {AppConstant.EVENT_WARNING.USING_STATUS}
                      </TableCell>
                    ) : (
                      <TableCell align="center" sx={{ color: "red", minWidth: "100px" }}>
                        {AppConstant.EVENT_WARNING.NOT_USE_STATUS}
                      </TableCell>
                    )}
                    <TableCell align="center">{data.name}</TableCell>
                    <TableCell align="center">
                      <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                        <Fragment>
                          <IconButton
                            disabled={
                              (data.owner.uid && currentUserId !== data.owner.uid) ||
                              !hasRoleScopes(AppConstant.SCOPES.EVENT_WARNING_UPDATE)
                            }
                            title={"Chỉnh sửa"}
                            onClick={() => onOpenEdit(data)}
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            disabled={
                              (data.owner.uid && currentUserId !== data.owner.uid) ||
                              !hasRoleScopes(AppConstant.SCOPES.EVENT_WARNING_DELETE)
                            }
                            title={"Xoá cấu hình"}
                            onClick={() => {
                              setIsOpenDelete(true);
                              setDataToDelete(data);
                            }}
                          >
                            <Delete />
                          </IconButton>
                          {data.receive_notification_f ? (
                            <IconButton
                              disabled={!hasRoleScopes(AppConstant.SCOPES.EVENT_WARNING_NOTIFY)}
                              sx={{ color: "cornflowerblue" }}
                              title={"Tắt thông báo"}
                              onClick={() => {
                                setIsOpenChangeNotify(true);
                                setDataToChangeNotify(data);
                              }}
                            >
                              <NotificationsOff />
                            </IconButton>
                          ) : (
                            <IconButton
                              disabled={!hasRoleScopes(AppConstant.SCOPES.EVENT_WARNING_NOTIFY)}
                              title={"Nhận thông báo"}
                              onClick={() => {
                                setIsOpenChangeNotify(true);
                                setDataToChangeNotify(data);
                              }}
                            >
                              <NotificationsActive />
                            </IconButton>
                          )}
                        </Fragment>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ padding: "10px" }}>
        <Pagination onChange={onChangePage} sx={{ paddingTop: 2 }} count={totalPage} variant="outlined" page={page} />
      </Box>

      <ConfirmDeleteDialog data={dataToDelete} open={isOpenDelete} onClose={() => setIsOpenDelete(false)} />
      <ConfirmChangeNotifyDialog
        data={dataToChangeNotify}
        open={isOpenChangeNotify}
        onClose={() => setIsOpenChangeNotify(false)}
      />
    </>
  );
};

export default TableEventWarningManagement;
