import React, { memo } from "react";
import { makeStyles } from "@mui/styles";
import { convertMillisecondsToDateTime } from "utils/date.utils";
import { useMapContext } from "hooks";
import { AppConstant } from "const";
import { getPositionOfPopup } from "utils";

const LayerShipInfoTooltip = () => {
  const { hoverShip, showMenuLayer } = useMapContext();

  const classes = useStyles();

  const { top, left, bottom, right } = hoverShip.event
    ? getPositionOfPopup(hoverShip.event)
    : { top: 0, left: 0, bottom: 0, right: 0 };
  return (
    <section
      className={
        Boolean(hoverShip.info) && !(hoverShip?.info?.ship_uid === showMenuLayer?.info?.ship_uid)
          ? classes.shipPopUpHover
          : classes.shipPopUpHoverClose
      }
      style={{ top: top, left: left, bottom: bottom, right: right }}
    >
      <p className={classes.shipPopUpHoverContent} style={{ marginBottom: "4px" }}>
        <strong>
          {hoverShip?.info?.name} [{hoverShip?.info?.country_code}]{" - "}
          {AppConstant.DATA_SOURCES[hoverShip?.info?.source_type]}
        </strong>
      </p>
      {hoverShip?.info?.speed ? (
        <>
          <p className={classes.shipPopUpHoverContent}>
            - Hướng: <strong> {hoverShip?.info?.course} độ</strong>
          </p>
          <p className={classes.shipPopUpHoverContent}>
            - Tốc độ: <strong>{hoverShip?.info?.speed} knot</strong>
          </p>
        </>
      ) : (
        <p className={classes.shipPopUpHoverContent}>
          - Tốc độ: <strong>Tạm dừng di chuyển</strong>
        </p>
      )}
      <p className={classes.shipPopUpHoverContent}>
        - Thời gian cập nhật: <strong>{convertMillisecondsToDateTime(hoverShip?.info?.crawl_time)}</strong>
      </p>
    </section>
  );
};

export default memo(LayerShipInfoTooltip);

const useStyles = makeStyles(() => ({
  shipPopUpHover: {
    width: "fit-content",
    height: "fit-content",
    backgroundColor: "rgba(255, 255, 255, 0.95)",
    position: "fixed",
    display: "block",
    cursor: "default",
    zIndex: 1000,
    borderRadius: 2,
    boxSizing: "border-box",
    fontFamily: "Roboto Condensed",
    padding: 5,
    lineHeight: 0.7,
  },

  shipPopUpHoverClose: {
    display: "none",
  },

  shipPopUpHoverContent: {
    fontFamily: "Arial",
    lineHeight: 1.3,
    margin: 0,
    fontSize: "13.5px",
  },
}));
