import React from "react";
import { Dialog, DialogTitle, DialogActions, Box, IconButton, Typography, Button } from "@mui/material";
import { Close } from "@mui/icons-material";

const CancelConfirmDialog = ({ open, onAccept, onDeny }) => {
  const handleAccept = () => {
    if (onAccept) onAccept();
  };

  const handleDeny = () => {
    if (onDeny) onDeny();
  };

  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogTitle component="div" sx={classes.header}>
        <Box component="h4" margin={0}>
          Thông báo
        </Box>
        <IconButton size="small" onClick={handleDeny}>
          <Close fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogActions>
        <Typography sx={classes.msg}>Dữ liệu thêm mới chưa được lưu. Bạn có muốn tiếp tục thêm mới dữ liệu?</Typography>
      </DialogActions>
      <DialogActions sx={classes.footer}>
        <Button variant="contained" color="primary" fullWidth onClick={handleAccept}>
          Có
        </Button>
        <Button variant="contained" color="error" fullWidth onClick={handleDeny}>
          Không
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelConfirmDialog;

const classes = {
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  msg: {
    width: "100%",
    textAlign: "left",
    paddingBottom: "16px",
  },

  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "8px",
  },
};
