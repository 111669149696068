import { put } from "redux-saga/effects";
import ErrorAction from "redux-store/error.redux";

export function* raiseError(action) {
  try {
    let { errors } = action.data;
    yield put(
      ErrorAction.errorSet({
        errors,
        ...errors,
      }),
    );
  } catch (error) {
    console.error(error);
  }
}
