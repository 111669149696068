import { useCallback } from "react";
import L from "leaflet";
import { convert2Coords } from "utils";
import { convertMillisecondsToDateTime } from "utils/date.utils";

export function useTrackkingPointMarker(trackkingPointLayerRef) {

  const createIcon = (course, color) => {
    const html = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" style="width: auto; height: 24px; transform: rotate(${course}deg); opacity: 0.5;">
    <g id="Arrow_GPS" data-name="Arrow GPS">
                      <path d="M26.9336,28.6411l-10-26a1,1,0,0,0-1.8672,0l-10,26A1,1,0,0,0,6.6,29.8L16,22.75l9.4,7.05a1,1,0,0,0,1.5332-1.1587Z" style="fill: ${color}; stroke: black"/>
                    </g>
                  </svg>`;

    return new L.DivIcon({
      html: html,
      className: "leaflet-tracking-div-icon",
    });
  };
  const createMarker = (lat, lng, icon, info) => {
    const marker = L.marker([lat, lng], { icon: icon });
    const coords = convert2Coords(info.location.lat, info.location.long);
    marker
      .bindPopup(
        [
          "Tên tàu: " + info.ship_name,
          "Thời gian: " + convertMillisecondsToDateTime(info.location.time),
          "Tọa độ: " + coords.lat + " - " + coords.lng,
          "Vận tốc: " + info.location.speed + " knot",
          "Hướng: " + info.angle + "°",
        ].join("<br/>"),
        {
          closeButton: true,
          closeOnEscapeKey: false,
          autoClose: false,
          closeOnClick: false,
        },
      )
    marker.custom_angle = info.angle;
    return marker;
  };

  const createTrackkingPoint = useCallback(
    (ship, location) => {
      trackkingPointLayerRef.current.clearLayers();
      const course = location.course;
      const info = { ...ship, location, angle: course };
      const trackkingPointMarker = createMarker(location.lat, location.long, createIcon(course, ship.color), info);
      trackkingPointLayerRef.current.addLayer(trackkingPointMarker);
      trackkingPointMarker.openPopup();
    },
    [trackkingPointLayerRef],
  );

  return { createTrackkingPoint };
}
