import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getReportByType: ["data"],
  reportSet: ["data"],
  reportReset: [],
});

export const ReportTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  isFetching: false,
  isFetchingReport: false,

  areaReportData: {},
  violateReportData: {},

  errors: null,
};

/* ------------- Reducers ------------- */

export const request = (state = INITIAL_STATE, action) => {
  let data = action.data || {};
  return {
    ...state,
    ...data,
    isFetching: true,
  };
};

export const set = (state = INITIAL_STATE, action) => {
  let data = action.data || {};

  return {
    ...state,
    ...data,
  };
};

export const reset = () => INITIAL_STATE;

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_REPORT_BY_TYPE]: request,
  [Types.REPORT_SET]: set,
  [Types.REPORT_RESET]: reset,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
