import React, { Fragment, useEffect } from "react";
import { Close } from "@mui/icons-material";
import { Box, Dialog, Grid, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AppConstant, LangConstant } from "const";
import ListShipLostTable from "./ListShipLostTable";
import ReportSummary from "./ReportSummary";
import ReportDoughnutChart from "./ReportDoughnutChart";
import ReportBarChart from "./ReportBarChart";
import { useDispatch, useSelector } from "react-redux";
import ShipRedux from "redux-store/ship.redux";
import { handleOpenLoading, dragElement } from "utils";

const ReportListShipLost = ({ open, onClose, lostType }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const id = "statistic-ship-lost-container";
  const getListShipLostStatus = useSelector(state => state.shipRedux.getListShipLostStatus);
  const shipLostChartData = useSelector(state => state.shipRedux.shipLostChartData);

  const onCloseDialog = () => {
    onClose();
  };

  const onMinimize = event => {
    event.preventDefault();
    event.stopPropagation();

    let parentContainer = document.getElementById(id);
    if (parentContainer) {
      parentContainer.style.transitionDuration = "500ms";
      parentContainer.style.transform = "scale(0.3)";
      parentContainer.style.bottom = "-160px";
      parentContainer.style.right = "-70%";

      let childContainer = parentContainer.children;
      if (childContainer) {
        childContainer[0].style.display = "none";
      }

      setTimeout(() => {
        parentContainer.style.removeProperty("transition-duration");
      }, 506);
    }
    // dragElement(id);
  };

  const onMaximize = () => {
    let parentContainer = document.getElementById(id);
    if (parentContainer) {
      parentContainer.style.transitionDuration = "500ms";
      parentContainer.style.transform = "scale(1)";
      parentContainer.style.top = "0px";
      parentContainer.style.bottom = "0px";
      parentContainer.style.right = "0px";
      parentContainer.style.left = "0px";

      let childContainer = parentContainer.children;
      if (childContainer) {
        childContainer[0].style.display = "flex";
      }

      setTimeout(() => {
        parentContainer.style.removeProperty("transition-duration");
      }, 506);
    }
  };

  useEffect(() => {
    dispatch(
      ShipRedux.getListShipLost({
        statistic_type: lostType,
        export_f: AppConstant.IS_EXPORT_FILE.no,
        page: AppConstant.FIRST_PAGE,
        size: AppConstant.PAGE_SIZE,
      }),
    );
    handleOpenLoading(true);
  }, []);

  useEffect(() => {
    if (
      getListShipLostStatus === AppConstant.API_STATUS.success ||
      getListShipLostStatus === AppConstant.API_STATUS.error
    ) {
      handleOpenLoading(false);
    }
    dispatch(
      ShipRedux.shipSet({
        getListShipLostStatus: AppConstant.API_STATUS.nothing,
      }),
    );
  }, [getListShipLostStatus]);

  return (
    <Fragment>
      {shipLostChartData && (
        <Dialog open={open} maxWidth="xl" fullWidth id={id} disableEnforceFocus>
          <Box className={classes.header}>
            <Typography className="header-title">
              {lostType === AppConstant.SHIP_LOST_TYPE.disconnected
                ? LangConstant.TXT_LIST_SHIP_DISCONNECTED_TITLE
                : LangConstant.TXT_LIST_SHIP_LOST_SIGNAL_TITLE}
            </Typography>
            <IconButton size="small" onClick={onCloseDialog}>
              <Close fontSize="small" />
            </IconButton>
          </Box>
          <Box className={classes.body} id={id + "header"}>
            <Grid container spacing={2} sx={{ display: "flex", justifyContent: "space-between" }}>
              <Grid item xs={4} className={classes.summaryContainer}>
                <ReportSummary lostType={lostType} />
              </Grid>
              <Grid item xs={4} className={classes.doughnutContainer}>
                <ReportDoughnutChart />
              </Grid>
            </Grid>
            <Box onClick={onMaximize}>
              <ListShipLostTable lostType={lostType} onClose={onMinimize} />
            </Box>
          </Box>
        </Dialog>
      )}
    </Fragment>
  );
};

export default ReportListShipLost;

const useStyles = makeStyles({
  body: {
    padding: 16,
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 16,
    "& > .header-title": {
      textTransform: "uppercase",
      fontSize: 16,
      fontWeight: "bold",
    },
  },

  summaryContainer: {
    display: "flex",
    alignItems: "center",
  },

  doughnutContainer: {
    height: "400px",
  },

  barChartContainer: {
    display: "flex",
    alignItems: "center",
  },
});
