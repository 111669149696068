import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { useMapContext } from "hooks";

export default function ShipList({ data, onChange }) {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);

  const { sidebarMode } = useMapContext();

  const onChangeValue = event => {
    const value = event.target.value;
    const list = [...selected];
    const index = list.indexOf(value);
    index === -1 ? list.push(value) : list.splice(index, 1);
    setSelected(list);
    onChange(event);
  };

  useEffect(() => {
    setSelected([]);
  }, [sidebarMode]);

  return data.length > 0 ? (
    <FormGroup
      classes={{
        root: classes.shipListBox,
      }}
    >
      {data.map((item, index) => (
        <FormControlLabel
          key={index}
          control={
            <Checkbox size="small" value={item.uid} onChange={onChangeValue} checked={item?.isSelected === true} />
          }
          label={item.name}
          classes={{
            label: classes.nameShip,
          }}
        />
      ))}
    </FormGroup>
  ) : (
    <Typography variant="caption">Không có dữ liệu hiển thị</Typography>
  );
}
const useStyles = makeStyles(theme => ({
  shipListBox: {
    display: "grid",
    gridTemplateColumns: "auto",
    marginLeft: "8px",
  },

  nameShip: {
    fontSize: "14px",
    color: "#000",
    lineHeight: "100%",
    display: "flex",
    alignItems: "center",
    userSelect: "none",
  },
}));
