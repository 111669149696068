import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AreaActions from "redux-store/area.redux";
import ShipActions from "redux-store/ship.redux";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { makeStyles } from "@mui/styles";

export default function SeaArea() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const defaultAreaList = useSelector(state => state.areaRedux.defaultAreaList);
  const selectedAreaList = useSelector(state => state.areaRedux.selectedAreaList);
  const shipConditions = useSelector(state => state.shipRedux.shipConditions);

  const [selected, setSelected] = useState(selectedAreaList?.map(a => a.uid));

  const onChangeValue = (event, area) => {
    let value = event.target.value;
    let list = [...selected];
    let index = list.indexOf(value);
    if (index === -1) {
      list.push(value);
      dispatch(AreaActions.areaSet({ selectedAreaList: selectedAreaList?.concat([area]) }));
    } else {
      list = list.filter(item => item !== value);
      dispatch(AreaActions.areaSet({ selectedAreaList: selectedAreaList?.filter(a => a.uid !== value) }));
    }
    setSelected(list);
    dispatch(
      ShipActions.shipSet({
        isChangingFilter: true,
        shipConditions: Object.assign(shipConditions, { default_area_list: list }),
      }),
    );
  };

  useEffect(() => {
    setSelected(selectedAreaList?.map(a => a.uid));
  }, [selectedAreaList]);

  return (
    <FormGroup
      classes={{
        root: classes.selectAreaCheckContainer,
      }}
    >
      {defaultAreaList.map(item => (
        <FormControlLabel
          key={item.uid}
          control={
            <Checkbox value={item.uid} onChange={e => onChangeValue(e, item)} checked={selected.includes(item.uid)} />
          }
          label={item.name}
          classes={{
            label: classes.areaName,
          }}
        />
      ))}
    </FormGroup>
  );
}

const useStyles = makeStyles(theme => ({
  selectAreaCheckContainer: {
    marginLeft: 16,
    background: "#cecece",
    padding: "0 16px",
    maxHeight: 400,
    flexWrap: "no-wrap",
    overflowY: "scroll",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    borderRadius: "4px",
    "&::-webkit-scrollbar": {
      width: "0.5em",
    },
    "&::-webkit-scrollbar-track": {
      background: "#F1F1F1",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
      borderRadius: 8,
    },
  },

  areaName: {
    fontSize: "14px",
    color: "#000",
    lineHeight: "100%",
    display: "flex",
    alignItems: "center",
    userSelect: "none",
  },
}));
