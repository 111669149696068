import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  Pagination,
  Button,
  Typography,
  Checkbox,
} from "@mui/material";
import { Visibility } from "@mui/icons-material";
import { AppConstant, LangConstant, SystemConstant } from "const";
import { debounce } from "lodash";
import { convertMillisecondsToDateTime } from "utils/date.utils";
import EventWarningAction from "redux-store/eventwarning.redux";
import DetailNotification from "./DetailNotification";
import { handleOpenLoading } from "utils";
import { EventWarningService } from "services";
import { makeStyles } from "@mui/styles";
import PopupConfirmDelete from "./PopupConfirmDelete";

const TableNotificationManagement = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [dataDetail, setDataDetail] = useState(null);
  const [isOpenDetailDialog, setIsOpenDetailDialog] = useState(false);

  const listEventWarningNotification = useSelector(state => state.eventWarningRedux.listEventWarningNotification);
  const totalPage = useSelector(state => state.eventWarningRedux.totalPageEventWarningNotification);
  const totalSize = useSelector(state => state.eventWarningRedux.totalElementEventWarningNotification);
  const [selectedNotificationUid, setSelectedNotificationUid] = useState([]);
  const [confirmDeleteMany, setConfirmDeleteMany] = useState(false);

  const onClickCheckbox = id => {
    setSelectedNotificationUid(pre => (pre.includes(id) ? pre.filter(item => item !== id) : [...pre, id]));
  };

  const onClickDeleteMuch = () => {
    setConfirmDeleteMany(true);
  };

  const onChangePage = (e, value) => {
    setPage(value);
  };

  const onOpenDetail = data => {
    if (!data?.is_read) {
      EventWarningService.getEventWarningNotificationDetail(data.uid).then(res => {
        dispatch(
          EventWarningAction.eventWarningSet({
            listEventWarningNotification: [...listEventWarningNotification].map(item => {
              return item.uid === data.uid ? { ...item, is_read: true } : item;
            }),
          }),
        );
      });
    }
    setDataDetail(data);
    setIsOpenDetailDialog(true);
  };

  const onCloseDetail = data => {
    setIsOpenDetailDialog(false);
  };

  const onHandleSearch = () => {
    handleOpenLoading();
    setPage(AppConstant.FIRST_PAGE);
    dispatch(
      EventWarningAction.getEventWarningNotifications({
        page: AppConstant.FIRST_PAGE - 1,
        size: AppConstant.PAGE_SIZE,
        filter: searchInput,
      }),
    );
  };

  const onHandleExportData = () => {
    handleOpenLoading();
    dispatch(
      EventWarningAction.exportEventWarningNotifications({
        export_f: 1,
        filter: searchInput,
      }),
    );
  };

  useEffect(() => {
    handleOpenLoading();
    dispatch(
      EventWarningAction.getEventWarningNotifications({
        page: page - 1,
        size: AppConstant.PAGE_SIZE,
        filter: searchInput,
      }),
    );
  }, [page]);

  const onReadAll = event => {
    EventWarningService.readAllEventWarningNotification().then(res => {
      dispatch(
        EventWarningAction.eventWarningSet({
          listEventWarningNotification: [...listEventWarningNotification].map(item => {
            return { ...item, is_read: true };
          }),
          is_new: 0,
        }),
      );
    });
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      setSelectedNotificationUid(listEventWarningNotification?.map(item => item?.uid));
      return;
    }
    setSelectedNotificationUid([]);
  };

  return (
    <>
      <Box sx={{ fontWeight: "bold", fontSize: "20px", color: "cornflowerblue" }}>
        {LangConstant.TXT_NOTIFICATON_WARNING_LIST}: {totalSize}
      </Box>
      <Box sx={{ marginTop: 2, display: "flex", justifyContent: "start" }}>
        <TextField
          sx={{ width: 300 }}
          onChange={debounce(e => {
            setSearchInput(e.target.value);
          }, 500)}
          fullWidth
          size="small"
          id="search_box_event_warning"
          label={LangConstant.L_SEARCH}
          variant="outlined"
        />
        <Button sx={{ ml: 3 }} variant="contained" onClick={onHandleSearch}>
          {LangConstant.TXT_SEARCH}
        </Button>
        <Box sx={{ ml: 3, marginLeft: "auto", marginRight: "20px", display: "flex" }}>
          <Typography
            sx={{ marginRight: "10px" }}
            className={classes.buttonMenu}
            onClick={() => {
              onReadAll();
            }}
          >
            Đánh dấu đã đọc
          </Typography>
          <Button variant="contained" onClick={onHandleExportData}>
            {LangConstant.L_EXPORT_DATA}
          </Button>
          <Button
            sx={{ marginLeft: "20px" }}
            onClick={onClickDeleteMuch}
            variant="outlined"
            disabled={selectedNotificationUid.length > 0 ? false : true}
          >
            Xoá
          </Button>
        </Box>
      </Box>

      <TableContainer style={{ height: 700 }}>
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="50px">
                <Checkbox
                  checked={
                    selectedNotificationUid?.length &&
                    selectedNotificationUid?.length === listEventWarningNotification?.length
                  }
                  onChange={handleSelectAllClick}
                  inputProps={{ "aria-label": "select all desserts" }}
                />
              </TableCell>{" "}
              <TableCell width="40px">{LangConstant.TXT_STT}</TableCell>
              <TableCell align="center">{LangConstant.TXT_SHIP_NAME}</TableCell>
              <TableCell align="center">{LangConstant.TXT_EVENT_WARNING_TYPE}</TableCell>
              <TableCell align="center">{LangConstant.TXT_TIME}</TableCell>
              <TableCell align="center">{LangConstant.TXT_DESCRIPTION}</TableCell>
              <TableCell align="center">{LangConstant.TXT_MANIPULATION}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {listEventWarningNotification &&
              listEventWarningNotification.map((data, index) => {
                return (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    key={data.uid}
                    id={data.uid}
                    className={{ [classes.notRead]: !data.is_read }}
                  >
                    {" "}
                    <TableCell>
                      <Checkbox
                        checked={selectedNotificationUid.includes(data.uid)}
                        onClick={() => {
                          onClickCheckbox(data.uid);
                        }}
                        color="info"
                      />
                    </TableCell>
                    <TableCell>{(page - 1) * AppConstant.PAGE_SIZE + index + 1}</TableCell>
                    <TableCell align="center">
                      {data.content.slice(data.content.indexOf("[") + 1, data.content.indexOf("]"))}
                    </TableCell>
                    <TableCell align="center">
                      {Object.values(SystemConstant.TYPE_ACTION_WARNING).find(item => item.id === data.type)?.name ||
                        ""}
                    </TableCell>
                    <TableCell align="center">{convertMillisecondsToDateTime(data.created)}</TableCell>
                    <TableCell align="center" sx={{ color: "seagreen" }}>
                      {data.content ? data.content : ""}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton title={"Xem thông tin chi tiết"} onClick={() => onOpenDetail(data)}>
                        <Visibility />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <DetailNotification data={dataDetail} open={isOpenDetailDialog} onClose={onCloseDetail} />

      <Box sx={{ padding: "10px" }}>
        <Pagination onChange={onChangePage} sx={{ paddingTop: 2 }} count={totalPage} variant="outlined" page={page} />
      </Box>
      <PopupConfirmDelete
        checked={selectedNotificationUid}
        open={confirmDeleteMany}
        onClose={() => setConfirmDeleteMany(false)}
        onReset={() => {
          setSelectedNotificationUid([]);
          setPage(1);
          onHandleSearch();
        }}
      />
    </>
  );
};

const useStyles = makeStyles(theme => ({
  notRead: {
    "& td": { fontWeight: "bold" },
    boxShadow: "0px 0px 3px rgba(0,0,0,.5)",
  },
  buttonMenu: {
    display: "flex",
    color: "#1976d2",
    width: "fit-content",
    cursor: "pointer",
    alignItems: "center",
  },
}));
export default TableNotificationManagement;
