import React from "react";
import { Box, Typography, TextField, InputAdornment, Button } from "@mui/material";
import { Search } from "@mui/icons-material";
import { debounce } from "lodash";
import { useNotification } from "hooks";
import { usePermission, useUpdatePermission } from "./hooks";
import { PermissionTable } from "./components";
import { Link } from "react-router-dom";
import { PathConstant } from "const";

const PermissionList = () => {
  const notification = useNotification();
  const { page, name, permissions, totalItems, totalPages, handleChangeParams } = usePermission();
  const { deletePermissionGroup } = useUpdatePermission();

  const handleInputChange = debounce(e => {
    handleChangeParams(page, e.target.value);
  }, 500);

  const handleDeleteGroup = async uid => {
    const isSuccess = await deletePermissionGroup(uid);
    if (isSuccess) {
      await handleChangeParams(page, name);
      notification.send("Xoá nhóm quyền thành công", "success");
    } else {
      notification.send("Đã có lỗi xảy ra, vui lòng thử lại sau", "error");
    }
  };

  return (
    <Box sx={classes.container}>
      <Typography sx={classes.pageTitle} variant="h6">
        Quản lý nhóm quyền
      </Typography>
      <Box sx={classes.searchContainer}>
        <TextField
          size="small"
          label="Tìm kiếm"
          variant="outlined"
          defaultValue={name}
          sx={classes.searchInput}
          onChange={handleInputChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <Link className="button-link" to={PathConstant.ADMIN_CREATE_PERMISSION}>
          <Button variant="contained">Thêm mới</Button>
        </Link>
      </Box>
      <PermissionTable
        rows={permissions}
        page={page}
        totalItems={totalItems}
        totalPages={totalPages}
        onChangePage={page => handleChangeParams(page, name)}
        onDelete={handleDeleteGroup}
      />
    </Box>
  );
};

export default PermissionList;

const classes = {
  container: {
    padding: "40px",
  },

  pageTitle: {
    marginBottom: "24px",
  },

  searchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "24px",
  },

  searchInput: {
    width: 500,
  },
};
