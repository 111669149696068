import React, { useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Paper, ListItemButton, ListItemText, Collapse, List, FormControlLabel, Checkbox } from "@mui/material";

const FeatureDropdown = ({ name, features, scopes, onChange }) => {
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleChange = feature => {
    if (onChange) onChange(feature, features);
  };

  const featureScopes = scopes ? scopes : [];

  return (
    <Paper elevation={2} sx={{ height: open ? "100%" : "unset", transition: "all linear 300ms" }}>
      <ListItemButton onClick={handleClick}>
        <ListItemText primary={name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout={200} unmountOnExit>
        <List component="div" disablePadding>
          {features.map((feature, index) => (
            <ListItemButton sx={{ p: 0, pl: 2 }} key={index}>
              <FormControlLabel
                sx={{ width: "100%" }}
                label={feature.label}
                control={
                  <Checkbox
                    size="small"
                    checked={featureScopes.includes(feature.id)}
                    onChange={() => handleChange(feature)}
                  />
                }
              />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </Paper>
  );
};

export default FeatureDropdown;
