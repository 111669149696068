import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  userActionSet: ["data"],

  getUserActionListByUser: ["data"],
  exportUserActionListByUser: ["data"],

  getUserActionListAllUser: ["data"],
  exportUserActionListAllUser: ["data"],

  getSummaryAction: ["data"],
  exportSummaryAction: ["data"],
  getTotalAction: ["data"],

  userActionReset: [],
});

export const UserActionTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  isFetching: false,
  userActionList: [],
  userActionAllList: [],
  userActionSummaryList: [],
  userActionTotalList: [],
  errors: null,
};

/* ------------- Reducers ------------- */

export const request = (state = INITIAL_STATE, action) => {
  let data = action.data || {};
  return {
    ...state,
    ...data,
    isFetching: true,
  };
};

export const set = (state = INITIAL_STATE, action) => {
  let data = action.data || {};

  return {
    ...state,
    ...data,
  };
};

export const reset = () => INITIAL_STATE;

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.USER_ACTION_SET]: set,

  [Types.GET_USER_ACTION_LIST_BY_USER]: request,
  [Types.EXPORT_USER_ACTION_LIST_BY_USER]: request,
  [Types.GET_USER_ACTION_LIST_ALL_USER]: request,
  [Types.EXPORT_USER_ACTION_LIST_ALL_USER]: request,

  [Types.GET_SUMMARY_ACTION]: request,
  [Types.EXPORT_SUMMARY_ACTION]: request,
  [Types.GET_TOTAL_ACTION]: request,

  [Types.USER_ACTION_RESET]: reset,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
