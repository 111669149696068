import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  requestGetPorts: ["data"],
  deletePorts: ["data"],
  updatePort: ["data"],
  portSet: ["data"],
  portReset: [],
});

export const PortTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  isFetching: false,
  isDeleteSuccess: false,
  isUpdatePortSuccess: false,
  ports: [],
  errors: null,
};

/* ------------- Reducers ------------- */

export const request = (state = INITIAL_STATE, action) => {
  let data = action.data || {};
  return {
    ...state,
    ...data,
    isFetching: true,
  };
};

export const set = (state = INITIAL_STATE, action) => {
  let data = action.data || {};

  return {
    ...state,
    ...data,
  };
};

export const reset = () => INITIAL_STATE;

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.REQUEST_GET_PORTS]: request,
  [Types.DELETE_PORTS]: request,
  [Types.UPDATE_PORT]: request,
  [Types.PORT_SET]: set,
  [Types.PORT_RESET]: reset,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
