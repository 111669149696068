import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PermissionService } from "services";
import { useLoading, useNotification } from "hooks";
import { PERMISSION_PAGE_SIZE } from "../components/PermissionTable";

export const usePermission = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const notification = useNotification();
  const loading = useLoading();

  const handleGetPage = () => {
    const page = searchParams.get("page") || "1";
    return parseInt(page);
  };

  const handleGetName = () => {
    const name = searchParams.get("name") || "";
    return name;
  };

  const [name, setName] = useState(handleGetName());
  const [page, setPage] = useState(handleGetPage());
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [permissions, setPermissions] = useState([]);

  const handleGetPermissions = async (page, name) => {
    try {
      loading.open();
      const response = await PermissionService.getPermissionGroup(page, PERMISSION_PAGE_SIZE, name);
      setTotalItems(response.data.total);
      setTotalPages(response.data.total_page);
      setPermissions(response.data.data);
      loading.close();
    } catch (error) {
      loading.close();
      console.error(error);
      notification.send("Đã có lỗi xảy ra, vui lòng thử lại sau", "error");
    }
  };

  const handleChangeParams = async (newPage, newName) => {
    setPage(newPage);
    setName(newName);
    setSearchParams(new URLSearchParams({ name: newName, page: newPage }));
    handleGetPermissions(newPage, newName);
  };

  useEffect(() => {
    handleGetPermissions(page, name);
  }, []);

  return { page, name, permissions, totalItems, totalPages, handleChangeParams };
};
