import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";

const NavigationAidIcon = ({ className, width, height }) => {
  return (
    <SvgIcon className={className} width={width} height={height} viewBox={`0 0 201.26 201.26`}>
      <path
        fill="rgba(255, 0, 0, 1)"
        d="M100.63 0L0 100.63l100.63 100.63 100.63-100.63L100.63 0zm0 194.178L7.09 100.631 100.63 7.09l93.548 93.541-93.548 93.547z"
      ></path>
      <path d="M100.629 194.177l-93.54-93.546L100.63 7.089l93.547 93.54z"></path>
      <path
        fill="rgba(255, 0, 0, .6)"
        d="M114.808 86.459c-7.834-7.828-20.523-7.828-28.349 0-7.828 7.825-7.828 20.514.001 28.349 7.825 7.832 20.514 7.832 28.348 0 7.832-7.835 7.832-20.523 0-28.349zm-22.687 22.666c-4.689-4.689-4.688-12.3.001-17.003 4.703-4.689 12.313-4.689 17.002 0 4.713 4.705 4.713 12.314.001 17.003-4.689 4.711-12.3 4.711-17.004 0z"
      ></path>
    </SvgIcon>
  );
};
NavigationAidIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};
NavigationAidIcon.defaultProps = {
  width: 24,
  height: 24,
};

export default memo(NavigationAidIcon);
