import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getFolders: ["data"],
  getFolder: ["data"],
  deleteFolder: ["data"],
  createFolder: ["data"],
  updateFolder: ["data"],
  uploadFileFolder: ["data"],
  deleteFileFolder: ["data"],
  downloadFolder: ["data"],
  downloadFilesFolder: ["data"],

  folderSet: ["data"],
  folderReset: [],

  getFolderNotifications: ["data"],

  folderUpdateIsNew: ["data"],
});
export const FolderTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  listFolder: [],
  totalPageFolder: 1,
  totalElementFolder: 0,
  isOpenCreateFolder: false,
  isOpenEditFolder: false,
  dataFolder: null,
  folderDetail: null,
  fileList: [],
  isReGetFolders: false,
  listFolderNotification: [],
  totalPageFolderNotification: 1,
  totalElementFolderNotification: 0,
  is_new: 0,
};

/* ------------- Reducers ------------- */
export const request = (state = INITIAL_STATE, action) => {
  let data = action.data || {};
  return {
    ...state,
    ...data,
    isFetching: true,
  };
};

export const set = (state = INITIAL_STATE, action) => {
  let data = action.data || {};

  return {
    ...state,
    ...data,
  };
};
export const updateIsNew = (state = INITIAL_STATE, action) => {
  let data = action.data || {};
  return {
    ...state,
    is_new: state.is_new + data,
  };
};

export const reset = () => INITIAL_STATE;

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_FOLDERS]: request,
  [Types.GET_FOLDER]: request,
  [Types.DELETE_FOLDER]: request,
  [Types.CREATE_FOLDER]: request,
  [Types.UPDATE_FOLDER]: request,
  [Types.UPLOAD_FILE_FOLDER]: request,
  [Types.DOWNLOAD_FOLDER]: request,
  [Types.DOWNLOAD_FILES_FOLDER]: request,

  [Types.DELETE_FILE_FOLDER]: set,
  [Types.FOLDER_SET]: set,
  [Types.FOLDER_RESET]: reset,

  [Types.GET_FOLDER_NOTIFICATIONS]: request,
  [Types.FOLDER_UPDATE_IS_NEW]: updateIsNew,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
