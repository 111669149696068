import React from "react";
import { Box, Typography } from "@mui/material";
import { PermissionForm } from "./components";
import { usePermissionDetails, useUpdatePermission } from "./hooks";

const UpdatePermission = () => {
  const { permission, permissionGroupUid } = usePermissionDetails();
  const { updatePermissionGroup } = useUpdatePermission();

  const handleSubmit = async payload => {
    updatePermissionGroup({ ...payload, uid: permissionGroupUid });
  };

  return (
    <Box sx={classes.container}>
      <Typography sx={classes.pageTitle} variant="h6">
        Cập nhật nhóm quyền
      </Typography>
      {permission && (
        <PermissionForm
          mode="edit"
          name={permission.name}
          description={permission.description}
          scopes={permission.scopes}
          users={permission.users}
          onSubmit={handleSubmit}
        />
      )}
    </Box>
  );
};

export default UpdatePermission;

const classes = {
  container: {
    padding: "40px",
  },

  pageTitle: {
    marginBottom: "24px",
  },
};
