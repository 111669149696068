import { ApiConstant, AppConstant } from "const";
import { createApiDownloadExcel, createApiWithToken } from "services/config/api.config";
import QueryString from "query-string";
import StringFormat from "string-format";

export const getShipTypes = () => createApiWithToken().get(ApiConstant.GET_SHIP_TYPES);

export const getShips = data => {
  if (Array.isArray(data.ship_uids)) {
    const ship_uids = data.ship_uids.map(uid => `ship_uids=${uid}`).join("&");
    return createApiWithToken().get(`${ApiConstant.GET_SHIPS}?${ship_uids}`);
  }
  return createApiWithToken().get(ApiConstant.GET_SHIPS, data);
};
export const getTrackingShips = () => createApiWithToken().get(ApiConstant.GET_ALL_TRACKING_SHIPS);

export const getLocation = data =>
  createApiWithToken().post(ApiConstant.GET_LOCATION, data, {
    paramsSerializer: params => QueryString.stringify(params, { arrayFormat: "repeat" }),
  });

export const getLocation2 = data =>
  createApiWithToken().post(ApiConstant.GET_LOCATION2, data, {
    paramsSerializer: params => QueryString.stringify(params, { arrayFormat: "repeat" }),
  });

export const trackingShips = data =>
  createApiWithToken().post(ApiConstant.TRACKING_SHIPS, data, {
    paramsSerializer: params => QueryString.stringify(params, { arrayFormat: "repeat" }),
    responseType: data.export_f === 1 ? "blob" : "json",
  });

export const trackingShip = data =>
  createApiWithToken().get(StringFormat(ApiConstant.TRACKING_SHIP, data.uid), { update_f: data?.update_f });

export const getCrawlDataHistory = () => createApiWithToken().get(ApiConstant.GET_DATA_HISTORY);

export const addViolationShip = data =>
  createApiWithToken().put(StringFormat(ApiConstant.ADD_VIOLATION_SHIP, data.uid), data);

export const deleteViolationShip = data =>
  createApiWithToken().delete(StringFormat(ApiConstant.DELETE_VIOLATION_SHIP, data.uid));

export const editViolationShip = data =>
  createApiWithToken().put(StringFormat(ApiConstant.DELETE_VIOLATION_SHIP, data.uid), data);

export const getFilterShipInDataTable = data =>
  createApiWithToken().post(ApiConstant.GET_FILTER_SHIP_IN_DATA_TABLE, data, {
    responseType: data.export_flag === 1 ? "blob" : "json",
    paramsSerializer: params => QueryString.stringify(params, { arrayFormat: "repeat" }),
  });

export const getShipInArea = data => {
  return createApiWithToken().get(StringFormat(ApiConstant.GET_SHIP_IN_AREA, data.area_uid), data, {
    responseType: data.export_f === 1 ? "blob" : "json",
  });
};

export const crawlShipToUpdate = data => {
  return createApiWithToken().get(StringFormat(ApiConstant.CRAWL_SHIP_TO_UPDATE, data.uid));
};

export const getListShipLost = data =>
  createApiWithToken().get(ApiConstant.GET_LIST_SHIP_LOST, data, {
    responseType: data.export_f === AppConstant.IS_EXPORT_FILE.yes ? "blob" : "json",
  });

export const removeListShipLost = data => createApiWithToken().post(ApiConstant.REMOVE_LIST_SHIP_LOST, data);

export const getListShipByDay = data => createApiWithToken().get(ApiConstant.GET_LIST_SHIP_BY_DAY, data);

export const exportListShipByDay = data => createApiDownloadExcel().get(ApiConstant.EXPORT_LIST_SHIP_BY_DAY, data);

export const getShipsAround = data =>
  createApiWithToken().post(ApiConstant.GET_SHIP_AROUND, data, {
    paramsSerializer: params => QueryString.stringify(params, { arrayFormat: "repeat" }),
  });
