import React, { useEffect, useRef, useState } from "react";
import {
  InputAdornment,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  Typography,
  Pagination,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Close, Search } from "@mui/icons-material";
import { debounce } from "lodash";
import { useSearchUser } from "../hooks";

export const PAGE_SIZE = 10;

const AddUserDialog = ({ open, currentSelectedUsers, onAdd, onClose }) => {
  const { searchingUsers, totalPages, totalItems, searchUser } = useSearchUser();

  const searchInputRef = useRef(null);

  const [page, setPage] = useState(1);
  const [selectedUsers, setSelectedUsers] = useState(currentSelectedUsers);

  const handleInputChange = debounce(e => {
    searchUser(e.target.value || "", page);
  }, 500);

  const handleChangePage = (_, value) => {
    if (page !== value) {
      setPage(value);
      searchUser(searchInputRef.current?.value || "", value);
    }
  };

  const handleClose = () => {
    if (onClose) {
      setPage(1);
      setSelectedUsers(currentSelectedUsers);
      searchInputRef.current.value = "";
      searchUser("", 1);
      onClose();
    }
  };

  const handleChangeCheckbox = user => {
    if (selectedUsers.map(user => user.uid).includes(user.uid)) {
      setSelectedUsers(state => state.filter(selectedUser => selectedUser.uid !== user.uid));
    } else {
      setSelectedUsers(state => [...state, user]);
    }
  };

  const handleAddUser = () => {
    if (onAdd) onAdd(selectedUsers);
  };

  useEffect(() => {
    setSelectedUsers(currentSelectedUsers);
  }, [currentSelectedUsers]);

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogContent>
        <Box sx={classes.heading}>
          <Typography variant="h6">Thêm người dùng</Typography>
          <IconButton variant="outlined" onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <TextField
          fullWidth
          size="small"
          label="Tìm kiếm"
          variant="outlined"
          ref={searchInputRef}
          sx={classes.searchInput}
          onChange={handleInputChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
          <Table sx={{ minWidth: 650 }} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="center">STT</TableCell>
                <TableCell align="center">Họ và tên</TableCell>
                <TableCell align="center">Phòng ban</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Số điện thoại</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchingUsers && searchingUsers.length > 0 ? (
                searchingUsers.map((searchingUser, index) => (
                  <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell align="center">
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={selectedUsers.map(user => user.uid).includes(searchingUser.uid)}
                            onChange={() => handleChangeCheckbox(searchingUser)}
                          />
                        }
                      />
                    </TableCell>
                    <TableCell align="center">{index + 1 + (page - 1) * PAGE_SIZE}</TableCell>
                    <TableCell align="center">{searchingUser.name}</TableCell>
                    <TableCell align="center">{searchingUser.department || "-"}</TableCell>
                    <TableCell align="center">{searchingUser.email}</TableCell>
                    <TableCell align="center">{searchingUser.phone_number || "-"}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell colSpan={6} align="center">
                    Không có dữ liệu
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={classes.footer}>
          <Typography variant="caption">Số lượng người dùng tìm thấy: {totalItems || 0}</Typography>
          <Pagination page={page} color="primary" count={totalPages} onChange={handleChangePage} />
        </Box>
      </DialogContent>
      <DialogActions sx={classes.dialogFooter}>
        <Button variant="contained" onClick={handleAddUser}>
          Xác nhận
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserDialog;

const classes = {
  heading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "24px",
  },

  searchInput: {
    marginBottom: "16px",
  },

  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "12px",
  },

  dialogFooter: {
    justifyContent: "center",
    paddingBottom: "24px",
  },
};
