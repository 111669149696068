import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { LangConstant } from "const";
import { useDispatch } from "react-redux";
import EventWarningAction from "redux-store/eventwarning.redux";
import StringFormat from "string-format";
import { Close } from "@mui/icons-material";

const ConfirmDeleteDialog = props => {
  const { open, onClose, data } = props;
  const dispatch = useDispatch();

  const onClickButtonDeleteShip = () => {
    dispatch(
      EventWarningAction.deleteEventWarning({
        uids: data.uid,
      }),
    );
    onClose();
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={onClose}>
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        {LangConstant.TXT_CONFIRM_DELETE_EVENT_WARNING}
        <IconButton onClick={onClose} sx={{ marginLeft: "auto" }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ paddingTop: 10 }}>
        {data && StringFormat(LangConstant.FM_CONFIRM_DELETE, data.event_id)}
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
        <Button variant="contained" onClick={onClickButtonDeleteShip}>
          {LangConstant.TXT_YES}
        </Button>
        <Button onClick={onClose} sx={{ color: "white", backgroundColor: "red" }}>
          {LangConstant.TXT_NO}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteDialog;
