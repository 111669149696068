import { ApiConstant } from "const";
import { call, put } from "redux-saga/effects";
import ManagementAction from "redux-store/management.redux";
import { ManagementService } from "services";

export function* getModule() {
  try {
    let response = yield call(ManagementService.getModule);

    if (response.status === ApiConstant.STT_OK && response.data.data) {
      yield put(
        ManagementAction.managementSet({
          module: response.data.data,
        }),
      );
    } else {
      yield put(
        ManagementAction.managementSet({
          errors: response,
        }),
      );
    }
  } catch (error) {
    console.trace(error);
  }
}

export function* getRolePermission(action) {
  let data = action.data;
  try {
    let response = yield call(ManagementService.getRolePermission, data);
    if (response.status === ApiConstant.STT_OK && response.data.data) {
      yield put(
        ManagementAction.managementSet({
          permission: response.data.data,
        }),
      );
    }
  } catch (error) {
    console.trace(error);
  }
}
