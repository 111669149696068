import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress, Modal } from "@mui/material";
import { EventBus } from "EventBus";
import { EventConstant } from "const";
import CommonActions from "redux-store/common.redux";

const Loading = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(state => state.commonRedux.isLoading);

  const handleLoading = open => {
    dispatch(CommonActions.commonSet({ isLoading: open }));
  };

  useEffect(() => {
    EventBus.on(EventConstant.GLOBAL_EVENTS.openLoading, handleLoading);
    return () => {
      EventBus.off(EventConstant.GLOBAL_EVENTS.openLoading, handleLoading);
    };
  });

  return (
    <Modal open={isLoading} slotProps={{ backdrop: { className: "loading-modal" } }}>
      <Box height="100vh" width="100vw" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    </Modal>
  );
};

export default Loading;
