import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ShipActions from "redux-store/ship.redux";
import clsx from "clsx";
import { some, uniqBy, debounce } from "lodash";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Popover,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  DeleteOutline,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Sailing,
  SailingOutlined,
  Search,
  Videocam,
  Cached,
  Download,
} from "@mui/icons-material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import L from "leaflet";
import { LangConstant, AppConstant, FormatConstant, EventConstant, SystemConstant } from "const";
import { convertMillisecondsToDateTime } from "utils/date.utils";
import { convertCoordinates, getRandomShipColor } from "utils";
import {
  useAnimationTrackingLayer,
  useCaptureLayer,
  useLoading,
  useMapContext,
  useNotification,
  useTrackingLayer,
  useTrackkingPointMarker,
} from "hooks";
import { EventBus } from "EventBus";
import { MonitoringShip, NearAreaDialog, ShipList } from "./ShipMonitoring/index";
import ConfirmDialog from "components/ConfirmDialog";
import ColorSketchPicker from "components/ColorSketchPicker";

export default function ShipMonitoring() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notification = useNotification();
  const loading = useLoading();
  const { map, mapState, sidebarMode, videoTrackingOptions, setVideoTrackingOptions, trackkingPointLayerRef } = useMapContext();
  const animationTrackingLayer = useAnimationTrackingLayer();
  const trackingLayer = useTrackingLayer();
  const captureLayer = useCaptureLayer();
  const { createTrackkingPoint } = useTrackkingPointMarker(trackkingPointLayerRef);

  const boundLayerRef = useRef(L.featureGroup());
  // Fix bug if 2 location too close apart, it will not pass on map
  const customLocationHistory = (location_history, minutes = 0) => {
    let lastTime = null;
    if (location_history?.length < 10) minutes = 0;
    return location_history?.filter(location => {
      if (lastTime === null) {
        lastTime = location.time;
        return true;
      }

      const thirtyMinutesInMilliseconds = 60 * 1000;
      if (Math.abs(lastTime - location.time) >= thirtyMinutesInMilliseconds * minutes) {
        lastTime = location.time;
        return true;
      }
      return false;
    });
  };

  const filterTrackingShipList = useSelector(state => state.shipRedux.filterTrackingShipList);
  const trackingShipsLocationListDefault = useSelector(state => state.shipRedux.trackingShipsLocationList);
  const [trackingShipsLocationList, setTrackingShipsLocationList] = useState([]);
  useEffect(() => {
    setTrackingShipsLocationList(
      trackingShipsLocationListDefault.map(trackingShip => {
        return { ...trackingShip, location_history: customLocationHistory(trackingShip.location_history) };
      }),
    );
    setCheckedShips(trackingShipsLocationListDefault.map(ship => ship.ship_uid));
  }, [trackingShipsLocationListDefault]);
  const [searchText, setSearchText] = useState("");
  const [selectedShipSearch, setSelectedShipSearch] = useState([]);
  const [trackingHistory, setTrackingHistory] = useState([]);
  const [fromDay, setFromDay] = useState(Date.now() - 24 * 60 * 60 * 1000);
  const [toDay, setToDay] = useState(Date.now());
  const [isOpenSearchDialog, setIsOpenSearchDialog] = useState(false);
  const [searchAnchorEl, setSearchAnchorEl] = useState(null);
  const [trackingShipUid, setTrackingShipUid] = useState("");
  const [isOpenTrackBox, setIsOpenTrackBox] = useState(true);
  const [colors, setColors] = useState({});
  const [selectedCaptureMode, setSelectedCaptureMode] = useState(null);
  const [selectedGroupShips, setSelectedGroupShips] = useState({});
  const [isOpenNearAreaDialog, setIsOpenNearAreaDialog] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [boundsCurrent, setBoundsCurrent] = useState(false);

  const currentTrackingShipDefault = trackingShipsLocationListDefault.find(ship => ship.ship_uid === trackingShipUid);
  const currentTrackingShip = trackingShipsLocationList.find(ship => ship.ship_uid === trackingShipUid);

  const handleOpenNearAreaDialog = () => {
    setIsOpenNearAreaDialog(true);
  };

  const handleCloseNearAreaDialog = () => {
    setIsOpenNearAreaDialog(false);
  };

  const onChangeText = value => {
    setSearchText(value);
  };

  const onClickSearchJourney = (additionalShips = []) => {
    // Get histories
    let localShipTrackingHistory = [];
    if (trackingHistory.length === 0) {
      localShipTrackingHistory = localStorage.getItem(AppConstant.LOCAL_STORAGE_KEY.shipTrackingHistory) || "[]";
      localShipTrackingHistory = JSON.parse(localShipTrackingHistory);
      localShipTrackingHistory = localShipTrackingHistory.map(history => ({ ...history, isSelected: false }));
      setTrackingHistory(localShipTrackingHistory);
    }

    // Save to localStorage
    let trackingHistoryData = [...selectedShipSearch, ...trackingHistory, ...localShipTrackingHistory];
    if (Array.isArray(additionalShips)) trackingHistoryData = [...additionalShips, ...trackingHistoryData];
    trackingHistoryData = uniqBy(trackingHistoryData, "uid");
    trackingHistoryData = trackingHistoryData
      .filter((_, index) => index < 5)
      .map(data => ({ name: data.name, uid: data.uid }));
    localStorage.setItem(AppConstant.LOCAL_STORAGE_KEY.shipTrackingHistory, JSON.stringify(trackingHistoryData));

    // Search
    const selectedgGroupShips = Object.values(selectedGroupShips).flat();
    let selectedShips = [...selectedShipSearch, ...selectedgGroupShips];
    if (Array.isArray(additionalShips)) selectedShips = [...additionalShips, ...selectedShips];
    selectedShips = uniqBy(selectedShips, "uid");
    if (selectedShips.length > 0) {
      dispatch(ShipActions.requestTrackingShips({ start: fromDay, end: toDay, ships: selectedShips }));
    } else {
      dispatch(ShipActions.shipSet({ errors: { message: "Vui lòng chọn ít nhất một tàu để giám sát" } }));
    }
  };

  const onChangeFromDay = newValue => {
    let date = new Date(newValue);
    let milliseconds = date.getTime();
    setFromDay(milliseconds);
  };

  const onChangeToDay = newValue => {
    let date = new Date(newValue);
    let milliseconds = date.getTime();
    setToDay(milliseconds);
  };

  const onDeleteSelectedShip = ship => {
    setSelectedShipSearch(state => state.filter(s => s.uid !== ship.uid));
    setTrackingHistory(state => {
      if (state.map(history => history.uid).includes(ship.uid)) {
        return state.map(history => {
          if (history.uid === ship.uid) return { ...history, isSelected: false };
          return history;
        });
      }
      return [{ name: ship.name, uid: ship.uid, isSelected: false }, ...state];
    });
  };

  const onFocusSearchInput = e => {
    setSearchAnchorEl(e.target.parentElement.parentElement);
    setIsOpenSearchDialog(filterTrackingShipList.status !== "idle");
  };

  const onBlurSearchInput = () => {
    setSearchAnchorEl(null);
    setIsOpenSearchDialog(false);
  };

  const onSelectShip = ship => {
    let isShipSelected = selectedShipSearch.find(selectedShip => selectedShip.uid === ship.uid);
    if (Boolean(isShipSelected)) {
      setSelectedShipSearch(state => state.filter(selectedShip => selectedShip.uid !== ship.uid));
    } else {
      setSelectedShipSearch(state => [...state, ship]);
    }
  };

  const onSelectHistoryShip = e => {
    const ships = [];
    setTrackingHistory(state =>
      state.map(ship => {
        if (ship.uid === e.target.value) {
          ships.push({ ...ship, isSelected: true });
          return { ...ship, isSelected: true };
        }
        return ship;
      }),
    );
    setSelectedShipSearch(state => state.concat(ships));
  };

  const handleFitShipLocations = ship => {
    boundLayerRef.current.clearLayers();
    const points = ship.location_history.map(point => new L.LatLng(point.lat, point.long));
    boundLayerRef.current.addLayer(L.polyline(points));
    onJumpToBounds(boundLayerRef.current.getBounds());
    setBoundsCurrent(boundLayerRef.current.getBounds());
  };

  const handleChangeTrackingShip = e => {
    const value = e.target.value;
    changeTrackingShip(value);
  };

  const changeTrackingShip = value => {
    captureLayer.clear();
    setSelectedCaptureMode(null);
    setTrackingShipUid(value);
    const currentTrackingShip = trackingShipsLocationList.find(ship => ship.ship_uid === value);
    setVideoTrackingOptions(animationTrackingLayer.initVideoTrackingConfigs(currentTrackingShip));
    handleFitShipLocations(currentTrackingShip);
    trackingLayer.clear();
    trackingLayer.show(
      trackingShipsLocationList
        .filter(ship => checkedShips.includes(ship.ship_uid))
        .map(ship => {
          return {
            ...ship,
            locations: customLocationHistory(ship.location_history, ship.ship_uid === value ? 0 : 30),
            color: colors[ship.ship_uid],
          };
        }),
    );
    trackingLayer.closeAllPopups();
    trackingLayer.trackingShips.current[value].lastMarker.openPopup();
  };

  const onJumpToBounds = bounds => {
    if (mapState === "loaded") {
      map.current.fitBounds(bounds);
    }
  };

  const onJumpToLocation = (latlng, shipUid, index) => {
    if (mapState === "loaded") {
      map.current.setView([latlng.lat, latlng.long], map.current.getZoom());
      trackingLayer.closeAllPopups();
      createTrackkingPoint(trackingShipsLocationList.find(ship => ship.ship_uid === shipUid), latlng);
    }
  };

  const onClickOpenTrackShipBox = () => {
    setIsOpenTrackBox(!isOpenTrackBox);
  };

  const onChangeColor = (shipUid, color) => {
    setColors(state => ({ ...state, [shipUid]: color }));
    trackingLayer.changeColor(shipUid, color);
  };
  const openTrackingUI = () => {
    if (selectedCaptureMode === null) {
      setVideoTrackingOptions(state => ({ ...state, open: true }));
      trackingLayer.closeAllPopups();
      handleFitShipLocations(currentTrackingShip);
      trackingLayer.clear();
      trackingLayer.show(
        trackingShipsLocationList
          .filter(ship => checkedShips.includes(ship.ship_uid))
          .map(ship => ({
            ...ship,
            locations: customLocationHistory(ship.location_history, ship.ship_uid === trackingShipUid ? 0 : 30),
            color: colors[ship.ship_uid],
          })),
      );
      trackingLayer.closeAllPopups();
    } else {
      setConfirmCancel(true);
    }
  };

  const onCancelVideoTracking = () => {
    setVideoTrackingOptions(state => ({ ...state, open: true }));
    trackingLayer.closeAllPopups();
    captureLayer.clear();
    setSelectedCaptureMode(null);
    handleFitShipLocations(currentTrackingShip);
    trackingLayer.clear();
    trackingLayer.show(
      trackingShipsLocationList
        .filter(ship => checkedShips.includes(ship.ship_uid))
        .map(ship => ({
          ...ship,
          locations: customLocationHistory(ship.location_history, ship.ship_uid === trackingShipUid ? 0 : 30),
          color: colors[ship.ship_uid],
        })),
    );
    trackingLayer.closeAllPopups();
    setConfirmCancel(false);
  };

  const onSendRequestSearchToSGW = () => {
    EventBus.emit(EventConstant.GATEWAY_EVENTS.syncShipInfo, searchText);
  };

  const handleShowAllPositions = () => {
    const mode = selectedCaptureMode === CAPTURE_MODE.all ? null : CAPTURE_MODE.all;
    setSelectedCaptureMode(mode);
    captureLayer.clear();
    if (mode) {
      setVideoTrackingOptions(state => ({ ...state, open: false, play: false }));
      animationTrackingLayer.clear();
      trackingLayer.clear();
      captureLayer.showAll(currentTrackingShip, MAXIMUM_DEFLECTION, MAXIMUM_COURSE_DEVIATION);
      handleFitShipLocations(currentTrackingShip);
    } else {
      trackingLayer.clear();
      trackingLayer.show(
        trackingShipsLocationList
          .filter(ship => checkedShips.includes(ship.ship_uid))
          .map(ship => ({
            ...ship,
            locations: customLocationHistory(ship.location_history, ship.ship_uid === trackingShipUid ? 0 : 30),
            color: colors[ship.ship_uid],
          })),
      );
      trackingLayer.closeAllPopups();
      trackingLayer.trackingShips.current[currentTrackingShip.ship_uid].lastMarker.openPopup();
      handleFitShipLocations(currentTrackingShip);
    }
  };

  const handleShowSpecialPositions = () => {
    const mode = selectedCaptureMode === CAPTURE_MODE.special ? null : CAPTURE_MODE.special;
    captureLayer.clear();
    setSelectedCaptureMode(mode);
    if (mode) {
      setVideoTrackingOptions(state => ({ ...state, open: false, play: false }));
      animationTrackingLayer.clear();
      trackingLayer.clear();
      const bounds = captureLayer.showSpecials(currentTrackingShip, MAXIMUM_DEFLECTION, MAXIMUM_COURSE_DEVIATION);
      if (bounds) {
        onJumpToBounds(bounds);
        console.log(bounds);
        setBoundsCurrent(bounds);
      } else notification.send("Không tồn tại hành trình nổi bật", "info");
    } else {
      trackingLayer.clear();
      trackingLayer.show(
        trackingShipsLocationList
          .filter(ship => checkedShips.includes(ship.ship_uid))
          .map(ship => ({
            ...ship,
            locations: customLocationHistory(ship.location_history, ship.ship_uid === trackingShipUid ? 0 : 30),
            color: colors[ship.ship_uid],
          })),
      );
      trackingLayer.closeAllPopups();
      trackingLayer.trackingShips.current[currentTrackingShip.ship_uid].lastMarker.openPopup();
      handleFitShipLocations(currentTrackingShip);
    }
  };

  const handleTakeScreenShoot = async () => {
    if (boundsCurrent) onJumpToBounds(boundsCurrent);
    await new Promise(resolve => setTimeout(resolve, 500));
    loading.open();
    const url = await captureLayer.capture("map");
    const a = document.createElement("a");
    loading.close();
    a.href = url;
    a.download = "bao-cao-hanh-trinh.jpg";
    a.click();
    a.remove();
  };

  useEffect(() => {
    captureLayer.clear();
    setSelectedCaptureMode(null);
    if (trackingShipsLocationList.length > 0) {
      const colors = {};
      trackingShipsLocationList.forEach(ship => {
        colors[ship.ship_uid] = getRandomShipColor();
      });
      setColors(colors);
      setTrackingShipUid(trackingShipsLocationList[0].ship_uid);
      setVideoTrackingOptions(animationTrackingLayer.initVideoTrackingConfigs(trackingShipsLocationList[0]));
      boundLayerRef.current.clearLayers();
      const points = trackingShipsLocationList[0].location_history.map(point => new L.LatLng(point.lat, point.long));
      boundLayerRef.current.addLayer(L.polyline(points));
      onJumpToBounds(boundLayerRef.current.getBounds());
      trackingLayer.clear();
      trackingLayer.show(
        trackingShipsLocationList
          .filter(ship => checkedShips.includes(ship.ship_uid))
          .map((ship, index) => ({
            ...ship,
            locations: customLocationHistory(ship.location_history, !index ? 0 : 30),
            color: colors[ship.ship_uid],
          })),
      );
      trackingLayer.trackingShips.current[trackingShipsLocationList[0].ship_uid].lastMarker.openPopup();
      selectedShipSearch.forEach(ship => {
        if (!trackingShipsLocationList.map(location => location.ship_uid).includes(ship.uid)) {
          notification.send("Không tìm thấy dữ liệu hành trình của tàu " + ship.name, "info");
        }
      });
    } else {
      setTrackingShipUid("");
      boundLayerRef.current.clearLayers();
      trackingLayer.clear();
    }
  }, [trackingShipsLocationList]);

  useEffect(() => {
    const handleAddTrackingShipData = e => {
      const ship = e.detail;
      const data = { name: ship.ship_name, uid: ship.ship_uid };
      if (!some(selectedShipSearch, data)) {
        setSelectedShipSearch(state => [data, ...state]);
        onClickSearchJourney([data]);
      }
    };

    window.addEventListener(EventConstant.GLOBAL_EVENTS.addTrackingShipData, handleAddTrackingShipData, false);

    return () => {
      window.removeEventListener(EventConstant.GLOBAL_EVENTS.addTrackingShipData, handleAddTrackingShipData, false);
    };
  });

  useEffect(() => {
    if (sidebarMode === "management") {
      setSelectedShipSearch([]);
      setTrackingHistory([]);
      dispatch(ShipActions.shipSet({ trackingShipsLocationList: [] }));
      trackingLayer.clear();
    } else {
      let localShipTrackingHistory = localStorage.getItem(AppConstant.LOCAL_STORAGE_KEY.shipTrackingHistory) || "[]";
      localShipTrackingHistory = JSON.parse(localShipTrackingHistory);
      localShipTrackingHistory = localShipTrackingHistory.map(history => ({ ...history, isSelected: false }));
      setTrackingHistory(localShipTrackingHistory);
    }
  }, [sidebarMode]);

  useEffect(() => {
    if (searchText) {
      dispatch(ShipActions.shipSet({ filterTrackingShipList: { status: "pending", data: [] } }));
      dispatch(ShipActions.requestSearchShips({ filter: searchText, isManageShip: sidebarMode === "management" }));
    } else {
      dispatch(ShipActions.shipSet({ filterTrackingShipList: { status: "idle", data: [] } }));
    }
  }, [searchText]);

  useEffect(() => {
    if (filterTrackingShipList.status !== "idle") {
      setIsOpenSearchDialog(true);
    }
  }, [filterTrackingShipList]);
  const [checkedShips, setCheckedShips] = React.useState([]);

  const handleChangeSelectShips = event => {
    const { name, checked } = event.target;
    if (name === trackingShipUid) return;
    const newCheckedShips = checked ? [...checkedShips, name] : checkedShips.filter(item => item !== name);
    setCheckedShips(newCheckedShips);
    trackingLayer.clear();
    trackingLayer.show(
      trackingShipsLocationList
        .filter(ship => newCheckedShips.includes(ship.ship_uid))
        .map(ship => ({
          ...ship,
          locations: customLocationHistory(ship.location_history, ship.ship_uid === trackingShipUid ? 0 : 30),
          color: colors[ship.ship_uid],
        })),
    );
  };

  return (
    <Box className={clsx(classes.shipMonitoringContainer, { hidden: sidebarMode === "management" })}>
      <Box className={classes.shipJourneyMonitoringBox}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "8px 0" }}>
          <Typography className={classes.shipJourneyMonitoringTitle} onClick={() => onClickOpenTrackShipBox()}>
            {LangConstant.TXT_SHIP_JOURNEY_MONITORING_TITLE}
          </Typography>
          {isOpenTrackBox ? (
            <KeyboardArrowUp className={classes.colorBlack} />
          ) : (
            <KeyboardArrowDown className={classes.colorBlack} />
          )}
        </Box>
        {isOpenTrackBox && (
          <Box>
            <Box className={classes.selectShipMonitoringBox}>
              <Box className={classes.selectShipMonitoringTitleBox}>
                <Typography className={classes.selectShipMonitoringTitle}>Chọn tàu muốn xem hành trình</Typography>
              </Box>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                variant="outlined"
                placeholder={LangConstant.P_INPUT_SEARCH}
                size="small"
                onChange={debounce(e => {
                  onChangeText(e.target.value);
                }, 500)}
                onFocus={onFocusSearchInput}
                autoComplete="off"
                className={classes.searchTextfield}
              />
              <Popover
                open={isOpenSearchDialog}
                anchorEl={searchAnchorEl}
                onClose={onBlurSearchInput}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                disableRestoreFocus={true}
                classes={{ paper: classes.searchDialog }}
                keepMounted={true}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {filterTrackingShipList.status !== "idle" ? (
                  filterTrackingShipList.status === "pending" ? (
                    <>
                      <Box className={classes.dialogNotification}>
                        <Search fontSize="small" color="primary" className={classes.notificationIcon} />
                        <Typography>Đang tìm kiếm kết quả...</Typography>
                      </Box>
                    </>
                  ) : filterTrackingShipList.data.length > 0 ? (
                    <>
                      <Typography paddingBottom={1} variant="h6">
                        Kết quả tìm kiếm:
                      </Typography>
                      <FormGroup classes={{ root: classes.formRow }}>
                        {filterTrackingShipList.data.map(ship => (
                          <FormControlLabel
                            key={ship.uid}
                            control={
                              <Checkbox
                                value={ship.uid}
                                onChange={e => onSelectShip(ship)}
                                checked={some(selectedShipSearch, ship)}
                              />
                            }
                            classes={{
                              root: classes.rowRoot,
                              label: classes.label,
                            }}
                            label={
                              <Box sx={{ display: "flex", alignItems: "center" }}>
                                <SailingOutlined className={classes.notificationIcon} />
                                <Box>
                                  <Typography>{ship.name}</Typography>
                                  <Typography display="block" variant="caption">
                                    {ship.mmsi ? "MMSI: " + ship.mmsi : <></>}{" "}
                                    {"(" + AppConstant.DATA_SOURCES[ship?.source_type] + ")"}
                                  </Typography>
                                </Box>
                              </Box>
                            }
                          />
                        ))}
                      </FormGroup>
                    </>
                  ) : (
                    <>
                      <Typography paddingBottom={1} variant="h6">
                        Kết quả tìm kiếm:
                      </Typography>
                      <Typography variant="caption" display="flex" alignItems="center" justifyContent="space-between">
                        Không có kết quả tìm kiếm
                        {AppConstant.CURRENT_SYSTEM_MODE === SystemConstant.SYSTEM_MODE.WAN && (
                          <IconButton
                            onClick={onSendRequestSearchToSGW}
                            sx={{ justifyContent: "flex-start" }}
                            fullWidth
                            title={LangConstant.TXT_SEND_REQUEST_TO_SGW}
                          >
                            <Cached />
                          </IconButton>
                        )}
                      </Typography>
                    </>
                  )
                ) : (
                  <></>
                )}
              </Popover>
              {selectedShipSearch.length > 0 && (
                <>
                  <Typography variant="body1" marginBottom={1}>
                    Các tàu đã chọn:
                  </Typography>
                  <FormGroup
                    classes={{
                      root: classes.shipListBox,
                    }}
                  >
                    {selectedShipSearch.map((ship, index) => (
                      <Box className={classes.selectedShip} key={index}>
                        <Box display="flex" justifyContent="flex-start" alignItems="center">
                          <Sailing className={classes.notificationIcon} fontSize="small" />
                          <Typography variant="body1">{ship.name}</Typography>
                        </Box>
                        <IconButton size="small" onClick={e => onDeleteSelectedShip(ship)}>
                          <DeleteOutline className={classes.deleteShipButton} fontSize="small" />
                        </IconButton>
                      </Box>
                    ))}
                  </FormGroup>
                  <hr />
                </>
              )}
              <Typography>{LangConstant.TXT_SHIP_LIST_RECENT}</Typography>
              <ShipList
                data={trackingHistory.filter(
                  history => !selectedShipSearch.map(ship => ship.uid).includes(history.uid),
                )}
                onChange={onSelectHistoryShip}
              />
            </Box>
            {/*TODO: Hiển thị danh sách tàu giám sát*/}
            <Box className={classes.shipListMonitoringBox}>
              <Box className={classes.shipListMonitoringTitleBox}>
                <Typography className={classes.shipListMonitoringTitle}>
                  {LangConstant.TXT_SHIP_LIST_MONITORING_TITLE}
                </Typography>
                <MonitoringShip setSelectedGroupShips={setSelectedGroupShips} />
              </Box>
            </Box>
            <Box className={classes.chooseTimeContainer}>
              <Box className={classes.chooseTimeTitleBox}>
                <Typography className={classes.chooseTimeTitle}>{LangConstant.TXT_CHOOSE_TIME}</Typography>
              </Box>
              <Box className={classes.chooseTimeBox}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    label={LangConstant.L_FROM_DAY}
                    value={fromDay}
                    onChange={onChangeFromDay}
                    renderInput={params => <TextField {...params} />}
                    className={classes.dateTimePickerBox}
                    inputFormat={FormatConstant.FM_DD_MM_YYY_HH_MM}
                  />
                  <DateTimePicker
                    label={LangConstant.L_TO_DAY}
                    value={toDay}
                    onChange={onChangeToDay}
                    renderInput={params => <TextField {...params} />}
                    className={classes.dateTimePickerBox}
                    inputFormat={FormatConstant.FM_DD_MM_YYY_HH_MM}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            <Button variant="contained" size="large" fullWidth onClick={onClickSearchJourney}>
              Tra cứu hành trình
            </Button>
          </Box>
        )}
      </Box>
      {trackingShipsLocationList.length > 0 && trackingShipUid && (
        <Box className={classes.coordinatesListBox}>
          <Button variant="outlined" size="large" onClick={handleOpenNearAreaDialog} fullWidth>
            Xem chi tiết khu vực lân cận
          </Button>
          <NearAreaDialog
            open={isOpenNearAreaDialog}
            onClose={handleCloseNearAreaDialog}
            ships={trackingShipsLocationList}
          />
          <Typography className={classes.coordinatesList}>Xem hành trình tàu</Typography>
          <FormGroup>
            {trackingShipsLocationList?.map((ship, index) => (
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedShips?.includes(ship.ship_uid)}
                      onChange={handleChangeSelectShips}
                      name={ship.ship_uid}
                    />
                  }
                  label={ship.ship_name}
                  key={index}
                />
                <Box className={classes.selectColorShip}>
                  <ColorSketchPicker
                    defaultColor={colors[ship.ship_uid]}
                    onChangeComplete={color => onChangeColor(ship.ship_uid, color)}
                    stylesPopover={{ right: 0 }}
                  />
                </Box>
              </Box>
            ))}
          </FormGroup>

          <Box>
            <Box className={classes.selectShipName}>
              <Typography className={classes.shipName}>Tên tàu: </Typography>
              <FormControl fullWidth>
                <Select
                  fullWidth
                  onChange={handleChangeTrackingShip}
                  value={trackingShipUid}
                  size="small"
                  variant="standard"
                >
                  {trackingShipsLocationList
                    .filter(ship => checkedShips.includes(ship.ship_uid))
                    ?.map(ship => (
                      <MenuItem value={ship.ship_uid} key={ship.ship_uid}>
                        {ship.ship_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <IconButton
                size="small"
                title="Xem hành trình"
                disabled={videoTrackingOptions.open}
                onClick={openTrackingUI}
              >
                <Videocam fontSize="small" />
              </IconButton>
            </Box>

            <Box className={classes.tableContainer}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD_COLUMNS.map(column => (
                        <TableCell key={column.id} align={column.align} style={{ top: 57, minWidth: column.minWidth }}>
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentTrackingShipDefault &&
                      currentTrackingShipDefault.location_history.map((locationData, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">{convertMillisecondsToDateTime(locationData.time)}</TableCell>
                            <TableCell
                              align="center"
                              onClick={() => {
                                onJumpToLocation(
                                  locationData,
                                  currentTrackingShip.ship_uid,
                                  currentTrackingShipDefault.location_history?.length - index - 1,
                                );
                              }}
                              style={{ cursor: "pointer", color: "blue" }}
                              title="Xem thông tin vị trí"
                            >
                              {convertCoordinates(
                                Number(locationData.lat).toFixed(5),
                                Number(locationData.long).toFixed(5),
                              )}
                            </TableCell>
                            <TableCell align="center">{locationData.speed} knot</TableCell>
                            <TableCell align="center">{locationData.course}°</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box className={classes.boundCaptureButtonContainer}>
              <Button
                className={classes.boundCaptureButton}
                variant={selectedCaptureMode === CAPTURE_MODE.all ? "contained" : "outlined"}
                onClick={handleShowAllPositions}
              >
                Xem hành trình tổng quát
              </Button>
              <IconButton
                size="small"
                color="primary"
                disabled={selectedCaptureMode !== CAPTURE_MODE.all}
                onClick={handleTakeScreenShoot}
                title="Tải hành trình tổng quát về thiết bị"
              >
                <Download fontSize="small" />
              </IconButton>
            </Box>
            <Box className={classes.boundCaptureButtonContainer}>
              <Button
                className={classes.boundCaptureButton}
                variant={selectedCaptureMode === CAPTURE_MODE.special ? "contained" : "outlined"}
                onClick={handleShowSpecialPositions}
              >
                Xem hành trình nổi bật
              </Button>
              <IconButton
                size="small"
                color="primary"
                disabled={selectedCaptureMode !== CAPTURE_MODE.special}
                onClick={handleTakeScreenShoot}
                title="Tải hành trình nổi bật về thiết bị"
              >
                <Download fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      )}
      <ConfirmDialog
        open={confirmCancel}
        onClose={() => setConfirmCancel(false)}
        onConfirm={() => onCancelVideoTracking()}
        title=""
        message={`Bạn có muốn thoát chức năng xem báo cáo hành trình không?`}
      />
    </Box>
  );
}

const TABLE_HEAD_COLUMNS = [
  { id: "index", label: "STT", minWidth: 20, align: "center" },
  { id: "timestamp", label: "Thời gian", minWidth: 90, align: "center" },
  { id: "location", label: "Vị trí", minWidth: 90, align: "center" },
  { id: "speed", label: "Vận tốc", minWidth: 50, align: "center" },
  { id: "direction", label: "Hướng", minWidth: 50, align: "center" },
];

const CAPTURE_MODE = {
  all: "all",
  special: "special",
};

const MAXIMUM_DEFLECTION = 45;

const MAXIMUM_COURSE_DEVIATION = 115;

const useStyles = makeStyles(theme => ({
  shipMonitoringContainer: {
    width: "inherit",
    height: "calc(100vh - 60px)",
    background: "#fff",
    overflowY: "auto",
    boxSizing: "border-box",
    padding: "8px",
  },

  shipJourneyMonitoringTitle: {
    fontSize: 20,
    fontWeight: 600,
    cursor: "pointer",
  },

  shipJourneyMonitoringBox: {
    fontSize: 20,
    fontWeight: 500,
    boxSizing: "border-box",
    borderRadius: 2,
    marginBottom: 12,
  },

  selectShipMonitoringBox: {
    borderTop: "1px solid #999",
    padding: "8px 0",
  },

  coordinatesList: {
    fontSize: 20,
    fontWeight: 600,
    boxSizing: "border-box",
    borderBottom: "1px solid #999",
    padding: "8px 0",
    marginTop: "16px",
  },

  selectShipMonitoringTitle: {
    padding: "5px 0",
    fontSize: 19,
    fontWeight: 500,
  },

  quantityShipTitle: {
    fontSize: 13,
    fontWeight: 400,
  },

  shipListMonitoringBox: {
    borderTop: "1px solid #999",
    padding: "8px 0",
    boxSizing: "border-box",
  },

  shipListMonitoringTitle: {
    padding: "5px 0",
    fontSize: 19,
    fontWeight: 500,
  },

  chooseTimeTitle: {
    fontSize: 19,
    fontWeight: 500,
  },

  chooseTimeContainer: {
    borderTop: "1px solid #999",
    padding: "8px 0",
    boxSizing: "border-box",
  },

  chooseTimeBox: {
    marginTop: 12,
  },

  buttonWrapper: {
    marginTop: 0,
    "& button": {
      marginTop: 8,
    },
  },

  dateTimePickerBox: {
    marginBottom: 16,
    width: "100%",
  },

  selectedShip: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  deleteShipButton: {
    cursor: "pointer",
  },

  notificationIcon: {
    marginRight: 8,
  },

  searchDialog: {
    marginTop: 4,
    marginLeft: "-8px",
    width: "343px",
    padding: 8,
  },

  dialogNotification: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  label: {
    userSelect: "none",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },

  formRow: {
    "& > label": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
  },

  selectShipName: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: 8,
    marginTop: 8,
    gap: "8px",
  },

  selectColorShip: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: 8,
    marginTop: 8,
    "&>button": {
      minWidth: 20,
    },
  },

  shipName: {
    fontSize: 14,
    flexShrink: 0,
  },

  colorShip: {
    fontSize: 14,
    marginRight: 8,
  },

  tableContainer: {
    height: "100%",
    maxHeight: 400,
    overflowY: "scroll",
    width: "100%",
    borderTop: "1px solid #ddd",
    borderBottom: "1px solid #ddd",
    "& th": {
      padding: 2,
      border: "1px solid #ddd",
      fontSize: "13px",
    },
    "& td": {
      padding: 2,
      border: "1px solid #ddd",
      fontSize: "13px",
    },
  },

  coordinatesListBox: {
    fontSize: 20,
    fontWeight: 500,
    boxSizing: "border-box",
    borderRadius: 2,
  },

  colorBox: {
    position: "absolute",
    zIndex: 10000,
    display: "flex",
    "& .twitter-picker div:nth-child(1)": {
      borderColor: "transparent !important",
    },
    "& .twitter-picker div:nth-child(2)": {
      borderColor: "transparent !important",
    },
  },

  searchTextfield: {
    marginBottom: "8px",
  },

  boundCaptureButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "16px",
    marginTop: "8px",
  },

  boundCaptureButton: {
    flex: "1",
  },
}));
