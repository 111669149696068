import React, { useState } from "react";
import clsx from "clsx";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import { AppConstant, LangConstant } from "const";
import SeaArea from "./SeaArea";
import DrawArea from "./DrawArea";

export default function FilterByArea() {
  const classes = useStyles();
  const [chooseType, setChooseType] = useState([]);

  const onClickOpen = id => {
    if (chooseType.includes(parseInt(id))) {
      const temp = chooseType.filter(type => type !== id);
      setChooseType(temp);
    } else {
      setChooseType(prev => [...prev, id]);
    }
  };

  return (
    <>
      <Box className={classes.seaAreaBox} onClick={() => onClickOpen(AppConstant.FILTER_SHIP_BY_SEA_AREA_ID)}>
        <Typography className={classes.seaAreaTitle}>{LangConstant.TXT_SEA_AREA}</Typography>
        <Box>
          {chooseType.includes(parseInt(AppConstant.FILTER_SHIP_BY_SEA_AREA_ID)) ? (
            <KeyboardArrowUp className={classes.colorBlack} />
          ) : (
            <KeyboardArrowDown className={classes.colorBlack} />
          )}
        </Box>
      </Box>
      <Box
        className={clsx(classes.optionContainer, {
          [classes.optionContainerHidden]: !chooseType.includes(parseInt(AppConstant.FILTER_SHIP_BY_SEA_AREA_ID)),
        })}
      >
        <SeaArea />
      </Box>
      <Box className={classes.drawAreaBox} onClick={() => onClickOpen(AppConstant.FILTER_SHIP_BY_DRAW_AREA_ID)}>
        <Typography className={classes.drawAreaTitle}>{LangConstant.TXT_DRAW_AREA}</Typography>
        <Box>
          {chooseType.includes(parseInt(AppConstant.FILTER_SHIP_BY_DRAW_AREA_ID)) ? (
            <KeyboardArrowUp className={classes.colorBlack} />
          ) : (
            <KeyboardArrowDown className={classes.colorBlack} />
          )}
        </Box>
      </Box>
      <Box
        className={clsx(classes.optionContainer, {
          [classes.optionContainerHidden]: !chooseType.includes(parseInt(AppConstant.FILTER_SHIP_BY_DRAW_AREA_ID)),
        })}
      >
        <DrawArea />
      </Box>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  seaAreaBox: {
    display: "flex",
    justifyContent: "space-between",
    "&:hover": {
      cursor: "pointer",
    },
  },

  drawAreaBox: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 8,
    "&:hover": {
      cursor: "pointer",
    },
  },

  seaAreaTitle: {
    fontSize: "16px",
    color: "#000",
    paddingLeft: 16,
    userSelect: "none",
  },

  drawAreaTitle: {
    fontSize: "16px",
    color: "#000",
    paddingLeft: 16,
    userSelect: "none",
  },

  colorBlack: {
    color: "#000",
  },

  optionContainer: {
    width: "100%",
    height: "100%",
    display: "block",
  },

  optionContainerHidden: {
    display: "none",
  },
}));
