import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import * as WebSocketCustom from "web-socket";
import { ApiConstant, EventConstant } from "const";
import ShipActions from "redux-store/ship.redux";
import { useNotificationQueue } from "hooks";
import EventWarningAction from "redux-store/eventwarning.redux";
import FolderAction from "redux-store/folder.redux";
import sound from "theme/sounds/notification-sound.mp3";

const NotificationDialog = () => {
  const dispatch = useDispatch();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const shipErrors = useSelector(state => state.shipRedux.errors);

  const [notification, setNotification] = useState(null);
  const { notificationQueue, setNotificationQueue } = useNotificationQueue();

  const action = snackbarId => (
    <IconButton
      onClick={() => {
        dispatch(ShipActions.shipSet({ errors: null }));
        closeSnackbar(snackbarId);
      }}
      size="small"
    >
      <Close fontSize="small" htmlColor="#ddd" />
    </IconButton>
  );

  const handleReceiveNotification = e => {
    const { message, type } = e.detail;
    setNotification({ message: message, type: type });
  };

  useEffect(() => {
    if (shipErrors) {
      if (shipErrors.status) {
        let status = shipErrors.status;
        if (status === ApiConstant.STT_NOT_FOUND && shipErrors.config.url === ApiConstant.TRACKING_SHIPS) {
          setNotification({ message: "Không tìm thấy dữ liệu của tàu", type: "error" });
        } else {
          setNotification({ message: "Có lỗi đã xảy ra. Vui lòng thử lại sau.", type: "error" });
        }
      } else {
        if (shipErrors.problem && shipErrors.problem === "TIMEOUT_ERROR") {
          setNotification({ message: "Máy chủ mất nhiều thời gian để phản hồi. Vui lòng thử lại sau.", type: "error" });
        } else if (shipErrors.problem && shipErrors.problem === "NETWORK_ERROR") {
          setNotification({ message: "Đường truyền mạng không ổn định.", type: "error" });
        } else if (shipErrors.message) {
          setNotification({ message: shipErrors.message, type: "error" });
        }
      }
    }
  }, [shipErrors]);

  const audio = new Audio(sound);

  useEffect(() => {
    WebSocketCustom.onConnected(({ notification, type, subscribe, cause }) => {
      if (notification && type) {
        if (subscribe === "event_warning") {
          if (cause) {
            audio.play();
            addNotification({ message: notification, type: "error", subscribe: subscribe });
            dispatch(EventWarningAction.eventWarningUpdateIsNew(1));
          } else {
            addNotification({ message: notification, type: type, subscribe: subscribe });
            dispatch(FolderAction.folderUpdateIsNew(1));
          }
        }
      }
    });
  }, []);

  const addNotification = newNotification => {
    setNotificationQueue(oldQueue => [...oldQueue, newNotification]);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (notificationQueue?.length > 0) {
        const [firstNotification, ...remainingNotifications] = notificationQueue;
        enqueueSnackbar(firstNotification.message, { variant: firstNotification.type, action: action });
        setNotificationQueue(remainingNotifications);
      }
    }, 500);

    return () => clearInterval(intervalId);
  }, [notificationQueue, enqueueSnackbar]);

  useEffect(() => {
    window.addEventListener(EventConstant.GLOBAL_EVENTS.appendNotification, handleReceiveNotification);
    return () => {
      window.removeEventListener(EventConstant.GLOBAL_EVENTS.appendNotification, handleReceiveNotification);
    };
  });

  useEffect(() => {
    if (notification) {
      enqueueSnackbar(notification.message, { variant: notification.type, action: action });
    }
  }, [notification]);

  return <></>;
};

export default NotificationDialog;
