import { call, put } from "redux-saga/effects";
import PortActions from "redux-store/port.redux";
import { ApiConstant } from "const";
import { toCamel } from "utils";
import { PortService } from "services";

export function* getPorts() {
  try {
    let response = yield call(PortService.getPorts);

    if (response.status === ApiConstant.STT_OK && response.data.data) {
      yield put(
        PortActions.portSet({
          ports: toCamel(response.data.data),
          isFetching: false,
        }),
      );
    } else {
      yield put(
        PortActions.portSet({
          error: response,
        }),
      );
    }
  } catch (error) {
    yield put(
      PortActions.portSet({
        error: error,
      }),
    );
  }
}

export function* deletePorts(action) {
  try {
    let { data } = action;
    let response = yield call(PortService.deletePorts, data);

    if (response.status === ApiConstant.STT_OK) {
      yield put(
        PortActions.portSet({
          isDeleteSuccess: true,
        }),
      );
    } else {
      yield put(
        PortActions.portSet({
          isDeleteSuccess: false,
          error: response,
        }),
      );
    }
  } catch (error) {
    yield put(
      PortActions.portSet({
        isDeleteSuccess: false,
        error: error,
      }),
    );
  }
}

export function* updatePort(action) {
  try {
    let { data } = action;
    let response = yield call(PortService.updatePort, data);

    if (response.status === ApiConstant.STT_OK) {
      yield put(
        PortActions.portSet({
          isUpdatePortSuccess: true,
        }),
      );
    } else {
      yield put(
        PortActions.portSet({
          isUpdatePortSuccess: false,
          error: response,
        }),
      );
    }
  } catch (error) {
    yield put(
      PortActions.portSet({
        isUpdatePortSuccess: false,
        error: error,
      }),
    );
  }
}
