import React, { useEffect, useState } from "react";
import { AppConstant } from "const";
import "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";
const ReportDoughnutChart = () => {
  const shipLostChartData = useSelector(state => state.shipRedux.shipLostChartData);

  const [dataChart, setDataChart] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    const dataSum = Object.keys({ ...shipLostChartData.internalSea, ...shipLostChartData.vnSea }).reduce(
      (accumulator, currentValue) => {
        accumulator[currentValue] =
          (shipLostChartData.internalSea[currentValue] || 0) + (shipLostChartData.vnSea[currentValue] || 0);
        return accumulator;
      },
      {},
    );

    const dataTmp = {
      labels: Object.values(AppConstant.SHIP_LENGTH_TYPE),
      datasets: [
        {
          label: "",
          data: Object.keys(AppConstant.SHIP_LENGTH_TYPE).map(item => dataSum[item]),
          backgroundColor: Object.keys(AppConstant.SHIP_LENGTH_TYPE).map(
            item => AppConstant.COLOR_REPORT_LOST_SHIP[item],
          ),
        },
      ],
    };
    setDataChart(dataTmp);
  }, [shipLostChartData]);

  return <Doughnut data={dataChart} options={options} />;
};

export default ReportDoughnutChart;

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "right",
      maxWidth: 220,
      labels: {
        usePointStyle: true,
        boxWidth: 50,
      },
    },
  },
};
