import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import SecondSubMenu from "./SecondSubMenu";

export default function SubMenu({ items }) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [idChoose, setIdChoose] = useState(null);

  const onClickOpen = id => {
    setIsOpen(prev => !prev);
    setIdChoose(id);
  };

  const onClickOpenChildren = id => {
    setIdChoose(id);
  };

  const onCloseChildrenComponent = () => {
    setIdChoose(null);
  };

  return (
    <Box className={classes.subMenuBox}>
      {items &&
        items.map((item, index) => (
          <Box key={index}>
            {item.submenu
              ? item.isShow && (
                  <Button
                    variant="outlined"
                    startIcon={item.icon}
                    endIcon={isOpen && idChoose === item.id ? <ChevronLeft /> : <ChevronRight />}
                    onClick={() => onClickOpen(item.id)}
                    fullWidth={true}
                    classes={{
                      root: classes.button,
                      startIcon: classes.startIconBox,
                      endIcon: classes.endIconBox,
                    }}
                  >
                    <Typography variant="caption" className={classes.buttonTitle}>
                      {item.title}
                    </Typography>
                  </Button>
                )
              : item.isShow && (
                  <Button
                    variant="outlined"
                    fullWidth={true}
                    classes={{
                      root: classes.button,
                      startIcon: classes.startIconBox,
                    }}
                    onClick={() => onClickOpenChildren(item.id)}
                  >
                    <Typography variant="caption" className={classes.buttonTitle}>
                      {item.title}
                    </Typography>
                  </Button>
                )}
            {isOpen && item.submenu && idChoose === item.id && <SecondSubMenu items={item.submenu} />}
            {idChoose === item.id && <item.Children open={true} onClose={onCloseChildrenComponent} />}
          </Box>
        ))}
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  subMenuBox: {
    position: "absolute",
    top: 48,
    width: 400,
    background: "#fff",
    marginTop: "2px",
    zIndex: 1000,
    padding: "8px",
    borderRadius: "4px",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;",
  },

  buttonTitle: {
    flex: 2,
    textAlign: "left",
    fontSize: 14,
    fontWeight: "500",
    color: "#000",
  },

  button: {
    justifyContent: "flex-start",
    margin: "4px 0px",
  },

  startIconBox: {
    color: "#000",
  },

  endIconBox: {
    color: "#000",
  },

  containerSlect: {
    padding: "10px 10px ",
  },

  containerTitle: {
    fontSize: 13,
    color: "#000000",
    padding: "10px 15px ",
  },
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderRadius: 10,
    },
  },
  centerButton: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10px 0 ",
  },

  flexfooterDialog: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    padding: "0 10px",
  },

  paddingButton: {
    paddingRight: 10,
  },

  flexTitle: {
    display: "flex",
    justifyContent: "space-between",
  },
}));
