import { useCallback } from "react";
import L from "leaflet";
import { useMapContext } from "./useMapContext";
import { EventBus } from "EventBus";
import { EventConstant } from "const";

export function useFlickerMarker(flickerLayerRef) {
  const { setClickedShip, setShowMenuLayer } = useMapContext();
  const createFlickerMarker = useCallback(
    ship => {
      flickerLayerRef.current.clearLayers();
      const flickerMarker = L.marker([ship.ship_lat, ship.ship_long], {
        icon: L.divIcon({
          className: "div-icon leaflet-tracking-div-icon z-index-10001",
          html: `
          <div>
          <svg fill="#00000080" height="28px" width="28px" style="position: absolute; top:-8px; left:-8px;" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-51.2 -51.2 614.40 614.40" xml:space="preserve" stroke="#000000" stroke-width="0.00512" transform="rotate(90)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#000000" stroke-width="1.168000000000001"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M256,0C114.84,0,0,114.842,0,256s114.84,256,256,256s256-114.842,256-256S397.16,0,256,0z M256,462.452 c-113.837,0-206.452-92.614-206.452-206.452S142.163,49.548,256,49.548S462.452,142.163,462.452,256S369.837,462.452,256,462.452z "></path> </g> </g> <g> <g> <path d="M256,132.129c-68.302,0-123.871,55.569-123.871,123.871S187.698,379.871,256,379.871S379.871,324.302,379.871,256 S324.302,132.129,256,132.129z M256,330.323c-40.983,0-74.323-33.341-74.323-74.323c0-40.982,33.339-74.323,74.323-74.323 s74.323,33.341,74.323,74.323C330.323,296.981,296.983,330.323,256,330.323z"></path> </g> </g> </g></svg>
          <div class="loader">
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="circle"></div>
          </div>
        `,
        }),
      });
      flickerMarker.on("click", function (e) {
        EventBus.emit(EventConstant.POINT_EVENTS.clickMeasure, e);
        setClickedShip({ event: e.originalEvent, info: ship });
      });
      flickerMarker.on("contextmenu", function (e) {
        setShowMenuLayer({ event: e, info: ship });
      });

      flickerLayerRef.current.addLayer(flickerMarker);
    },
    [flickerLayerRef],
  );

  return { createFlickerMarker };
}
