import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";

const ConfirmDialog = ({ open, onClose, onConfirm, title, message }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onConfirm} color="primary" autoFocus>
        Đồng ý
      </Button>
      <Button onClick={onClose} color="primary">
        Hủy
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmDialog;
