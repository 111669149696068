import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppConstant } from "const";
import ShipActions from "redux-store/ship.redux";
import SliderCapacity from "./SliderCapacity";

export default function FilterByLength() {
  const dispatch = useDispatch();

  const shipConditions = useSelector(state => state.shipRedux.shipConditions);

  const onChangeData = (min, max) => {
    dispatch(
      ShipActions.shipSet({
        isChangingFilter: true,
        shipConditions: Object.assign(shipConditions, { length_min: min, length_max: max }),
      }),
    );
  };

  return <SliderCapacity onChange={onChangeData} data={AppConstant.SHIP_CAPACITY} />;
}
