import React, { useEffect, useMemo, useState } from "react";
import getCountries from "react-select-country-list";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Close, Delete, Edit } from "@mui/icons-material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { LangConstant, FormatConstant, AppConstant, EventConstant } from "const";
import { convertJson2Xlsx, deepCloneJsonObject } from "utils";
import { convertMillisecondsToDateTime, getEndOfDay, getTimestamp } from "utils/date.utils";
import ConversationActions from "redux-store/conversation.redux";
import ReportActions from "redux-store/report.redux";
import ShipActions from "redux-store/ship.redux";
import EditViolationShip from "./EditViolationShip";
import AddViolationShip from "./AddViolationShip";
import { handleSendNotiInSaga } from "utils";
import ConfirmDialog from "components/ConfirmDialog";

const ViolateShipReport = ({ open, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const countries = useMemo(getCountries, []);
  const id = "violate-ship-report-container";

  const shipTypes = useSelector(state => state.shipRedux.shipTypes);
  const violationShipNumber = useSelector(state => state.shipRedux.violationShipNumber);
  const isDeleteViolationShipSuccess = useSelector(state => state.shipRedux.isDeleteViolationShipSuccess);
  const isEditViolationShipSuccess = useSelector(state => state.shipRedux.isEditViolationShipSuccess);
  const violateReportData = useSelector(state => state.reportRedux.violateReportData);
  const isFetchingReport = useSelector(state => state.reportRedux.isFetchingReport);
  const groupList = useSelector(state => state.conversationRedux.getGroupsucsess);
  const addGroupShip = useSelector(state => state.conversationRedux.addGroupShip);
  const errCreateGroupShips = useSelector(state => state.conversationRedux.errCreateGroupShips);

  const [selectedCountry, setSelectedCountry] = useState(ALL_VALUE);
  const [selectedShipType, setSelectedShipType] = useState(DEFAULT_SHIP_TYPE.type);
  const [userType, setUserType] = useState(AppConstant.USER_TYPE.user.key);
  const [searchUserType, setSearchUserType] = useState(AppConstant.USER_TYPE.user.key);
  const [pagination, setPagination] = useState({ total: 1, page: 1 });
  const [data, setData] = useState({});
  const [paginationData, setPaginationData] = useState({});
  const [isInit, setIsInit] = useState(true);
  const [isOpenAddViolationShipDialog, setIsOpenAddViolationShipDialog] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedViolationShip, setSelectedViolationShip] = useState(null);
  const [selectedShip, setSelectedShip] = useState(DEFAULT_SHIP_TYPE); // TODO: Need to replace selectedShip by selectedShipType
  const [checkedCheckbox, setCheckedCheckbox] = useState([]);
  const [monitoringReason, setMonitoringReason] = useState("");
  const [isOpenAddShipToGroup, setIsOpenAddShipToGroup] = useState(false);
  const [selectedShipGroup, setSelectedShipGroup] = useState("");
  const [filterDatetime, setFilterDatetime] = useState({
    start: dayjs().startOf("month").valueOf(),
    end: getEndOfDay() * 1000,
  });
  const [confirmOpenDelete, setConfirmOpenDelete] = useState(false);
  const onClickCheckBox = id => {
    setCheckedCheckbox(pre => (pre.includes(id) ? pre.filter(item => item !== id) : [...pre, id]));
  };

  const onChangeUserType = e => {
    setUserType(e.target.value);
  };

  const onChangeCountry = e => {
    setSelectedCountry(e.target.value);
  };

  const onChangeShipType = e => {
    const value = parseInt(e.target.value);
    const selectedShipType = value === -1 ? DEFAULT_SHIP_TYPE : shipTypes.find(shipType => shipType.type === value);
    setSelectedShipType(selectedShipType.type);
    setSelectedShip(selectedShipType);
  };

  const onChangePage = (e, value) => {
    setPagination(state => ({ ...state, page: value }));
  };

  const onSubmit = e => {
    e.preventDefault();
    setPagination(state => ({ ...state, page: 1 }));
    setIsInit(false);
    setSearchUserType(userType);
    dispatch(ReportActions.reportSet({ violateReportData: {} }));
    dispatch(
      ReportActions.getReportByType({ countryCode: selectedCountry, shipType: selectedShipType, type: userType }),
    );
  };

  const onExportReport = () => {
    const handleData = Object.values(violateReportData)
      .sort((a, b) => {
        return b.created - a.created;
      })
      .filter(ship => {
        const name = ship.ship_name.toLowerCase() || "";
        const sign = ship.call_sign || "";
        const imo = ship.IMO || "";
        const mmsi = ship.mmsi_code || "";
        const isMatchingSearchValue =
          name.includes(searchText.toLowerCase() || searchText) ||
          sign.includes(searchText.toLowerCase() || searchText) ||
          imo.includes(searchText) ||
          mmsi.includes(searchText);
        const isMatchingShipType = selectedShipType < 0 || isEqualString(ship.ship_type, selectedShip.name);
        const isMatchingCountry = selectedCountry === ALL_VALUE || isEqualString(ship.country_code, selectedCountry);
        const isMatchingTime = ship.created >= filterDatetime.start && ship.created <= filterDatetime.end;
        return isMatchingSearchValue && isMatchingShipType && isMatchingCountry && isMatchingTime;
      })
      .map(data => ({
        "Tên tàu": data.ship_name,
        "Hô hiệu": data.call_sign,
        IMO: data.IMO,
        MMSI: data.mmsi_code,
        "Loại tàu": data.ship_type,
        "Lý do vi phạm": data.note,
        "Vị trí": getLastLocation(data.location_list).join(" - "),
        "Trạng thái": Object.values(AppConstant.VIOLATION_STATUS).find(status => status.key === data.status).title,
        "Cấp độ vi phạm": data.violation_level,
        "Quốc tịch": data.country_code,
        "Ngày tạo": convertMillisecondsToDateTime(data.created),
      }));

    convertJson2Xlsx(handleData, "DANH SÁCH TÀU CÓ NGUY CƠ VI PHẠM");
  };

  const onCloseDialog = () => {
    dispatch(ReportActions.reportSet({ violateReportData: {} }));
    setPagination(state => ({ ...state, page: 1 }));
    setIsInit(true);
    onClose();
  };

  const onOpenAddViolationShipDialog = () => {
    setIsOpenAddViolationShipDialog(true);
  };

  const onCloseAddViolationShipDialog = () => {
    setIsOpenAddViolationShipDialog(false);
  };

  const onChangeSearchText = e => {
    let value = e.target.value;
    setSearchText(value);
  };

  const onDeleteViolationShip = ship => {
    dispatch(ShipActions.requestDeleteViolationShip({ uid: ship.uid }));
    setConfirmOpenDelete(false);
  };

  const onEditViolationShip = ship => {
    setSelectedViolationShip(deepCloneJsonObject(ship));
  };

  const onCloseEditViolationShipDialog = () => {
    setSelectedViolationShip(null);
  };

  const onChangeStartDay = value => {
    setFilterDatetime(state => ({ ...state, start: getTimestamp(value) * 1000 }));
  };

  const onChangeEndDay = value => {
    setFilterDatetime(state => ({ ...state, end: getTimestamp(value) * 1000 }));
  };

  const onClickAddShipToGroup = () => {
    dispatch(
      ConversationActions.updateGroupShip({
        uid: selectedShipGroup,
        ship_note: monitoringReason,
        ships: checkedCheckbox,
      }),
    );
  };

  useEffect(() => {
    const dataArr = Object.values(data)
      .sort((a, b) => {
        return b.created - a.created;
      })
      .filter(ship => {
        const name = ship.ship_name.toLowerCase() || "";
        const sign = ship.call_sign || "";
        const imo = ship.IMO || "";
        const mmsi = ship.mmsi_code || "";
        const isMatchingSearchValue =
          name.includes(searchText.toLowerCase() || searchText) ||
          sign.includes(searchText.toLowerCase() || searchText) ||
          imo.includes(searchText) ||
          mmsi.includes(searchText);
        const isMatchingShipType = selectedShipType < 0 || isEqualString(ship.ship_type, selectedShip.name);
        const isMatchingCountry = selectedCountry === ALL_VALUE || isEqualString(ship.country_code, selectedCountry);
        const isMatchingTime = ship.created >= filterDatetime.start && ship.created <= filterDatetime.end;
        return isMatchingSearchValue && isMatchingShipType && isMatchingCountry && isMatchingTime;
      });

    const totalPages = Math.ceil(dataArr.length / PAGE_SIZE);
    const dataPagination = {};

    for (let i = 0; i < totalPages; i++) {
      dataPagination[i + 1] = dataArr.slice(i * PAGE_SIZE, (i + 1) * PAGE_SIZE);
    }

    setPaginationData(dataPagination);
    setPagination(state => ({ ...state, page: 1, total: totalPages }));
  }, [data, searchText, selectedCountry, selectedShipType, filterDatetime]);

  useEffect(() => {
    setData(deepCloneJsonObject(violateReportData));
  }, [violateReportData]);

  useEffect(() => {
    if (violationShipNumber !== null) {
      setPagination(state => ({ ...state, page: 1 }));
      setIsInit(false);
      setSearchUserType(AppConstant.USER_TYPE.user);
      dispatch(ReportActions.reportSet({ violateReportData: {} }));
      dispatch(
        ReportActions.getReportByType({
          countryCode: selectedCountry,
          shipType: selectedShipType,
          type: AppConstant.USER_TYPE.user.key,
        }),
      );
    }
  }, [violationShipNumber]);

  useEffect(() => {
    if (isDeleteViolationShipSuccess) {
      setPagination(state => ({ ...state, page: 1 }));
      setIsInit(false);
      setSearchUserType(AppConstant.USER_TYPE.user);
      dispatch(ReportActions.reportSet({ violateReportData: {} }));
      dispatch(
        ReportActions.getReportByType({
          countryCode: selectedCountry,
          shipType: selectedShipType,
          type: AppConstant.USER_TYPE.user.key,
        }),
      );
      dispatch(ShipActions.shipSet({ isDeleteViolationShipSuccess: false }));
      handleSendNotiInSaga(LangConstant.MS_DELETE_SUCCESS, "success");
    }
  }, [isDeleteViolationShipSuccess]);

  useEffect(() => {
    if (isEditViolationShipSuccess) {
      setPagination(state => ({ ...state, page: 1 }));
      setIsInit(false);
      // setSearchUserType(AppConstant.USER_TYPE.user);
      dispatch(ReportActions.reportSet({ violateReportData: {} }));
      dispatch(
        ReportActions.getReportByType({
          countryCode: selectedCountry,
          shipType: selectedShipType,
          type: userType,
        }),
      );
      dispatch(ShipActions.shipSet({ isEditViolationShipSuccess: false }));
      handleSendNotiInSaga(LangConstant.MS_UPDATE_SUCCESS, "success");
    }
  }, [isEditViolationShipSuccess]);

  useEffect(() => {
    if (addGroupShip && isOpenAddShipToGroup) {
      setIsOpenAddShipToGroup(false);
      setMonitoringReason("");
      handleSendNotiInSaga(LangConstant.MS_ADD_SHIP_TO_GROUP_SUCCESS, "success");
      dispatch(ShipActions.requestGetTrackingShips());
      dispatch(
        ConversationActions.conversationSet({
          addGroupShip: false,
        }),
      );
    }

    if (errCreateGroupShips) {
      handleSendNotiInSaga(LangConstant.MS_ADD_SHIP_TO_GROUP_ERROR, "error");
    }
  }, [addGroupShip, errCreateGroupShips]);

  const onMinimize = event => {
    event.preventDefault();
    event.stopPropagation();

    let parentContainer = document.getElementById(id);
    if (parentContainer) {
      parentContainer.style.transitionDuration = "500ms";
      parentContainer.style.transform = "scale(0.3)";
      parentContainer.style.bottom = "-160px";
      parentContainer.style.right = "-70%";

      let childContainer = parentContainer.children;
      if (childContainer) {
        childContainer[0].style.display = "none";
      }

      setTimeout(() => {
        parentContainer.style.removeProperty("transition-duration");
      }, 506);
    }
    // dragElement(id);
  };

  const onMaximize = () => {
    let parentContainer = document.getElementById(id);
    if (parentContainer) {
      parentContainer.style.transitionDuration = "500ms";
      parentContainer.style.transform = "scale(1)";
      parentContainer.style.top = "0px";
      parentContainer.style.bottom = "0px";
      parentContainer.style.right = "0px";
      parentContainer.style.left = "0px";

      let childContainer = parentContainer.children;
      if (childContainer) {
        childContainer[0].style.display = "flex";
      }

      setTimeout(() => {
        parentContainer.style.removeProperty("transition-duration");
      }, 506);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onCloseDialog} maxWidth="xl" fullWidth id={id}>
        <Box className={classes.header}>
          <Typography className="header-title">Danh sách tàu có nguy cơ vi phạm</Typography>
          <IconButton size="small" onClick={onCloseDialog}>
            <Close fontSize="small" />
          </IconButton>
        </Box>
        <Box className={classes.body} onClick={onMaximize}>
          <form className={classes.formCont} onSubmit={onSubmit}>
            <Box>
              <FormControl sx={{ width: 400 }}>
                <TextField
                  id="violate-user-text-seach"
                  label="Tìm kiếm"
                  variant="outlined"
                  size="small"
                  placeholder="Tìm kiếm theo tên tàu, hô hiệu, IMO hoặc MMSI"
                  disabled={Object.values(violateReportData).length === 0}
                  className={classes.textfield}
                  onChange={onChangeSearchText}
                  value={searchText}
                />
              </FormControl>
            </Box>
            <Box className={classes.alignItemsStart}>
              <Box>
                <Box className={classes.alignItemsStart}>
                  <FormControl sx={{ minWidth: 200 }}>
                    <InputLabel id="violate-user-type-select">Đối tượng thực hiện</InputLabel>
                    <Select
                      size="small"
                      labelId="violate-user-type-select"
                      value={userType}
                      label="Đối tượng thực hiện"
                      onChange={onChangeUserType}
                    >
                      {Object.values(AppConstant.USER_TYPE).map((type, index) => (
                        <MenuItem value={type.key} key={index}>
                          {type.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ minWidth: 200 }}>
                    <InputLabel id="ship-type-select">Loại tàu</InputLabel>
                    <Select
                      size="small"
                      labelId="ship-type-select"
                      value={selectedShipType}
                      label="Loại tàu"
                      onChange={onChangeShipType}
                    >
                      <MenuItem value={DEFAULT_SHIP_TYPE.type}>{DEFAULT_SHIP_TYPE.name}</MenuItem>
                      {shipTypes.map(shipType => (
                        <MenuItem value={shipType.type} key={shipType.uid}>
                          {shipType.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ minWidth: 200 }}>
                    <InputLabel id="country-select">Quốc tịch</InputLabel>
                    <Select
                      size="small"
                      labelId="country-select"
                      value={selectedCountry}
                      label="Quốc tịch"
                      onChange={onChangeCountry}
                    >
                      <MenuItem value={ALL_VALUE}>Tất cả</MenuItem>
                      {countries.data.map(country => (
                        <MenuItem key={country.value} value={country.value}>
                          {country.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                {userType !== AppConstant.USER_TYPE.user.key ? (
                  <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "10px", gap: "12px" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        label={LangConstant.L_FROM_DAY}
                        value={filterDatetime.start}
                        onChange={onChangeStartDay}
                        renderInput={params => <TextField fullWidth size="small" {...params} />}
                        inputFormat={FormatConstant.FM_DD_MM_YYY_HH_MM}
                      />
                      <DateTimePicker
                        label={LangConstant.L_TO_DAY}
                        value={filterDatetime.end}
                        onChange={onChangeEndDay}
                        renderInput={params => <TextField fullWidth size="small" {...params} />}
                        inputFormat={FormatConstant.FM_DD_MM_YYY_HH_MM}
                      />
                    </LocalizationProvider>
                  </Box>
                ) : (
                  ""
                )}
              </Box>
              <Button sx={{ height: 40 }} type="submit" variant="contained" disableElevation>
                Xem danh sách
              </Button>
            </Box>
          </form>
          <TableContainer sx={{ height: 600 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="center">STT</TableCell>
                  <TableCell align="center">Tên tàu</TableCell>
                  <TableCell align="center">Hô hiệu</TableCell>
                  <TableCell align="center">IMO</TableCell>
                  <TableCell align="center">MMSI</TableCell>
                  <TableCell align="center">Loại tàu</TableCell>
                  <TableCell align="center">Quốc tịch</TableCell>
                  <TableCell align="center">Vị trí</TableCell>
                  <TableCell align="center">Lý do</TableCell>
                  <TableCell align="center">Trạng thái</TableCell>
                  <TableCell align="center">Cấp độ vi phạm</TableCell>
                  <TableCell align="center">Thời gian</TableCell>
                  <TableCell align="center">Thao tác</TableCell>
                </TableRow>
              </TableHead>
              {Object.keys(paginationData).length > 0 ? (
                <TableBody>
                  {(paginationData[pagination.page] || []).map((data, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{(pagination.page - 1) * PAGE_SIZE + index + 1}</TableCell>
                      <TableCell align="center">{data.ship_name}</TableCell>
                      <TableCell align="center">{data.call_sign}</TableCell>
                      <TableCell align="center">{data.IMO}</TableCell>
                      <TableCell align="center">{data.mmsi_code}</TableCell>
                      <TableCell align="center">{data.ship_type}</TableCell>
                      <TableCell align="center">{data.country_code === "Unknown" ? "" : data.country_code}</TableCell>
                      <TableCell align="center" style={{ whiteSpace: "pre" }}>
                        {getLastLocation(data.location_list).join(",\n")}
                      </TableCell>
                      <TableCell align="center">{data.note}</TableCell>
                      <TableCell align="center">
                        {Object.values(AppConstant.VIOLATION_STATUS).find(s => s.key === data.status).title || ""}
                      </TableCell>
                      <TableCell align="center">{data.violation_level}</TableCell>
                      <TableCell align="center">
                        {data.created ? convertMillisecondsToDateTime(data.created) : ""}
                      </TableCell>
                      <TableCell align="center">
                        {searchUserType === AppConstant.USER_TYPE.user.key ? (
                          <>
                            <IconButton
                              size="small"
                              disabled={searchUserType === AppConstant.USER_TYPE.system.key}
                              onClick={() => onEditViolationShip(data)}
                            >
                              <Edit fontSize="small" />
                            </IconButton>
                            <IconButton
                              size="small"
                              disabled={searchUserType === AppConstant.USER_TYPE.system.key}
                              onClick={() => setConfirmOpenDelete(data)}
                            >
                              <Delete fontSize="small" />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <IconButton size="small" onClick={() => onEditViolationShip(data)}>
                              <Edit fontSize="small" />
                            </IconButton>
                            <Checkbox
                              checked={checkedCheckbox.includes(data.ship_uid)}
                              onClick={() => onClickCheckBox(data.ship_uid)}
                              sx={{ color: "dodgerblue" }}
                            />
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    {isInit ? (
                      <TableCell align="center" colSpan={13} style={{ color: "#999" }}>
                        Nhấn xem danh sách để tải dữ liệu
                      </TableCell>
                    ) : isFetchingReport ? (
                      <TableCell align="center" colSpan={13} style={{ color: "green" }}>
                        Đang tải dữ liệu
                      </TableCell>
                    ) : (
                      <TableCell align="center" colSpan={13} style={{ color: "#999" }}>
                        Không có dữ liệu
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
            <Pagination
              style={{ marginTop: 16 }}
              onChange={onChangePage}
              count={pagination.total}
              page={pagination.page}
              variant="outlined"
              color="primary"
            />
            <Box className={classes.boxCont}>
              {userType === AppConstant.USER_TYPE.user.key ? (
                <Button
                  variant="outlined"
                  disableElevation
                  disabled={searchUserType === AppConstant.USER_TYPE.system.key}
                  onClick={onOpenAddViolationShipDialog}
                >
                  Thêm tàu vào danh sách
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  disableElevation
                  disabled={!Boolean(checkedCheckbox.length > 0)}
                  onClick={() => setIsOpenAddShipToGroup(true)}
                >
                  Thêm tàu vào danh sách giám sát
                </Button>
              )}
              <Button
                variant="contained"
                disableElevation
                onClick={onExportReport}
                disabled={Object.values(violateReportData).length === 0}
              >
                Xuất báo cáo
              </Button>
              <Box display="flex" marginRight={"16px"}>
                <Button variant="contained" onClick={onMinimize}>
                  Thu nhỏ
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Dialog open={isOpenAddShipToGroup} onClose={() => setIsOpenAddShipToGroup(false)} maxWidth="xs" fullWidth>
          <DialogTitle>Thêm vào danh sách giám sát</DialogTitle>
          <DialogContent>
            <FormControl fullWidth size="small" sx={{ margin: "8px 0" }}>
              <InputLabel id="shipGroupList">Danh sách giám sát</InputLabel>
              <Select
                label="Danh sách giám sát"
                onChange={e => {
                  setSelectedShipGroup(e.target.value);
                }}
                value={selectedShipGroup}
              >
                <MenuItem disabled value={""}></MenuItem>
                {groupList.map(item => (
                  <MenuItem key={item.uid} value={item.uid}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box className={classes.paddingInput}>
              <TextField
                onChange={e => setMonitoringReason(e.target.value)}
                fullWidth
                value={monitoringReason}
                size="small"
                placeholder="Lý do giám sát "
                variant="outlined"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => setIsOpenAddShipToGroup(false)}>
              Hủy
            </Button>
            <Button variant="contained" disableElevation onClick={() => onClickAddShipToGroup()}>
              Lưu
            </Button>
          </DialogActions>
        </Dialog>
        <AddViolationShip open={isOpenAddViolationShipDialog} onClose={onCloseAddViolationShipDialog} />
        <EditViolationShip
          open={Boolean(selectedViolationShip)}
          onClose={onCloseEditViolationShipDialog}
          ship={selectedViolationShip}
        />
      </Dialog>
      <ConfirmDialog
        open={Boolean(confirmOpenDelete)}
        onClose={() => setConfirmOpenDelete(false)}
        onConfirm={() => onDeleteViolationShip(confirmOpenDelete)}
        title=""
        message={`Bạn muốn xoá tàu ${confirmOpenDelete?.ship_name} khỏi danh sách không?`}
      />
    </>
  );
};

export default ViolateShipReport;

const getLastLocation = (locations = []) => {
  if (locations.length === 0) return ["", ""];
  let sortedLocations = locations.sort((prev, next) => prev.time - next.time);
  let lastLocation = sortedLocations[sortedLocations.length - 1];

  return [lastLocation.lat, lastLocation.lon];
};

const PAGE_SIZE = 10;
const ALL_VALUE = "all";
const DEFAULT_SHIP_TYPE = {
  name: "Tất cả",
  type: -1,
  uid: ALL_VALUE,
};

const isEqualString = (firstSrc, secondSrc) => {
  return (firstSrc || "").toLowerCase() === (secondSrc || "").toLowerCase();
};

const useStyles = makeStyles(theme => ({
  body: {
    padding: 16,
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 16,
    "& > .header-title": {
      textTransform: "uppercase",
      fontSize: 16,
      fontWeight: "bold",
    },
  },

  alignItemsStart: {
    display: "flex",
    alignItems: "flex-start",
    gap: 12,
  },

  boxCont: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 8,
  },

  formCont: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex",
  },

  textfield: {
    "& input::placeholder": {
      fontSize: 14,
    },
  },

  shipGroupInput: {
    marginBottom: "8px",
  },
}));
