import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { AppConstant, LangConstant } from "const";
import React, { useState } from "react";
import { useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import ConversationRedux from "redux-store/conversation.redux";
import { handleSendNotiInSaga } from "utils";

const AddMorePort = ({ open, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const createPortSuccsess = useSelector(state => state.conversationRedux.createPortSuccsess);
  const errLangtitude = useSelector(state => state.conversationRedux.errLangtitude);
  const errLongtitude = useSelector(state => state.conversationRedux.errLongtitude);

  const [phoneNumber, setPhoneNumber] = useState();
  const [errNameShip, setErrNameShip] = useState("");
  const [errAddress, setErrAddress] = useState("");
  const [errHost, setErrHost] = useState("");
  const [valueInput, setValueInput] = useState({
    seaportName: "",
    numberOfBridges: "",
    DWT: "",
    height: "",
    minimumDepth: "",
    maximumFlowDepth: "",
    longStream: "",
    wattage: "",
    warehouseArea: "",
    companyName: "",
    address: "",
    numberFax: "",
    latitude: "",
    longitude: "",
    management: "",
  });

  const onClickAddPort = () => {
    if (!Boolean(valueInput.seaportName)) {
      setErrNameShip("Không được để trống trường này");
    } else {
      setErrNameShip("");
    }

    if (!Boolean(valueInput.address)) {
      setErrAddress("Không được để trống trường này");
    } else {
      setErrAddress("");
    }

    if (!Boolean(valueInput.management)) {
      setErrHost("Không được để trống trường này");
    } else {
      setErrHost("");
    }

    if (Boolean(valueInput.seaportName) && Boolean(valueInput.management) && Boolean(valueInput.address)) {
      dispatch(
        ConversationRedux.createMorePort({
          address: valueInput.address,
          code: "VN",
          country_code: "VN",
          latitude: valueInput.latitude,
          longitude: valueInput.longitude,
          name: valueInput.seaportName,
          phone_number: phoneNumber,
          source_id: "VN",
          source_type: 4,
          status: 0,
          host: valueInput.management,
          fax: valueInput.numberFax,
        }),
      );
    }
  };

  const onClickCancel = () => {
    onClose();
    setErrNameShip("");
    setErrAddress("");
  };

  const onChangeInput = e => {
    setValueInput({ ...valueInput, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (createPortSuccsess) {
      onClose();
      handleSendNotiInSaga(LangConstant.MS_SUCCESS_CREATE_PORT, "success");
      setValueInput({
        address: "",
        seaportName: "",
      });
      setPhoneNumber("");
      dispatch(ConversationRedux.conversationReset());
    }
  }, [createPortSuccsess]);

  return (
    <Dialog open={Boolean(open)} maxWidth="sm" fullWidth onClose={onClose}>
      <DialogTitle>{LangConstant.TXT_MORE_PORT}</DialogTitle>
      <DialogContent>
        <DialogContent>
          <Box style={{ padding: "10px 0" }}>
            <Typography className={classes.inputLabel}>
              Tên cảng biển
              <Box component="span" className={classes.requiredMark}>
                &nbsp;*
              </Box>
            </Typography>
            <TextField
              onChange={onChangeInput}
              name="seaportName"
              size="small"
              fullWidth
              label={LangConstant.L_ADD_SEAPORT_NAME}
              variant="outlined"
            />
            {errNameShip && (
              <Typography variant="h4" style={{ fontSize: 10, color: "red" }}>
                {errNameShip}
              </Typography>
            )}
          </Box>
          <Box className={classes.paddingInput}>
            <Typography className={classes.inputLabel}>
              Địa chỉ
              <Box component="span" className={classes.requiredMark}>
                &nbsp;*
              </Box>
            </Typography>
            <TextField
              onChange={onChangeInput}
              name="address"
              size="small"
              fullWidth
              label={LangConstant.L_ADDRESS}
              variant="outlined"
            />
            {errAddress && (
              <Typography variant="h4" style={{ fontSize: 10, color: "red" }}>
                {errAddress}
              </Typography>
            )}
          </Box>
          <Box style={{ paddingBottom: 10 }}>
            <Typography className={classes.inputLabel}>
              Đơn vị quản lý
              <Box component="span" className={classes.requiredMark}>
                &nbsp;*
              </Box>
            </Typography>
            <TextField
              onChange={onChangeInput}
              name="management"
              size="small"
              fullWidth
              label="Đơn vị quản lý"
              variant="outlined"
            />
            {errHost && (
              <Typography variant="h4" style={{ fontSize: 10, color: "red" }}>
                {errHost}
              </Typography>
            )}
          </Box>
          <PhoneInput
            value={phoneNumber}
            onChange={phone => setPhoneNumber(phone)}
            country="vn"
            onlyCountries={AppConstant.COUNTRY}
          />

          <Box className={classes.paddingInput}>
            <TextField
              onChange={onChangeInput}
              name="longitude"
              type="number"
              size="small"
              fullWidth
              label={LangConstant.L_LONGITUDE}
              variant="outlined"
            />
            {errLongtitude ? (
              <Typography variant="h4" style={{ fontSize: 10, color: "red" }}>
                {errLongtitude}
              </Typography>
            ) : (
              ""
            )}
          </Box>
          <TextField
            onChange={onChangeInput}
            name="latitude"
            type="number"
            size="small"
            fullWidth
            label={LangConstant.L_LANGITUDE}
            variant="outlined"
          />
          {errLangtitude ? (
            <Typography variant="h4" style={{ fontSize: 10, color: "red" }}>
              {errLangtitude}
            </Typography>
          ) : (
            ""
          )}
          <Box style={{ paddingTop: 10 }}>
            <TextField
              onChange={onChangeInput}
              name="numberFax"
              type="number"
              size="small"
              fullWidth
              label="Fax"
              variant="outlined"
            />
          </Box>
        </DialogContent>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickAddPort}>{LangConstant.TXT_BUTTON_ADD}</Button>
        <Button onClick={onClickCancel}>{LangConstant.TXT_BUTTON_CANCEL}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddMorePort;

const useStyles = makeStyles(theme => ({
  paddingInput: {
    padding: "10px 0",
  },

  requiredMark: {
    color: "red",
  },

  inputLabel: {
    marginBottom: 10,
    fontSize: 14,
    fontWeight: 500,
  },
}));
