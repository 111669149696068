import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useDispatch } from "react-redux";
import ConversationRedux from "redux-store/conversation.redux";

const ConfirmDeletePort = props => {
  const { open, onClose, data, checked } = props;
  const dispatch = useDispatch();

  const onClickButtonDeleteShip = () => {
    dispatch(
      ConversationRedux.deleteHarbors({
        uids: checked,
      }),
    );
    onClose();
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={onClose}>
      <DialogTitle>Xác nhận xóa</DialogTitle>
      <DialogContent style={{ paddingTop: 10 }}>
        {checked?.length > 1
          ? `Bạn muốn xóa ${checked?.length} bến cảng không?`
          : `Bạn muốn xóa bến cảng ${data?.name} không?`}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClickButtonDeleteShip}>
          Đồng ý
        </Button>
        <Button onClick={onClose}>Thoát</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeletePort;
