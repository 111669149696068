import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ShipActions from "redux-store/ship.redux";
import { Box, Button, Dialog, FormLabel, IconButton, OutlinedInput, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";
import { AppConstant, LangConstant } from "const";
import { handleSendNotiInSaga } from "utils";

const LayerAddViolationShipDialog = ({ open, onClose, ship }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const violationShipNumber = useSelector(state => state.shipRedux.violationShipNumber);

  const [selectedShips, setSelectedShips] = useState([]);
  const [reason, setReason] = useState("");
  const [status, setStatus] = useState(AppConstant.VIOLATION_STATUS.NOT_PROCESSING.key);
  const [errors, setErrors] = useState({ ship: "", reason: "" });

  const onCloseDialog = () => {
    onClose();
    setReason("");
    setSelectedShips([]);
    setErrors({ ship: "", reason: "" });
    setStatus(AppConstant.VIOLATION_STATUS.NOT_PROCESSING.key);
    dispatch(ShipActions.shipSet({ violationShipNumber: null }));
  };

  const onChangeReason = e => {
    setReason(e.target.value);
    setErrors(state => ({ ...state, reason: "" }));
  };

  const onChangeStatus = e => {
    setStatus(e.target.value);
  };

  const onSubmit = e => {
    let isValidated = true;

    if (selectedShips.length === 0) {
      isValidated = false;
      setErrors(state => ({ ...state, ship: "Vui lòng chọn ít nhất một tàu" }));
    }

    if (reason.length === 0) {
      isValidated = false;
      setErrors(state => ({ ...state, reason: "Vui lòng không để trống trường này" }));
    }

    if (isValidated) {
      dispatch(
        ShipActions.requestAddViolationShip({
          ships: selectedShips.map(s => s.ship_uid),
          note: reason,
          status: status,
        }),
      );
    }
  };

  useEffect(() => {
    if (violationShipNumber !== null) {
      if (violationShipNumber !== 0) {
        handleSendNotiInSaga(LangConstant.MS_ADD_VIOLATION_SHIP_SUCCESS, "success");
        onCloseDialog();
      } else {
        handleSendNotiInSaga(LangConstant.MS_VIOLATION_SHIP_EXSITED, "error");
        onCloseDialog();
      }
    }
  }, [violationShipNumber]);

  useEffect(() => {
    if (ship) {
      setSelectedShips([ship]);
    }
  }, [ship]);

  return (
    <Dialog open={open} onClose={onCloseDialog}>
      <Box className={classes.cont} component="section">
        <Box className={classes.header}>
          <Typography variant="h6">Thêm tàu vào danh sách vi phạm</Typography>
          <IconButton size="small" onClick={onCloseDialog}>
            <Close fontSize="small" />
          </IconButton>
        </Box>
        <Box className={classes.body}>
          <Box>
            <Typography variant="h6" style={{ marginBottom: selectedShips.length > 0 ? 8 : 0 }}>
              Tàu đã chọn:
            </Typography>
            <Box>
              {selectedShips.length > 0 ? (
                selectedShips.map((ship, index) => (
                  <Box key={index} className={classes.selectedShip} marginLeft={1}>
                    <Typography>&#x2022; {ship.ship_name}</Typography>
                  </Box>
                ))
              ) : (
                <Typography variant="caption" style={{ marginLeft: 8 }}>
                  &#x2022; Chưa có tàu được chọn
                </Typography>
              )}
            </Box>
          </Box>
          <Box marginBottom={2}>
            <FormLabel required className={classes.formLabel} htmlFor="violate-ship-reason">
              Lý do
            </FormLabel>
            <OutlinedInput
              id="violate-ship-reason"
              size="small"
              fullWidth
              multiline
              rows={4}
              placeholder="Nhập lý do..."
              value={reason}
              onChange={onChangeReason}
            />
            <Typography variant="caption" color="error">
              {errors.reason}
            </Typography>
          </Box>
          <Box marginBottom={2}>
            <FormLabel required className={classes.formLabel} htmlFor="violate-ship-status">
              Trạng thái
            </FormLabel>
            <select id="violate-ship-status" className={classes.status} value={status} onChange={onChangeStatus}>
              <option value={AppConstant.VIOLATION_STATUS.NOT_PROCESSING.key}>
                {AppConstant.VIOLATION_STATUS.NOT_PROCESSING.title}
              </option>
              <option value={AppConstant.VIOLATION_STATUS.PROCESSED.key}>
                {AppConstant.VIOLATION_STATUS.PROCESSED.title}
              </option>
            </select>
          </Box>
        </Box>
        <Box className={classes.footer}>
          <Button fullWidth variant="contained" onClick={onSubmit} size="large">
            Thêm tàu
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default memo(LayerAddViolationShipDialog);

const useStyles = makeStyles(theme => ({
  cont: {
    width: 500,
  },

  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 16,
  },

  body: {
    padding: "0 16px",
  },

  paper: {
    width: 552,
    maxWidth: "unset",
    maxHeight: "unset",
    marginTop: 4,
    padding: 8,
  },

  resultCont: {
    maxHeight: "30vh",
    height: "100%",
    overflowY: "scroll",
  },

  selectedShip: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 8,
  },

  formLabel: {
    display: "inline-block",
    color: "#000000",
    fontSize: 18,
    fontWeight: 400,
    marginBottom: 8,
  },

  status: {
    display: "block",
    width: "100%",
    padding: 10,
    border: "1px solid #cecece",
    borderRadius: 4,
    cursor: "pointer",
    outline: "none",
  },

  footer: {
    padding: 16,
    paddingTop: 0,
  },
}));
