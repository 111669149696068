import { ApiConstant } from "const";
import { createApiDownloadExcel, createApiWithToken, createApiUploadFile } from "services/config/api.config";
import StringFormat from "string-format";

export const createUserSaga = data => createApiWithToken().post(ApiConstant.CREATE_USER_LOGIN, data);

export const deleteUserSaga = data => createApiWithToken().delete(ApiConstant.DELETE_USER, data);

export const changePasswordSaga = data => createApiWithToken().put(ApiConstant.CHANGE_PASSWORD, data);

export const loginPageSaga = data => createApiWithToken().post(ApiConstant.LOGIN_PAGE, data);

export const editUserSaga = data => createApiWithToken().put(StringFormat(ApiConstant.USER, data.id), data);

export const getHistorySaga = data => createApiWithToken().get(ApiConstant.GET_HISTORY, data);

export const getUserList = data => createApiWithToken().get(ApiConstant.GET_USER_LOGIN, data);

export const resetPasswordSaga = data =>
  createApiWithToken().put(StringFormat(ApiConstant.RESET_PASSWORD, data.id), data);

export const createShipSaga = data => createApiWithToken().post(ApiConstant.CREATE_SHIP, data);

export const getListShipSaga = data => createApiWithToken().get(ApiConstant.GET_LIST_SHIP, data);

export const getShipTypeSaga = data => createApiWithToken().get(ApiConstant.GET_TYPE_SHIP, data);

export const deleteListSaga = data =>
  createApiWithToken().delete(StringFormat(ApiConstant.DETELE_LIST_SHIP, data.ids), data);

export const updateShipSaga = data => createApiWithToken().put(StringFormat(ApiConstant.UPDATE_SHIP, data.uid), data);

export const createGroupShipSaga = data => createApiWithToken().post(ApiConstant.CREATE_GROUP_SHIP, data);

export const getHarborsSaga = data => createApiWithToken().get(ApiConstant.GET_HARBOR, data);

export const createHarborsSaga = data => createApiWithToken().post(ApiConstant.CREATE_HARBOR_SHIP, data);

export const createMorePortSaga = data => createApiWithToken().post(ApiConstant.CREATE_MORE_PORE, data);

export const getPortManagerSaga = data => createApiWithToken().get(ApiConstant.GET_PORT_MANAGER, data);

export const deleteHarborsSaga = data => createApiWithToken().delete(ApiConstant.DELETER_HARBOR_SHIP, data);

export const updateHarborsSaga = data =>
  createApiWithToken().put(StringFormat(ApiConstant.UPDATE_HARBOR_SHIP, data.uid), data);

export const getGroupShipSaga = data => createApiWithToken().get(ApiConstant.GET_GROUP_SHIP, data);

export const deleteGroupShipSaga = data => createApiWithToken().delete(ApiConstant.DELETE_GROUP_SHIP, data);

export const updateShipGroupSaga = data =>
  createApiWithToken().put(StringFormat(ApiConstant.UPDATE_SHIP_GROUP, data.uid), data);

export const deleteGroupDetailShipSaga = data =>
  createApiWithToken().delete(StringFormat(ApiConstant.DELETE_GROUP_DETAIL_SHIP, data.uid, data.ships));

export const uploadFileSaga = data => {
  return createApiUploadFile().post(ApiConstant.UPLOAD_FILE, data);
};

export const exportFileSaga = data => createApiDownloadExcel().get(StringFormat(ApiConstant.EXPORT_FILE, data));
export const exportFileSagaAll = data => createApiDownloadExcel().get(ApiConstant.EXPORT_FILE_ALL, data);
export const importFileSaga = data => createApiWithToken().post(StringFormat(ApiConstant.IMPORT_FILE, data.uid), data);

export const getGroupShipDetailSaga = data => {
  return createApiWithToken().get(StringFormat(ApiConstant.GET_IMPORT_GROUP_DETAIL, data.uid), data);
};

export const getDetailGroupSaga = data =>
  createApiWithToken().get(StringFormat(ApiConstant.GET_GROUP_DETAILS_SHIP, data.uid), data);

export const getStatisticSaga = data => createApiWithToken().get(ApiConstant.GET_STATISTICS, data);

export const updateStatisticSaga = data =>
  createApiWithToken().put(StringFormat(ApiConstant.UPDATE_STATISTICS, data.uid), data);

export const deleteStatisticSaga = data =>
  createApiWithToken().delete(StringFormat(ApiConstant.DELETE_STATISTICS, data.uid), data);

export const getListShipWatchSaga = data => createApiWithToken().get(ApiConstant.GET_LIST_SHIP, data);
export const getListDeatailsSaga = data => {
  return createApiWithToken().get(StringFormat(ApiConstant.GET_IMPORT_GROUP_DETAIL, data.uid), data);
};
