export const TXT_BUTTON_AGREE = "Đồng ý";
export const TXT_BUTTON_CANCEL = "Thoát";
export const TXT_NEW_PASSWORD = "Mật khẩu mới";
export const TXT_CONFIRM_PASSWORD = "Xác nhận mật khẩu mới";
export const TXT_PASSWORD_INCORRECT = "Mật khẩu không khớp";
export const TXT_BUTTON_CHANGE_PASSWORD = "Đổi mật khẩu";
export const TXT_VALIDATE_EMAIL = "Email không hợp lệ";
export const TXT_ERROR_NAME_INPUT = "Tên phải dài hơn 4 kí tự";
export const TXT_ERROR_PASSWORD_INPUT = "Mật khẩu là 8 kí tự ";
export const TXT_USER_RIGHTS_FIX = "Sửa quyền người dùng";
export const TXT_ADD_USER = "Thêm người dùng";
export const TXT_NAME = "Họ và tên";
export const TXT_EMAIL = "Email";
export const TXT_PASSWORD = "Mật khẩu";
export const TXT_NUMBER = "Số điện thoại";
export const TXT_BUTTON_ADD = "Thêm";
export const TXT_BUTTON_UPDATE = "Cập nhật";
export const TXT_USER_RIGHTS = "Quyền người dùng";
export const TXT_TIME_SYSTEM = "Phòng ban";
export const TXT_SYSTEM = "Hệ thống";
export const TXT_MANIPULATION = "Thao tác";
export const TXT_CONFIRM_CHANGE_PASSWORD = "Xác nhận đổi mật khẩu";
export const TXT_HISTORY_STYSTEM = "Lịch sử xuất nhập hệ thống";
export const TXT_HISTORY_TYPE = "Loại lịch sử";
export const TXT_DATA_TYPE = "Loại dữ liệu";
export const TXT_EVENT_WARNING_TYPE = "Loại cảnh báo";
export const TXT_STT = "STT";
export const TXT_NAME_FILE = "Tên file";
export const TXT_FILE_FORMAT = "Link file";
export const TXT_PEOPLE_AWAKENING = "Người thực hiện";
export const TXT_TIME_AWAKENING = "Thời gian thực hiện";
export const TXT_STATUS_SHIP_SUCCESS = "Hoàn thành";
export const TXT_STATUS_SHIP = "Đang xử lý";
export const TXT_STATUS_ERR_SHIP = "Bị lỗi";
export const TXT_MONITORING_SOFTWARE = "Phần mềm giám sát";
export const TXT_BUTTON_LOGIN = "Đăng nhập";
export const TXT_BUTTON_TRIOS_LOGIN = "Đăng nhập qua TRIOS";
export const TXT_BUTTON_TRIOS_LINK = "Liên kết tài khoản ";
export const TXT_PASSWORD_PRESENT = "Mật khẩu hiện tại";
export const TXT_CONFIRM_DELETE_USER = "Xác nhận xóa tài khoản";
export const TXT_USER_NAME_LOCAL = "username";
export const TXT_PASSWORD_LOCAL = "passWord";
export const TXT_REMEMBER_PASSWORD = "remember-password";
export const TXT_ERR_ERR_DEPARTMENT = "Không được để trống";
export const TXT_FILTER = "Bộ lọc";
export const TXT_STATUS = "Trạng thái";
export const TXT_SEA_AREA = "Khu vực biển";
export const TXT_DRAW_AREA = "Khu vực tự vẽ";
export const TXT_MONITORING_LIST = "Danh sách giám sát";
export const TXT_MONITORING_AREA = "Khu vực giám sát";
export const TXT_QUANTITY = "Số lượng: ";
export const TXT_SHOW_DETAIL = "Xem chi tiết";
export const TXT_ENTER_THE_VALUE = "Nhập giá trị: ";
export const TXT_DASH = "_";
export const TXT_RESET_FILTER = "Reset bộ lọc";
export const TXT_SEARCH = "Tìm kiếm";
export const TXT_FILTER_SHIP_DATA = "Bộ lọc dữ liệu tàu";
export const TXT_PAGE_TITLE = "Hệ thống giám sát thông tin\nhỗ trợ công tác cảnh sát biển";
export const TXT_SHIP_MANAGE = "Quản lý tàu";
export const TXT_MONITERING = "Giám sát";
export const TXT_STATISTICAL = "Báo cáo thống kê";
export const TXT_REPORT_JOURNEY = "Báo cáo hành trình";
export const TXT_WARNING_SHIP = "Báo cáo tàu có nguy cơ vi phạm";
export const TXT_STATISTICAL_AREA = "Thống kê theo khu vực";
export const TXT_MANAGE_SYSTEM = "Quản trị hệ thống";
export const TXT_SHIP = "Theo tàu";
export const TXT_SHIP_LIST_MONITERED = "Theo danh sách tàu giám sát";
export const TXT_SHIP_JOURNEY_MONITORING_TITLE = "Giám sát hành trình theo tàu";
export const TXT_SHIP_LIST_MONITORING_TITLE = "Danh sách tàu giám sát";
export const TXT_SHIP_LIST_RECENT = "Danh sách tàu đã xem gần đây:";
export const TXT_SHIP_LIST_MANAGE = "Quản lý danh sách tàu giám sát";
export const TXT_CHOOSE_TIME = "Chọn thời gian xem hành trình";
export const TXT_EXPORT_REPORT = "Xuất báo cáo";
export const TXT_SEARCH_JOURNEY = "Tra cứu hành trình";
export const TXT_SHIP_NAME = "Tên tàu";
export const TXT_SHIP_LICENSE_PLATE = "Biển số tàu";
export const TXT_SHIP_CAREER = "Nghề";
export const TXT_MAIN_FISHING_PORT = "Cảng chính";
export const TXT_IMO = "IMO";
export const TXT_MMSI = "MMSI";
export const TXT_SHIP_TYPE = "Loại tàu";
export const TXT_NATIONALITY = "Quốc tịch";
export const TXT_PORT = "Cảng biển";
export const TXT_WEIGHT = "Tải trọng";
export const TXT_LOCATION = "Vị trí";
export const TXT_LOCATION_LAST = "Vị trí cuối cùng ghi nhận";
export const TXT_SHIP_LIST = "Danh sách tàu";
export const TXT_CLOSE = "Đóng";
export const TXT_EXPORT_BY_EXCEL = "Xuất excel";
export const TXT_SPEED_SHIP = "Vận tốc tàu";
export const TXT_SPEED_WIND = "Vận tốc gió";
export const TXT_DIRECTION = "Hướng di chuyển";
export const TXT_TIME = "Thời gian";
export const TXT_SEASCAPE = "Cảng biển";
export const TXT_SHIPS = "Tàu thuyền";
export const TXT_JOURNEYS = "Hành trình";
export const TXT_MAP = "Bản đồ";
export const TXT_MORE_PORT = "Thêm cảng biển";
export const TXT_INFORMATION = "Thông tin";
export const TXT_VALIDATE_PHONE_NUMBER = "Số điện thoại trên 8 chữ số";
export const TXT_VALIDATE_EMPTY = "Trường này không được để trống";
export const TXT_ADD_MORE = "Thêm bến cảng";
export const TXT_ADD_SHIP = "Thêm tàu";
export const TXT_EDIT_INFOMATION = "Sửa thông tin";
export const TXT_BUTTON_DELETE = "Xóa nhiều";
export const TXT_TABLE_NAME_PORT = "Bến cảng";
export const TXT_MANAGEMENT_UNIT = "Đơn vị quản lý";
export const TXT_ADDRESS = "Địa chỉ";
export const TXT_FAX = "Số fax";
export const TXT_TIME_UPDATE = "Thời gian cập nhật";
export const TXT_NO_DATA = "Không có dữ liệu";
export const TXT_VIEW_MORE_PORT = "Xem bến cảng";
export const TXT_STATISTICAL_DETAIL_TITLE = "Thống kê chi tiết theo từng nguồn dữ liệu";
export const TXT_GENERAL_INFO = "Thông tin chung";
export const TXT_CONTINUE = "Tiếp tục";
export const TXT_UNDEFINE_NAME = "Chưa đặt tên";
export const TXT_TRIOS = "TRIOS";
export const TXT_GST = "GST";
export const TXT_SAVE = "Lưu";
export const TXT_DESCRIPTION = "Mô tả";
export const TXT_CONFIRM = "Xác nhận";
export const TXT_PERMISSION = "Nhóm quyền";
export const TXT_YES = "Có";
export const TXT_NO = "Không";
export const TXT_NOTIFICATON = "Thông báo";
export const TXT_UNDERSTOOD = "Đã hiểu";
export const TXT_CANCEL = "Hủy";
export const TXT_LOADING_GROUP_SHIP = "Đang cập nhật danh sách giám sát...";

export const TXT_SEND_REQUEST_TO_SGW = "Gửi truy vấn lên Secured Gateway";

export const TXT_OPTION_MORE = "Chọn bến cảng";

export const TXT_SHIP_MONITORING = "Giám sát tàu cá";
export const TXT_MARINE_TRAFFIC = "Marine Trafic";
export const TXT_VISHIPEL = "Vishipel";
export const TXT_LOCATION_WHEN_GO_OUT_AREA = "Vị trí ở thời điểm ra khỏi khu vực";
export const TXT_LOCATION_WHEN_GO_TO_AREA = "Vị trí ở thời điểm vào khu vực";
export const TXT_DISTANCE_FROM_AREA = "Khoảng cách so với khu vực";
export const TXT_INTERNAL_WATER = "Nội thủy";
export const TXT_STATISTICAL_AREA_TITLE = "Thống kê số lần vào, ra khu vực";
export const TXT_SHOW_STATISTIC = "Xem thống kê";
export const TXT_SHIP_CODE = "Số hiệu";
export const TXT_TIMES_GO_TO_AREA = "Số lần vào";
export const TXT_TIMES_GO_OUT_AREA = "Số lần ra";

export const TXT_RESET_PASSWORD = "Reset Password";
export const TXT_ACTION = "Hành động";
export const TXT_ALL = "Tất cả";
export const TXT_TIME_CREATE = "Thời gian tạo";
export const TXT_USER_CREATE = "Người tạo";

export const TXT_EVENT_WARNING_ID = "Mã cấu hình";
export const TXT_EVENT_WARNING_NAME = "Tên cấu hình";
export const TXT_CONFIRM_DELETE_EVENT_WARNING = "Xác nhận xoá cấu hình";
export const TXT_SHOW_LIST_EVENT_WARNING = "Xem Danh sách cấu hình cảnh báo";
export const TXT_EVENT_WARNING_INFO = "Thông tin cấu hình cảnh báo";
export const TXT_EVENT_WARNING_DATA = "Dữ liệu theo dõi cảnh báo";
export const TXT_DATA_SHIP = "Dữ liệu tàu";
export const TXT_DATA_AREA = "Dữ liệu khu vực";
export const TXT_REQUIRED_CHOOSED_TYPE_ACTION = "Vui lòng chọn ít nhất 1 loại cảnh báo";
export const TXT_TURN_ON_NOTIFY_EVENT = "Bật cảnh báo sự kiện";
export const TXT_TURN_OFF_NOTIFY_EVENT = "Tắt cảnh báo sự kiện";
export const FM_CONFIRM_TURN_OFF_NOTIFY = "Bạn có muốn tắt cảnh báo {0} không?";
export const FM_CONFIRM_TURN_ON_NOTIFY = "Bạn có muốn bật cảnh báo {0} không?";

export const TXT_SHOW_LIST_FOLDER = "Xem Danh sách folder báo cáo";
export const TXT_FOLDER_INFO = "Thông tin folder báo cáo";
export const TXT_FILE_REPORT = "File báo cáo";
export const TXT_FILE_REPORT_ACCESS = "Phân quyền truy cập báo cáo";
export const TXT_FOLDER_ID = "Mã folder";
export const TXT_FOLDER_NAME = "Tên folder";
export const TXT_FOLDER_DESCRIPTION = "Mô tả";
export const TXT_FOLDER_ONLY_ME = "Chỉ mình tôi";
export const TXT_FOLDER_ANOTHER_USER = "Cho những thành viên khác";
export const TXT_CONFIRM_ADD_SAVE = "Dữ liệu thêm mới chưa được lưu. Bạn có muốn tiếp tục thêm mới dữ liệu?";
export const TXT_CONFIRM_EDIT_SAVE = "Dữ liệu cập nhật chưa được lưu. Bạn có muốn tiếp tục cập nhật dữ liệu?";
export const TXT_FOLDER_FILE_ERROR = "File dữ liệu bạn vừa tải lên không phù hợp. Vui lòng tải lại file báo cáo khác";

export const TXT_IP_ADDRESS = "Địa chỉ IP";
export const TXT_MAC_ADDRESS = "Địa chỉ MAC";

export const TXT_SUCCESS = "Thành công";
export const TXT_FAIL = "Thất bại";
export const TXT_MESSAGE_ERROR = "Có lỗi xảy ra, vui lòng thử lại sau";

export const TXT_USER_ACTION_PERSONAL = "Lịch sử hoạt động cá nhân";
export const TXT_USER_ACTION_HISTORY = "Lịch sử truy cập hệ thống";
export const TXT_USER_ACTION_SUMMARY = "Thống kê truy cập hệ thống";
export const TXT_USER_ACTION_ON_SYSTEM = "Thống kê hoạt động trên hệ thống";
export const TXT_USER_ACTION_DETAIL_USER = "Thống kê chi tiết theo tài khoản";

export const TXT_FILE_REPORT_NAME = "Tên file báo cáo";
export const TXT_FILE_REPORT_TYPE = "Định dạng";
export const TXT_FILE_REPORT_SIZE = "Dung lượng";
export const TXT_FILE_ID = "ID";
export const TXT_FOLDER_REPORT_NAME = "Tên folder báo cáo";
export const TXT_FOLDER_REPORT_QUANTITY = "Số lượng báo cáo";
export const TXT_FOLDER_REPORT_OWNER = "Chủ folder";
export const TXT_CONFIRM_DELETE_FOLDER = "Xác nhận xoá folder";
export const TXT_CONFIRM_SAVE = "Xác nhận lưu dữ liệu";
export const TXT_CONFIRM_DOWNLOAD_FOLDER = "Xuất file báo cáo";
export const TXT_CONFIRM_DOWNLOAD_FOLDER_MSG = "Chọn file báo cáo để xuất về thiết bị";
export const TXT_BUTTON_DOWNLOAD_FILE = "Xuất file";

// Text Profile
export const TXT_USER_CURRENT_PROFILE = "Thông tin cá nhân";
export const TXT_USER_PROFILE = "Thông tin người dùng";
export const TXT_SHARE = "Chia sẻ";
export const TXT_SHARE_PROFILE_TO_TRIOS = "Chia sẻ thông tin qua TRIOS";
export const TXT_EXPORT_PROFILE = "Tải thông tin cá nhân";
export const TXT_ACCOUNT = "Tài khoản";
export const TXT_UNIT = "Đơn vị";
export const TXT_ON_2FA = "Bật xác thực 2 bước";
export const TXT_OFF_2FA = "Tắt xác thực 2 bước";
export const TXT_USER_ACTION = "Lịch sử hoạt động";
export const TXT_ERROR_NULL_PERMISSION = "Quyền người dùng không được để trống";

export const TXT_REPORT_LIST_SHIP_DISCONNECTED = "Thống kê tàu cá mất kết nối trên 10 ngày";
export const TXT_LIST_SHIP_DISCONNECTED_TITLE = "Bảng tổng hợp tàu mất kết nối";
export const TXT_REPORT_SUMMARY_TITLE = "Tổng hợp tàu mất kết nối";
export const TXT_REPORT_SUMMARY_DVT_TEXT = "ĐVT: Tàu";
export const TXT_REPORT_BAR_CHART_TITLE = "Tổng hợp theo kích thước và khu vực";
export const TXT_SHIP_LENGTH = "Kích thước tàu";
export const TXT_OWNER = "Chủ tàu";
export const TXT_NEAREST_PORT_OF_ENTRY = "Cảng nhập cảnh gần nhất";
export const TXT_LAST_UPDATE_DATA = "Dữ liệu cập nhật lần cuối";
export const TXT_SOURCE_DATA = "Nguồn dữ liệu";
export const TXT_NUMBER_HOURS_LOST = "Số giờ không gửi thông tin";
export const TXT_SHIP_LIST_LOST = "Danh sách tàu mất kết nối";
export const TXT_UNFOLLOW = "Dừng theo dõi";
export const TXT_DOWNLOAD = "Tải xuống";
export const TXT_CONFIRM_REMOVE_LIST_SHIP = "Bạn chắc chắn thực hiện hành động này?";
export const TXT_LIST_SHIP_LOST_FILE_NAME = "Tàu mất kết nối.xlsx";
export const TXT_REMOVE_LIST_SHIP_SUCCESS = "Tắt theo dõi thành công";
export const TXT_DEVICE = "Thiết bị";

export const TXT_REPORT_LIST_SHIP_LOST_SIGNAL = "Thống kê tàu cá mất kết nối";
export const TXT_LIST_SHIP_LOST_SIGNAL_TITLE = "Bảng tổng hợp tàu mất kết nối dưới 10 ngày";
export const TXT_REPORT_SUMMARY_LOST_SIGNAL_TITLE = "Tổng hợp tàu mất kết nối dưới 10 ngày";
export const TXT_SHIP_LIST_LOST_SIGNAL = "Danh sách tàu mất kết nối dưới 10 ngày";
export const TXT_LIST_SHIP_LOST_SIGNAL_FILE_NAME = "Tàu mất tín hiệu.xlsx";

export const TXT_REPORT_BY_DAY = "Báo cáo theo ngày";
export const TXT_PLACE = "Địa điểm";
export const TXT_SPEED_AVG = "Vận tốc trung bình (knot)";
export const TXT_ACTIVE = "Hoạt động";
export const TXT_LIST_SHIP_BY_DAY_FILE_NAME = "Báo cáo theo ngày.pdf";
export const L_DATE_REPORT = "Ngày xem báo cáo";

export const TXT_NOTIFICATON_WARNING_LIST = "Danh sách cảnh báo";

export const TXT_CONTENT = "Nội dung";
//Format
export const FM_CONFIRM_DELETE = "Bạn muốn xóa {0} không?";
export const FM_QUANTITY_SHIP = "Số lượng: {0} tàu";
export const FM_MONITORING_AREA = "{0} ({1} tàu)";
export const FM_MONITORING_LIST = "{0} ({1} tàu)";
export const FM_FILTER_TYPE = "{0}. {1}";
export const FM_SHIP_NAME = "Tên tàu: {0}";
export const FM_AREA = "Khu vực: {0}";
export const FM_FROM_DAY = "Từ ngày: {0}";
export const FM_TO_DAY = "Đến ngày: {0}";
export const FM_TIMES_GO_OUT_AREA = "Số lần ra khỏi khu vực: {0}";
export const FM_TIMES_GO_TO_AREA = "Số lần vào khu vực: {0}";
export const FM_ALL_EVENT = "Toàn bộ sự kiện ({0})";
export const FM_TOTAL_SHIP_IN_DATABASE = "Trong tổng số {0} tàu trong hệ thống";

export const TXT_DELETE_SELECT = "Xóa giá trị đã chọn";

//Label
export const L_EMAIL = "Email";
export const L_NAME = "Tên";
export const L_OPTIONS = "Tùy chọn";
export const L_NEW_PASSWORD = "Mật khẩu mới";
export const L_CONFIRM_PASSWORD = "Xác nhận mật khẩu mới";
export const L_PASSWORD = "Mật khẩu";
export const L_NUMBER_PHONE = "Số điện thoại";
export const L_EXPORT_DATA = "Xuất dữ liệu";
export const L_IMPORT_DATA = "Nhập dữ liệu";
export const L_PORT = "Cảng";
export const L_JOURNEYS = "Hành trình";
export const L_SHIP = "Tàu";
export const L_REMEMBER_PASSWORD = "Nhớ mật khẩu";
export const L_TXT_PASSWORD_PRESENT = "Mật khẩu hiện tại";
export const L_SHIP_VICINITY_LIST = "Danh sách tàu nước ngoài vào vùng lân cận";
export const L_SHOW_DATA_BY_TABLE = "Xem dữ liệu dạng bảng danh sách";
export const L_FROM_DAY = "Từ ngày";
export const L_TO_DAY = "Đến ngày";
export const L_PASSWORD_PRESENT = "Mật khẩu hiện tại";
export const L_DATA_TYPE = "Loại dữ liệu";
export const L_SEARCH = "Tìm kiếm";
export const L_STATUS = "Trạng thái";
export const L_ADD_SEAPORT_NAME = "Tên cảng biển";
export const L_ADDRESS = "Địa chỉ";
export const L_LONGITUDE = "Kinh độ";
export const L_LANGITUDE = "Vĩ độ";
export const L_ADD_SHIP = "Thêm tàu";
export const L_NAME_MORE_PORT = "Tên bến cảng";
export const L_NUMBER_OF_DEMAND = "Số cầu";
export const L_DWT = "DWT";
export const L_HEIGHT = "Chiều dài";
export const L_MINIMUM_DEPTH = "Luồng sâu tối thiểu";
export const L_MAXMUM_FLOW_DEPTH = "Luồng sâu tối đa";
export const L_LONG_STREAM = "luồng dài";
export const L_POWER = "Công suất";
export const L_SQUARE = "Diện tích kho";
export const L_COMPANY_NAME = " Tên công ty";
export const L_FAX = "Số fax";
export const L_SEARCH_SHIP = "Tìm kiếm tàu cảng";
export const L_HISTORY_SEARCH_SHIP_LIST = "Các tàu đã xem gần đây";

export const L_OPTION_MORE = "Chọn bến cảng";

export const L_CHOOSE_RESOURCE = "Chọn nguồn";
export const L_CHOOSE_AREA = "Chọn khu vực";
export const L_SHIP_NAME = "Tên tàu";

export const L_DOWNLOAD_ACTION = "Tải xuống hoạt động";
export const L_DOWNLOAD_LIST = "Tải danh sách";
export const L_BTN_ADD_NEW = "Thêm mới";

export const L_RECEIVE_NOTIFY_EVENT_WARNING = "Nhận cảnh báo về sự kiện";
export const L_TYPE_EVENT_WARNING = "Loại cảnh báo";
export const L_CHOOSE_TYPE_CHANGE_VALUE = "Chọn giá trị thay đổi";
export const L_RANGE_SPEED = "Khoảng giá trị thay đổi vận tốc";
export const L_RANGE_COURSE = "Khoảng giá trị thay đổi về hướng";
export const L_RANGE_DRAFT = "Khoảng giá trị thay đổi về mớn nước";
export const L_INPUT_MIN_SPEED = "Nhập vận tốc tối thiểu (Hải lý)";
export const L_INPUT_MAX_SPEED = "Nhập vận tốc tối đa (Hải lý)";
export const L_INPUT_COURSE = "Nhập hướng (độ)";
export const L_INPUT_DRAFT = "Nhập mớn nước (mét)";

export const L_MANAGER_FOLDER_REPORT = "Quản lý folder báo cáo";
export const L_ADD_USER_FOLDER_REPORT = "Thêm người dùng";

//Placeholder
export const P_NEW_PASSWORD = "Mật khẩu mới";
export const P_CONFIRM_PASSWORD = "Xác nhận mật khẩu mới";
export const P_CONFIRM_PASSWORD_PRESENT = "Mật khẩu hiện tại";
export const P_CHOOSE_NATION = "Chọn quốc gia";
export const P_INPUT_SEARCH = "Tên tàu, số hiệu, IMO, MMSI";

export const FM_NEW_CREATED_BY_DAY = "{0} ngày trước";
export const FM_NEW_CREATED_BY_HOUR = "{0} giờ trước";
export const FM_NEW_CREATED_BY_MINUTE = "{0} phút trước";
export const FM_NEW_CREATED_BY_SECOND = "{0} giây trước";

// Create third party token
export const TXT_WAIT_LINK_TRIOS = "Vui lòng chờ kết nối với tài khoản Trios của bạn";
export const TXT_REQUEST_LINK_TRIOS = "Vui lòng kết nối với tài khoản Trios của bạn";
export const TXT_AUTHENTICATING = "Đang thực hiện xác thực";
export const TXT_INFO_LINK_TRIOS =
  "Sau khi đồng bộ, lần đăng nhập tiếp theo bạn có thể sử dụng chức năng đăng nhập với Trios và bật xác thực 2 bước";
export const TXT_LINKED_TRIOS = "Trios đã được liên kết với tài khoản khác";
export const TXT_UNLINKED_TRIOS = "Trios chưa được liên kết";
export const TXT_LINK_TRIOS_SUCCES = "Kết nối thành công";
export const TXT_ERROR_CONTENT = "Đã xảy ra lỗi vui lòng thử lại";
export const TXT_FORM_LINK_TRIOS = `Tài khoản Trios của bạn chưa kết nối với hệ thống Giám Sát Tàu. \nĐể thực hiện việc kết nối, vui lòng điền thông tin đăng nhập`;
export const TXT_LINKED_ANOTHER_TRIOS = "Tài khoản đã được liên kết Trios trước đó. Vui lòng thử lại";

//Messages
export const MS_EXPORT_FAILED = "Xuất dữ liệu không thành công, vui lòng kiểm tra đường truyền và thử lại sau!";
export const MS_UPLOAD_AVATAR_TOO_LARGE = "Ảnh tải lên quá dung lượng cho phép";
export const MS_EDIT_PROFILE_FAILED = "Cập nhật không thành công";
export const MS_EDIT_PROFILE_SUCCESS = "Cập nhật hoàn thành";
export const MS_EXPORT_PROFILE_SUCCESS = "Tải xuống thành công";
export const MS_EXPORT_PROFILE_FAILED = "Tải xuống thất bại";
export const MS_PASSWORD_INVALID = "Mật khẩu cần có tối thiểu 8 ký tự bao gồm chữ số, chữ cái và các ký tự đặc biệt";
export const MS_PASSWORD_LENGTH_INVALID = "Mật khẩu trên 8 kí tự";
export const MS_NEW_SAME_CURRENT_PASSWORD = "Mật khẩu mới phải khác mật khẩu hiện tại";
export const MS_PASSWORD_CONFIRM_MISMATCH = "Mật khẩu mới không trùng khớp";
export const MS_PASSWORD_UNAUTHORIZED = "Mật khẩu hiện tại không chính xác";
export const MS_CHANGE_PASSWORD_SUCCESS = "Thay đổi mật khẩu thành công";
export const MS_TURN_ON_2FA_SUCCESS = "Xác thực 2 bước đã được bật";
export const MS_TURN_OFF_2FA_SUCCESS = "Xác thực 2 bước đã tắt";
export const MS_UPLOAD_SUCCESS = "Upload thành công";
export const MS_UPLOAD_FAILED = "Upload thất bại";
export const MS_UPLOAD_FILE_TOO_LARGE = "File tải lên quá dung lượng cho phép";
export const MS_CREATE_SUCCESS = "Tạo thành công";
export const MS_CREATE_FAILED = "Tạo thất bại";
export const MS_UPDATE_SUCCESS = "Cập nhật thành công";
export const MS_UPDATE_FAILED = "Cập nhật thất bại";
export const MS_ADDRESS_INVALID = "Địa chỉ có thể sử dụng số, chữ thường, chữ hoa, dấu “,”, dấu cách, dấu “_”, dấu “/”";
export const MS_DELETE_SUCCESS = "Xoá thành công";
export const MS_DELETE_ERROR = "Xoá thất bại, vui lòng thử lại";
export const MS_EDIT_SUCCESS = "Chỉnh sửa thành công";
export const MS_ERROR_EMAIL_EXSITED = "Email đã tồn tại trong hệ thống";
export const MS_ERROR_SELECT_PERMISSION_AGAIN = "Vui lòng chọn lại phân quyền";
export const MS_ERROR_CURRENT_PASSWORD_NOT_MATCH = "Mật khẩu hiện tại không đúng";
export const MS_SUCCESS_CREATE_PORT = "Tạo cảng thành công";
export const MS_WAITING_UPLOAD_FILE =
  "Tạo file thành công. Quá trình nhập file đang được xử lý. Vui lòng chờ trong giây lát!";
export const MS_WAITING_EXPORT_FILE = "Yêu cầu đang được xử lý. Vui lòng chờ trong giây lát!";
export const MS_NOT_HAVE_NEW_DATA = "Không có dữ liệu mới!";
export const MS_FILE_NOT_EXIST = "File không tồn tại!";
export const MS_EXPORT_DATA_SUCCESS = "Xuất dữ liệu thành công!";
export const MS_INPUT_CREATE_SHIP_INVALID = "Tên tàu, loại tàu, mã quốc gia và mã MMSI không được để trống";
export const MS_ADD_SHIP_TO_GROUP_SUCCESS = "Thêm vào danh sách giám sát thành công";
export const MS_ADD_SHIP_TO_GROUP_ERROR = "Thêm vào danh sách giám sát thất bại";
export const MS_CREATE_USER_SUCCESS = "Tạo tài khoản thành công";
export const MS_NOT_SELECTED_SHIP = "Chưa có tàu được chọn!";
export const MS_SHIP_IS_EXSITED = "Tàu đã tồn tại";
export const MS_ERROR_GROUP_NAME_EMPTY = "Tên danh sách và lý do giám sát không được bỏ trống!";
export const MS_ADD_VIOLATION_SHIP_SUCCESS = "Thêm tàu vi phạm thành công";
export const MS_VIOLATION_SHIP_EXSITED = "Tàu đã tồn tại trong danh sách vi phạm!";
export const MS_SELECT_DATE_AGAIN = "Vui lòng chọn lại ngày tìm kiếm";
export const MS_ERROR_SELECT_DATE_MORE_THAN_1_MONTH = "Thời gian tìm kiếm dữ liệu lớn hơn 1 tháng. Vui lòng chọn lại";
export const MS_SELECT_SHOW_CLUSTERED_SHIP_TO_SHOW_SHIP_NAME =
  "Vui lòng chọn 'Hiển thị tàu theo nhóm' để xử dụng chức năng hiển thị tên tàu";
export const MS_ERROR_DATA_AREA = "Dữ liệu khu vực không chính xác, vui lòng kiểm tra lại!";
export const MS_CREATE_POINT_AREA_SUCCESS = "Tạo điểm trên bản đồ thành công";
export const MS_UPDATE_POINT_AREA_SUCCESS = "Cập nhật điểm trên bản đồ thành công";
export const MS_DELETE_POINT_AREA_SUCCESS = "Xóa điểm trên bản đồ thành công";

export const OBJ_USER_ACTION = {
  LOGIN: "Đăng nhập",
  LOGOUT: "Đăng xuất",
  CHANGE_PASSWORD: "Đổi mật khẩu",
  TURN_ON_2_FACTOR_AUTHENTICATION: "Bật xác thực 2 lớp",
  TURN_OFF_2_FACTOR_AUTHENTICATION: "Tắt xác thực 2 lớp",
  CREATE_FOLDER: "Tạo folder báo cáo",
  CREATE_REPORT: "Tạo báo cáo",
  SHARE_FILE: "Chia sẻ file",
  SHARE_INFORMATION: "Chia sẻ thông tin cá nhân",
  DELETE_DATA: "Xóa dữ liệu",
};
