import { useEffect } from "react";
import { IconButton, ListItemButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { ExitToApp, ArrowBack } from "@mui/icons-material";
import Cookies from "js-cookie";
import { AppConstant, PathConstant } from "const";
import { onLogout, hasRoleScopes, IS_ADMIN } from "utils";
import { SCOPES } from "const/app.const";
import ConversationActions from "redux-store/conversation.redux";
import ShipActions from "redux-store/ship.redux";
import { AuthService } from "services";
import { useNotificationQueue } from "hooks";
import FolderAction from "redux-store/folder.redux";

const Sidebar = ({ children }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { permissionGroupUid } = useParams();
  const { setNotificationQueue } = useNotificationQueue();

  const userName = Cookies.get(AppConstant.KEY_USER_NAME);
  const is_new_notify = useSelector(state => state.folderRedux.is_new);
  const onClickLogout = () => {
    AuthService.logout();
    dispatch(ConversationActions.conversationReset());
    dispatch(ConversationActions.conversationSet({ isLoginSuccess: false }));
    setNotificationQueue([]);
    onLogout();
  };
  useEffect(() => {
    dispatch(
      FolderAction.getFolderNotifications({
        page: 0,
        size: 0,
        filter: "",
      }),
    );
  }, []);

  const onClickBackHome = () => {
    navigate(PathConstant.HOME);
    dispatch(
      ShipActions.shipSet({
        shipConditions: {
          foreign_f: false,
          capacity_min: AppConstant.SHIP_CAPACITY.min,
          capacity_max: AppConstant.SHIP_CAPACITY.max,
          source_type_list: [],
          group_ids: [],
          handleGroupMembers: {},
          group_members: [],
          custom_area_list: [],
          default_area_list: [],
          area_list: [],
          ports: [],
          ship_type_list: [],
          country_code_list: [],
        },
      }),
    );
  };

  const menuItem = [
    {
      path: PathConstant.ADMIN_NOTIFICATION,
      name: `Thông báo`,
      rightContent: is_new_notify ? is_new_notify : null,
      isShow: true,
      isActive: location.pathname === PathConstant.ADMIN_NOTIFICATION,
    },
    {
      path: PathConstant.ADMIN_ROLE_MANAGEMENT,
      name: "Quản lý vai trò",
      isShow: IS_ADMIN(),
      isActive: location.pathname === PathConstant.ADMIN_ROLE_MANAGEMENT,
    },
    {
      path: PathConstant.ADMIN_PERMISSION_LIST,
      name: "Quản lý nhóm quyền",
      isShow: IS_ADMIN(),
      isActive: [
        PathConstant.ADMIN_PERMISSION_LIST,
        PathConstant.ADMIN_CREATE_PERMISSION,
        PathConstant.ADMIN_UPDATE_PERMISSION.replace(":permissionGroupUid", permissionGroupUid || ""),
      ].includes(location.pathname),
    },
    {
      path: PathConstant.ADMIN_USER,
      name: "Quản lý người dùng",
      isShow: hasRoleScopes(SCOPES.USER_LIST),
      isActive:
        location.pathname === PathConstant.ADMIN_USER || location.pathname.includes(`${PathConstant.ADMIN_USER}/`),
    },
    {
      path: PathConstant.ADMIN_USER_ACTION_MANAGEMENT,
      name: "Quản lý lịch sử truy cập",
      isShow: hasRoleScopes(SCOPES.USER_ACTION_MN_LIST) || hasRoleScopes(SCOPES.USER_ACTION_SUMMARY_LIST),
      isActive: location.pathname === PathConstant.ADMIN_USER_ACTION_MANAGEMENT,
    },
    {
      path: PathConstant.ADMIN_HISTORY,
      name: "Quản lý hệ thống",
      isShow: hasRoleScopes(SCOPES.USER_ACTIVITY),
      isActive: location.pathname === PathConstant.ADMIN_HISTORY,
    },
    {
      path: PathConstant.ADMIN_SHIP,
      name: "Quản lý tàu thuyền",
      isShow: true,
      isActive: location.pathname === PathConstant.ADMIN_SHIP,
    },
    {
      path: PathConstant.ADMIN_HABOR,
      name: "Quản lý bến cảng",
      isShow: hasRoleScopes(SCOPES.HARBOR_LIST),
      isActive: location.pathname === PathConstant.ADMIN_HABOR,
    },
    {
      path: PathConstant.ADMIN_SEAPORT,
      name: "Quản lý cảng biển",
      isShow: hasRoleScopes(SCOPES.PORT_LIST),
      isActive: location.pathname === PathConstant.ADMIN_SEAPORT,
    },
    {
      path: PathConstant.ADMIN_WATCH_LIST,
      name: "Danh sách giám sát ",
      isShow: hasRoleScopes(SCOPES.GROUP_SHIP_LIST),
      isActive:
        location.pathname === PathConstant.ADMIN_WATCH_LIST ||
        location.pathname.includes(PathConstant.ADMIN_GROUP_DETAILS),
    },
    {
      path: PathConstant.ADMIN_DATA_CRAWL_HISTORY,
      name: "Lịch sử thu thập",
      isShow: hasRoleScopes(SCOPES.CRAWLING_HISTORY),
      isActive: location.pathname === PathConstant.ADMIN_DATA_CRAWL_HISTORY,
    },
    {
      path: PathConstant.ADMIN_MANAGE_EVENT_WARNING,
      name: "Quản lý cấu hình cảnh báo",
      isShow: hasRoleScopes(SCOPES.EVENT_WARNING_LIST),
      isActive: location.pathname === PathConstant.ADMIN_MANAGE_EVENT_WARNING,
    },
    {
      path: PathConstant.ADMIN_NOTIFICATION_EVENT_WARNING_MANAGEMENT,
      name: "Quản lý danh sách cảnh báo",
      isShow: hasRoleScopes(SCOPES.NOTIFY_LIST),
      isActive: location.pathname === PathConstant.ADMIN_NOTIFICATION_EVENT_WARNING_MANAGEMENT,
    },
    {
      path: PathConstant.ADMIN_FOLDER_WARNING,
      name: "Quản lý folder báo cáo",
      isShow: true,
      isActive: location.pathname === PathConstant.ADMIN_FOLDER_WARNING,
    },
    {
      path: PathConstant.ADMIN_PROFILE,
      name: "Thông tin cá nhân",
      isShow: true,
      isActive: location.pathname === PathConstant.ADMIN_PROFILE,
    },
  ];

  return (
    <Box className={classes.container}>
      <Box className={classes.topSection}>
        <Box onClick={onClickBackHome}>
          <IconButton size="small" title="Thoát">
            <ArrowBack color="warning" fontSize="small" />
          </IconButton>
        </Box>
        <Typography sx={{ color: "#fff" }}>{userName}</Typography>
        <Box onClick={onClickLogout}>
          <IconButton size="small" title="Đăng xuất">
            <ExitToApp color="warning" fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <Box className={classes.sidebar}>
        {menuItem.map((item, index) => {
          return (
            item.isShow && (
              <ListItemButton key={index} sx={{ padding: 0 }}>
                <Link to={item.path} key={index} className={item.isActive ? classes.active : classes.link}>
                  <Box className={classes.containerItem}>
                    <Box className={classes.textLink}>
                      {item.name}
                      <Typography variant="p" className={classes.rightContent}>
                        {item?.rightContent}
                      </Typography>
                    </Box>
                  </Box>
                </Link>
              </ListItemButton>
            )
          );
        })}
      </Box>
      <main className={classes.main}>{children}</main>
    </Box>
  );
};

export default Sidebar;

const useStyles = makeStyles(theme => ({
  container: {
    background: "#3e4045",
  },

  sidebar: {
    color: "#d9d9da",
    height: "calc(100vh - 62px)",
    width: 260,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0",
    },
  },

  topSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 0",
  },

  main: {
    width: "100%",
  },

  textLink: {
    width: "100%",
    color: "#ffff",
    height: "100%",
    padding: "0 20px",
    justifyContent: "space-between",
    display: "flex",
  },

  active: {
    background: "#ed6c02",
    width: "100%",
    height: "100%",
    transition: "all ease-in 0.1s",
  },

  link: {
    width: "100%",
    height: "100%",
    transition: "all ease-in 0.1s",
  },

  containerItem: {
    width: "100%",
    height: "100%",
    padding: "16px 0",
  },
  rightContent: {},
}));
