import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import MonitoringShipList from "./MonitoringShipList";
import { LangConstant } from "const";

export default function MonitoringShips() {
  const classes = useStyles();

  const trackingShips = useSelector(state => state.shipRedux.trackingShips);
  const isFetching = useSelector(state => state.shipRedux.isFetching);
  return (
    <Box className={classes.monitoringShipContainer}>
      {isFetching && <Box sx={{ paddingLeft: "15px" }}> {LangConstant.TXT_LOADING_GROUP_SHIP} </Box>}
      <Box>
        {trackingShips.map(item => (
          <MonitoringShipList item={item} key={item.uid} />
        ))}
      </Box>
    </Box>
  );
}
const useStyles = makeStyles(theme => ({
  monitoringShipBox: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: 18,
    "&:hover": {
      cursor: "pointer",
    },
  },

  selectFilterCheckBox: {
    display: "flex",
  },

  monitoringListTitle: {
    fontSize: "16px",
    color: "#000",
    userSelect: "none",
  },

  colorBlack: {
    color: "#000",
  },

  monitoringShipContainer: {
    borderRadius: "4px",
  },
}));
