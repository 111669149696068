import { ApiConstant } from "const";
import { createApiWithToken } from "services/config/api.config";
import StringFormat from "string-format";

export const getTypeAreaSaga = data => createApiWithToken().get(ApiConstant.GET_TYPE_AREA, data);

export const getArea = data => createApiWithToken().get(ApiConstant.GET_AREA, data);

export const createCustomArea = data => createApiWithToken().post(ApiConstant.CREATE_CUSTOM_AREA, data);

export const updateCustomArea = data =>
  createApiWithToken().put(StringFormat(ApiConstant.UPDATE_DELETE_AREA, data.uid), data);

export const deleteCustomArea = data =>
  createApiWithToken().delete(StringFormat(ApiConstant.UPDATE_DELETE_AREA, data.uid));

export const uploadKmlFile = data =>
  createApiWithToken().post(ApiConstant.UPLOAD_FILE, data.formData, {
    headers: { mime_type: data.mime_type },
  });

export const getKmlFile = data =>
  createApiWithToken().get(StringFormat(ApiConstant.GET_ATTACHMENT, data.uid), {}, { responseType: "text" });

export const getWeatherPoint = data => createApiWithToken().get(ApiConstant.GET_WEATHER_DATA_DETAIL, data);
