import { memo, useEffect, useState, useRef } from "react";
import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getPositionOfPopup } from "utils";
import { useMapContext } from "hooks";
import { EventBus } from "EventBus";
import { EventConstant, SystemConstant, AppConstant } from "const";

const LayerMenuRightClick = () => {
  const classes = useStyles();
  const { map, mapState, setWeatherDialog } = useMapContext();
  const [dialogPositions, setDialogPositions] = useState({ top: 0, left: 0, bottom: 0, right: 0, display: "none" });
  const [event, setEvent] = useState({ top: 0, left: 0, bottom: 0, right: 0, display: "none" });
  const ref = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handleClose();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleRightClick = e => {
    setEvent(e);
    const pointerEvent = e.originalEvent;
    const { top, left, bottom, right } = getPositionOfPopup(pointerEvent);
    setDialogPositions({ top, left, bottom, right, display: "block" });
  };

  useEffect(() => {
    if (map.current && mapState === "loaded") {
      EventBus.on(EventConstant.MAP_EVENTS.contextmenu, handleRightClick);
    }

    return () => {
      EventBus.off(EventConstant.MAP_EVENTS.contextmenu, handleRightClick);
    };
  }, [map, mapState]);

  const handleClose = () => {
    setDialogPositions(state => ({ ...state, display: "none" }));
  };

  const handleStopPropagation = e => {
    e.stopPropagation();
  };

  return (
    <Box
      ref={ref}
      className={classes.root}
      onClick={handleStopPropagation}
      onDoubleClick={handleStopPropagation}
      onMouseDown={handleStopPropagation}
      onMouseMove={handleStopPropagation}
      onWheel={handleStopPropagation}
      sx={dialogPositions}
      component="section"
    >
      <Box className={classes.formBody}>
        <Button
          variant="text"
          onClick={() => {
            setDialogPositions({ top: 0, left: 0, bottom: 0, right: 0, display: "none" });
            EventBus.emit(EventConstant.POINT_EVENTS.createPoint, event);
          }}
          fullWidth={true}
          classes={{
            root: classes.button,
            startIcon: classes.startIconBox,
            endIcon: classes.endIconBox,
          }}
        >
          <Typography variant="caption" className={classes.buttonTitle}>
            Thêm điểm trên bản đồ
          </Typography>
        </Button>{" "}
        {AppConstant.CURRENT_SYSTEM_MODE === SystemConstant.SYSTEM_MODE.INTERNET && (
          <Button
            variant="text"
            onClick={() => {
              setWeatherDialog({ event: event });
              setDialogPositions({ top: 0, left: 0, bottom: 0, right: 0, display: "none" });
            }}
            fullWidth={true}
            classes={{
              root: classes.button,
              startIcon: classes.startIconBox,
              endIcon: classes.endIconBox,
            }}
          >
            {AppConstant.CURRENT_SYSTEM_MODE === 1 && (
              <Typography variant="caption" className={classes.buttonTitle}>
                Dự báo thời tiết - (Nguồn WWO)
              </Typography>
            )}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default memo(LayerMenuRightClick);

const useStyles = makeStyles(() => ({
  root: {
    position: "fixed",
    width: 225,
    backgroundColor: "#fff",
    zIndex: 1000,
    boxShadow: "0px 0px 2px 2px rgba(0, 0, 0, 0.25)",
    borderRadius: 4,
    cursor: "auto",
  },

  formBody: {
    padding: "2px",
    position: "relative",
  },

  button: {
    justifyContent: "normal",
  },
}));
