import L from "leaflet";
import { D3WindBarb } from "d3-wind-barbs";
import { convertMillisecondsToDateTime } from "utils/date.utils";
import { useMapContext } from "./useMapContext";

export const useWeatherMarker = () => {
  const { weatherLayer } = useMapContext();

  const createWindIcon = windPoint => {
    const markerColor = getWindColor(windPoint.temperature);
    const windBard = new D3WindBarb(windPoint.windSpeed, windPoint.windDirection, {
      bar: {
        stroke: markerColor,
      },
      // size: {
      //   width: 20,
      //   height: 10
      // },
    }).draw();

    windBard.style.overflow = "visible";
    windBard.style.transform = 'scale(0.6)';

    const LeafletIcon = L.divIcon({
      html: windBard.outerHTML,
      // className: "wind-barb",
    });

    return LeafletIcon;
  };

  const createMarker = (lat, lng, icon, info) => {
    const marker = L.marker([lat, lng], { icon: icon });
    let windLevel = getWindLevel(info.windSpeed);
    let clicked = false;

    marker.on("click", async function (e) {
      await this.closePopup();
      clicked = true;
      this.openPopup();
    });

    marker.on("mouseover", function (e) {
      if (!clicked) {
        this.openPopup();
      }
    });

    marker.on("mouseout", function (e) {
      if (!clicked) {
        this.closePopup();
      }
    });

    marker.on("popupclose", function (e) {
      clicked = false;
    });
    marker.bindPopup(
      [
        "<div class='weather-info-tooltip-header'>Thông tin thời tiết</div>",
        "Nhiệt độ :" + info.temperature + "°C <br/>",
        "Hướng gió :" + info.windDirection + "° <br/>",
        "Tốc độ gió :" + convertKnotToMetPerSecond(info.windSpeed) + "m/s " + "(cấp " + windLevel + ")" + "<br/>",
        "Độ cao sóng :" + info.waveHeight + "m <br/>",
        "Hướng sóng :" + info.waveDirection + "° <br/>",
        "Chu kì sóng :" + info.wavePeriod + "s <br/>",
        convertMillisecondsToDateTime(info.time, "vn"),
      ].join(""),
      {
        className: "weather-info-tooltip",
        closeButton: true,
        closeOnEscapeKey: false,
        autoClose: false,
        closeOnClick: false,
      },
    );

    return marker;
  };

  const show = (points = []) => {
    points.forEach(point => {
      const marker = createMarker(point.lat, point.lon, createWindIcon(point), point);
      weatherLayer.current.addLayer(marker);
    });
  };

  const clear = () => {
    weatherLayer.current.clearLayers();
  };

  return { show, clear };
};

const getWindColor = tempF => {
  let temp = Math.floor(tempF);
  if (temp < 0) return "#9d2eb0";
  if (temp > 0 && temp <= 10) return "#663fb4";
  if (temp > 10 && temp <= 20) return "#4055b2";
  if (temp > 20 && temp <= 25) return "#00ffff";
  if (temp > 25 && temp < 30) return "#73ffab";
  if (temp >= 30 && temp <= 40) return "#587cf7";
  return "#fd572d";
};

const getWindLevel = s => {
  if (s > 0 && s <= 0.2) return 0;
  if (s > 0.2 && s <= 1.5) return 1;
  if (s > 1.5 && s <= 3.3) return 2;
  if (s > 3.3 && s <= 5.4) return 3;
  if (s > 5.4 && s <= 7.9) return 4;
  if (s > 7.9 && s <= 10.7) return 5;
  if (s > 10.7 && s <= 13.8) return 6;
  if (s > 13.8 && s <= 17.1) return 7;
  if (s > 17.1 && s <= 20.7) return 8;
  if (s > 20.7 && s <= 24.4) return 9;
  if (s > 24.4 && s <= 28.4) return 10;
  if (s > 28.4 && s <= 32.6) return 11;
  if (s > 32.6 && s <= 36.9) return 12;
  if (s > 36.9 && s <= 41.4) return 13;
  if (s > 41.4 && s <= 46.1) return 14;
  if (s > 46.1 && s <= 50.9) return 15;
  if (s > 50.9 && s <= 56.0) return 16;
  if (s > 56.0 && s <= 61.2) return 17;
  return 18;
};

const convertKnotToMetPerSecond = knot => {
  return (knot * 0.514444).toFixed(3);
};
