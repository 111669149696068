import React from "react";
import { Navigate, Route, Routes, HashRouter, useLocation, useNavigate } from "react-router-dom";
import {
  ShipManagement,
  History,
  PortManagement,
  WatchList,
  DataCrawlHistory,
  EditWatchList,
  UserManagement,
  RoleManagement,
  HaborManagement,
  Profile,
  UserActionManagement,
  NotificationEventWarningManagement,
  PermissionList,
  CreatePermission,
  UpdatePermission,
  NotifyUser,
} from "pages/AdminDashboard";
import HomePage from "pages/HomePage";
import Login from "pages/Login";
import Layout from "layout/Layout";
import { TrackingUserActivity, Loading, NotificationDialog } from "components";
import { useHandleGatewayRequest } from "hooks";
import { IS_ADMIN, hasLogin, hasRoleScopes } from "utils";
import EventWarningManagement from "pages/AdminDashboard/EventWarning";
import FolderManagement from "pages/AdminDashboard/Folder";
import UserActionPersonal from "pages/AdminDashboard/UserManagement/UserActionPersonal";
import UserDetailPersonal from "pages/AdminDashboard/UserManagement/UserDetailPersonal";
import { SCOPES } from "const/app.const";
import { PathConstant } from "const";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const isLogin = hasLogin();

  window.history.navigate = navigate;
  window.history.location = location;

  useHandleGatewayRequest();

  return (
    <div className="App">
      <Loading />
      <TrackingUserActivity />
      <NotificationDialog />
      {!isLogin && location.pathname !== PathConstant.LOGIN_PAGE && (
        <Navigate to={PathConstant.LOGIN_PAGE} replace={true} />
      )}
      {isLogin && location.pathname === PathConstant.LOGIN_PAGE && <Navigate to={PathConstant.HOME} replace={true} />}
      <Routes>
        <Route exact path={PathConstant.ADMIN} element={<Layout />}>
          <Route exact path={PathConstant.ADMIN_NOTIFICATION} element={<NotifyUser />} />
          {IS_ADMIN() && <Route exact path={PathConstant.ADMIN_ROLE_MANAGEMENT} element={<RoleManagement />} />}
          {hasRoleScopes(SCOPES.USER_LIST) && (
            <Route exact path={PathConstant.ADMIN_USER} element={<UserManagement />} />
          )}
          {hasRoleScopes(SCOPES.USER_DETAIL) && (
            <Route exact path={PathConstant.ADMIN_USER_DETAIL} element={<UserDetailPersonal />} />
          )}
          <Route exact path={PathConstant.ADMIN_USER_ACTION_PERSONAL} element={<UserActionPersonal />} />
          <Route exact path={PathConstant.ADMIN_SHIP} element={<ShipManagement />} />
          {hasRoleScopes(SCOPES.USER_ACTIVITY) && (
            <Route exact path={PathConstant.ADMIN_HISTORY} element={<History />} />
          )}
          {hasRoleScopes(SCOPES.HARBOR_LIST) && (
            <Route exact path={PathConstant.ADMIN_HABOR} element={<PortManagement />} />
          )}
          {hasRoleScopes(SCOPES.PORT_LIST) && (
            <Route exact path={PathConstant.ADMIN_SEAPORT} element={<HaborManagement />} />
          )}
          {hasRoleScopes(SCOPES.EVENT_WARNING_LIST) && (
            <Route exact path={PathConstant.ADMIN_MANAGE_EVENT_WARNING} element={<EventWarningManagement />} />
          )}
          {hasRoleScopes(SCOPES.NOTIFY_LIST) && (
            <Route
              exact
              path={PathConstant.ADMIN_NOTIFICATION_EVENT_WARNING_MANAGEMENT}
              element={<NotificationEventWarningManagement />}
            />
          )}
          {hasRoleScopes(SCOPES.GROUP_SHIP_LIST) && (
            <Route exact path={PathConstant.ADMIN_WATCH_LIST} element={<WatchList />} />
          )}
          {hasRoleScopes(SCOPES.CRAWLING_HISTORY) && (
            <Route exact path={PathConstant.ADMIN_DATA_CRAWL_HISTORY} element={<DataCrawlHistory />} />
          )}
          {hasRoleScopes(SCOPES.GROUP_SHIP_UPDATE) && (
            <Route exact path={PathConstant.ADMIN_GROUP_DETAILS_ID} element={<EditWatchList />} />
          )}
          {(hasRoleScopes(SCOPES.USER_ACTION_MN_LIST) || hasRoleScopes(SCOPES.USER_ACTION_SUMMARY_LIST)) && (
            <Route exact path={PathConstant.ADMIN_USER_ACTION_MANAGEMENT} element={<UserActionManagement />} />
          )}
          <Route exact path={PathConstant.ADMIN_PROFILE} element={<Profile />} />
          <Route exact path={PathConstant.ADMIN_FOLDER_WARNING} element={<FolderManagement />} />
          {IS_ADMIN() && <Route exact path={PathConstant.ADMIN_PERMISSION_LIST} element={<PermissionList />} />}
          {IS_ADMIN() && <Route exact path={PathConstant.ADMIN_CREATE_PERMISSION} element={<CreatePermission />} />}
          {IS_ADMIN() && <Route exact path={PathConstant.ADMIN_UPDATE_PERMISSION} element={<UpdatePermission />} />}
          <Route
            exact
            path={`${PathConstant.ADMIN}*`}
            element={<Navigate to={PathConstant.ADMIN_PROFILE} replace={true} />}
          />
        </Route>
        <Route exact path={PathConstant.ROOT} element={<Navigate to={PathConstant.LOGIN_PAGE} replace={true} />} />
        <Route exact path={PathConstant.HOME} element={<HomePage />} />
        <Route exact path={PathConstant.LOGIN_PAGE} element={<Login />} />
      </Routes>
    </div>
  );
}

export default App;
