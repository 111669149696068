import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { PROVINCES } from "const/app.const";
import { makeStyles } from "@mui/styles";
import { LangConstant, SystemConstant } from "const";
import { Close } from "@mui/icons-material";
import ConversationRedux from "redux-store/conversation.redux";
import { useDispatch, useSelector } from "react-redux";
import ReactFlagsSelect from "react-flags-select";
import clsx from "clsx";
import { toDecimalDegree } from "utils";
import { handleSendNotiInSaga } from "utils";

const CreateAddShip = ({ open, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const inputRef = useRef();

  const sucessCreateShip = useSelector(state => state.conversationRedux.sucessCreateShip);
  const shipNameArr = useSelector(state => state.conversationRedux.getTypeShip);
  const createShipErrorMsg = useSelector(state => state.conversationRedux.createShipErrorMsg);

  const [shipName, setShipName] = useState();
  const [backDropCreateShip, setBackDropCreateShip] = useState(false);
  const [valueInput, setValueInput] = useState({
    nameShip: "",
    IMO: "",
    MMSI: "",
    AIS: "",
    chants: "",
    idIdentification: "",
    shipNumber: "",
    supplier: "",
    fishingPort: "",
    auxiliaryFishing: "",
    career: "",
    typeShip: "",
    //
    identityCardOwner: "",
    emailOwner: "",
    addressOwner: "",
    nameOwner: "",
    numberFaxshipOwner: "",
    //
    addressCaptain: "",
    nameCaptain: "",
    weightCaptain: "",
    numberFaxshipCaptain: "",
    draftCaptain: "",
    wattageCaptain: 0,
    shellColorCaptain: "",
    crewNumberCaptain: "",
    trainSpeedCaptain: "",
    catchingSpeedCaptain: "",
    maxSpeedCaptain: "",
    vesselLengthCaptain: "",
    vesselWidthCaptain: 0,
    design_length: 0,
    free_speed: 0,
    capacity: 0,
  });
  const [provincesVN, setProvincesVN] = useState();
  const [nation, setNation] = useState();
  const [statusactivated] = useState();
  const [statusShip] = useState();
  const [phoneNumberOwner] = useState();
  const [phoneNumberCaptain] = useState();
  const [expirationDate] = useState(new Date());
  const [dateOfManufactor] = useState(new Date());
  const [shipLat, setShipLat] = useState({
    deg: 0,
    min: 0,
    sec: 0,
  });
  const [shipLng, setShipLng] = useState({
    deg: 0,
    min: 0,
    sec: 0,
  });

  const onChangeValue = e => {
    setValueInput({ ...valueInput, [e.target.name]: e.target.value });
  };

  const onChangeProvincesVN = e => {
    setProvincesVN(e.target.value);
  };

  const onClickAddShips = () => {
    if (Boolean(nation) && Boolean(valueInput.nameShip) && Boolean(valueInput.MMSI) && Boolean(shipName)) {
      setBackDropCreateShip(true);
      dispatch(
        ConversationRedux.createShip({
          GPS_status: statusShip,
          IMO: valueInput.IMO,
          call_sign: valueInput.chants,
          captain_address: valueInput.addressCaptain,
          captain_fax: valueInput.numberFaxshipCaptain,
          captain_name: valueInput.nameCaptain,
          captain_phone: phoneNumberCaptain,
          color: valueInput.shellColorCaptain,
          country_code: nation,
          sub_fishing_port: valueInput.auxiliaryFishing,
          date_of_manufactor: dateOfManufactor.getTime(),
          expiration_date: expirationDate?.getTime(),
          fishing_velocity: valueInput.catchingSpeedCaptain,
          license_plate: valueInput.shipNumber,
          main_fishing_port: valueInput.fishingPort,
          max_speed: valueInput.maxSpeedCaptain,
          mmsi_code: valueInput.MMSI,
          phone_number: phoneNumberCaptain,
          province: provincesVN,
          ship_name: valueInput.nameShip,
          ship_owner_address: valueInput.addressOwner,
          ship_owner_citizen_identification: valueInput.identityCardOwner,
          ship_owner_email: valueInput.emailOwner,
          ship_owner_fax: valueInput.numberFaxshipOwner,
          ship_owner_name: valueInput.nameOwner,
          ship_owner_phone: phoneNumberOwner,
          ship_type: shipName,
          status: statusactivated,
          supplier: valueInput.supplier,
          weight: valueInput.weightCaptain,
          width: +valueInput?.vesselWidthCaptain,
          career: valueInput.career,
          vessel_draft: valueInput.draftCaptain,
          power: +valueInput.wattageCaptain,
          number_of_sailor: valueInput.crewNumberCaptain,
          ship_captain_citizen_identification: valueInput.idIdentification,
          length: valueInput.vesselLengthCaptain,
          capacity: +valueInput.capacity,
          free_speed: +valueInput.free_speed,
          design_length: +valueInput.design_length,
          ship_lat: toDecimalDegree(shipLat.deg, shipLat.min, shipLat.sec),
          ship_lon: toDecimalDegree(shipLng.deg, shipLng.min, shipLng.sec),
          device_code: valueInput.AIS,
        }),
      );
    } else {
      handleSendNotiInSaga(LangConstant.MS_INPUT_CREATE_SHIP_INVALID, "error");
    }
  };

  const onChangeShipName = event => {
    setShipName(event.target.value);
  };

  useEffect(() => {
    if (sucessCreateShip) {
      onClose();
      setBackDropCreateShip(false);
      handleSendNotiInSaga(LangConstant.MS_CREATE_SUCCESS, "success");
    }
    dispatch(
      ConversationRedux.conversationSet({
        sucessCreateShip: false,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sucessCreateShip]);

  useEffect(() => {
    if (createShipErrorMsg) {
      let errorMsg = createShipErrorMsg.includes("name")
        ? "Tên tàu đã tồn tại!"
        : createShipErrorMsg.includes("imo")
          ? "Mã imo đã tồn tại!"
          : createShipErrorMsg.includes("mmsi_code")
            ? "Mã MMSI đã tồn tại!"
            : "Vui lòng chọn quốc gia và loại tàu hoặc kiểm tra lại thông tin tàu";

      handleSendNotiInSaga(errorMsg, "error");
      setBackDropCreateShip(false);
      dispatch(
        ConversationRedux.conversationSet({
          createShipErrorMsg: null,
        }),
      );
    }
  }, [createShipErrorMsg]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Box className={classes.titleFlex}>
          <Typography variant="h6">Thêm dữ liệu tàu</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContent>
          <Box style={{ paddingTop: 10 }}>
            <Typography className={classes.inputLabel}>
              Tên tàu
              <Box component="span" className={classes.requiredMark}>
                &nbsp;*
              </Box>
            </Typography>
            <TextField
              onChange={onChangeValue}
              name="nameShip"
              fullWidth
              size="small"
              label="Tên tàu"
              variant="outlined"
              value={valueInput.nameShip}
            />
          </Box>
          <Box className={classes.paddingItem}>
            <FormControl size="small" fullWidth required>
              <InputLabel id="demo-simple-select-label">Loại Tàu</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={shipName}
                label="Loại Tàu"
                onChange={onChangeShipName}
              >
                {shipNameArr &&
                  shipNameArr.map((item, index) => (
                    <MenuItem key={index} value={item.type}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.paddingItem}>
            <Typography className={classes.inputLabel}>
              Quốc gia
              <Box component="span" className={classes.requiredMark}>
                &nbsp;*
              </Box>
            </Typography>
            <ReactFlagsSelect
              placeholder="Chọn quốc gia"
              searchable
              fullWidth
              selected={nation}
              onSelect={code => setNation(code)}
            />
          </Box>

          {nation === "VN" ? (
            <Box className={classes.paddingItem}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Tỉnh thành Việt Nam</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={provincesVN}
                  onChange={onChangeProvincesVN}
                  label="tỉnh thành Việt Nam"
                >
                  {PROVINCES.map((item, index) => (
                    <MenuItem key={index} value={item.contry}>
                      {item.contry}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          ) : (
            ""
          )}
          <TextField
            onChange={onChangeValue}
            name="IMO"
            fullWidth
            size="small"
            label="Số hiệu IMO"
            variant="outlined"
          />
          <Box className={classes.paddingItem}>
            <Typography className={classes.inputLabel}>
              Số hiệu MMSI
              <Box component="span" className={classes.requiredMark}>
                &nbsp;*
              </Box>
            </Typography>
            <TextField
              onChange={onChangeValue}
              name="MMSI"
              fullWidth
              size="small"
              label="Số hiệu MMSI"
              variant="outlined"
            />
          </Box>
          <TextField
            onChange={onChangeValue}
            name="AIS"
            fullWidth
            size="small"
            label="Mã thiết bị AIS"
            variant="outlined"
          />
          <Box className={classes.latlngFields}>
            <Box className={classes.formInputField}>
              <Typography className={classes.formLabel}>Vĩ độ: </Typography>
              <Box className={classes.coordBox}>
                <OutlinedInput
                  classes={{
                    root: classes.coordInput,
                    input: clsx(classes.formInput, classes.coordPaddingInput),
                  }}
                  size="small"
                  placeholder="Độ"
                  value={shipLat.deg}
                  inputProps={{
                    type: "number",
                    min: -90,
                    max: 90,
                  }}
                  onChange={e => {
                    let value = e.target.value;
                    if (-90 < value && value < 90) {
                      setShipLat(state => ({
                        ...state,
                        deg: value,
                      }));
                    }
                  }}
                  endAdornment={<Typography className={classes.inputUnit}>&deg;</Typography>}
                />
                <OutlinedInput
                  classes={{
                    root: classes.coordInput,
                    input: clsx(classes.formInput, classes.coordPaddingInput),
                  }}
                  size="small"
                  placeholder="Phút"
                  value={shipLat.min}
                  inputProps={{
                    type: "number",
                    min: 0,
                    max: 60,
                  }}
                  onChange={e => {
                    let value = e.target.value;
                    if (0 <= value && value < 60)
                      setShipLat(state => ({
                        ...state,
                        min: value,
                      }));
                  }}
                  endAdornment={<Typography className={classes.inputUnit}>'</Typography>}
                />
                <OutlinedInput
                  classes={{
                    root: classes.coordInput,
                    input: clsx(classes.formInput, classes.coordPaddingInput),
                  }}
                  size="small"
                  placeholder="Giây"
                  value={shipLat.sec}
                  inputProps={{
                    type: "number",
                    min: 0,
                    max: 60,
                  }}
                  onChange={e => {
                    let value = e.target.value;
                    if (0 <= value && value < 60)
                      setShipLat(state => ({
                        ...state,
                        sec: value,
                      }));
                  }}
                  endAdornment={<Typography className={classes.inputUnit}>"</Typography>}
                />
              </Box>
            </Box>
            <Box className={classes.formInputField}>
              <Typography className={classes.formLabel}>Kinh độ: </Typography>
              <Box className={classes.coordBox}>
                <OutlinedInput
                  classes={{
                    root: classes.coordInput,
                    input: clsx(classes.formInput, classes.coordPaddingInput),
                  }}
                  size="small"
                  placeholder="Độ"
                  value={shipLng.deg}
                  inputProps={{
                    type: "number",
                    min: -180,
                    max: 180,
                  }}
                  onChange={e => {
                    let value = e.target.value;
                    if (-180 < value && value < 180)
                      setShipLng(state => ({
                        ...state,
                        deg: value,
                      }));
                  }}
                  endAdornment={<Typography className={classes.inputUnit}>&deg;</Typography>}
                />
                <OutlinedInput
                  classes={{
                    root: classes.coordInput,
                    input: clsx(classes.formInput, classes.coordPaddingInput),
                  }}
                  size="small"
                  placeholder="Phút"
                  value={shipLng.min}
                  inputProps={{
                    type: "number",
                    min: 0,
                    max: 60,
                  }}
                  onChange={e => {
                    let value = e.target.value;
                    if (0 <= value && value < 60)
                      setShipLng(state => ({
                        ...state,
                        min: value,
                      }));
                  }}
                  endAdornment={<Typography className={classes.inputUnit}>'</Typography>}
                />
                <OutlinedInput
                  classes={{
                    root: classes.coordInput,
                    input: clsx(classes.formInput, classes.coordPaddingInput),
                  }}
                  size="small"
                  placeholder="Giây"
                  value={shipLng.sec}
                  inputProps={{
                    type: "number",
                    min: 0,
                    max: 60,
                  }}
                  onChange={e => {
                    let value = e.target.value;
                    if (0 <= value && value < 60)
                      setShipLng(state => ({
                        ...state,
                        sec: e.target.value,
                      }));
                  }}
                  endAdornment={<Typography className={classes.inputUnit}>"</Typography>}
                />
              </Box>
            </Box>
          </Box>
          <Box className={classes.paddingItem}>
            <TextField
              onChange={onChangeValue}
              name="chants"
              fullWidth
              size="small"
              label="Hô hiệu"
              variant="outlined"
            />
          </Box>
          <TextField
            onChange={onChangeValue}
            name="idIdentification"
            fullWidth
            size="small"
            label="ID nhận dạng"
            variant="outlined"
          />
        </DialogContent>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickAddShips}>Thêm dữ liệu tàu</Button>
      </DialogActions>
      <Backdrop sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }} open={backDropCreateShip}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};

export default CreateAddShip;

const useStyles = makeStyles(theme => ({
  paddingItem: {
    padding: "10px 0",
  },

  titleShipOwner: {
    color: "#000",
  },

  containerPicker: {
    display: "flex",
    width: "100%",
    "&>*:first-child": {
      marginRight: 10,
    },
    "&>*": {
      width: "50%",
    },
  },

  subPickerPadding: {
    paddingLeft: 10,
  },

  inputPaddingTop: {
    paddingTop: 10,
  },

  titleFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  paddingBottom: {
    paddingBottom: 10,
  },

  shipTypeSelect: {
    maxHeight: 300,
    overflow: "auto",
  },

  shipNameInput: {
    height: 40,
  },

  latlngFields: {
    marginTop: 10,
    display: "flex",
    justifyContent: "space-between",
    "&>:first-child": {
      marginRight: 10,
    },
  },

  coordInput: {
    width: "30%",
    height: 32,
    paddingRight: 0,
  },

  formInput: {
    padding: "0 10px",
    maxHeight: "100%",
    height: "100%",
    fontSize: 14,
  },

  coordPaddingInput: {
    paddingRight: 0,
  },

  coordBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },

  inputUnit: {
    backgroundColor: "#DDD",
    height: "100%",
    padding: "0 8px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },

  formLabel: {
    fontSize: 15,
    fontWeight: 500,
    minWidth: "max-content",
    marginRight: 20,
    marginTop: 10,
    width: "calc(35% - 20px)",
  },

  formInputField: {
    width: "45%",
  },

  requiredMark: {
    color: "red",
  },

  inputLabel: {
    marginBottom: 10,
    fontSize: 14,
    fontWeight: 500,
  },
}));
