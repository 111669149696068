import React, { Fragment, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  OutlinedInput,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Close, Search, KeyboardArrowDown } from "@mui/icons-material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { addDays, isValid } from "date-fns";
import { AppConstant, FormatConstant, LangConstant } from "const";
import { convertTimeStampToDate, getEndOfDay, getStartOfDay } from "utils/date.utils";
import ShipAction from "redux-store/ship.redux";
import { useMapContext } from "hooks";
import { handleSendNotiInSaga, convertCoordinates, dragElement } from "utils";
import getCountries from "react-select-country-list";
import FilterByManyNation from "pages/HomePage/Sidebar/Filter/FilterByManyNation";
import FilterByShipType from "pages/HomePage/Sidebar/Filter/FilterByShipType";
import clsx from "clsx";
import countryList from "react-select-country-list";

const SearchInAreaDialog = ({ onClose, data: areaDetail }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const id = AREA_SHIP_LIST_CONTAINER;

  const { map } = useMapContext();

  const shipInAreaData = useSelector(state => state.shipRedux.shipInAreaData);
  const isFetchingAreaData = useSelector(state => state.shipRedux.isFetchingAreaData);
  const shipConditions = useSelector(state => state.shipRedux.shipConditions);
  const countryCodes = useMemo(() => countryList().getData(), []);

  const [from, setFrom] = useState((getStartOfDay(new Date()) - 24 * 60 * 60) * 1000);
  const [to, setTo] = useState(getEndOfDay(new Date()) * 1000);
  const [shipName, setShipName] = useState("");
  const [data, setData] = useState(null);
  const [selectedShips, setSelectedShips] = useState([]);
  const [listShipDataWithPage, setListShipDataWithPage] = useState([]);
  const countries = useMemo(getCountries, []);
  const shipTypes = useSelector(state => state.shipRedux.shipTypes);

  const [isOpenShipTypeList, setIsOpenShipTypeList] = useState(false);
  const [isOpenCountryCodeList, setIsOpenCountryCodeList] = useState(false);

  const paginationListShipWithPage = (listShip, page = AppConstant.FIRST_PAGE, size = AppConstant.PAGE_SIZE) => {
    const start = (page - 1) * size;
    const end = page * size;
    const listShipData = listShip?.[1];
    return {
      page: page,
      total: listShipData?.length,
      data: listShipData?.slice(start, end),
      totalPage: Math.ceil(listShipData?.length / size),
    };
  };
  useEffect(() => {
    setListShipDataWithPage(paginationListShipWithPage(data));
  }, [data]);

  const onChangeShipName = event => {
    let value = event.target.value;
    setShipName(value);
  };

  const onExportFile = () => {
    dispatch(
      ShipAction.getShipInArea({
        fromDate: from,
        toDate: to,
        shipName: shipName,
        size: 20,
        areaUid: areaDetail.uid,
        shipTypeUids:
          shipConditions.ship_type_list.length === 0 || shipConditions.ship_type_list === null
            ? null
            : shipConditions.ship_type_list.toString(),
        countryCodes:
          shipConditions.country_code_list.length === 0 ||
          shipConditions.country_code_list.length === countryCodes.length
            ? null
            : shipConditions.country_code_list.toString(),
        exportF: 1,
      }),
    );
  };

  const onSearch = () => {
    onChangePage(1);
    dispatch(
      ShipAction.shipSet({
        isFetchingAreaData: true,
      }),
    );
    dispatch(
      ShipAction.getShipInArea({
        fromDate: from,
        toDate: to,
        page: 0,
        shipName: shipName,
        size: 20,
        areaUid: areaDetail.uid,
        shipTypeUids:
          shipConditions.ship_type_list.length === 0 || shipConditions.ship_type_list === null
            ? null
            : shipConditions.ship_type_list.toString(),
        countryCodes:
          shipConditions.country_code_list.length === 0 ||
          shipConditions.country_code_list.length === countryCodes.length
            ? null
            : shipConditions.country_code_list.toString(),
      }),
    );
  };

  const onChangePage = (event, newPage) => {
    setListShipDataWithPage(paginationListShipWithPage(data, newPage));
  };

  const onViewShipLocation = event => {
    event.preventDefault();
    event.stopPropagation();

    let parentContainer = document.getElementById(AREA_SHIP_LIST_CONTAINER);
    if (parentContainer) {
      parentContainer.style.transitionDuration = "500ms";
      parentContainer.style.transform = 'scale(0.4)';
      parentContainer.style.bottom = "-160px";
      parentContainer.style.right = "-230px";

      setTimeout(() => {
        parentContainer.style.removeProperty("transition-duration");
      }, 506);
    }

    // TODO: Handle find ship location in area
    // console.log(selectedShips, filterShipResult, shipInAreaData);
    // dispatch(
    //   ShipAction.shipSet({
    //     shipConditions: {
    //       foreign_f: false,
    //       capacity_min: AppConstant.SHIP_CAPACITY.min,
    //       capacity_max: AppConstant.SHIP_CAPACITY.max,
    //       source_type_list: [],
    //       group_ids: [],
    //       handleGroupMembers: {},
    //       group_members: [],
    //       custom_area_list: [],
    //       default_area_list: [],
    //       area_list: [],
    //       ports: [],
    //       ship_type_list: [],
    //       country_code_list: [],
    //     },
    //   }),
    // );
  };

  const onDismissSelecting = () => {
    setSelectedShips([]);
    dispatch(ShipAction.requestFilterShips({ bounds: map.current.getBounds() }));
  };

  const onViewShipList = () => {
    let parentContainer = document.getElementById(AREA_SHIP_LIST_CONTAINER);
    if (parentContainer) {
      parentContainer.style.transitionDuration = "500ms";
      parentContainer.style.transform = 'scale(1)';
      parentContainer.style.bottom = "20%";
      parentContainer.style.right = "30%";

      setTimeout(() => {
        parentContainer.style.removeProperty("transition-duration");
      }, 506);
    }
  };

  const onPreventPropagation = event => {
    event.stopPropagation();
  };

  const onErrorInvalidTime = () => {
    handleSendNotiInSaga(LangConstant.MS_SELECT_DATE_AGAIN, "error");
  };

  const onErrorOutOfRange = () => {
    handleSendNotiInSaga(LangConstant.MS_ERROR_SELECT_DATE_MORE_THAN_1_MONTH, "error");
  };

  const onJumpToShipNotification = (event, data) => {
    onViewShipLocation(event);
    dispatch(ShipAction.requestFilterShips({ bounds: map.current.getBounds(), selectShip: data?.shipUid }));
  };

  const onCloseDialog = () => {
    dispatch(
      ShipAction.shipSet({
        isChangingFilter: false,
        shipConditions: Object.assign(shipConditions, { ship_type_list: [], country_code_list: [] }),
      }),
    );
    onClose();
  };

  useEffect(() => {
    if (shipInAreaData.listData) {
      const listData = shipInAreaData.listData;
      setData(listData);
    }
  }, [shipInAreaData]);

  useEffect(() => {
    onSearch();
  }, []);
  useEffect(() => {
    if (id) {
      dragElement(id);
    }
  }, [id]);

  return (
    <Box className={Boolean(areaDetail) ? classes.dialogPaper : "hidden"} id={AREA_SHIP_LIST_CONTAINER}>
      <Box className={classes.shipListHeader} id={AREA_SHIP_LIST_CONTAINER + "header"}>
        <Typography className={classes.areaName}>{areaDetail.name}</Typography>
        <IconButton onClick={onCloseDialog} className={classes.closeBtn}>
          <Close />
        </IconButton>
      </Box>
      <OutlinedInput
        classes={{
          root: classes.searchRoot,
          input: classes.searchInput,
        }}
        startAdornment={<Search />}
        onChange={onChangeShipName}
        placeholder="Tìm kiếm tàu trong khu vực"
        onSelect={onPreventPropagation}
        onSelectCapture={onPreventPropagation}
        onMouseDownCapture={onPreventPropagation}
        onMouseDown={onPreventPropagation}
      />
      <Box className={classes.countrySelector}>
        <Box className={classes.dataListBox}>
          <Box className={classes.dataList} onClick={() => setIsOpenShipTypeList(!isOpenShipTypeList)}>
            {LangConstant.TXT_SHIP_TYPE} <KeyboardArrowDown />
          </Box>
          <Box
            className={clsx(classes.optionShow, {
              [classes.optionHidden]: !isOpenShipTypeList,
              [classes.optionScroll]: true,
            })}
          >
            <FilterByShipType />
          </Box>
        </Box>

        <Box className={classes.dataListBox}>
          <Box className={classes.dataList} onClick={() => setIsOpenCountryCodeList(!isOpenCountryCodeList)}>
            {LangConstant.TXT_NATIONALITY} <KeyboardArrowDown />
          </Box>
          <Box
            className={clsx(classes.optionShow, {
              [classes.optionHidden]: !isOpenCountryCodeList,
              [classes.optionScroll]: true,
            })}
          >
            <FilterByManyNation />
          </Box>
        </Box>

        <Box
          className={classes.datePickerContainer}
          onSelect={onPreventPropagation}
          onSelectCapture={onPreventPropagation}
          onMouseDownCapture={onPreventPropagation}
          onMouseDown={onPreventPropagation}
        >
          <Box display="flex" marginX={"5px"}>
            <Box style={{ paddingRight: 10 }}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                onSelect={onPreventPropagation}
                onSelectCapture={onPreventPropagation}
                onMouseDownCapture={onPreventPropagation}
                onMouseDown={onPreventPropagation}
              >
                <DateTimePicker
                  label="Từ ngày"
                  inputFormat={FormatConstant.FM_DD_MM_YYY_HH_MM}
                  onChange={newValue => {
                    if (newValue) {
                      if (isValid(newValue)) {
                        setFrom(newValue.getTime());
                        if (addDays(newValue, 31).getTime() < to) {
                          onErrorOutOfRange();
                        }
                      } else {
                        onErrorInvalidTime();
                      }
                    }
                  }}
                  value={new Date(from)}
                  maxDate={new Date(new Date().getTime() - 24 * 60 * 60 * 1000)}
                  renderInput={params => <TextField size="small" {...params} />}
                />
              </LocalizationProvider>
            </Box>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              onSelect={onPreventPropagation}
              onSelectCapture={onPreventPropagation}
              onMouseDownCapture={onPreventPropagation}
              onMouseDown={onPreventPropagation}
            >
              <DateTimePicker
                label="Tới ngày"
                inputFormat={FormatConstant.FM_DD_MM_YYY_HH_MM}
                value={new Date(to)}
                minDate={new Date(from + 24 * 60 * 60 * 1000)}
                maxDate={addDays(new Date(from), 31)}
                onChange={newValue => {
                  if (newValue) {
                    if (isValid(newValue)) {
                      setTo(newValue.getTime());
                      if (addDays(newValue, 31).getTime() > from) {
                        onErrorOutOfRange();
                      }
                    } else {
                      onErrorInvalidTime();
                    }
                  }
                }}
                renderInput={params => <TextField size="small" {...params} />}
              />
            </LocalizationProvider>
          </Box>
          <Button
            variant="contained"
            className={classes.searchButton}
            onClick={onSearch}
            disableElevation
            disabled={
              isFetchingAreaData ||
              new Date(from).getTime() >= new Date().getTime() - 24 * 60 * 60 * 1000 ||
              new Date(to).getTime() <= new Date(from).getTime() ||
              !from ||
              !to ||
              !isValid(from) ||
              !isValid(to) ||
              addDays(new Date(from), 31).getTime() < to
            }
          >
            Tìm kiếm
          </Button>
        </Box>
      </Box>

      <Box className={classes.mainContentContainer} onClick={onViewShipList}>
        {isFetchingAreaData ? (
          <Box className={classes.loadingBox}>
            <CircularProgress size={24} className={classes.loadingAnimation} />
            <Typography variant="caption">Đang tải dữ liệu</Typography>
          </Box>
        ) : (
          data && (
            <>
              <Box display="flex">
                <Typography alignItems="center" display="flex">
                  Danh sách tàu: ({data?.[1]?.length})
                </Typography>
                <Button
                  variant="text"
                  onClick={onExportFile}
                  color="primary"
                  disableElevation
                  className={classes.exportFileBtn}
                  disabled={data.length <= 0}
                >
                  Xuất báo cáo
                </Button>
              </Box>
              {listShipDataWithPage?.total ? (
                <>
                  <TableContainer
                    component={Paper}
                    className={classes.tableContainer}
                    onScroll={onPreventPropagation}
                    onScrollCapture={onPreventPropagation}
                    onWheel={onPreventPropagation}
                    onWheelCapture={onPreventPropagation}
                    onSelect={onPreventPropagation}
                    onSelectCapture={onPreventPropagation}
                    onMouseDownCapture={onPreventPropagation}
                    onMouseDown={onPreventPropagation}
                  >
                    <Table stickyHeader aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>STT</TableCell>
                          <TableCell className={classes.shipName}>Tên tàu</TableCell>
                          <TableCell>Loại tàu</TableCell>
                          <TableCell>IMO</TableCell>
                          <TableCell>MMSI</TableCell>
                          <TableCell>Chiều dài</TableCell>
                          <TableCell>Quốc tịch</TableCell>
                          <TableCell>Thời gian ghi nhận</TableCell>
                          <TableCell>Vị trí hiện tại</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.tableBody}>
                        {listShipDataWithPage?.data?.map((row, index) => (
                          <Fragment key={row.shipUid}>
                            <TableRow key={row.shipName} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                              <TableCell component="th" scope="row">
                                {listShipDataWithPage.page > 0
                                  ? index + 1 + (listShipDataWithPage.page - 1) * 10
                                  : index + 1}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {row.shipName}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {shipTypes.find(item => item.id === row.shipType)?.type || row.shipTypeName}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {row.imo}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {row.mmsi}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {row.length}(m)
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {row.countryCode}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                Từ {convertTimeStampToDate(row.timeIn / 1000, FormatConstant.FM_DD_MM_YYY_HH_MM)} đến{" "}
                                {convertTimeStampToDate(row.timeOut / 1000, FormatConstant.FM_DD_MM_YYY_HH_MM)}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                onClick={e => onJumpToShipNotification(e, row)}
                                sx={{ cursor: "pointer", color: "#1976d2", fontWeight: "450" }}
                              >
                                {convertCoordinates(Number(row?.shipLat), Number(row?.shipLong))}
                              </TableCell>
                            </TableRow>
                          </Fragment>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box className={classes.tableAction}>
                    {/* <Button
                      disableElevation
                      className={classes.dismissButton}
                      onClick={onDismissSelecting}
                      variant="contained"
                      color="inherit"
                    >
                      Bỏ chọn
                    </Button> */}
                    <Button
                      disableElevation
                      className={classes.viewLocationButton}
                      onClick={onViewShipLocation}
                      variant="contained"
                      color="primary"
                      // disabled={selectedShips.length <= 0}
                    >
                      Thu nhỏ
                    </Button>
                  </Box>
                  <Pagination
                    onChange={onChangePage}
                    page={listShipDataWithPage.page}
                    sx={{ paddingTop: 2 }}
                    count={listShipDataWithPage.totalPage}
                    variant="outlined"
                    color="primary"
                  />
                </>
              ) : (
                <>Không có kết quả tìm kiếm</>
              )}
            </>
          )
        )}
      </Box>
    </Box>
  );
};

SearchInAreaDialog.propTypes = {
  onClose: PropTypes.func,
  data: PropTypes.object,
};

SearchInAreaDialog.defaultProps = {
  onClose: () => {},
  data: {},
};

const AREA_SHIP_LIST_CONTAINER = "area-ship-list-container";

export default SearchInAreaDialog;

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    padding: 15,
    borderRadius: 4,
    position: "absolute",
    zIndex: 1000,
    height: 750,
    width: 1000,
    left: `calc(50% - 400px)`,
    top: `calc(50% - 300px)`,
    cursor: "default",
    background: "#fff",
    boxSizing: "border-box",
    boxShadow: "0px 0px 4px 2px rgba(0, 0, 0, 0.4)",
    display: "flex",
    flexDirection: "column",
    scale: 1,
  },

  shipListHeader: {
    display: "flex",
    marginBottom: 10,
    position: "relative",
    height: 34,
    alignItems: "center",
    cursor: "move",
  },

  areaName: {
    fontSize: 20,
    fontWeight: 700,
  },

  closeBtn: {
    position: "absolute",
    right: 0,
    "& > svg": {
      fontSize: 18,
    },
  },

  searchRoot: {
    borderRadius: 4,
    width: "100%",
  },

  searchInput: {
    padding: "8px 10px",
  },

  datePickerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },

  countrySelector: {
    display: "flex",
    marginTop: 20,
  },

  searchButton: {
    backgroundColor: "#1D83C3",
    borderRadius: 4,
    padding: "6px 10px",
    color: "white",
    width: "100px",
    alignSelf: "center",
    textTransform: "none",
  },

  showPositionButton: {
    textTransform: "none",
  },

  mainContentContainer: {
    overflow: "hidden",
  },

  loadingBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    margin: "16px 0",
  },

  tableContainer: {
    overflow: "auto",
    maxHeight: 420,
  },

  tableBody: {},

  tableAction: {
    marginTop: 20,
  },

  dismissButton: {
    color: "#1D83C3",
    border: "1px solid #1D83C3",
    backgroundColor: "white",
    marginRight: 10,
  },

  exportFileBtn: {
    textTransform: "none",
    margin: "8px",
  },

  loadingAnimation: {
    marginBottom: 15,
  },

  shipName: {
    minWidth: 150,
  },

  optionShow: {
    display: "block",
    maxHeight: 350,
    borderRight: "unset",
    position: "absolute",
    backgroundColor: "white",
    width: "370px",
    zIndex: 999,
  },

  optionHidden: {
    display: "none",
  },

  optionScrollBox: {
    position: "absolute",
    top: "-220px",
    height: "220px",
    overflowY: "scroll",
    border: "1px solid #0033",
  },

  optionScroll: {
    height: "220px",
    overflowY: "scroll",
    border: "1px solid #0033",
  },

  dataListBox: {
    position: "relative",
    marginRight: 8,
  },

  dataList: {
    paddingLeft: 10,
    paddingRight: 10,
    height: "40px",
    width: "150px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    border: "1px solid gainsboro",
  },
}));
