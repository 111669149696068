import L from "leaflet";
import domtoimage from "dom-to-image";
import { convert2Coords, checkAndSetOpacityPopup } from "utils";
import { convertMillisecondsToDateTime } from "utils/date.utils";
import { useMapContext } from "./useMapContext";

export const useCaptureLayer = () => {
  const { captureLayer } = useMapContext();

  const createIcon = course => {
    const html = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" style="width: auto; height: 24px; transform: rotate(${course}deg);">
                    <g id="Arrow_GPS" data-name="Arrow GPS">
                      <path d="M26.9336,28.6411l-10-26a1,1,0,0,0-1.8672,0l-10,26A1,1,0,0,0,6.6,29.8L16,22.75l9.4,7.05a1,1,0,0,0,1.5332-1.1587Z" style="fill: #1976d2; stroke: black"/>
                    </g>
                  </svg>`;

    return new L.DivIcon({
      html: html,
      className: "leaflet-tracking-div-icon",
    });
  };

  const createMarker = (lat, lng, icon, info) => {
    const marker = L.marker([lat, lng], { icon: icon });
    const coords = convert2Coords(info.lat, info.long);
    // TODO: Đợi BA confirm UX lại cho phần này vì nó chưa tối ưu
    marker.bindPopup(
      info.isSpecial
        ? [
            "Kinh độ: " + coords.lng,
            "Vĩ độ: " + coords.lat,
            "Vận tốc: " + info.speed + " knot",
            info.heading ? "Hướng mũi tàu: " + info.heading + "°" : "Hướng mũi tàu: N/A",
            "Hướng tàu: " + info.course + "°",
            convertMillisecondsToDateTime(info.time),
          ].join("<br/>")
        : convertMillisecondsToDateTime(info.time),
      {
        closeButton: true,
        closeOnEscapeKey: false,
        autoClose: false,
        closeOnClick: false,
      },
    );
    return marker;
  };
  const show = ship => {
    // const points = ship.locations.map(marker => {
    //   const markerLayer = createMarker(marker.lat, marker.long, createIcon(marker.course), marker);
    //   captureLayer.current.addLayer(markerLayer);
    //   markerLayer.openPopup();
    //   return markerLayer;
    // });
    let lastTime = null;

    const points = ship.locations
      .filter(marker => {
        if (marker.isSpecial) {
          lastTime = marker.time;
          return true;
        }
        if (lastTime === null) {
          lastTime = marker.time;
          return true;
        }

        const thirtyMinutesInMilliseconds = 30 * 60 * 1000;
        if (marker.time - lastTime >= thirtyMinutesInMilliseconds) {
          lastTime = marker.time;
          return true;
        }

        return false;
      })
      .map(marker => {
        const markerLayer = createMarker(marker.lat, marker.long, createIcon(marker.course), marker);
        captureLayer.current.addLayer(markerLayer);
        markerLayer.openPopup();
        return markerLayer;
      });

    const polyline = L.polyline(points.map(point => point.getLatLng()));
    captureLayer.current.addLayer(polyline);
    checkAndSetOpacityPopup();
  };

  const showAll = (ship, maxDeflection, maxCourseDeviation) => {
    const captureShip = {
      ...ship,
      locations: ship.location_history.map((location, index) => {
        if (index === 0 || index === ship.location_history.length - 1) {
          return { ...location, isSpecial: true };
        }
        const prevLocation = ship.location_history[index - 1];
        if (location.course - prevLocation.course >= maxDeflection) {
          return { ...location, isSpecial: true };
        }
        if (prevLocation.speed !== 0 && (location.speed / prevLocation.speed) * 100 >= maxCourseDeviation) {
          return { ...location, isSpecial: true };
        }
        return { ...location, isSpecial: false };
      }),
    };
    show(captureShip);
  };

  const showSpecials = (ship, maxDeflection, maxCourseDeviation) => {
    const captureShip = {
      ...ship,
      locations:
        ship.location_history
          .reduce((output, location, index) => {
            if (output.length === 0) {
              output.push([location]);
            } else {
              const lastArea = output[output.length - 1];
              const prevLocation = ship.location_history[index - 1];
              let isSpecial = false;
              if (Math.abs(location.course - prevLocation.course) >= maxDeflection) {
                isSpecial = true;
              } else if (
                (prevLocation.speed === 0 && location.speed !== 0) ||
                (prevLocation.speed !== 0 && (location.speed / prevLocation.speed) * 100 >= maxCourseDeviation) ||
                (prevLocation.speed !== 0 && (location.speed / prevLocation.speed) * 100 <= 200 - maxCourseDeviation)
              ) {
                isSpecial = true;
              }
              if (isSpecial) {
                if (lastArea.length < 10) {
                  output[output.length - 1] = [...lastArea, location];
                } else {
                  output.push([location]);
                }
              } else {
                if (lastArea.length < 4) {
                  output[output.length - 1] = [];
                } else {
                  output.push([]);
                }
              }
            }
            return output;
          }, [])
          .sort((prev, next) => next.length - prev.length)
          .filter(area => area.length >= 4)
          .find((_, index) => index === 0) || [],
    };
    show(captureShip);
    if (captureShip.locations.length === 0) return null;
    const polyline = L.polyline(captureShip.locations.map(location => new L.LatLng(location.lat, location.long)));
    return polyline.getBounds();
  };

  const clear = () => {
    captureLayer.current.clearLayers();
  };

  const capture = id => {
    return new Promise((resolve, reject) => {
      try {
        const container = document.getElementById(id);
        domtoimage
          .toJpeg(container, {
            filter: node => node.nodeName !== "SECTION" && node.className !== "leaflet-control-container",
          })
          .then(base64 => {
            const canvas = document.createElement("canvas");
            canvas.width = container.clientWidth;
            canvas.height = container.clientHeight;
            const context = canvas.getContext("2d");
            const img = new Image();
            img.style.width = canvas.width + "px";
            img.style.height = canvas.height + "px";
            img.onload = function () {
              context.clearRect(0, 0, canvas.width, canvas.height);
              context.drawImage(img, 0, 0);
              const dataURL = canvas.toDataURL("image/jpeg");
              canvas.remove();
              resolve(dataURL);
            };
            img.src = base64;
          });
      } catch (error) {
        reject(error);
      }
    });
  };

  return { showAll, showSpecials, clear, capture };
};
