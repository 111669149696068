import React from "react";
import clsx from "clsx";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useMapContext } from "hooks";
import ShipManage from "./ShipManage";
import ShipMonitoring from "./ShipMonitoring";

export default function Sidebar() {
  const classes = useStyles();
  const { isShowSidebar, toggleShowShipManage } = useMapContext();

  return (
    <Box
      sx={{
        zIndex: 1000,
        position: "fixed",
        top: "60px",
        left: 0,
        width: "360px",
        transition: "all .1s linear",
        transform: isShowSidebar ? "translateX(0)" : "translateX(-360px)",
        boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <ShipManage />
        <ShipMonitoring />
      </Box>
      <Box className={clsx(classes.btnShipManage)} onClick={toggleShowShipManage}>
        {isShowSidebar ? <ArrowBackIosNew fontSize="small" /> : <ArrowForwardIos fontSize="small" />}
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  btnShipManage: {
    height: "40px",
    width: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "4px",
    borderRadius: "0 4px 4px 0",
    backgroundColor: "#fff",
    cursor: "pointer",
    position: "absolute",
    top: "50%",
    right: "0",
    transform: "translateX(31px)",
  },
}));
