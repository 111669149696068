import { WEB_SOCKET_URL } from "const/api.const";
import SockJS from "sockjs-client";
import { over } from "stompjs";
import Cookies from "js-cookie";
import { AppConstant, SystemConstant } from "./const";
import { convertTimeStampToDate } from "./utils/date.utils";

let stompClient = null;
const Sock = new SockJS(WEB_SOCKET_URL);

export const onConnected = callback => {
  stompClient = over(Sock);
  stompClient.connect({}, frame => {
    stompClient.subscribe("/topic", x => {
      callback(onMessageReceived(x.body));
    });
    stompClient.subscribe("/event_warning", x => {
      callback(onNotifyReceived(x.body));
    });
  });
};

export const onDisconnected = () => {
  if (stompClient != null) {
    stompClient.disconnect();
  }
};

export const send = () => {
  stompClient.send("/app/hello", {}, "some-thing-like-str-or-obj");
};

export const onMessageReceived = message => {
  let data = JSON.parse(message);
  let notification = "Yêu cầu ";
  if (data.type === AppConstant.BACKGROUND_JOB_TYPE.EXPORT_FILE) {
    notification =
      notification +
      Object.values(AppConstant.EXPORT_FILE_ACTION).find(s => s.key === data.subType).title +
      " từ ngày " +
      convertTimeStampToDate(data.fromDate / 1000) +
      "đến ngày " +
      convertTimeStampToDate(data.toDate / 1000) +
      " xử lý " +
      (data.status === AppConstant.BACKGROUND_JOB_STATUS.FINISHED ? "thành công" : "thất bại");
  }
  if (data.type === AppConstant.BACKGROUND_JOB_TYPE.IMPORT_FILE) {
    notification =
      notification +
      "nhập dữ liệu" +
      " xử lý " +
      (data.status === AppConstant.BACKGROUND_JOB_STATUS.FINISHED ? "thành công" : "thất bại");
  }
  return { notification, type: data.status };
};

export const onNotifyReceived = noti => {
  let data = JSON.parse(noti);
  if (Cookies.get(AppConstant.KEY_USER_ID) === data.userUid) {
    let type = data.type === SystemConstant.TYPE_NOTIFICATION.NOTIFY_TYPE_WARNING ? "warning" : "info";
    return { notification: data.message, type: type, subscribe: "event_warning", cause: data?.cause };
  }
  return { notification: null, type: null };
};
