import { memo } from "react";
import { makeStyles } from "@mui/styles";
import { Box, IconButton, Typography, Checkbox } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useMapContext } from "hooks";
import { AppConstant } from "const";

const LayerBackgroundMapSettingDialog = () => {
  const classes = useStyles();

  const {
    showBackgroundMapList,
    setShowBackgroundMapList,
    areaManagementConfigs,
    chooseBackgroundMap,
    setChooseBackgroundMap,
  } = useMapContext();

  const stopPropagation = e => {
    e.stopPropagation();
  };

  const handleCloseDialog = () => {
    setShowBackgroundMapList(false);
  };

  const onChangeValue = url => {
    setChooseBackgroundMap(url);
    localStorage.setItem(AppConstant.LOCAL_STORAGE_KEY.backgroundMap, url);
  };

  return (
    <>
      <Box
        className={showBackgroundMapList ? classes.openDragDropElem : classes.closeDragDropElem}
        onDoubleClick={stopPropagation}
        component="section"
      >
        <Box marginBottom={1} className={classes.wrapper}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            marginBottom={1}
            className={classes.drawWatchListHeader}
          >
            <Typography>Nền bản đồ</Typography>
            <IconButton size="small" onClick={handleCloseDialog} disabled={areaManagementConfigs.status !== "idle"}>
              <Close
                fontSize="small"
                htmlColor={areaManagementConfigs.status === "idle" ? "#fff" : "rgba(0, 0, 0, 0.5)"}
              />
            </IconButton>
          </Box>
        </Box>
        {AppConstant.BACKGROUND_MAP_LIST.map(item => (
          <Box className={classes.bodyList} onClick={() => onChangeValue(item.url)}>
            <Checkbox value={item.url} checked={item.url === chooseBackgroundMap} />
            <Typography className={item.url === chooseBackgroundMap ? classes.selectBox : ""}>{item.title}</Typography>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default memo(LayerBackgroundMapSettingDialog);

const useStyles = makeStyles(() => ({
  openDragDropElem: {
    position: "fixed",
    top: "135px",
    right: "60px",
    width: 200,
    display: "block",
    cursor: "default",
    zIndex: 1000,
    background: "#fff",
    boxSizing: "border-box",
    borderRadius: 4,
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  },

  closeDragDropElem: {
    display: "none",
  },

  header: {
    width: "100%",
    height: 32,
    backgroundColor: "#1d83c3",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "4px 4px 0 0",
  },

  bodyList: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    cursor: "pointer",
  },

  drawWatchListHeader: {
    backgroundColor: "#1d83c3",
    color: "#ffffff",
    padding: "4px 8px",
    borderRadius: "4px 4px 0 0",
    marginBottom: 0,
  },

  wrapper: {
    borderBottom: "1px solid #999999",
  },

  selectBox: {
    color: "#1588d1",
  },
}));
