import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import StringFormat from "string-format";
import { LangConstant } from "const";
import ShipActions from "redux-store/ship.redux";
import ConversationRedux from "redux-store/conversation.redux";

const FilterGroupShip = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const shipConditions = useSelector(state => state.shipRedux.shipConditions);
  const getGroupsucsess = useSelector(state => state.conversationRedux.getGroupsucsess);

  const [selected, setSelected] = useState(shipConditions.group_ids);

  useEffect(() => {
    dispatch(ConversationRedux.getGroupShip({}));
  }, []);

  useEffect(() => {
    setSelected(shipConditions.group_ids);
  }, [shipConditions.group_ids]);

  const onChangeValue = e => {
    let value = e.target.value;
    let list = [...selected];
    let index = list.indexOf(value);
    if (index === -1) {
      list.push(value);
    } else {
      list = list.filter(item => item !== value);
    }
    setSelected(list);
    dispatch(ShipActions.shipSet({ shipConditions: Object.assign(shipConditions, { group_ids: list }) }));
  };

  return (
    <Box className={classes.monitoringShipContainer}>
      <FormGroup
        classes={{
          root: classes.selectCheckContainer,
        }}
      >
        {getGroupsucsess.map(item => (
          <FormControlLabel
            key={item.uid}
            control={
              <Checkbox value={item.uid} onChange={e => onChangeValue(e)} checked={selected.includes(item.uid)} />
            }
            label={StringFormat(
              LangConstant.FM_MONITORING_AREA,
              item.name
                ? item.name.slice(0, 25) + (item.name.length > 25 ? "..." : "")
                : LangConstant.TXT_UNDEFINE_NAME,
              item.ships.length,
            )}
            classes={{
              label: classes.monitoringListTitle,
            }}
          />
        ))}
      </FormGroup>
    </Box>
  );
};

export default FilterGroupShip;

const useStyles = makeStyles(theme => ({
  monitoringListTitle: {
    fontSize: "14px",
    color: "#000",
    userSelect: "none",
    marginTop: "4px",
  },

  monitoringShipContainer: {
    border: "1px solid rgba(0, 0, 0, 0.2)",
    borderRadius: "4px",
  },

  selectCheckContainer: {
    padding: "0 16px",
  },
}));
