import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Checkbox,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ManagementAction from "redux-store/management.redux";
import { AppConstant, SystemConstant } from "const";
import Cookies from "js-cookie";

const RoleManagement = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const module = useSelector(state => state.managementRedux.module);
  const permissionObj = useSelector(state => state.managementRedux.permission);

  const [role, setRole] = useState(parseInt(Cookies.get(AppConstant.KEY_ROLE_ID)) || SystemConstant.ROLE.admin);
  const [functionArr, setFunctionArr] = useState([]);
  const [permissionArr, setPermissionArr] = useState([]);

  const onChangeRoleFilter = newRole => {
    setRole(newRole);
  };

  useEffect(() => {
    dispatch(ManagementAction.getModule());
  }, []);

  useEffect(() => {
    dispatch(ManagementAction.getPermission(role));
  }, [role]);

  useEffect(() => {
    setFunctionArr(SystemConstant.PERMISSION_ARRAY);
  }, [module]);

  useEffect(() => {
    if (JSON.stringify(permissionObj.permission) !== JSON.stringify(permissionArr) && permissionObj.role === role) {
      setPermissionArr(permissionObj.permission);
    }
  }, [permissionObj]);

  return (
    <Box className={classes.roleRoot}>
      <Box className={classes.roleFilter}>
        <Typography className={classes.roleLabel}>Vai trò: </Typography>
        <Select
          value={role}
          onChange={event => {
            onChangeRoleFilter(event.target.value);
          }}
          className={classes.selectRoleInput}
        >
          <MenuItem value={SystemConstant.ROLE.system_admin}>Quản trị hệ thống</MenuItem>
          <MenuItem value={SystemConstant.ROLE.admin}>Quản trị viên</MenuItem>
          <MenuItem value={SystemConstant.ROLE.manager}>Quản lý</MenuItem>
          <MenuItem value={SystemConstant.ROLE.staff}>Nhân viên</MenuItem>
        </Select>
      </Box>

      <Box className={classes.roleDetailTable}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Danh sách chức năng</TableCell>
                <TableCell align="right">Xem</TableCell>
                <TableCell align="right">Chỉnh sửa</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {functionArr?.map(row => (
                <TableRow
                  key={row.name}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">
                    <Checkbox checked={Boolean(permissionArr.find(item => item.id === row.id))} disabled />
                  </TableCell>
                  <TableCell align="right">
                    <Checkbox
                      checked={Boolean(
                        permissionArr.find(
                          item =>
                            item.id === row.id && item.permission === SystemConstant.PERMISSION_ACCESSIBILITY.edit,
                        ),
                      )}
                      disabled
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

RoleManagement.propTypes = {};

export default RoleManagement;

const useStyles = makeStyles(() => ({
  roleRoot: {
    padding: 30,
  },

  roleFilter: {
    display: "flex",
  },

  roleLabel: {
    fontSize: 16,
    fontWeight: 500,
    marginRight: 20,
  },

  selectRoleInput: {
    borderRadius: 12,
  },
}));
