import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConversationRedux from "redux-store/conversation.redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { regEx } from "utils";
import { AppConstant, LangConstant } from "const";
import PhoneInput from "react-phone-input-2";
import { Close, Visibility, VisibilityOff } from "@mui/icons-material";
import Cookies from "js-cookie";
import { handleSendNotiInSaga } from "utils";

const DialogCreateUser = ({ setOpen, open }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const createUserSuccess = useSelector(state => state.conversationRedux.createUserSuccess);
  const errCreateUserFailed = useSelector(state => state.conversationRedux.errCreateUserFailed);

  const [showPassword, setShowPassword] = useState(false);
  const [messageEmail, setMessageEmail] = useState("");
  const [valueValidateNumber, setValueValidateNumber] = useState();
  const [valueInputCreateUser, setValueInputCreateUser] = useState({
    name: "",
    email: "",
    password: "",
    phoneNumber: "",
    department: "",
  });
  // const [errValue, setErrValue] = useState("");
  const [errPassword, setErrPassword] = useState("");
  const [errDepartment, setErrDepartment] = useState("");
  const [errNumber, setErrNumber] = useState("");
  const [selectPermission, setSelectPermission] = useState();
  const [errorName, setErrorName] = useState("");
  const [errorPermission, setErrorPermission] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const onClickShowPassword = () => setShowPassword(show => !show);

  const onChangeValueCreateUser = event => {
    setValueInputCreateUser({ ...valueInputCreateUser, [event.target.name]: event.target.value });
  };

  const onChangeSelect = event => {
    setSelectPermission(event.target.value);
    setErrorPermission(false);
  };

  const onClickSave = () => {
    const { email, password, name, department } = valueInputCreateUser;
    let errName = false;
    let errEmail = false;
    let errPassword = false;
    let errDepartment = false;
    let errPhone = false;

    if (regEx.test(email)) {
      setMessageEmail("");
    } else if (!regEx.test(email)) {
      setMessageEmail(LangConstant.TXT_VALIDATE_EMAIL);
      errEmail = true;
    } else {
      setMessageEmail("");
    }

    if (name.length <= 4) {
      setErrorName(LangConstant.TXT_ERROR_NAME_INPUT);
      errName = true;
    } else {
      setErrorName("");
    }

    if (valueInputCreateUser.password.length < 8) {
      setErrPassword("Mật khẩu nhập tối thiểu 8 ký tự");
      errPassword = true;
    } else {
      setErrPassword("");
    }

    if (department === "") {
      setErrDepartment(LangConstant.TXT_ERR_ERR_DEPARTMENT);
      // eslint-disable-next-line no-unused-vars
      errDepartment = true;
    } else {
      setErrDepartment("");
    }

    if (!selectPermission) {
      setErrorPermission(true);
    }

    if (
      !errEmail &&
      !errName &&
      !errPassword &&
      email &&
      name &&
      password &&
      !errPhone &&
      !errDepartment &&
      selectPermission
    ) {
      console.log(selectPermission);
      dispatch(
        ConversationRedux.createUser({
          name: valueInputCreateUser.name,
          email: valueInputCreateUser.email,
          role: selectPermission,
          password: valueInputCreateUser.password,
          phone_number: valueValidateNumber,
          department: valueInputCreateUser.department,
        }),
      );
    }
  };

  useEffect(() => {
    if (createUserSuccess) {
      setOpen(false);
      setErrPassword("");
      setErrDepartment("");
      setMessageEmail("");
      setErrNumber("");
      // setErrValue("");
      handleSendNotiInSaga(LangConstant.MS_CREATE_USER_SUCCESS, "success");
    } else if (errCreateUserFailed) handleSendNotiInSaga(LangConstant.MS_ERROR_EMAIL_EXSITED, "error");

    dispatch(
      ConversationRedux.conversationSet({
        createUserSuccess: false,
        errCreateUserFailed: false,
      }),
    );
  }, [createUserSuccess, setOpen, errCreateUserFailed]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <Box className={classes.flexIcon}>
        <DialogTitle>Tạo người dùng</DialogTitle>
        <Box onClick={() => setOpen(false)}>
          <IconButton>
            <Close />
          </IconButton>
        </Box>
      </Box>
      <DialogContent className={classes.containerBody}>
        <Fragment>
          <TextField
            onChange={onChangeValueCreateUser}
            name="name"
            fullWidth
            size="small"
            label="Họ và tên"
            variant="outlined"
          />
          <Typography className={classes.errMessenger}>{errorName}</Typography>

          <Box style={{ paddingTop: 10 }}>
            <TextField
              name="email"
              fullWidth
              size="small"
              label={LangConstant.L_EMAIL}
              variant="outlined"
              onChange={onChangeValueCreateUser}
            />
            <Typography className={classes.errMessenger}>{messageEmail}</Typography>
          </Box>
          <Box className={classes.containerInput}>
            <FormControl fullWidth size="small" variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Mật khẩu</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={onClickShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                onChange={onChangeValueCreateUser}
                name="password"
              />
            </FormControl>
            <Typography className={classes.errMessenger}>{errPassword}</Typography>
          </Box>
          <PhoneInput
            onChange={phone => setValueValidateNumber(phone)}
            country="vn"
            onlyCountries={AppConstant.COUNTRY}
          />
          <Box style={{ fontSize: 10, color: "red" }}>{errNumber}</Box>
          <Box className={classes.containerInput}>
            <TextField
              onChange={onChangeValueCreateUser}
              name="department"
              fullWidth
              label={LangConstant.TXT_TIME_SYSTEM}
              variant="outlined"
              size="small"
            />
            <Typography className={classes.errMessenger}>{errDepartment}</Typography>
          </Box>
          <FormControl size="small" fullWidth>
            <InputLabel id="demo-simple-select-label">Quyền người dùng</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Quyền người dùng"
              value={selectPermission}
              onChange={onChangeSelect}
            >
              {Object.keys(AppConstant.ROLE_NAME)
                .filter(value => value > parseInt(Cookies.get(AppConstant.KEY_ROLE_ID)))
                .map((value, index) => (
                  <MenuItem key={index} value={value}>
                    {AppConstant.ROLE_NAME[value]}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {errorPermission && <Typography sx={{ color: "red" }}>*{LangConstant.TXT_ERROR_NULL_PERMISSION}</Typography>}
        </Fragment>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickSave}>{LangConstant.TXT_BUTTON_AGREE}</Button>
        <Button onClick={handleClose}>{LangConstant.TXT_BUTTON_CANCEL}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogCreateUser;

const useStyles = makeStyles(theme => ({
  containerBody: {
    display: "flex",
    flexDirection: "column",
  },

  containerInput: {
    padding: "15px 0",
  },

  paddingTopInput: {
    paddingTop: 10,
  },

  errMessenger: {
    fontSize: "12px !important",
    color: "red",
  },

  flexIcon: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));
