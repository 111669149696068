import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AppConstant, LangConstant } from "const";
import { useSelector } from "react-redux";
import StringFormat from "string-format";

const ReportSummary = ({ lostType }) => {
  const classes = useStyles();
  const shipLostChartData = useSelector(state => state.shipRedux.shipLostChartData);
  const totalShip = useSelector(state => state.shipRedux.totalShipInDatabase);

  return (
    <Box className={classes.summaryContainer}>
      <Typography className={classes.titleSummary}>
        {lostType === AppConstant.SHIP_LOST_TYPE.disconnected
          ? LangConstant.TXT_REPORT_SUMMARY_TITLE
          : LangConstant.TXT_REPORT_SUMMARY_LOST_SIGNAL_TITLE}
      </Typography>
      <Typography>{LangConstant.TXT_REPORT_SUMMARY_DVT_TEXT}</Typography>
      <Typography className={classes.numberShipsLost}>{shipLostChartData?.total}</Typography>
      <Typography>{StringFormat(LangConstant.FM_TOTAL_SHIP_IN_DATABASE, totalShip || 0)}</Typography>
    </Box>
  );
};

export default ReportSummary;

const useStyles = makeStyles({
  summaryContainer: {
    marginLeft: "32px",
  },

  titleSummary: {
    fontWeight: 600,
  },

  numberShipsLost: {
    fontSize: 40,
    fontWeight: 500,
    marginTop: "16px",
  },
});
